
import
    {
        ADD_TO_CART,
        ADD_TO_CART_SUCCESS,
        ADD_TO_CART_FAIL,
        ADD_TO_GIFT,
        ADD_TO_GIFT_FAIL,
        ADD_TO_GIFT_SUCCESS,
        CALCULATE_GIFT,
        CALCULATE_GIFT_SUCCESS
    } from "./actionTypes"

export const addToCart = (cart) => ({
    type: ADD_TO_CART,
    payload : {cart}
})

export const addToCartSuccess = message => ({
    type: ADD_TO_CART_SUCCESS,
    payload: {message}
})
export const addToCartFail = message => ({
    type: ADD_TO_CART_FAIL,
    payload: {message}
})

export const addToGift = (data) => ({
    type: ADD_TO_GIFT,
    payload: {data}
})

export const addToGiftSuccess = message => ({
    type: ADD_TO_CART_SUCCESS,
    payload : {message}
})

export const addToGiftFail = message => ({
    type: ADD_TO_CART_FAIL,
    payload: {message}
})

export const calculateGift = (discount, finalPrice) => ({
    type: CALCULATE_GIFT,
    payload: {discount, finalPrice}
})