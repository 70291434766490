import React, { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";



import "./studentProfileView.scss";
import { getStudentData } from "../../store/RetailGame/Student/actions";
import StudentGameTable from "./StudentGameTable";
import LoginHeader from "../StudentPages/Common/LoginHeader";
import MainFooter from "../StudentPages/Common/footer/MainFooter";

const GameList = () => {
    let dispatch = useDispatch();
  const { token,  studentCourse } = useSelector(
    (state) => ({
      token: state.Login.token,
      role: state.Login.userrole,
      courses: state.courseReducer.ownCourse,
      studentCourse: state.studentGame.studentCourse,
     
    })
  );
console.log('studentCourse', studentCourse);

  useEffect(() => {
   
    dispatch(getStudentData(token));
  }, []);

  return (
    <div>
      <MetaTags>
        <title> GameProject</title>
      </MetaTags>
      <LoginHeader />
      <StudentGameTable
        studentCourse={studentCourse}
        
      />
      {/* <StudentProfileViewNew /> */}
      <MainFooter />
    </div>
  );
};

export default GameList;
