import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { clearGame, getSingleGame, storeStudentGameData } from "../../store/RetailGame/Student/actions";
import RetailGameLayout from "../Retail/RetailGameLayout";
import { getSingleCourse } from "../../store/actions";
const GameInformation = () => {
  let dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = useLocation();
 
  const { id, courseName } = useParams();
  console.log("courseName", courseName);
  const { token, studentQues, typeArray, singleGame, singleGameLoading } = useSelector((state) => ({
    token: state.Login.token,
    studentQues: state.studentGame.studentQues,
    typeArray: state.studentGame.typeArray,
    singleGame: state.studentGame.singleGame,
    singleGameLoading: state.studentGame.singleGameLoading,
  }));
  console.log("singleGame", singleGame);
  useEffect(()=>{
    dispatch(getSingleGame(id))
  },[])
  console.log("id", id);
  useEffect(() => {
    let type = [];
    let totalQuestion = 0;
    if(!singleGameLoading){
      for (let index = 0; index < singleGame.questionNumberAndSerial.length; index++) {
        type.push(singleGame?.questionNumberAndSerial[index].examType);
  
        totalQuestion =
          totalQuestion + singleGame?.questionNumberAndSerial[index].totalQuestion;
        if (singleGame?.questionNumberAndSerial[index].examType === "mcq") {
          dispatch(
            storeStudentGameData(
              "mcqEndStep",
              singleGame?.questionNumberAndSerial[index].totalQuestion
            )
          );
        }
        if (singleGame?.questionNumberAndSerial[index].examType === "drugdrop") {
          dispatch(
            storeStudentGameData(
              "dragDropEndStep",
              singleGame?.questionNumberAndSerial[index].totalQuestion
            )
          );
        }
        if (singleGame?.questionNumberAndSerial[index].examType === "grid") {
          dispatch(
            storeStudentGameData(
              "gridEndStep",
              singleGame?.questionNumberAndSerial[index].totalQuestion
            )
          );
        }
      }
      dispatch(storeStudentGameData("typeArray", type));
      dispatch(storeStudentGameData("totalQuestion", totalQuestion));
    }
   
 
  }, [singleGameLoading]);
  // useEffect(() => {
  //   dispatch(storeStudentGameData("currentIndex", 0));
  //   dispatch(storeStudentGameData("countQuestion", 0));
  //   dispatch(storeStudentGameData("currentType", ""));
  //   dispatch(storeStudentGameData("mcqStartStep", 0));
  //   dispatch(storeStudentGameData("dragDropStartStep", 0));
  //   dispatch(storeStudentGameData("gridStartStep", 0));
  //   dispatch(storeStudentGameData("selected", ""));
  // }, []);

  useEffect(()=>{
    dispatch(storeStudentGameData("courseName", courseName));
    dispatch(storeStudentGameData("courseId", id));
    dispatch(clearGame())
  },[])

  return (
    <RetailGameLayout pathname={pathname}>
      <div className="p-3">
        <h3
          style={{
            textAlign: "center",
          }}
        >
          IOQ গেম
        </h3>
        <p
          style={{
            margin: "12px 0",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          আপনি এই IQ কুইজটি শুরু করতে পারেন। প্রতিটি প্রশ্নে একটি বুদ্ধিমত্তা
          সংক্রান্ত প্রশ্ন দেয়া হবে। প্রশ্নগুলির সাম্প্রতিক সঠিক উত্তর দিন।
           উত্তর প্রদানের পরে
          সরাসরি আপনার স্কোর দেখা যাবে। কুইজটি সম্পূর্ণ করতে পর্যাপ্ত সময় দেয়া
          হবে। আপনার কুইজে প্রাপ্ত সর্বোচ্চ স্কোর দেখতে পাবেন। যদি আপনি চান,
          তাহলে কুইজটি পুনরায় করতে পারেন।
        </p>
        <button
          onClick={() => history.push(`/student-game-ques/${id}/${courseName}`)}
          className="next-quiz-submit-btn"
        >
          <span>
            <span>
              <span>
                <span>খেলা শুরু</span>
              </span>
            </span>
          </span>
        </button>
        {/* <Button
          onClick={() => history.push("/student-game-ques", { data: data })}
          style={{ border: "1px solid black" }}
          className="mt-5"
        >
          Start Game
        </Button> */}
      </div>
    </RetailGameLayout>
  );
};

export default GameInformation;
