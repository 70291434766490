import React from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const StudentGameTable = ({ studentCourse }) => {
  const history = useHistory();
  const getData = (courseID, courseName) => {
    console.log('courseName', courseName);

    switch (courseName) {
      case "Retail Game 4":
        return     history.push(
          `/game-information/${courseID}/${courseName}`,
          { data: courseID }
        );
        case "Retail Game 5":
          return     history.push(
            `/game-two-information/${courseID}/${courseName}`,
            { data: courseID }
          );
  
        default:
          return null;
    }
  };

  return (
    <div style={{ minHeight: "40vh", margin: "60px 0" }}>
      <Container>
        <h3 style={{ marginBottom: "14px" }}>Game list</h3>
        <Row>
          <Col lg={3}></Col>
          <Col lg={9}>
            <Table
              borderless
              className="list-course-view-data-table-main-area-wrap"
              responsive
              style={{ verticalAlign: "middle" }}
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Code</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {studentCourse.map((v, i) => (
                  <tr>
                    <td style={{ padding: "5px 6px", width: "50%" }}>
                      <div>
                        <img
                          src={v?.courseId?.image}
                          style={{ height: "40px" }}
                          alt=""
                        />
                        <span>{v?.courseId?.courseName}</span>
                      </div>
                    </td>
                    <td style={{ width: "30%", padding: "5px 6px" }}>
                      <p>{v?.courseId?.code}</p>
                    </td>

                    <td style={{ width: "20%", padding: "5px 6px" }}>
                      <div className="list-course-view-data-table-jdhbbd">
                        {/* <span onClick={()=>  history.push('/game-info')}>View</span> */}
                        <span
                          className="single-game-btn-table"
                          style={{
                            cursor: "pointer",
                            border: "1px solid black",
                            padding: "5px 10px",
                          }}
                          onClick={() =>
                          
                            getData(v?.courseId?._id,v?.courseId?.courseName)
                          }
                        >
                          View
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default StudentGameTable;
