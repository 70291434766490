import React from 'react';

const QuestionComponent = ({
  item,
  answer,
  index,
  handleTakeAnswer,
  questionSerial,
}) => {
  const getQuestion = () => {
    switch (item?.type) {
      case 'mcq':
        return (
          <div className="lafarge-exercise-single-item-inner-right">
            <div className="radio-buttons">
              {item?.option?.map((data, idx) => (
                <div className="form-group" key={idx}>
                  <input
                    type="radio"
                    id={data + index}
                    name={'radio' + index + idx}
                    value={data}
                    checked={answer?.[index + 1]?.givenAnswer === data}
                    onClick={(e) =>
                      handleTakeAnswer(
                        e,
                        item,
                        index + 1,
                        'mcq',
                        questionSerial
                      )
                    }
                  />
                  <label htmlFor={data + index}>{data}</label>
                </div>
              ))}
            </div>
          </div>
        );
      case 'fillGap':
        return (
          <div className="lafarge-exercise-single-item-inner-right fill-inthe-gap-field">
            <input
              className="form-control"
              type="text"
              value={answer?.[index + 1]?.givenAnswer}
              onChange={(e) =>
                handleTakeAnswer(e, item, index + 1, 'fillGap', questionSerial)
              }
            ></input>
          </div>
        );
      case 'situation':
        return (
          <div
            className="lafarge-exercise-single-item-inner-right "
            style={{ height: '100%' }}
          >
            <textarea
              className="form-control"
              value={answer?.[index + 1]?.givenAnswer}
              style={{ height: '100%' }}
              onChange={(e) =>
                handleTakeAnswer(
                  e,
                  item,
                  index + 1,
                  'situation',
                  questionSerial
                )
              }
            ></textarea>
          </div>
        );
      default:
        return 'NO Data';
    }
  };
  return <React.Fragment>{getQuestion()}</React.Fragment>;
};

export default QuestionComponent;
