import moment from "moment";
import React, { useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Link } from "react-router-dom";
import BeforeLoginCartBody from "../BeforeLoginCart/BeforeLoginCartBody";
import modalImageItem from "./img/LoginModal.svg";
import trashIcon from "./img/trash-2.svg";
import ReletadCourse from "./ReletadCourse/ReletadCourse";
const CartPageBody = ({
	cart,
	token,
	handleRemoveCart,
	handleQuantity,
	handleIncrement,
	handleDecrement,
	role,
}) => {
	let [count, setCount] = useState({});
	let [cost, setCost] = useState({});
	const calculateDate = (duration) => {
		let day = new Date(Date.now());
		return day.setDate(day.getDate() + duration);
	};

	const total = (cartData) => {
		let result = 0;
		cartData.map((v, i) => {
			result += v.price * v.count;
		});
		return result;
	};

	const [open, setOpen] = useState(false);
	let dispatch = useDispatch();
	// console.log(" lenght", JSON.parse(localStorage.getItem("cart"))?.length)
	const onOpenModal = () => setOpen(true);
	const onCloseModal = () => setOpen(false);

	const TableData = (role) => {
		if (role == "teacher") {
			return (
				<Table borderless className="cart-table-main-wrap-hgjfkdlujfnc">
					<thead>
						<tr>
							<th></th>
							<th>Course Title</th>
							<th>Activation/Expiry Date</th>
							<th>Price</th>
							<th>Unit</th>
							<th>Total (TK.)</th>
						</tr>
					</thead>
					<tbody>
						{cart?.map((v, i) => (
							<tr>
								<td className="cart-page-first-item-tgbnhyujm">
									<img
										src={trashIcon}
										alt=""
										onClick={(e) => handleRemoveCart(e, v)}
									/>
								</td>
								<td>
									<div className="cart-page-single-data-item">
										<img
											src={`${process.env.REACT_APP_IMAGE_URL}${v.image}`}
											alt=""
										/>
										<div className="">
											<h4>{v.courseName}</h4>
											<p>Validity - {v.duration}</p>
										</div>
									</div>
								</td>
								<td>
									{
										new Date(Date.now())
											.toLocaleString()
											.split(",")[0]
									}
									<br />
									to
									<br />
									{
										moment(new Date())
											.add(v.duration, "days")
											.toDate()
											.toLocaleString()
											.split(",")[0]
									}
								</td>
								<td>Tk. {v.price}</td>
								<td>
									<p>
										<Button
											onClick={(e) =>
												handleIncrement(e, v._id)
											}>
											+
										</Button>{" "}
										{v.count}{" "}
										<Button
											onClick={(e) =>
												handleDecrement(e, v._id)
											}>
											-
										</Button>{" "}
									</p>
									{/* <div className="course-count-main-increement-wrap">
                          {" "}
                          <span typeof="submit" style={{ cursor: "pointer" }}>
                            -
                          </span>
                          <p>{v.count}</p>
                          <span typeof="submit" style={{ cursor: "pointer" }}>
                            +
                          </span>
                        </div> */}
								</td>
								<td>TK. {v.price * v.count}</td>
							</tr>
						))}{" "}
					</tbody>
				</Table>
			);
		} else {
			return (
				<Table borderless className="cart-table-main-wrap-hgjfkdlujfnc">
					<thead>
						<tr>
							<th></th>
							<th>Course Title</th>
							<th>Activation/Expiry Date</th>
							<th>Price</th>
							<th>Unit</th>
							<th>Total (TK)</th>
						</tr>
					</thead>
					<tbody>
						{cart?.map((v, i) => (
							<tr>
								<td className="cart-page-first-item-tgbnhyujm">
									<img
										src={trashIcon}
										alt=""
										onClick={(e) => handleRemoveCart(e, v)}
									/>
								</td>
								<td>
									<div className="cart-page-single-data-item">
										<img
											src={`https://staging-courseware-backend.sgp1.digitaloceanspaces.com/${v.image}`}
											alt=""
										/>
										<div className="">
											<h4>{v.courseName}</h4>
											<p>Validity - {v.duration}</p>
										</div>
									</div>
								</td>
								<td>
									{
										new Date(Date.now())
											.toLocaleString()
											.split(",")[0]
									}
									<br />
									to
									<br />
									{
										moment(new Date())
											.add(v.duration, "days")
											.toDate()
											.toLocaleString()
											.split(",")[0]
									}
								</td>
								<td>TK. {v.price}</td>
								<td>
									<p> {v.count}</p>
								</td>
								<td>TK. {v.price * v.count}</td>
							</tr>
						))}{" "}
					</tbody>
				</Table>
			);
		}
	};

	return (
		<div>
			<Container>
				<div className="cart-page-inner-main-wrap">
					{/* <EmptyCart /> */}
					{cart && cart.length > 0 ? (
						<>
							<div className="cart-header-title">
								<h4>Cart</h4>
								<p>
									{cart ? cart.length : 0} course
									{cart?.length > 1 ? "s" : ""} in cart
								</p>
							</div>
							<div className="cart-main-area-wrap-cart-body">
								{TableData(role)}
								<div className="total-price-area">
									<h5>TK. {total(cart)}</h5>
								</div>
								<div className="buy-courses-in-cart-page-main-process">
									<Link to="/student-courses">
										Buy Another Course
									</Link>

									{token ? (
										<Link to="/checkout">Checkout</Link>
									) : (
										<button onClick={onOpenModal}>
											<span>
												<i className="fas fa-lock"></i>
											</span>{" "}
											Checkout
										</button>
									)}
								</div>
							</div>{" "}
						</>
					) : (
						<BeforeLoginCartBody />
					)}
				</div>
				<div className="releted-course-bottom-wrap-area">
					<ReletadCourse />
				</div>
			</Container>
			<Modal open={open} onClose={onCloseModal} center className="modal">
				<div className="popup-modal-main-wrap">
					<img src={modalImageItem} alt="" />
					<p>You need to sign in to proceed with payment.</p>
					<Link
						className="student-signup-btn-area"
						to={{
							pathname: "/login",
							state: { from: "cart-page" },
						}}>
						Sign In
					</Link>{" "}
					<Link
						className="student-signup-btn-area-signup"
						onClick={(e) =>
							localStorage.setItem("linkUrl", "cart-page")
						}
						to={{
							pathname: "/registration",
							state: { from: "cart-page" },
						}}>
						Sign Up
					</Link>
				</div>
			</Modal>
		</div>
	);
};

export default CartPageBody;
