import React, { useEffect } from "react";
import "react-modern-drawer/dist/index.css";
 import MainFooter from "../Common/footer/MainFooter";
import LoginHeader from "../Common/LoginHeader";
import EducatorCopyBody from "./EducatorCopyBody";
import { useHistory, useLocation,useParams   } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { viewStudents, insertStudentsIntoCourse, removeStudentsFromCourse } from "../../../store/actions";
import { toast } from 'react-toastify';
const TeacherCourseAdd = () => {
  const { pathname } = useLocation();
  const { courseId } = useParams()
  let dispatch = useDispatch()
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  
    const { token , students, loading} = useSelector(
      state => ({
        token: state.Login.token,
        students: state.courseReducer.students,
        loading: state.courseReducer.loading
      })
  )

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handlerInsertStudentIntoCourse =  (e, email) =>
  {
    e.preventDefault()
     console.log("email data" , email)
     
    if (!email) {
      toast.error("Email not found")
    } else {
        dispatch(insertStudentsIntoCourse(token, courseId, email))
      }
    
   
 
  }

  //remove a user form list
  const onRemoveUser = (e,studentId) =>
  {
    e.preventDefault()
    console.log("student Id", studentId)
    dispatch(removeStudentsFromCourse(token,studentId, courseId, ))
  }
  useEffect(() =>
  {

    dispatch(viewStudents(token, courseId))
  },[])
  return (
    <>
      <LoginHeader />
      <EducatorCopyBody
        handlerInsertStudentIntoCourse={handlerInsertStudentIntoCourse}
        loading={loading}
        students={students} 
        onRemoveUser={onRemoveUser}
        
        />
      <MainFooter />
    </>
  );
};

export default TeacherCourseAdd;
