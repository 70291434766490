import React, { useEffect } from "react";
import { gdpData } from "./gdpData";

const Introduction = () => {
  const [value, setValue] = React.useState(0);
  const [selectYear, setSetSelectYear] = React.useState(0);
  const [barLabel, setBarLabel] = React.useState();
  const totalValue = gdpData?.length - 1;

  useEffect(() => {
    if (gdpData?.length) {
      setSetSelectYear(gdpData[value]?.year);
    }
  }, [value]);
  const selectYearGdpData = gdpData?.find((data) => data.year === selectYear);
  const gdpGraphBar = 100 / selectYearGdpData?.countryData?.length;

  //=================== graph =================

  const sortGdpDatawithCountry = selectYearGdpData?.countryData?.sort(
    (a, b) => {
      if (a.data < b.data) {
        return 1;
      } else if (a.data > b.data) {
        return -1;
      } else {
        return 0;
      }
    }
  );

  return (
    <>
      <div className="show-selected-year-gdp-value">
        {sortGdpDatawithCountry?.map((country, idx) => {
          return (
            <div
              key={idx}
              className="single-gdp-country-list"
              style={{ height: `${gdpGraphBar}%` }}
            >
              <img src={country.flag} alt="" />
              <div className="country-gdp-growth-bar">
                <div
                  className="bar-width-length-area"
                  style={{ width: `calc(100% - ${idx * 7}%)` }}
                >
                  <p>{new Intl.NumberFormat("en-IN").format(country?.data)} </p>
                </div>
              </div>
            </div>
          );
        })}
        <div className="graphBg-gdp-area">
          {sortGdpDatawithCountry?.map((data, idx) => (
            <span
              key={idx}
              className="vertical-align-bar"
              style={{ width: `calc(100% - ${10 * idx})` }}
            ></span>
          ))}{" "}
          <span className="vertical-align-bar"></span>
        </div>
        <div className="graphBg-gdp-area-horizontal">
          {selectYearGdpData?.countryData?.map((data, idx) => (
            <span className="horizontal-align-bar" key={idx}></span>
          ))}
          <span className="horizontal-align-bar"></span>
        </div>
      </div>
      <div className="input-range-value-with-gdp-box">
        <div className="year-list-with-range">
          <ul>
            {gdpData.map((value, idx) => (
              <li key={idx}> {value.year}</li>
            ))}
          </ul>
        </div>
        <div className="input-range-target-box">
          <input
            max={totalValue}
            min={0}
            type="range"
            className="styled-range"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />

          {/* {Number(value) + 1} */}
        </div>
      </div>
    </>
  );
};

export default Introduction;
