import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const LafargeGuidlinesComponents = () => {
  const { questionID } = useParams();

  console.log("questionID", questionID);
  return (
    <div>
      <Container>
        <div className="lafarge-training-inner-wrap">
          <div className="lafarge-training-header-title">
            <h3>Guideline</h3>
            <p>
              In this section, we need you to submit your negotiation pitch
              based on the case study provided here.
            </p>
          </div>
          <div className="">
            <div className="lafarge-training-guideline-inner-wrap">
              <h6>Rules:</h6>
              <ul>
                <li>You need to select your role</li>
                <li>Read the case study</li>
                <li>Prepare your pitch and record it.</li>
                <li>Upload and Submit</li>
                <li>Your pitch can be a maximum of 5 min</li>
              </ul>
            </div>
            <div className="guidlines-procceed-button">
              <Link
                to={`/lafarge-file-upload/${questionID}?questionID=${questionID}`}
                className="lafarge-ques-submit-button-inner"
                // onClick={() => setOpen(true)}
              >
                Procceed
              </Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default LafargeGuidlinesComponents;
