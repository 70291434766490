import {
  ADD_COURSE_USER,
  ADD_COURSE_USER_FAIL,
  ADD_COURSE_USER_SUCCESS,
  ADD_QUES_SERIAL,
  ADD_QUES_SERIAL_FAIL,
  ADD_QUES_SERIAL_SUCCESS,
  CLEAR_SECOND_GAME_DATA,
  GET_COURSE_ALL_STUDENT,
  GET_COURSE_ALL_STUDENT_FAIL,
  GET_COURSE_ALL_STUDENT_SUCCESS,
  GET_RETAIL_COURSE,
  GET_RETAIL_COURSE_FAIL,
  GET_RETAIL_COURSE_SUCCESS,
  GET_SECOND_GAME,
  GET_SECOND_GAME_FAIL,
  GET_SECOND_GAME_SUCCESS,
  POST_INTRO_DATA,
  POST_INTRO_DATA_FAIL,
  POST_INTRO_DATA_SUCCESS,
  STORE_GENERAL_DATA,
  STORE_SECOND_GAME_DATA,
} from "./actionTypes";

export const postIntroData = (obj, authToken, option) => ({
  type: POST_INTRO_DATA,
  payload: { obj, authToken, option },
});

export const postIntroDataSuccess = (data) => ({
  type: POST_INTRO_DATA_SUCCESS,
  payload: { data },
});

export const postIntroDataFail = (data) => ({
  type: POST_INTRO_DATA_FAIL,
  payload: { data },
});
export const postCourseUser = (obj, authToken, option) => ({
  type: ADD_COURSE_USER,
  payload: { obj, authToken, option },
});

export const postCourseUserSuccess = (data) => ({
  type: ADD_COURSE_USER_SUCCESS,
  payload: { data },
});

export const postCourseUserFail = (data) => ({
  type: ADD_COURSE_USER_FAIL,
  payload: { data },
});
export const postQuesSerial = (obj, authToken, option) => ({
  type: ADD_QUES_SERIAL,
  payload: { obj, authToken, option },
});

export const postQuesSerialSuccess = (data) => ({
  type: ADD_QUES_SERIAL_SUCCESS,
  payload: { data },
});

export const postQuesSerialFail = (data) => ({
  type: ADD_QUES_SERIAL_FAIL,
  payload: { data },
});

export const getQuantitativeQues = (
  token,
  Section,
  difficultyLevel,
  PackageId
) => {
  return {
    type: GET_COURSE_ALL_STUDENT,
    payload: { token, Section, difficultyLevel, PackageId },
  };
};

export const getQuantitativeQuesSuccess = (values) => {
  return {
    type: GET_COURSE_ALL_STUDENT_SUCCESS,
    payload: { values },
  };
};

export const getQuantitativeQuesFail = (message) => {
  return {
    type: GET_COURSE_ALL_STUDENT_FAIL,
    payload: { message },
  };
};

export const getRetailCourse = (token, courseId) => {
  return {
    type: GET_RETAIL_COURSE,
    payload: { token, courseId },
  };
};

export const getRetailCourseSuccess = (values) => {
  return {
    type: GET_RETAIL_COURSE_SUCCESS,
    payload: values,
  };
};

export const getRetailCourseFail = (message) => {
  return {
    type: GET_RETAIL_COURSE_FAIL,
    payload: { message },
  };
};

export const getRetailSecondGame = (token, courseId) => {
  return {
    type: GET_SECOND_GAME,
    payload: { token, courseId },
  };
};

export const getRetailSecondGameSuccess = (values) => {

  return {
    type: GET_SECOND_GAME_SUCCESS,
    payload: values,
  };
};

export const getRetailSecondGameFail = (message) => {
  return {
    type: GET_SECOND_GAME_FAIL,
    payload: { message },
  };
};


export const storeGeneralData = (name, data) => {
  return {
    type: STORE_GENERAL_DATA,
    payload: { name, data },
  };
};
export const storeSecondGameData = (name, data) => ({
  type: STORE_SECOND_GAME_DATA,
  payload: { name, data },
});
export const clearSecondGame = () => ({
  type: CLEAR_SECOND_GAME_DATA,
  payload: {},
});