import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { MetaTags } from "react-meta-tags";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import MainFooter from "../../components/StudentPages/Common/footer/MainFooter";
import Header from "../../components/StudentPages/Common/Header";
import LoginHeader from "../../components/StudentPages/Common/LoginHeader";
import "./about-us.scss";
import AboutHero from "./AboutHero";
import OurExpertise from "./OurExpertise";
const AboutUs = () => {
  const { pathname } = useLocation();
  const { token } = useSelector((state) => ({
    token: state.Login.token,
  }));
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <MetaTags>
        <title>About Us | GameProject</title>
      </MetaTags>
      {token ? <LoginHeader /> : <Header />}
      <Container>
        <div className="about-us-innder-wrap">
          <AboutHero />
        </div>
      </Container>
      <OurExpertise />
      {/* <Faq /> */}
      <MainFooter />
    </div>
  );
};

export default AboutUs;
