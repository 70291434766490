import { useState } from "react";
import { Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { videoLinkData } from "./VideoLinkData";
const NegotiationMethod = () => {
  let [link, setLink] = useState("https://www.youtube.com/embed/zw4QwDZF2Jw");
  let [bgColors, setBGCOlors] = useState("first");
  const handleVideoPLay = (e, tabItem) => {
    // console.log("tab data here", e.target.value, tabItem);
    setLink(tabItem.videoLink);
    setBGCOlors(tabItem.id);
  };
  const { courseId } = useParams();
  return (
    <div>
      <Container>
        <div className="lafarge-training-inner-wrap">
          <div className="lafarge-training-header-title">
            <h3>Negotiation Process</h3>
            {/* <p>Author: Nazmus Shakib</p> */}
          </div>
          <div className="negotiation-mrthod-content-area">
            <p>
              In the workshop we talked about the fundamentals of negotiation,
              how to objectively handle objection, pre-negotiation framework and
              negotiation process. We will sum up the entire negotiation
              workshop with the key learning objectives that we discussed in the
              workshop
            </p>
            <br />
            <br />
            <div className="negotiation-main-area-tabs-wrap mb-5">
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col lg={6}>
                    <Nav
                      variant="pills"
                      className="flex-column tab-content-part-part-wrap lafarge-left-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">
                          <i className="bx bx-chevron-right"></i> Always think
                          WIN-WIN
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          <i className="bx bx-chevron-right"></i>Move from
                          position to interest
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">
                          <i className="bx bx-chevron-right"></i>Find the 18th
                          Camel
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="four">
                          <i className="bx bx-chevron-right"></i>Handle
                          objection objectively, show empathy
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="five">
                          <i className="bx bx-chevron-right"></i>Preparation is
                          the key
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="six">
                          <i className="bx bx-chevron-right"></i>Remember to use
                          these on the negotiation table
                        </Nav.Link>
                      </Nav.Item>{" "}
                      <Nav.Item>
                        <Nav.Link eventKey="seven">
                          <i className="bx bx-chevron-right"></i> Focus on long
                          term
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col lg={6}>
                    <Tab.Content className="tab-content-right-part-wrap">
                      <Tab.Pane
                        eventKey="first"
                        className="tab-content-right-single-tabs"
                      >
                        <h5>Always think WIN-WIN</h5>
                        <p>
                          Details: Modern-day negotiation is all about WIN-WIN.
                          Driven by collaboration, a good negotiation always
                          focuses on benefiting both the parties. Win-Win habit
                          needs a communication process where consideration and
                          courage is high for the negotiating parties. In order
                          to do that we need to create a paradigm shift in our
                          current mindset.{" "}
                        </p>
                        <ul>
                          <li></li>
                        </ul>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="second"
                        className="tab-content-right-single-tabs"
                      >
                        {/* <h5>Financial benefits</h5> */}
                        <h5>Move from position to interest</h5>
                        <ul>
                          <li>
                            - Position is Simple statements of what you want{" "}
                            <br />- Interest is the reasons underlying the
                            position <br /> - Negotiating based on interest
                            actually asks the crucial question “what they really
                            want or need?”
                            <br />- Negotiating based on position does not move
                            forwards to a favorable outcome for both the parties
                            <br /> - Take the perspective of person you are
                            negotiating with, find their interest
                          </li>
                        </ul>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="third"
                        className="tab-content-right-single-tabs"
                      >
                        <h5>Find the 18th Camel</h5>
                        <ul>
                          <li>
                            <span>
                              We all remember the story of the camel right! Its
                              all about finding the common ground We have to
                              remember to ask the magical question <br />
                              <b>
                                <i>
                                  “Are you willing to look for a solution that
                                  is better than either one of us has came up
                                  with”
                                </i>
                              </b>
                            </span>
                          </li>
                          <li>
                            <span>
                              - Its not your way or my way, it’s a higher way{" "}
                              <br />
                              - We have to create the moment of synergy where
                              1+1=3 meaning the whole is greater than the sum of
                              its parts <br />- Synergistic communication
                              happens when Trust and Cooperation is high for
                              both the parties
                            </span>
                          </li>
                        </ul>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="four"
                        className="tab-content-right-single-tabs"
                      >
                        <h5>Handle objection objectively, show empathy </h5>
                        <ul>
                          <li>
                            <span>
                              Use the APAC process thoroughly to handle the
                              objection, responding in a way that alleviates
                              those concerns and allows the deal to{" "}
                              <b>
                                <i>move forward. </i>
                              </b>
                            </span>
                          </li>
                          <li>
                            <span>
                              - Acknowledge the problem with the key sentence “I
                              understand you” <br />
                              - Probe at the depth of the problem by asking
                              ‘investigative question <br />
                              - Answer in a way which sounds beneficial <br />
                              - Confirm whether the other party feels that the
                              solution has reduced the risk of business. <br />
                              All while being empathetic to the concerns and
                              issues.
                            </span>
                          </li>
                        </ul>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="five"
                        className="tab-content-right-single-tabs"
                      >
                        <h5> Preparation is the key</h5>
                        <ul>
                          <li>
                            <span>
                              Research shows that Successful negotiation is 80%
                              preparation. To do that you have to do the
                              following <br />
                              - Consider all the moving parts of your
                              negotiation and prepare a priority list <br />
                              - MAP out your concessions you are willing to
                              make: but want something in return. Also known as
                              reciprocity <br />
                              - Learn as much as you can about your bargaining
                              partner <br />
                              - Never stop asking questions, because information
                              is power <br />
                              <br />
                              - Finding connections inside and outside your
                              business organization that links your negotiation
                              to the strategic points of the business. Make sure
                              that your connections are credible
                              <br />- Identify the stakeholders, inside and
                              outside of organization involved in the
                              negotiation process.
                            </span>
                          </li>
                        </ul>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="six"
                        className="tab-content-right-single-tabs"
                      >
                        <h5>
                          {" "}
                          Remember to use these on the negotiation table{" "}
                        </h5>
                        <ul>
                          <li>
                            <span>
                              Negotiation is a planned event; if you want it to
                              and you can follow the below principle <br />
                              - Your opening request should sound like you are
                              offering a benefit <br />
                              - Framing will provide a positive perspective to
                              your bargaining partner <br />
                              - Anchor first, which will create a referring
                              point around which your negotiation will revolve
                              around <br />
                              - Simplifying concessions and reciprocity <br />
                            </span>
                          </li>
                        </ul>
                      </Tab.Pane>{" "}
                      <Tab.Pane
                        eventKey="seven"
                        className="tab-content-right-single-tabs"
                      >
                        <h5> Focus on long term</h5>
                        <ul>
                          <li>
                            <span>
                              Remember that doing negotiation is not a one-time
                              thing. It is a repetitive process where you have
                              to focus on long term relation. Even if the
                              negotiation does not goes as planned, we do not
                              have to make it a bitter process. Always remember,
                              be hard on the subject but hard on the people.
                            </span>
                          </li>
                        </ul>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
          <div className="negotiation-mrthod-content-area">
            <h4>Remember to prepare yourself with the tool of negotiation</h4>
            <br />
            <br />
            <div className="negotiation-main-area-tabs-wrap mb-5">
              <Tab.Container id="left-tabs-example">
                <Row>
                  <Col lg={6}>
                    <Nav
                      variant="pills"
                      className="flex-column tab-content-part-part-wrap lafarge-left-tab"
                    >
                      {videoLinkData.map((tabItem, i) => (
                        <>
                          <button
                            className={`nav-item ${
                              bgColors == tabItem.id ? "active" : ""
                            }`}
                            onClick={(e, i) => handleVideoPLay(e, tabItem)}
                          >
                            <span className="nav-link">
                              {" "}
                              <i className="bx bx-chevron-right"></i>{" "}
                              {tabItem.title}
                            </span>
                          </button>
                          {/* <Nav.Item key={i}>
                            <Nav.Link eventKey={tabItem?.id}></Nav.Link>
                          </Nav.Item> */}
                        </>
                      ))}
                    </Nav>
                  </Col>
                  <Col lg={6}>
                    <div className="lafarge-video-area-wrap">
                      <iframe
                        // width={}
                        // height={}
                        src={link}
                        title="YouTube video player"
                        frameBorder={0}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen=""
                      />
                    </div>
                    {/* <img src={tabImage} alt="" /> */}

                    {/* <Tab.Pane
                        eventKey="first"
                        className="tab-content-right-single-tabs"
                      >
                        <div className="lafarge-video-area-wrap">
                          <iframe
                            width={560}
                            // height={}
                            src="https://www.youtube.com/embed/zw4QwDZF2Jw"
                            title="YouTube video player"
                            frameBorder={0}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen=""
                          />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="second"
                        className="tab-content-right-single-tabs"
                      >
                        <div className="lafarge-video-area-wrap">
                          <iframe
                            width={560}
                            height={315}
                            src="https://www.youtube.com/embed/-JoImyGI3R0"
                            title="YouTube video player"
                            frameBorder={0}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen=""
                          />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="third"
                        className="tab-content-right-single-tabs"
                      >
                        <div className="lafarge-video-area-wrap">
                          <iframe
                            width={560}
                            height={315}
                            src="https://www.youtube.com/embed/XnSVxuL7_o0"
                            title="YouTube video player"
                            frameBorder={0}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen=""
                          />
                        </div>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="four"
                        className="tab-content-right-single-tabs"
                      >
                        <div className="lafarge-video-area-wrap">
                          <iframe
                            width={560}
                            height={315}
                            src="https://www.youtube.com/embed/dwmjE13X-Os"
                            title="YouTube video player"
                            frameBorder={0}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen=""
                          />
                        </div>
                      </Tab.Pane>{" "}
                      <Tab.Pane
                        eventKey="five"
                        className="tab-content-right-single-tabs"
                      >
                        <div className="lafarge-video-area-wrap">
                          <iframe
                            width={560}
                            height={315}
                            src="https://www.youtube.com/embed/ot3jjj8N2hY"
                            title="YouTube video player"
                            frameBorder={0}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen=""
                          />
                        </div>
                      </Tab.Pane>{" "}
                      <Tab.Pane
                        eventKey="six"
                        className="tab-content-right-single-tabs"
                      >
                        <div className="lafarge-video-area-wrap">
                          <iframe
                            width={560}
                            height={315}
                            src="https://www.youtube.com/embed/aQDIXHjfT60"
                            title="YouTube video player"
                            frameBorder={0}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen=""
                          />
                        </div>
                      </Tab.Pane>{" "}
                      <Tab.Pane
                        eventKey="saven"
                        className="tab-content-right-single-tabs"
                      >
                        <div className="lafarge-video-area-wrap">
                          <iframe
                            width={560}
                            height={315}
                            src="https://www.youtube.com/embed/JLgr5RTBqcI"
                            title="YouTube video player"
                            frameBorder={0}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen=""
                          />
                        </div>
                      </Tab.Pane>{" "} */}
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
          <div className="lafarge-training-footer-wrap">
            <div className="lafarge-training-footer-button lafarge-details">
              <Link to={`/${courseId}/why-do-we-need`}>Previous </Link>
              <Link to={`/${courseId}/lafarge-exercise`}>Next</Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default NegotiationMethod;
