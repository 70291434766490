import {
	RECRUITE_MANAGER,
	RECRUITE_MANAGER_FAIL,
	RECRUITE_MANAGER_SUCCESS,
	CALCULATE_BUDGETING,
	CALCULATE_BUDGETING_SUCCESS,
	ADD_COST_OF_FINANCE,
	ADD_COST_OF_FINANCE_SUCCESS,
	GET_REVENUE_ON_TAB,
	GET_REVENUE_ON_TAB_SUCCESS,
	SET_SELLING_PRICE_SUCCESS,
	SET_SELLING_PRICE,
	CALCULATE_REVENUE_SUCCESS,
	CALCULATE_REVENUE,
	FINANCIAL_STATEMENT,
	FINANCIAL_STATEMENT_SUCCESS,
	CLEAN_DATA_PLAY_AGAIN,
	CLEAN_DATA_PLAY_AGAIN_SUCCESS,
} from "./actionTypes";

const initialState = {
	recruitManager: {},
	budget: {},
	costOfFinance: {},
	revenue: {},
	sellingPrice: {},
	revenueFinal: {},
	financialStatement: {},
	loading: false,
	abc: 0,
	isloading: false,
	message: "",
	error: "",
};

const recruitReducer = (state = initialState, action) => {
	switch (action.type) {
		case RECRUITE_MANAGER:
			state = { ...state, loading: true };
			break;
		case RECRUITE_MANAGER_SUCCESS:
			state = {
				...state,
				loading: false,
				recruitManager: action.payload.data,
			};
			break;
		case CALCULATE_BUDGETING:
			state = { ...state, loading: true };
			break;
		case CALCULATE_BUDGETING_SUCCESS:
			state = {
				...state,
				loading: false,
				budget: action.payload.data,
			};
			break;
		case ADD_COST_OF_FINANCE:
			state = {
				...state,
				loading: true,
			};
			break;
		case ADD_COST_OF_FINANCE_SUCCESS:
			state = {
				...state,
				loading: false,
				costOfFinance: action.payload.data,
			};

		case GET_REVENUE_ON_TAB:
			state = {
				...state,
				loading: true,
			};
			break;

		case GET_REVENUE_ON_TAB_SUCCESS:
			state = {
				...state,
				loading: false,
				revenue: action.payload.data,
			};
			break;

		case SET_SELLING_PRICE:
			state = {
				...state,
				loading: true,
			};
		case SET_SELLING_PRICE_SUCCESS:
			state = {
				...state,
				loading: false,
				sellingPrice: action.payload.data,
			};
			break;

		case CALCULATE_REVENUE:
			state = {
				...state,
				loading: true,
			};
			break;
		case CALCULATE_REVENUE_SUCCESS:
			state = {
				...state,
				loading: false,
				revenueFinal: action.payload.data,
			};
			break;
		case FINANCIAL_STATEMENT:
			state = {
				...state,
				isloading: true,
				abc: 1,
			};
			break;
		case FINANCIAL_STATEMENT_SUCCESS:
			state = {
				...state,
				isloading: false,
				financialStatement: action.payload.data,
				abc: 0,
			};
			break;
		case CLEAN_DATA_PLAY_AGAIN:
			state = {
				...state,
				loading: true,
			};
			break;
		case CLEAN_DATA_PLAY_AGAIN_SUCCESS:
			return state = 
				initialState
			
		default:
			state = state;
			break;
	}
	return state;
};
export default recruitReducer;
