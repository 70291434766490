//for all login user
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

//for all
export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

//update user info
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_USER_INFO_SUCCESS = 'UPDATE_USER_INFO_SUCCESS';
export const UPDATE_USER_INFO_FAIL = 'UPDATE_USER_INFO_FAIL';

//for all
export const REGISTER_LOGIN = 'REGISTER_LOGIN';
export const REGISTER_LOGIN_SUCCESS = 'REGISTER_LOGIN_SUCCESS';
export const REGISTER_LOGIN_ERROR = 'REGISTER_LOGIN_ERROR';

export const STORE_LOGIN_USER = 'STORE_LOGIN_USER';
