export const RECRUITE_MANAGER = "RECRUITE_MANAGER";
export const RECRUITE_MANAGER_SUCCESS = "RECRUITE_MANAGER_SUCCESS";
export const RECRUITE_MANAGER_FAIL = "RECRUITE_MANAGER_FAIL";

export const CALCULATE_BUDGETING = "CALCULATE_BUDGETING";
export const CALCULATE_BUDGETING_SUCCESS = "CALCULATE_BUDGETING_SUCCESS";
// export const CALCULATE_BUDGETING = "CALCULATE_BUDGETING"

export const ADD_COST_OF_FINANCE = "ADD_COST_OF_FINANCE";
export const ADD_COST_OF_FINANCE_SUCCESS = "ADD_COST_OF_FINANCE_SUCCESS";

export const GET_REVENUE_ON_TAB = "GET_REVENUE_ON_TAB";
export const GET_REVENUE_ON_TAB_SUCCESS = "GET_REVENUE_ON_TAB_SUCCESS";

export const SET_SELLING_PRICE = "SET_SELLING_PRICE";
export const SET_SELLING_PRICE_SUCCESS = "SET_SELLING_PRICE_SUCCESS";
export const SET_SELLING_PRICE_FAIL = "SET_SELLING_PRICE_FAIL";

export const CALCULATE_REVENUE = "CALCULATE_REVENUE";
export const CALCULATE_REVENUE_SUCCESS = "CALCULATE_REVENUE_SUCCESS";

export const FINANCIAL_STATEMENT = "FINANCIAL_STATEMENT";
export const FINANCIAL_STATEMENT_SUCCESS = "FINANCIAL_STATEMENT_SUCCESS";

//clean data

export const CLEAN_DATA_PLAY_AGAIN = "CLEAN_DATA_PLAY_AGAIN";
export const CLEAN_DATA_PLAY_AGAIN_SUCCESS = "CLEAN_DATA_PLAY_AGAIN_SUCCESS";
