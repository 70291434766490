import { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  getAcademicArea,
  getCouponByUser,
  getCouponByUserSuccess,
  getSixCoursesForStudents,
} from "../../../../../store/actions";
import MainFooter from "../../../Common/footer/MainFooter";
import Header from "../../../Common/Header";
import LoginHeader from "../../../Common/LoginHeader";
import "../Home/assets/css/homeAllCss.css";
// import "../Home/assets/Sass/studentHome.sass";
import BusinessClass from "./BusinessClass/BusinessClass";
import CallToAction from "./CallToAction/CallToAction";
import Feature from "./feature/Feature";
import Hero from "./Hero/Hero";
import PromoCode from "./PromoCode/PromoCode";
import Service from "./Services/Service";
const StudentHome = () => {
  const { pathname } = useLocation();
  let dispatch = useDispatch();
  let history = useHistory();

  const { token, loading, academicAreas, username, courses, coupon } =
    useSelector((state) => ({
      token: state.Login.token,
      username: state.Login.username,
      loading: state.AdminAuthorReducer.loading,
      academicAreas: state.AdminAuthorReducer.academicAreas,
      courses: state.courseReducer.courses,
      courseLoading: state.courseReducer.loading,
      coupon: state.adminDashboardReducer.coupon,
    }));

  const handleMove = (e) => {
    e.preventDefault();
    console.log("remove coupon");
    dispatch(getCouponByUserSuccess({}));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    dispatch(getAcademicArea());
    dispatch(getSixCoursesForStudents());
    dispatch(getCouponByUser());
  }, []);

  return (
    <div className="App home-page-main-bg">
      <MetaTags>
        <title>Home Page | GameProject</title>
      </MetaTags>
      {token ? <LoginHeader username={username} /> : <Header />}
      {/* <Header /> */}
      {coupon?.couponCode ? (
        <PromoCode handleMove={handleMove} coupon={coupon} />
      ) : (
        <></>
      )}

      <Hero academicAreas={academicAreas} />
      <BusinessClass courses={courses} />
      <Service />
      <CallToAction />
      <Feature />

      <MainFooter />
      {/* <Footer /> */}
    </div>
  );
};

export default StudentHome;
