import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import tickNegative from "../../assests/images/tick-negative.svg";
import { courseOwnerValid, submitMark } from "../../store/actions";
import ListHeader from "./ListHeader";
const IndustryRatio = () => {
  const [rev, setRev] = useState(0);
  let dispatch = useDispatch();
  let history = useHistory();

  const { revData, token, orderId } = useSelector((state) => ({
    revData: state.comparativeAnalysisReducer.revData,
    token: state.Login.token,
    orderId: state.courseReducer.orderId,
  }));
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("hello data here", rev);
    if (rev == 67.5) {
      dispatch(submitMark(token, courseId, 100, orderId, history));
    } else {
      // console.log("hello data here");
      dispatch(submitMark(token, courseId, 0, orderId, history));
    }
  };
  const { courseId } = useParams();
  useEffect(() => {
    dispatch(courseOwnerValid(token, courseId, history));
  }, []);
  return (
    <div>
      <div className="container-xl">
        <div>
          <div className="case-method-list">
            <ListHeader />
            <div>
              <div className="row mx-5 py-3 pb-5">
                <div className="col-md-6 my-auto">
                  <div className="industry-ratio-left">
                    <div className="my-3">
                      <h3>RevPAR</h3>
                      <p>
                        The Revenue Per Available Room is one of the most
                        important ratios to the hotel industry because it allows
                        import {toast} from 'react-toastify'; us to value the
                        financial performance of an establishment or a chain.
                        RevPar is the English abbreviation for Revenue Per
                        Available Room. This always refers to a specific period
                        (weekly, monthly, annually, etc.).
                      </p>
                    </div>

                    <h3>Calculation</h3>
                    <div>
                      <div className="calculation-info">
                        <h6>
                          {" "}
                          <span>RevPAR</span> = It/ΣHt
                        </h6>
                        <p>
                          <span>It</span> : Refers to the total income generated
                          by the rooms occupied during a period t.
                        </p>
                        <p>
                          {" "}
                          <span>ΣHt</span> : Number of rooms available during a
                          period t.
                        </p>
                      </div>
                    </div>
                    <div className="my-3">
                      <h3>Average Daily Rate</h3>
                      <p>
                        (commonly known as ADR) is also a statistic widely used
                        in the hotel industry. The figure represents the average
                        income earned from letting a room during a determined
                        period of time. ADR, and (property) occupancy are the
                        bases for the financial performance of the property. The
                        ADR can be calculated by dividing the income per room by
                        the number of rooms sold.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 my-auto">
                  <div className="revpar-calculation-info">
                    <div className="">
                      <p className="revpar-hint">
                        Please calculate the following industry ratio bearing in
                        mind that:
                      </p>
                      <h6>
                        In the company the number of rooms is 1,000, the average
                        rate is 90 euros and the average occupancy ratio is 75%.
                      </h6>
                      <div className="revpar-calculation-part">
                        <Row>
                          <Col lg={4}>
                            {" "}
                            <h4>RevPAR</h4>
                          </Col>
                          <Col lg={7}>
                            {" "}
                            <input
                              type="number"
                              className="RevPAR-input"
                              value={rev}
                              onChange={(e) => setRev(e.target.value)}
                            ></input>
                          </Col>
                          <Col lg={1}>
                            <p>%</p>
                          </Col>
                        </Row>

                        {/* {revData == 1 && (
													<img
														src={
															positiveTick
														}></img>
												)} */}
                        {revData == 2 && <img src={tickNegative}></img>}
                      </div>
                      <Row>
                        <Col lg={4}></Col>
                        <Col lg={7}>
                          <Link>
                            <div className="revpar-calculation-btn">
                              <button
                                type="button"
                                className=""
                                onClick={(e) => handleSubmit(e)}
                              >
                                Check
                              </button>
                            </div>
                          </Link>
                        </Col>
                        <Col lg={1}></Col>
                      </Row>

                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustryRatio;
