const ForwardArrow = () => {
  return (
    <>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.7832 5L21.5003 12L14.7832 19"
          stroke="#FCFCFC"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <line
          x1="20.2324"
          y1="12.0317"
          x2="4.49936"
          y2="12.0317"
          stroke="#FCFCFC"
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </>
  );
};

export default ForwardArrow;
