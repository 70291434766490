import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Container } from "reactstrap";
import sslCommercepayIcon from "../../../../assests/images/1111.png";
import footerLogo from "../../StudentHomePage/components/Home/assets/image/footer-logo.svg";
import insight from "../img/insight.svg";
import "./footer.scss";
const MainFooter = () => {
  return (
    <div>
      <div className="footer-main-area">
        <div className="container">
          <div className="footer-inner-wrap">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div className="footer-single-item">
                  <div className="footer-single-item-title">
                    <img src={footerLogo} alt="" />
                  </div>
                  <div className="footer-single-item-content">
                    <p>
                      You can’t know everything but you can always have a basic
                      understanding of things happening around you, right? Your
                      brain needs a push to learn things more engagingly.
                      Courseware has it all engaging, illustrative, and visually
                      impactful.
                    </p>

                    {/* <Row>
                    <Col lg={6} sm={6}>
                      <div className="footer-left-copyright-area">
                        <p className="footer-mobile-hide mt-3">
                          © CourseWare 2022
                        </p>
                      </div>
                    </Col>
                    <Col lg={6} sm={6}>
                      <div className="footer-right-copyright-area">
                        <ul>
                          <li>
                            <Link to="privecy-policy" className="nav-link">
                              Privacy Policy
                            </Link>
                          </li>
                          <li>
                            <Link to="privecy-policy" className="nav-link">
                              Privacy Policy
                            </Link>
                          </li>
                          <li>
                            <Link to="privecy-policy" className="nav-link">
                              Privacy Policy
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-md-6 mobile-hide-footer">
                <div className="footer-single-item footer-item-get-in-touch">
                  <div className="footer-single-item-title">
                    <h5>Get in Touch</h5>
                  </div>
                  <div className="footer-single-item-content">
                    <span>
                      <span>
                        <i className="bx bx-map"></i>
                      </span>
                      <span>House 15, Road 24, Gulshan - 2 Dhaka - 1212</span>
                    </span>
                    <span>
                      <span>
                        <i className="bx bx-map"></i>
                      </span>
                      <span>
                        Registered Address: Ta-143/A, Moddho Badda (3rd Floor),
                        Gulshan, Badda, Dhaka - 1212
                      </span>
                    </span>
                    <span>
                      <span>
                        <i className="bx bx-envelope"></i>
                      </span>
                      <span>solutionsmaac@gmail.com</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-12">
                <div className="footer-single-item">
                  <div className="footer-single-item-title">
                    <h5>Want some insights?</h5>
                  </div>
                  <div className="footer-single-item-content insights-image">
                    <a href="https://maacsolutions.com/insights">
                      <img src={insight} alt="" />
                    </a>
                    {/* <label>Your Email</label>
                  <input type="email" placeholder="Enter Your Email" />
                  <button>Subscribe</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom-main-content-wrap">
        <Container>
          <div className="ssl-commerce-payment-bank-list">
            <img src={sslCommercepayIcon} alt="" />
          </div>
        </Container>
        <hr />
        <Container>
          <Row>
            <div className="col-12">
              <div className="copy-right">
                <Row>
                  <Col sm={6} className="my-auto">
                    <div className="footer-left-copyright-area">
                      <p>© CourseWare {new Date().getFullYear()}</p>
                    </div>
                  </Col>
                  <Col sm={6} className="my-auto">
                    <div className="footer-right-copyright-area">
                      <ul>
                        <li>
                          <Link to="/privecy-policy">Privacy Policy</Link>
                        </li>{" "}
                        <li>
                          <Link to="/term-and-condition">
                            Terms & Conditions
                          </Link>
                        </li>{" "}
                        <li>
                          <Link to="/refund-return-policy">
                            Refund and Return Policy
                          </Link>
                        </li>{" "}
                        <li>
                          <Link to="/about-us">About Us</Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default MainFooter;
