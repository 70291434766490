export const FormatData = [
  {
    id: 1,
    Email: "email@example.com",
    Slug: "03",
    AcademicAreaName: "Dipto Nath",
    CategoryName: "Games",
  },
  {
    id: 2,
    Email: "email@example.com",
    Slug: "03",
    AcademicAreaName: "Dipto Nath",
    CategoryName: "Simulation",
  },
  {
    id: 3,
    Email: "email@example.com",
    Slug: "03",
    AcademicAreaName: "Dipto Nath",
    CategoryName: "Case Studies",
  },
  {
    id: 4,
    Email: "email@example.com",
    Slug: "03",
    AcademicAreaName: "Dipto Nath",
    CategoryName: "Multimedia",
  },
  {
    id: 5,
    Email: "email@example.com",
    Slug: "03",
    AcademicAreaName: "Dipto Nath",
    CategoryName: "Key Concepts",
  },
  {
    id: 6,
    Email: "email@example.com",
    Slug: "03",
    AcademicAreaName: "Dipto Nath",
    CategoryName: "PDF/PPT",
  },
  {
    id: 7,
    Email: "email@example.com",
    Slug: "03",
    AcademicAreaName: "Dipto Nath",
    CategoryName: "Lecture Series",
  },
  {
    id: 8,
    Email: "email@example.com",
    Slug: "03",
    AcademicAreaName: "Dipto Nath",
    CategoryName: "Lecture Series",
  },
  {
    id: 9,
    Email: "email@example.com",
    Slug: "03",
    AcademicAreaName: "Dipto Nath",
    CategoryName: "Teachers note",
  },
];
