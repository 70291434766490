import { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import {
  getStudentAnswer,
  getTotalUser,
  getUser,
  storeTotalMarksData,
  updateTotalMarks,
} from "../../../../../store/actions";
import View from "../../../img/eye.svg";
import Pagination from "../../Common/Pagination";
const StudentInfoBody = ({ history }) => {
  let dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageRange, setPageRange] = useState(10);
  const [totalMarks, setTotalMarks] = useState(0);
  const [examId, setExamId] = useState("");
  const [key, setKey] = useState("");
  const [objData, setObjData] = useState({});
  const {
    token,
    lafargeTotalUserData,
    lafargeUserData,
    loading,
    lafargeStudentAnswerData,
    editTotalMarksInfo,
  } = useSelector((state) => ({
    token: state.Login.token,
    loading: state.lafargeReducer.loading,
    lafargeTotalUserData: state.lafargeReducer.lafargeTotalUserData,
    lafargeUserData: state.lafargeReducer.lafargeUserData,
    lafargeStudentAnswerData: state.lafargeReducer.lafargeStudentAnswerData,
    editTotalMarksInfo: state.lafargeReducer.editTotalMarksInfo,
  }));
  let num =
    (lafargeTotalUserData?.submitted + lafargeTotalUserData?.reviewedAnswer) %
    10;
  let total =
    (lafargeTotalUserData?.submitted +
      lafargeTotalUserData?.reviewedAnswer -
      num) /
    10;

  let totalPageNumber = total < 1 ? 1 : num == 0 ? total : total + 1;
  useEffect(() => {
    dispatch(getTotalUser(token));
  }, []);
  useEffect(() => {
    dispatch(getUser(token, (currentPage - 1) * 10));
  }, [currentPage]);

  const handleUserDetail = (data) => {
    setShow(true);
    setExamId(data?._id);
    dispatch(getStudentAnswer(token, data?._id));
  };
  async function handleSubmit(e, data) {
    e.preventDefault();

    let obj = {};
    obj.mark = objData?.[key]?.score ? objData?.[key]?.score : data?.score;
    // obj.mark = totalMarks;
    console.log("ppppp", obj.mark);
    dispatch(
      updateTotalMarks(token, examId, obj, history, (currentPage - 1) * 10)
    );
    setShow(false);
  }

  const handleEditMark = (data, id) => {
    setKey(id);
    dispatch(storeTotalMarksData("singleMark", data, id));
  };
  console.log("editTotalMarksInfo", editTotalMarksInfo);

  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>Game</h3>
            <p>Course</p>
          </div>
        </div>
        <div className="packge-header-right">
          <div className="d-flex justify-content-center align-items-center">
            {/* <div className="header-card me-4">
              <span>Total Student</span>
              <p className="mt-1">760</p>
            </div> */}
            <div className="header-card me-4">
              <span>Total Submitted</span>
              <p className="mt-1">
                {lafargeTotalUserData?.submitted +
                  lafargeTotalUserData?.reviewedAnswer}
              </p>
            </div>
            <div className="header-card">
              <span>Total Review</span>
              <p className="mt-1">{lafargeTotalUserData?.reviewedAnswer}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="package-main-content-list table-responsive">
        <Table borderless>
          <thead className="package-main-content-list-table-header">
            <tr>
              <th style={{ width: "20%" }}>#</th>
              <th style={{ width: "40%" }}> Email</th>
              <th style={{ width: "27%" }}>Result</th>
              {/* <th>Course Count </th> */}
              {/* <th>Slug</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr style={{ width: "100%" }}>
                <div
                  className="text-center my-5 py-5 d-flex justify-content-center w-100 h-100"
                  style={{ width: "100%" }}
                >
                  <div>
                    <Spinner animation="border" variant="primary" />
                  </div>
                </div>
              </tr>
            ) : lafargeUserData?.length > 0 ? (
              lafargeUserData?.map((data, idx) => (
                <tr key={idx}>
                  <th scope="row" style={{ paddingLeft: "25px" }}>
                    {idx + 1 + (currentPage - 1) * 10}
                  </th>
                  <td>{data?.student?.email}</td>
                  <td>
                    {data?.status == 4
                      ? "Not checked"
                      : data?.score
                      ? data?.score
                      : "0"}
                  </td>
                  <td>
                    <div className="package-control-action-area">
                      <button
                        className="btn btn-outline-secondary"
                        onClick={() => {
                          handleUserDetail(data);
                          handleEditMark(data, idx);
                        }}
                      >
                        <img
                          src={View}
                          alt="Icon"
                          // onClick={handleUser(data.student._id)}
                          // onClick={() =>
                          //   history.push(
                          //     `/admin/lafarz/view-answer-student/${data.student._id}`
                          //   )
                          // }
                        />{" "}
                        View
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>No data available</tr>
            )}
          </tbody>
        </Table>

        <div className="">
          <Modal
            show={show}
            onHide={handleClose}
            className="student_modal"
            size="xl"
            // style={{ width: "1400px !important" }}
            // dialogClassName="my-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title className="">Answer Scripts</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Row>
                  <Col lg="3">
                    {/* <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="Text" placeholder="Enter name" />
                  </Form.Group> */}
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={lafargeStudentAnswerData?.student?.email}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Phone No.</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Enter phone"
                        value={lafargeStudentAnswerData?.student?.phoneNumber}
                      />
                    </Form.Group>

                    <div
                      className="embed-responsive embed-responsive-16by9"
                      style={{ height: "267px" }}
                    >
                      {/* <iframe
                      className="embed-responsive-item"
                        src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0"
                        allowfullscreen
                      ></iframe> */}
                      {loading ? (
                        <div>
                          <Spinner animation="border" variant="primary" />
                        </div>
                      ) : (
                        <video
                          width="100%"
                          height="100%"
                          className="h-100"
                          style={{
                            objectFit: "contain",
                          }}
                          controls
                          autoPlay
                        >
                          <source
                            src={`${
                              process.env.REACT_APP_IMAGE_URL
                            }${lafargeStudentAnswerData?.ten?.answer
                              ?.split("/")
                              .pop()}`}
                          />
                        </video>
                      )}
                    </div>
                  </Col>
                  <Col lg="3">
                    {" "}
                    <Form.Group className="mb-3">
                      <Form.Label>Question 1</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Question"
                        value={lafargeStudentAnswerData?.one?.question}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Question 2</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Question"
                        value={lafargeStudentAnswerData?.two?.question}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Question 3</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Question"
                        value={lafargeStudentAnswerData?.three?.question}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Question 4</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Question"
                        value={lafargeStudentAnswerData?.four?.question}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Question 5</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Question"
                        value={lafargeStudentAnswerData?.five?.question}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Question 6</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Question"
                        value={lafargeStudentAnswerData?.six?.question}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Question 7</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Question"
                        value={lafargeStudentAnswerData?.seven?.question}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Question 8</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Question"
                        value={lafargeStudentAnswerData?.eight?.question}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Question 9</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Question"
                        value={lafargeStudentAnswerData?.nine?.question}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3">
                    <Form.Group className="mb-3">
                      <Form.Label>Answer 1</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Answer"
                        value={lafargeStudentAnswerData?.one?.answer}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Answer 2</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Answer"
                        value={lafargeStudentAnswerData?.two?.answer}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Answer 3</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Answer"
                        value={lafargeStudentAnswerData?.three?.answer}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Answer 4</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Answer"
                        value={lafargeStudentAnswerData?.four?.answer}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Answer 5</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Answer"
                        value={lafargeStudentAnswerData?.five?.answer}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Answer 6</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Answer"
                        value={lafargeStudentAnswerData?.six?.answer}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Answer 7</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Answer"
                        value={lafargeStudentAnswerData?.seven?.answer}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Answer 8</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Answer"
                        value={lafargeStudentAnswerData?.eight?.answer}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Answer 9</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Answer"
                        value={lafargeStudentAnswerData?.nine?.answer}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="3">
                    <Form.Group className="mb-3">
                      <Form.Label>Right Answer 1</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Right answer"
                        value={lafargeStudentAnswerData?.one?.rightAnswer}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Right Answer 2</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Right answer"
                        value={lafargeStudentAnswerData?.two?.rightAnswer}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Right Answer 3</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Right answer"
                        value={lafargeStudentAnswerData?.three?.rightAnswer}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Right Answer 4</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Right answer"
                        value={lafargeStudentAnswerData?.four?.rightAnswer}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Right Answer 5</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Right answer"
                        value={lafargeStudentAnswerData?.five?.rightAnswer}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Right Answer 6</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Right answer"
                        value={lafargeStudentAnswerData?.six?.rightAnswer}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Right Answer 7</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Right answer"
                        value={lafargeStudentAnswerData?.seven?.rightAnswer}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Right Answer 8</Form.Label>
                      <Form.Control
                        type="Text"
                        placeholder="Right answer"
                        value={lafargeStudentAnswerData?.eight?.rightAnswer}
                      />
                    </Form.Group>
                    {/* <Form.Group className="mb-3">
                    <Form.Control
                      type="Text"
                      placeholder="Right answer"
                      value={lafargeStudentAnswerData?.nine?.rightAnswer}
                    />
                  </Form.Group> */}

                    <Form.Group className="mb-3">
                      <Form.Label>Total Marks:</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Marks"
                        // onChange={(e) => setTotalMarks(e.target.value)}
                        onChange={(e) => {
                          setTotalMarks(e.target.value);
                          setObjData({
                            ...objData,
                            [key]: {
                              ...objData[key],
                              score: e.target.value,
                            },
                          });
                        }}
                        defaultValue={editTotalMarksInfo?.[key]?.data?.score}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div className="text-end mt-4">
                  <Button
                    variant="success"
                    type="submit"
                    onClick={(e) =>
                      handleSubmit(e, editTotalMarksInfo?.[key].data)
                    }
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        </div>
      </div>
      <Pagination
        parentClass={`paginationContainer text-right py-3`}
        reInitialize="reInitialize"
        page={totalPageNumber}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default StudentInfoBody;
