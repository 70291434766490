import React from "react";
import MAPMain from "../map/MAPMain";

const Forecast = () => {
  return (
    <>
      <div className="show-forecast-full-area-wrap">
        <MAPMain />
      </div>
    </>
  );
};

export default Forecast;
