import React from "react";
import ReactApexChart from "react-apexcharts";

const NewGraph = ({ datas }) => {
  console.log(datas);
  const series = [
    {
      name: "NH Hoteles",
      data:
        datas.length < 1 ? [45, 47, 50, 22] : [datas[0], datas[1], datas[2]],
    },
    {
      name: "Sol Melia",
      data:
        datas.length < 1 ? [25, 37, 48, 41] : [datas[4], datas[5], datas[6]],
    },
  ];

  const options = {
    chart: {
      height: 400,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    colors: ["#A589DF", "#BDD1F9"],
    dataLabels: {
      enabled: false,
    },

    stroke: {
      width: 3,
      curve: "smooth",
    },
    xaxis: {
      categories: ["2007", "2008", "2009", "2010"],
    },
    // title: {
    //   text: "Page Statistics",
    //   align: "left",
    // },
    grid: {
      show: true,
      borderColor: "#a6b0cf4d",
      strokeDashArray: 0,
      position: "back",
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      row: {
        colors: undefined,
        opacity: 0.5,
      },
      column: {
        colors: undefined,
        opacity: 0.5,
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 10,
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      tooltipHoverFormatter: function (val, opts) {
        return (
          val +
          " - " +
          opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
          ""
        );
      },
    },
  };

  return (
    <div>
      <div className="comparative-ratio-graph">
        <p>
          Click on the ratio that you would like to see graphically, remember
          that you can only see one of them at a time.
        </p>
      </div>
      <div className="comparative-ratio-graph-box">
        <ReactApexChart options={options} series={series} height={400} />
      </div>
    </div>
  );
};

export default NewGraph;
