import React from "react";
import { Col, Row } from "react-bootstrap";
import teacherPart from "../assets/image/X.png";
import logoCali from "./img/logo.svg";
const CalibraX = () => {
  return (
    <div>
      <Row>
        <Col lg={6}>
          <div className="teacher-single-item text-center">
            <img src={teacherPart} alt="" />
          </div>
        </Col>
        <Col lg={6} className="my-auto">
          <div className="teacher-single-item">
            <h4>
              {" "}
              <img src={logoCali} alt="" /> Games Zone
            </h4>
            <p>
              Want to see the real time impact of your business decisions at
              critical times? Here it is. This game zone lets you know the
              competition effect, their moves and thus you navigate your team
              towards success. Want to try?
            </p>
            <div className="business-cases-bottom-button-area -service-area-page">
              <a
                href="https://calibrax.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                CalibraX
              </a>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CalibraX;
