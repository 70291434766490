const Contept2 = () => {
  return (
    <svg
      width="86"
      height="86"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2038_35710)">
        <path
          d="M43.0003 78.8332C23.292 78.8332 7.16699 62.7082 7.16699 42.9998C7.16699 23.2915 23.292 7.1665 43.0003 7.1665C62.7087 7.1665 78.8337 23.2915 78.8337 42.9998C78.8337 62.7082 62.7087 78.8332 43.0003 78.8332ZM43.0003 10.7498C25.0837 10.7498 10.7503 25.0832 10.7503 42.9998C10.7503 60.9165 25.0837 75.2498 43.0003 75.2498C60.917 75.2498 75.2503 60.9165 75.2503 42.9998C75.2503 25.0832 60.917 10.7498 43.0003 10.7498Z"
          fill="black"
        />
        <path
          d="M42.9997 17.9167C41.9247 17.9167 41.208 17.2 41.208 16.125V1.79167C41.208 0.716667 41.9247 0 42.9997 0C44.0747 0 44.7913 0.716667 44.7913 1.79167V16.125C44.7913 17.2 44.0747 17.9167 42.9997 17.9167Z"
          fill="black"
        />
        <path
          d="M42.9997 17.9167C41.9247 17.9167 41.208 17.2 41.208 16.125V1.79167C41.208 0.716667 41.9247 0 42.9997 0C44.0747 0 44.7913 0.716667 44.7913 1.79167V16.125C44.7913 17.2 44.0747 17.9167 42.9997 17.9167Z"
          fill="black"
        />
        <path
          d="M84.208 44.7918H69.8747C68.7997 44.7918 68.083 44.0752 68.083 43.0002C68.083 41.9252 68.7997 41.2085 69.8747 41.2085H84.208C85.283 41.2085 85.9997 41.9252 85.9997 43.0002C85.9997 44.0752 85.283 44.7918 84.208 44.7918Z"
          fill="black"
        />
        <path
          d="M42.9997 86.0002C41.9247 86.0002 41.208 85.2835 41.208 84.2085V69.8752C41.208 68.8002 41.9247 68.0835 42.9997 68.0835C44.0747 68.0835 44.7913 68.8002 44.7913 69.8752V84.2085C44.7913 85.2835 44.0747 86.0002 42.9997 86.0002Z"
          fill="black"
        />
        <path
          d="M16.125 44.7918H1.79167C0.716667 44.7918 0 44.0752 0 43.0002C0 41.9252 0.716667 41.2085 1.79167 41.2085H16.125C17.2 41.2085 17.9167 41.9252 17.9167 43.0002C17.9167 44.0752 17.2 44.7918 16.125 44.7918Z"
          fill="black"
        />
        <path
          d="M43 53.75C36.9083 53.75 32.25 49.0917 32.25 43C32.25 36.9083 36.9083 32.25 43 32.25C49.0917 32.25 53.75 36.9083 53.75 43C53.75 49.0917 49.0917 53.75 43 53.75ZM43 35.8333C39.0583 35.8333 35.8333 39.0583 35.8333 43C35.8333 46.9417 39.0583 50.1667 43 50.1667C46.9417 50.1667 50.1667 46.9417 50.1667 43C50.1667 39.0583 46.9417 35.8333 43 35.8333Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2038_35710">
          <rect width="86" height="86" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Contept2;
