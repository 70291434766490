import
    {
        GET_NUMBERS_OF_ALL_DATA,
        GET_NUMBERS_OF_ALL_DATA_SUCCESS,
        GET_RECENT_COURSES_BY_ADMIN,
        GET_RECENT_COURSES_BY_ADMIN_SUCCESS,
        GET_POPULAR_COURSES_BY_ADMIN,
        GET_POPULAR_COURSES_BY_ADMIN_SUCCESS,
        GET_CATEGORIES_BY_ADMIN,
        GET_CATEGORIES_BY_ADMIN_SUCCESS,
        GET_STUDENT_ENROLLMENT_DATA,
        GET_STUDENT_ENROLLMENT_DATA_SUCCESS,
        GET_ORDER_LIST,
        GET_ORDER_LIST_SUCCESS,
        CREATE_COUPON,
        CREATE_COUPON_FAIL,
        CREATE_COUPON_SUCCESS,
        GET_COUPONS,
        GET_COUPONS_SUCCESS,
        DELETE_COUPON,
        DELETE_COUPON_SUCCESS,
        DELETE_COUPON_FAIL,
        UPDATE_COUPON,
        UPDATE_COUPON_SUCCESS,
        UPDATE_COUPON_FAIL,
        GET_COUPON_BY_USER,
        GET_COUPON_BY_USER_SUCCESS
    } from "./actionTypes"

const initialState = {
    adminDashboardLeftSide: {},
    recentCourses: [],
    popularCourses: [],
    categoreis: [],
    enrolmentData: {},
    coupons : [],
    loading: false,
    message: "",
    error: "",
    coupon: []
}


const adminDashboardPage = (state = initialState, action) =>
{
    switch (action.type) {
        case GET_NUMBERS_OF_ALL_DATA:
            state = { ...state, loading: true }
            break
        
        case GET_NUMBERS_OF_ALL_DATA_SUCCESS:
            state = { ...state, loading: false, adminDashboardLeftSide: action.payload.data }
            break
        case GET_RECENT_COURSES_BY_ADMIN:
            state = { ...state, loading: true }
            break
        case GET_RECENT_COURSES_BY_ADMIN_SUCCESS:
            state = { ...state, loading: false, recentCourses: action.payload.data }
            break
        case GET_POPULAR_COURSES_BY_ADMIN:
            state = { ...state, loading: true }
            break
        case GET_POPULAR_COURSES_BY_ADMIN_SUCCESS:
            state = { ...state, loading: false, popularCourses: action.payload.data }
            break
        
        case GET_CATEGORIES_BY_ADMIN:
            state = { ...state, loading: true }
            break
        case GET_CATEGORIES_BY_ADMIN_SUCCESS:
            state = { ...state, loading: false, categoreis: action.payload.data }
            break
        case GET_STUDENT_ENROLLMENT_DATA:
            state = { ...state, loading: true }
            break
        
        case GET_STUDENT_ENROLLMENT_DATA_SUCCESS:
            state = { ...state, loading: false, enrolmentData: action.payload.data }
            break
         
        
        case CREATE_COUPON:
            state = { ...state, loading: true }
            break
        case CREATE_COUPON_SUCCESS:
            state = { ...state, loading: false, message: action.payload.message }
            break
        case CREATE_COUPON_FAIL:
            state = { ...state, loading: false, error: action.payload.message }
            break
        case GET_COUPONS:
            state = { ...state, loading: true }
            break
        case GET_COUPONS_SUCCESS:
            state = { ...state, loading: false, coupons: action.payload.data }
            break
        case CREATE_COUPON:
            state = { ...state, loading: true }
            break
        case CREATE_COUPON_SUCCESS:
            state = { ...state, loading: false, message: action.payload.message }
            break
        case CREATE_COUPON_FAIL:
            state = { ...state, loading: false, message: action.payload.message }
            break
        case DELETE_COUPON:
            state = { ...state, loading: true }
            break
        case DELETE_COUPON_SUCCESS:
            state = { ...state, loading: false, message: action.payload.message }
            break
        case DELETE_COUPON_FAIL:
            state = { ...state, loading: false, error: action.payload.message }
            break
        case UPDATE_COUPON:
            state = { ...state, loading: true }
            break
        case UPDATE_COUPON_SUCCESS:
            state = { ...state, loading: false, message: action.payload.message }
            break
        case UPDATE_COUPON_FAIL:
            state = { ...state, loading: false, error: action.payload.message }
            break
        case GET_COUPON_BY_USER:
            state = {
                ...state, loading: true
            }
            break
        case GET_COUPON_BY_USER_SUCCESS:
            state = {
                ...state,
                loading: false,
                coupon: action.payload.data
            }
            break
            default:
                state = state
            break 
    }
    return state
}

export default adminDashboardPage