import { Col, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { Spinner } from "reactstrap";

const AuthorListEditBody = ({
  authorName,
  phoneNumber,
  courseAcademicIds,
  description,
  handleSetDescription,
  email,
  handleSetEmail,
  handleSetAcademicIds,
  handleSetPhoneNumber,
  hanldeUpdateAuthorName,
  hanldeUpdateAuthorData,
  multiSelectorData,
  loading,
  author,
  photo,
  handleUploadAuthorImage,
  courseFormatIds,
  handleSetCategoryFormatIds,
  updatedPhoto,
}) => {
  // console.log("author ", authorName)
  let dispatch = useDispatch();
  let history = useHistory();
  //  let [courseAcademicIds, setCourseAcademicId] = useState([])
  // let [photo, setPhoto] = useState("")
  // let [courseFormatIds, setCourseFormatIds] = useState([])

  const handleSubmitData = () => {};
  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>Author Update</h3>
            {/* {JSON.stringify(courseAcademicIds)} */}
            <p>
              Course list<i className="bx bx-chevron-right"></i>{" "}
              <Link to="/dashboard-author-area">Author</Link>{" "}
              <i className="bx bx-chevron-right"></i>
              <span>Author Update</span>{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="package-main-content-list new-main-area">
        <div className="new-individual-course-inner-wrap">
          <div className="new-individual-course-title">
            <h4> Author Update</h4>
          </div>
          <Form>
            <Row>
              <Col lg={6}>
                <Row className="g-0">
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Author Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Type name"
                          name="categoryName"
                          value={authorName.name}
                          onChange={(e) => hanldeUpdateAuthorName(e)}
                        />
                      </Form.Group>
                    </div>
                  </Col>{" "}
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          style={{ textTransform: "lowercase" }}
                          placeholder="Type email address"
                          name="category"
                          value={email.email}
                          onChange={(e) => handleSetEmail(e)}
                        />
                      </Form.Group>
                    </div>
                  </Col>{" "}
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Course Academic Area</Form.Label>
                        <Select
                          value={courseAcademicIds.obj}
                          isMulti
                          onChange={handleSetAcademicIds}
                          options={multiSelectorData.getAcademicAreas}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Upload image</Form.Label>

                        <img
                          className="autor"
                          src={`${process.env.REACT_APP_IMAGE_URL}${
                            updatedPhoto ? updatedPhoto : photo?.photo
                          }`}
                          height={"70px"}
                        />

                        <Form.Control
                          type="file"
                          placeholder="Type category name"
                          name="category"
                          accept="images/*"
                          onChange={(e) => handleUploadAuthorImage(e)}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </Col>{" "}
              <Col lg={6}>
                <Row className="g-0">
                  {" "}
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Phone No.</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Type phone no."
                          name="category"
                          value={phoneNumber.phone}
                          onChange={(e) => handleSetPhoneNumber(e)}
                        />
                      </Form.Group>
                    </div>
                  </Col>{" "}
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="about author..."
                          name="description"
                          value={description.description}
                          onChange={(e) => handleSetDescription(e)}
                        />
                      </Form.Group>
                    </div>
                  </Col>{" "}
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Course Format</Form.Label>
                        <Select
                          value={courseFormatIds.obj}
                          isMulti
                          onChange={handleSetCategoryFormatIds}
                          options={multiSelectorData?.getcategories}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {loading ? (
              <Spinner />
            ) : (
              <div className="form-submit-button-area-wrap">
                <button
                  type="submit"
                  className="btn create-new-item"
                  onClick={(e) => hanldeUpdateAuthorData(e)}
                >
                  Update Data
                </button>
                {/* <button type="submit" className="btn save-to-new-item-draft">
              Save as Draft
            </button> */}
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AuthorListEditBody;
