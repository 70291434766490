import { combineReducers } from "redux";
import AdminAuthorReducer from "./AdminRelated/author/reducer";
import adminDashboardReducer from "./AdminRelated/dashboard/reducers";
import courseThreeSlice from "./CourseThree/courseSlice";
import ForgetPassword from "./ForgetPassword/reducer";
import lafargeReducer from "./Lafarge/reducers";
import RetailGeneralReducer from "./RetailGame/Course/reducer";
import academicAreaReducer from "./Users/academicArea/reducer";
import cartReducer from "./Users/cart/reducer";
import courseReducer from "./Users/courses/reducer";
import updateAccountReducer from "./Users/updateAccount/actionReducer";
import studentGame from './RetailGame/Student/reducer'
import comparativeAnalysisReducer from "./comparativeAnalysis/reducers";
import housingMarketSlice from "./housingMarket/housingMarketSlice";
import Login from "./login/reducer";
import loginError from "./loginError/reducer";
import recruitReducer from "./recruitment/reducers";
import registration from "./registration/reducer";
const rootReducer = combineReducers({
  recruitReducer,
  comparativeAnalysisReducer,
  Login,
  adminDashboardReducer,
  AdminAuthorReducer,
  registration,
  courseReducer,
  cartReducer,
  academicAreaReducer,
  loginError,
  ForgetPassword,
  updateAccountReducer,
  lafargeReducer,
  gdpCourse: courseThreeSlice,
  studentGame,
  housingMarket: housingMarketSlice,
  retail: RetailGeneralReducer,
});

export default rootReducer;
