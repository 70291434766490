import React, { useEffect } from "react";
import { Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getAuthorsByAdmin } from "../../../../store/actions";
import deleteIcon from "../../img/close-circle.svg";
import editIcon from "../../img/edit-2.svg";
import { FormatData } from "./FormatData";
const AllCourseFrameBody = () => {
  let dispatch = useDispatch();
  let history = useHistory();
  const { token, loading } = useSelector((state) => ({
    token: state.Login.token,
    loading: state.AdminAuthorReducer.loading,
  }));

  useEffect(() => {
    dispatch(getAuthorsByAdmin(token));
  }, []);
  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>All Course Frame List</h3>
            <p>
              Course {`>`} <span>Course Preview</span>{" "}
            </p>
          </div>
        </div>
        <div className="packge-header-right">
          <Link to="/create-course-frame-new">
            <span>+ </span>
            Create New
          </Link>
        </div>
      </div>
      <div className="data-search-and-filtering-area-wrap">
        <div className="data-search-inner-area-jhhbcvhkjzjghfjdn">
          <div className="search-box-dfghjkiujfb">
            <input type="search" name="" id="" />
          </div>
          <div className="select-filtering-area-jyfgvasjyhdfb">
            <Form.Group>
              <Form.Select>
                <option value="">Select name</option>
              </Form.Select>
            </Form.Group>
          </div>
        </div>
      </div>
      <div className="package-main-content-list">
        <Table borderless>
          <thead className="package-main-content-list-table-header">
            <tr>
              <th>#</th>
              <th>Course Title</th>
              <th>Game ID</th>
              <th>Course Category</th>
              <th>Course Format</th>
              <th>Package</th>
              <th>Publish</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {FormatData.map((item, i) => {
              return (
                <tr key={i}>
                  <td>{item.id}</td>
                  <td>{item.CourseTitle}</td>
                  <td>{item.GameID}</td>
                  <td>{item.CourseCategory}</td>
                  <td>{item.CourseFormat}</td>
                  <td>{item.Package}</td>
                  <td>
                    <i className="bx bx-check-circle"></i>
                  </td>
                  <td>
                    <div className="package-control-action-area">
                      <Link to="formate-edit">
                        <img src={editIcon} alt="" /> Edit
                      </Link>
                      <button>
                        <img src={deleteIcon} alt="" />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default AllCourseFrameBody;
