import React from "react";
import { NavLink } from "react-router-dom";
import { useHistory,  useParams} from "react-router-dom";

const ListHeader = () =>
{
  const {courseId} = useParams()
  return (
    <div>
      <div>
        <div className="case-method-info mb-5">
          <NavLink
            to={`/${courseId}/case-method`}
            activeClassName="navbar__link--active "
            className="navbar__link"
          >
            <div className="case-method-text">
              <p>Case Summary</p>
            </div>
          </NavLink>
          <NavLink
            to={`/${courseId}/pyramid-ratio`}
            activeClassName="navbar__link--active"
            className="navbar__link"
          >
            <div className="case-method-text">
              <p>Pyramid of Ratios</p>
            </div>
          </NavLink>
          <NavLink
            to={`/${courseId}/financial-statement`}
            activeClassName="navbar__link--active"
            className="navbar__link"
          >
            <div className="case-method-text">
              <p>Financial statements</p>
            </div>
          </NavLink>
          <NavLink
            to={`/${courseId}/comparative-ratio`}
            activeClassName="navbar__link--active"
            className="navbar__link"
          >
            <div className="case-method-text ">
              <p>Comparative of ratios</p>
            </div>
          </NavLink>

          <NavLink
            to={`/${courseId}/industry-ratio`}
            activeClassName="navbar__link--active"
            className="navbar__link"
          >
            <div className="case-method-text">
              <p>Industry Specific Ratios</p>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default ListHeader;
