import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
const initialState = {
  // prevValues: [],
  newAddedValues: [84, 88, 94, 98, 102, 106, 110, 116],
};
const housingMarketSlice = createSlice({
  name: "housingMarket",
  initialState,
  reducers: {
    addPrediction: (state, action) => {
      if (action?.payload?.index) {
        const newArray = [...state.newAddedValues];
        newArray[action?.payload?.index] = action?.payload?.value;
        state.newAddedValues = newArray;
      }
    },
    clearPredictionData: (state, action) => {
      state.newAddedValues = [84, 88, 94, 98, 102, 106, 110, 116];
    },
  },
});
export const { addPrediction, clearPredictionData } =
  housingMarketSlice.actions;
const persistConfig = {
  keyPrefix: "HM-",
  key: "housingMarket",
  storage,
};

export default persistReducer(persistConfig, housingMarketSlice.reducer);
