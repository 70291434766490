import React, { useEffect, useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// import inputBg from "./img/file.svg";
import Select from "react-select";
import { toast } from "react-toastify";
import { createAcademicArea, getCategoryIds } from "../../../../store/actions";
const AcademicEdit = () => {
  let history = useHistory();
  let dispatch = useDispatch();
  let [category, setCategory] = useState([]);
  let [academicName, setAcademicName] = useState("");
  const { token, loading, categoryIds } = useSelector((state) => ({
    token: state.Login.token,
    loading: state.AdminAuthorReducer.loading,
    categoryIds: state.AdminAuthorReducer.categoryIds,
  }));

  useEffect(() => {
    dispatch(getCategoryIds(token));
  }, []);

  const handleSubmitData = (e) => {
    e.preventDefault();
    console.log("hello data", category, academicName);

    if (!category.length || !academicName) {
      toast.error("Please fill all the field first");
    } else {
      let categoryArr = [];
      category.map((v, i) => {
        categoryArr.push(v.value);
      });

      dispatch(createAcademicArea(token, academicName, categoryArr, history));
    }
  };

  //store category
  const handleStoreCategory = (selectedOption) => setCategory(selectedOption);
  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>New Academic Area</h3>
            <p>
              Course list <i className="bx bx-chevron-right"></i>{" "}
              <Link to={"/academic-area"}> Academic Area </Link>{" "}
              <i className="bx bx-chevron-right"></i>
              <span> Create New Academic Area</span>{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="package-main-content-list kjhgfwertyuiutfc">
        <div className="add-new-format-area-wrap">
          <div className="add-new-format-inner-item-wrap">
            <h4>Create New Academic Area</h4>
            <Form className="format-upload-form-main-area-zkjdh">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Academic name</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Tag name"
                  value={academicName}
                  onChange={(e) => setAcademicName(e.target.value)}
                />
              </Form.Group>
              <div className="input-data-single-field mb-3">
                <Form.Label>Category Id</Form.Label>
                <Select
                  value={category}
                  isMulti
                  options={categoryIds ? categoryIds : ""}
                  onChange={handleStoreCategory}
                />
              </div>
              {/* <Form.Group>
                <Form.Label>Upload user image</Form.Label>
                <div className="button-area-input-data-wrap">
                  <label
                    for="file-upload"
                    // style={{ backgroundImage: `url(${inputBg})` }}
                  >
                    <img src={inputBg} alt="" />
                    Upload Your Data
                  </label>
                  <input type="file" id="file-upload" />
                </div>
              </Form.Group> */}
              <div className="">
                {loading ? (
                  <Spinner />
                ) : (
                  <div className="create-new-format-btn-area-wrap">
                    <button onClick={(e) => handleSubmitData(e)}>
                      Create New Academic Area
                    </button>
                    <button onClick={(e) => history.push("/academic-area")}>
                      Cancel
                    </button>
                  </div>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcademicEdit;
