import {
   
    GET_SIX_COURSES_FOR_STUDENTS,
    GET_SIX_COURSES_FOR_STUDENTS_SUCCESS,
    ORDER_COURSE,
    ORDER_COURSE_FAIL,
    ORDER_COURSE_SUCCESS,
    GET_ALL_COURSES_STD,
    GET_ALL_COURSES_SUCCESS,
 
    GET_STUDENT_OWN_COURSE,
    GET_STUDENT_OWN_COURSE_SUCCESS,
    SUBMIT_MARK,
    SUBMIT_MARK_SUCCESS,
    SUBMIT_MARK_FAIL,
    SHOW_RESULT,
    SHOW_RESULT_SUCCESS,
    COURSE_OWNER_VALID,
    COURSE_OWNER_VALID_FAIL,
    COURSE_OWNER_VALID_SUCCESS,
    GET_RANKING_BASED_ON_COURSE,
    GET_RANKING_BASED_ON_COURSE_SUCCESS,
    VIEW_STUDENTS,
    VIEW_STUDENTS_SUCCESS,
    INSERT_STUDENT_INTO_COURSE,
    INSERT_STUDENT_INTO_COURSE_SUCCESS,
    INSERT_STUDENT_INTO_COURSE_FAIL,
    GET_RANKING_BASED_ON_COURSE_BY_TEACHER,
    GET_RANKING_BASED_ON_COURSE_BY_TEACHER_SUCCESS,
    GIFT_A_COURSE,
    GIFT_A_COURSE_SUCCESS,
    GIFT_A_COURSE_FAIL,
    SEARCH_QUERY,
    SEARCH_QUERY_SUCCESS,
    SEARCH_ON_CATEGORY,
    SEARCH_ON_CATEGORY_SUCCESS,
    REMOVE_STUDENTS_FORM_COURSE,
    REMOVE_STUDENTS_FORM_COURSE_FAIL,
    REMOVE_STUDENTS_FORM_COURSE_SUCCESS
} from "./actionTypes"

export const getSixCoursesForStudents = () => ({
    type: GET_SIX_COURSES_FOR_STUDENTS,
    payload : {}
})

export const getSixCoursesForStudentsSuccess = (data) => ({
    type: GET_SIX_COURSES_FOR_STUDENTS_SUCCESS,
    payload: {data}
})


export const orderCourse = (course,price,totalPrice, token,email,mainPrice, discount,  history) => ({
    type: ORDER_COURSE,
    payload: {course,price,  totalPrice, token,email, mainPrice, discount, history}
})

export const orderCourseSuccess = message => ({
    type: ORDER_COURSE_SUCCESS,
    payload: {message}
})
export const orderCourseFail = message => ({
    type: ORDER_COURSE_FAIL,
    payload: {message}
})

export const getAllCourses = () => ({
    type: GET_ALL_COURSES_STD,
    payload: {}
})

export const getAllCoursesSuccess = data => ({
    type: GET_ALL_COURSES_SUCCESS,
    payload: {data}
})




export const getStudetnOwnCourse = token => ({
    type: GET_STUDENT_OWN_COURSE,
    payload: {token}
})

export const getStudentOwnCourseSuccess = data => ({
    type: GET_STUDENT_OWN_COURSE_SUCCESS,
    payload: {data}
})

export const submitMark = (token, courseId, mark,orderId, history) => ({
    type: SUBMIT_MARK,
    payload: {token, courseId, mark, orderId, history}
})

export const submitMarkSuccess = message => ({
    type: SUBMIT_MARK_SUCCESS,
    payload: {message}
})

export const submitMarkFail = error => ({
    type: SUBMIT_MARK_FAIL,
    payload: {error}
})

export const showResult = (token, courseId) => ({
    type: SHOW_RESULT,
    payload: {token, courseId}
})

export const showResultSuccess = data => ({
    type: SHOW_RESULT_SUCCESS,
    payload: {data}
})

export const courseOwnerValid = (token, courseId, history) => ({
    type: COURSE_OWNER_VALID,
    payload: {token, courseId, history}
})

export const courseOwnerValidSuccess = data => ({
    type: COURSE_OWNER_VALID_SUCCESS,
    payload: {
        data
    }
})

export const courseOwnerValidFail = data => ({
    type: COURSE_OWNER_VALID_FAIL,
    payload: {data}
})

export const getRankingBasedOnCourse = (token,role, courseId) => ({
    type: GET_RANKING_BASED_ON_COURSE,
    payload: {token,role, courseId}
})

export const getRankingBasedOnCourseSuccess = data => ({
    type: GET_RANKING_BASED_ON_COURSE_SUCCESS,
    payload: {data}
})

export const getRankingBasedOnCourseByTeacher = (token, courseId) => ({
    type: GET_RANKING_BASED_ON_COURSE_BY_TEACHER,
    payload: {token, courseId}
})
export const getRankingBasedOnCourseByTeacherSuccess = data => ({
    type: GET_RANKING_BASED_ON_COURSE_BY_TEACHER_SUCCESS,
    payload: {data}
})
export const viewStudents = (token, courseId) => ({
    type: VIEW_STUDENTS,
    payload: {token, courseId}
})



export const viewStudentsSuccess = data => ({
    type: VIEW_STUDENTS_SUCCESS,
    payload: {data}
})

export const insertStudentsIntoCourse = (token, courseId, studentEmail) => ({
    type: INSERT_STUDENT_INTO_COURSE,
    payload: {token, courseId, studentEmail}
})

export const insertStudentsIntoCourseSuccess = message => ({
    type: INSERT_STUDENT_INTO_COURSE_SUCCESS,
    payload: {message}
})

export const isnertStudentsIntoCourseFail = message => ({
    type: INSERT_STUDENT_INTO_COURSE_FAIL,
    payload : {message}
})

export const searchQuery = text => ({
    type: SEARCH_QUERY,
    payload: {text}
})

export const searchOnCategory = categoryIds => ({
    type: SEARCH_ON_CATEGORY,
    payload: {categoryIds}
})

export const searchOnCategorySuccess = data => ({
    type: SEARCH_ON_CATEGORY_SUCCESS,
    payload :{data}
})


export const removeStudentsFromCourse = (token, studentId, courseId) => ({
    type: REMOVE_STUDENTS_FORM_COURSE,
    payload: {token, studentId, courseId}
})

export const removeStudetnsFromCourseSuccess = message => ({
    type: REMOVE_STUDENTS_FORM_COURSE_SUCCESS,
    payload: {message}
})

export const removeStudentsFromCourseFail = error => ({
    type: REMOVE_STUDENTS_FORM_COURSE_FAIL,
    payload: {
        error
    }
})