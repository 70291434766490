import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";

import {
	GET_LAFARGE_COURSE,
	GET_STUDENT_ANSWER,
	GET_TOTAL_USER,
	GET_USER,
	UPDATE_TOTAL_MARKS,
} from "./actionTypes";

import {
	getLafargeCourseSuccess,
	getStudentAnswerSuccess,
	getTotalUserSuccess,
	getUserSuccess,
	updateTotalMarksFail,
	updateTotalMarksSuccess,
} from "./actions";

import {
	axiosGetLafargeCourse,
	axiosGetTotalUser,
	axiosGetUser,
	axiosStudentAnswer,
	axiosUpdateTotalMarks,
} from "../../helpers/api_helper";

//get lafarge courses
const asyncGetLafargeCourseData = async (token) => {
	try {
		const response = await axiosGetLafargeCourse(token);
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};

function* workerGetLafargeCourseData({ payload: { token } }) {
	try {
		let response = yield call(asyncGetLafargeCourseData, token);
		yield put(getLafargeCourseSuccess(response.getData));
	} catch (error) {}
}

//get lafarge users
const asyncGetUserData = async (token, skip) => {
	try {
		const response = await axiosGetUser(token, skip);
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};

function* workerGetUserData({ payload: { token, skip } }) {
	try {
		let response = yield call(asyncGetUserData, token, skip);
		yield put(getUserSuccess(response.getData));
	} catch (error) {}
}

//get lafarge total users
const asyncGetTotalUserData = async (token) => {
	try {
		const response = await axiosGetTotalUser(token);

		return response.data;
	} catch (error) {
		return error.response.data;
	}
};

function* workerGetTotalUserData({ payload: { token } }) {
	try {
		let response = yield call(asyncGetTotalUserData, token);
		yield put(getTotalUserSuccess(response.getData));
	} catch (error) {}
}

//get lafarge total users
const asyncGetStudentAnswerData = async (token, studentId) => {
	try {
		const response = await axiosStudentAnswer(token, studentId);

		return response.data;
	} catch (error) {
		return error.response.data;
	}
};

function* workerGetStudentAnswerData({ payload: { token, studentId } }) {
	try {
		let response = yield call(asyncGetStudentAnswerData, token, studentId);
		yield put(getStudentAnswerSuccess(response.getData));
	} catch (error) {}
}

//update academci Area
const asyncUpdateTotalMarks = async (token, examId, marks) => {
	try {
		const { mark } = marks;
		const response = await axiosUpdateTotalMarks(token, examId, mark);
		return response.data;
	} catch (error) {
		return error.respone.data;
	}
};
function* workerUpdateTotalMarks({
	payload: { token, examId, marks, history, skip },
}) {
	try {
		const response = yield call(
			asyncUpdateTotalMarks,
			token,
			examId,
			marks,
		);
		if (response.status == "success") {
			console.log("skip", skip);
			toast.success(response.message);
			yield put(updateTotalMarksSuccess(response.message));
			let res = yield call(asyncGetUserData, token, skip);
			let respon = yield call(asyncGetTotalUserData, token);
			yield put(getUserSuccess(res.getData));
			yield put(getTotalUserSuccess(respon.getData));

			// history.push("/admin/lafarz/view-users/table?skip= 0");
			yield put(updateTotalMarksSuccess());
		} else {
			yield put(updateTotalMarksFail(response.message));
			toast.error(response.message);
			yield put(updateTotalMarksFail());
		}
	} catch (error) {}
}

function* lafargeSaga() {
	yield takeEvery(GET_LAFARGE_COURSE, workerGetLafargeCourseData);
	yield takeEvery(GET_USER, workerGetUserData);
	yield takeEvery(GET_TOTAL_USER, workerGetTotalUserData);
	yield takeEvery(GET_STUDENT_ANSWER, workerGetStudentAnswerData);
	yield takeEvery(UPDATE_TOTAL_MARKS, workerUpdateTotalMarks);
}

export default lafargeSaga;
