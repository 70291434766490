import React,{useState} from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
 import editIcon from "./img/edit.svg";
import profileImage from "./img/profileImage.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {changePassword, updatePassword, updatePROFILE } from "./../../../store/actions";
import { toast } from 'react-toastify';

const StudentProfileEditPage = () =>
{
  const { token,  loading,isloadingPassword } = useSelector((state) => ({
    token: state.Login.token,
    loading: state.updateAccountReducer.loading,
    isloadingPassword : state.updateAccountReducer.isloadingPass
  }));

  let dispatch = useDispatch()

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")

  let [password, setPassword] = useState("")
  let [newPassword, setNewPassword] = useState("")
  let [confirmPassword, setConfirmPassword] = useState("")
  const handleUpdateProfile = (e) =>
  {
    e.preventDefault()
    console.log("click fromhere")
    dispatch(updatePROFILE(token, firstName,lastName,phoneNumber))    
  }

  //update password
  const handleChangePassword = (e) =>
  {
    e.preventDefault()
     if (!newPassword || !password ) {
      toast.error("Password is not valid")
    }
    if (newPassword.length < 5 || newPassword !== confirmPassword) {
      toast.error("Password length must be more than 5")
    } else {
      
      dispatch(changePassword(token, password, newPassword, confirmPassword) )
    }
  }
  return (
    <div>
      <Container>
        <div className="student-profile-view-main-area">
          <div className="student-profile-title-area">
            <h3>Profile Edit</h3>
          </div>
         {/* <h2>Loading : ...{loading}</h2>  */}
          <Row>
            <Col lg={6}>
              <div className="stident-profile-content-fgbhdnjmk gfhdjskuedj">
                <h3>Profile Information</h3>
                <div className="student-profile-image-area-top-tgbnmkiuh">
                  <div className="profile-image-upload-btn-gfhdjksuej">
                    <label for="profileImage">
                      {/* <img src={editIcon} alt="" /> */}
                    </label>
                    {/* <input type="file" name="profileImage" id="profileImage" /> */}
                    <img src={profileImage} alt="" />
                    <h4>Your Avater</h4>
                  </div>
                </div>
                <div className="student-course-profile-bottom-edfswrkjhg">
                  <h4>Details</h4>
                  <Form>
                    <Row>
                      <Col lg={6}>
                        <div className="student-profile-update-tgbdhsjka">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicFirstName"
                          >
                            <Form.Label>First name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type your first name"
                              value={firstName}
                              onChange={e => setFirstName(e.target.value)}
                            />
                          </Form.Group>
                        </div>
                      </Col>{" "}
                      <Col lg={6}>
                        <div className="student-profile-update-tgbdhsjka">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicLastName"
                          >
                            <Form.Label>Last name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type your last name"
                              value={lastName}
                              onChange={e => setLastName(e.target.value)}
                            />

                          </Form.Group>
                        </div>
                      </Col>{" "}
                      {/* <Col lg={12}>
                        <div className="student-profile-update-tgbdhsjka">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Type your email"
                            />
                          </Form.Group>
                        </div>
                      </Col>{" "} */}
                      <Col lg={12}>
                        <div className="student-profile-update-tgbdhsjka">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicLastName"
                          >
                            <Form.Label>Phone No:</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Type your phone number"
                              value={phoneNumber}
                              onChange ={e => setPhoneNumber(e.target.value)}
                            />
                          </Form.Group>
                        </div>
                      </Col>{" "}
                      <Col lg={6}>
                        <div className="student-profile-update-tgbdhsjka">
                          {loading ? <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
                </Spinner> :
                            <Button variant="primary" type="submit" onClick={e => handleUpdateProfile(e)}>
                            Update
                          </Button>}
                        
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="stident-profile-content-fgbhdnjmk gfhdjskuedj right-gfdhsjkaudhncbcnm">
                <div className="student-course-profile-bottom-edfswrkjhg hjkrhdncbvcnmxjc">
                  <h4>Details</h4>
                  <Form>
                    <Row>
                      <Col lg={12}>
                        <div className="student-profile-update-tgbdhsjka">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicFirstName"
                          >
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={3} />
                             
                          </Form.Group>
                        </div>
                      </Col>{" "}
                       
                      <Col lg={6}>
                        <div className="student-profile-update-tgbdhsjka mt-2">
                          <Button variant="primary" type="submit">
                            Update
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>{" "}
              <div className="stident-profile-content-fgbhdnjmk gfhdjskuedj right-gfdhsjkaudhncbcnm">
                <div className="student-course-profile-bottom-edfswrkjhg hjkrhdncbvcnmxjc">
                  <h4>Change password</h4>
                  <Form>
                    <Row>
                      <Col lg={12}>
                        <div className="student-profile-update-tgbdhsjka">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicFirstName"

                          >
                            <Form.Label>Current Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Current Password"
                              value={password}
                              onChange={e => setPassword(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </div>
                      </Col>{" "}
                      <Col lg={6}>
                        <div className="student-profile-update-tgbdhsjka">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicLastName"
                          >
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Type your last name"
                              value={newPassword}
                              onChange={e => setNewPassword(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </div>
                      </Col>{" "}
                      <Col lg={6}>
                        <div className="student-profile-update-tgbdhsjka">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Confirm Password"
                              value={confirmPassword}
                              onChange={e => setConfirmPassword(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </div>
                      </Col>{" "}\<div className="student-profile-update-tgbdhsjka mt-2">
                      {isloadingPassword ? <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
                </Spinner> :
                            <Button variant="primary" type="submit" onClick={e => handleChangePassword(e)}>
                            Update
                          </Button>}
                          </div>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default StudentProfileEditPage;
