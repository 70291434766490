import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "../../assests/styles.css";
import {
  addCostOfFinance,
  calculateBudgeting,
  courseOwnerValid,
} from "../../store/actions";

const Budgeting = () => {
  let dispatch = useDispatch();
  let history = useHistory();

  const { budget, costofFinnace, token } = useSelector((state) => ({
    budget: state.recruitReducer.budget,
    costofFinnace: state.recruitReducer.costofFinnace,
    token: state.Login.token,
  }));
  let { courseId } = useParams();
  const handleSubmitProdCost = (e, prodType) => {
    const keyCode = e.keyCode || e.which;
    if (keyCode == 13) {
      if (e.target.value > 100) {
        toast.error("Capacity utilization can't exceed 100");
      } else {
        dispatch(calculateBudgeting(e.target.value, prodType));
      }
    }
  };

  const handleSubmitCostOfFinance = (e,cost, financeType) =>
  {
    const keyCode = e.keyCode || e.which;
console.log("hello data ", financeType)
    if (keyCode == 13) {
      console.log("cost type" , financeType)
      if (financeType == "costOfFinanceReal1") {
        if (cost > 100) {
          toast.error("can't enter more than 100");
        } else {
          dispatch(addCostOfFinance(cost, financeType));

        }
      }
     else if (!localStorage.getItem("prodOne") ) {
        toast.error("Please enter the production cost In Product A first")
        } else if ( !localStorage.getItem("prodTwo")) {
          toast.error("Please enter the production cost In Product B first")
      } else if (cost > 100) {
          toast.error("can't enter more than 100");
        } else {
          dispatch(addCostOfFinance(cost, financeType));
        }  
    }
    
  };

  useEffect(() => {
    dispatch(courseOwnerValid(token, courseId, history));
  }, []);

  const handleClick = e =>
  {
    
    if (!localStorage.getItem("costOfFinanceReal1")) {
      toast.error("Cost of finance in month one must have a value")
    } else if (!localStorage.getItem("costOfFinanceReal2")) {
      toast.error("Cost of finance in month Two must have a value")

    } else {
      history.push(`/${courseId}/revenue`)
    }
    
    // 
  }
  return (
    <div>
      <div className="container">
        <div className="recruitment-info">
          <h4>Budgeting</h4>
          <h5>
            You need to recruit 3 managers, one HR manager, and one BDM, and 1
            Sales manager in your team. For that you have only 5 BDT budget, and
            sales manager can only have salaries in sales, no fixed amount
            therefore. And CEO’s salary is 3 BDT
          </h5>
        </div>
        <div>
          <table className="table budget-table table-borderless">
            <thead>
              <tr className="budget-table-data">
                <th scope="col">Budget of Bangladesh</th>
                <th scope="col">Month 1</th>
                <th scope="col">Month 2</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Recruitment</td>
                <td className="recruitment-input">
                  <p>{localStorage.getItem("totalOpex")}</p>
                </td>
                <td className="recruitment-input">
                  <p>{localStorage.getItem("totalOpex")}</p>
                </td>
              </tr>
              <tr>
                <td>Production Cost _ product A</td>
                <td className="recruitment-input">
                  <p>1050</p>
                </td>
                <td className="recruitment-input">
                  <div className="table-data-inner-area-wrap-hukdhvb">
                    <input
                      type="number"
                      className="budget-data"
                      placeholder="0"
                      onKeyPress={(e) => handleSubmitProdCost(e, "prodOne")}
                    />
                    <p>
                      {" "}
                      {budget?.prodCostOne
                        ? budget.prodCostOne
                        : localStorage.getItem("prodOne")}
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Production Cost _ product B</td>
                <td className="recruitment-input">
                  <p>880</p>
                </td>
                <td className="recruitment-input">
                  <div className="table-data-inner-area-wrap-hukdhvb">
                    <input
                      type="number"
                      className="budget-data"
                      placeholder="0"
                      onKeyPress={(e) => handleSubmitProdCost(e, "prodTwo")}
                    />
                    <p>
                      {" "}
                      {budget?.prodCostTwo
                        ? budget.prodCostTwo
                        : localStorage.getItem("prodTwo")}
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Overheads</td>
                <td className="recruitment-input">
                  <p>50</p>
                </td>
                <td className="recruitment-input">
                  <p>50</p>
                </td>
              </tr>
              <tr>
                <td>Launch and marketing cost</td>
                <td className="recruitment-input">
                  <p>15</p>
                </td>
                <td className="recruitment-input">
                  <p>10</p>
                </td>
              </tr>
              <tr>
                <td>Cost of finance</td>
                <td>
                  <div className="table-data-inner-area-wrap-hukdhvb">
                    <input
                      type="number"
                      className="budget-data"
                      onKeyPress={(e) =>
                        handleSubmitCostOfFinance(
                          e,e.target.value,
                          "costOfFinanceReal1"
                        )
                      }
                    />
                    <p>
                      {" "}
                      {costofFinnace?.costOfFinanceOne
                        ? costofFinnace?.costOfFinanceOne
                        : localStorage.getItem("costOfFinanceReal1")
                        ? localStorage.getItem("costOfFinanceReal1")
                        : 0}{" "}
                      %
                    </p>
                  </div>
                </td>
                <td>
                  <div className="table-data-inner-area-wrap-hukdhvb">
                    <input
                      type="number"
                      className="budget-data"
                      max={100}
                      onKeyPress={(e) =>
                        handleSubmitCostOfFinance(
                          e,
                          e.target.value,
                          "costOfFinanceReal2"
                        )
                      }
                      // value={
                      // 	budget?.costOfFinanceTwo
                      // 		? budget?.costOfFinanceTwo
                      // 		: localStorage.getItem(
                      // 				"costOfFinanceTwo",
                      // 		  )
                      // 		? localStorage.getItem(
                      // 				"costOfFinanceTwo",
                      // 		  )
                      // 		: 0
                      // }
                    />
                    <p>
                      {costofFinnace?.costOfFinanceTwo
                        ? costofFinnace?.costOfFinanceTwo
                        : localStorage.getItem("costOfFinanceReal2")
                        ? localStorage.getItem("costOfFinanceReal2")
                        : 0}
                      %
                    </p>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Total cost</td>
                <td className="recruitment-input">
                  <p>
                    {costofFinnace?.totalProdCostMonthOne
                      ? costofFinnace?.totalProdCostMonthOne
                      : localStorage.getItem("totalProdCostMonthOne")
                      ? localStorage.getItem("totalProdCostMonthOne")
                      : 0}
                  </p>
                </td>
                <td className="recruitment-input">
                  <p>
                    {costofFinnace?.totalProdCostMonthTwo
                      ? costofFinnace?.totalProdCostMonthTwo
                      : localStorage.getItem("totalProdCostMonthTwo")
                      ? localStorage.getItem("totalProdCostMonthTwo")
                      : 0}
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="case-summery-btn">
            <Link to={`/${courseId}/recruitment`}>
              <div>
                <button className="case-summery-previous-btn">Previous</button>
              </div>
            </Link>
            <Link >
              <div>
                <button className="case-summery-next-btn" onClick={e => handleClick(e)}>Next</button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Budgeting;
