import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import laptopIcon2 from "./img/diagram.svg";
// import { useHistory } from "react-router-dom";
const BusinessClass = ({ courses }) => {
  let history = useHistory();
  return (
    <div className="business-cases-main-area">
      <Container>
        <div className="business-mases-inner-area">
          <div className="business-cases-area-title">
            {/* <h3>BUSINESS CASES AND SIMULATIONS VIEW</h3> */}
            <h3>Business cases and simulations view</h3>
          </div>
          <div className="business-cases-service-area">
            <Row>
              {courses?.map((v, i) => (
                <Col lg={4}>
                  <div
                    className="business-clases-single-item-wrap"
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}${v.image})`,
                    }}
                  >
                    <div className="business-clases-main-part">
                      <div className="business-clases-main-part-top">
                        <h3>{v.courseName}</h3>
                        <i>{v.code}</i>
                      </div>
                      <div className="business-clases-main-part-bottom">
                        <i>
                          {v?.academicArea?.map((v1, i) => (
                            <span>{v1.academicName} { v?.academicArea?.length -1 ==i ? "" : "|" }  </span>
                          ))}
                        </i>

                        <h4>{v.courseName}</h4>
                        <div className="business-clases-main-part-bottom-inner">
                          <div className="business-clases-main-part-bottom-inner-left">
                            {/* <img src={laptopIcon2} alt="" /> */}
                            <img  className="course-homeIcon-abcd" src={`${process.env.REACT_APP_IMAGE_URL}${v?.category[0]?.image}`} alt="" />

                          </div>{" "}
                          <div className="business-clases-main-part-bottom-inner-left">
                            <h6>
                              <span>Format: </span>{" "}
                              {v?.category?.map((v1, i) => (
                                <> {v1.categoryName} { v?.category?.length -1 ==i ? "" : "|" }  </>
                              ))}
                            </h6>{" "}
                            <h6>
                              <span>Language: </span> {v?.language}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-clases-hover-part">
                      <div className="">
                        <p>
                          {v.description && v.description.substring(0, 100)}...
                        </p>
                        <div className="business-clases-hover-part-bottom-link">
                          <Link to={`/course-details-page/${v._id}`}>
                            <span>+</span>
                            <span>Read More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            <div className="business-cases-bottom-button-area">
              <Link to ="/courses">See More</Link>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BusinessClass;
