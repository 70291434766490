import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import loginBeforeImage from "./img/emptyImage.png";
const EmptyCart = () => {
  return (
    <div>
      <Container>
        <div className="cart-page-inner-main-wrap">
          <div className="cart-header-title">
            <h4>Cart</h4>
          </div>
          <div className="cart-main-area-wrap-cart-body-gfhdjskujcm uyghbjnkiouhjn">
            <span>
              {" "}
              <img src={loginBeforeImage} alt="" />
              <p>Your cart is empty.</p>
              <Link to="/" className="empty-page-shoping-btn">
                Your cart is empty.
              </Link>
            </span>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default EmptyCart;
