import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import MainFooter from "../Common/footer/MainFooter";
import LoginHeader from "../Common/LoginHeader";
import {
  addToCart,
  calculateGift,
  getCouponByUser,
  orderCourse,
} from "./../../../store/actions";
import "./cart.scss";
import CheckoutPageBody from "./CheckoutPageBody";

const ChackoutPage = () => {
  const { token, cart, discount, coupon, finalPrice } = useSelector(
    (state) => ({
      token: state.Login.token,
      cart: state.cartReducer.cart,
      coupon: state.adminDashboardReducer.coupon,

      discount: state.cartReducer.discount,
      finalPrice: state.cartReducer.finalPrice,
    })
  );
  let dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    dispatch(getCouponByUser());
  }, []);
  let [giftUserEmail, setGiftUserEmail] = useState("");
  const updatedetails = (elm, prodtype) => {
    let cart = [];
    if (typeof window !== "undefined") {
      if (localStorage.getItem("cart")) {
        cart = JSON.parse(localStorage.getItem("cart"));
      }
      if (prodtype === "color") {
        cart.map((prod, i) => {
          if (prod._id === elm._id) {
            cart[i].color = elm.target.value;
          }
        });
      } else if (prodtype === "count") {
        let cont = elm;
        if (cont > elm.quantity) {
          toast.error(`Maximum Quantity of this product : ${elm.quantity}`);
          return;
        }

        cart.map((prod, i) => {
          if (prod._id === elm._id) {
            cart[i].count = cont;
          }
        });
      } else if (prodtype === "remove") {
        cart.map((prod, i) => {
          if (prod._id === elm._id) {
            cart.splice(i, 1);
          }
        });
        toast.error(`${elm.courseName} has been removed`);
      }
      // console.log("update cart : ", cart)
      localStorage.setItem("cart", JSON.stringify(cart));
      dispatch(addToCart(cart));
    }
  };

  const handleQuantity = (elm) => {
    let count = "count";
    // updatedetails(elm, count);
    // console.log("Total COunt :", elm);
    cart = JSON.parse(localStorage.getItem("cart"));
    cart.map((prod, i) => {
      if (prod._id === elm._id) {
        cart[i].count = elm;
      }
    });

    dispatch(addToCart(cart));
  };

  const onCreateOrder = (e, course, totalPrice) => {
    e.preventDefault();

    let price = 0;
    course.map((v, i) => {
      price += v.price;
    });

    dispatch(
      orderCourse(course, price, 0, token, giftUserEmail, 0, 0, history)
    );
  };
  const handleRemoveCart = (e, course) => {
    e.preventDefault();
    // console.log("cart id", cartId)

    updatedetails(course, "remove");
  };

  const handleSubmitEmail = (e, email) => {
    e.preventDefault();
    console.log("email user", email);
    setGiftUserEmail(email);
  };

  const calculateCoupon = (e, couponCode) => {
    e.preventDefault();
    console.log("coupon code", couponCode);
    let pr = 0;
    cart?.map((v, i) => {
      pr += v.price * v.count;
    });
    console.log("total price", pr);

    if (coupon?.couponCode == couponCode) {
      let percentage = (pr * coupon?.couponPercentage) / 100;
      pr -= percentage;
      console.log("discount data here", pr, percentage);
      dispatch(calculateGift(percentage, pr));
    }
  };
  return (
    <div>
      <MetaTags>
        <title>Checkout | GameProject</title>
      </MetaTags>
      <LoginHeader />
      <CheckoutPageBody
        cart={cart}
        handleRemoveCart={handleRemoveCart}
        onCreateOrder={onCreateOrder}
        handleSubmitEmail={handleSubmitEmail}
        calculateCoupon={calculateCoupon}
        discount={discount}
        finalPrice={finalPrice}
      />
      <MainFooter />
    </div>
  );
};

export default ChackoutPage;
