export const GET_SIX_COURSES_FOR_STUDENTS = "GET_SIX_COURSES_FOR_STUDENTS"
export const GET_SIX_COURSES_FOR_STUDENTS_SUCCESS = "GET_SIX_COURSES_FOR_STUDENTS_SUCCESS"

export const GET_SINGLE_COURSE_FOR_LOGIN_USER = "GET_SINGLE_COURSE_FOR_LOGIN_USER"
export const GET_SINGLE_COURSE_FOR_LOGIN_USER_SUCCESS = "GET_SINGLE_COURSE_FOR_LOGIN_USER_SUCCESS"
export const GET_SINGLE_COURSE_FOR_LOGIN_USER_FAIL = "GET_SINGLE_COURSE_FOR_LOGIN_USER_FAIL"

export const ORDER_COURSE = "ORDER_COURSE"
export const ORDER_COURSE_SUCCESS = "ORDER_COURSE_SUCCESS"
export const ORDER_COURSE_FAIL = "ORDER_COURSE_FAIL"

export const GIFT_A_COURSE = "GIFT_A_COURSE"
export const GIFT_A_COURSE_SUCCESS = "GIFT_A_COURSE_SUCCESS"
export const GIFT_A_COURSE_FAIL = "GIFT_A_COURSE_FAIL"
export const GET_ALL_COURSES_STD = "GET_ALL_COURSES_STD"
export const GET_ALL_COURSES_SUCCESS = "GET_ALL_COURSES_SUCCESS"


//student see own course in profile
export const GET_STUDENT_OWN_COURSE = "GET_STUDENT_OWN_COURSE"
export const GET_STUDENT_OWN_COURSE_SUCCESS = "GET_STUDENT_OWN_COURSE_SUCCESS"

//store course mark in db
export const SUBMIT_MARK = "SUBMIT_MARK"
export const SUBMIT_MARK_SUCCESS = "SUBMIT_MARK_SUCCESS"
export const SUBMIT_MARK_FAIL = "SUBMIT_MARK_FAIL"

//show result
export const SHOW_RESULT = "SHOW_RESULT"
export const SHOW_RESULT_SUCCESS = "SHOW_RESULT_SUCCESS"

//course has already brought or not
export const COURSE_OWNER_VALID = "COURSE_OWNER_VALID"
export const COURSE_OWNER_VALID_SUCCESS = "COURSE_OWNER_VALID_SUCCESS"
export const COURSE_OWNER_VALID_FAIL = "COURSE_OWNER_VALID_FAIL"

//get ranking based on course
export const GET_RANKING_BASED_ON_COURSE = "GET_RANKING_BASED_ON_COURSE"
export const GET_RANKING_BASED_ON_COURSE_SUCCESS = "GET_RANKING_BASED_ON_COURSE_SUCCESS"
//get ranking based on course BY TEACHER
export const GET_RANKING_BASED_ON_COURSE_BY_TEACHER = "GET_RANKING_BASED_ON_COURSE_BY_TEACHER"
export const GET_RANKING_BASED_ON_COURSE_BY_TEACHER_SUCCESS = "GET_RANKING_BASED_ON_COURSE_BY_TEACHER_SUCCESS"

//view student
export const VIEW_STUDENTS = "VIEW_STUDENTS"
export const VIEW_STUDENTS_SUCCESS = "VIEW_STUDENTS_SUCCESS"

//create user
export const INSERT_STUDENT_INTO_COURSE = "INSERT_STUDENT_INTO_COURSE"
export const INSERT_STUDENT_INTO_COURSE_SUCCESS = "INSERT_STUDENT_INTO_COURSE_SUCCESS"
export const INSERT_STUDENT_INTO_COURSE_FAIL = "INSERT_STUDENT_INTO_COURSE_FAIL"

//search product
export const SEARCH_PRODUCT = "SERACT_PRODUCT"
export const SEARCH_PRODUCT_SUCCESS = "SERACT_PRODUCT_SUCCESS"

//search query
export const SEARCH_QUERY = "SEARCH_QUERY"
export const SEARCH_QUERY_SUCCESS = "SEARCH_QUERY_SUCCESS"

//search on category
export const SEARCH_ON_CATEGORY = "SEARCH_ON_CATEGORY"
export const SEARCH_ON_CATEGORY_SUCCESS = "SEARCH_ON_CATEGORY_SUCCESS"

//remove students form course
export const REMOVE_STUDENTS_FORM_COURSE = "REMOVE_STUDENTS_FORM_COURSE"
export const REMOVE_STUDENTS_FORM_COURSE_SUCCESS = "REMOVE_STUDENTS_FORM_COURSE_SUCCESS"
export const REMOVE_STUDENTS_FORM_COURSE_FAIL = "REMOVE_STUDENTS_FORM_COURSE_FAIL"
 
 