import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import bgImage from "./img/bg-image.png";
const HousngMarketingIntro = () => {
  const history = useHistory();
  const [housingStap1, setHousingStap1] = useState("");
  const [housingStap2, setHousingStap2] = useState("");
  const [housingStap3, setHousingStap3] = useState("");
  const handleSubmit = () => {
    if (housingStap1 && housingStap2 && housingStap3 !== "") {
      history.push("/housing-market-introduction");
    }
  };
  console.log(housingStap1);
  return (
    <div className="housing-marketing-main-area-wrap">
      <div
        className="market-housing-bg-area-awrap"
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className="housing-market-intro">
          <div className="housing-market-intro-box-area">
            <div className="housing-market-intro-box-area-header">
              <h2>
                Housing market <span>in Spain</span>{" "}
              </h2>
              <p>
                Lorem ipsum dolor sit amet consectetur. Elit felis duis eu
                pellentesque viverra. Risus
              </p>
            </div>
            <div className="housing-market-intro-radio-checkbox">
              <Row>
                <Col lg="6">
                  <div className="single-radio-checkbox-card">
                    <div className="housing-radio-group-block-header">
                      <h3>1</h3>
                      <p>
                        Lorem ipsum dolor sit amet consectetur. Eu pellentesque
                        integer{" "}
                      </p>
                    </div>
                    <div className="housing-radio-group-block-wrap">
                      <div className="housing-radio-group-block">
                        <label htmlFor="housing-radio-yes">Yes</label>
                        <input
                          id="housing-radio-yes"
                          name="group1"
                          type="radio"
                          value={"yes"}
                          onChange={() => setHousingStap1("yes")}
                          checked={housingStap1 === "yes"}
                        />
                      </div>{" "}
                      <div className="housing-radio-group-block-divide"></div>
                      <div className="housing-radio-group-block">
                        <label htmlFor="housing-radio-no">no</label>
                        <input
                          id="housing-radio-no"
                          name="group1"
                          type="radio"
                          value={"no"}
                          onChange={() => setHousingStap1("no")}
                          checked={housingStap1 === "no"}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="6 mb-3">
                  {housingStap1 !== "" && (
                    <div className="single-radio-checkbox-card">
                      <div className="housing-radio-group-block-header">
                        <h3>2</h3>
                        <p>
                          Lorem ipsum dolor sit amet consectetur. Eu
                          pellentesque integer{" "}
                        </p>
                      </div>
                      <div className="housing-radio-group-block-wrap-inline">
                        <div className="housing-radio-group-inline">
                          <input
                            id="housing-group-2-1"
                            name="group2"
                            type="radio"
                            value={"housing-group-2-1"}
                            onChange={() =>
                              setHousingStap2("housing-group-2-1")
                            }
                            checked={housingStap2 === "housing-group-2-1"}
                          />
                          <label htmlFor="housing-group-2-1">
                            Lorem ipsum dolor sit amet consec
                          </label>
                        </div>{" "}
                        <div className="housing-radio-group-inline">
                          <input
                            id="housing-group-2-2"
                            name="group2"
                            type="radio"
                            value={"housing-group-2-2"}
                            onChange={() =>
                              setHousingStap2("housing-group-2-2")
                            }
                            checked={housingStap2 === "housing-group-2-2"}
                          />
                          <label htmlFor="housing-group-2-2">
                            Lorem ipsum dolor sit amet consec
                          </label>
                        </div>{" "}
                        <div className="housing-radio-group-inline">
                          <input
                            id="housing-group-2-3"
                            name="group2"
                            type="radio"
                            value={"housing-group-2-3"}
                            onChange={() =>
                              setHousingStap2("housing-group-2-3")
                            }
                            checked={housingStap2 === "housing-group-2-3"}
                          />
                          <label htmlFor="housing-group-2-3">
                            Lorem ipsum dolor sit amet consec
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </Col>{" "}
                <Col lg="12">
                  {housingStap1 && housingStap2 !== "" && (
                    <div className="single-radio-checkbox-card">
                      <div className="housing-radio-group-block-header">
                        <p>
                          Lorem ipsum dolor sit amet consectetur. Eu
                          pellentesque integer{" "}
                        </p>
                      </div>
                      <div className="housing-radio-group-block-wrap last-form-area-intro">
                        <div>
                          <div className="housing-radio-group-inline">
                            <label htmlFor="housing-group-3-1">10%</label>
                            <input
                              id="housing-group-3-1"
                              name="group3"
                              type="radio"
                              value={"10%"}
                              onChange={() => setHousingStap3("10%")}
                              checked={housingStap3 === "10%"}
                            />
                          </div>
                          <div className="housing-radio-group-inline">
                            <label htmlFor="housing-group-3-2">20%</label>
                            <input
                              id="housing-group-3-2"
                              name="group3"
                              type="radio"
                              value={"20%"}
                              onChange={() => setHousingStap3("20%")}
                              checked={housingStap3 === "20%"}
                            />
                          </div>
                          <div className="housing-radio-group-inline">
                            <label htmlFor="housing-group-3-3">30%</label>
                            <input
                              id="housing-group-3-3"
                              name="group3"
                              type="radio"
                              value={"30%"}
                              onChange={() => setHousingStap3("30%")}
                              checked={housingStap3 === "30%"}
                            />
                          </div>
                        </div>
                        <div className="housing-radio-group-block-divide"></div>
                        <div>
                          <div className="housing-radio-group-inline">
                            <label htmlFor="housing-group-3-4">40%</label>
                            <input
                              id="housing-group-3-4"
                              name="group3"
                              type="radio"
                              value={"40%"}
                              onChange={() => setHousingStap3("40%")}
                              checked={housingStap3 === "40%"}
                            />
                          </div>{" "}
                          <div className="housing-radio-group-inline">
                            <label htmlFor="housing-group-3-5">50%</label>
                            <input
                              id="housing-group-3-5"
                              name="group3"
                              type="radio"
                              value={"50%"}
                              onChange={() => setHousingStap3("50%")}
                              checked={housingStap3 === "50%"}
                            />
                          </div>
                          <div className="housing-radio-group-inline">
                            <label htmlFor="housing-group-3-6">60%</label>
                            <input
                              id="housing-group-3-6"
                              name="group3"
                              type="radio"
                              value={"60%"}
                              onChange={() => setHousingStap3("60%")}
                              checked={housingStap3 === "60%"}
                            />
                          </div>
                        </div>
                        <div className="housing-radio-group-block-divide"></div>
                        <div>
                          <div className="housing-radio-group-inline">
                            <label htmlFor="housing-group-3-7">70%</label>
                            <input
                              id="housing-group-3-7"
                              name="group3"
                              type="radio"
                              value={"70%"}
                              onChange={() => setHousingStap3("70%")}
                              checked={housingStap3 === "70%"}
                            />
                          </div>
                          <div className="housing-radio-group-inline">
                            <label htmlFor="housing-group-3-8">80%</label>
                            <input
                              id="housing-group-3-8"
                              name="group3"
                              type="radio"
                              value={"80%"}
                              onChange={() => setHousingStap3("80%")}
                              checked={housingStap3 === "80%"}
                            />
                          </div>
                          <div className="housing-radio-group-inline">
                            <label htmlFor="housing-group-3-9">90%</label>
                            <input
                              id="housing-group-3-9"
                              name="group3"
                              type="radio"
                              value={"90%"}
                              onChange={() => setHousingStap3("90%")}
                              checked={housingStap3 === "90%"}
                            />
                          </div>
                        </div>
                        <div className="housing-radio-group-block-divide"></div>
                        <div>
                          <div className="housing-radio-group-inline">
                            <label htmlFor="housing-group-3-10">100%</label>
                            <input
                              id="housing-group-3-10"
                              name="group3"
                              type="radio"
                              value={"100%"}
                              onChange={() => setHousingStap3("100%")}
                              checked={housingStap3 === "100%"}
                            />
                          </div>
                          <div className="housing-radio-group-inline">
                            <label htmlFor="housing-group-3-11">Other</label>
                            <input
                              id="housing-group-3-11"
                              name="group3"
                              type="radio"
                              value={"Other"}
                              onChange={() => setHousingStap3("Other")}
                              checked={housingStap3 === "Other"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
                <Col lg="12">
                  {housingStap1 && housingStap2 && housingStap3 !== "" && (
                    <div className="housing-marketing-intro-submit-button">
                      <button onClick={handleSubmit}>Send</button>
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HousngMarketingIntro;
