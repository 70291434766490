import {
	GET_AUTHORS_BY_ADMIN,
	GET_AUTHORS_BY_ADMIN_SUCCESS,
	GET_SINGLE_AUTHOR_SUCCESS,
	GET_SINGLE_AUTHOR,
	GET_CATEGORY,
	GET_CATEGORY_SUCCESS,
	CREATE_CATEGORY,
	CREATE_CATEGORY_FAIL,
	CREATE_CATEGORY_SUCCESS,
	GET_STUDENTS,
	GET_STUDENTS_SUCCESS,
	GET_SINGLE_STUDENT,
	GET_SINGLE_STUDENT_SUCCESS,
	GET_ACADEMIC_AREAS,
	GET_ACADEMIC_AREAS_SUCCESS,
	UPDATE_AUTHOR,
	UPDATE_AUTHOR_SUCCESS,
	GET_MULTISELECTOR_DATA,
	GET_MULTISELECTOR_DATA_SUCCESS,
	CREATE_AUTHOR_FAIL,
	CREATE_AUTHOR_SUCCESS,
	CREATE_AUTHOR,
	COURSE_RELATED_PREVOUS_MULTI_DATA,
	COURSE_RELATED_PREVOUS_MULTI_DATA_SUCCESS,
	COURSE_CREATE,
	COURSE_CREATE_FAIL,
	COURSE_CREATE_SUCCESS,
	GET_COURSES,
	GET_COURSES_SUCCESS,
	GET_SINGLE_COURSE_FAIL,
	GET_SINGLE_COURSE_SUCCESS,
	GET_SINGLE_COURSE,
	CREATE_ACADEMIC_AREA_FAIL,
	CREATE_ACADEMIC_AREA_SUCCESSS,
	CREATE_ACADEMIC_AREA,
	GET_CATEGORY_IDS_SUCCESS,
	GET_CATEGORY_IDS,
	GET_SINGLE_COURSE_OF_VALID_USER,
	GET_SINGLE_COURSE_OF_VALID_USER_SUCCESS,
	GET_SINGLE_COURSE_OF_VALID_USER_FAIL,
	GET_COURSE_DATA_FOR_UPDATE,
	GET_COURSE_DATA_FOR_UPDATE_FAIL,
	GET_COURSE_DATA_FOR_UPDATE_SUCCESS,
	UPLOAD_AUTHOR_PHOTO,
	UPLOAD_AUTHOR_PHOTO_FAIL,
	UPLOAD_AUTHOR_PHOTO_SUCCESS,
	UPLOAD_COURSE_PHOTO,
	UPLOAD_COURSE_PHOTO_FAIL,
	UPLOAD_COURSE_PHOTO_SUCCESS,
	GET_FORMAT,
	GET_FORMAT_FAIL,
	GET_FORMAT_SUCCESS,
	UPDATE_ACADEMIC_AREA,
	UPDATE_ACADEMIC_AREA_FAIL,
	UPDATE_ACADEMIC_AREA_SUCCESS,
	GET_SINGLE_ACADEMIC_AREA_ADMIN,
	GET_SINGLE_ACADEMIC_AREA_FAIL_ADMIN,
	GET_SINGLE_ACADEMIC_AREA_SUCCESS_ADMIN,
	UPDATE_FORMAT,
	UPDATE_FORMAT_FAIL,
	UPDATE_FORMAT_SUCCESS,
	UPDATE_COURSE,
	UPDATE_COURSE_FAIL,
	UPDATE_COURSE_SUCCESS,
	GET_ORDERS,
	GET_ORDERS_SUCCESS,
} from "./actionTypes";

const initialState = {
	authors: [],
	loading: false,
	author: {},
	message: "",
	error: "",
	categories: [],
	students: [],
	student: {},
	academicAreas: [],
	multiSelectorData: [],
	courseRelatedData: [],
	courses: [],
	course: {},
	categoryIds: [],
	owner: false,
	courseDataForUpdate: {},
	photo: "",
	coursePhoto: "",
	formatData: [],
	academicArea: {},
	orderListData: [],
};

const adminAuthorPage = (state = initialState, action) => {
	switch (action.type) {
		case GET_AUTHORS_BY_ADMIN:
			state = { ...state, loading: true };
			break;

		case GET_AUTHORS_BY_ADMIN_SUCCESS:
			state = {
				...state,
				loading: false,
				authors: action.payload.data,
			};
			break;
		case GET_SINGLE_AUTHOR:
			state = {
				...state,
				loading: true,
			};
			break;
		case GET_SINGLE_AUTHOR_SUCCESS:
			state = {
				...state,
				loading: false,
				author: action.payload.data,
				photo: "",
			};
			break;
		case GET_CATEGORY:
			state = {
				...state,
				loading: true,
			};
			break;
		case GET_CATEGORY_SUCCESS:
			state = {
				...state,
				loading: false,
				categories: action.payload.data,
			};
			break;
		case CREATE_CATEGORY:
			state = {
				...state,
				loading: true,
			};
			break;
		case CREATE_CATEGORY_SUCCESS:
			state = {
				...state,
				loading: false,
				message: action.payload.message,
			};
			break;
		case CREATE_CATEGORY_FAIL:
			state = {
				...state,
				loading: false,
				error: action.payload.message,
			};
			break;
		case GET_STUDENTS:
			state = {
				...state,
				loading: true,
			};
			break;
		case GET_STUDENTS_SUCCESS:
			state = {
				...state,
				loading: false,
				students: action.payload.data,
			};
			break;
		case GET_SINGLE_STUDENT:
			state = {
				...state,
				loading: true,
			};
			break;
		case GET_SINGLE_STUDENT_SUCCESS:
			state = {
				...state,
				loading: false,
				student: action.payload.data,
			};
			break;
		case GET_ACADEMIC_AREAS:
			state = {
				...state,
				loading: true,
			};
			break;
		case GET_ACADEMIC_AREAS_SUCCESS:
			state = {
				...state,
				loading: false,
				academicAreas: action.payload.data,
			};
			break;
		case UPDATE_AUTHOR:
			state = {
				...state,
				loading: true,
			};
			break;
		case UPDATE_AUTHOR_SUCCESS:
			state = {
				...state,
				loading: false,
				message: action.payload.data,
			};
			break;
		case GET_MULTISELECTOR_DATA:
			state = {
				...state,
				loading: true,
			};
			break;
		case GET_MULTISELECTOR_DATA_SUCCESS:
			state = {
				...state,
				loading: false,
				multiSelectorData: action.payload.data,
			};
			break;
		case CREATE_AUTHOR:
			state = {
				...state,
				loading: true,
			};
			break;

		case CREATE_AUTHOR_SUCCESS:
			state = {
				...state,
				loading: false,
				message: action.payload.message,
			};
			break;
		case CREATE_AUTHOR_FAIL:
			state = {
				...state,
				loading: false,
				error: action.payload.message,
			};
			break;
		case COURSE_RELATED_PREVOUS_MULTI_DATA:
			state = {
				...state,
				loading: true,
			};
			break;
		case COURSE_RELATED_PREVOUS_MULTI_DATA_SUCCESS:
			state = {
				...state,
				loading: false,
				courseRelatedData: action.payload.data,
			};
			break;
		case COURSE_CREATE:
			state = {
				...state,
				loading: true,
			};
			break;
		case COURSE_CREATE_SUCCESS:
			state = {
				...state,
				loading: false,
				message: action.payload.message,
			};
			break;
		case COURSE_CREATE_FAIL:
			state = {
				...state,
				loading: false,
				error: action.payload.message,
			};
			break;
		case GET_COURSES:
			state = {
				...state,
				loading: true,
			};
			break;
		case GET_COURSES_SUCCESS:
			state = {
				...state,
				loading: false,
				photo: "",
				courses: action.payload.data,
			};
			break;
		case GET_SINGLE_COURSE:
			state = {
				...state,
				loading: true,
			};
			break;
		case GET_SINGLE_COURSE_SUCCESS:
			state = {
				...state,
				loading: false,
				course: action.payload.data,
			};
			break;
		case GET_SINGLE_COURSE_FAIL:
			state = {
				...state,
				loading: false,
				error: action.payload.message,
			};
			break;
		case CREATE_ACADEMIC_AREA:
			state = {
				...state,
				loading: true,
			};
			break;
		case CREATE_ACADEMIC_AREA_SUCCESSS:
			state = {
				...state,
				loading: false,
				message: action.payload.message,
			};
			break;
		case CREATE_ACADEMIC_AREA_FAIL:
			state = {
				...state,
				loading: false,
				error: action.payload.message,
			};
			break;

		case GET_CATEGORY_IDS:
			state = {
				...state,
				loading: true,
			};
			break;
		case GET_CATEGORY_IDS_SUCCESS:
			state = {
				...state,
				loading: false,
				categoryIds: action.payload.data,
			};
			break;
		case GET_SINGLE_COURSE_OF_VALID_USER:
			state = {
				...state,
				loading: true,
			};
			break;
		case GET_SINGLE_COURSE_OF_VALID_USER_SUCCESS:
			state = {
				...state,
				loading: false,
				course: action.payload.data.getSingleData,
				owner: action.payload.data.owner,
			};
			break;
		case GET_SINGLE_COURSE_OF_VALID_USER_FAIL:
			state = {
				...state,
				loading: false,
				error: action.payload.message,
			};
			break;
		case GET_COURSE_DATA_FOR_UPDATE:
			state = {
				...state,
				loading: true,
			};
			break;
		case GET_COURSE_DATA_FOR_UPDATE_SUCCESS:
			state = {
				...state,
				loading: false,
				courseDataForUpdate: action.payload.data,
			};
			break;

		case GET_COURSE_DATA_FOR_UPDATE_FAIL:
			state = {
				...state,
				loading: false,
				error: action.payload.message,
			};
			break;
		case UPLOAD_AUTHOR_PHOTO:
			state = {
				...state,
				loading: true,
			};
			break;
		case UPLOAD_AUTHOR_PHOTO_SUCCESS:
			state = {
				...state,
				loading: false,
				photo: action.payload.photo,
			};
			break;
		case UPLOAD_AUTHOR_PHOTO_FAIL:
			state = {
				...state,
				loading: false,
				error: action.payload.message,
			};
			break;

		case UPLOAD_COURSE_PHOTO:
			state = {
				...state,
				loading: true,
			};
			break;
		case UPLOAD_COURSE_PHOTO_SUCCESS:
			state = {
				...state,
				loading: false,
				coursePhoto: action.payload.data,
			};
			break;
		case UPLOAD_COURSE_PHOTO_FAIL:
			state = {
				...state,
				loading: false,
				error: action.payload.message,
			};
			break;
		case GET_FORMAT:
			state = {
				...state,
				loading: true,
			};
			break;
		case GET_FORMAT_SUCCESS:
			state = {
				...state,
				loading: false,
				photo: "",
				formatData: action.payload.data,
			};
			break;

		case GET_SINGLE_ACADEMIC_AREA_ADMIN:
			state = {
				...state,
				loading: true,
			};
			break;

		case GET_SINGLE_ACADEMIC_AREA_SUCCESS_ADMIN:
			state = {
				...state,
				loading: false,
				academicArea: action.payload.data,
			};
			break;
		case GET_SINGLE_ACADEMIC_AREA_FAIL_ADMIN:
			state = {
				...state,
				loading: false,
				error: action.payload.message,
			};
			break;

		case UPDATE_ACADEMIC_AREA:
			state = {
				...state,
				loading: true,
			};
			break;

		case UPDATE_ACADEMIC_AREA_SUCCESS:
			state = {
				...state,
				loading: false,
				message: action.payload.message,
			};
			break;
		case UPDATE_ACADEMIC_AREA_FAIL:
			state = {
				...state,
				loading: false,
				error: action.payload.error,
			};
			break;

		case UPDATE_FORMAT:
			state = {
				...state,
				loading: true,
			};
			break;
		case UPDATE_FORMAT_SUCCESS:
			state = {
				...state,
				loading: false,
				message: action.payload.message,
			};
			break;
		case UPDATE_FORMAT_FAIL:
			state = {
				...state,
				loading: false,
				error: action.payload.message,
			};
			break;

		case UPDATE_COURSE:
			state = {
				...state,
				loading: true,
			};
			break;
		case UPDATE_COURSE_SUCCESS:
			state = {
				...state,
				loading: false,
				message: action.payload.message,
			};
			break;
		case UPDATE_COURSE_FAIL:
			state = {
				...state,
				loading: false,
				error: action.payload.message,
			};
			break;
		case GET_ORDERS:
			state = {
				...state,
				loading: true,
			};

		case GET_ORDERS_SUCCESS:
			state = {
				...state,
				loading: false,
				orderListData: action.payload.data,
			};
		default:
			state = state;
			break;
	}
	return state;
};

export default adminAuthorPage;
