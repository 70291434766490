export const packageData = [
  {
    id: 1,
    name: "Individual",
    packageType: "Individual Package",
    costType: "paid",
    status: "actice",
  },
  {
    id: 2,
    name: "Bundle",
    packageType: "Bundle Package",
    costType: "paid",
    status: "actice",
  },
  {
    id: 3,
    name: "Gift & Rewards",
    packageType: "Gift & Rewards",
    costType: "paid",
    status: "actice",
  },
  {
    id: 4,
    name: "Annual",
    packageType: "Annual Package",
    costType: "paid",
    status: "actice",
  },
];
