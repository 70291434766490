import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import service3 from "./img/3.png";
import laptopIcon2 from "./img/diagram.svg";
import laptopIcon3 from "./img/document-text.svg";
import laptopIcon4 from "./img/keyboard-open.svg";
import serviceOne from "./img/service-1.jpg";
import service2 from "./img/service-2.jpg";
const ReletadCourse = () => {
  return (
    <div>
      <Container>
        <div className="category-inner-area-main-wrap">
          <div className="leretd-all-course-bottom-wrap">
            <h4>Related Materials</h4>
          </div>
          <Row>
            <Col lg={4}>
              <div
                className="business-clases-single-item-wrap"
                style={{ backgroundImage: `url(${serviceOne})` }}
              >
                <div className="business-clases-main-part">
                  <div className="business-clases-main-part-top">
                    <h3>BASICS OF SALES MANAGEMENT</h3>
                    <i>MKS020188-U-ENG-HTM</i>
                  </div>
                  <div className="business-clases-main-part-bottom">
                    <i>Academic Area</i>
                    <h4>COST ACCOUNTING & MANAGEMENT CONTROL</h4>
                    <div className="business-clases-main-part-bottom-inner">
                      <div className="business-clases-main-part-bottom-inner-left">
                        <img src={laptopIcon2} alt="" />
                      </div>{" "}
                      <div className="business-clases-main-part-bottom-inner-left">
                        <h6>
                          <span>Format: </span> Multimedia & Interactive
                        </h6>{" "}
                        <h6>
                          <span>Language: </span> English
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="business-clases-hover-part">
                  <div className="">
                    <p>
                      This interactive tutorial introduces the student to the
                      world of business management. The tutorial is divided into
                      4 sections: trade, trading activity, the complex sale and
                      the personal sale process, where the student is introduced
                      to the basic concepts of the commercial world up to how to
                      put the seller´s activity into
                    </p>
                    <div className="business-clases-hover-part-bottom-link">
                      <Link to="/course-details-page">
                        <span>+</span>
                        <span>Read More</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>{" "}
            <Col lg={4}>
              <div
                className="business-clases-single-item-wrap"
                style={{ backgroundImage: `url(${service2})` }}
              >
                <div className="business-clases-main-part">
                  <div className="business-clases-main-part-top">
                    <h3>People Management</h3>
                    <i>MKS020188-U-ENG-HTM</i>
                  </div>
                  <div className="business-clases-main-part-bottom">
                    <i>Academic Area</i>
                    <h4>COST ACCOUNTING & MANAGEMENT CONTROL</h4>
                    <div className="business-clases-main-part-bottom-inner">
                      <div className="business-clases-main-part-bottom-inner-left">
                        <img src={laptopIcon3} alt="" />
                      </div>{" "}
                      <div className="business-clases-main-part-bottom-inner-left">
                        <h6>
                          <span>Format: </span> Case Study
                        </h6>{" "}
                        <h6>
                          <span>Language: </span> English
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="business-clases-hover-part">
                  <div className="">
                    <p>
                      This interactive tutorial introduces the student to the
                      world of business management. The tutorial is divided into
                      4 sections: trade, trading activity, the complex sale and
                      the personal sale process, where the student is introduced
                      to the basic concepts of the commercial world up to how to
                      put the seller´s activity into
                    </p>
                    <div className="business-clases-hover-part-bottom-link">
                      <a href="/">
                        <span>+</span>
                        <span>Read More</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Col>{" "}
            <Col lg={4}>
              <div
                className="business-clases-single-item-wrap"
                style={{ backgroundImage: `url(${service3})` }}
              >
                <div className="business-clases-main-part">
                  <div className="business-clases-main-part-top">
                    <h3>Key Concepts in Economics</h3>
                    <i>MKS020188-U-ENG-HTM</i>
                  </div>
                  <div className="business-clases-main-part-bottom">
                    <i>Academic Area</i>
                    <h4>ECONOMIC ENVIRONMENT & PUBLIC AFFAIRS</h4>
                    <div className="business-clases-main-part-bottom-inner">
                      <div className="business-clases-main-part-bottom-inner-left">
                        <img src={laptopIcon4} alt="" />
                      </div>{" "}
                      <div className="business-clases-main-part-bottom-inner-left">
                        <h6>
                          <span>Format: </span> Grains & Simulation
                        </h6>{" "}
                        <h6>
                          <span>Language: </span> English
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="business-clases-hover-part">
                  <div className="">
                    <p>
                      This interactive tutorial introduces the student to the
                      world of business management. The tutorial is divided into
                      4 sections: trade, trading activity, the complex sale and
                      the personal sale process, where the student is introduced
                      to the basic concepts of the commercial world up to how to
                      put the seller´s activity into
                    </p>
                    <div className="business-clases-hover-part-bottom-link">
                      <a href="/">
                        <span>+</span>
                        <span>Read More</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Col>{" "}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default ReletadCourse;
