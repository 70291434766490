import { Col, Container, Row, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import emptyImage from "./img/emptyImage.png";
const CategoryAllDataList = ({
  courses,
  loading,
  categoris,
  academicId,
  handleSearchByText,
  searchedCourse,
  handleStoreCategoryIds,
}) => {
  let dispatch = useDispatch();
  const hanldeDuplicateRemoveAndCall = (id) => {};
  const handleDirectCall = (id) => {};
  //searching algorithm
  //Part 1
  //if categoryIds.length <1
  //store id into categoryIds
  //part 2:
  //if categoryId>1:
  //inside 1:
  //global array
  //  map(categoryId)=>categoryIdEach
  // if(categoryIdEach == searchItem)
  // return null
  //else:
  //add categoryIdEach into global array
  //

  const courseCart = (course) => {
    if (loading) {
      return (
        <>
          <Row>
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </Row>
        </>
      );
    } else {
      if (course.length < 1) {
        return (
          <>
            {" "}
            <Col lg={4}>
              <img src={emptyImage} />
              No Data Found...
            </Col>
          </>
        );
      } else {
        return (
          <>
            <Row>
              {course.map((v, i) => (
                <Col lg={4}>
                  <div
                    className="business-clases-single-item-wrap"
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}${v.image})`,
                    }}
                  >
                    <div className="business-clases-main-part">
                      <div className="business-clases-main-part-top">
                        <h3>{v.courseName}</h3>
                        <i>{v.courseCode}</i>
                      </div>
                      <div className="business-clases-main-part-bottom">
                        <i>Academic Area : </i>
                        {v?.academicArea?.map((v1, i) => (
                          <span>
                            {v1.academicName}{" "}
                            {v.academicArea[i + 1] ? " | " : ""}
                          </span>
                        ))}
                        <h4>{v.courseName}</h4>
                        <div className="business-clases-main-part-bottom-inner">
                          <div className="business-clases-main-part-bottom-inner-left img">
                            <img
                              src={`${process.env.REACT_APP_IMAGE_URL}${v?.category[0]?.image}`}
                              alt=""
                            />
                          </div>{" "}
                          <div className="business-clases-main-part-bottom-inner-left">
                            <h6>
                              <span>Format: </span>{" "}
                              {v?.category?.map((v1, i) => (
                                <>
                                  {v1.categoryName}
                                  {v?.category.length == i + 1 ? "" : "|"}
                                </>
                              ))}
                            </h6>{" "}
                            <h6>
                              <span>Language: </span> {v.langugge}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-clases-hover-part">
                      <div className="">
                        <p>
                          {v.description && v.description.substring(0, 100)}
                          ...
                        </p>
                        <div className="business-clases-hover-part-bottom-link">
                          <Link to={`/course-details-page/${v._id}`}>
                            <span>+</span>
                            <span>Read More</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </>
        );
      }
    }
  };
  const courseCartGrid = (course) => {
    return (
      <>
        <Table
          borderless
          className="list-course-view-data-table-main-area-wrap"
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Course Code</th>
              <th>Academic Area</th>
              <th>Format</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {course.map((v, i) => (
              <tr>
                <td>
                  <div className="list-course-view-data-table-jdhbbd">
                    <img
                      src={`${process.env.REACT_APP_IMAGE_URL}${v.image}`}
                      alt=""
                    />
                    <h4>{v.courseName}</h4>
                  </div>
                </td>
                <td>
                  <p>{v.code}</p>
                </td>
                <td>
                  <p>
                    {v?.academicArea?.map((v1, i) => (
                      <span>
                        {v1.academicName} {v.academicArea[i + 1] ? "|" : ""}
                      </span>
                    ))}
                  </p>
                </td>
                <td>
                  {" "}
                  <p>
                    <span></span>{" "}
                    {v?.category?.map((v1, i) => (
                      <>
                        {v1.categoryName}
                        {v?.category.length == i + 1 ? "" : "|"}
                      </>
                    ))}
                  </p>
                </td>
                <td>
                  <div className="list-course-view-data-table-jdhbbd">
                    <Link to={`/course-details-page/${v._id}`}>
                      <span>View</span>
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  };
  return (
    <div>
      <Container>
        <div className="category-inner-area-main-wrap">
          <Row>
            <Col lg={3}>
              <div className="hero-slider-content-right category-page">
                <h6>REFINE YOUR SEARCH</h6>
                <div className="academic-right-area-wrap category-page">
                  {categoris?.map((item, i) => {
                    return (
                      <div className="academic-right-single-item category-page">
                        <input
                          type="checkbox"
                          id={i}
                          name="ECONOMIC"
                          value={item._id}
                          onClick={(e) => handleStoreCategoryIds(e, item._id)}
                        />
                        <label for={i}> {item.categoryName}</label>
                        {/* <a href="/student-category"> </a>
                    <a href="/">STRATEGY</a> */}
                      </div>
                    );
                  })}
                </div>
              </div>
            </Col>
            <Col lg={9}>
              <div className="search-course-main-area-wrap">
                <div className="search-top-area-">
                  <div className="search-area-input-field-and-button">
                    <div className="search-area-input-field-box-wrap">
                      <input
                        type="search"
                        placeholder="Search course"
                        onChange={(e) => handleSearchByText(e)}
                      />
                      <i className="bx bx-search"></i>
                    </div>
                    <button>Search</button>
                  </div>
                  <div className="course-filtering-area-wrap-inner-wrap">
                    {/* <span>Showing 1 - 9 of 108 result(s)</span> */}
                    {/* <span>
                      <img src={fIcon2} alt="" />
                      <img src={fIcon1} alt="" />
                    </span> */}
                  </div>
                </div>
                <div className="course-filtering-area-wrap courseware-common-tabs">
                  <Tabs>
                    <TabList className="tab-list-grid-course-data">
                      <Tab>
                        {" "}
                        <i className="bx bx-grid-alt"></i>
                        {/* <img src={fIcon1} alt="" /> */}
                      </Tab>
                      <Tab>
                        <i className="bx bx-menu"></i>
                        {/* <img src={fIcon2} alt="" /> */}
                      </Tab>
                    </TabList>

                    {courses.length > 0 ? (
                      <>
                        <TabPanel>{courseCart(courses)}</TabPanel>
                        <TabPanel>{courseCartGrid(courses)}</TabPanel>{" "}
                      </>
                    ) : (
                      <></>
                    )}
                  </Tabs>
                  <Row>
                    {/* <Col lg={4}>
                  <div
                    className="business-clases-single-item-wrap"
                    style={{ backgroundImage: `url(${service2})` }}
                  >
                    <div className="business-clases-main-part">
                      <div className="business-clases-main-part-top">
                        <h3>People Management</h3>
                        <i>MKS020188-U-ENG-HTM</i>
                      </div>
                      <div className="business-clases-main-part-bottom">
                        <i>Academic Area</i>
                        <h4>COST ACCOUNTING & MANAGEMENT CONTROL</h4>
                        <div className="business-clases-main-part-bottom-inner">
                          <div className="business-clases-main-part-bottom-inner-left">
                            <img src={laptopIcon3} alt="" />
                          </div>{" "}
                          <div className="business-clases-main-part-bottom-inner-left">
                            <h6>
                              <span>Format: </span> Case Study
                            </h6>{" "}
                            <h6>
                              <span>Language: </span> English
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-clases-hover-part">
                      <div className="">
                        <p>
                          This interactive tutorial introduces the student to
                          the world of business management. The tutorial is
                          divided into 4 sections: trade, trading activity, the
                          complex sale and the personal sale process, where the
                          student is introduced to the basic concepts of the
                          commercial world up to how to put the seller´s
                          activity into
                        </p>
                        <div className="business-clases-hover-part-bottom-link">
                          <a href="/">
                            <span>+</span>
                            <span>Read More</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>{" "}
                <Col lg={4}>
                  <div
                    className="business-clases-single-item-wrap"
                    style={{ backgroundImage: `url(${service3})` }}
                  >
                    <div className="business-clases-main-part">
                      <div className="business-clases-main-part-top">
                        <h3>Key Concepts in Economics</h3>
                        <i>MKS020188-U-ENG-HTM</i>
                      </div>
                      <div className="business-clases-main-part-bottom">
                        <i>Academic Area</i>
                        <h4>ECONOMIC ENVIRONMENT & PUBLIC AFFAIRS</h4>
                        <div className="business-clases-main-part-bottom-inner">
                          <div className="business-clases-main-part-bottom-inner-left">
                            <img src={laptopIcon4} alt="" />
                          </div>{" "}
                          <div className="business-clases-main-part-bottom-inner-left">
                            <h6>
                              <span>Format: </span> Grains & Simulation
                            </h6>{" "}
                            <h6>
                              <span>Language: </span> English
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-clases-hover-part">
                      <div className="">
                        <p>
                          This interactive tutorial introduces the student to
                          the world of business management. The tutorial is
                          divided into 4 sections: trade, trading activity, the
                          complex sale and the personal sale process, where the
                          student is introduced to the basic concepts of the
                          commercial world up to how to put the seller´s
                          activity into
                        </p>
                        <div className="business-clases-hover-part-bottom-link">
                          <a href="/">
                            <span>+</span>
                            <span>Read More</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>{" "}
                <Col lg={4}>
                  <div
                    className="business-clases-single-item-wrap"
                    style={{ backgroundImage: `url(${service4})` }}
                  >
                    <div className="business-clases-main-part">
                      <div className="business-clases-main-part-top">
                        <h3>Metaverse reality</h3>
                        <i>MKS020188-U-ENG-HTM</i>
                      </div>
                      <div className="business-clases-main-part-bottom">
                        <i>Academic Area</i>
                        <h4>
                          DIGITAL TECHNOLOGIES & DATA <br /> SCIENCE
                        </h4>
                        <div className="business-clases-main-part-bottom-inner">
                          <div className="business-clases-main-part-bottom-inner-left">
                            <img src={laptopIcon2} alt="" />
                          </div>{" "}
                          <div className="business-clases-main-part-bottom-inner-left">
                            <h6>
                              <span>Format: </span> Coursware
                            </h6>{" "}
                            <h6>
                              <span>Language: </span> English
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-clases-hover-part">
                      <div className="">
                        <p>
                          This interactive tutorial introduces the student to
                          the world of business management. The tutorial is
                          divided into 4 sections: trade, trading activity, the
                          complex sale and the personal sale process, where the
                          student is introduced to the basic concepts of the
                          commercial world up to how to put the seller´s
                          activity into
                        </p>
                        <div className="business-clases-hover-part-bottom-link">
                          <a href="/">
                            <span>+</span>
                            <span>Read More</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>{" "}
                <Col lg={4}>
                  <div
                    className="business-clases-single-item-wrap"
                    style={{ backgroundImage: `url(${service5})` }}
                  >
                    <div className="business-clases-main-part">
                      <div className="business-clases-main-part-top">
                        <h3>Accounting Principles</h3>
                        <i>MKS020188-U-ENG-HTM</i>
                      </div>
                      <div className="business-clases-main-part-bottom">
                        <i>Academic Area</i>
                        <h4>COST ACCOUNTING & MANAGEMENT CONTROL</h4>
                        <div className="business-clases-main-part-bottom-inner">
                          <div className="business-clases-main-part-bottom-inner-left">
                            <img src={laptopIcon3} alt="" />
                          </div>{" "}
                          <div className="business-clases-main-part-bottom-inner-left">
                            <h6>
                              <span>Format: </span> Case Study
                            </h6>{" "}
                            <h6>
                              <span>Language: </span> English
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-clases-hover-part">
                      <div className="">
                        <p>
                          This interactive tutorial introduces the student to
                          the world of business management. The tutorial is
                          divided into 4 sections: trade, trading activity, the
                          complex sale and the personal sale process, where the
                          student is introduced to the basic concepts of the
                          commercial world up to how to put the seller´s
                          activity into
                        </p>
                        <div className="business-clases-hover-part-bottom-link">
                          <a href="/">
                            <span>+</span>
                            <span>Read More</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>{" "}
                <Col lg={4}>
                  <div
                    className="business-clases-single-item-wrap"
                    style={{ backgroundImage: `url(${service6})` }}
                  >
                    <div className="business-clases-main-part">
                      <div className="business-clases-main-part-top">
                        <h3>Econometrics</h3>
                        <i>MKS020188-U-ENG-HTM</i>
                      </div>
                      <div className="business-clases-main-part-bottom">
                        <i>Academic Area</i>
                        <h4>ECONOMIC ENVIRONMENT & PUBLIC AFFAIRS</h4>
                        <div className="business-clases-main-part-bottom-inner">
                          <div className="business-clases-main-part-bottom-inner-left">
                            <img src={laptopIcon4} alt="" />
                          </div>{" "}
                          <div className="business-clases-main-part-bottom-inner-left">
                            <h6>
                              <span>Format: </span> Grains & Simulation
                            </h6>{" "}
                            <h6>
                              <span>Language: </span> English
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-clases-hover-part">
                      <div className="">
                        <p>
                          This interactive tutorial introduces the student to
                          the world of business management. The tutorial is
                          divided into 4 sections: trade, trading activity, the
                          complex sale and the personal sale process, where the
                          student is introduced to the basic concepts of the
                          commercial world up to how to put the seller´s
                          activity into
                        </p>
                        <div className="business-clases-hover-part-bottom-link">
                          <a href="/">
                            <span>+</span>
                            <span>Read More</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col> */}
                  </Row>
                </div>
              </div>

              {/* <div className="business-cases-bottom-button-area">
                <a href="/">See More</a>
              </div> */}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default CategoryAllDataList;
