import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import lafargeDetailsIamge from "../../LafargeAssets/images/hero-1.jpg";
const LafargeTrainingDetails = () => {
  const { courseId } = useParams();
  return (
    <Container>
      <div className="lafarge-training-inner-wrap">
        <div className="lafarge-training-header-title">
          <h3>The Concept</h3>
          {/* <p>Author: Nazmus Shakib</p> */}
        </div>
        <div className="lafarge-training-items-wrap ptainig-page">
          <div className="lafarge-training-content">
            <div className="the-concept-image-overlay">
              <img src={lafargeDetailsIamge} alt="" />
            </div>
            <p>
              Negotiation has come a long way. A once purely adversarial,
              one-time event has evolved into a relationship-based, ongoing
              process that puts a premium on empathy and co-operation.
              Negotiation is a popular topic these days, in consumer magazines
              and scholarly journals alike. Business schools, too, are putting
              more emphasis on negotiating, and corporations are running
              workshops designed to give their managers a negotiating edge.
              There are two obvious reasons for the revival of interest in the
              art of negotiating. For starters, it is clear that unsuccessful
              negotiation, or the failure to negotiate at all, leads to conflict
              that is both unproductive and costly. Secondly, there is a
              recognition that many negotiations fail to maximize potential
              gains in the outcomes they produce. There is a third, less obvious
              point: Disputes are not zero-sum situations-one side does not need
              to lose for another side to win, and all parties do not
              necessarily want the same thing. Negotiations that take a zero-sum
              approach can fail to optimize results, if indeed they succeed at
              all.
            </p>
            {/* <p>
              Over three decades, RSHP, an international architectural practice
              based in London, had attracted critical acclaim and awards with
              projects built across Europe, North America and Asia. The practice
              was experienced in designing a wide range of building types
              including office, residential, transport, educational, cultural,
              leisure, retail, government and healthcare.
            </p>
            <br />
            <h6>
              Estimated time to complete this multimedia case: 60 minutes.
            </h6> */}
          </div>
        </div>
        <div className="lafarge-training-footer-wrap">
          <div className="lafarge-training-footer-button ">
            <Link to={`/${courseId}/why-do-we-need`}>Next</Link>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default LafargeTrainingDetails;
