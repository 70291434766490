import React, { useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
	getStudents,
	getOrderList,
	getOrderRelatedData,
} from "../../../../store/actions";
import DashboardCommonAllLink from "../../DashboardCommon/DashboardCommonAllLink";
import DashboardCoomonTopbar from "../../DashboardCommon/DashboardCoomonTopbar";
import OrderListData from "./OrderData";

const OrderList = () => {
	const { pathname } = useLocation();
	let dispatch = useDispatch();
	let history = useHistory();
	const { token, loading, students, orderListData } = useSelector(
		(state) => ({
			token: state.Login.token,
			loading: state.AdminAuthorReducer.loading,
			// students: state.AdminAuthorReducer.students,
			orderListData: state.AdminAuthorReducer.orderListData,
		}),
	);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	const [isOpen, setIsOpen] = useState(true);
	const [toggle, setToggle] = useState(false);
	// const [toggleClass, setToggleClass] = useState("");
	const toggleDrawer = () => {
		setIsOpen((prevState) => !prevState);

		// setToggleClass("showNavbar");
	};

	useEffect(() => {
		// dispatch(getStudents(token));
		dispatch(getOrderRelatedData());
	}, []);

	return (
		<>
			<DashboardCoomonTopbar />
			<div
				className={
					toggle
						? "dashboard-sidebar-nav-area showNavbar"
						: "dashboard-sidebar-nav-area"
				}>
				<button
					onClick={() => {
						toggleDrawer();
						setToggle(!toggle);
					}}
					className="main-sidebar-toggle-button">
					<i className="bx bx-menu"></i>
				</button>
				<Drawer
					open={isOpen}
					onClose={toggleDrawer}
					direction="left"
					className={`navbar-inner-main-wrap-items-wrap `}>
					<button
						onClick={() => {
							toggleDrawer();
							setToggle(!toggle);
						}}
						className="main-sidebar-toggle-button">
						<i className="bx bx-menu"></i>
					</button>
					<div className={`navbar-item-single-main-area-jddiu`}>
						<DashboardCommonAllLink />
					</div>
				</Drawer>
			</div>
			<div
				className={
					toggle
						? "dashboard-main-body showNavbar"
						: "dashboard-main-body"
				}>
				<OrderListData
					orderListData={orderListData}
					loading={loading}
				/>
			</div>
		</>
	);
};

export default OrderList;
