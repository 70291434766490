import React, { useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DashboardCommonAllLink from "../../DashboardCommon/DashboardCommonAllLink";
import DashboardCoomonTopbar from "../../DashboardCommon/DashboardCoomonTopbar";
import {
  courseRelatedPreviousMultiData,
  getCourseDataForUpdate,
  updateCourse,
  uploadAuthorPhoto,
} from "./../../../../store/actions";
import AllNewCourseEditBody from "./AllNewCourseEditBody";
import { options } from "./Data";

const AllNewCourseEdit = () => {
  const { pathname } = useLocation();
  let history = useHistory();
  const { token, loading, courses, course, courseRelatedData, coursePhoto } =
    useSelector((state) => ({
      token: state.Login.token,
      loading: state.AdminAuthorReducer.loading,
      courses: state.AdminAuthorReducer.courses,
      course: state.AdminAuthorReducer.courseDataForUpdate,
      courseRelatedData: state.AdminAuthorReducer.courseRelatedData,
      coursePhoto: state.AdminAuthorReducer.photo,
    }));

  let [courseSummary, setCourseSummary] = useState("");

  let [validation, setValidation] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  let { courseId } = useParams();
  console.log("course Id data", courseId);
  let dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const [toggle, setToggle] = useState(false);
  // const [toggleClass, setToggleClass] = useState("");
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);

    // setToggleClass("showNavbar");
  };

  let [courseTitle, setCourseTitle] = useState({
    title: course?.courseName,
  });

  let [courseCode, setCourseCode] = useState({
    code: course?.code,
  });
  let [photo, setPhoto] = useState({
    photo: coursePhoto ? coursePhoto : course?.image,
  });
  let [noOfPages, setNoOfPages] = useState({
    pages: course?.noOfPages,
  });

  let [coursePrice, setCoursePrice] = useState({
    price: course?.price,
  });

  // let [courseAcademicId, setCourseAcademicId] = useState({
  //   obj: course?.academicArea?.map((v, i) =>
  //   {
  //     return{label: v.academicName, value : v._id}
  //   })
  // });

  let [description, setDescription] = useState({
    description: course?.description,
  });

  // let [courseFormatId, setCourseFormatId] = useState({
  //   obj: course?.category?.map((v, i) =>
  //   {
  //     return{label: v.categoryName, value : v._id}
  //   })
  // });

  let [estimatedTime, setEstimatedTime] = useState({
    estimatedTime: course?.estimatedTime,
  });
  let [courseLanguage, setCourseLanguage] = useState({
    language: course?.language,
  });

  // let [courseAuthorId, setCourseAuthorId] = useState({

  //   obj :[{label: course?.author?.authorName, value : course?.author?._id}]
  // })

  let [subjectCoverd, setSubjectCoverd] = useState({
    subjectCoverd: course?.subjectCoverd,
  });
  let [duration, setDuration] = useState({
    duration: course?.subjectCoverd,
  });

  let [typeOfPublication, setTypeOfPublication] = useState({
    obj: options?.map((v, i) => {
      if (v.value == course?.typeOfPublication) {
        return [{ label: v.label, value: v.value }];
      }
    }),
  });

  useEffect(() => {
    dispatch(getCourseDataForUpdate(token, courseId));
    dispatch(courseRelatedPreviousMultiData(token));
    // dispatch(getFormat(token))
  }, []);

  useEffect(() => {
    if (course) {
      setCourseTitle({
        title: course?.courseName || "",
      });
      setCourseCode({
        code: course?.code || "",
      });

      setNoOfPages({
        pages: course?.noOfPages || "",
      });
      setPhoto({
        photo: course?.image || "",
      });
      setCoursePrice({
        price: course?.price || "",
      });

      // setCourseAcademicId({
      //   obj: course?.academicArea?.map((v, i) =>
      //   {
      //     return{label: v.academicName, value : v._id}
      //   })
      // })

      setDescription({
        description: course?.description || "",
      });

      // setCourseFormatId({
      //   obj: options?.category?.map((v, i) =>
      //   {
      //     return{label: v.categoryName, value : v._id}
      //   })
      // })

      setEstimatedTime({
        estimatedTime: course?.estimatedTime,
      });
      setCourseLanguage({
        language: course?.language,
      });

      // setCourseAuthorId({
      //   obj :[{label: course?.author?.authorName, value : course?.author?._id}]
      // })
      setSubjectCoverd({
        subjectCoverd: course?.subjectCoverd,
      });

      setDuration({
        duration: course?.duration,
      });
      setTypeOfPublication({
        obj: options?.map((v, i) => {
          console.log("mile nai", v.value, course?.typeOfPublication);
          if (v.value == course?.typeOfPublication) {
            console.log("milche");

            return { label: v.label, value: v.value };
          }
        }),
      });
    }
  }, [course]);

  const handleSetCourseName = (e) => {
    e.preventDefault();
    setCourseTitle({
      title: e.target.value,
    });
  };
  const handleCourseCode = (e) => {
    e.preventDefault();
    setCourseCode({
      code: e.target.value,
    });
  };

  const handleUploadAuthorImage = (e) => {
    console.log("hello photo", e.target.files[0]);

    let formData = new FormData();

    formData.append("photo", e.target.files[0]);
    dispatch(uploadAuthorPhoto(token, formData));
  };

  const handleSetNoOfPages = (e) => {
    e.preventDefault();
    setNoOfPages({
      pages: e.target.value,
    });
  };

  const handleSetDescription = (e) => {
    e.preventDefault();
    setDescription({
      description: e.target.value,
    });
  };
  const handleSetCoursePrice = (e) => {
    e.preventDefault();
    setCoursePrice({
      price: e.target.value,
    });
  };
  const handleSetEstimatedTime = (e) => {
    e.preventDefault();
    setEstimatedTime({
      estimatedTime: e.target.value,
    });
  };

  // const handleSetAcademicIds = (selectedOptions) =>
  // {
  //   // e.preventDefault()
  //   console.log("selected Options", selectedOptions)
  //   setCourseAcademicId({
  //     obj:  selectedOptions
  //   })
  // }
  // const handleSetCourseFormatIds = (selectedOptions) =>
  // {
  //   // e.preventDefault()
  //   console.log("selected Options", selectedOptions)
  //   setCourseFormatId({
  //     obj:  selectedOptions
  //   })
  // }

  const handleCourseLanguage = (e) => {
    e.preventDefault();
    setCourseLanguage({
      language: e.target.value,
    });
  };

  const handleSetSubjectCoverd = (e) => {
    e.preventDefault();
    setSubjectCoverd({
      subjectCoverd: e.target.value,
    });
  };

  const handleSetDuration = (e) => {
    e.preventDefault();
    setDuration({
      duration: e.target.value,
    });
  };

  const handleSetTypeOfPublications = (selectidOption) => {
    setTypeOfPublication({
      obj: selectidOption,
    });
  };

  const handleSubmitData = (e) => {
    e.preventDefault();
    let value;
    console.log("type of puh", typeOfPublication);
    if (typeOfPublication.obj.value) {
      value = typeOfPublication.obj.value;
    } else {
      Object.keys(typeOfPublication?.obj).map((v, i) => {
        if (typeOfPublication?.obj[v]?.value) {
          value = typeOfPublication?.obj[v]?.value;
        }
      });
    }
    console.log(
      "value here",
      courseTitle.title,
      courseCode.code,
      noOfPages.pages,
      estimatedTime.estimatedTime,
      coursePrice.price,
      description.description,
      coursePhoto,
      courseLanguage.language,
      subjectCoverd.subjectCoverd,
      duration.duration,
      value,
      courseId
    );
    if (coursePhoto) {
      dispatch(
        updateCourse(
          token,
          courseId,
          courseTitle.title,
          courseCode.code,
          noOfPages.pages,
          estimatedTime.estimatedTime,
          coursePrice.price,
          description.description,
          coursePhoto,
          courseLanguage.language,
          subjectCoverd.subjectCoverd,
          duration.duration,
          value,
          history
        )
      );
    } else {
      dispatch(
        updateCourse(
          token,
          courseId,
          courseTitle.title,
          courseCode.code,
          noOfPages.pages,
          estimatedTime.estimatedTime,
          coursePrice.price,
          description.description,
          photo.photo,
          courseLanguage.language,
          subjectCoverd.subjectCoverd,
          duration.duration,
          value,
          history
        )
      );
    }
  };
  return (
    <>
      <DashboardCoomonTopbar />
      <div
        className={
          toggle
            ? "dashboard-sidebar-nav-area showNavbar"
            : "dashboard-sidebar-nav-area"
        }
      >
        <button
          onClick={() => {
            toggleDrawer();
            setToggle(!toggle);
          }}
          className="main-sidebar-toggle-button"
        >
          <i className="bx bx-menu"></i>
        </button>
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="left"
          className={`navbar-inner-main-wrap-items-wrap `}
        >
          <button
            onClick={() => {
              toggleDrawer();
              setToggle(!toggle);
            }}
            className="main-sidebar-toggle-button"
          >
            <i className="bx bx-menu"></i>
          </button>
          <div className={`navbar-item-single-main-area-jddiu`}>
            <DashboardCommonAllLink course={course} />
          </div>
        </Drawer>
      </div>
      <div
        className={
          toggle ? "dashboard-main-body showNavbar" : "dashboard-main-body"
        }
      >
        <AllNewCourseEditBody
          courseTitle={courseTitle}
          handleSetCourseName={handleSetCourseName}
          courseCode={courseCode}
          handleCourseCode={handleCourseCode}
          token={token}
          noOfPages={noOfPages}
          handleSetNoOfPages={handleSetNoOfPages}
          handleSetCoursePrice={handleSetCoursePrice}
          coursePrice={coursePrice}
          // handleSetAcademicIds={handleSetAcademicIds}
          // courseAcademicId={courseAcademicId}
          courseRelatedData={courseRelatedData}
          description={description}
          handleSetDescription={handleSetDescription}
          // handleSetCourseFormatIds={handleSetCourseFormatIds}
          // courseFormatId={courseFormatId}
          handleSetEstimatedTime={handleSetEstimatedTime}
          estimatedTime={estimatedTime}
          handleCourseLanguage={handleCourseLanguage}
          courseLanguage={courseLanguage}
          // courseAuthorId={courseAuthorId}
          subjectCoverd={subjectCoverd}
          handleSetSubjectCoverd={handleSetSubjectCoverd}
          duration={duration}
          handleSetDuration={handleSetDuration}
          coursePhoto={coursePhoto}
          loading={loading}
          typeOfPublication={typeOfPublication}
          handleSetTypeOfPublications={handleSetTypeOfPublications}
          photo={photo}
          handleUploadAuthorImage={handleUploadAuthorImage}
          handleSubmitData={handleSubmitData}
        />
      </div>
    </>
  );
};

export default AllNewCourseEdit;
