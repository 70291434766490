import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./circle-progressbar.scss";
import BgShadow from "./img/BgShadow";
import CloseIcon from "./img/CloseIcon";
import logo from "./img/logo.png";
const RetailGameLayout2 = ({fixedGrowth, pathname, children }) => {
  const { totalQuestion, countQuestion,startStep,totalQues, courseName , courseId } = useSelector((state) => ({
    totalQuestion: state.studentGame.totalQuestion,
    countQuestion: state.studentGame.countQuestion,
    startStep: state.retail.startStep,
    totalQues: state.retail.totalQues,
    courseName: state.studentGame.courseName,
    courseId: state.studentGame.courseId,
  }));
  console.log('courseId', courseId);
  console.log('courseName', courseName);
  // console.log();

  return (
    <div className="retail-game-layout-wrap">
      <div className="retail-game-inner-container">
        <div className="quiz-comp-header">
        <Link to={`/game-two-information/${courseId}/${courseName}`}>
            <CloseIcon />
          </Link>
          <div className="inner-circle-complate-profile">
            <div className="flex-wrapper">
              {!pathname && (
                <div className="single-chart">
                  <svg viewBox="0 0 36 36" className="circular-chart blue">
                    <path
                      className="circle-bg"
                      d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path
                      className="circle"
                      strokeDasharray={`${
                        (startStep * 100) / totalQues
                      }, 100`}
                      //   strokeDasharray={`${data?.getData?.percentage}, 100`}
                      d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                  </svg>
                  <span className="percentage">{startStep}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="quiz-comp-content-full-area">
          {fixedGrowth === "final-result-page" ? (
            ""
          ) : (
            <div className="main-logo-area">
              <img src={logo} alt="" />
            </div>
          )}

          {children}
        </div>
        <BgShadow />
      </div>
    </div>
  );
};

export default RetailGameLayout2;
