import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {
  LOGIN_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  REGISTER_LOGIN,
  REGISTER_LOGIN_ERROR,
  REGISTER_LOGIN_SUCCESS,
  STORE_LOGIN_USER,
  UPDATE_USER_INFO,
  UPDATE_USER_INFO_FAIL,
  UPDATE_USER_INFO_SUCCESS,
} from './actionTypes';

const initialState = {
  loading: false,
  username: '',
  token: '',
  email: '',
  userrole: '',
  message: '',
  error: '',
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        username: action.payload.user,
        token: action.payload.token,
        email: action.payload.email,
        userrole: action.payload.userrole,
        loading: false,
      };
      break;
    case LOGIN_USER_ERROR:
      state = {
        ...state,

        loading: false,
      };
      break;
    case LOGOUT_USER:
      state = { ...state, username: '', token: '', email: '', userrole: '' };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state };
      break;
    case UPDATE_USER_INFO:
      state = { ...state, loading: true };
      break;
    case UPDATE_USER_INFO_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message };
      break;
    case UPDATE_USER_INFO_FAIL:
      state = { ...state, loading: false, error: action.payload.message };
      break;
    case REGISTER_LOGIN:
      state = { ...state, loading: true };
      break;
    case REGISTER_LOGIN_SUCCESS:
      state = { ...state, loading: false, message: action.payload.message };
      break;
    case REGISTER_LOGIN_ERROR:
      state = { ...state, loading: false, error: action.payload.error };
      break;
    case STORE_LOGIN_USER:
      state = { ...state, [action.payload.name]: action.payload.data };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const persistConfig = {
  keyPrefix: 'courseware-',
  key: 'login',
  storage,
};

export default persistReducer(persistConfig, login);
