import React from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { addToCart } from "../../../store/actions";
import MainFooter from "../Common/footer/MainFooter";
import Header from "../Common/Header";
import LoginHeader from "../Common/LoginHeader";
import "./cart.scss";
import CartPageBody from "./CartPageBody";
const CartPage = () => {
  const { token, cart, role } = useSelector((state) => ({
    token: state.Login.token,
    cart: state.cartReducer.cart,
    role: state.Login.userrole,
  }));
  let dispatch = useDispatch();

  const updatedetails = (elm, prodtype) => {
    let cart = [];
    if (typeof window !== "undefined") {
      if (localStorage.getItem("cart")) {
        cart = JSON.parse(localStorage.getItem("cart"));
      }
      if (prodtype === "color") {
        cart.map((prod, i) => {
          if (prod._id === elm._id) {
            cart[i].color = elm.target.value;
          }
        });
      } else if (prodtype === "count") {
        let cont = elm;
        if (cont > elm.quantity) {
          toast.error(`Maximum Quantity of this product : ${elm.quantity}`);
          return;
        }

        cart.map((prod, i) => {
          if (prod._id === elm._id) {
            cart[i].count = cont;
          }
        });
      } else if (prodtype === "remove") {
        cart.map((prod, i) => {
          if (prod._id === elm._id) {
            cart.splice(i, 1);
          }
        });
        toast.error(`${elm.courseName} has been removed`);
      }
      // console.log("update cart : ", cart)
      localStorage.setItem("cart", JSON.stringify(cart));
      dispatch(addToCart(cart));
    }
  };

  const handleQuantity = (elm) => {
    let count = "count";
    // updatedetails(elm, count);
    // console.log("Total COunt :", elm);
    cart = JSON.parse(localStorage.getItem("cart"));
    cart.map((prod, i) => {
      if (prod._id === elm._id) {
        cart[i].count = elm;
      }
    });

    dispatch(addToCart(cart));
  };

  const handleIncrement = (e, cartId) => {
    e.preventDefault();
    // let  cart = JSON.parse(localStorage.getItem("cart"));
    cart.map((prod, i) => {
      if (prod._id === cartId) {
        cart[i].count += 1;
      }
    });

    dispatch(addToCart(cart));
    // localStorage.setItem("cart", cart)
  };

  const handleDecrement = (e, cartId) => {
    e.preventDefault();

    // let  cart = JSON.parse(localStorage.getItem("cart"));
    cart.map((prod, i) => {
      if (prod._id === cartId) {
        if (cart[i].count < 1) {
          toast.error("Length can't be less than 0");
        } else {
          cart[i].count -= 1;
        }
      }
    });

    dispatch(addToCart(cart));
    // localStorage.setItem("cart", cart)
  };
  const handleRemoveCart = (e, course) => {
    e.preventDefault();
    // console.log("cart id", cartId)
    updatedetails(course, "remove");
  };
  return (
    <div>
      <MetaTags>
        <title>Cart Page | GameProject</title>
      </MetaTags>
      {token ? <LoginHeader /> : <Header />}

      <CartPageBody
        cart={cart}
        token={token}
        handleRemoveCart={handleRemoveCart}
        handleQuantity={handleQuantity}
        handleIncrement={handleIncrement}
        handleDecrement={handleDecrement}
        role={role}
      />
      <MainFooter />
    </div>
  );
};

export default CartPage;
