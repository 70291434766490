import React, { useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DashboardCommonAllLink from "../../DashboardCommon/DashboardCommonAllLink";
import DashboardCoomonTopbar from "../../DashboardCommon/DashboardCoomonTopbar";
import {
  getFormat,
  updateFormat,
  uploadAuthorPhoto,
} from "./../../../../store/AdminRelated/author/actions";
import FormatEditBody from "./FormatEditBody";
const FormatEdit = () => {
  const { pathname } = useLocation();
  let history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  let { formatId } = useParams();
  const { token, formatData, updatedPhoto } = useSelector((state) => ({
    token: state.Login.token,
    // loading: state.AdminAuthorReducer.loading,
    // courses: state.AdminAuthorReducer.courses,
    formatData: state.AdminAuthorReducer.formatData,
    //  courseRelatedData: state.AdminAuthorReducer.courseRelatedData,
    //  coursePhoto : state.AdminAuthorReducer.coursePhoto
    updatedPhoto: state.AdminAuthorReducer.photo,
  }));

  let dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(true);
  const [toggle, setToggle] = useState(false);
  // const [toggleClass, setToggleClass] = useState("");
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);

    // setToggleClass("showNavbar");
  };

  let [categoryName, setCategoryName] = useState({
    name: formatData?.categoryName,
  });

  let [image, setImage] = useState({
    image: formatData?.image,
  });
  useEffect(() => {
    dispatch(getFormat(token, formatId));
  }, []);

  useEffect(() => {
    if (formatData) {
      setCategoryName({
        name: formatData?.categoryName || "",
      });

      setImage({
        image: formatData?.image || "",
      });
    }
  }, [formatData]);

  const handleSetFormatData = (e) => {
    e.preventDefault();
    setCategoryName({
      name: e.target.value,
    });
  };

  const hanldeUploadImage = (e) => {
    e.preventDefault();

    let formData = new FormData();

    formData.append("photo", e.target.files[0]);
    dispatch(uploadAuthorPhoto(token, formData));
  };

  const handleUpdateFormat = (e) => {
    e.preventDefault();
    console.log("call from here");
    if (updatedPhoto) {
      dispatch(
        updateFormat(token, formatId, categoryName.name, updatedPhoto, history)
      );
    } else {
      dispatch(
        updateFormat(token, formatId, categoryName.name, image.image, history)
      );
    }
  };
  return (
    <>
      <DashboardCoomonTopbar />
      <div
        className={
          toggle
            ? "dashboard-sidebar-nav-area showNavbar"
            : "dashboard-sidebar-nav-area"
        }
      >
        <button
          onClick={() => {
            toggleDrawer();
            setToggle(!toggle);
          }}
          className="main-sidebar-toggle-button"
        >
          <i className="bx bx-menu"></i>
        </button>
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="left"
          className={`navbar-inner-main-wrap-items-wrap `}
        >
          <button
            onClick={() => {
              toggleDrawer();
              setToggle(!toggle);
            }}
            className="main-sidebar-toggle-button"
          >
            <i className="bx bx-menu"></i>
          </button>
          <div className={`navbar-item-single-main-area-jddiu`}>
            <DashboardCommonAllLink />
          </div>
        </Drawer>
      </div>
      <div
        className={
          toggle ? "dashboard-main-body showNavbar" : "dashboard-main-body"
        }
      >
        <FormatEditBody
          categoryName={categoryName}
          handleSetFormatData={handleSetFormatData}
          image={image}
          updatedPhoto={updatedPhoto}
          hanldeUploadImage={hanldeUploadImage}
          history={history}
          handleUpdateFormat={handleUpdateFormat}
        />
      </div>
    </>
  );
};

export default FormatEdit;
