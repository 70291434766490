import
    {
        GET_NUMBERS_OF_ALL_DATA,
        GET_NUMBERS_OF_ALL_DATA_SUCCESS,
        GET_RECENT_COURSES_BY_ADMIN,
        GET_RECENT_COURSES_BY_ADMIN_SUCCESS,
        GET_POPULAR_COURSES_BY_ADMIN,
        GET_POPULAR_COURSES_BY_ADMIN_SUCCESS,
        GET_CATEGORIES_BY_ADMIN,
        GET_CATEGORIES_BY_ADMIN_SUCCESS,
        GET_STUDENT_ENROLLMENT_DATA,
        GET_STUDENT_ENROLLMENT_DATA_SUCCESS,
        GET_ORDER_LIST,
        GET_ORDER_LIST_SUCCESS,
        CREATE_COUPON,
        CREATE_COUPON_SUCCESS,
        CREATE_COUPON_FAIL,
        GET_COUPONS,
        GET_COUPONS_SUCCESS,
        UPDATE_COUPON,
        UPDATE_COUPON_SUCCESS,
        UPDATE_COUPON_FAIL,
        DELETE_COUPON,
        DELETE_COUPON_SUCCESS,
        DELETE_COUPON_FAIL,
        GET_COUPON_BY_USER,
        GET_COUPON_BY_USER_SUCCESS
    } from "./actionTypes"


export const getNumberOfAllData = (token) =>
{
    return {
        type: GET_NUMBERS_OF_ALL_DATA,
        payload : {token}
    }
}


export const getNumberOfAllDataSuccess = data =>
{
    return {
        type: GET_NUMBERS_OF_ALL_DATA_SUCCESS,
        payload:{data}
    }
}

export const getRecentCoursesByAdmin = token =>
{
    return {
        type: GET_RECENT_COURSES_BY_ADMIN,
        payload: {token}
    }
}

export const getRecentCousesByAdminSuccess = data =>
{
    return {
        type: GET_RECENT_COURSES_BY_ADMIN_SUCCESS,
        payload: {data}
    }
}


export const getPopularCoursesByAdmin = token =>
{
    return {
        type: GET_POPULAR_COURSES_BY_ADMIN,
        payload:{token}
    }
}

export const getPopularCorsesByAdminSuccess = data =>
{
    return {
        type: GET_POPULAR_COURSES_BY_ADMIN_SUCCESS,
        payload:{data}
    }
}

export const getCategoriesByAdmin = token =>
{
    return {
        type: GET_CATEGORIES_BY_ADMIN,
        payload:{token}
    }
}

export const getCategoriesByAdminSuccess = data =>
{
    return {
        type: GET_CATEGORIES_BY_ADMIN_SUCCESS,
        payload:{data}
    }
}

export const getStudentEnrolmentData = token =>
{
    return {
        type: GET_STUDENT_ENROLLMENT_DATA,
        payload:{token}
    }
}

export const getStudentEnrolmentDataSuccess = data =>
{
    return {
        type: GET_STUDENT_ENROLLMENT_DATA_SUCCESS,
        payload:{data}
    }
}

export const getOrderList = token =>
{
    return {
        type: GET_ORDER_LIST,
        payload:{token}
    }
}

export const getOrderListSuccess = data =>
{
    return {
        type: GET_ORDER_LIST_SUCCESS,
        payload: {data}
    }
}

export const createCoupon = (token,couponCode, couponPercentage, couponStartingDate, couponEndingDate, history) =>
{
    return {
        type: CREATE_COUPON,
        payload: {token,couponCode, couponPercentage, couponStartingDate, couponEndingDate,history }
   } 
}

export const createCouponSuccess = message =>
{
    return {
        type: CREATE_COUPON_SUCCESS,
        payload: {message}
    }
}

export const createCouponFail = message =>
{
    return {
        type: CREATE_COUPON_FAIL,
        payload: {message}
    }
}

export const getCoupons = (token) =>
{
    return {
        type: GET_COUPONS,
        payload: {token}
    }
}


export const getCouponsSuccess = data =>
{
    return {
        type: GET_COUPONS_SUCCESS,
        payload: {data}
    }
}

export const updateCoupon = (token, couponId, couponStartingDate, couponEndingDate, couponPercentage) =>
{
    return {
        type: UPDATE_COUPON,
        payload: {token,couponId, couponStartingDate, couponEndingDate, couponPercentage}
    }
}

export const updateCouponSuccess = message =>
{
    return {
        type: UPDATE_COUPON_SUCCESS,
        payload: {message}
    }
}

export const updateCouponFail = message =>
{
    return {
        type: UPDATE_COUPON_FAIL,
        payload: {message}
    }
}

export const deleteCoupon = (token, couponId) =>
{
    return {
        type: DELETE_COUPON,
        payload: {token, couponId}
    }
}

export const deleteCouponSuccess = message =>
{
    return {
        type: DELETE_COUPON_SUCCESS,
        payload: {message}
    }
}

export const deleteCouponFail = message =>
{
    return {
        type: DELETE_COUPON_FAIL,
        payload: {message}
    }
}

export const getCouponByUser = () =>
{
    return {
        type: GET_COUPON_BY_USER,
        payload: {}
    }
}

export const getCouponByUserSuccess = data =>
{
    return {
        type: GET_COUPON_BY_USER_SUCCESS,
        payload: {data}
    }
}