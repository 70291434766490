import {
   
    GET_SIX_COURSES_FOR_STUDENTS_SUCCESS,
    GET_SIX_COURSES_FOR_STUDENTS,
    ORDER_COURSE,
    ORDER_COURSE_FAIL,
    ORDER_COURSE_SUCCESS,
    GET_ALL_COURSES_STD,
    GET_ALL_COURSES_SUCCESS,

    GET_STUDENT_OWN_COURSE,
    GET_STUDENT_OWN_COURSE_SUCCESS,
    SUBMIT_MARK,
    SUBMIT_MARK_FAIL,
    SUBMIT_MARK_SUCCESS,
    SHOW_RESULT,
    SHOW_RESULT_SUCCESS,
    COURSE_OWNER_VALID,
    COURSE_OWNER_VALID_FAIL,
    COURSE_OWNER_VALID_SUCCESS,
    GET_RANKING_BASED_ON_COURSE,
    GET_RANKING_BASED_ON_COURSE_SUCCESS,
    VIEW_STUDENTS,
    VIEW_STUDENTS_SUCCESS,
    INSERT_STUDENT_INTO_COURSE,
    INSERT_STUDENT_INTO_COURSE_SUCCESS,
    INSERT_STUDENT_INTO_COURSE_FAIL,
    GET_RANKING_BASED_ON_COURSE_BY_TEACHER,
    GET_RANKING_BASED_ON_COURSE_BY_TEACHER_SUCCESS,
    SEARCH_PRODUCT,
    SEARCH_PRODUCT_SUCCESS,
    SEARCH_QUERY,
    SEARCH_ON_CATEGORY,
    SEARCH_ON_CATEGORY_SUCCESS,
    REMOVE_STUDENTS_FORM_COURSE,
    REMOVE_STUDENTS_FORM_COURSE_FAIL,
    REMOVE_STUDENTS_FORM_COURSE_SUCCESS
} from "./actionTypes"

const initialState = {
    loadign: false,
    searchLoading: false,
    error: "",
    token: "",
    message: "",
    courses: [],
    allCourses: [],
    searchedData: [],
    ownCourse: [],
    result: {},
    position: 0,
    course: {},
    courseValid: false,
    rankData :[],
    students: [],
    orderId: "",
    completedCourse: 0,
    searchQuery : "",
    categoryWiseCourse : []
}

const courseReducer = (state = initialState, action) =>
{
    switch (action.type) {
        case GET_SIX_COURSES_FOR_STUDENTS:
            state = {
                ...state,
                loadign: true
            }
            break
        case GET_SIX_COURSES_FOR_STUDENTS_SUCCESS:
            state = {
                ...state,
                loadign: false,
                courses: action.payload.data
            }
            break
        case ORDER_COURSE:
            state = {
                ...state,
                loadign: true
            }
            break
        case ORDER_COURSE_SUCCESS:
            state = {
                ...state,
                loadign: false,
                message: action.payload.message
            }
            break
        case ORDER_COURSE_FAIL:
            state = {
                ...state,
                loadign: false,
                error : action.payload.message
            }
            break
        case GET_ALL_COURSES_STD:
            state = {
                ...state,
                loadign: true
            }
            break
        case GET_ALL_COURSES_SUCCESS:
            state = {
                ...state,
                loadign: false,
                allCourses: action.payload.data
            }
            break
       
        case GET_STUDENT_OWN_COURSE:
            state = {
                ...state,
                loadign: true
            }
            break
        case GET_STUDENT_OWN_COURSE_SUCCESS:
            state = {
                ...state,
                loadign: false,
                ownCourse: action.payload.data.data,
                completedCourse: action.payload.data.completedCourse

            }
            break
        case SUBMIT_MARK:
            state = {
                ...state,
                loadign: true
            }
            break
        case SUBMIT_MARK_SUCCESS:
            state = {
                ...state,
                loadign: false,
                message: action.payload.message
            }
            break
        case SUBMIT_MARK_FAIL:
            state = {
                ...state,
                loadign: false,
                error: action.payload.message
            }
            break
        case SHOW_RESULT:
            state = {
                ...state,
                loading : true
            }
            break
        case SHOW_RESULT_SUCCESS:
            state = {
                ...state,
                loadign: false,
                result: action.payload.data.findStudentMark,
                position: action.payload.data.serial,
                course: action.payload.data.findCourse,
            }
            break
        case COURSE_OWNER_VALID:
            state = {
                ...state,
                loadign: true
            }
            break
        case COURSE_OWNER_VALID_SUCCESS:
            state = {
                ...state,
                loadign: false,
                courseValid: action.payload.data.valid,
                orderId: action.payload.data.orderId
            }
            break
        case COURSE_OWNER_VALID_FAIL:
            state = {
                ...state,
                loadign: false,
                courseValid: action.payload.data
            }
            break
        case GET_RANKING_BASED_ON_COURSE:
            state = {
                ...state,
                loadign: true
            }
            break
        case GET_RANKING_BASED_ON_COURSE_SUCCESS:
            state = {
                ...state,
                loadign: false,
                rankData: action.payload.data
            }
            break
        case GET_RANKING_BASED_ON_COURSE_BY_TEACHER:
            state = {
                ...state,
                loadign: true
            }
            break
        case GET_RANKING_BASED_ON_COURSE_BY_TEACHER_SUCCESS:
            state = {
                ...state,
                loadign: false,
                rankData: action.payload.data
            }
            break
        case VIEW_STUDENTS:
            state = {
                ...state,
                loadign: true
            }
            break
        case VIEW_STUDENTS_SUCCESS:
            state = {
                ...state,
                loadign: false,
                students: action.payload.data
            }
            break
        case INSERT_STUDENT_INTO_COURSE:
            state = {
                ...state,
                loadign: true
            }
            break
        
        case INSERT_STUDENT_INTO_COURSE_SUCCESS:
            state = {
                ...state,
                loadign: false,
                message  : action.payload.message
            }
            break
        case INSERT_STUDENT_INTO_COURSE_FAIL:
            state = {
                ...state,
                loadign: false,
                error : action.payload.message
            }
            break
        case SEARCH_QUERY:
            state = {
                ...state,
                loadign: false,
                searchQuery : action.payload.text
            }
            break
        case SEARCH_ON_CATEGORY:
            state = {
                ...state,
                loadign: true
            }
            break
        case SEARCH_ON_CATEGORY_SUCCESS:
            state = {
                ...state,
                loadign: false,
                allCourses: action.payload.data
            }
            break
        case REMOVE_STUDENTS_FORM_COURSE:
            state = {
                ...state,
                loadign: true
            }
            break
        case REMOVE_STUDENTS_FORM_COURSE_SUCCESS:
            state = {
                ...state,
                loadign: false,
                message : action.payload.message
            }
            break
        case REMOVE_STUDENTS_FORM_COURSE_FAIL:
            state = {
                ...state,
                loadign: false,
                error : action.payload.message
            }
            break
        default:
              state = state
              break
    }
    return state
}


export default courseReducer