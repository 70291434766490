import React from "react";
import { Container } from "react-bootstrap";
import ListHeader from "./ListHeader";
import "./style.css";
const PyramidRatio1 = () => {
  const financialProfit = () => {
    document.getElementById("roe").style =
      "background-color: #f7b941;color: white";
    document.getElementById("roa").style = "background-color: white";
    document.getElementById("grossSales").style = "background-color: white";
    document.getElementById("totalAsset").style = "background-color: white";
    document.getElementById("fixedAsset").style = "background-color: white";
    document.getElementById("inventory").style = "background-color: white";
    document.getElementById("Solvency").style = "background-color: white";
    document.getElementById("debt").style = "background-color: white";
    document.getElementById("Coverage").style = "background-color: white";
    document.getElementById("equity").style = "background-color: white";
    document.getElementById("currentRatio").style = "background-color: white";
    document.getElementById("quickRatio").style = "background-color: white";
    document.getElementById("sellInventory").style = "background-color: white";
    document.getElementById("accounts").style = "background-color: white";
    document.getElementById("period").style = "background-color: white";
    document.getElementById("operating").style = "background-color: white";
  };
  const investmentPolicy = () => {
    document.getElementById("roe").style = "background-color: white";
    document.getElementById("roa").style = "background-color: white";
    document.getElementById("grossSales").style = "background-color: white";
    document.getElementById("Solvency").style = "background-color: white";
    document.getElementById("debt").style = "background-color: white";
    document.getElementById("Coverage").style = "background-color: white";
    document.getElementById("equity").style = "background-color: white";
    document.getElementById("currentRatio").style = "background-color: white";
    document.getElementById("quickRatio").style = "background-color: white";
    document.getElementById("sellInventory").style = "background-color: white";
    document.getElementById("accounts").style = "background-color: white";
    document.getElementById("period").style = "background-color: white";
    document.getElementById("operating").style = "background-color: white";
    document.getElementById("totalAsset").style =
      "background-color: #5896FE;color: white;border:1px solid #5896FE!important";
    document.getElementById("fixedAsset").style =
      "background-color: #5896FE;color: white;border:1px solid #5896FE!important";
    document.getElementById("inventory").style =
      "background-color: #5896FE;color: white;border:1px solid #5896FE!important";
  };
  const productMarket = () => {
    document.getElementById("roe").style = "background-color: white";
    document.getElementById("Solvency").style = "background-color: white";
    document.getElementById("debt").style = "background-color: white";
    document.getElementById("Coverage").style = "background-color: white";
    document.getElementById("equity").style = "background-color: white";
    document.getElementById("currentRatio").style = "background-color: white";
    document.getElementById("quickRatio").style = "background-color: white";
    document.getElementById("sellInventory").style = "background-color: white";
    document.getElementById("accounts").style = "background-color: white";
    document.getElementById("period").style = "background-color: white";
    document.getElementById("operating").style = "background-color: white";
    document.getElementById("roa").style =
      "background-color: #EC407A;color: white;border:1px solid #EC407A!important";

    document.getElementById("grossSales").style =
      "background-color: #EC407A;border:1px solid #EC407A!important; color:white!important";
    document.getElementById("totalAsset").style =
      "background-color: #EC407A;color: white;border:1px solid #EC407A!important";
    document.getElementById("fixedAsset").style =
      "background-color: #EC407A;color: white;border:1px solid #EC407A!important";
    document.getElementById("inventory").style =
      "background-color: #EC407A;color: white;border:1px solid #EC407A!important";
  };
  const financialMarket = () => {
    document.getElementById("roe").style = "background-color: white";
    document.getElementById("roa").style = "background-color: white";
    document.getElementById("grossSales").style = "background-color: white";
    document.getElementById("totalAsset").style = "background-color: white";
    document.getElementById("fixedAsset").style = "background-color: white";
    document.getElementById("inventory").style = "background-color: white";
    document.getElementById("operating").style = "background-color: white";
    document.getElementById("Solvency").style =
      "background-color: #27AE60;color: white;border:1px solid #27AE60!important";
    document.getElementById("debt").style =
      "background-color: #27AE60;color: white;border:1px solid #27AE60!important";
    document.getElementById("Coverage").style =
      "background-color: #27AE60;color: white;border:1px solid #27AE60!important";
    document.getElementById("equity").style =
      "background-color: #27AE60;color: white;border:1px solid #27AE60!important";
  };

  const operationsManagement = () => {
    document.getElementById("roe").style = "background-color: white";
    document.getElementById("roa").style = "background-color: white";
    document.getElementById("grossSales").style = "background-color: white";
    document.getElementById("totalAsset").style = "background-color: white";
    document.getElementById("fixedAsset").style = "background-color: white";
    document.getElementById("inventory").style = "background-color: white";
    document.getElementById("Solvency").style = "background-color: white";
    document.getElementById("debt").style = "background-color: white";
    document.getElementById("Coverage").style = "background-color: white";
    document.getElementById("equity").style = "background-color: white";
    document.getElementById("currentRatio").style =
      "background-color: #26C6DA;color: white;border:1px solid #26C6DA!important";
    document.getElementById("quickRatio").style =
      "background-color: #26C6DA;color: white;border:1px solid #26C6DA!important";
    document.getElementById("sellInventory").style =
      "background-color: #26C6DA;color: white;border:1px solid #26C6DA!important";
    document.getElementById("accounts").style =
      "background-color: #26C6DA;color: white;border:1px solid #26C6DA!important";
    document.getElementById("period").style =
      "background-color: #26C6DA;color: white;border:1px solid #26C6DA!important";
    document.getElementById("operating").style =
      "background-color: #26C6DA;color: white;border:1px solid #26C6DA!important";
  };
  const financingpolicy = () => {
    document.getElementById("roe").style = "background-color: white";
    document.getElementById("roa").style = "background-color: white";
    document.getElementById("grossSales").style = "background-color: white";
    document.getElementById("totalAsset").style = "background-color: white";
    document.getElementById("fixedAsset").style = "background-color: white";
    document.getElementById("inventory").style = "background-color: white";
    document.getElementById("Solvency").style = "background-color: white";
    document.getElementById("debt").style = "background-color: white";
    document.getElementById("Coverage").style = "background-color: white";
    document.getElementById("operating").style = "background-color: white";
    document.getElementById("equity").style =
      "background-color: #B751E1;color: white;border:1px solid #B751E1!important";
    document.getElementById("currentRatio").style = "background-color: white";
    document.getElementById("quickRatio").style = "background-color: white";
    document.getElementById("sellInventory").style =
      "background-color: #B751E1;color: white;border:1px solid #B751E1!important";
    document.getElementById("accounts").style =
      "background-color: #B751E1;color: white;border:1px solid #B751E1!important";
    document.getElementById("period").style =
      "background-color: #B751E1;color: white;border:1px solid #B751E1!important";
  };
  return (
    <div>
      <Container>
        <div className="case-method-list">
          <div>
            <ListHeader />
            <div className="pyramid-main-title-wra-grgbvcrtyhg">
              <h3>Pyramid of Ratios</h3>
            </div>
            <div className="pyramid-tree-main-area">
              <div className="pyramid-main-head-1st">
                <div className="pyramid-item-wrap">
                  <div className="pyramid-item first-row">
                    <div className="single-item" id="roe">
                      <>
                        ROE<sup>(1)</sup>
                      </>
                    </div>
                  </div>
                  <div className="pyramid-item-second-row-main-wrap">
                    <div className="pyramid-item-second-row">
                      <div className="pyramid-item-left">
                        <div className="single-item" id="roa">
                          <>
                            ROA<sup>(1)</sup>
                          </>
                        </div>
                        <div className="pyramid-item-left-inner-third-row">
                          <div
                            className="single-item third-row-left"
                            id="grossSales"
                          >
                            <>Gross Profit/Sales</>
                          </div>
                          <div className=" third-row-left-item-right-tree">
                            <div className="single-item" id="totalAsset">
                              <>Total Asset turnover</>
                            </div>{" "}
                            <div
                              className="single-item fixed-asset-turnover"
                              id="fixedAsset"
                            >
                              <>Fixed Asset turnover</>
                            </div>{" "}
                            <div className="inventory-turnover-left-items-wrap">
                              <div
                                className="single-item inventory-turnover"
                                id="inventory"
                              >
                                <>
                                  Inventory turnover<sup>TM</sup>
                                </>
                              </div>
                              <div className="inventory-turnover-left-items-inner-wrap">
                                <div className="inventory-turnover-left-single-item-wrap">
                                  <div
                                    className="single-item"
                                    id="sellInventory"
                                  >
                                    <>Average days to sell inventory</>
                                  </div>{" "}
                                  <div className="single-item" id="accounts">
                                    <>Average accounts receivalbe (clientes)</>
                                  </div>{" "}
                                  <div className="single-item" id="period">
                                    <>Average payment period (suppliers)</>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="inventory-turnover-left-items-wrap right-items-wrap-ghbnjmk">
                              <div
                                className="single-item inventory-turnover right-items-wrap-ghbnjmk"
                                id="currentRatio"
                              >
                                <>Current ratio</>
                              </div>
                              <div className="inventory-turnover-left-items-inner-wrap right-items-wrap-ghbnjmk">
                                <div className="inventory-turnover-left-single-item-wrap right-items-wrap-ghbnjmk">
                                  <div className="single-item" id="quickRatio">
                                    <>Quick ratio</>
                                  </div>{" "}
                                  <div className="single-item" id="operating">
                                    <>Operating expenses coverage</>
                                  </div>{" "}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pyramid-item-right">
                        <div className="single-item" id="Solvency">
                          <>Solvency</>
                        </div>
                        <div className="pyramid-item-left-inner-third-row ">
                          <div className="gvhbjnkmkjhbgv">
                            <div className="single-item" id="debt">
                              <>Average cost of debt</>
                            </div>
                            <div className="single-item" id="Coverage">
                              <>Interests Coverage</>
                            </div>
                            {/* <div className="single-item">
                              <>Average cost of debt</>
                            </div> */}
                          </div>
                          <div
                            className="single-item third-row-right"
                            id="equity"
                          >
                            <>Debt to equity ratio</>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="type-of-ratio">
              <div className="title-radio-button-pyramid">
                <h5>TYPES OF RATIOS</h5>
              </div>
              <div>
                <div>
                  {" "}
                  <input
                    type="radio"
                    id="profit"
                    name="fav_language"
                    value="HTML"
                    onClick={financialProfit}
                  />{" "}
                  <label for="profit">Financial profit</label>
                </div>
                <div>
                  {" "}
                  <input
                    type="radio"
                    id="market"
                    name="fav_language"
                    value="CSS"
                    onClick={productMarket}
                  />{" "}
                  <label for="market"> Product market</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="fin"
                    name="fav_language"
                    value="JavaScript"
                    onClick={financialMarket}
                  />
                  <label for="fin">Financial market</label>
                </div>
                <div>
                  {" "}
                  <input
                    type="radio"
                    id="Investment"
                    name="fav_language"
                    value="CSS"
                    onClick={investmentPolicy}
                  />{" "}
                  <label for="Investment"> Investment policy</label>
                </div>
                <div>
                  {" "}
                  <input
                    type="radio"
                    id="Operations"
                    name="fav_language"
                    value="CSS"
                    onClick={operationsManagement}
                  />{" "}
                  <label for="Operations">Operations management</label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="Financing"
                    name="fav_language"
                    value="CSS"
                    onClick={financingpolicy}
                  />{" "}
                  <label for="Financing"> Financing policy</label>
                </div>
                <p>
                  Select a ratio to see how the other ratios interact with it.
                </p>
              </div>
            </div>
            <div className="pyramid-ration-content-main-area">
              <p>(1) Return on Equity</p>
              <p>(2) Return on Assest</p>
              <p>(3) Inverse of the average storage period</p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PyramidRatio1;
