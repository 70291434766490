import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { countryData } from "./countryData";

const GrowthDrivers = () => {
  const { countryName } = useSelector((state) => state?.gdpCourse);
  console.log(countryName);
  const studentData = {};
  countryName?.map((el, id) => {
    studentData[el?.countryTitle] = el?.gdpNumber;
  });
  return (
    <>
      <div className="show-growth-drivers-full-area-wrap">
        <Row>
          <Col lg="6">
            <div className="growth-drivers-your-prediction">
              <h3>Your prediction</h3>
              <div className="growth-drivers-yours-p-list">
                {countryData &&
                  countryData.map((data, idx) => (
                    <div className="g-d-y-p-single-list" key={idx}>
                      <img src={data?.flag} alt="Country" />
                      <div className="g-d-y-p-single-list-right-content">
                        <div className="g-d-y-p-s-l-r-content-left">
                          <span>{idx + 1}</span>
                          <span>{data?.shortName}</span>
                        </div>
                        <div className="g-d-y-p-s-l-r-content-right">
                          {studentData[data?.shortName]}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </Col>
          <Col lg="6">
            <div className="growth-drivers-your-prediction">
              <h3>Actual GDP</h3>
              <div className="growth-drivers-yours-p-list">
                {countryData &&
                  countryData.map((data, idx) => (
                    <div className="g-d-y-p-single-list" key={idx}>
                      <img src={data?.flag} alt="Country" />
                      <div className="g-d-y-p-single-list-right-content">
                        <div className="g-d-y-p-s-l-r-content-left">
                          <span>{idx + 1}</span>
                          <span>{data?.shortName}</span>
                        </div>
                        <div className="g-d-y-p-s-l-r-content-right">10</div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default GrowthDrivers;
