import { call, put, takeEvery } from "redux-saga/effects"

import
    {
        GET_NUMBERS_OF_ALL_DATA,
        GET_RECENT_COURSES_BY_ADMIN,
        GET_POPULAR_COURSES_BY_ADMIN,
        GET_CATEGORIES_BY_ADMIN,
        GET_STUDENT_ENROLLMENT_DATA,
        GET_STUDENT_ENROLLMENT_DATA_SUCCESS,
        GET_COUPONS,
        CREATE_COUPON,
        UPDATE_COUPON,
        DELETE_COUPON,
         GET_COUPON_BY_USER

    } from "./actionTypes"
 
import
    {
        getNumberOfAllDataSuccess,
        getRecentCousesByAdminSuccess,
        getPopularCorsesByAdminSuccess,
        getCategoriesByAdminSuccess,
        getStudentEnrolmentDataSuccess,
        createCouponSuccess,
        createCouponFail,
        getCouponsSuccess,
        updateCouponFail,
        updateCouponSuccess,
        deleteCouponSuccess,
        deleteCouponFail,
        getCouponByUserSuccess

    } from "./actions"
import { toast } from "react-toastify"

import
    {
        axiosGetNumberDataAdminPage,
        axiosGetRecentCourses,
        axiosGetPopularCourses,
        axiosGetCategories,
        axiosGetStudentEnrolmentData,
        axiosCreateCoupon,
        axiosUpdateCoupon,
        axiosDeleteCoupon,
        axiosGetAllCouponForAdmin,
        axiosGetAllCouponForStudent,
        axiosGetCouponByUser
    } from "../../../helpers/api_helper"

const asyncGetAllNumbersRelatedDataAdmin = async (token) =>
{
    try {
        const response = await axiosGetNumberDataAdminPage(token)
        // console.log("resposne", response.data)
         return response.data
    } catch (error) {
        return error.response.data
    }
}

function* workerGetALlNumbersData({ payload: { token } })
{
    try {
         let response = yield call(asyncGetAllNumbersRelatedDataAdmin,token)
         yield put(getNumberOfAllDataSuccess(response.getDatas))
    } catch (error) {
        
    }

}



//get recentcourses
const asyncGetRecentCourse = async (token) =>
{
    try {
         const response = await axiosGetRecentCourses(token)
        return response.data
    } catch (error) {
        
    }
}
function* workerAdminGetRecentCourses({ payload: { token } })
{
    try {
        const response = yield call(asyncGetRecentCourse, token)
        // console.log("response data 48", response.getTopCourse)
        yield put(getRecentCousesByAdminSuccess(response.getTopCourse))
    } catch (error) {
        
    }
}

//get Popular Courses
const asyncGetPopularCoursesByAdmin = async (token) =>
{
    try {
        const response = await axiosGetPopularCourses(token)
        // console.log("response", response)
        return response.data
    } catch (error) {
        
    }
}
function* workerGetPopularCoursesByAdmin({ payload: { token } })
{
    try {
        let response = yield call(asyncGetPopularCoursesByAdmin, token)
    
    yield put(getPopularCorsesByAdminSuccess(response.getTopCourse))
    } catch (error) {
        
    }
    
}


//function get Categories
const asyncGetCategories = async (token) =>
{
    try {
         const response = await axiosGetCategories(token)
         return response.data
    } catch (error) {
        return error.response.data
    }
}
function* workerGetCategoires({ payload: { token } })
{
    try {
 
        const response = yield call(asyncGetCategories, token)
         yield put(getCategoriesByAdminSuccess(response.getData))
    } catch (error) {
        
    }
}


//enrollment data

const asyncEnrollmentData = async token =>
{
    try {
        const response = await axiosGetStudentEnrolmentData(token)
        return response.data
    } catch (error) {
        return error.response.data
    }
}
function* workerEnrollmentData({ payload: { token } })
{
    try {
        const response = yield call(asyncEnrollmentData, token)
         yield put(getStudentEnrolmentDataSuccess(response.newArr))
    } catch (error) {
        
    }
}

//update coupon
const asyncUpdateCoupon = async (token, couponId, couponStartingDate, couponEndingDate, couponPercentage) =>
{
    try {
        const response = axiosUpdateCoupon(token, couponId, couponStartingDate, couponEndingDate, couponPercentage)
        return response.data
    } catch (error) {
        return error.response.data
    }
}
function* workerUpdateCoupon({ token, couponId, couponStartingDate, couponEndingDate, couponPercentage })
{
    try {
        const response = yield call(asyncUpdateCoupon, token, couponId, couponStartingDate, couponEndingDate, couponPercentage)
    
        yield put(updateCouponSuccess(response.message))
    } catch (error) {
        console.log("error data", error)
    }
 }

//get coupons

//async Coupon 
const asyncCouponCreate =async (token, couponCode, couponPercentage, couponStartingDate, couponEndingDate) =>
{
    try {
        const response = await axiosCreateCoupon(token, couponCode, couponPercentage, couponStartingDate, couponEndingDate)
        return response.data
    } catch (error) {
        return error.response.data
    }
}
function* workerCreateCoupon({ payload: { token, couponCode, couponPercentage, couponStartingDate, couponEndingDate, history }})
{ 
    try {
    //    console.log("token data here", token, couponCode)
        const response = yield call(asyncCouponCreate, token, couponCode, couponPercentage, couponStartingDate, couponEndingDate)
        if (response.status == "success") {
            yield put(createCouponSuccess(response.message))
            toast.success(response.message, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            yield put(createCouponSuccess())
            history.push("/coupon")

        } else {
            yield put(createCouponFail(response.message))
            toast.error(response.message, {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            yield put(createCouponFail())

        }
    } catch (error) {
        console.log("error data")
    }
}

//delete coupon
const asyncDeleteCoupon = async (token, couponId) =>
{
    try {
        const response = await axiosDeleteCoupon(token, couponId)
        return response.data
    } catch (error) {
        return error.response.data
    }
}
function* workerDeleteCoupon({ payload: { token, couponId } })
{
    try {
        const response = yield call(asyncDeleteCoupon, token, couponId)
        if (response.status == "success") {
            yield put(deleteCouponSuccess(response.message))
            let res = yield call(asyncGetCoupons, token)
            yield put(getCouponsSuccess(res.getDatas))

            yield put(deleteCouponSuccess())
        } else {
            yield put(deleteCouponFail(response.message))
            yield put(deleteCouponFail())
        }
    } catch (error) {
        
    }
}
//async
const asyncGetCoupons = async (token) =>
{
    try {
        const response = await axiosGetAllCouponForAdmin(token)
        return response.data
    } catch (error) {
        return error.response.data
    }
}
function* workerGetCoupons({ payload: { token } })
{
    try {
        // console.log("coupon name", token)
        const response = yield call(asyncGetCoupons, token)
        // console.log("response data ", response)
        // console.log("coupon data here", response.getDatas)
        yield put(getCouponsSuccess(response.getDatas))
    } catch (error) {
        console.log("error data", error)
    }
}


//get coupon for student
const asyncGetCouponByUser = async () =>
{
    try {
        const response = await axiosGetCouponByUser()
        return response.data
    } catch (error) {
        return error.response.data
    }
}
function* workerGetCouponByUser()
{
    try {
        const response = yield call(asyncGetCouponByUser)
        console.log("response data here", response)
        yield put(getCouponByUserSuccess(response.getData))
    } catch (error) {
        console.log("error data", error)
    }
}

function* adminDashboardPage()
{
    
    yield takeEvery(GET_NUMBERS_OF_ALL_DATA, workerGetALlNumbersData)
    yield takeEvery(GET_RECENT_COURSES_BY_ADMIN, workerAdminGetRecentCourses)
    yield takeEvery(GET_POPULAR_COURSES_BY_ADMIN, workerGetPopularCoursesByAdmin)
    yield takeEvery(GET_CATEGORIES_BY_ADMIN, workerGetCategoires)
    yield takeEvery(GET_STUDENT_ENROLLMENT_DATA, workerEnrollmentData)
    yield takeEvery(GET_COUPONS, workerGetCoupons)
    yield takeEvery(UPDATE_COUPON, workerUpdateCoupon)
    yield takeEvery(CREATE_COUPON, workerCreateCoupon)
    yield takeEvery(DELETE_COUPON, workerDeleteCoupon)
    yield takeEvery(GET_COUPON_BY_USER, workerGetCouponByUser)
}


export default adminDashboardPage