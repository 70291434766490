import {
	RECRUITE_MANAGER,
	RECRUITE_MANAGER_FAIL,
	RECRUITE_MANAGER_SUCCESS,
	CALCULATE_BUDGETING,
	CALCULATE_BUDGETING_SUCCESS,
	ADD_COST_OF_FINANCE,
	ADD_COST_OF_FINANCE_SUCCESS,
	GET_REVENUE_ON_TAB,
	GET_REVENUE_ON_TAB_SUCCESS,
	SET_SELLING_PRICE,
	SET_SELLING_PRICE_SUCCESS,
	CALCULATE_REVENUE,
	CALCULATE_REVENUE_SUCCESS,
	FINANCIAL_STATEMENT,
	FINANCIAL_STATEMENT_SUCCESS,
	CLEAN_DATA_PLAY_AGAIN,
	CLEAN_DATA_PLAY_AGAIN_SUCCESS,
} from "./actionTypes";

//recruitmanager
export const addRecruitManager = (budget, position) => {
	return {
		type: RECRUITE_MANAGER,
		payload: { budget, position },
	};
};

//recruitmanager success
export const addRecruitManagerSuccess = (data) => {
	return {
		type: RECRUITE_MANAGER_SUCCESS,
		payload: { data },
	};
};

//recruitmanager fail
export const addRecruitManagerFail = (data) => {
	return {
		type: RECRUITE_MANAGER_FAIL,
		payload: { data },
	};
};

//calculate budgeting
export const calculateBudgeting = (capacityUtilization, prodSerial) => {
	return {
		type: CALCULATE_BUDGETING,
		payload: { capacityUtilization, prodSerial },
	};
};

export const calculateBudgetingSuccess = (data) => {
	return {
		type: CALCULATE_BUDGETING_SUCCESS,
		payload: { data },
	};
};

export const addCostOfFinance = (cost, costType) => {
	return {
		type: ADD_COST_OF_FINANCE,
		payload: { cost, costType },
	};
};

export const addCostOfFinanceSuccess = (data) => {
	return {
		type: ADD_COST_OF_FINANCE_SUCCESS,
		payload: { data },
	};
};

export const getReveuneOnTab = (month) => {
	return {
		type: GET_REVENUE_ON_TAB,
		payload: { month },
	};
};

export const getRevenueOnTabSuccess = (data) => {
	return {
		type: GET_REVENUE_ON_TAB_SUCCESS,
		payload: { data },
	};
};

export const setSellingPrice = (markeUp, month, prodName) => {
	return {
		type: SET_SELLING_PRICE,
		payload: { markeUp, month, prodName },
	};
};

export const setSellingPriceSuccess = (data) => {
	return {
		type: SET_SELLING_PRICE_SUCCESS,
		payload: { data },
	};
};

export const calculateRevenue = (monthSerial, prodName, unitSold) => {
	return {
		type: CALCULATE_REVENUE,
		payload: { monthSerial, prodName, unitSold },
	};
};

export const calculateRevenueSuccess = (data) => {
	return {
		type: CALCULATE_REVENUE_SUCCESS,
		payload: { data },
	};
};

export const financialStatement = (elementType, revenueOrCost, position) => {
	return {
		type: FINANCIAL_STATEMENT,
		payload: { elementType, revenueOrCost, position },
	};
};

export const financialStatementSuccess = (data) => {
	return {
		type: FINANCIAL_STATEMENT_SUCCESS,
		payload: { data },
	};
};

export const cleanDataPlayAgain = (empty, courseId, history) => {
	return {
		type: CLEAN_DATA_PLAY_AGAIN,
		payload: { empty,courseId, history },
	};
};

export const cleanDataPlayAgainSuccess = () => {
	return {
		type: CLEAN_DATA_PLAY_AGAIN_SUCCESS,
		payload: {  },
	};
};
