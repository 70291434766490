import React, { useEffect } from "react";
import { useAudioPlayer } from "../../Hooks/AudioPlayerHooks";
import { AudioPlayer } from "../Audio/AudioPlayer";

const AudioSet = ({ track, pauseTime }) => {
  const {
    audioPlayer,
    backward,
    changeRange,
    currentTime,
    duration,
    forward,
    isPlaying,
    // play,
    progressBar,
    mute,
    // setIsPlaying,
    togglePlayPause,
    // setTimeJump,
    toggleMute,
  } = useAudioPlayer();

  // handle time jumps
  // const timeJump = (time) => {
  //   setTimeJump(time);
  //   setIsPlaying(true);
  //   play();
  // };

  //   controlled pause time
  // useEffect(() => {
  //   if (!!pauseTime) {
  //     if (currentTime === pauseTime) {
  //       togglePlayPause();
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentTime]);

  const data = {
    audioPlayer,
    backward: () => backward(5),
    changeRange,
    currentTime,
    duration,
    forward: () => forward(5),
    isPlaying,
    progressBar,
    togglePlayPause,
    track,
    mute,
    toggleMute,
   
  };
  return (
    <React.Fragment>
      <AudioPlayer data={data} />
    </React.Fragment>
  );
};

export default AudioSet;
