import React from "react";
import { Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import deleteIcon from "../../img/close-circle.svg";
import editIcon from "../../img/edit-2.svg";
import { FormatData } from "./FormatData";
const FormatBody = ({categories, loading}) => {
  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>Format List</h3>
            <p>
              Course list {`>`} <span>Format List</span>{" "}
            </p>
          </div>
        </div>
        <div className="packge-header-right">
          <Link to="/create-new-format">
            <span>+ </span>
            Create New
          </Link>
        </div>
      </div>
      <div className="data-search-and-filtering-area-wrap">
        <div className="data-search-inner-area-jhhbcvhkjzjghfjdn">
          <div className="search-box-dfghjkiujfb">
            <input type="search" name="" id="" />
          </div>
          <div className="select-filtering-area-jyfgvasjyhdfb">
            <Form.Group>
              <Form.Select>
                <option value="">Select name</option>
              </Form.Select>
            </Form.Group>
          </div>
        </div>
      </div>
      <div className="package-main-content-list">
        <Table borderless>
          <thead className="package-main-content-list-table-header">
            <tr>
              <th>#</th>
              <th>Formate Name</th>
              <th>Course Count</th>
              <th>Slug</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loading && <Spinner />}
            {!loading && categories?.map((item, i) => {
              return (
                <tr key={i}>
                  <td>{i +1}</td>
                  <td>{item.categoryName}</td>

                  <td>Null</td>
                  <td>{item.categoryName}</td>
                  <td>
                    <div className="package-control-action-area">
                      <Link to={`/formate-edit/${item._id}`}>
                        <img src={editIcon} alt="" /> Edit
                      </Link>
                      <button>
                        <img src={deleteIcon} alt="" />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default FormatBody;
