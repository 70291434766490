import React from "react";
import { Container } from "react-bootstrap";
// import { Container } from "reactstrap";
import commingSoonLogo from "./Screenshot_1.png";
const Attendence = () => {
  return (
    <div>
      <Container fluid>
        <div className="dashboard-main-content-area">
          <div className="title">
            <p>Attendence</p>
          </div>
          <div className="commin-soon-pages">
            <img src={commingSoonLogo} alt="" />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Attendence;
