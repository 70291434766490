import { useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import Modal from "react-responsive-modal";
import { withRouter } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import {
	deleteData,
	patchData,
	postData,
} from "../../../../helpers/backend_helper";
import videoUploadBg from "../../LafargeAssets/images/video-upload.svg";
import AsCoustomer from "./AsCoustomer";
import AsOrganization from "./AsOrganization";
function getExtension(filename) {
	var parts = filename.split(".");
	return parts[parts.length - 1];
}

const isVideo = (filename) => {
	var ext = getExtension(filename);
	switch (ext.toLowerCase()) {
		case "m4v":
		case "avi":
		case "mpg":
		case "mp4":
		case "mov":
		case "wmv":
		case "flv":
			// etc
			return true;
		default:
			return false;
	}
};

const LafargeFileUpload = ({
	lafargePopup,
	setLafargePopup,
	questions,
	history,
}) => {
	const { token } = useSelector((state) => ({
		token: state.Login.token,
	}));
	const [uploading, setUploading] = useState(false);
	const [uploadedFile, setUploadedFile] = useState(null);
	const [submitting, setSubmitting] = useState(false);
	const [chooseOtion, setChooseOtion] = useState({
		option1: "",
		option2: "",
	});

	const { questionID } = useParams();

	console.log("questionID", questionID);

	const [optionValue, setOptionValue] = useState("");
	const handleOtionSubmit = (e) => {
		e.preventDefault();
		setOptionValue(chooseOtion);
		setLafargePopup(false);
		setChooseOtion("");
	};
	console.log(optionValue);

	const [removeVideo, setRemoveVideo] = useState(false);
	const deleteVideo = async () => {
		setRemoveVideo(true);
		try {
			let vd = uploadedFile?.video.split("/");
			let url = vd.pop();
			// console.log('vd here ', url);
			await deleteData(`/delete/video/${url}/${uploadedFile?.id}`, token);
			setRemoveVideo(false);
			setUploadedFile(null);
		} catch (err) {
			setRemoveVideo(false);
			console.log(err);
		}
	};

	const handleFile = async (e) => {
		const file = e.target.files?.[0];
		console.log(`line 46 ~ handleFile ~ file`, file);
		if (!isVideo(file?.name)) {
			return alert(`Please upload only video file `);
		}
		if (file) {
			const sizeInMB = (file?.size / (1024 * 1024)).toFixed(2);
			if (sizeInMB > 50) {
				return alert(
					`File size should be less than 50 MB, You can compress your video through https://www.veed.io/video-compressor link `,
				);
			}
			if (uploadedFile) {
				await deleteVideo();
			}
			setUploading(true);
			const formData = new FormData();
			formData.append("video", file);
			try {
				const response = await postData(
					"/admin/lafarz/uploadPitch",
					formData,
					token,
				);
				if (response) {
					setUploadedFile(response);
					setUploading(false);
				}
			} catch (err) {
				console.log(err);
				setUploading(false);
			}
		}
	};
	const handleSubmit = async () => {
		setSubmitting(true);
		console.log("question id", questionID);
		try {
			const url = `/user/question-answer/${questionID}`;
			const submit = await patchData(
				url,
				{
					question: "",
					questionId: 10,
					sl: chooseOtion?.option1 ? 1 : 2,
					questionType: "",
					answer: uploadedFile?.video,
				},
				token,
			);
			if (submit) {
				console.log("question id here", questions);
				await postData(`/user/final-submit/${questionID}`, {}, token);
				history.push("/succsessfull-submit");
			}
			setSubmitting(false);
		} catch (error) {
			console.log(error);
			setSubmitting(false);
		}
	};

	return (
		<div>
			<Container>
				<Modal
					closeOnOverlayClick={false}
					open={lafargePopup}
					onClose={() => setLafargePopup(false)}
					classNames={{
						overlay: "lafarge-file-upload-Overlay",
						modal: "lafarge-file-upload-Modal",
					}}
					center>
					<div className="popup-modal-main-wrap">
						<h3>How do you want to play?</h3>
						<div className="lafarge-training-inner-wrap popup-file-option-select">
							<div className="lafarge-ques-items-wrap">
								<div className="lafarge-exercise-ques-main-wrap">
									<form
										onSubmit={handleOtionSubmit}
										className="lafarge-exercise-single-item ">
										<div className="lafarge-exercise-single-item-inner">
											<Row>
												<Col md={12}>
													<div className="lafarge-exercise-single-item-inner-right">
														<div className="radio-buttons">
															<div className="form-group">
																<input
																	type="radio"
																	id="male"
																	name="gender"
																	value="as_a_coustomer"
																	onChange={(
																		e,
																	) =>
																		setChooseOtion(
																			{
																				...chooseOtion,
																				option1:
																					e
																						.target
																						.value,
																			},
																		)
																	}
																/>
																<label htmlFor="male">
																	As a
																	Distributor
																</label>
															</div>

															<div className="form-group">
																<input
																	type="radio"
																	id="female"
																	name="gender"
																	value="as_a_organization"
																	onChange={(
																		e,
																	) =>
																		setChooseOtion(
																			{
																				...chooseOtion,
																				option2:
																					e
																						.target
																						.value,
																			},
																		)
																	}
																/>
																<label htmlFor="female">
																	As a Sales
																	Person
																</label>
															</div>
														</div>
													</div>
												</Col>
											</Row>
										</div>
										<div className="lafarge-ques-submit-button">
											<button
												disabled={
													!chooseOtion?.option1 &&
													!chooseOtion?.option2
												}
												// to="/succsessfull-submit"
												className="lafarge-ques-submit-button-inner">
												Continue
											</button>
										</div>
									</form>{" "}
								</div>
							</div>
						</div>
					</div>
				</Modal>
				<div className="lafarge-training-inner-wrap file-upload-container">
					<div className="lafarge-ques-items-wrap">
						<div className="lafarge-exercise-ques-main-wrap">
							<div className="lafarge-exercise-single-item mb-5">
								<div className="lafarge-exercise-single-item-inner">
									<Row>
										<Col md={12}>
											<div className="">
												{optionValue.option1 ===
												"as_a_coustomer" ? (
													<AsCoustomer />
												) : optionValue.option2 ===
												  "as_a_organization" ? (
													<AsOrganization />
												) : null}
											</div>
										</Col>
									</Row>
									<Row className="mt-5">
										<Col md={6}>
											<div className="lafarge-exercise-single-item-inner-right">
												{uploading ? (
													<div
														// style={{
														//   height: "252px",
														// }}
														className="lafarge-video-upload-right-wrap d-flex justify-content-center align-items-center">
														{/* <h6>Uploading...</h6> */}
														<div className="video-uploading-loader">
															<Button
																className="lafarge-loader"
																disabled>
																<Spinner
																	as="span"
																	animation="border"
																	size="sm"
																	role="status"
																	aria-hidden="true"
																/>
																Uploading...
															</Button>
														</div>
														{/* <Spinner animation="border" variant="primary" /> */}
													</div>
												) : (
													<div className="lafarge-video-upload-right-wrap">
														<input
															onChange={
																handleFile
															}
															type="file"
															name=""
															hidden
															accept="video/*"
															id="video-upload"
														/>

														<label htmlFor="video-upload">
															<div
																style={{
																	textAlign:
																		"center",
																}}>
																<img
																	src={
																		videoUploadBg
																	}
																	alt=""
																/>
																<p>
																	Drop your
																	video.{" "}
																	<br /> or
																	<span
																		style={{
																			color: "#017d3c",
																			paddingLeft:
																				"10px",
																		}}>
																		Browse.
																		(File
																		Size :
																		Max 50
																		MB)
																	</span>
																</p>
															</div>
														</label>
													</div>
												)}
											</div>
										</Col>{" "}
										<Col md={6}>
											<div className="lafarge-exercise-single-item-inner-right preview-part">
												<div
													className="lafarge-video-upload-right-wrap"
													style={{ height: "252px" }}>
													{uploadedFile ? (
														<div className="h-100">
															<div className="video-remove-btn-lafarge">
																<button
																	disabled={
																		removeVideo
																	}
																	onClick={() =>
																		deleteVideo()
																	}>
																	<i className="fa-solid fa-xmark"></i>
																</button>
															</div>
															<video
																width="100%"
																height="100%"
																className="h-100"
																style={{
																	objectFit:
																		"contain",
																}}
																controls
																autoPlay>
																<source
																	src={
																		`${
																			process
																				.env
																				.REACT_APP_IMAGE_URL
																		}${uploadedFile?.video
																			.split(
																				"/",
																			)
																			.pop()}` ||
																		"https://staging-courseware-backend.sgp1.digitaloceanspaces.com/1655281396742-1-%20Introduction.mp4"
																	}
																/>
															</video>
														</div>
													) : (
														<div className="video-remove-btn-lafarge-right-prev-area">
															<h5>
																You can see your
																uploaded <br />{" "}
																video here
															</h5>
														</div>
													)}
												</div>
											</div>
										</Col>
									</Row>
								</div>
								<div className="lafarge-ques-submit-button">
									<button
										// to="/succsessfull-submit"
										onClick={handleSubmit}
										disabled={
											submitting ||
											removeVideo ||
											uploading
										}
										className="lafarge-ques-submit-button-inner">
										{submitting
											? "Submitting..."
											: "Submit"}
									</button>
								</div>
							</div>{" "}
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default withRouter(LafargeFileUpload);
