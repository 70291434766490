import React, { useState } from "react";
import { Container, Form, Table } from "react-bootstrap";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { Link } from "react-router-dom";
import { FormatData } from "./FormatData";
import deleteIcon from "./img/close-circle.svg";
import editIcon from "./img/edit-2.svg";
import fileUpload from "./img/OBJECTS 1.svg";
const EducatorCopyBody = ({handlerInsertStudentIntoCourse, loading, students,onRemoveUser}) => {
  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  let [email, setEmail]= useState("")
  return (
    <Container>
      <Modal open={open} onClose={onCloseModal} center>
        <h2>Add to course</h2>
        <div className="add-student-data-main-wrap-fdgshjuhdcbcxnhdbc">
          <div className="">
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control type="text"
                  placeholder="name@example.com"
                  value ={email}
             onChange = {e => setEmail(e.target.value)}
                     />
              </Form.Group>
              <Form.Group
                controlId="formFile"
                className="mb-3 jkfhgsuhgjbygbhvdgb-s"
              
              >
                <button className="p-1" onClick={e => handlerInsertStudentIntoCourse(e, email)}>
                  {" "}
                   Upload Student Data
                </button>
                {/* <Form.Control type="file" /> */}
              </Form.Group>
            </Form>
          </div>
        </div>
      </Modal>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>Overview</h3>
          </div>
        </div>
        <div className="packge-header-right">
          <button onClick={onOpenModal}>
            <span>+ </span>
            Create New
          </button>
        </div>
      </div>

      <div>
        <p>Users Limit : {students?.limit}</p>
        <p>Remaining Students : {students?.currentRemaining}</p>
      </div>
      <div className="package-main-content-list">
        <Table borderless>
          <thead className="package-main-content-list-table-header">
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Account Status</th>
              <th>Rank</th>
              <th>Contract Number</th>
            </tr>
          </thead>
          <tbody>
            {!loading && students?.students?.map((item, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>

     
                  <td>{item.firstName? item.firstName + " " +item.lastName : item?.email?.split("@")[0]}</td>
                  <td>{item?.email}</td>
                  <td>{item?.request}</td>

                  <td>Not Given</td>
                  <td>
                    <div className="package-control-action-area">
                      <Link to="formate-edit">
                        <img src={editIcon} alt="" /> Edit
                      </Link>
                      <button>
                        <img src={deleteIcon} alt="" onClick={e => onRemoveUser(e, item._id)}  />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </Container>
  );
};

export default EducatorCopyBody;
