import { Container } from "react-bootstrap";
import "../LafargeAssets/scss/lafargeStyle.scss";
import LafargeLandingHeader from "../LafargeCommon/LafargeHeader/LafargeLandingHeader";
import LafargeHero from "./../LafargeCommon/LafargeComponents/LafargeHero";
const LafargeHome = () => {
  return (
    <div>
      <div className="lafarge-landing-header-main-wrap">
        <Container>
          <LafargeLandingHeader />
        </Container>
      </div>
      <LafargeHero />
    </div>
  );
};

export default LafargeHome;
