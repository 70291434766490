import {
	COMPARATIVE_ANALYSIS,
	COMPARATIVE_ANALYSIS_SUCCESS,
	SUBMIT_REV,
	SUBMIT_REV_SUCCESS,
} from "./actionTypes";

export const comparativeAnalysis = (ratioType, obj) => {
	return {
		type: COMPARATIVE_ANALYSIS,
		payload: {
			ratioType,
			obj,
		},
	};
};

export const comparativeAnalysisSuccess = (data, ratioType) => {
	return {
		type: COMPARATIVE_ANALYSIS_SUCCESS,
		payload: { data, ratioType },
	};
};

export const submitRev = (revNumber, history) => {
	return {
		type: SUBMIT_REV,
		payload: { revNumber, history },
	};
};

export const submitRevSuccess = (data) => {
	return {
		type: SUBMIT_REV_SUCCESS,
		payload: { data },
	};
};
