import React, { useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
  getItemStyle,
  getListStyle,
  move,
  reorder,
} from "../../helpers/Custom/DragAndDrop";
import { storeStudentGameData } from "../../store/RetailGame/Student/actions";
import Footer from "./Footer";
import RetailGameLayout from "./RetailGameLayout";
import DragAndDropIcon from "./img/DragAndDropIcon";

const id2List = {
  droppable: "options",
  droppable2: "haveToDone",
  droppable3: "notToDone",
};

const DragAndDropQuiz = () => {
  const dispatch = useDispatch();
  const [isWrong, setIsWrong] = useState(false);
  const { options, haveToDone, notToDone, studentQues } = useSelector(
    (state) => ({
      token: state.Login.token,
      studentQues: state.studentGame.studentQues,
      options: state.studentGame?.options || [],
      haveToDone: state.studentGame?.haveToDone || [],
      notToDone: state.studentGame?.notToDone || [],
    })
  );

  const data = {
    options,
    haveToDone,
    notToDone,
  };

  const indexs = {
    haveToDone: 0,
    notToDone: 1,
  };

  const getList = (id) => data[id2List[id]];

  // console.log("studentQues",studentQues)

  const onDragEnd = (result) => {
    const { source, destination } = result;
    console.log(`result:`, result);
    if (!destination) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      if (
        source.droppableId === "droppable" &&
        destination.droppableId === "droppable"
      ) {
        return;
      }
      const items = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );
      if (source.droppableId === "droppable") {
        dispatch(storeStudentGameData("options", items));
      }
      if (source.droppableId === "droppable2") {
        dispatch(storeStudentGameData("haveToDone", items));
      }
      if (source.droppableId === "droppable3") {
        dispatch(storeStudentGameData("notToDone", items));
      }
      // }
    } else {
      const sourceData = getList(source.droppableId);
      const destinationData = getList(destination.droppableId);
      // const getDestinationName = id2List[destination?.droppableId];
      const result = move(sourceData, destinationData, source, destination);
      // const destinationFinalData = result[destination.droppableId]
      // console.log(`result`, result, getDestinationName);
      // if(getDestinationName === "haveToDone" || getDestinationName === "notToDone"){
      //     const index = indexs[getDestinationName];
      //     const answer = studentQues?.questionArr[index]?.rightAnswer;
      //     const checkAnswer = destinationFinalData?.some(el => answer?.includes(el));
      //     if(!checkAnswer){
      //       setIsWrong(true);
      //     }
      //     // console.log("destination Data", destinationFinalData, "answer" , answer, "checkAnswer" , checkAnswer);
      // }
      if (!!result?.droppable) {
        dispatch(storeStudentGameData("options", result?.droppable));
      }
      if (!!result?.droppable2) {
        dispatch(storeStudentGameData("haveToDone", result?.droppable2));
      }
      if (!!result?.droppable3) {
        dispatch(storeStudentGameData("notToDone", result?.droppable3));
      }
    }
  };

  const checkErrors = useMemo(() => {
    console.log('studentQues', studentQues);
    const haveCheck = studentQues?.questionArr?.[0]?.rightAnswer;
    const notHaveCheck = studentQues?.questionArr?.[1]?.rightAnswer;
    const checkAnswer = haveToDone?.every((el) => haveCheck?.includes(el));
    const checkAnswer2 = notToDone?.every((el) => notHaveCheck?.includes(el));
    console.log("checkAnswer2:", checkAnswer2,checkAnswer)
    if (checkAnswer && checkAnswer2) {
      return true;
    }
    return false;
  }, [studentQues, haveToDone, notToDone]);

  console.log("checkErrors:>>>>>>>>>>>>>>>", checkErrors);

  const isActive = (name, idx) => {
    
    const index = indexs[name];

    const getData = data[name];
    const answer = studentQues?.questionArr?.[index]?.rightAnswer;
    const singleData = getData[idx];
   
    const checkAnswer = answer?.includes(singleData);
    if (checkAnswer) {
      return "correct-item";
    }
    if (!checkAnswer) return " false-item";
    return "";
  };

  return (
    <RetailGameLayout>
      <div className="quiz-content-full-area">
        <div className="single-quiz-item">
         
          <h6>IOQ করার ক্ষেত্রে যা করা যাবে ও যা করা যাবে না :</h6>
          <DragDropContext onDragEnd={onDragEnd}>
            <div className="drag-quiz-content-wrap">
              <div className="popup-drag-select-item drag-area-top">
                <Droppable droppableId="droppable" style={{ width: "100%" }}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver, true)}
                    >
                      {options?.map((item, index) => (
                        <Draggable
                          key={item + index}
                          draggableId={item + index}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                                true
                              )}
                            >
                              <div className="single-drag-content-popup">
                                <DragAndDropIcon />
                                <span>{item}</span>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </div>
              <div className="popup-drag-select-item drop-area-title-middle">
                <p>সঠিক শব্দ টেনে এনে সঠিক বক্স এ বসান</p>
              </div>
              <div className="popup-drag-select-item drop-area-bottom">
                <div className="g-0 row">
                  <div className="col-6">
                    <div className="single-drag-button">
                      <span>যা করণীয়</span>
                    </div>
                    <Droppable
                      droppableId="droppable2"
                      style={{ width: "100%" }}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          className="selected-option-wrap"
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {haveToDone?.map((item, index) => (
                            <Draggable
                              key={item + index}
                              draggableId={item + index}
                              index={index}
                              style={{ width: "100%" }}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                    true
                                  )}
                                >
                                  <div
                                    className={`selected-single-option ${isActive(
                                      "haveToDone",
                                      index
                                    )}`}
                                  >
                                    <div className="single-drag-content-popup">
                                      <div

                                      // onClick={() => alert("test")}
                                      >
                                        {/* <i className="bx bx-plus"></i> */}
                                      </div>
                                      <DragAndDropIcon />
                                      <span>{item}</span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </div>
                      )}
                    </Droppable>
                  </div>
                  <div className="col-6">
                    <div className="single-drag-button">
                      <span>যা বর্জনীয়</span>
                    </div>
                    <Droppable
                      droppableId="droppable3"
                      style={{ width: "100%" }}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          className="selected-option-wrap"
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {notToDone?.map((item, index) => (
                            <Draggable
                              key={item + index}
                              draggableId={item + index}
                              index={index}
                              style={{ width: "100%" }}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                    true
                                  )}
                                >
                                  <div
                                    className={`selected-single-option ${isActive(
                                      "notToDone",
                                      index
                                    )}`}
                                  >
                                    <div className="single-drag-content-popup">
                                      <div>
                                        {/* <i className="bx bx-plus"></i> */}
                                      </div>
                                      <DragAndDropIcon />
                                      <span>{item}</span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        </div>
                      )}
                    </Droppable>
                    {/* <div className="selected-option-wrap">
                      <div className="selected-single-option">
                        <div className="single-drag-content-popup">
                          <div
                            className="close-icon-remove"
                            onClick={() => alert("test")}
                          >
                            <i className="bx bx-plus"></i>
                          </div>
                          <DragAndDropIcon />
                          <span>আগেরদিনের সেলস কত জেনে নিন </span>
                        </div>
                      </div>
                      <div className="selected-single-option">
                        <div className="single-drag-content-popup">
                          <div
                            className="close-icon-remove"
                            onClick={() => alert("test")}
                          >
                            {" "}
                            <i className="bx bx-plus"></i>
                          </div>
                          <DragAndDropIcon />
                          <span>আগেরদিনের সেলস কত জেনে নিন </span>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </DragDropContext>
          <Footer
            setRightAns={setIsWrong}
            rightAns={checkErrors && options?.length === 0}
          />
        </div>
      </div>
    </RetailGameLayout>
  );
};

export default DragAndDropQuiz;
