export const ADD_TO_CART = "ADD_TO_CART"
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS"
export const ADD_TO_CART_FAIL = "ADD_TO_CART_FAIL"

export const ADD_TO_GIFT = "ADD_TO_GIFT"
export const ADD_TO_GIFT_SUCCESS = "ADD_TO_GIFT-SUCCESS"
export const ADD_TO_GIFT_FAIL = "ADD_TO_GIFT_FAIL"

export const CALCULATE_GIFT = "CALCULATE_GIFT"
export const CALCULATE_GIFT_SUCCESS = "CALCULATE_GIFT_SUCCESS"
// export const CALCULATE_GIFT = "CALCULATE_GIFT"
