export const FormatData = [
  {
    id: 1,
    formatName: "Business Strategy	Certificate",
    Slug: "03",
    CertificateIssued: 10,
    CategoryName: "Games",
    type: "Completion",
  },
  {
    id: 2,
    formatName: "Adding and Managing	Certificate",
    Slug: "03",
    CertificateIssued: 10,
    CategoryName: "Simulation",
    type: "Completion",
  },
  {
    id: 3,
    formatName: "Adding and Managing	Certificate",
    Slug: "03",
    CertificateIssued: 10,
    CategoryName: "Case Studies",
    type: "Completion",
  },
  {
    id: 4,
    formatName: "Buddy Press Integration	Certificate",
    Slug: "03",
    CertificateIssued: 10,
    CategoryName: "Multimedia",
    type: "Completion",
  },
  {
    id: 5,
    formatName: "SEO Keyword Hound	Certificate",
    Slug: "03",
    CertificateIssued: 10,
    CategoryName: "Key Concepts",
    type: "Completion",
  },
  {
    id: 6,
    formatName: "Adding and Managing	Certificate",
    Slug: "03",
    CertificateIssued: 10,
    CategoryName: "PDF/PPT",
    type: "Completion",
  },
  {
    id: 7,
    formatName: "Business Strategy	Certificate",
    Slug: "03",
    CertificateIssued: 10,
    CategoryName: "Lecture Series",
    type: "Completion",
  },
  {
    id: 8,
    formatName: "Adding and Managing	Certificate",
    Slug: "03",
    CertificateIssued: 10,
    CategoryName: "Lecture Series",
    type: "Completion",
  },
];
