import React,{useEffect} from "react";
import { ProgressBar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { cleanDataPlayAgain } from "../../store/actions";
 import { submitMark, courseOwnerValid } from './../../store/actions';
const FinancialGraph = () =>
{
  let history = useHistory()
let {courseId} = useParams()
   let dispatch = useDispatch();

  const { token,orderId } = useSelector(
    state => ({
      token: state.Login.token,
       orderId: state.courseReducer.orderId
    })
  )

  useEffect(() =>
  {
    dispatch(courseOwnerValid(token,courseId, history ))
  },[])


  const handleClick = (e, totalPoint) =>
  {
    //token , courseId, mark , history
    e.preventDefault()
    dispatch(submitMark(token, courseId, totalPoint, orderId, history))
  }
  const calculateRealWeight = (mark, achivePercent) => {
    return (mark * achivePercent) / 100;
  };
  //total Reveneu count
  let monthOneProdARevenue =
    localStorage.getItem("monthOneProdARevenue") !== null
      ? localStorage.getItem("monthOneProdARevenue")
      : 0;
  let monthOneProdBRevenue =
    localStorage.getItem("monthOneProdBRevenue") !== null
      ? localStorage.getItem("monthOneProdBRevenue")
      : 0;
  let monthTwoProdARevenue =
    localStorage.getItem("monthTwoProdARevenue") !== null
      ? localStorage.getItem("monthTwoProdARevenue")
      : 0;
  let monthTwoProdBRevenue =
    localStorage.getItem("monthTwoProdBRevenue") !== null
      ? localStorage.getItem("monthTwoProdBRevenue")
      : 0;

  let totalRevenue =
    parseInt(monthOneProdARevenue) +
    parseInt(monthOneProdBRevenue) +
    parseInt(monthTwoProdARevenue) +
    parseInt(monthTwoProdBRevenue);
  localStorage.setItem("totalMonthReveneu", totalRevenue);

  //value share
  let valueShare = (totalRevenue / 20000) * 100;
  console.log("value share", valueShare, totalRevenue);
  if (valueShare >= 10) {
    valueShare = 30;
  } else {
    let achiveNumber = (10 * valueShare) / 100;
    valueShare = calculateRealWeight(30, achiveNumber);
  }
  if (totalRevenue >= 2000) {
    console.log("hello revenue call from 48");
    totalRevenue = 25;
  } else {
    console.log("hello revenue call from 50");
    let achiveNumber = (totalRevenue / 2000) * 100;
    totalRevenue = calculateRealWeight(30, achiveNumber);
  }

  //net profit
  let netProfit =
    localStorage.getItem("netProfit") !== null
      ? parseInt(localStorage.getItem("netProfit"))
      : 0;

  if (netProfit >= 500) {
    netProfit = 25;
  } else {
    let achiveNumber = (netProfit / 500) * 100;
    netProfit = calculateRealWeight(30, achiveNumber);
  }

  //market share calculate at previous page
  let unitSoldProdAmonthOne =
    localStorage.getItem("unitSoldProdAmonthOne") !== null
      ? localStorage.getItem("unitSoldProdAmonthOne")
      : 0;
  let unitSoldProdBmonthOne =
    localStorage.getItem("unitSoldProdBmonthOne") !== null
      ? localStorage.getItem("unitSoldProdBmonthOne")
      : 0;
  let unitSoldProdAmonthTwo =
    localStorage.getItem("unitSoldProdAmonthTwo") !== null
      ? localStorage.getItem("unitSoldProdAmonthTwo")
      : 0;
  let unitSoldProdBmonthTwo =
    localStorage.getItem("unitSoldProdBmonthTwo") !== null
      ? localStorage.getItem("unitSoldProdBmonthTwo")
      : 0;

  let unitSold =
    parseInt(unitSoldProdAmonthOne) +
    parseInt(unitSoldProdBmonthOne) +
    parseInt(unitSoldProdAmonthTwo) +
    parseInt(unitSoldProdBmonthTwo);
  console.log("unit sold here", unitSold);
  let marketShare = (unitSold / 2000) * 100;
  localStorage.setItem("totalMarketShare", marketShare);

  if (marketShare >= 10) {
    marketShare = 30;
  } else {
    let achiveNumber = (marketShare / 10) * 100;
    marketShare = calculateRealWeight(30, achiveNumber);
  }

  let totalPoint = 0;
  if (totalRevenue) {
    totalPoint += totalRevenue;
  }
  if (netProfit) {
    totalPoint += netProfit;
  }
  if (valueShare) {
    totalPoint += valueShare;
  }
  if (marketShare) {
    totalPoint += marketShare;
  }
  if (totalPoint >= 100) {
    totalPoint = 100;
  } else {
    let achiveNumber = (totalPoint / 110) * 100;
    totalPoint = parseInt(calculateRealWeight(100, achiveNumber)).toFixed(2);
  }
  localStorage.setItem("totalPoint", parseInt(totalPoint).toFixed(2));

  console.log("total Revenue", totalRevenue);
  const handleClean = (e) => {
    console.log("Hanlde clean here");
    var answer = window.confirm(
      "Do you want to delete all the data and play again?"
    );
    if (answer) {
      dispatch(cleanDataPlayAgain("empty",courseId, history));
    }
  };
  return (
    <div>
      <div className="container">
        <div className="all-financial-data">
          <div className="row">
            <div className="col-md-3">
              <div className="single-financial-data">
                <h5>Market Share</h5>
                <ProgressBar now={marketShare} max={30} min={0} />
                <p>{marketShare ? marketShare.toFixed(2) : 0}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="single-financial-data">
                <h5>Value Share</h5>
                <ProgressBar now={valueShare} max={30} min={0} />
                <p>{valueShare ? valueShare.toFixed(2) : 0}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="single-financial-data">
                <h5>Revenue</h5>
                <ProgressBar now={totalRevenue} max={25} min={0} />
                <p>{totalRevenue ? totalRevenue.toFixed(2) : 0}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="single-financial-data">
                <h5>Net Profit</h5>
                <ProgressBar now={netProfit} max={25} min={0} />
                <p>{netProfit ? netProfit.toFixed(2) : 0}</p>
              </div>
            </div>
          </div>
        </div>
        <p>Total Point : {totalPoint}</p>
        <div className="case-summery-btn">
          <Link to={`/${courseId}/financial-graph`}>
            <div>
              <button className="case-summery-previous-btn">Previous</button>
            </div>
          </Link>

          <div>
            <button
              onClick={(e) => handleClean(e)}
              className="case-summery-next-btn"
            >
              Play Again
            </button>
          </div>

          {/* <Link to="/result"> */}
            <div>
              <button onClick={e => handleClick(e, totalPoint)} className="case-summery-next-btn">Next</button>
            </div>
          {/* </Link> */}
        </div>
      </div>
    </div>
  );
};

export default FinancialGraph;
