import React from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
const AcademicEditBody = ({
  academicName,
  hanldeUpdateAcademicname,
  handleUpdate,
  history,
}) => {
  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>New Academic Area</h3>
            <p>
              Course list<i className="bx bx-chevron-right"></i>{" "}
              <Link to={"/academic-area"}> academic area </Link>{" "}
              <i className="bx bx-chevron-right"></i>
              <span> edit academic list</span>{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="package-main-content-list kjhgfwertyuiutfc">
        <div className="add-new-format-area-wrap">
          {/* <div className="delete-btn-rfvbjuyghbjuyhnm">
            <button>
              <i className="bx bx-trash-alt"></i>
            </button>
          </div> */}
          <div className="add-new-format-inner-item-wrap">
            <h4>Edit Academic Area</h4>
            <Form className="format-upload-form-main-area-zkjdh">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Academic name</Form.Label>
                <Form.Control
                  type="Text"
                  value={academicName.name}
                  onChange={(e) => hanldeUpdateAcademicname(e)}
                  placeholder="Tag name"
                />
              </Form.Group>
              {/* <Form.Group>
                <Form.Label>Upload user image</Form.Label>
                <div className="button-area-input-data-wrap">
                  <label
                    for="file-upload"
                    // style={{ backgroundImage: `url(${inputBg})` }}
                  >
                    <img src={inputBg} alt="" />
                    Upload Your Data
                  </label>
                  <input type="file" id="file-upload" />
                </div>
              </Form.Group> */}
              <div className="">
                <div className="create-new-format-btn-area-wrap">
                  <button onClick={(e) => handleUpdate(e)}>Update</button>
                  <button onClick={(e) => history.push("/academic-area")}>
                    Cancel
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcademicEditBody;
