import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "./hero.scss";
import heroBg from "./HerorightBg.png";
import heroBg3 from "./img/3.png";
import heroBg2 from "./img/4.png";
const Hero = ({ academicAreas }) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoPlay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="hero-main-wrap">
      <Container>
        <div className="hero-inner-wrap">
          <div className="hero-slider-items">
            <div className="hero-slider-single-item">
              <Row>
                <Col lg={7}>
                  <div className="hero-slider-single-item-inner-wrap">
                    <Slider {...settings}>
                      <div
                        style={{ backgroundImage: `url(${heroBg})` }}
                        className="hero-left-image"
                      >
                        <img src={heroBg} alt="" />
                        <div className="hero-content-left">
                          <div className="">
                            <h2>Learn key insights</h2>
                            <p>
                              This interactive tutorial introduces the student
                              to the world of business management. The tutorial
                              is divided into 4 sections: trade, trading
                              activity, the complex sale and the personal sale
                              process.
                            </p>
                          </div>
                        </div>
                      </div>{" "}
                      <div
                        style={{ backgroundImage: `url(${heroBg})` }}
                        className="hero-left-image"
                      >
                        <img src={heroBg2} alt="" />
                        <div className="hero-content-left">
                          <div className="">
                            <h2>Learn key insights</h2>
                            <p>
                              This interactive tutorial introduces the student
                              to the world of business management. The tutorial
                              is divided into 4 sections: trade, trading
                              activity, the complex sale and the personal sale
                              process.
                            </p>
                          </div>
                        </div>
                      </div>{" "}
                      <div
                        style={{ backgroundImage: `url(${heroBg})` }}
                        className="hero-left-image"
                      >
                        <img src={heroBg3} alt="" />
                        <div className="hero-content-left">
                          <div className="">
                            <h2>Learn key insights</h2>
                            <p>
                              This interactive tutorial introduces the student
                              to the world of business management. The tutorial
                              is divided into 4 sections: trade, trading
                              activity, the complex sale and the personal sale
                              process.
                            </p>
                          </div>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </Col>
                <Col lg={5}>
                  <div className="hero-slider-content-right">
                    <h3 className="mb-3 home-hero-man-area">Academic areas</h3>
                    <div className="academic-right-area-wrap">
                      <div className="academic-right-area-inner-wrap">
                        <Row className="g-0">
                          {academicAreas?.map((v, i) => (
                            <Col lg={6} key={i}>
                              <div className="academic-right-single-item ">
                                <>
                                  <Link to={`/student-category/${v._id}`}>
                                    {v.academicName}
                                  </Link>
                                </>
                              </div>
                            </Col>
                          ))}
                        </Row>{" "}
                      </div>

                      {/* <div className="academic-right-single-item">
                        <a href="/">ENTREPRENEURSHIP</a>
                        <a href="/">DIGITAL TECHNOLOGIES & DATA SCIENCE</a>
                      </div>{" "}
                      <div className="academic-right-single-item">
                        <a href="/">MARKETING & COMMUNICATIONS</a>
                        <a href="/">FINANCE</a>
                      </div>{" "}
                      <div className="academic-right-single-item">
                        <a href="/">HUMAN RESOURCES</a>
                        <a href="/">FINANCIAL ACCOUNTING</a>
                      </div>{" "}
                      <div className="academic-right-single-item">
                        <a href="/">OPERATIONS & SUPPLY CHAIN MANAGEMENT </a>
                        <a href="/">COST ACCOUNTING & MANAGEMENT CONTROL</a>
                      </div>{" "}
                      <div className="academic-right-single-item">
                        <a href="/">INNOVATION</a>
                        <a href="/">ORGANISATIONAL BEHAVIOUR</a>
                      </div>{" "}
                      <div className="academic-right-single-item">
                        <a href="/">NEGOTIATION</a>
                        <a href="/">ARCHITECTURE</a>
                      </div>{" "}
                      <div className="academic-right-single-item">
                        <a href="/">LAW</a>
                        <a href="/">OTHERS</a>
                      </div> */}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Hero;
