import { call, put, takeEvery } from "redux-saga/effects"

import
    {
        axiosUpdatePassword,
        axiosUpdateProfile,
        axiosGetMe,
         axiosChangePassword
} from "../../../helpers/api_helper"
    
import
    {
        updateProfileSuccess,
        updatePasswordFail,
        updatePasswordSuccess,
        updateProfileFail,
        getProfileSuccess,
        changePasswordSuccess,
        changePasswordFail
} from "./actions"
    
import { toast } from "react-toastify"
import
    {
        UPDATE_PROFILE,
        GET_PROFILE,
        UPDATE_PASSWORD,
        CHANGE_PASSWORD
} from "./actionTypes"

// get profile
const asyncGetProfile =async token =>
{
    try {
        const response = await axiosGetMe(token)
        return response.data
    } catch (error) {
        return error.response.data
    }
}
function* workerGetProfile({ payload: { token } })
{
    try {
        const response = yield call(asyncGetProfile, token)
        yield put(getProfileSuccess(response.getProfile))
    } catch (error) {
        //console.log("error data", error)
    }
}
//update password

const asyncUpdatePassword = async (token, firstName, lastName, phoneNo) =>
{
    try {
        const response = await axiosUpdatePassword(token, firstName, lastName, phoneNo)
        return response.data
    } catch (error) {
        return error.response.data
    }
}
function* workerUpdatePassword({ payload: { token, firstName, lastName, phoneNo } })
{
    try {
        const response = yield call(asyncUpdatePassword, token, firstName, lastName, phoneNo)
        if (response.status == "success") {
            yield put(updatePasswordSuccess(response.message))
            yield put(updatePasswordSuccess())
        } else {
            yield put(updatePasswordFail(response.message))
            yield put(updatePasswordFail())
        }
    } catch (error) {
        
    }
}

//update profile
const asyncUpdateProfile = async (token, firstName, lastName, phoneNo) =>
{
    try {
        const response = await axiosUpdateProfile(token, firstName, lastName, phoneNo)
        return response.data
    } catch (error) {
        return error.response.data
    }
}
function* workerUpdateProfile({ payload: {token, firstName, lastName, phoneNo } })
{
    try {
        const response = yield call(asyncUpdateProfile, token, firstName, lastName, phoneNo)
        if (response.status == "success") {
            yield put(updateProfileSuccess(response.message))
            toast.success(response.message)
            yield put(updateProfileSuccess())
        } else {
            yield put(updateProfileFail(response.message))
            toast.error(response.message)
            yield put(updateProfileFail( ))

        }
    } catch (error) {
        
    }
}


const asyncChangePassword = async (token, currentPassword, password, confirmPassword) =>
{
    try {
        const response = await axiosUpdatePassword(token, currentPassword, password, confirmPassword)
        return response.data
    } catch (error) {
        
    }
}
function* workerChangePassword ({ payload: { token, password, newPassword, confirmPassword } }){
    try {
        
        const response = yield call(asyncChangePassword, token, password, newPassword, confirmPassword)
        if (response.status == "success") {
            yield put(changePasswordSuccess(response.message))
            toast.success(response.message)
            yield put(changePasswordSuccess())

        } else {
            yield put(changePasswordFail(response.message))
            toast.error(response.message)
            yield put(changePasswordFail())
        }
    } catch (error) {
        
    }

}

function* updatePasswordSaga()
{
    yield takeEvery(UPDATE_PASSWORD, workerUpdatePassword)
    yield takeEvery(GET_PROFILE, workerGetProfile)
    yield takeEvery(UPDATE_PROFILE, workerUpdateProfile)
    yield takeEvery(CHANGE_PASSWORD, workerChangePassword)
}

export default updatePasswordSaga