import { call, put, takeEvery } from "redux-saga/effects";
import { getData } from "../../../helpers/backend_helper";

import { toaster } from "../../../helpers/Custom/Toaster";
import {
  getSingleGameFail,
  getSingleGameSuccess,
  getStudentDataFail,
  getStudentDataSuccess,
  getStudentQuesFail,
  getStudentQuesSuccess,
  storeStudentGameData,
} from "./actions";
import { GET_SINGLE_GAME, GET_STUDENT_COURSE, GET_STUDENT_QUES } from "./actionTypes";

function* onGetStudentCourse({ payload: { token } }) {
  try {
    const url = `/student-get-courses`;
    const response = yield call(getData, url, token);
    yield put(getStudentDataSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(getStudentDataFail(message));
    toaster("error", message);
  }
}
function* onGetStudentQues({ payload: { token, courseId, type } }) {
  try {
    const url = `get-exam-questions/${courseId}/${type}`;
    const response = yield call(getData, url, token);
    console.log("response", response);
    if (response?.status === "success") {
      yield put(getStudentQuesSuccess(response?.getData));
      yield put(storeStudentGameData("currentType", type));
      
      // yield put(
      //   storeStudentGameData("mcqQuesNum", response?.getData?.mcq?.)
      // );
    }
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(getStudentQuesFail(message));
    toaster("error", message);
  }
}
function* onGetSingleGame({ payload: { courseId } }) {
  try {
    const url = `read-single-course/${courseId}`;
    const response = yield call(getData, url);
    console.log("response", response);
    if (response?.status === "success") {
      yield put(getSingleGameSuccess(response?.getSingleData));
 
      
     
    }
  } catch (error) {
    const message = error.response?.data?.message || "get Screen failed";
    yield put(getSingleGameFail(message));
    toaster("error", message);
  }
}

function* StudentGameSaga() {
  yield takeEvery(GET_STUDENT_COURSE, onGetStudentCourse);
  yield takeEvery(GET_STUDENT_QUES, onGetStudentQues);
  yield takeEvery(GET_SINGLE_GAME, onGetSingleGame);
}

export default StudentGameSaga;
