import React from "react";
import WhyDoWeNeedDetails from "../LafargeCommon/LafargeComponents/WhyDoWeNeedDetails";
import LafargeCourseWareHeader from "../LafargeCommon/LafargeHeader/LafargeCourseWareHeader";

const WhyDoWeNeedPage = () => {
  return (
    <div>
      <LafargeCourseWareHeader />
      <WhyDoWeNeedDetails />
    </div>
  );
};

export default WhyDoWeNeedPage;
