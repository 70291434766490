import React from "react";
import MainFooter from "../Common/footer/MainFooter";
import LoginHeader from "../Common/LoginHeader";
import BeforeLoginCartBody from "./BeforeLoginCartBody";
import "./cart.scss";
const BeforeLoginCart = () => {
  return (
    <div>
      <LoginHeader />
      <BeforeLoginCartBody />
      <MainFooter />
    </div>
  );
};

export default BeforeLoginCart;
