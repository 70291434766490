import React, { useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col } from "reactstrap";
import {
  getCategoriesByAdmin,
  getNumberOfAllData,
  getPopularCoursesByAdmin,
  getRecentCoursesByAdmin,
  getStudentEnrolmentData,
} from "../../../../store/actions";
import shareIcon from "../../img/external-link.svg";
import academicIcon from "../../img/icon_dashboard/academic.svg";
import formattIcon from "../../img/icon_dashboard/clipboard.svg";
import courseIcon_d from "../../img/icon_dashboard/course.svg";
import emptyIcon from "../../img/icon_dashboard/empty-wallet.svg";
import authorIcon from "../../img/icon_dashboard/reading-book 1.svg";
import studentIcon from "../../img/icon_dashboard/teacher.svg";
import StudentEnroll from "./StudentEnroll";
const DashboardMenu = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  const {
    token,
    NumberRelatedData,
    recentCourses,
    popularCourses,
    categories,
    enrolmentData,
  } = useSelector((state) => ({
    token: state.Login.token,
    NumberRelatedData: state.adminDashboardReducer.adminDashboardLeftSide,
    recentCourses: state.adminDashboardReducer.recentCourses,
    popularCourses: state.adminDashboardReducer.popularCourses,
    categories: state.adminDashboardReducer.categoreis,
    enrolmentData: state.adminDashboardReducer.enrolmentData,
  }));

  useEffect(() => {
    dispatch(getNumberOfAllData(token));
    dispatch(getRecentCoursesByAdmin(token));
    dispatch(getPopularCoursesByAdmin(token));
    dispatch(getCategoriesByAdmin(token));
    dispatch(getStudentEnrolmentData(token));
  }, []);
  return (
    <div>
      <Container fluid>
        <div className="dashboard-main-content-area">
          <Row>
            <Col lg={8}>
              <div className="dashboards-title">
                <h4>Dashboard</h4>
              </div>
              <Row>
                <Col lg={4}>
                  <div className="dashboard-main-area-single-content color-1">
                    <span>
                      <img src={studentIcon} alt="" />
                      {/* <i className="bx bxs-graduation"></i> */}
                    </span>
                    <span>
                      <p>Total Student</p>
                      <h6>{NumberRelatedData?.totalStudents}</h6>
                    </span>
                  </div>
                </Col>{" "}
                <Col lg={4}>
                  <div className="dashboard-main-area-single-content color-2">
                    <span>
                      <img src={authorIcon} alt="" />
                    </span>
                    <span>
                      <p>Total Author</p>
                      <h6>{NumberRelatedData?.getTotalCourses}</h6>
                    </span>
                  </div>
                </Col>{" "}
                <Col lg={4}>
                  <div className="dashboard-main-area-single-content color-3">
                    <span>
                      <img src={courseIcon_d} alt="" />
                    </span>
                    <span>
                      <p>Total Course</p>
                      <h6>{NumberRelatedData?.getTotalCategories}</h6>
                    </span>
                  </div>
                </Col>{" "}
                <Col lg={4}>
                  <div className="dashboard-main-area-single-content color-4">
                    <span>
                      <img src={academicIcon} alt="" />
                    </span>
                    <span>
                      <p>Total Academic Areas</p>
                      <h6>{NumberRelatedData?.getTotalAcademicAreas}</h6>
                    </span>
                  </div>
                </Col>{" "}
                <Col lg={4}>
                  <div className="dashboard-main-area-single-content color-5">
                    <span>
                      <img src={formattIcon} alt="" />
                    </span>
                    <span>
                      <p>Total Format</p>
                      <h6>{NumberRelatedData?.totalFormat}</h6>
                    </span>
                  </div>
                </Col>{" "}
                <Col lg={4}>
                  <div className="dashboard-main-area-single-content color-6">
                    <span>
                      <img src={emptyIcon} alt="" />
                    </span>
                    <span>
                      <p>Total Revenue</p>
                      <h6>$ {NumberRelatedData?.totalRevenue}</h6>
                    </span>
                  </div>
                </Col>{" "}
              </Row>
              {/* //chart */}
              <div className="sales-analyticx-wrap">
                <StudentEnroll
                  enrolmentData={enrolmentData ? enrolmentData : ""}
                />
              </div>
            </Col>
            <Col lg={4}>
              <aside className="dashboard-sidebar-right">
                <div className="dashboard-sidebar-single-item">
                  <div className="dashboard-sidebar-title">
                    <div className="dashboard-sidebar-title-left">
                      <h5>Recent Course</h5>
                    </div>
                    <div className="dashboard-sidebar-title-right">
                      {/* <img src={shareIcon} alt="" /> */}
                    </div>
                  </div>
                  <div className="sidebar-course-list">
                    {recentCourses?.map((course, ind) => (
                      <div className="course-sidebar-single-item" index={ind}>
                        <div
                          className="course-sidebar-single-item-left"
                          index={ind}
                        >
                          <img
                            src={`${process.env.REACT_APP_IMAGE_URL}${course.image}`}
                            height="70px"
                            alt=""
                          />
                        </div>
                        <div
                          className="course-sidebar-single-item-right"
                          index={ind}
                        >
                          <p>{course?.courseName}</p>
                        </div>
                      </div>
                    ))}{" "}
                  </div>
                </div>{" "}
                <div className="dashboard-sidebar-single-item">
                  <div className="dashboard-sidebar-title">
                    <div className="dashboard-sidebar-title-left">
                      <h5>Popular Course</h5>
                    </div>
                    <div className="dashboard-sidebar-title-right">
                      <img src={shareIcon} alt="" />
                    </div>
                  </div>
                  <div className="sidebar-course-list">
                    {popularCourses?.map((course, ind) => (
                      <div className="course-sidebar-single-item">
                        <div className="course-sidebar-single-item-left">
                          <img
                            src={`${process.env.REACT_APP_IMAGE_URL}${course.image}`}
                            height="70px"
                            alt=""
                          />
                        </div>
                        <div className="course-sidebar-single-item-right">
                          <p>{course?.courseName}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>{" "}
                <div className="dashboard-sidebar-single-item">
                  <div className="dashboard-sidebar-title">
                    <div className="dashboard-sidebar-title-left">
                      <h5>Course Categories</h5>
                    </div>
                    <div className="dashboard-sidebar-title-right">
                      <img src={shareIcon} alt="" />
                    </div>
                  </div>
                  <div className="sidebar-course-list">
                    <div className="course-category-main-wrap">
                      <Row>
                        {categories?.map((category, ind) => (
                          <Col md={6}>
                            <div
                              className="course-category-single-item first-course-item"
                              index={ind}
                            >
                              <span>
                                <h4>{category.categoryName}</h4>
                                <span>
                                  {" "}
                                  <i className="bx bx-palette"></i>
                                </span>
                              </span>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </div>
                </div>
              </aside>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default DashboardMenu;
