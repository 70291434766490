import React, { useState } from 'react';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import DashboardCommonAllLink from '../../../DashboardCommon/DashboardCommonAllLink';
import DashboardCoomonTopbar from '../../../DashboardCommon/DashboardCoomonTopbar';
import LafargeGameBody from './LafargeGameBody';
const LafargeGame = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [toggle, setToggle] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <React.Fragment>
      <DashboardCoomonTopbar />
      <div
        className={
          toggle
            ? 'dashboard-sidebar-nav-area showNavbar'
            : 'dashboard-sidebar-nav-area'
        }
      >
        <button
          onClick={() => {
            toggleDrawer();
            setToggle(!toggle);
          }}
          className="main-sidebar-toggle-button"
        >
          <i className="bx bx-menu"></i>
        </button>
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="left"
          className={`navbar-inner-main-wrap-items-wrap `}
        >
          <button
            onClick={() => {
              toggleDrawer();
              setToggle(!toggle);
            }}
            className="main-sidebar-toggle-button"
          >
            <i className="bx bx-menu"></i>
          </button>
          <div className={`navbar-item-single-main-area-jddiu`}>
            <DashboardCommonAllLink />
          </div>
        </Drawer>
      </div>
      <div
        className={
          toggle ? 'dashboard-main-body showNavbar' : 'dashboard-main-body'
        }
      >
        <LafargeGameBody />
      </div>
    </React.Fragment>
  );
};

export default LafargeGame;
