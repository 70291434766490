import {
   
    GET_COURSE_OF_ACADEMIC_AREA,
    GET_COURSE_OF_ACADEMIC_AREA_SUCCESS,
    GET_COURSE_OF_ACADEMIC_AREA_FAIL,
    GET_SINGLE_ACADEMIC_DATA,
    GET_SINGLE_ACADEMIC_DATA_SUCCESS,
    GET_SINGLE_ACADEMIC_DATA_FAIL,
    GET_ALL_CATEGORIES_FOR_ALL,
    GET_ALL_CATEGORIES_FOR_ALL_SUCCESSS,
    GET_DATA_ON_SEARCH,
    GET_DATA_ON_SEARCH_SUCCESS,
    GET_AUTHOR_DATA_USER,
    GET_AUTHOR_DATA_USER_FAIL,
     GET_AUTHOR_DATA_USER_SUCCESS
    // new
    // GET_
    
} from "./actionTypes"

export const getCourseOfAcademicArea = (academicId) => ({
    type: GET_COURSE_OF_ACADEMIC_AREA,
    payload : {academicId}
})

export const getCourseOfAcademicAreaSuccess = (data) => ({
    type: GET_COURSE_OF_ACADEMIC_AREA_SUCCESS,
    payload: {data}
})

// export const getSingleAcademicData = (academicId)
export const getAllCategoriesForALl = () => ({
    type: GET_ALL_CATEGORIES_FOR_ALL,
    payload: {}
})

export const getAllCategoriesForAllSuccess = (data) => ({
    type: GET_ALL_CATEGORIES_FOR_ALL_SUCCESSS,
    payload: {data}
})

export const getDataOnSearch = text => ({
    type: GET_DATA_ON_SEARCH,
    payload: {text}
})

export const getDataOnSearchSuccess = data => ({
    type: GET_DATA_ON_SEARCH_SUCCESS,
    payload: {data}
})


export const getAuthorData = (authorId) => ({
    type: GET_AUTHOR_DATA_USER,
    payload: { authorId}
})

export const getAuthorDataSuccess = data => ({
    type: GET_AUTHOR_DATA_USER_SUCCESS,
    payload: {data}
})

export const getAuthorDataFail = message => ({
    type: GET_AUTHOR_DATA_USER_FAIL,
    payload: {message}
})