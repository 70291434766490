import LafargeGuidlinesComponents from "../LafargeCommon/LafargeComponents/LafargeGuidlinesComponents";
import LafargeCourseWareHeader from "../LafargeCommon/LafargeHeader/LafargeCourseWareHeader";

const LafargeGuidlines = () => {
  return (
    <div>
      <LafargeCourseWareHeader />
      <LafargeGuidlinesComponents />
    </div>
  );
};

export default LafargeGuidlines;
