import React, { useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  getMultiSelectorData,
  getSingleAuthor,
  updateAuthor,
  uploadAuthorPhoto,
} from "../../../../store/actions";
import DashboardCommonAllLink from "../../DashboardCommon/DashboardCommonAllLink";
import DashboardCoomonTopbar from "../../DashboardCommon/DashboardCoomonTopbar";
import AuthorListEditBody from "./AuthorListEditBody";
const AdminAuthorEdit = () => {
  const { pathname } = useLocation();
  let { authorId } = useParams();
  let dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [isOpen, setIsOpen] = useState(true);
  const [toggle, setToggle] = useState(false);
  // const [toggleClass, setToggleClass] = useState("");
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);

    // setToggleClass("showNavbar");
  };

  const { token, loading, author, multiSelectorData, updatedPhoto } =
    useSelector((state) => ({
      token: state.Login.token,
      multiSelectorData: state.AdminAuthorReducer.multiSelectorData,
      loading: state.AdminAuthorReducer.loading,
      author: state.AdminAuthorReducer.author,
      updatedPhoto: state.AdminAuthorReducer.photo,
    }));
  let [authorName, setAuthorName] = useState({
    name: author?.getSingleData?.authorName || "",
  });
  let [phoneNumber, setPhoneNumber] = useState({
    phone: author?.getSingleData?.phoneNumber,
  });
  let [email, setEmail] = useState({
    email: author?.getSingleData?.email,
  });

  let [courseAcademicIds, setCourseAcademicId] = useState({
    obj: author?.getSingleData?.academicArea.map((v, i) => {
      return { label: v.academicName, value: v._id };
    }),
  });

  let [description, setDescription] = useState({
    description: author?.getSingleData?.description,
  });

  let [photo, setPhoto] = useState({
    photo: updatedPhoto ? updatedPhoto : author?.getSingleData?.authorPhoto,
  });

  let [courseFormatIds, setCourseFormatIds] = useState({
    obj: author?.getSingleData?.category.map((v, i) => {
      return { label: v.categoryName, value: v._id };
    }),
  });

  useEffect(() => {
    dispatch(getSingleAuthor(token, authorId, history));
    dispatch(getMultiSelectorData(token));
  }, []);

  useEffect(() => {
    if (author) {
      setAuthorName({
        name: author?.getSingleData?.authorName || "",
      });
      setPhoneNumber({
        phone: author?.getSingleData?.phoneNumber || "",
      });
      setEmail({
        email: author?.getSingleData?.email || "",
      });
      setDescription({
        description: author?.getSingleData?.description || "",
      });
      setCourseAcademicId({
        obj: author?.getSingleData?.academicArea.map((v, i) => {
          return { label: v.academicName, value: v._id };
        }),
      });

      setPhoto({
        photo: updatedPhoto ? updatedPhoto : author?.getSingleData?.authorPhoto,
      });

      setCourseFormatIds({
        obj: author?.getSingleData?.category.map((v, i) => {
          return { label: v.categoryName, value: v._id };
        }),
      });
    }
  }, [author]);

  const hanldeUpdateAuthorName = (e) => {
    e.preventDefault();
    setAuthorName({
      name: e.target.value,
    });
  };

  const handleSetPhoneNumber = (e) => {
    e.preventDefault();
    setPhoneNumber({
      phone: e.target.value,
    });
  };
  const handleSetEmail = (e) => {
    e.preventDefault();
    setEmail({
      email: e.target.value,
    });
  };

  const handleSetDescription = (e) => {
    e.preventDefault();
    setDescription({
      description: e.target.value,
    });
  };

  const handleSetAcademicIds = (selectedOptions) => {
    // e.preventDefault()
    console.log("selected Options", selectedOptions);
    setCourseAcademicId({
      obj: selectedOptions,
    });
  };

  const handleSetCategoryFormatIds = (selectedOptions) => {
    // e.preventDefault()
    setCourseFormatIds({
      obj: selectedOptions,
    });
  };
  const handleUploadAuthorImage = (e) => {
    console.log("hello photo", e.target.files[0]);

    let formData = new FormData();

    formData.append("photo", e.target.files[0]);
    dispatch(uploadAuthorPhoto(token, formData));
  };
  const hanldeUpdateAuthorData = (e) => {
    e.preventDefault();
    console.log("hello data", courseAcademicIds, courseFormatIds);
    let AcademicIds = [];
    courseAcademicIds?.obj.map((v, i) => {
      AcademicIds.push(v.value);
    });

    let formatIds = [];
    courseFormatIds?.obj.map((v, i) => {
      formatIds.push(v.value);
    });
    if (updatedPhoto) {
      dispatch(
        updateAuthor(
          token,
          authorName.name,
          phoneNumber.phone,
          email.email,
          AcademicIds,
          description.description,
          updatedPhoto,
          formatIds,
          authorId,
          history
        )
      );
    } else {
      dispatch(
        updateAuthor(
          token,
          authorName.name,
          phoneNumber.phone,
          email.email,
          AcademicIds,
          description.description,
          photo.photo,
          formatIds,
          authorId,
          history
        )
      );
    }
  };
  return (
    <>
      <DashboardCoomonTopbar />
      <div
        className={
          toggle
            ? "dashboard-sidebar-nav-area showNavbar"
            : "dashboard-sidebar-nav-area"
        }
      >
        <button
          onClick={() => {
            toggleDrawer();
            setToggle(!toggle);
          }}
          className="main-sidebar-toggle-button"
        >
          <i className="bx bx-menu"></i>
        </button>
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="left"
          className={`navbar-inner-main-wrap-items-wrap `}
        >
          <button
            onClick={() => {
              toggleDrawer();
              setToggle(!toggle);
            }}
            className="main-sidebar-toggle-button"
          >
            <i className="bx bx-menu"></i>
          </button>
          <div className={`navbar-item-single-main-area-jddiu`}>
            <DashboardCommonAllLink />
          </div>
        </Drawer>
      </div>
      <div
        className={
          toggle ? "dashboard-main-body showNavbar" : "dashboard-main-body"
        }
      >
        <AuthorListEditBody
          authorName={authorName}
          phoneNumber={phoneNumber}
          email={email}
          photo={photo}
          handleUploadAuthorImage={handleUploadAuthorImage}
          setAuthorName={setAuthorName}
          hanldeUpdateAuthorName={hanldeUpdateAuthorName}
          handleSetAcademicIds={handleSetAcademicIds}
          courseAcademicIds={courseAcademicIds}
          courseFormatIds={courseFormatIds}
          handleSetCategoryFormatIds={handleSetCategoryFormatIds}
          handleSetDescription={handleSetDescription}
          description={description}
          // handleSetDescription={handleSetDescription}
          handleSetEmail={handleSetEmail}
          updatedPhoto={updatedPhoto}
          hanldeUpdateAuthorData={hanldeUpdateAuthorData}
          handleSetPhoneNumber={handleSetPhoneNumber}
          multiSelectorData={multiSelectorData}
          loading={loading}
          author={author.getSingleData}
        />
      </div>
    </>
  );
};

export default AdminAuthorEdit;
