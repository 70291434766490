import React,{useEffect} from "react";
import ListHeader from "./ListHeader";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation , useParams} from "react-router-dom";
import { courseOwnerValid } from "../../store/actions";

const FinancialStatement = () =>
{
  const { token,  } = useSelector((state) => ({
    token: state.Login.token,
  
  }));
  const {courseId} = useParams()
  let dispatch = useDispatch()
  let history = useHistory()
   useEffect(() =>
  {
    // dispatch(courseOwnerValid(token,courseId, history ))
  },[])
  return (
    <div>
      <div className="container">
        <div>
          <div className="case-method-list">
            <ListHeader />
            <h3 className="my-3 ms-5">PROFIT AND LOSS ACCOUNT </h3>
            <div className="table-responsive finsasdsijk">
              <table className="table table-bordered mb-0">
                <thead>
                  <tr className="text-center">
                    <th rowSpan="2" className="fghbjnkmjhgfc">
                      PROFIT AND LOSS ACCOUNT{" "}
                    </th>
                    <th colSpan="2">2007</th>
                    <th colSpan="2">2008</th>
                    <th colSpan="2">2009</th>
                  </tr>
                  <tr>
                    <th scope="col">$</th>
                    <th scope="col">%</th>
                    <th scope="col">$</th>
                    <th scope="col">%</th>
                    <th scope="col">$</th>
                    <th scope="col">%</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Operating income</td>
                    <td>1,490,328</td>
                    <td>100</td>
                    <td>1,471,135</td>
                    <td>100</td>
                    <td>1,214,935</td>
                    <td>100</td>
                  </tr>
                  <tr>
                    <td>Consumption of raw materials and other supplies</td>
                    <td>-132,425</td>
                    <td>8.89</td>
                    <td>-129,476</td>
                    <td>8.80</td>
                    <td>-91,329</td>
                    <td>7.52</td>
                  </tr>
                  <tr>
                    <td>Gross profit margin </td>
                    <td>1,357,903</td>
                    <td>91.11</td>
                    <td>1,341,659</td>
                    <td>91.20</td>
                    <td>1,123,606</td>
                    <td>92.48</td>
                  </tr>
                  <tr>
                    <td>Gains on non-current asset disposals </td>
                    <td>25,730</td>
                    <td>1.73</td>
                    <td>54.409</td>
                    <td>3.70</td>
                    <td>-326</td>
                    <td>0.03</td>
                  </tr>
                  <tr>
                    <td>Operating expenses</td>
                    <td>-1,212,802</td>
                    <td>81.38</td>
                    <td>-1,271,383</td>
                    <td>86.42</td>
                    <td>-1,204,593</td>
                    <td>99.15</td>
                  </tr>
                  <tr>
                    <td>Staff costs </td>
                    <td>465,375</td>
                    <td>31.23</td>
                    <td>464,922</td>
                    <td>31.60</td>
                    <td>425,071</td>
                    <td>34.99</td>
                  </tr>
                  <tr>
                    <td>Depreciation</td>
                    <td>129,453</td>
                    <td>7.34</td>
                    <td>124,172</td>
                    <td>8.44</td>
                    <td>124,810</td>
                    <td>10.27</td>
                  </tr>
                  <tr>
                    <td>Net losses - assets impairment </td>
                    <td>277</td>
                    <td>0.02</td>
                    <td>32.060</td>
                    <td>2.18</td>
                    <td>36.832</td>
                    <td>3.03</td>
                  </tr>
                  <tr>
                    <td>Other operating expenses </td>
                    <td>637,697</td>
                    <td>42.79</td>
                    <td>650,229</td>
                    <td>42.20</td>
                    <td>617,880</td>
                    <td>50.86</td>
                  </tr>
                  <tr>
                    <td>Earnings before interests and taxes </td>
                    <td>170,831</td>
                    <td>11.46</td>
                    <td>124,685</td>
                    <td>8.48</td>
                    <td>-81,313</td>
                    <td>6.69</td>
                  </tr>
                  <tr>
                    <td>Financial income </td>
                    <td>9,769</td>
                    <td>0.66</td>
                    <td>8.617</td>
                    <td>0.59</td>
                    <td>9,770</td>
                    <td>0.80</td>
                  </tr>
                  <tr>
                    <td>Interest charges </td>
                    <td>-65,456</td>
                    <td>4.39</td>
                    <td>-77,860</td>
                    <td>5.19</td>
                    <td>-57,698</td>
                    <td>4.75</td>
                  </tr>
                  <tr>
                    <td>
                      Other financial items (entities valued by the equity
                      method of stock valuation, reasonable adjustment value,
                      net exchange rate differences, etc.){" "}
                    </td>
                    <td>-7,142</td>
                    <td>0.48</td>
                    <td>-46,662</td>
                    <td>3.17</td>
                    <td>-6,139</td>
                    <td>0.51</td>
                  </tr>
                  <tr>
                    <td>Financial results </td>
                    <td>-62,829</td>
                    <td>4.22</td>
                    <td>-115,905</td>
                    <td>7.88</td>
                    <td>-54,067</td>
                    <td>9.47</td>
                  </tr>
                  <tr>
                    <td>
                      Income from ordinary activities (Pre-tax earnings from
                      continuous activities){" "}
                    </td>
                    <td>108,002</td>
                    <td>7.25</td>
                    <td>8,780</td>
                    <td>0.60</td>
                    <td>-135,380</td>
                    <td>11.14</td>
                  </tr>
                  <tr>
                    <td>Taxes</td>
                    <td>-8,495</td>
                    <td> 0.57</td>
                    <td>-3,730</td>
                    <td>0.25</td>
                    <td>20,270</td>
                    <td>1.67</td>
                  </tr>
                  <tr>
                    <td>Earnings after taxes </td>
                    <td>99,504</td>
                    <td>6.68</td>
                    <td>5,050</td>
                    <td>0.34</td>
                    <td>-115,110</td>
                    <td>9.47</td>
                  </tr>
                  <tr>
                    <td>Attributable to the parent company </td>
                    <td>77,443</td>
                    <td>5.20</td>
                    <td>23,168</td>
                    <td>1.57</td>
                    <td>-97,100</td>
                    <td>7.99</td>
                  </tr>
                  <tr>
                    <td>Minority interests </td>
                    <td>22,064</td>
                    <td>1.48</td>
                    <td>-21,742</td>
                    <td>1.48</td>
                    <td>-18,010</td>
                    <td>1.48</td>
                  </tr>
                  <tr>
                    <td>Earnings per share (basic) </td>
                    <td>0.55</td>
                    <td> </td>
                    <td>0.16</td>
                    <td> </td>
                    <td>-0.48</td>
                    <td> </td>
                  </tr>
                  <tr>
                    <td>Depreciation</td>
                    <td>109,453</td>
                    <td>7.34</td>
                    <td>124,172</td>
                    <td>8.44</td>
                    <td>124,810</td>
                    <td>10.27</td>
                  </tr>
                  <tr>
                    <td>Staff costs </td>
                    <td>465,375</td>
                    <td>31.23</td>
                    <td>464,922</td>
                    <td>31.60</td>
                    <td>425,071</td>
                    <td>34.99</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="drthbvcdftyuiuhjuyg">
              <h3 className="my-3">BALANCE SHEET</h3>
              <div className="table-responsive">
                <table className="table table-bordered mb-0">
                  <thead>
                    <tr className="text-center">
                      <th rowSpan="2">Non-current assets </th>
                      <th colSpan="2">2007</th>
                      <th colSpan="2">2008</th>
                      <th colSpan="2">2009</th>
                    </tr>
                    <tr>
                      <th scope="col">$</th>
                      <th scope="col">%</th>
                      <th scope="col">$</th>
                      <th scope="col">%</th>
                      <th scope="col">$</th>
                      <th scope="col">%</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Non-current assets</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>Intangible assets</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>Goodwill</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>Intangible assets</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>Property, plant and equipment</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>Real estate investments </td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>
                        Financial assets (including Investments values using the
                        equity method){" "}
                      </td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>Other non-current assets</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>Stock</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>Clients and other debtors </td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>
                        Other current assets (including current financial
                        investments){" "}
                      </td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>Total Assets </td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>Equity</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>Capital</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>
                        Reserves, accounting adjustments, treasury stock and
                        profits and losses{" "}
                      </td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>Minority interests </td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>Long-term creditors </td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>Long-term debt with credit institutions </td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>Other long-term debts </td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>Short-term creditors </td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>Short-term debt with credit institutions </td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>Suppliers</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>Other short-term debt (non-commercial) </td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>Total Liabilities </td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                    <tr>
                      <td>Total Liabilities and Equity </td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                      <td>2008</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialStatement;
