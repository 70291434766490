import React from "react";

const NeutralIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="74"
      height="75"
      viewBox="0 0 74 75"
      fill="none"
    >
      <g clipPath="url(#clip0_445_1285)">
        <mask
          id="mask0_445_1285"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="74"
          height="75"
        >
          <path
            d="M-3.05176e-05 0.724098H74V74.7241H-3.05176e-05V0.724098Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_445_1285)">
          <path
            d="M50.7304 20.2358V15.8999C50.7304 14.3034 49.4363 13.0093 47.8398 13.0093H38.4453C36.4497 13.0093 34.832 14.627 34.832 16.6226C34.832 18.6181 36.4497 20.2358 38.4453 20.2358H43.5039L42.8935 20.5409C39.7245 22.1256 37.7226 25.3645 37.7226 28.9077"
            stroke="white"
            strokeWidth="4.33594"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M46.3945 13.0093V5.78271C46.3945 4.18622 45.1004 2.89209 43.5039 2.89209H37.7226"
            stroke="white"
            strokeWidth="4.33594"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M29.0508 41.9155V34.689C26.2746 31.9128 24.7148 28.1473 24.7148 24.2211V5.7827C24.7148 4.18621 26.0089 2.89207 27.6054 2.89207H37.7226"
            stroke="white"
            strokeWidth="4.33594"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M50.7304 18.7905V27.4624L44.9492 34.689V41.9155"
            stroke="white"
            strokeWidth="4.33594"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M46.3945 72.5562H27.6054V41.9155H46.3945V72.5562Z"
            stroke="white"
            strokeWidth="4.33594"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24.7148 8.67334V12.2866C24.7148 14.6812 26.6562 16.6226 29.0508 16.6226H34.832"
            stroke="white"
            strokeWidth="4.33594"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M67.4961 15.8999C67.4961 18.2945 65.5547 20.2358 63.1601 20.2358C60.7655 20.2358 58.8242 18.2945 58.8242 15.8999C58.8242 13.5053 60.7655 11.564 63.1601 11.564C65.5547 11.564 67.4961 13.5053 67.4961 15.8999Z"
            stroke="white"
            strokeWidth="4.33594"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M58.8242 15.8999H50.7304"
            stroke="white"
            strokeWidth="4.33594"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M71.832 15.8999H67.4961"
            stroke="white"
            strokeWidth="4.33594"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.50388 15.8999C6.50388 18.2945 8.44522 20.2358 10.8398 20.2358C13.2344 20.2358 15.1758 18.2945 15.1758 15.8999C15.1758 13.5053 13.2344 11.564 10.8398 11.564C8.44522 11.564 6.50388 13.5053 6.50388 15.8999Z"
            stroke="white"
            strokeWidth="4.33594"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.1758 15.8999H24.7148"
            stroke="white"
            strokeWidth="4.33594"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.16794 15.8999H6.50388"
            stroke="white"
            strokeWidth="4.33594"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M18.0664 43.3608H3.61325L10.8398 20.2358L18.0664 43.3608Z"
            stroke="white"
            strokeWidth="4.33594"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10.8398 54.9233C6.05048 54.9233 2.16794 51.0408 2.16794 46.2515V43.3608H19.5117V46.2515C19.5117 51.0408 15.6291 54.9233 10.8398 54.9233Z"
            stroke="white"
            strokeWidth="4.33594"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M55.9336 43.3608H70.3867L63.1601 20.2358L55.9336 43.3608Z"
            stroke="white"
            strokeWidth="4.33594"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M63.1601 54.9233C67.9495 54.9233 71.832 51.0408 71.832 46.2515V43.3608H54.4883V46.2515C54.4883 51.0408 58.3708 54.9233 63.1601 54.9233Z"
            stroke="white"
            strokeWidth="4.33594"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_445_1285">
          <rect
            width="74"
            height="74"
            fill="white"
            transform="translate(-3.05176e-05 0.724121)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NeutralIcon;
