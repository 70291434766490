import React from "react";
import "../../assests/styles.css";
import NewGraph from "./NewGraph";

const RatioGraph = ({ data: datas }) => {
  return (
    <>
      <NewGraph datas={datas} />
    </>
  );
};

export default RatioGraph;
