import React from "react";
import { Table } from "react-bootstrap";
import { Button } from "reactstrap";
import resultLogo from "./img/successImage.svg";

const ResultGameThree = () => {
  return (
    <div>
      <div className="container-md">
        <div className="result-page-full">
          <div className="result-page-top kduhjnfjgnjvduybghvjk">
            <div>
              <img src={resultLogo} alt=""></img>

              <p>Result Summery</p>
              <h4 style={{ color: "rgba(247, 185, 65, 1)" }}>
                Course Result:{" "}
              </h4>
            </div>
          </div>
          <div className="result-page-top kduhjnfjgnjvduybghvjk">
            <div className="row">
              <div className="col-md-6">
                <div className="profile-hjkdjfbajbknyfa">
                  <p>
                    <span className="result-title">Author : </span>
                  </p>

                  <p>
                    <span className="result-title">Course: </span>
                  </p>
                  <p>
                    <span className="result-title">Course Code: </span>
                  </p>
                </div>
              </div>{" "}
              <div className="col-md-6">
                <div className="profile-hjkdjfbajbknyfa">
                  <Table striped borderless hover>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Mark</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="profile-hsjbahfbdgjbh">
                            <div className="profile-rank-data-image">
                              <img src="" alt="MAAC" />
                            </div>
                            <div className="profile-rank-data">
                              <h5> Shakib </h5>
                              <p>www</p>
                            </div>
                          </div>
                        </td>
                        <td>55</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="col-md-12 mt-5">
                <Button>Go Home</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultGameThree;
