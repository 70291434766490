import Concepts1 from "../../LafargeAssets/icon/Concepts1";
import Contept2 from "../../LafargeAssets/icon/Contept2";
import Contept3 from "../../LafargeAssets/icon/Contept3";
import Contept4 from "../../LafargeAssets/icon/Contept4";

export const lafargeData = [
  {
    id: 1,
    name: "The Concept",
    boxColor: "concept-1",
    icon: <Concepts1 />,
    contact:
      "In this section, we will be detailing on from where the negotiation came and how it is useful in day to day business transaction and the impact of successful negotiation is business.",
  },
  {
    id: 2,
    name: "Why do we need this?",
    boxColor: "concept-2",
    icon: <Contept2 />,
    contact:
      "In this section, we will talk about the key goals after the negotiation workshop is done for LafargeHolcim; what are the baseline topic and the remedy of it.",
  },
  {
    id: 3,
    name: "Negotiation Process",
    boxColor: "concept-3",
    icon: <Contept3 />,

    contact:
      "In this section, we will re emphasize on the topics that we learned from the workshop and take a bite on the key terms requiring in our daily negotiation .",
  },
  {
    id: 4,
    name: "Gamification",
    boxColor: "concept-4",
    icon: <Contept4 />,
    contact:
      "In this section, we will put our knowledge into test with problems associated with day to day negotiation and create your own pitch.",
  },
];
