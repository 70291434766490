import React from "react";
import MainFooter from "../StudentPages/Common/footer/MainFooter";
import Header from "../StudentPages/Common/Header";
import checkIcon from "./img/success.svg";
import "./registration.scss";
const RegSuccess = () => {
  return (
    <div>
      <Header />
      <div className="success-message-main-wrap">
        <div className="success-message-inner-wrap">
          <img src={checkIcon} alt="" />
          <h4>Successful</h4>
          <p>A mail has been send in your account. Please Check</p>
        </div>
      </div>
      <MainFooter />
    </div>
  );
};

export default RegSuccess;
