import React from "react";
import { Route } from "react-router-dom";
import Coupon from "../components/Dashboard/DashboardItemHome/Coupon/Coupon";
import CouppnNew from "../components/Dashboard/DashboardItemHome/CouppnNew/CouppnNew";
import EditCoupon from "../components/Dashboard/DashboardItemHome/EditCoupon/EditCoupon";
import HousingMarket from "../components/HousingMarket/HousingMarket";
import HousingMarketIntroduction from "../components/HousingMarket/components/HousingMarketIntroduction";
import HousingMarketStepOne from "../components/HousingMarket/components/step/HousingMarketStepOne";
import HousingMarketStepThree from "../components/HousingMarket/components/step/HousingMarketStepThree";
import HousingMarketStepTwo from "../components/HousingMarket/components/step/HousingMarketStepTwo";
import CourseHome from "../components/StudentPages/AllCourses/CourseHome";
import AuthorOverview from "../components/StudentPages/AuthorOverview/AuthorOverview";
import BeforeLoginCart from "../components/StudentPages/BeforeLoginCart/BeforeLoginCart";
import CartPage from "../components/StudentPages/CartPage/CartPage";
import ChackoutPage from "../components/StudentPages/ChackoutPage/ChackoutPage";
import LoginHeader from "../components/StudentPages/Common/LoginHeader";
import CourseDetailsHome from "../components/StudentPages/CourseWareDetailsPage/CourseDetailsHome";
import CourseWareDetailsPageAfterLogin from "../components/StudentPages/CourseWareDetailsPageAfterLogin/CourseWareDetailsPageAfterLogin";
import CourseHomeCategory from "../components/StudentPages/CoursesStudent/CourseHomeCategory";
import EmptyCart from "../components/StudentPages/EmptyCart/EmptyCart";
import GiftReward from "../components/StudentPages/GiftReward/GiftReward";
import StudentCategoryHome from "../components/StudentPages/StudentCategory/StudentCategoryHome";
import StudentProfileEdit from "../components/StudentPages/StudentProfileEdit/StudentProfileEdit";
import StudentProfileView from "../components/StudentPages/StudentProfileView/StudentProfileView";
import TeacherCourseAdd from "../components/StudentPages/TeacherCourseAdd/TeacherCourseAdd";
import TeacherProfileEdit from "../components/StudentPages/TeacherProfileEdit/TeacherProfileEdit";
import TeacherProfileView from "../components/StudentPages/TeacherProfileView/TeacherProfileView";
import ThirdGame from "../components/ThirdGame/ThirdGame";
import ThirdGameFrame from "../components/ThirdGame/ThirdGameFrame";
import AboutUs from "../pages/AboutUs/AboutUs";
import PrivecyPolicy from "../pages/PrivecyPolicy/PrivecyPolicy";
import RetailGame from "../pages/RetailGame/RetailGame";
import ReturnPolicy from "../pages/ReturnPolicy/ReturnPolicy";
import TermAndConditions from "../pages/TermAndConditions/TermAndConditions";
import Settings from "./../components/Dashboard/DashboardItemHome/Settings/Settings";
import AuthorVIew from "./../components/StudentPages/AuthorProfile/AuthorVIew";
import CheckoutPageGift from "./../components/StudentPages/ChackOutPageGift/ChackoutPageGift";

import GameList from "../components/StudentGame/GameList";

import SubmisstionPage from "../components/Retail/SubmisstionPage";
import GameInformation from "../components/StudentGame/GameInformation";
import RetailGame2 from "../pages/RetailGame/RetailGame2";
import GameSecondInformation from "../components/StudentGame/GameSecondInformation";
import SecondGameSubmission from "../components/Retail/SecondGameSubmission";

const CategoryRoute = () => {
  return (
    <div>
      <Route exact path="/student-category/:academicId">
        <StudentCategoryHome />
      </Route>
      <Route exact path="/courses">
        <CourseHomeCategory />
      </Route>
      <Route exact path="/student-courses">
        <CourseHome />
      </Route>
      <Route exact path="/course-details-page/:courseId">
        <CourseDetailsHome />
      </Route>
      <Route exact path="/student-profile-page">
        <CourseWareDetailsPageAfterLogin />
      </Route>
      <Route exact path="/student-profile-view">
        <StudentProfileView />
      </Route>
      <Route exact path="/edit-student-profile">
        <StudentProfileEdit />
      </Route>
      <Route exact path="/teacher-profile-view">
        <TeacherProfileView />
      </Route>
      <Route exact path="/edit-teacher-profile">
        <TeacherProfileEdit />
      </Route>
      <Route exact path="/cart-page">
        <CartPage />
      </Route>
      <Route exact path="/checkout">
        <ChackoutPage />
      </Route>
      <Route exact path="/checkout-gift">
        <CheckoutPageGift />
      </Route>
      <Route exact path="/login-before-cart">
        <BeforeLoginCart />
      </Route>
      <Route exact path="/empty-cart">
        <EmptyCart />
      </Route>
      <Route exact path="/:courseId/teacher-add-course-page">
        <TeacherCourseAdd />
      </Route>
      {/* //author profile  */}
      <Route exact path="/:authorId/author-profile">
        <AuthorVIew />
      </Route>
      <Route exact path="/author-overview">
        <AuthorOverview />
      </Route>
      <Route exact path="/gift-reward-page">
        <GiftReward />
      </Route>
      <Route exact path="/coupon">
        <Coupon />
      </Route>
      <Route exact path="/coupon-new">
        <CouppnNew />
      </Route>
      <Route exact path="/coupon-edit">
        <EditCoupon />
      </Route>
      <Route exact path="/privecy-policy">
        <PrivecyPolicy />
      </Route>
      <Route exact path="/term-and-condition">
        <TermAndConditions />
      </Route>
      <Route exact path="/refund-return-policy">
        <ReturnPolicy />
      </Route>
      <Route exact path="/about-us">
        <AboutUs />
      </Route>
      <Route exact path="/settings-s">
        <Settings />
      </Route>
      <Route exact path="/:courseId/economic-super-power">
        <LoginHeader />
        <ThirdGame />
      </Route>
      <Route exact path="/economic-super-power-introduction">
        <LoginHeader />
        <ThirdGameFrame />
      </Route>
      <Route exact path="/student-game">
        <GameList />
      </Route>
      <Route exact path="/game-information/:id/:courseName">
        <GameInformation />
      </Route>
      <Route exact path="/game-two-information/:id/:courseName">
        <GameSecondInformation/>
        
      </Route>
      <Route exact path="/student-game-ques/:id/:courseName">
        <RetailGame />
      </Route>
      <Route exact path="/retail-game-2/:id/:courseName">
        <RetailGame2 />
      </Route>
      <Route exact path="/housing-market">
        <LoginHeader />
        <HousingMarket />
      </Route>
      <Route exact path="/housing-market-introduction">
        <LoginHeader />
        <HousingMarketIntroduction />
      </Route>
      <Route exact path="/housing-market-prediction">
        <LoginHeader />
        <HousingMarketStepOne />
      </Route>
      <Route exact path="/housing-market-prediction/step-2">
        <LoginHeader />
        <HousingMarketStepTwo />
      </Route>
      <Route exact path="/housing-market-prediction/step-3">
        <LoginHeader />
        <HousingMarketStepThree />
      </Route>
      <Route exact path="/housing-market-prediction/step-4">
        <LoginHeader />
        <HousingMarketStepOne />
      </Route>
      <Route exact path="/housing-market-prediction/step-5">
        <LoginHeader />
        <HousingMarketStepOne />
      </Route>{" "}
      <Route exact path="/retail-game">
        <RetailGame />
      </Route>
      <Route exact path="/game-sumbit">
        <SubmisstionPage />
      </Route>
      <Route exact path="/second-game-submission">
        <SecondGameSubmission />
      </Route>
    </div>
  );
};

export default CategoryRoute;
