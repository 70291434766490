export const POST_INTRO_DATA = "POST_INTRO_DATA";
export const POST_INTRO_DATA_SUCCESS = "POST_INTRO_DATA_SUCCESS";
export const POST_INTRO_DATA_FAIL = "POST_INTRO_DATA_FAIL";

export const ADD_COURSE_USER = "ADD_COURSE_USER";
export const ADD_COURSE_USER_SUCCESS = "ADD_COURSE_USER_SUCCESS";
export const ADD_COURSE_USER_FAIL = "ADD_COURSE_USER_FAIL";

export const ADD_QUES_SERIAL = "ADD_QUES_SERIAL";
export const ADD_QUES_SERIAL_SUCCESS = "ADD_QUES_SERIAL_SUCCESS";
export const ADD_QUES_SERIAL_FAIL = "ADD_QUES_SERIAL_FAIL";

export const REMOVE_COURSE_STUDENT = "REMOVE_COURSE_STUDENT";
export const REMOVE_COURSE_STUDENT_SUCCESS = "REMOVE_COURSE_STUDENT_SUCCESS";
export const REMOVE_COURSE_STUDENT_FAIL = "REMOVE_COURSE_STUDENT_FAIL";

export const GET_COURSE_ALL_STUDENT = "GET_COURSE_ALL_STUDENT";
export const GET_COURSE_ALL_STUDENT_SUCCESS = "GET_COURSE_ALL_STUDENT_SUCCESS";
export const GET_COURSE_ALL_STUDENT_FAIL = "GET_COURSE_ALL_STUDENT_FAIL";

export const GET_COURSE_SINGLE_STUDENT = "GET_COURSE_SINGLE_STUDENT";
export const GET_COURSE_SINGLE_STUDENT_SUCCESS =
  "GET_COURSE_SINGLE_STUDENT_SUCCESS";
export const GET_COURSE_SINGLE_STUDENT_FAIL = "GET_COURSE_SINGLE_STUDENT_FAIL";

export const GET_RETAIL_COURSE = "GET_RETAIL_COURSE";
export const GET_RETAIL_COURSE_SUCCESS = "GET_RETAIL_COURSE_SUCCESS";
export const GET_RETAIL_COURSE_FAIL = "GET_RETAIL_COURSE_FAIL";


export const GET_SECOND_GAME = "GET_SECOND_GAME";
export const GET_SECOND_GAME_SUCCESS = "GET_SECOND_GAME_SUCCESS";
export const GET_SECOND_GAME_FAIL = "GET_SECOND_GAME_FAIL";

export const STORE_GENERAL_DATA = "STORE_GENERAL_DATA";
export const CLEAR_SECOND_GAME_DATA = "CLEAR_SECOND_GAME_DATA"

export const STORE_SECOND_GAME_DATA = "STORE_SECOND_GAME_DATA";
