import React from "react";
import "./housing-market.scss";
import HousngMarketingIntro from "./HousngMarketingIntro";
const HousingMarket = () => {
  return (
    <div className="housing-market-main-game-wrap">
      <HousngMarketingIntro />
    </div>
  );
};

export default HousingMarket;
