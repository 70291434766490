import { call, put, takeEvery } from 'redux-saga/effects';
import { axiosUpdateProfile, axiosUserLogin } from '../../helpers/api_helper';
import { UserError } from '../loginError/actions';
import {
  loginSuccess,
  loginUserError,
  registerLoginFail,
  registerLoginSuccess,
} from './actions';
// Login Redux States
import { toast } from 'react-toastify';
import { postData } from '../../helpers/backend_helper';
import {
  LOGIN_USER,
  LOGOUT_USER,
  REGISTER_LOGIN,
  UPDATE_USER_INFO,
} from './actionTypes';

//3rd call async login
const loginWithEmailPasswordAsync = async (email, password) => {
  try {
    const response = await axiosUserLogin(email, password);
    // console.log("response here", response)
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

function* loginUser({ payload: { user, history } }) {
  const { email, password } = user;

  try {
    const loginUserResponse = yield call(
      loginWithEmailPasswordAsync,
      email,
      password
    );

    if (loginUserResponse.status === 'success') {
      yield put(
        loginSuccess(
          loginUserResponse.data.firstName,
          loginUserResponse.token,
          loginUserResponse.data.email,
          loginUserResponse.data.role
        )
      );

      yield put(UserError());

      if (loginUserResponse.data.role == 'admin') {
        history.push(`/${loginUserResponse.data.role}dashboard`);
      } else {
        // console.log("history data here", history.location.state)
        let intended = history.location.state;
        if (intended) {
          history.push(intended.from);
        } else {
          history.push('/home');
        }
      }
    } else {
      yield put(UserError());
      yield put(loginUserError());
      // toast.error(loginUserResponse.message)
      yield put(UserError(loginUserResponse.message));
    }
  } catch (error) {
    // console.log("error data", error.response)
    toast.error(error.response);
    yield put(UserError(error));
    yield put(loginUserError());
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    yield put(loginSuccess());
    localStorage.removeItem('persist:primary');
    localStorage.clear();

    history.push('/home');
  } catch (error) {
    // yield put(apiError(error))
  }
}

//update user info
const asyncUpdateProfile = async (token, firstName, lastName, phoneNumber) => {
  try {
    const response = await axiosUpdateProfile(
      token,
      firstName,
      lastName,
      phoneNumber
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
function* workerUpdateUserInfo({
  payload: { firstName, lastName, phoneNo, history },
}) {}

function* onRegisterLogin({ payload: { data, history, id, setLafargePopup } }) {
  try {
    const url = `/lafarz-registration-course-buy/${id}?courseName=lafarz`;
    const registerLoginResponse = yield call(postData, url, data);
    if (registerLoginResponse.status === 'success') {
      yield put(registerLoginSuccess(registerLoginResponse.message));
      yield put(
        loginSuccess(
          registerLoginResponse.data.firstName,
          registerLoginResponse.token,
          registerLoginResponse.data.email,
          registerLoginResponse.data.role
        )
      );
      setLafargePopup(false);
      history.push(`/course-details-page/${id}`);
    }
  } catch (err) {
    const message = err.response?.data?.message || 'Login Failed';
    yield put(registerLoginFail(message));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
  yield takeEvery(UPDATE_USER_INFO, workerUpdateUserInfo);
  yield takeEvery(REGISTER_LOGIN, onRegisterLogin);
}

export default authSaga;
