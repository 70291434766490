import React from "react";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import bgImage from "../img/bg-image2.png";
const HousingMarketIntroduction = () => {
  const history = useHistory();
  return (
    <div>
      <Container>
        <div className="housing-market-introduction-page">
          <div
            className="h-m-i-p-header"
            style={{ backgroundImage: `url(${bgImage})` }}
          >
            <div>
              <h2>
                Housing market <span>in Spain</span>{" "}
              </h2>
              <p>
                Lorem ipsum dolor sit amet consectetur. Elit felis duis eu
                pellentesque viverra. Risus
              </p>
            </div>
          </div>
          <div className="housing-market-introduction-page-content">
            <p>
              WalKart is a furniture manufacturer which wants to expand its
              buisness in south east Asia. Their flagship brand, Teaco is a
              household name and they are not available in the region.
              Currently, the size of the industry in Bangladesh is 1000 BDT, and
              there are 5 players who are purely manufacturers. T mart is the
              market leader having 25% share. Now you are assigned as commercial
              head who would launch Teaco in Bangladesh first, and then in
              Nepal. Few elements that you would consider:1. You have total 15
              BDT to launch Teaco. You need to decide how much would you spend
              in Bangladesh and in Nepal. Remember, Bangladesh has 3X more
              consumers than Nepal. You need to recruit 3 managers, one HR
              manager, and one BDM, and 1 Sales manager in your team. For that
              you have only 5 BDT budget, and sales manager can only have
              salaries in sales, no fixed amount therefore.
            </p>
          </div>
          <div className="housing-market-introduction-next-page-button">
            <button onClick={() => history.push("housing-market-prediction")}>
              Enter
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default HousingMarketIntroduction;
