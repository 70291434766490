import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { options } from "./Data";
const AllNewCourseFrameBody = () => {
  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>New Course Frame</h3>
            <p>
              Course {`>`} <span>Course Preview</span>{" "}
            </p>
          </div>
        </div>
        {/* <div className="packge-header-right">
          <Link to="/create-course-frame-new">
            <span>+ </span>
            Create New
          </Link>
        </div> */}
      </div>

      <div className="package-main-content-list new-main-area">
        <div className="new-individual-course-inner-wrap">
          <div className="new-individual-course-title">
            <h4>Create New Course Frame</h4>
          </div>
          <Form>
            <Row>
              <Col lg={6}>
                <Row className="g-0">
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Course Profile Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Type category name"
                          name="categoryName"
                        />
                      </Form.Group>
                    </div>
                  </Col>{" "}
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Package Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Type category name"
                          name="category"
                        />
                      </Form.Group>
                    </div>
                  </Col>{" "}
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Course Price</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Type category name"
                          name="category"
                        />
                      </Form.Group>
                    </div>
                  </Col>{" "}
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Duration</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Type category name"
                          name="category"
                        />
                      </Form.Group>
                    </div>
                  </Col>{" "}
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Add Quiz</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Type category name"
                          name="category"
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Assesment</Form.Label>
                        <Form.Select aria-label="Assesment">
                          <option>Select</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </Col>{" "}
              <Col lg={6}>
                <Row className="g-0">
                  <Col lg={12}>
                    <div className="input-data-single-field mb-3">
                      <Form.Label>Game ID</Form.Label>
                      <Select isMulti options={options} />
                    </div>
                  </Col>{" "}
                  <Col lg={12}>
                    <div className="input-data-single-field mb-3">
                      <Form.Label>Academic Area</Form.Label>
                      <Select isMulti options={options} />
                    </div>
                  </Col>{" "}
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Educator Copy</Form.Label>
                        <Form.Select aria-label="Assesment">
                          <option>Select</option>
                          <option value="1">One</option>
                          <option value="2">Two</option>
                          <option value="3">Three</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </Col>{" "}
                </Row>
              </Col>
              <Col lg={12}>
                <div className="input-data-single-field">
                  <Form.Group
                    className="mb-3 mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Course Summary</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter text here..."
                    />
                  </Form.Group>
                </div>
              </Col>
              <Col lg={6}>
                <div className="input-data-single-field">
                  <Form.Group className="mb-3">
                    <Form.Label>Course Language</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type category name"
                      name="category"
                    />
                  </Form.Group>
                </div>
              </Col>
              <Col lg={6}>
                <div className="input-data-single-field">
                  <Form.Group className="mb-3">
                    <Form.Label>Upload user image</Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="Type category name"
                      name="category"
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            <div className="form-submit-button-area-wrap">
              <button type="submit" className="btn create-new-item">
                Create New
              </button>
              <button type="submit" className="btn save-to-new-item-draft">
                Save as Draft
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AllNewCourseFrameBody;
