
export const GET_STUDENT_COURSE = "GET_STUDENT_COURSE"
export const GET_STUDENT_COURSE_SUCCESS = "GET_STUDENT_COURSE_SUCCESS"
export const GET_STUDENT_COURSE_FAIL = "GET_STUDENT_COURSE_FAIL"

export const GET_STUDENT_QUES = "GET_STUDENT_QUES"
export const GET_STUDENT_QUES_SUCCESS = "GET_STUDENT_QUES_SUCCESS"
export const GET_STUDENT_QUES_FAIL = "GET_STUDENT_QUES_FAIL"


export const GET_SINGLE_GAME = "GET_SINGLE_GAME"
export const GET_SINGLE_GAME_SUCCESS = "GET_SINGLE_GAME_SUCCESS"
export const GET_SINGLE_GAME_FAIL = "GET_SINGLE_GAME_FAIL"

export const STORE_STUDENT_GAME_DATA = "STORE_STUDENT_GAME_DATA"


export const CLEAR_STUDENT_GAME_DATA = "CLEAR_STUDENT_GAME_DATA"
