export const GET_AUTHORS_BY_ADMIN = "GET_AUTHORS_BY_ADMIN";
export const GET_AUTHORS_BY_ADMIN_SUCCESS = "GET_AUTHORS_BY_ADMIN_SUCCESS";

//GET SINGLE AUTHOR
export const GET_SINGLE_AUTHOR = "GET_SINGLE_AUTHOR";
export const GET_SINGLE_AUTHOR_SUCCESS = "GET_SINGLE_AUTHOR_SUCCESS";

//Update Author
export const UPDATE_AUTHOR = "UPDATE_AUTHOR";
export const UPDATE_AUTHOR_SUCCESS = "UPDATE_AUTHOR_SUCCESS";

//Category
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";

//create cateogry
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAIL = "CREATE_CATEGORY_FAIL";

//only category Id
export const GET_CATEGORY_IDS = "GET_CATEGORY_IDS";
export const GET_CATEGORY_IDS_SUCCESS = "GET_CATEGORY_IDS_SUCCESS";
//Student List
export const GET_STUDENTS = "GET_STUDENTS";
export const GET_STUDENTS_SUCCESS = "GET_STUDENTS_SUCCESS";

//get Single Student
export const GET_SINGLE_STUDENT = "GET_SINGLE_STUDENT";
export const GET_SINGLE_STUDENT_SUCCESS = "GET_SINGLE_STUDENT_SUCCESS";

//Academic Areas
//get acadmeic area
export const GET_ACADEMIC_AREAS = "GET_ACADEMIC_AREAS";
export const GET_ACADEMIC_AREAS_SUCCESS = "GET_ACADEMIC_AREAS_SUCCESS";

export const CREATE_ACADEMIC_AREA = "CREATE_ACADEMIC_AREA";
export const CREATE_ACADEMIC_AREA_SUCCESSS = "CREATE_ACADEMIC_AREA_SUCCESS";
export const CREATE_ACADEMIC_AREA_FAIL = "CREATE_ACADEMIC_AREA_FAIL";

//Update Academic Area
export const UPDATE_ACADEMIC_AREA = "UPDATE_ACADEMIC_AREA";
export const UPDATE_ACADEMIC_AREA_SUCCESS = "UPDATE_ACADEMIC_AREA_SUCCESS";
export const UPDATE_ACADEMIC_AREA_FAIL = "UPDATE_ACADEMIC_AREA_FAIL";

//get single academic Area
export const GET_SINGLE_ACADEMIC_AREA_ADMIN = "GET_SINGLE_ACADEMIC_AREA_ADMIN";
export const GET_SINGLE_ACADEMIC_AREA_SUCCESS_ADMIN =
	"GET_SINGLE_ACADEMIC_AREA_SUCCESS_ADMIN";
export const GET_SINGLE_ACADEMIC_AREA_FAIL_ADMIN =
	"GET_SINGLE_ACADEMIC_AREA_FAIL_ADMIN";
//GET MULTISELECTOROBJECT
export const GET_MULTISELECTOR_DATA = "GET_MULTISELECTOR_DATA";
export const GET_MULTISELECTOR_DATA_SUCCESS = "GET_MULTISELECTOR_DATA_SUCCESS";

//Create author
export const CREATE_AUTHOR = "CREATE_AUTHOR";
export const CREATE_AUTHOR_SUCCESS = "CREATE_AUTHOR_SUCCESS";
export const CREATE_AUTHOR_FAIL = "CREATE_AUTHOR_FAIL";

/* ----------Course Related------------ */
export const COURSE_RELATED_PREVOUS_MULTI_DATA =
	"COURSE_RELATED_PREVOUS_MULTI_DATA";
export const COURSE_RELATED_PREVOUS_MULTI_DATA_SUCCESS =
	"COURSE_RELATED_PREVOUS_MULTI_DATA_SUCCESS";

export const COURSE_CREATE = "COURSE_CREATE";
export const COURSE_CREATE_SUCCESS = "COURSE_CREATE_SUCCESS";
export const COURSE_CREATE_FAIL = "COURSE_CREATE_FAIL";

export const GET_COURSES = "GET_COURSES";
export const GET_COURSES_SUCCESS = "GET_COURSES_SUCCESS";

export const GET_SINGLE_COURSE = "GET_SINGLE_COURSE";
export const GET_SINGLE_COURSE_SUCCESS = "GET_SINGLE_COURSE_SUCCESS";
export const GET_SINGLE_COURSE_FAIL = "GET_SINGLE_COURSE_FAIL";

//get single course of Valid User
export const GET_SINGLE_COURSE_OF_VALID_USER =
	"GET_SINGLE_COURSE_OF_VALID_USER";
export const GET_SINGLE_COURSE_OF_VALID_USER_SUCCESS =
	"GET_SINGLE_COURSE_OF_VALID_USER_SUCCESS";
export const GET_SINGLE_COURSE_OF_VALID_USER_FAIL =
	"GET_SINGLE_COURSE_OF_VALID_USER_FAIL";

//get Course For Update
export const GET_COURSE_DATA_FOR_UPDATE = "GET_COURSE_DATA_FOR_UPDATE";
export const GET_COURSE_DATA_FOR_UPDATE_SUCCESS =
	"GET_COURSE_DATA_FOR_UPDATE_SUCCESS";
export const GET_COURSE_DATA_FOR_UPDATE_FAIL =
	"GET_COURSE_DATA_FOR_UPDATE_FAIL";

//upload author photo
export const UPLOAD_AUTHOR_PHOTO = "UPLOAD_AUTHOR_PHOTO";
export const UPLOAD_AUTHOR_PHOTO_SUCCESS = "UPLOAD_AUTHOR_PHOTO_SUCCESS";
export const UPLOAD_AUTHOR_PHOTO_FAIL = "UPLOAD_AUTHOR_PHOTO_FAIL";

//upload course photo
export const UPLOAD_COURSE_PHOTO = "UPLOAD_COURSE_PHOTO";
export const UPLOAD_COURSE_PHOTO_SUCCESS = "UPLOAD_COURSE_PHOTO_SUCCESS";
export const UPLOAD_COURSE_PHOTO_FAIL = "UPLOAD_COURSE_PHOTO_FAIL";

//update course edit data
export const UPDATE_COURSE = "UPDATE_COURSE";
export const UPDATE_COURSE_SUCCESS = "UPDATE_COURSE_SUCCESS";
export const UPDATE_COURSE_FAIL = "UPDATE_COURSE_FAIL";

// Update Format
export const GET_FORMAT = "GET_FORMAT";
export const GET_FORMAT_SUCCESS = "GET_FORMAT_SUCCESS";
export const GET_FORMAT_FAIL = "GET_FORMAT_FAIL";

export const UPDATE_FORMAT = "UPDATE_FORMAT";
export const UPDATE_FORMAT_SUCCESS = "UPDATE_FORMAT_SUCCESS";
export const UPDATE_FORMAT_FAIL = "UPDATE_FORMAT_FAIL";
//update course data

export const GET_ORDERS = "GET_ORDERS";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCCESS";
