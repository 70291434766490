export const FormatData = [
  {
    id: 1,
    formatName: "Marketing",
    Slug: "03",
    AcademicAreaName: "Dipto Nath",
    CategoryName: "Games",
  },
  {
    id: 2,
    formatName: "Management",
    Slug: "03",
    AcademicAreaName: "Dipto Nath",
    CategoryName: "Simulation",
  },
  {
    id: 3,
    formatName: "Finance",
    Slug: "03",
    AcademicAreaName: "Dipto Nath",
    CategoryName: "Case Studies",
  },
  {
    id: 4,
    formatName: "Finance",
    Slug: "03",
    AcademicAreaName: "Dipto Nath",
    CategoryName: "Multimedia",
  },
  {
    id: 5,
    formatName: "Law",
    Slug: "03",
    AcademicAreaName: "Dipto Nath",
    CategoryName: "Key Concepts",
  },
  {
    id: 6,
    formatName: "English",
    Slug: "03",
    AcademicAreaName: "Dipto Nath",
    CategoryName: "PDF/PPT",
  },
  {
    id: 7,
    formatName: "Bangla",
    Slug: "03",
    AcademicAreaName: "Dipto Nath",
    CategoryName: "Lecture Series",
  },
  {
    id: 8,
    formatName: "Arabic",
    Slug: "03",
    AcademicAreaName: "Dipto Nath",
    CategoryName: "Lecture Series",
  },
  {
    id: 9,
    formatName: "Arabic",
    Slug: "03",
    AcademicAreaName: "Dipto Nath",
    CategoryName: "Teachers note",
  },
];
