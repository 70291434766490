import React, { useEffect, useState } from "react";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  getSingleAcademicAreaAdmin,
  updateAcademicArea,
} from "../../../../store/actions";
import DashboardCommonAllLink from "../../DashboardCommon/DashboardCommonAllLink";
import DashboardCoomonTopbar from "../../DashboardCommon/DashboardCoomonTopbar";
import AcademicEditBody from "./AcademicEditBody";

const AcademicEdit = () => {
  const { pathname } = useLocation();
  const { academicId } = useParams();
  let history = useHistory();
  let dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const { token, loading, academicArea } = useSelector((state) => ({
    token: state.Login.token,
    loading: state.AdminAuthorReducer.loading,
    academicArea: state.AdminAuthorReducer.academicArea,
  }));

  let [academicName, setAcadmeicName] = useState({
    name: academicArea?.academicName,
  });
  const [isOpen, setIsOpen] = useState(true);
  const [toggle, setToggle] = useState(false);
  // const [toggleClass, setToggleClass] = useState("");
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);

    // setToggleClass("showNavbar");
  };

  useEffect(() => {
    dispatch(getSingleAcademicAreaAdmin(token, academicId));
  }, []);

  useEffect(() => {
    if (academicArea) {
      setAcadmeicName({
        name: academicArea?.academicName || "",
      });
    }
  }, [academicArea]);

  const hanldeUpdateAcademicname = (e) => {
    e.preventDefault();
    setAcadmeicName({
      name: e.target.value,
    });
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    if (academicName) {
      dispatch(
        updateAcademicArea(token, academicId, academicName.name, history)
      );
    }
  };
  return (
    <>
      <DashboardCoomonTopbar />
      <div
        className={
          toggle
            ? "dashboard-sidebar-nav-area showNavbar"
            : "dashboard-sidebar-nav-area"
        }
      >
        <button
          onClick={() => {
            toggleDrawer();
            setToggle(!toggle);
          }}
          className="main-sidebar-toggle-button"
        >
          <i className="bx bx-menu"></i>
        </button>
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="left"
          className={`navbar-inner-main-wrap-items-wrap `}
        >
          <button
            onClick={() => {
              toggleDrawer();
              setToggle(!toggle);
            }}
            className="main-sidebar-toggle-button"
          >
            <i className="bx bx-menu"></i>
          </button>
          <div className={`navbar-item-single-main-area-jddiu`}>
            <DashboardCommonAllLink />
          </div>
        </Drawer>
      </div>
      <div
        className={
          toggle ? "dashboard-main-body showNavbar" : "dashboard-main-body"
        }
      >
        <AcademicEditBody
          academicName={academicName}
          hanldeUpdateAcademicname={hanldeUpdateAcademicname}
          handleUpdate={handleUpdate}
          history={history}
        />
      </div>
    </>
  );
};

export default AcademicEdit;
