const AsOrganization = () => {
  return (
    <div className="as-organization-content-area">
      <h2>As Organization</h2>
      <p>The Target Dilemma</p>
      <p>
        Confidential role information for Mr. Nayan; ASM of Lafarge Bangladesh
        (Organization)
      </p>
      <p>
        You are the ASM of Mirpur area of Lafarge Bangladesh, one of the largest
        cement companies in Bangladesh which has immense brand value for its
        products and its sales forces reputation preceded than any other
        multinational cement company in Bangladesh. Recently your organization
        has organized a sales conference where your monthly target for each of
        your distributors in your area.
      </p>
      <p>
        You are all but okay with one distributor’s target, M/S Shymoli Trading,
        who is also your exclusive distributor.{" "}
      </p>
      <p>
        M/S Shymoli Trading, one of the largest distributors in Adabor area is
        also your highest contributor also, contributing 20% of your total
        sales. Since your total target has increased, so did your individual
        distributor's target and you have to take commitment from Fahmid bhai
        (owner) he will agree to 50,000 bags in this quarter.{" "}
      </p>
      <p>
        Why are you worried? Because of two reasons. One is the recent price
        hike, where the price of supercrete PCC has increased 10% which was
        previously 500 taka. Since then Fahmid vai started being skeptical
        whether he will be able to sell cement at this higher price or not. Two,
        due to the recent price hike, he is starting to consider stepping down
        from exclusive distributor to Multi brand distributor (Fahmid vai did
        not told you directly)
      </p>
      <p>
        To cover up this situation, management offered you the same incentive
        package of 5 taka/bag, same as other distributors but also allowed 3
        taka/bag as additional commission if you can keep him as exclusive
        distributor. Also, the maximum allowed credit term has increased from 30
        days from 45 days but also warned that 2% loss will incur as finance
        charge from the area if it goes above 45 days. Management has also taken
        decision to increase IHB activities in all areas and will be running a
        Consumer promotion all over Bangladesh
      </p>
      <p>
        Your objective, should you choose to accept it ! is to
        <ul>
          <li>
            1. Keep M/S Shymoli traders as an exclusive distributor for next
            quarter.
          </li>
          <li>
            2. Take commitment from Fahmid vai that he will do 50,000 bag sales
            in the next quarter.{" "}
          </li>
        </ul>
      </p>
      <p>
        While doing that find out your BATNA, Reservation value and ZOPA (if
        there is one)
      </p>
      <p>
        <b>
          Record your pitch on how you will prepare for this negotiation, what
          will be the opening argument and the possible solution for your
          win-win negotiation
        </b>
      </p>
    </div>
  );
};

export default AsOrganization;
