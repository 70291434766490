import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import {
  createAuthor,
  getMultiSelectorData,
  uploadAuthorPhoto,
} from "../../../../store/actions";
const AdminAuthorNewBody = () => {
  let dispatch = useDispatch();
  let history = useHistory();
  let [authorName, setAuthorName] = useState("");
  let [email, setEmail] = useState("");
  let [phoneNumber, setPhoneNumber] = useState("");
  let [courseAcademicIds, setCourseAcademicId] = useState([]);
  let [description, setDescription] = useState("");
  // let [photo, setPhoto] = useState("")
  let [courseFormatIds, setCourseFormatIds] = useState([]);
  const { token, loading, author, multiSelectorData, photo } = useSelector(
    (state) => ({
      token: state.Login.token,
      loading: state.AdminAuthorReducer.loading,
      author: state.AdminAuthorReducer.author,
      multiSelectorData: state.AdminAuthorReducer.multiSelectorData,
      photo: state.AdminAuthorReducer.photo,
    })
  );

  const handleAcademicChange = (selectedOption) => {
    setCourseAcademicId(selectedOption);
  };
  const handleFormatChange = (selectedOption) => {
    setCourseFormatIds(selectedOption);
  };
  useEffect(() => {
    dispatch(getMultiSelectorData(token));
  }, []);

  const handleSubmitData = (e) => {
    e.preventDefault();
    if (
      !courseFormatIds.length ||
      !courseAcademicIds.length ||
      !phoneNumber ||
      !authorName ||
      !email
    ) {
      toast.error("PLease filled all the field correctly");
    } else {
      console.log("submit form here");
      let formatId = [];
      let academicId = [];
      courseFormatIds.map((v, i) => {
        formatId.push(v.value);
      });
      courseAcademicIds.map((v, i) => {
        academicId.push(v.value);
      });

      dispatch(
        createAuthor(
          token,
          formatId,
          academicId,
          phoneNumber,
          authorName,
          email,
          photo,
          description,
          history
        )
      );
    }
  };

  const handleUploadAuthorImage = (e) => {
    console.log("hello photo", e.target.files[0]);

    let formData = new FormData();

    formData.append("photo", e.target.files[0]);
    dispatch(uploadAuthorPhoto(token, formData));
  };
  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>New Author Create</h3>
            <p>
              Course list<i className="bx bx-chevron-right"></i>{" "}
              <Link to="/dashboard-author-area">Author</Link>{" "}
              <i className="bx bx-chevron-right"></i>
              <span> New Author Create</span>{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="package-main-content-list new-main-area">
        <div className="new-individual-course-inner-wrap">
          <div className="new-individual-course-title">
            <h4>New Author Create</h4>
          </div>
          <Form>
            <Row>
              <Col lg={6}>
                <Row className="g-0">
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Author Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Type name"
                          name="categoryName"
                          value={authorName}
                          onChange={(e) => setAuthorName(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </Col>{" "}
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          style={{ textTransform: "lowercase" }}
                          placeholder="Type email address"
                          name="category"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </Col>{" "}
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Course Academic Area</Form.Label>
                        <Select
                          value={courseAcademicIds}
                          isMulti
                          onChange={handleAcademicChange}
                          options={multiSelectorData.getAcademicAreas}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Upload Author's image</Form.Label>
                        {/* {JSON.stringify(photo)} */}
                        {photo && (
                          <img
                            className="autor"
                            src={`${process.env.REACT_APP_IMAGE_URL}${photo}`}
                            height={"70px"}
                          />
                        )}

                        <Form.Control
                          type="file"
                          placeholder="Type category name"
                          name="category"
                          accept="images/*"
                          onChange={(e) => handleUploadAuthorImage(e)}
                          //  disabled={e => }
                          // onChange={(e) => setPhoto(e.target.files[0])}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </Col>{" "}
              <Col lg={6}>
                <Row className="g-0">
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Author ID</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Type author id"
                          name="categoryName"
                        />
                      </Form.Group>
                    </div>
                  </Col>{" "}
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Phone No.</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Type phone no."
                          name="category"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </Col>{" "}
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="about author..."
                          name="description"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </Col>{" "}
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Course Format</Form.Label>
                        <Select
                          value={courseFormatIds}
                          isMulti
                          onChange={handleFormatChange}
                          options={multiSelectorData?.getcategories}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            {loading ? (
              <Spinner />
            ) : (
              <div className="form-submit-button-area-wrap">
                <button
                  type="submit"
                  className="btn create-new-item"
                  onClick={(e) => handleSubmitData(e)}
                >
                  Create New
                </button>
                {/* <button type="submit" className="btn save-to-new-item-draft">
              Save as Draft
            </button> */}
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AdminAuthorNewBody;
