import React from "react";
import { useHistory } from "react-router-dom";

const StepNavbar = ({ pathname }) => {
  const history = useHistory();
  return (
    <div className="">
      <ul>
        <li
          className={pathname === "/housing-market-prediction" ? "active" : ""}
          onClick={() => history.push("/housing-market-prediction")}
        >
          Step 1
        </li>
        <li
          className={
            pathname === "/housing-market-prediction/step-2" ? "active" : ""
          }
          onClick={() => history.push("/housing-market-prediction/step-2")}
        >
          Step 2
        </li>
        <li
          className={
            pathname === "/housing-market-prediction/step-3" ? "active" : ""
          }
          onClick={() => history.push("/housing-market-prediction/step-3")}
        >
          Step 3
        </li>
        <li
          className={
            pathname === "/housing-market-prediction/step-4" ? "active" : ""
          }
          onClick={() => history.push("/housing-market-prediction/step-4")}
        >
          Step 4
        </li>
        <li
          className={
            pathname === "/housing-market-prediction/step-5" ? "active" : ""
          }
          onClick={() => history.push("/housing-market-prediction/step-5")}
        >
          Step 5
        </li>
      </ul>
    </div>
  );
};

export default StepNavbar;
