import React from "react";
import { Container } from "react-bootstrap";
import closeIcon from "./img/close-circle.svg";
import "./promoCode.scss";
const PromoCode = ({coupon,handleMove}) => {
  return (
    <div className="promocode-main-area-wrap">
      <Container>
        <div className="promocode-inner-wrap">
          <h6>Use Coupon: {coupon.couponCode} (For {coupon.couponPercentage}% discount)</h6>
          <img src={closeIcon} alt="" onClick = {e => handleMove(e)} />
        </div>
      </Container>
    </div>
  );
};

export default PromoCode;
