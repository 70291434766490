import React, { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAuthorData } from "../../../store/actions";
import MainFooter from "../Common/footer/MainFooter";
import Header from "../Common/Header";
import LoginHeader from "../Common/LoginHeader";
import AuthorViewProfile from "./AuthorViewProfile";
import "./studentProfileView.scss";
const AuthorVIew = () => {
  const { authorId } = useParams();
  console.log("author id", authorId);
  let dispatch = useDispatch();
  const { token, students, loading, authorData } = useSelector((state) => ({
    token: state.Login.token,
    students: state.courseReducer.students,
    loading: state.courseReducer.loading,
    authorData: state.academicAreaReducer.authorData,
  }));
  useEffect(() => {
    dispatch(getAuthorData(authorId));
  }, []);
  return (
    <div>
      <MetaTags>
        <title>Author View | GameProject</title>
      </MetaTags>
      {token ? <LoginHeader /> : <Header />}
      {/* <LoginHeader /> */}
      <AuthorViewProfile authorData={authorData} />
      <MainFooter />
    </div>
  );
};

export default AuthorVIew;
