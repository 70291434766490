import React from "react";
import { Col, Row, Table } from "react-bootstrap";
import icon4 from "./img/clock_circle.svg";
import courseIcon from "./img/courseImage.png";
import icon3 from "./img/email.svg";
import icon2 from "./img/ios-smartphone.svg";
import icon1 from "./img/person.svg";
import profileImage from "./img/profile.png";
const StudentsResultBody = () => {
  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>User</h3>
            <p>
              Course > <span>Category List</span>{" "}
            </p>
          </div>
        </div>
        {/* <div className="packge-header-right">
          <Link to="/create-new-package">
            <span>+ </span>
            Create New
          </Link>
        </div> */}
      </div>
      <div className="package-main-content-list">
        <div className="profile-top-view">
          <div className="profile-image">
            <img src={profileImage} alt="" />
          </div>
          <div className="profile-content">
            <Table borderless>
              <thead>
                <tr>
                  <th>Student Name</th>
                  <th>Total Course</th>
                  <th>Total Category</th>
                  <th>Total Academic Area</th>
                  <th>Total Active TIme</th>
                  <th>Total Student</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Samsad Rashid</td>
                  <td>6</td>
                  <td>2</td>
                  <td>2</td>
                  <td>10hrs</td>
                  <td>10,000</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <Row>
        <Col lg={4}>
          <div className="profile-general-information">
            <h4>General Information</h4>
            <div className="profile-user-info-wrap">
              <div className="user-info-single-item">
                <div className="user-info-item">
                  <span>
                    <img src={icon1} alt="" />
                  </span>
                  <span> User Role</span>
                </div>
                <div className="user-info-item2">
                  : <span>Student</span>
                </div>
              </div>{" "}
              <div className="user-info-single-item">
                <div className="user-info-item">
                  <span>
                    <img src={icon2} alt="" />
                  </span>
                  <span> Mobile</span>
                </div>
                <div className="user-info-item2">
                  : <span>+88 0171777785</span>
                </div>
              </div>{" "}
              <div className="user-info-single-item">
                <div className="user-info-item">
                  <span>
                    <img src={icon3} alt="" />
                  </span>
                  <span> Email</span>
                </div>
                <div className="user-info-item2">
                  : <span>useremail@mail.com</span>
                </div>
              </div>{" "}
              <div className="user-info-single-item">
                <div className="user-info-item">
                  <span>
                    <img src={icon4} alt="" />
                  </span>
                  <span> Joined at</span>
                </div>
                <div className="user-info-item2">
                  : <span>5 May 2021</span>
                </div>
              </div>
            </div>
            <div className="profile-user-info-wrap mt-5">
              <p>Payment Method</p>
              <div className="payment-method-wrapper-sdfcgvhbjn">
                <p>Credit Card</p>
              </div>
            </div>
            <div className="profile-user-info-wrap">
              <p>Package</p>
              <div className="payment-method-wrapper-sdfcgvhbjn">
                <p>Individual</p>
              </div>
            </div>
          </div>
        </Col>
        <Col lg={8}>
          <div className="profile-general-information">
            <h4>Course Information</h4>

            <div className="profile-user-info-wrap mt-5">
              <p>Course Category</p>
              <div className="payment-method-wrapper-sdfcgvhbjnhgvbj">
                <ul>
                  <li>Games</li>
                  <li>Simulation</li>
                </ul>
              </div>
            </div>
            <div className="profile-user-info-wrap mt-4">
              <p>Academic Area</p>
              <div className="payment-method-wrapper-sdfcgvhbjnhgvbj">
                <ul>
                  <li>Finance</li>
                  <li>Marketing</li>
                </ul>
              </div>
            </div>
            <div className="course-list-profile-page">
              <div className="dashboard-sidebar-single-item">
                <div className="sidebar-course-list">
                  <div className="course-sidebar-single-item-main-wrap ujhgfbsdvzbsd kjhgfedxgfd">
                    <p>Marketing</p>
                    <div className="course-sidebar-single-item ujhgfbsdvzbsd kjhgfedxgfd">
                      <div className="course-sidebar-single-item-left-sdfghcjv easfv">
                        <span className="course-sidebar-single-item-jhkdfgnsglfkzdg">
                          <p>100.</p>
                        </span>
                        <img src={courseIcon} alt="" />
                        <p>5.0 Brand Campaign</p>
                      </div>
                      <div className="course-sidebar-single-item-right-sdfghcjv rthbgxh">
                        <p>66 Points</p>
                      </div>
                    </div>{" "}
                  </div>{" "}
                  <div className="course-sidebar-single-item-main-wrap ujhgfbsdvzbsd kjhgfedxgfd">
                    <p>Finance</p>
                    <div className="course-sidebar-single-item ujhgfbsdvzbsd kjhgfedxgfd">
                      <div className="course-sidebar-single-item-left-sdfghcjv easfv">
                        <span className="course-sidebar-single-item-jhkdfgnsglfkzdg">
                          <p>120.</p>
                        </span>
                        <img src={courseIcon} alt="" />
                        <p>5.0 Brand Campaign</p>
                      </div>
                      <div className="course-sidebar-single-item-right-sdfghcjv rthbgxh">
                        <p>76 Points</p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default StudentsResultBody;
