import React from "react";
import MainFooter from "../Common/footer/MainFooter";
import LoginHeader from "../Common/LoginHeader";
import "./studentProfileView.scss";
import TeacherProfileDetails from "./TeacherProfileDetails";
const TeacherProfileView = () => {
  return (
    <div>
      <LoginHeader />
      <TeacherProfileDetails />
      <MainFooter />
    </div>
  );
};

export default TeacherProfileView;
