import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";
import { logoutUser } from "../../../store/actions";
import "../StudentHomePage/components/Home/assets/css/header.css";
import "./header.scss";
import profileimage from "./img/profilePicture.png";
import bagCart from "./img/shoping.svg";
import mainLogo from "./logo.svg";
const LoginHeader = () => {
  const [toggle, setToggle] = useState(false);
  let dispatch = useDispatch();
  let history = useHistory();
  const handleLogout = () => {
    dispatch(logoutUser(history));
  };

  const { token, cart, username, firstname, email, role } = useSelector(
    (state) => ({
      token: state.Login.token,
      username: state.Login.username,
      role: state.Login.userrole,
      firstname: state.Login.firstname,
      email: state.Login.email,
      cart: state.cartReducer.cart,
    })
  );
  return (
    <header className="header-main-area">
      <div className="container">
        <div className="header-inner-wrap login-after-navbar">
          <div className="header-logo">
            <Link to="/home">
              <img src={mainLogo} alt="" />
            </Link>
          </div>
          <div className="menuToggle" onClick={() => setToggle(!toggle)}>
            {toggle ? (
              <div className="navbar_mobile_close remove">
                <span></span>
                <span></span>
                <span></span>
              </div>
            ) : (
              <div className=" navbar_mobile_menu_bar">
                <span></span>
                <span></span>
                <span></span>
              </div>
            )}
          </div>
          <nav
            className={
              toggle ? "navbar_mobile_menu show" : "navbar_mobile_menu hide"
            }
          >
            <div className="header-navbar">
              <ul>
                <li>
                  <NavLink
                    exact
                    activeClassName="navbar__link--active"
                    className="navbar__link"
                    to="/home"
                  >
                    Home
                  </NavLink>
                </li>
                {/* <li>
                  <a
                    exact
                    activeClassName="navbar__link--active"
                    className="navbar__link"
                    href="#Techer_Panel"
                  >
                    About
                  </a>
                </li>
                <li>
                  <a
                    exact
                    activeClassName="navbar__link--active"
                    className="navbar__link"
                    href="#Student_Panel"
                  >
                    Resources
                  </a>
                </li> */}
                <li>
                  <a
                    exact
                    activeClassName="navbar__link--active"
                    className="navbar__link"
                    href="https://maacsolutions.com/contact"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Contact Us
                  </a>
                </li>
                <li>
                  <Link to="/cart-page">
                    <span className="navbar-card-area-wrap">
                      <img src={bagCart} alt="" />
                      <span>{cart ? cart.length : 0}</span>
                    </span>
                  </Link>
                </li>

                <li>
                  <div
                    exact
                    className="navbar__link header-login-btn ghbfjnkdlfjhbnvmjnv"
                  >
                    <Dropdown className="header-login-dropdown">
                      <Dropdown.Toggle id="dropdown-basic">
                        <img src={profileimage} alt="" />
                        {username ? username : email.split("@")[0]}
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {role == "admin" ? (
                          <Dropdown.Item>
                            <Link to="/admindashboard">Dashboard</Link>
                          </Dropdown.Item>
                        ) : (
                          <>
                          <Dropdown.Item>
                            <Link to="/student-profile-view">Profile</Link>
                          </Dropdown.Item>
                          <Dropdown.Item>
                          <Link to="/student-game">Game</Link>
                        </Dropdown.Item>
                        </>
                        )}
                        {/* <span className="font-smaller"> Or</span> */}
                        <Dropdown.Item onClick={() => handleLogout()}>
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default LoginHeader;
