import React from "react";
import { MetaTags } from "react-meta-tags";
import MainFooter from "../Common/footer/MainFooter";
import LoginHeader from "../Common/LoginHeader";
import StudentProfileEditPage from "./StudentProfileEditPage";
import "./studentProfileView.scss";
const StudentProfileEdit = () => {
  return (
    <div>
      <MetaTags>
        <title>Edit Profile | GameProject</title>
      </MetaTags>
      <LoginHeader />
      <StudentProfileEditPage />
      <MainFooter />
    </div>
  );
};

export default StudentProfileEdit;
