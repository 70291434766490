import {
	COMPARATIVE_ANALYSIS,
	COMPARATIVE_ANALYSIS_SUCCESS,
	SUBMIT_REV,
	SUBMIT_REV_SUCCESS,
} from "./actionTypes";

const initialState = {
	loading: false,
	comparativeData: [],
	revData: 0,
	ratioType: "",
};

const recruitReducer = (state = initialState, action) => {
	switch (action.type) {
		case COMPARATIVE_ANALYSIS:
			state = {
				...state,
				loading: true,
			};
		case COMPARATIVE_ANALYSIS_SUCCESS:
			state = {
				...state,
				loading: false,
				comparativeData: action.payload.data,
				ratioType: action.payload.ratioType,
			};
			break;
		case SUBMIT_REV:
			state = {
				...state,
				loading: true,
			};
			break;
		case SUBMIT_REV_SUCCESS:
			state = {
				...state,
				loading: false,
				revData: action.payload.data,
			};
			break;
		default:
			state = state;
			break;
	}
	return state;
};
export default recruitReducer;
