import React from "react";

const SuccessfulIcon = () => {
  return (
    <svg
      width="61"
      height="62"
      viewBox="0 0 61 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M59.08 34.4071C59.0796 39.8649 57.4688 45.2001 54.4514 49.7378C51.4339 54.2756 47.1454 57.8121 42.1281 59.9003C37.1107 61.9885 31.5899 62.5345 26.2638 61.4693C20.9377 60.4041 16.0455 57.7754 12.2058 53.9158C8.3662 50.0563 5.75158 45.139 4.69263 39.786C3.63367 34.433 4.17794 28.8845 6.2566 23.8424C8.33526 18.8002 11.855 14.4908 16.3706 11.459C20.8862 8.4273 26.195 6.8094 31.6255 6.80995C35.2366 6.79078 38.8157 7.49154 42.1556 8.8717C45.4954 10.2519 48.5298 12.284 51.083 14.8505C53.6363 17.4171 55.6577 20.467 57.0304 23.8239C58.4031 27.1808 59.0997 30.7779 59.08 34.4071Z"
        fill="url(#paint0_linear_105_1432)"
      />
      <path
        d="M14.2204 1.99885C14.2204 2.38427 14.1067 2.76104 13.8936 3.08151C13.6806 3.40198 13.3778 3.65176 13.0235 3.79927C12.6692 3.94678 12.2793 3.98539 11.9032 3.91022C11.527 3.83505 11.1815 3.64948 10.9103 3.37697C10.6391 3.10445 10.4544 2.75724 10.3796 2.37923C10.3047 2.00122 10.3431 1.60939 10.4898 1.25329C10.6365 0.897185 10.885 0.592803 11.2038 0.37863C11.5227 0.164456 11.8975 0.0501101 12.281 0.0500489C12.5357 0.0500082 12.7879 0.100385 13.0232 0.198303C13.2585 0.296221 13.4723 0.439761 13.6524 0.620728C13.8325 0.801695 13.9753 1.01654 14.0728 1.253C14.1703 1.48946 14.2204 1.7429 14.2204 1.99885Z"
        fill="url(#paint1_linear_105_1432)"
      />
      <path
        d="M59.9669 55.2632C60.4566 55.2632 60.8537 54.8642 60.8537 54.372C60.8537 53.8797 60.4566 53.4807 59.9669 53.4807C59.4771 53.4807 59.0801 53.8797 59.0801 54.372C59.0801 54.8642 59.4771 55.2632 59.9669 55.2632Z"
        fill="url(#paint2_linear_105_1432)"
      />
      <path
        d="M56.6471 10.1956C57.1368 10.1956 57.5339 9.79656 57.5339 9.30434C57.5339 8.81211 57.1368 8.41309 56.6471 8.41309C56.1573 8.41309 55.7603 8.81211 55.7603 9.30434C55.7603 9.79656 56.1573 10.1956 56.6471 10.1956Z"
        fill="url(#paint3_linear_105_1432)"
      />
      <path
        d="M1.91898 50.4325C1.91892 50.6087 1.86688 50.7809 1.76945 50.9274C1.67202 51.0738 1.53356 51.188 1.37159 51.2554C1.20962 51.3228 1.0314 51.3404 0.859463 51.306C0.687529 51.2716 0.529596 51.1868 0.405636 51.0622C0.281677 50.9376 0.197263 50.7789 0.163047 50.6061C0.128832 50.4333 0.146354 50.2542 0.213412 50.0914C0.28047 49.9286 0.39405 49.7895 0.539785 49.6915C0.685521 49.5936 0.85688 49.5413 1.03219 49.5413C1.14864 49.5413 1.26394 49.5643 1.37153 49.6091C1.47913 49.6539 1.57689 49.7195 1.65924 49.8023C1.74159 49.8851 1.80692 49.9833 1.85148 50.0914C1.89605 50.1996 1.91898 50.3155 1.91898 50.4325Z"
        fill="url(#paint4_linear_105_1432)"
      />
      <path
        d="M41.5644 28.8118L29.2391 41.1989L23.6367 35.5683"
        stroke="white"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_105_1432"
          x1="31.575"
          y1="-0.414541"
          x2="31.6234"
          y2="62"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00CD2D" />
          <stop offset="1" stopColor="#1DE448" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_105_1432"
          x1="10.5207"
          y1="-15.6767"
          x2="23.5518"
          y2="113.948"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00CD2D" />
          <stop offset="1" stopColor="#00CD2D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_105_1432"
          x1="59.1617"
          y1="46.2883"
          x2="65.1212"
          y2="105.57"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00CD2D" />
          <stop offset="1" stopColor="#00CD2D" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_105_1432"
          x1="55.8418"
          y1="1.22069"
          x2="61.8014"
          y2="60.5026"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00CD2D" />
          <stop offset="1" stopColor="#00CD2D" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_105_1432"
          x1="0.227553"
          y1="42.3514"
          x2="6.18503"
          y2="101.613"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00CD2D" />
          <stop offset="1" stopColor="#00CD2D" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default SuccessfulIcon;
