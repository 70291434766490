 
import
    {
        UPDATE_PROFILE,
        UPDATE_PROFILE_FAIL,
        UPDATE_PROFILE_SUCCESS,
        GET_PROFILE,
        GET_PROFILE_SUCCESS,
        UPDATE_PASSWORD,
        UPDATE_PASSWORD_FAIL,
        UPDATE_PASSWORD_SUCCESS,
        CHANGE_PASSWORD,
        CHANGE_PASSWORD_FAIL,
        CHANGE_PASSWORD_SUCCESS
    } from './actionTypes';
     

export const getProfile = (token) => ({
    type: GET_PROFILE,
    payload: {token}
})

export const getProfileSuccess = data => ({
    type: GET_PROFILE_SUCCESS,
    payload: {data}
})

export const updatePROFILE = (token, firstName, lastName, phoneNo) => ({
    type: UPDATE_PROFILE,
    payload: {token, firstName, lastName, phoneNo}
})

export const updateProfileSuccess = message => ({
    type: UPDATE_PROFILE_SUCCESS,
    payload: {message}
    
})

export const updateProfileFail = message => ({
    type: UPDATE_PROFILE_FAIL,
    payload: {message}
})

export const updatePassword = (token, password, confirmPassword) => ({
    type: UPDATE_PASSWORD,
    payload: {token, password, confirmPassword}
})

export const updatePasswordSuccess = message => ({
    type: UPDATE_PASSWORD_SUCCESS,
    payload: {message}
})

export const updatePasswordFail = error => ({
    type: UPDATE_PASSWORD_FAIL,
    payload: {error}
})

export const changePassword = (token, password, newPassword, confirmPassword) => ({
    type: CHANGE_PASSWORD,
    payload: {token, password, newPassword, confirmPassword}
})

export const changePasswordSuccess = (message) => ({
    type: CHANGE_PASSWORD_SUCCESS,
    payload: {message}
})

export const changePasswordFail = error => ({
    type: CHANGE_PASSWORD_FAIL,
    payload : {error}
})