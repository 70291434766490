import { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import "../../../components/StudentPages/StudentHomePage/components/Home/assets/css/feature.css";
import {
  getAllCategoriesForALl,
  getCourseOfAcademicArea,
  getDataOnSearch,
  searchQuery,
} from "../../../store/actions";
import MainFooter from "../Common/footer/MainFooter";
import Header from "../Common/Header";
import LoginHeader from "./../Common/LoginHeader";
import "./category.scss";
import CategoryAllDataList from "./CategoryAllDataList";
const StudentCategoryHome = () => {
  const { academicId } = useParams();
  let dispatch = useDispatch();
  let history = useHistory();

  const { token, courses, categoris, searchText, searchedCourse, loading } =
    useSelector((state) => ({
      token: state.Login.token,
      courses: state.academicAreaReducer.academicCourses,
      categoris: state.academicAreaReducer.categoris,
      searchText: state.courseReducer.searchQuery,
      searchedCourse: state.academicAreaReducer.courses,
      loading: state.academicAreaReducer.loadign,
    }));

  const handleSearchByText = (e) => {
    e.preventDefault();

    console.log("hello target data here", e.target.value);
    if (!e.target.value) {
      dispatch(searchQuery(""));
    } else {
      dispatch(searchQuery(e.target.value));
    }
  };
  useEffect(() => {
    dispatch(getCourseOfAcademicArea(academicId));
    dispatch(getAllCategoriesForALl());
  }, []);

  useEffect(() => {
    const delay = setTimeout(() => {
      dispatch(getDataOnSearch(searchText));
    }, 400);
    return () => clearTimeout(delay);
  }, [searchText]);

  return (
    <div className="StudentCategoryHome-page">
      <MetaTags>
        <title>Category | GameProject</title>
      </MetaTags>
      {token ? <LoginHeader /> : <Header />}
      <CategoryAllDataList
        courses={searchText ? searchedCourse : courses}
        categoris={categoris}
        academicId={academicId}
        loading={loading}
        handleSearchByText={handleSearchByText}
        searchedCourse={searchedCourse}
      />
      <MainFooter />
    </div>
  );
};

export default StudentCategoryHome;
