import React, { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { getAcademicArea, getStudetnOwnCourse } from "../../../store/actions";
import MainFooter from "../Common/footer/MainFooter";
import LoginHeader from "../Common/LoginHeader";
import StudentProfileDetails from "./StudentProfileDetails";
import "./studentProfileView.scss";

const StudentProfileView = () => {
  const { token, courses, completedCourse, academicAreas, role } = useSelector(
    (state) => ({
      token: state.Login.token,
      role: state.Login.userrole,
      courses: state.courseReducer.ownCourse,
      completedCourse: state.courseReducer.completedCourse,
      academicAreas: state.AdminAuthorReducer.academicAreas,
    })
  );
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStudetnOwnCourse(token));
    dispatch(getAcademicArea());
  }, []);

  return (
    <div>
      <MetaTags>
        <title>Profile | GameProject</title>
      </MetaTags>
      <LoginHeader />
      <StudentProfileDetails
        courses={courses}
        completedCourse={completedCourse}
        academicAreas={academicAreas}
      />
      {/* <StudentProfileViewNew /> */}
      <MainFooter />
    </div>
  );
};

export default StudentProfileView;
