import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
const AnnualBody = () => {
  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>New Package</h3>
            <p>
              Course > <span>Category List</span>{" "}
            </p>
          </div>
        </div>
        <div className="packge-header-right">
          <Link to="/create-new-package">
            <span>+ </span>
            Create New
          </Link>
        </div>
      </div>
      <div className="new-individual-course">
        <div className="new-individual-course-inner-wrap">
          <div className="new-individual-course-title">
            <h4>Create New Package</h4>
          </div>
          <Form>
            <Row>
              <Col lg={12}>
                <div className="input-data-single-field">
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type category name"
                      name="categoryName"
                    />
                  </Form.Group>
                </div>
              </Col>{" "}
              <Col lg={6}>
                <div className="input-data-single-field">
                  <Form.Group className="mb-3">
                    <Form.Label>Course Category</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type category name"
                      name="category"
                    />
                  </Form.Group>
                </div>
              </Col>{" "}
              <Col lg={6}>
                <div className="input-data-single-field">
                  <Form.Group className="mb-3">
                    <Form.Label>Course Academic Area</Form.Label>
                    <Form.Select>
                      <option>dhaka</option>
                      <option>khulna</option>
                      <option>Chandpur</option>
                    </Form.Select>
                  </Form.Group>
                </div>
              </Col>
              <Col lg={6}>
                <div className="input-data-single-field">
                  <Form.Group className="mb-3">
                    <Form.Label>Duration </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type category name"
                    />
                  </Form.Group>
                </div>
              </Col>
              <Col lg={6}>
                <div className="input-data-single-field">
                  <Form.Group className="mb-3">
                    <Form.Label>Course Type </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Individual Package"
                    />
                  </Form.Group>
                </div>
              </Col>
              <Col lg={6}>
                <div className="input-data-single-field">
                  <Form.Group className="mb-3">
                    <Form.Label>Course Type </Form.Label>
                    <Form.Control type="text" placeholder="Paid" />
                  </Form.Group>
                </div>
              </Col>
              <Col lg={6}>
                <div className="input-data-single-field">
                  <Form.Group className="mb-3">
                    <Form.Label>Course Price</Form.Label>
                    <Form.Control type="number" placeholder="$5.00" />
                  </Form.Group>
                </div>
              </Col>
              <Col lg={6}>
                <div className="input-data-single-field">
                  <Form.Group className="mb-3">
                    <Form.Label>Course Language</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type category name"
                    />
                  </Form.Group>
                </div>
              </Col>{" "}
            </Row>
            <div className="form-submit-button-area-wrap">
              <button type="submit" className="btn create-new-item">
                Create New
              </button>
              <button type="submit" className="btn save-to-new-item-draft">
                Save as Draft
              </button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AnnualBody;
