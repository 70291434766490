import { all, fork } from "redux-saga/effects";
import AdminAuthorSaga from "./AdminRelated/author/saga";
import AdminDashboardSaga from "./AdminRelated/dashboard/saga";
import forgetPasswordSaga from "./ForgetPassword/saga";
import lafargeSaga from "./Lafarge/saga";
import StudentGameSaga from "./RetailGame/Student/saga";
import academicAreaSaga from "./Users/academicArea/saga";
import CourseSaga from "./Users/courses/saga";
import updatePasswordSaga from "./Users/updateAccount/saga";
import comparativeAnalysisSaga from "./comparativeAnalysis/saga";
import LoginSaga from "./login/saga";
import recruitmentSaga from "./recruitment/saga";
import registrationSaga from "./registration/saga";
import RetailGeneralSaga from "./RetailGame/Course/saga";

export default function* rootSaga() {
  yield all([fork(recruitmentSaga)]);
  yield all([fork(comparativeAnalysisSaga)]);
  yield all([fork(LoginSaga)]);
  yield all([fork(AdminDashboardSaga)]);
  yield all([fork(AdminAuthorSaga)]);
  yield all([fork(registrationSaga)]);
  yield all([fork(CourseSaga)]);
  yield all([fork(academicAreaSaga)]);
  yield all([fork(forgetPasswordSaga)]);
  yield all([fork(updatePasswordSaga)]);
  yield all([fork(lafargeSaga)]);
  yield all([fork(StudentGameSaga)]);
  yield all([fork(RetailGeneralSaga)]);
}
