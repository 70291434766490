import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import McqQuiz2 from "../../components/Retail/McqQuiz2";
import "./retail.scss";
import { useEffect } from "react";
import { getRetailSecondGame } from "../../store/RetailGame/Course/actions";
import { Spinner } from "react-bootstrap";
import { getStudentQues } from "../../store/RetailGame/Student/actions";
import Loader from "../../components/Common/Loader";
const RetailGame2 = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { id, courseName } = useParams();

  const {
    token,
    studentQues,
    mcqQuesNum,
    currentIndex,
    typeArray,
    currentType,
    studentQuesLoading,
    secondGameData,
    secondGameLoading,
    gameType
  } = useSelector((state) => ({
    token: state.Login.token,
    studentQues: state.studentGame.studentQues,
    mcqQuesNum: state.studentGame.mcqQuesNum,
    currentIndex: state.studentGame.currentIndex,
    typeArray: state.studentGame.typeArray,
    currentType: state.studentGame.currentType,
    studentQuesLoading: state.studentGame.studentQuesLoading,
    secondGameData:  state?.retail?.secondGameData,
    secondGameLoading: state?.retail?.secondGameLoading,
    gameType: state?.retail?.gameType,
    
  }));
  console.log("gameType", gameType);
  console.log('studentQues', studentQues);
  useEffect(() => {
    if (gameType === "") {
      history.push(`/game-two-information/${id}/${courseName}`);
    }
  }, [history, gameType]);
  useEffect(() => {
    console.log("565id", id);
    if (id && gameType) {
      dispatch(
        getStudentQues(
          token,
          id,
          // data?.questionNumberAndSerial?.[0]?.examType
          gameType
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log("secondGameLoading", secondGameLoading);
  return (
    <>
    {
      studentQuesLoading ? <Loader/> : <McqQuiz2 studentQues={studentQues} />
    }
      
    </>
  );
};

export default RetailGame2;
