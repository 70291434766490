import React from "react";

const UnitsRevenue = ({ prodName, monthSerial }) => {
  console.log("hello prod Name from another", prodName, monthSerial);
  return (
    <div>
      <div className="unit-sold">
        <div>
          <div className="row">
            <div className="col-md-4">
              <div className="unit-sold-price">
                <p>Unit Sold</p>
              </div>
            </div>
            <div className="col-md-8">
              <div className="radio-items-wrap">
                <div className="radio-input-single-item">
                  <input type="radio" id="sold1" name="sold" />
                  <label for="sold1">0</label>
                </div>
                <div className="radio-input-single-item">
                  <input type="radio" id="sold2" name="sold" /> {" "}
                  <label for="sold2">10</label>
                </div>
                <div className="radio-input-single-item">
                  <input type="radio" id="sold3" name="sold" />
                  <label for="sold3">20</label>
                </div>
                <div className="radio-input-single-item">
                  <input type="radio" id="sold4" name="sold" />{" "}
                  <label for="sold4">25</label>
                </div>
                <div className="radio-input-single-item">
                  <input type="radio" id="sold5" name="sold" />
                  <label fro="sold5">30</label>
                </div>
                <div className="radio-input-single-item">
                  <input type="radio" id="sold6" name="sold" />
                  <label for="sold6">35</label>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="revenue-sold">
        <div>
          <div className="row">
            <div className="col-md-4">
              <div className="unit-sold-price">
                <p>Unit Sold</p>
              </div>
            </div>
            <div className="col-md-8">
              <div className="unit-sold-price">
                <div>
                  <h6>0</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitsRevenue;
