import {
  GET_LAFARGE_COURSE,
  GET_LAFARGE_COURSE_SUCCESS,
  GET_STUDENT_ANSWER,
  GET_STUDENT_ANSWER_SUCCESS,
  GET_TOTAL_USER,
  GET_TOTAL_USER_SUCCESS,
  GET_USER,
  GET_USER_SUCCESS,
  STORE_TOTAL_MARKS_DATA,
  UPDATE_TOTAL_MARKS,
  UPDATE_TOTAL_MARKS_FAIL,
  UPDATE_TOTAL_MARKS_SUCCESS,
} from "./actionTypes";

const initialState = {
  lafargeCourseData: [],
  lafargeUserData: [],
  lafargeTotalUserData: {},
  lafargeStudentAnswerData: [],
  editTotalMarksInfo: [],

  loading: false,
  message: "",
  error: "",
};

const lafargeReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LAFARGE_COURSE:
      state = { ...state, loading: true };
      break;

    case GET_LAFARGE_COURSE_SUCCESS:
      state = {
        ...state,
        loading: false,
        lafargeCourseData: action.payload.data,
      };
      break;

    case GET_USER:
      state = { ...state, loading: true };
      break;

    case GET_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        lafargeUserData: action.payload.data,
      };
      break;

    case GET_TOTAL_USER:
      state = { ...state, loading: true };
      break;

    case GET_TOTAL_USER_SUCCESS:
      state = {
        ...state,
        loading: false,
        lafargeTotalUserData: action.payload.data,
      };
      break;

    case GET_STUDENT_ANSWER:
      state = { ...state, loading: true };
      break;

    case GET_STUDENT_ANSWER_SUCCESS:
      state = {
        ...state,
        loading: false,
        lafargeStudentAnswerData: action.payload.data,
      };
      break;

    case UPDATE_TOTAL_MARKS:
      state = {
        ...state,
        loading: true,
      };
      break;

    case UPDATE_TOTAL_MARKS_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: action.payload.message,
      };
      break;
    case UPDATE_TOTAL_MARKS_FAIL:
      state = {
        ...state,
        loading: false,
        error: action.payload.error,
      };
      break;

    case STORE_TOTAL_MARKS_DATA:
      return {
        ...state,
        editTotalMarksInfo: {
          ...state.editTotalMarksInfo,
          [action.payload.id]: action.payload,
        },
      };

    default:
      state = state;
      break;
  }
  return state;
};

export default lafargeReducer;
