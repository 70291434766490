import React, { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import "../../../components/StudentPages/StudentHomePage/components/Home/assets/css/feature.css";
import { getAllCourses } from "../../../store/actions";
import MainFooter from "../Common/footer/MainFooter";
import Header from "../Common/Header";
import LoginHeader from "../Common/LoginHeader";
import "./category.scss";
import CategoryAllDataList from "./CategoryAllDataList";
const CourseHome = () => {
  const { academicId } = useParams();
  let dispatch = useDispatch();
  let history = useHistory();

  const { token, courses } = useSelector((state) => ({
    token: state.Login.token,
    courses: state.courseReducer.allCourses,
  }));

  useEffect(() => {
    dispatch(getAllCourses());
  }, []);

  return (
    <div>
      <MetaTags>
        <title>Courses | GameProject</title>
      </MetaTags>
      {token ? <LoginHeader /> : <Header />}
      <CategoryAllDataList courses={courses} />
      <MainFooter />
    </div>
  );
};

export default CourseHome;
