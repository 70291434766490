import { useState } from "react";
import { NavLink } from "react-router-dom";
import dashboardIcon2 from "../img/book.svg";
import dashboardIcon from "../img/dashboard.svg";
import Game from "../img/game.svg";
import dashboardIconRank from "../img/gps.svg";
import dashboardIcon7 from "../img/open-book.svg";
// import dashboardIcon6 from "../img/overview.svg";
import dashboardIcon4 from "../img/people.svg";
import dashboardIcon5 from "../img/profile-circle.svg";
import dashboardIconSettings from "../img/setting.svg";
import dashboardIcon8 from "../img/task-square.svg";
import dashboardIconCheck from "../img/tick-square.svg";
const DashboardCommonAllLink = ({ course }) => {
  const [dropdown, setDropdown] = useState(false);
  return (
    <>
      <ul>
        <li>
          <NavLink to="/admindashboard" activeClassName="admin-navlink">
            <span>
              <img src={dashboardIcon} alt="" />
            </span>
            <span>Dashboard</span>
          </NavLink>
        </li>{" "}
        {/* <li>
          <NavLink to="/overview" activeClassName="admin-navlink">
            <span>
              <img src={dashboardIcon6} alt="" />
            </span>
            <span>Overview</span>
          </NavLink>
        </li>{" "} */}
        <li>
          <NavLink to="/students-list" activeClassName="admin-navlink">
            <span>
              <img src={dashboardIcon4} alt="" />
            </span>
            <span>Students List</span>
          </NavLink>
        </li>{" "}
        <li>
          <NavLink to="/teacher-list" activeClassName="admin-navlink">
            <span>
              <img src={dashboardIcon5} alt="" />
            </span>
            <span>Teacher List</span>
          </NavLink>
        </li>{" "}
        <li
          onClick={() => {
            setDropdown(!dropdown);
          }}
        >
          <div className="dashboard-dropdown-menuarea">
            {dropdown ? (
              <div className="icon_active">
                <span className="menu-expand">
                  <i className="fa fa-angle-up"></i>
                </span>
              </div>
            ) : (
              <div className="icon_reverse">
                <span className="menu-expand">
                  <i className="fa fa-angle-down"></i>
                </span>
              </div>
            )}
            <span>
              {" "}
              <span>
                <img src={dashboardIcon2} alt="" />
              </span>
              <span>Course List</span>
            </span>
            <ul className={dropdown ? "sub-menu" : "active_subMenu"}>
              <>
                <NavLink to="/academic-area" activeClassName="admin-navlink">
                  <i className="bx bx-chevron-right"></i>Academic Area
                </NavLink>{" "}
                <NavLink
                  to="/dashboard-author-area"
                  activeClassName="admin-navlink"
                >
                  <i className="bx bx-chevron-right"></i>Author
                </NavLink>{" "}
                <NavLink to="/format-list" activeClassName="admin-navlink">
                  <i className="bx bx-chevron-right"></i>Format
                </NavLink>{" "}
                <NavLink to="/educator-copy" activeClassName="admin-navlink">
                  <i className="bx bx-chevron-right"></i>Educator Copy
                </NavLink>{" "}
                <NavLink to="/quiz" activeClassName="admin-navlink">
                  <i className="bx bx-chevron-right"></i>Quiz
                </NavLink>{" "}
                <NavLink to="/coupon" activeClassName="admin-navlink">
                  <i className="bx bx-chevron-right"></i>Coupon
                </NavLink>{" "}
                {/* <NavLink to="/assesment" activeClassName="admin-navlink">
                    <i className="bx bx-chevron-right"></i>Assesment
                  </NavLink>{" "} */}
                <NavLink to="/course" activeClassName="admin-navlink">
                  <i className="bx bx-chevron-right"></i>Course
                </NavLink>
              </>
            </ul>
          </div>

          {/* <span>
            <Dropdown className="jnjvlzkdmxcmnb">
              <Dropdown.Toggle
                id="dropdown-basic"
                className="dropdown-toggle-admin-page"
              >
                <span>Course List</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <NavLink to="/academic-area" activeClassName="admin-navlink">
                  <i className="bx bx-chevron-right"></i>Academic Area
                </NavLink>{" "}
                <NavLink
                  to="/dashboard-author-area"
                  activeClassName="admin-navlink"
                >
                  <i className="bx bx-chevron-right"></i>Author
                </NavLink>{" "}
                <NavLink to="/format-list" activeClassName="admin-navlink">
                  <i className="bx bx-chevron-right"></i>Format
                </NavLink>{" "}
                <NavLink to="/educator-copy" activeClassName="admin-navlink">
                  <i className="bx bx-chevron-right"></i>Educator Copy
                </NavLink>{" "}
                <NavLink to="/quiz" activeClassName="admin-navlink">
                  <i className="bx bx-chevron-right"></i>Quiz
                </NavLink>{" "}
                <NavLink to="/assesment" activeClassName="admin-navlink">
                  <i className="bx bx-chevron-right"></i>Assesment
                </NavLink>{" "}
                <NavLink to="/course" activeClassName="admin-navlink">
                  <i className="bx bx-chevron-right"></i>Course
                </NavLink>
              </Dropdown.Menu>
            </Dropdown>
          </span> */}
        </li>{" "}
        <li>
          <NavLink to="/admin-lafarge-games" activeClassName="admin-navlink">
            <span>
              <img src={Game} alt="" />
            </span>
            <span>Game</span>
          </NavLink>
        </li>{" "}
        <li>
          <NavLink to="/course-frame" activeClassName="admin-navlink">
            <span>
              <img src={dashboardIcon7} alt="" />
            </span>
            <span>Course Frame</span>
          </NavLink>
        </li>{" "}
        <li>
          <NavLink to="/courseware" activeClassName="admin-navlink">
            <span>
              <img src={dashboardIcon4} alt="" />
            </span>
            <span>Courseware</span>
          </NavLink>
        </li>{" "}
        <li>
          <NavLink to="/package-list" activeClassName="admin-navlink">
            <span>
              <img src={dashboardIcon8} alt="" />
            </span>
            <span>Package</span>
          </NavLink>
        </li>{" "}
        {/* <li>
          <NavLink to="/certificate" activeClassName="admin-navlink">
            <span>
              <img src={dashboardIcon5} alt="" />
            </span>
            <span>Certificate</span>
          </NavLink>
        </li>{" "} */}
        <li>
          <NavLink to="/rank" activeClassName="admin-navlink">
            <span>
              <img src={dashboardIconRank} alt="" />
            </span>
            <span>Rank</span>
          </NavLink>
        </li>{" "}
        <li>
          <NavLink to="/settings-s" activeClassName="admin-navlink">
            <span>
              <img src={dashboardIconSettings} alt="" />
            </span>
            <span>Settings</span>
          </NavLink>
        </li>{" "}
        <li>
          <NavLink to="/attendence" activeClassName="admin-navlink">
            <span>
              <img src={dashboardIconCheck} alt="" />
            </span>
            <span>Attendence</span>
          </NavLink>
        </li>{" "}
        <li>
          <NavLink to="/total-orders" activeClassName="admin-navlink">
            <span>
              <img src={dashboardIconCheck} alt="" />
            </span>
            <span>Orders List</span>
          </NavLink>
        </li>
        <li
          onClick={() => {
            setDropdown(!dropdown);
          }}
        >
          <div className="dashboard-dropdown-menuarea">
            {dropdown ? (
              <div className="icon_active">
                <span className="menu-expand">
                  <i className="fa fa-angle-up"></i>
                </span>
              </div>
            ) : (
              <div className="icon_reverse">
                <span className="menu-expand">
                  <i className="fa fa-angle-down"></i>
                </span>
              </div>
            )}
            <span>
              {" "}
              <span>
                <img src={dashboardIcon2} alt="" />
              </span>
              <span>Retail Game</span>
            </span>
            <ul className={dropdown ? "sub-menu" : "active_subMenu"}>
              <>
                <NavLink to="/game-info" activeClassName="admin-navlink">
                  <i className="bx bx-chevron-right"></i>Course
                </NavLink>{" "}
                <NavLink to="/assign-user" activeClassName="admin-navlink">
                  <i className="bx bx-chevron-right"></i>Assign User
                </NavLink>{" "}
              
              </>
            </ul>
          </div>

          {/* <span>
            <Dropdown className="jnjvlzkdmxcmnb">
              <Dropdown.Toggle
                id="dropdown-basic"
                className="dropdown-toggle-admin-page"
              >
                <span>Course List</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <NavLink to="/academic-area" activeClassName="admin-navlink">
                  <i className="bx bx-chevron-right"></i>Academic Area
                </NavLink>{" "}
                <NavLink
                  to="/dashboard-author-area"
                  activeClassName="admin-navlink"
                >
                  <i className="bx bx-chevron-right"></i>Author
                </NavLink>{" "}
                <NavLink to="/format-list" activeClassName="admin-navlink">
                  <i className="bx bx-chevron-right"></i>Format
                </NavLink>{" "}
                <NavLink to="/educator-copy" activeClassName="admin-navlink">
                  <i className="bx bx-chevron-right"></i>Educator Copy
                </NavLink>{" "}
                <NavLink to="/quiz" activeClassName="admin-navlink">
                  <i className="bx bx-chevron-right"></i>Quiz
                </NavLink>{" "}
                <NavLink to="/assesment" activeClassName="admin-navlink">
                  <i className="bx bx-chevron-right"></i>Assesment
                </NavLink>{" "}
                <NavLink to="/course" activeClassName="admin-navlink">
                  <i className="bx bx-chevron-right"></i>Course
                </NavLink>
              </Dropdown.Menu>
            </Dropdown>
          </span> */}
        </li>{" "}
        
      </ul>
    </>
  );
};

export default DashboardCommonAllLink;
