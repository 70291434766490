import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import authorImage from "./img/author.png";
import courseIcon3 from "./img/document-text.svg";
import recorderIcon from "./img/monitor-recorder.svg";
import saveIcon from "./img/saveCourse.svg";
import courseIcon1 from "./img/scan.svg";
import shareIcon from "./img/share.svg";
import shopingBag from "./img/shopping-bag.svg";
import courseIcon2 from "./img/translate.svg";
const CourseDetails = () => {
  let history = useHistory();

  return (
    <div>
      <Container>
        <div className="details-page-inner-main-wrap">
          <Link onClick={() => history.goBack()} className="back-btn">
            <span>
              <i className="bx bx-arrow-back"></i>
            </span>
            <span>Back</span>
          </Link>
          <p>
            <i>Home {`> `}Introduction to Financial Restructuring</i>
          </p>
          <Row>
            <Col lg={9}>
              <div className="course-details-content-main-area">
                <h2>Business Decision Making</h2>
                <div className="course-share-btn-area">
                  <Link>
                    <span>
                      <img src={saveIcon} alt="" />
                    </span>
                    <span>Save</span>
                  </Link>
                  <Link>
                    <span>
                      <img src={shareIcon} alt="" />
                    </span>
                    <span>Share</span>
                  </Link>{" "}
                </div>
                <Row>
                  <Col lg={4}>
                    <div className="course-details-single-box-item-wrap">
                      <i>IE CODE:</i>
                      <h4>
                        <img src={courseIcon1} alt="" />
                        <span>DF2-264-I</span>
                      </h4>
                    </div>
                  </Col>{" "}
                  <Col lg={4}>
                    <div className="course-details-single-box-item-wrap">
                      <i>LANGUAGE:</i>
                      <h4>
                        <img src={courseIcon2} alt="" />
                        <span>English</span>
                      </h4>
                    </div>
                  </Col>{" "}
                  <Col lg={4}>
                    <div className="course-details-single-box-item-wrap">
                      <i>FORMAT:</i>
                      <h4>
                        <img src={courseIcon3} alt="" />
                        <span>PDF</span>
                      </h4>
                    </div>
                  </Col>{" "}
                  <Col lg={4}>
                    <div className="course-details-single-box-item-wrap">
                      <i>ACADEMIC AREA:</i>
                      <h4>
                        <span>Finance</span>
                      </h4>
                    </div>
                  </Col>{" "}
                  <Col lg={4}>
                    <div className="course-details-single-box-item-wrap">
                      <i>NR. OF PAGES:</i>
                      <h4>
                        <img src={courseIcon1} alt="" />
                        <span>20</span>
                      </h4>
                    </div>
                  </Col>{" "}
                  <Col lg={4}>
                    <div className="course-details-single-box-item-wrap">
                      <i>TYPE OF PUBLICATION:</i>
                      <h4>
                        <img src={courseIcon1} alt="" />
                        <span>Case Study</span>
                      </h4>
                    </div>
                  </Col>
                </Row>
                <div className="course-more-btn-area-see-more-btn">
                  <button> Play</button>
                </div>
                <div className="content-decription-coursware">
                  <i>DESCRIPTION</i>
                  <p>
                    This technical note explains what a financial restructuring
                    of a company involves, differentiating it from other types
                    of transactions which share similarities, such as
                    operational restructurings or debt refinancings. It also
                    explains the main financial tools generally used in
                    financial restructuring deals, their advantages and main
                    implementation principles.
                    <br />
                    <br />
                    At the end of the note, the author includes a practical
                    example of financial restructuring in a food processing
                    company.
                  </p>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <Card className="card-main-area-course-page-main-card">
                      <Card.Img variant="left" src={recorderIcon} />
                      <Card.Body>
                        <Card.Title>
                          <h3>Educator Copy</h3>
                        </Card.Title>
                        <Card.Text>
                          <i>Access a full preview copy of the article.</i>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <aside className="course-author-main-area-kmfskm">
                <div className="course-price-wrap-jdkbad">
                  <i>Price Per Unit:</i>
                  <h1>$5.00</h1>
                </div>
                <div className="buy-course-main-wrap">
                  <img src={shopingBag} alt="" />
                  <button disabled>Add to Cart</button>
                </div>
                <div className="course-author-main-area-wrap-jbd">
                  <i>THE AUTHORS</i>
                  <hr />
                  <img src={authorImage} alt="" />
                  <h5>DANIEL PAREDERO DEL BOSQUE</h5>
                  <i>ACADEMIC AREA:</i>
                  <h6>Finance</h6>
                  <div className="buy-course-main-wrap fdgf">
                    <button>Read More</button>
                  </div>
                </div>
                <div className="course-author-main-area-wrap-jbd gsfgf">
                  <i>ADDITIONAL INFO</i>
                  <hr />

                  <div className="additional-info-main-area-hbgjfkdl">
                    <div className="gfdbvghnhjn">
                      <p>
                        <b>LAST UPDATED:</b>
                      </p>
                      <i>February 5, 2021</i>
                      <hr />
                    </div>
                    <div className="gfdbvghnhjn">
                      <p>
                        <b>LAST UPDATED:</b>
                      </p>
                      <i>February 5, 2021</i>
                      <hr />
                    </div>{" "}
                    <div className="gfdbvghnhjn">
                      <p>
                        <b>SUBJECTS COVERED:</b>
                      </p>
                      <p>
                        Financial restructuring; restructuring; refinancing;
                        debt for equity swap; debt capitalisation; debt for
                        asset swap; deleverage; sustainable debt; amend and
                        extend; amend and pretend; PIK interest; PIYC interest;
                        profit sharing agreement; profit participa
                      </p>
                    </div>
                  </div>
                </div>
              </aside>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default CourseDetails;
