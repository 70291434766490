const mcq = [
	{
		question:
			"What is the most relevant word related to a win-win negotiation",
		option: ["Power", "Influence"],
		answer: "Power",
		number: 1,
		type: "mcq",
	},
	{
		question: "Negotiation is a conversation leading to",
		option: ["agreement", "argument"],
		answer: "agreement",
		number: 2,
		type: "mcq",
	},
	{
		question: "Integrative negotiation is driven by",
		option: ["Conflict", "Collaboration"],
		answer: "Collaboration",
		number: 3,
		type: "mcq",
	},
	{
		question: "Synergy means",
		option: ["1+1 = 0 or less", "1+1 = 3 or more"],
		answer: "1+1 = 3 or more",
		number: 4,
		type: "mcq",
	},
	{
		question: "What will be the basis for negotiation",
		option: ["Position", "Interest"],
		answer: "Interest",
		number: 5,
		type: "mcq",
	},
	{
		question: "When a proposal is better than BATNA",
		option: ["Accept it", "Reject it"],
		answer: "Accept it",
		number: 6,
		type: "mcq",
	},
	{
		question: "Frame Your opening request as a",
		option: ["Benefit", "Conflict"],
		answer: "Benefit",
		number: 7,
		type: "mcq",
	},
	{
		question:
			"Research shows that 85% of negotiated outcomes align with the person who anchors",
		option: ["First", "Second"],
		answer: "First",
		number: 8,
		type: "mcq",
	},
	{
		question:
			"Research shows that 85% of negotiated outcomes align with the person who anchors",
		option: ["First", "Second"],
		answer: "First",
		number: 8,
		type: "mcq",
	},
	{
		question:
			"In strategies of influence, what needs to be highlghted more",
		option: ["Potential losses", "Potential gains"],
		answer: "Potential losses",
		number: 9,
		type: "mcq",
	},
	{
		question: "Identify the non-price items from the following",
		option: ["Product", "Commission"],
		answer: "Product",
		number: 10,
		type: "mcq",
	},
];

const shortQuestion = [
	{
		question: "Your name",
		answer: "ABC",
		number: 9,
		type: "short",
	},
	{
		question: "Country's name",
		answer: "Bangladesh",
		number: 10,
		type: "short",
	},
	{
		question: "Captial's name",
		answer: "Dhaka",
		number: 11,
		type: "short",
	},
	{
		question: "best food",
		answer: "dal vat",
		number: 12,
		type: "short",
	},
];

const fillGaps = [
	{
		question: "Negotiation is a $gap$ Event",
		answer: "Planned",
		number: 13,
		type: "fillGap",
	},
	{
		question: "Paradigm shift means finding the $gap$ alternative",
		answer: "3rd",
		number: 14,
		type: "fillGap",
	},
	{
		question: "BATNA stands for BEST $gap$ to a negatiated agreement",
		answer: "alternative",
		number: 15,
		type: "fillGap",
	},
	{
		question: "Reservation value is also known as walk $gap$",
		answer: "away",
		number: 16,
		type: "fillGap",
	},
	{
		question: "ZOPA stands for Zone of possible $gap$",
		answer: "agreement",
		number: 17,
		type: "fillGap",
	},
];

const situation = [
	{
		question:
			"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
		answer: "",
		number: 18,
		type: "situation",
	},
	{
		question:
			"It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
		answer: "",
		number: 19,
		type: "situation",
	},
	{
		question:
			"It was popularised in the 1960s with the release of constraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
		answer: "",
		number: 20,
		type: "situation",
	},
	{
		question:
			"It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
		answer: "",
		number: 21,
		type: "situation",
	},
	{
		question:
			"The point of using Lorem Ipsum is that it has a more-or-less normal distribution of constters, as opposed to using 'Content here, content here',",
		answer: "",
		number: 22,
		type: "situation",
	},
	{
		question:
			"it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text,",
		answer: "",
		number: 23,
		type: "situation",
	},
];

const situationQuestion = [
	{
		question: `Tahmid vai is the ASM of Mirpur area. After nationwide poor credit term
    performance and distributor’s repeated complain, management has decided
    that management has decided to increase the credit limit to maximum 45
    days after that a penalty will incur. Management also said that who
    Mamun vai, owner of Rahman traders is repeatedly getting pressure from his
    retailers   to   increase   credit   terms.   Since   he   only   gets   30   days   from
    LafargeHolcim he cannot allow the retailers more than that. He wants ASM to
    increase the time to 1 more week
    Write out the reservation value for each party and what can be the possible
    ZOPA.`,
		answer: "",
		type: "situation",
	},
	{
		question: `Ahmed traders is a govt contractor who provides construction material to
    different projects. Recently, they fired their long-term cement provider over
    consistency issue. In your first interaction with their owner he expressed their
    deep concern over consistency and meeting the deadline on delivery.
    You, as a corporate sales KAM now need to frame your company to the
    contractor in such a way that he perceives that doing business with you is
    beneficial for you.
    Write down using the framing techniques of Negotiation workshop`,
		answer: "",
		type: "situation",
	},
];

export { mcq, shortQuestion, situation, fillGaps, situationQuestion };
