import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { createCoupon } from "../../../../store/actions";
const CouponNewBody = ({}) => {
  let history = useHistory();
  let dispatch = useDispatch();

  let [error, setError] = useState("");
  let [dat, newDat] = useState(new Date());
  let [couponCode, setCouponCode] = useState("");
  let [couponPercentage, setCouponPercentage] = useState("");
  let [startingDate, setStartingDate] = useState(new Date());
  let [endingDate, setEndingDate] = useState(new Date());
  const { token, loading } = useSelector((state) => ({
    token: state.Login.token,
    loading: state.AdminAuthorReducer.loading,
  }));

  const handleSubmitData = (e) => {
    e.preventDefault();
    if (!couponCode) {
      setError("Coupon code is not valid");
    } else if (!couponPercentage) {
      setError(...error, "Coupon percentage is not valid");
    } else if (!startingDate) {
      setError(...error, "Coupon Starting Date  is not valid");
    } else if (!endingDate) {
      setError(...error, "Coupon Ending Date is not valid");
    } else {
      // console.log("hello data coupon", couponCode, couponPercentage, startingDate, endingDate);
      dispatch(
        createCoupon(
          token,
          couponCode,
          couponPercentage,
          startingDate,
          endingDate,
          history
        )
      );
    }

    // dispatch(createCoupon(token, academicName, categoryArr, history));
  };

  //store category

  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>New Copon</h3>
            <p>
              Course list <i className="bx bx-chevron-right"></i> academic area{" "}
              <i className="bx bx-chevron-right"></i>
              <span> Create New Academic Area</span>{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="package-main-content-list kjhgfwertyuiutfc">
        <div className="add-new-format-area-wrap">
          <div className="add-new-format-inner-item-wrap">
            <h4>New Coupon Area</h4>
            <Form className="format-upload-form-main-area-zkjdh">
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Coupon Code</Form.Label>
                <Form.Control
                  type="Text"
                  placeholder="Coupon Code"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                />
              </Form.Group>{" "}
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Coupon Percentage</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Coupon Percentage"
                  value={couponPercentage}
                  onChange={(e) => setCouponPercentage(e.target.value)}
                />
              </Form.Group>{" "}
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Starting date </Form.Label>

                <Form.Control
                  type="date"
                  placeholder="Starting Date"
                  value={startingDate}
                  min={new Date()}
                  onChange={(e) => setStartingDate(e.target.value)}
                />
              </Form.Group>{" "}
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Ending date </Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Tag name"
                  value={endingDate}
                  onChange={(e) => setEndingDate(e.target.value)}
                />
              </Form.Group>
              {error ? (
                <Row>
                  <Col sm={12}>
                    <div className="error-main-area-hdfskjdfbschdb">
                      <p>
                        <i className="bx bxs-error-alt"></i> {error}
                      </p>
                    </div>
                  </Col>{" "}
                </Row>
              ) : (
                ""
              )}
              <div className="">
                {loading ? (
                  <Spinner />
                ) : (
                  <div className="create-new-format-btn-area-wrap">
                    <button onClick={(e) => handleSubmitData(e)}>
                      Create New Coupon
                    </button>
                    <button>Cancel</button>
                  </div>
                )}
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponNewBody;
