import React from "react";
import MainFooter from "../Common/footer/MainFooter";
import LoginHeader from "../Common/LoginHeader";
import AuthorOverviewData from "./AuthorOverviewData";
import "./studentProfileView.scss";
const AuthorOverview = () => {
  return (
    <div>
      <LoginHeader />
      <AuthorOverviewData />
      <MainFooter />
    </div>
  );
};

export default AuthorOverview;
