import moment from "moment";
import React, { useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import ReletadCourse from "./ReletadCourse/ReletadCourse";
const CheckoutPageBody = ({
	cart,
	handleRemoveCart,
	onCreateOrder,
	handleSubmitEmail,
	handleQuantity,
	calculateCoupon,
	discount,
	finalPrice,
}) => {
	let [couponCode, setCouponCode] = useState("");

	const [email, setEmail] = useState("");
	const total = (cartTotal) => {
		let pr = 0;
		cartTotal.map((v, i) => {
			pr += v.price * v.count;
		});
		return pr;
	};
	const [payBtn, setPayBtn] = useState(false);
	let [check, setCheck] = useState(true);

	return (
		<div>
			<Container>
				<div className="cart-page-inner-main-wrap">
					<div className="cart-header-title-checkout-page">
						<h4>Checkout</h4>
					</div>
					<div className="checkout-main-area-wrap-cart-body">
						<div className="checkout-content-checkout-page-main-wrap">
							<Row>
								<Col lg={6}>
									<div className="checkout-cart-items-wrap-rfdgbhghfgcxvbnb hbnvkjcb">
										<Table borderless>
											<tbody>
												{cart.map((v, i) => (
													<tr className="checkout-ghdjkiuhybnjiduhbn">
														<td>
															<img
																src={`https://staging-courseware-backend.sgp1.digitaloceanspaces.com/${v.image}`}
																alt=""
															/>
														</td>
														<td>
															<div className="course-remove-delete-btn">
																<i
																	className="bx bx-message-square-x"
																	onClick={(
																		e,
																	) =>
																		handleRemoveCart(
																			e,
																			v,
																		)
																	}></i>
															</div>
															<div className="added-single-courses">
																<h6>
																	{
																		v.courseName
																	}
																</h6>
																<p>
																	<span>
																		<i className="bx bxs-watch"></i>
																	</span>{" "}
																	Duration:{" "}
																	{
																		new Date(
																			Date.now(),
																		)
																			.toLocaleString()
																			.split(
																				",",
																			)[0]
																	}{" "}
																	-{" "}
																	{
																		moment(
																			new Date(),
																		)
																			.add(
																				v.duration,
																				"days",
																			)
																			.toDate()
																			.toLocaleString()
																			.split(
																				",",
																			)[0]
																	}
																</p>
																<span>
																	TK.{" "}
																	{v.price}
																</span>
															</div>
														</td>
													</tr>
												))}
											</tbody>
										</Table>
										<div className="ssl-commerce-require-pages-link">
											<Link to="/privecy-policy">
												Privacy Policy
											</Link>
											<Link to="/term-and-condition">
												Terms & Conditions
											</Link> 
											<Link to="/refund-return-policy">
												Refund and Return Policy
											</Link>
										</div>
									</div>
								</Col>
								<Col lg={6}>
									<div className="main-checkout-details-wrap">
										<div className="checkout-details-single-item-cvbn">
											<div className="checkout-name-plmhtr">
												<p>Price</p>
											</div>
											<div className="checkout-details-efvbhui">
												<p>Tk. {total(cart)}</p>
											</div>
										</div>{" "}
										<div className="checkout-details-single-item-cvbn">
											<div className="checkout-name-plmhtr">
												<p>Discount</p>
											</div>
											<div className="checkout-details-efvbhui">
												<p> - TK. {discount}</p>
											</div>
										</div>{" "}
										<div className="checkout-details-single-item-cvbn">
											<div className="checkout-name-plmhtr">
												<p>VAT</p>
											</div>
											<div className="checkout-details-efvbhui">
												<p>TK. 0</p>
											</div>
										</div>{" "}
										<div className="checkout-details-single-item-cvbn">
											<div className="checkout-name-plmhtr">
												<p>
													<b>Grand Total</b>
												</p>
											</div>
											<div className="checkout-details-efvbhui">
												<p>
													<b>
														TK.
														{finalPrice
															? finalPrice
															: total(cart)}
													</b>
												</p>
											</div>
										</div>{" "}
										<div className="checkout-details-single-item-cvbn">
											<div className="checkout-name-plmhtr">
												<p>
													<b>Apply Coupon</b>
												</p>
											</div>
											<div className="checkout-details-efvbhui">
												<div className="checkout-coupon-area">
													<input
														type="text"
														onChange={(e) =>
															setCouponCode(
																e.target.value,
															)
														}
													/>
													{finalPrice > 0 ? (
														<button disabled>
															Applied
														</button>
													) : (
														<button
															onClick={(e) =>
																calculateCoupon(
																	e,
																	couponCode,
																)
															}>
															Apply
														</button>
													)}
												</div>
											</div>
										</div>{" "}
										{/* <div className="checkout-details-single-item-cvbn">
                      <div className="checkout-name-plmhtr">
                        <p>
                          <b>Gift & Reward</b>
                        </p>
                      </div>
                      <div className="checkout-details-efvbhui">
                        <div className="checkout-coupon-area">
                          <input type="text" value={email} onChange={e => setEmail(e.target.value)}  />
                          <button onClick={e => handleSubmitEmail(e,email)}>Submit</button>
                        </div>
                      </div>   
                    </div> */}
										<div className="checkout-details-single-item-cvbn">
											<div className="checkout-name-plmhtr jhgfdefgb">
												{/* 
                      if button checked submit button color chenge gray to code: #5756D8
                      */}
												<input
													type="checkbox"
													id="confirm-checkout"
													onClick={() => {
														setPayBtn(!payBtn);
														setCheck(false);
													}}
												/>
												<label htmlFor="confirm-checkout">
													I have read and agree to the
													website's{" "}
													<span
														style={{
															color: "blue",
														}}>
														<Link to="/term-and-condition">
															Terms & Conditions
														</Link>
													</span>
													,{" "}
													<span
														style={{
															color: "blue",
														}}>
														<Link to="/privecy-policy">
															Privacy Policy
														</Link>
													</span>,{" "}
													<span
														style={{
															color: "blue",
														}}>
														<Link to="/refund-return-policy">
															Refund and Return
															Policy
														</Link>
													</span>
												</label>
											</div>
											<div className="checkout-details-efvbhui lkjhgfd">
												<div
													className={
														payBtn
															? "checkout-coupon-area confirm-checkout"
															: "checkout-coupon-area"
													}>
													<button
														disabled={check}
														onClick={(e) =>
															onCreateOrder(
																e,
																cart,
																0,
															)
														}>
														Pay
													</button>
												</div>
											</div>
										</div>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				</div>
				<div className="releted-course-bottom-wrap-area">
					<ReletadCourse />
				</div>
			</Container>
		</div>
	);
};

export default CheckoutPageBody;
