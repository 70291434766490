import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import FixedIcon from "../../assests/icons/FixedIcon";
import GrowthIcon from "../../assests/icons/GrowthIcon";
import NeutralIcon from "../../assests/icons/NeutralIcon";
import RetailGameLayout from "./RetailGameLayout";
import { useLocation } from "react-router-dom";
import RetailGameLayout2 from "./RetailGameLayout2";
import { useHistory, useParams } from "react-router-dom";
const SecondGameSubmission = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { token, startStep, totalMark, totalNumber, courseName, courseId , gameType} = useSelector((state) => ({
    token: state.Login.token,

    startStep: state.retail.startStep,
    currentMark: state.retail.currentMark,
    totalMark: state.retail.totalMark,
    courseName: state.studentGame.courseName,
    courseId: state.studentGame.courseId,
    totalNumber: state.retail.totalNumber,
    gameType: state.retail.gameType,
   
  }));
  console.log("totalMark", totalMark);

  return (
    <RetailGameLayout2 fixedGrowth={"final-result-page"} pathname={pathname}>
      <div className="fixed-growth-result-box">
        <div className="fixed-growth-result-inner-box">
          <div>
            <p>আপনার মাইন্ডসেট: </p>
            <hr />
            {totalMark > totalNumber / 2 ? (
              <>
                <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                  গ্রোথ
                </div>
                <GrowthIcon />
              </>
            ) : totalMark < totalNumber / 2 ? (
              <>
                ফিক্সড <FixedIcon />
              </>
            ) : (
              <>
                <div style={{ marginBottom: "20px", marginTop: "20px" }}>
                  নিউট্রাল
                </div>{" "}
                <NeutralIcon />
              </>
            )}
          </div>
          
        </div>
           
      </div>
    </RetailGameLayout2>
  );
};

export default SecondGameSubmission;
