import React from "react";
import NegotiationMethod from "../LafargeCommon/LafargeComponents/NegotiationMethod";
import LafargeCourseWareHeader from "../LafargeCommon/LafargeHeader/LafargeCourseWareHeader";

const NegotiationMethodPage = () => {
  return (
    <div>
      <LafargeCourseWareHeader />
      <NegotiationMethod />
    </div>
  );
};

export default NegotiationMethodPage;
