import React from "react";
import { Form, Table } from "react-bootstrap";
import { Col, Row } from "reactstrap";
import image1 from "./img/profile-1.png";
const RangkingBody = () => {
  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>Rank</h3>
            <p>
              Dashboard > <span>Rangking</span>{" "}
            </p>
          </div>
        </div>
        {/* <div className="packge-header-right">
          <Link to="/create-new-format">
            <span>+ </span>
            Create New
          </Link>
        </div> */}
      </div>

      <div className="package-main-content-list--jhdgfubv">
        <Row>
          <Col lg={6}>
            <div className="course-rank-main-area">
              <div className="course-rank-title">
                <h4>Course User Rank</h4>
              </div>
              <div className="course-ranking-drop-wrap">
                <Row>
                  <Col lg={6}>
                    <div className="select-filtering-area-jyfgvasjyhdfb">
                      <Form.Group>
                        <Form.Select>
                          <option value="">Select name</option>
                          <option value="">Select name</option>
                          <option value="">Select name</option>
                          <option value="">Select name</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </Col>{" "}
                  <Col lg={6}>
                    <div className="select-filtering-area-jyfgvasjyhdfb">
                      <Form.Group>
                        <Form.Select>
                          <option value="">Select name</option>
                          <option value="">Select name</option>
                          <option value="">Select name</option>
                          <option value="">Select name</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="rangking-data-table-main-wrap-area mt-3">
                <Table borderless className="ihdughghvjjeifck">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Name</th>
                      <th>Mark</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        <div className="profile-rangking-content">
                          <div className="profile-rangking-content-left">
                            <img src={image1} alt="" />
                          </div>
                          <div className="profile-rangking-content-right">
                            <h6>Jenny Wilson</h6>
                            <p>
                              <span>
                                <i className="bx bx-envelope"></i>
                              </span>
                              <span>useremail@gmail.com</span>
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>98</td>
                    </tr>{" "}
                    <tr>
                      <td>1</td>
                      <td>
                        <div className="profile-rangking-content">
                          <div className="profile-rangking-content-left">
                            <img src={image1} alt="" />
                          </div>
                          <div className="profile-rangking-content-right">
                            <h6>Jenny Wilson</h6>
                            <p>
                              <span>
                                <i className="bx bx-envelope"></i>
                              </span>
                              <span>useremail@gmail.com</span>
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>98</td>
                    </tr>{" "}
                    <tr>
                      <td>1</td>
                      <td>
                        <div className="profile-rangking-content">
                          <div className="profile-rangking-content-left">
                            <img src={image1} alt="" />
                          </div>
                          <div className="profile-rangking-content-right">
                            <h6>Jenny Wilson</h6>
                            <p>
                              <span>
                                <i className="bx bx-envelope"></i>
                              </span>
                              <span>useremail@gmail.com</span>
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>98</td>
                    </tr>{" "}
                    <tr>
                      <td>1</td>
                      <td>
                        <div className="profile-rangking-content">
                          <div className="profile-rangking-content-left">
                            <img src={image1} alt="" />
                          </div>
                          <div className="profile-rangking-content-right">
                            <h6>Jenny Wilson</h6>
                            <p>
                              <span>
                                <i className="bx bx-envelope"></i>
                              </span>
                              <span>useremail@gmail.com</span>
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>98</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>{" "}
          <Col lg={6}>
            <div className="course-rank-main-area">
              <div className="course-rank-title">
                <h4>Course Rank</h4>
              </div>

              <div className="">
                <Table borderless className="ihdughghvjjeifck">
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>Name</th>
                      <th>Mark</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                        <div className="profile-rangking-content">
                          <div className="profile-rangking-content-left">
                            <img src={image1} alt="" />
                          </div>
                          <div className="profile-rangking-content-right">
                            <h6>Jenny Wilson</h6>
                            <p>
                              <span>
                                <i className="bx bx-envelope"></i>
                              </span>
                              <span>useremail@gmail.com</span>
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>98</td>
                    </tr>{" "}
                    <tr>
                      <td>1</td>
                      <td>
                        <div className="profile-rangking-content">
                          <div className="profile-rangking-content-left">
                            <img src={image1} alt="" />
                          </div>
                          <div className="profile-rangking-content-right">
                            <h6>Jenny Wilson</h6>
                            <p>
                              <span>
                                <i className="bx bx-envelope"></i>
                              </span>
                              <span>useremail@gmail.com</span>
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>98</td>
                    </tr>{" "}
                    <tr>
                      <td>1</td>
                      <td>
                        <div className="profile-rangking-content">
                          <div className="profile-rangking-content-left">
                            <img src={image1} alt="" />
                          </div>
                          <div className="profile-rangking-content-right">
                            <h6>Jenny Wilson</h6>
                            <p>
                              <span>
                                <i className="bx bx-envelope"></i>
                              </span>
                              <span>useremail@gmail.com</span>
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>98</td>
                    </tr>{" "}
                    <tr>
                      <td>1</td>
                      <td>
                        <div className="profile-rangking-content">
                          <div className="profile-rangking-content-left">
                            <img src={image1} alt="" />
                          </div>
                          <div className="profile-rangking-content-right">
                            <h6>Jenny Wilson</h6>
                            <p>
                              <span>
                                <i className="bx bx-envelope"></i>
                              </span>
                              <span>useremail@gmail.com</span>
                            </p>
                          </div>
                        </div>
                      </td>
                      <td>98</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default RangkingBody;
