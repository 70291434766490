import { useState } from "react";
import LafargeFileUpload from "../LafargeCommon/LafargeComponents/LafargeFileUpload";
import LafargeCourseWareHeader from "../LafargeCommon/LafargeHeader/LafargeCourseWareHeader";

const LafargeFileUploadPage = () => {
  const [lafargePopup, setLafargePopup] = useState(true);
  return (
    <>
      <LafargeCourseWareHeader />
      <LafargeFileUpload
        lafargePopup={lafargePopup}
        setLafargePopup={setLafargePopup}
      />
    </>
  );
};

export default LafargeFileUploadPage;
