export const CategoryData = [
  {
    id: 1,
    name: "ECONOMIC ENVIRONMENT & PUBLIC AFFAIRS",
  },
  {
    id: 2,
    name: "STRATEGY",
  },
  {
    id: 3,
    name: "ENTREPRENEURSHIP",
  },
  {
    id: 4,
    name: "DIGITAL TECHNOLOGIES & DATA SCIENCE",
  },
  {
    id: 5,
    name: "MARKETING & COMMUNICATIONS",
  },
  {
    id: 6,
    name: "FINANCE",
  },
  {
    id: 7,
    name: "HUMAN RESOURCES",
  },
  {
    id: 8,
    name: "FINANCIAL ACCOUNTING",
  },
  {
    id: 9,
    name: "OPERATIONS & SUPPLY CHAIN MANAGEMENT ",
  },
  {
    id: 10,
    name: "COST ACCOUNTING & MANAGEMENT CONTROL",
  },
  {
    id: 11,
    name: "INNOVATION",
  },

  {
    id: 12,
    name: "ORGANISATIONAL BEHAVIOUR",
  },
  {
    id: 13,
    name: "NEGOTIATION",
  },
  {
    id: 14,
    name: "ARCHITECTURE",
  },
  {
    id: 15,
    name: "LAW",
  },
  {
    id: 16,
    name: "OTHERS",
  },
];
