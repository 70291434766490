import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getLafargeCourse } from "../../../../../store/actions";
const LafargeGameBody = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let dispatch = useDispatch();
  const { token, loading, lafargeCourseData } = useSelector((state) => ({
    token: state.Login.token,
    loading: state.lafargeReducer.loading,
    lafargeCourseData: state.lafargeReducer.lafargeCourseData,
  }));
  useEffect(() => {
    dispatch(getLafargeCourse(token));
  }, []);

  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>Game</h3>
            <p>Course</p>
          </div>
        </div>
      </div>
      <div>
        <Row>
          {lafargeCourseData?.length > 0 ? (
            lafargeCourseData?.map((data, idx) => (
              <Col md="3">
                <div className="game_card">
                  <div className="game_image">
                    <img
                      alt="image"
                      src={`${process.env.REACT_APP_IMAGE_URL}${data?.image}`}
                    />
                  </div>
                  <div className="header-content">
                    <p>{data?.courseName}</p>
                    <span>{data?.code}</span>
                  </div>
                  <div className="content_area">
                    <Link to="/admin-lafarge-student">
                      <span>Academic Area</span>
                      <h6 className="">
                        {data?.academicArea[0]?.academicName}
                      </h6>
                    </Link>

                    <div className="d-flex justify-content-left">
                      <div className="content-icon me-3">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}${data?.category[0]?.image}`}
                          alt="image"
                          style={{ width: "39px", height: "39px" }}
                        />
                      </div>
                      <div>
                        <p>
                          <span className="me-2">Format: </span>{" "}
                          {data?.category[0]?.categoryName}
                        </p>
                        <p>
                          <span className="me-2">Language: </span>{" "}
                          {data?.language}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <Col md="12" className="text-center mt-5 text-warning">
              No Course available
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default LafargeGameBody;
