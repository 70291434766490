export const FormatData = [
  {
    id: 1,
    CourseTitle: "Marketing",
    GameID: "#1243",
    CourseCategory: "Marketing",
    CourseFormat: "Simulation",
    Package: "Individual",
    Publish: "3Min",
  },
  {
    id: 2,
    CourseTitle: "Marketing",
    GameID: "#1243",
    CourseCategory: "Marketing",
    CourseFormat: "Simulation",
    Package: "Individual",
    Publish: "3Min",
  },
  {
    id: 3,
    CourseTitle: "Marketing",
    GameID: "#1243",
    CourseCategory: "Marketing",
    CourseFormat: "Simulation",
    Package: "Individual",
    Publish: "3Min",
  },
  {
    id: 4,
    CourseTitle: "Marketing",
    GameID: "#1243",
    CourseCategory: "Marketing",
    CourseFormat: "Simulation",
    Package: "Individual",
    Publish: "3Min",
  },
  {
    id: 5,
    CourseTitle: "Marketing",
    GameID: "#1243",
    CourseCategory: "Marketing",
    CourseFormat: "Simulation",
    Package: "Individual",
    Publish: "3Min",
  },
  {
    id: 6,
    CourseTitle: "Marketing",
    GameID: "#1243",
    CourseCategory: "Marketing",
    CourseFormat: "Simulation",
    Package: "Individual",
    Publish: "3Min",
  },
  {
    id: 7,
    CourseTitle: "Marketing",
    GameID: "#1243",
    CourseCategory: "Marketing",
    CourseFormat: "Simulation",
    Package: "Individual",
    Publish: "3Min",
  },
  {
    id: 8,
    CourseTitle: "Marketing",
    GameID: "#1243",
    CourseCategory: "Marketing",
    CourseFormat: "Simulation",
    Package: "Individual",
    Publish: "3Min",
  },
  {
    id: 9,
    CourseTitle: "Marketing",
    GameID: "#1243",
    CourseCategory: "Marketing",
    CourseFormat: "Simulation",
    Package: "Individual",
    Publish: "3Min",
  },
];
