import React from "react";
import { Container, Table } from "react-bootstrap";
import "react-responsive-modal/styles.css";
import { Link } from "react-router-dom";
import { FormatData } from "./FormatData";
import deleteIcon from "./img/close-circle.svg";
import editIcon from "./img/edit-2.svg";
const AuthorOverviewData = () => {
  return (
    <Container>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>Overview</h3>
          </div>
        </div>
      </div>

      <div className="package-main-content-list">
        <Table borderless>
          <thead className="package-main-content-list-table-header">
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Email</th>
              <th>Result</th>
              <th>Rank</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {FormatData.map((item, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>

                  <td>{item.AcademicAreaName}</td>
                  <td>{item.Email}</td>
                  <td>{item.CategoryName}</td>

                  <td>{item.Slug}</td>
                  <td>
                    <div className="package-control-action-area">
                      <Link to="formate-edit">
                        <img src={editIcon} alt="" /> Edit
                      </Link>
                      <button>
                        <img src={deleteIcon} alt="" />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </Container>
  );
};

export default AuthorOverviewData;
