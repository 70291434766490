import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { MetaTags } from "react-meta-tags";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import MainFooter from "../../components/StudentPages/Common/footer/MainFooter";
import Header from "../../components/StudentPages/Common/Header";
import LoginHeader from "../../components/StudentPages/Common/LoginHeader";
import "./return-policy.scss";
const ReturnPolicy = () => {
  const { token } = useSelector((state) => ({
    token: state.Login.token,
  }));
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <MetaTags>
        <title>Refund and Return Policy | GameProject</title>
      </MetaTags>
      {token ? <LoginHeader /> : <Header />}

      <Container>
        <div className="return-policy-main-wrap">
          <div className="return-policy-inner-content-wrap">
            <div className="return-policy-header">
              <h3>Refund and Return Policy</h3>
              <p>Last Updated on 27th October 2022</p>
            </div>
            <div className="return-policy-content-bottom">
              <div className="return-policy-single-item">
                <h4>
                  Transform Your Idea Into Reality with Ether a Leading Digital
                  Agency
                </h4>
                <p>
                  You have access to the Courseware service through the end of
                  your paying month, even if you decide to cancel your
                  membership at any time. To the extent permitted by relevant
                  law, payments are non-refundable, and we do not offer credits
                  or refunds for any unused portions of membership periods or
                  Courseware content. Your account will automatically close at
                  the conclusion of the current billing cycle if you discontinue
                  your subscription.
                </p>
                <p>
                  Users of courseware are not given a free trial. However,
                  occasionally a small number of courses may be provided without
                  charge to provide customers with a proper experience. As a
                  result, there is NO RETURN AND NO REFUND FOR ANY PURCHASED
                  SUBSCRIPTION.
                </p>
                <p>
                  We reserve the right to alter both the cost of our service and
                  our subscription options at any moment. Any pricing
                  adjustments or modifications to your subscription plans,
                  however, won't take effect till 10 days have passed after you
                  received notice of them.
                </p>
                <p>
                  Contact info@maacsolutions.com for a personalized subscription
                  plan.
                </p>
              </div>{" "}
            </div>
          </div>
        </div>
      </Container>
      <MainFooter />
    </div>
  );
};

export default ReturnPolicy;
