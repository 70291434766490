import { useState } from "react";
import { Container } from "react-bootstrap";
import Modal from "react-responsive-modal";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import trainingpopupbg from "../../LafargeAssets/images/coursewarePopupBg.svg";
import { lafargeData } from "./LafargeTrainingData";
const LafargeTraining = () => {
  const { courseId } = useParams();
  const [openTrainingModal, setOpenTrainingModal] = useState(false);
  const [trainingData, setTrainingData] = useState({});
  console.log(trainingData);

  return (
    <Container>
      <Modal
        open={openTrainingModal}
        onClose={() => {
          setOpenTrainingModal(false);
          setTrainingData("");
        }}
        center
        classNames={{
          modal: "lafarge-training-modal",
          overlayAnimationIn: "customEnterOverlayAnimation",
          overlayAnimationOut: "customLeaveOverlayAnimation",
          modalAnimationIn: "customEnterModalAnimation",
          modalAnimationOut: "customLeaveModalAnimation",
        }}
      >
        <h3>{trainingData.name}</h3>
        <p>{trainingData.contact}</p>

        <div className="training-popup-bg">
          <img src={trainingpopupbg} alt="" />
        </div>
      </Modal>
      <div className="lafarge-training-inner-wrap">
        <div className="lafarge-training-header-title">
          <h3>Your playbook to win against odds</h3>
          {/* <p>Author: Nazmus Shakib</p> */}
        </div>
        <div className="lafarge-training-items-wrap">
          {lafargeData.map((item, i) => (
            <div
              key={i}
              className={`lafarge-training-item ${item.boxColor}`}
              onClick={() => {
                setOpenTrainingModal(true);
                setTrainingData(item);
              }}
            >
              <div style={{ textAlign: "center" }}>
                {item.icon}

                <h5 className="mt-3">{item.name}</h5>
                <span>Read more</span>
              </div>
            </div>
          ))}
        </div>
        <div className="lafarge-training-footer-wrap">
          <div className="lafarge-training-footer-button">
            <Link to={`/${courseId}/training-details`}>
              Preceed to next section
            </Link>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default LafargeTraining;
