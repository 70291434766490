import {
	GET_AUTHORS_BY_ADMIN,
	GET_AUTHORS_BY_ADMIN_SUCCESS,
	GET_SINGLE_AUTHOR,
	GET_SINGLE_AUTHOR_SUCCESS,
	GET_CATEGORY,
	GET_CATEGORY_SUCCESS,
	CREATE_CATEGORY,
	CREATE_CATEGORY_SUCCESS,
	CREATE_CATEGORY_FAIL,
	GET_STUDENTS,
	GET_STUDENTS_SUCCESS,
	GET_SINGLE_STUDENT,
	GET_SINGLE_STUDENT_SUCCESS,
	GET_ACADEMIC_AREAS,
	GET_ACADEMIC_AREAS_SUCCESS,
	UPDATE_AUTHOR,
	UPDATE_AUTHOR_SUCCESS,
	GET_MULTISELECTOR_DATA,
	GET_MULTISELECTOR_DATA_SUCCESS,
	CREATE_AUTHOR,
	CREATE_AUTHOR_FAIL,
	CREATE_AUTHOR_SUCCESS,
	COURSE_RELATED_PREVOUS_MULTI_DATA,
	COURSE_RELATED_PREVOUS_MULTI_DATA_SUCCESS,
	COURSE_CREATE_FAIL,
	COURSE_CREATE_SUCCESS,
	COURSE_CREATE,
	GET_COURSES,
	GET_COURSES_SUCCESS,
	GET_SINGLE_COURSE,
	GET_SINGLE_COURSE_FAIL,
	GET_SINGLE_COURSE_SUCCESS,
	CREATE_ACADEMIC_AREA,
	CREATE_ACADEMIC_AREA_FAIL,
	CREATE_ACADEMIC_AREA_SUCCESSS,
	GET_CATEGORY_IDS,
	GET_CATEGORY_IDS_SUCCESS,
	GET_SINGLE_COURSE_OF_VALID_USER,
	GET_SINGLE_COURSE_OF_VALID_USER_SUCCESS,
	GET_SINGLE_COURSE_OF_VALID_USER_FAIL,
	GET_COURSE_DATA_FOR_UPDATE,
	GET_COURSE_DATA_FOR_UPDATE_SUCCESS,
	GET_COURSE_DATA_FOR_UPDATE_FAIL,
	UPLOAD_AUTHOR_PHOTO,
	UPLOAD_AUTHOR_PHOTO_FAIL,
	UPLOAD_AUTHOR_PHOTO_SUCCESS,
	UPLOAD_COURSE_PHOTO,
	UPLOAD_COURSE_PHOTO_FAIL,
	UPLOAD_COURSE_PHOTO_SUCCESS,
	UPDATE_COURSE,
	UPDATE_COURSE_FAIL,
	UPDATE_COURSE_SUCCESS,
	GET_FORMAT,
	GET_FORMAT_SUCCESS,
	GET_FORMAT_FAIL,
	UPDATE_ACADEMIC_AREA,
	UPDATE_ACADEMIC_AREA_FAIL,
	UPDATE_ACADEMIC_AREA_SUCCESS,
	GET_SINGLE_ACADEMIC_AREA_ADMIN,
	GET_SINGLE_ACADEMIC_AREA_FAIL_ADMIN,
	GET_SINGLE_ACADEMIC_AREA_SUCCESS_ADMIN,
	UPDATE_FORMAT,
	UPDATE_FORMAT_FAIL,
	UPDATE_FORMAT_SUCCESS,
	GET_ORDERS,
	GET_ORDERS_SUCCESS,
} from "./actionTypes";

export const getAuthorsByAdmin = (token) => {
	return {
		type: GET_AUTHORS_BY_ADMIN,
		payload: { token },
	};
};

export const getAuthorsByAdminSuccess = (data) => {
	return {
		type: GET_AUTHORS_BY_ADMIN_SUCCESS,
		payload: { data },
	};
};

export const getSingleAuthor = (token, authorId, history) => {
	return {
		type: GET_SINGLE_AUTHOR,
		payload: { token, authorId, history },
	};
};

export const getSingleAuthorSuccess = (data) => {
	return {
		type: GET_SINGLE_AUTHOR_SUCCESS,
		payload: { data },
	};
};

export const getCategory = (token) => {
	return {
		type: GET_CATEGORY,
		payload: { token },
	};
};

export const getCategorySuccess = (data) => {
	return {
		type: GET_CATEGORY_SUCCESS,
		payload: { data },
	};
};

export const createCategory = (token, formData, history) => {
	return {
		type: CREATE_CATEGORY,
		payload: { token, formData, history },
	};
};

export const createCateogrySuccess = (message) => {
	return {
		type: CREATE_CATEGORY_SUCCESS,
		payload: { message },
	};
};

export const createCategoryFail = (message) => {
	return {
		type: CREATE_CATEGORY_FAIL,
		payload: { message },
	};
};

export const getStudents = (token) => {
	return {
		type: GET_STUDENTS,
		payload: { token },
	};
};

export const getStudentsSuccess = (data) => {
	return {
		type: GET_STUDENTS_SUCCESS,
		payload: { data },
	};
};

//get Orders Related Data
export const getOrderRelatedData = () => {
	return {
		type: GET_ORDERS,
		payload: [],
	};
};

export const getOrderRelatedDataSuccess = (data) => {
	return {
		type: GET_ORDERS_SUCCESS,
		payload: { data },
	};
};
export const getSingleStudent = (token, id) => {
	return {
		type: GET_SINGLE_STUDENT,
		payload: { token, id },
	};
};

export const getSingleStudentSuccess = (data) => {
	return {
		type: GET_SINGLE_STUDENT_SUCCESS,
		payload: { data },
	};
};

export const getAcademicArea = () => {
	return {
		type: GET_ACADEMIC_AREAS,
		payload: {},
	};
};

export const getAcademicAreaSuccess = (data) => {
	return {
		type: GET_ACADEMIC_AREAS_SUCCESS,
		payload: { data },
	};
};

export const updateAuthor = (
	token,
	authorName,
	phoneNumber,
	email,
	academicIds,
	description,
	photo,
	formatIds,
	authorId,
	history,
) => {
	return {
		type: UPDATE_AUTHOR,
		payload: {
			token,
			authorName,
			phoneNumber,
			email,
			academicIds,
			description,
			photo,
			formatIds,
			authorId,
			history,
		},
	};
};

export const updateAuthorSuccess = (message) => {
	return {
		type: UPDATE_AUTHOR_SUCCESS,
		payload: { message },
	};
};

export const getMultiSelectorData = (token) => {
	return {
		type: GET_MULTISELECTOR_DATA,
		payload: { token },
	};
};

export const getMultiSelectorDataSuccess = (data) => {
	return {
		type: GET_MULTISELECTOR_DATA_SUCCESS,
		payload: { data },
	};
};

export const createAuthor = (
	token,
	formatId,
	academicId,
	phoneNumber,
	authorName,
	email,
	photo,
	description,
	history,
) => {
	return {
		type: CREATE_AUTHOR,
		payload: {
			token,
			formatId,
			academicId,
			phoneNumber,
			authorName,
			email,
			photo,
			description,
			history,
		},
	};
};

export const createAuthorSuccess = (message) => {
	return {
		type: CREATE_AUTHOR_SUCCESS,
		payload: { message },
	};
};

export const createAuthorFail = (message) => {
	return {
		type: CREATE_AUTHOR_FAIL,
		payload: { message },
	};
};

export const courseRelatedPreviousMultiData = (token) => {
	return {
		type: COURSE_RELATED_PREVOUS_MULTI_DATA,
		payload: { token },
	};
};

export const courseRelatedPreviousMultiDataSuccess = (data) => {
	return {
		type: COURSE_RELATED_PREVOUS_MULTI_DATA_SUCCESS,
		payload: { data },
	};
};

export const courseCreate = (
	token,
	courseName,
	code,
	noOfPages,
	category,
	price,
	estimatedTime,
	description,
	subjectCoverd,
	language,
	academicArea,
	author,
	typeOfPublication,
	duration,
	coursePhoto,
	history,
) => {
	return {
		type: COURSE_CREATE,
		payload: {
			token,
			courseName,
			code,
			noOfPages,
			category,
			price,
			estimatedTime,
			description,
			subjectCoverd,
			language,
			academicArea,
			author,
			typeOfPublication,
			duration,
			coursePhoto,
			history,
		},
	};
};

export const courseCreateSuccess = (message) => {
	return {
		type: COURSE_CREATE_SUCCESS,
		payload: { message },
	};
};

export const courseCreateFail = (message) => {
	return {
		type: COURSE_CREATE_FAIL,
		payload: { message },
	};
};

export const getCourses = () => {
	return {
		type: GET_COURSES,
		payload: {},
	};
};

export const getCoursesSuccess = (data) => {
	return {
		type: GET_COURSES_SUCCESS,
		payload: { data },
	};
};

export const getSingleCourse = (courseId) => {
	return {
		type: GET_SINGLE_COURSE,
		payload: { courseId },
	};
};

export const getSingleCourseSuccess = (data) => {
	return {
		type: GET_SINGLE_COURSE_SUCCESS,
		payload: { data },
	};
};

export const getSingleCourseFail = (message) => {
	return {
		type: GET_SINGLE_COURSE_FAIL,
		payload: { message },
	};
};

export const createAcademicArea = (token, academicName, category, history) => {
	return {
		type: CREATE_ACADEMIC_AREA,
		payload: { token, academicName, category, history },
	};
};

export const createAcademicAreaSuccess = (message) => {
	return {
		type: CREATE_ACADEMIC_AREA_SUCCESSS,
		payload: { message },
	};
};

export const createAcademicAreaFail = (message) => {
	return {
		type: CREATE_ACADEMIC_AREA_FAIL,
		payload: { message },
	};
};

export const getCategoryIds = (token) => {
	return {
		type: GET_CATEGORY_IDS,
		payload: { token },
	};
};

export const getCategoryIdsSuccess = (data) => {
	return {
		type: GET_CATEGORY_IDS_SUCCESS,
		payload: { data },
	};
};

export const getSingleCourseOfValidUser = (token, courseId) => {
	return {
		type: GET_SINGLE_COURSE_OF_VALID_USER,
		payload: { token, courseId },
	};
};

export const getSingleCourseOfValidUserSuccess = (data) => {
	return {
		type: GET_SINGLE_COURSE_OF_VALID_USER_SUCCESS,
		payload: { data },
	};
};

export const getSingleCourseOfValidUserFail = (message) => {
	return {
		type: GET_SINGLE_COURSE_OF_VALID_USER_FAIL,
		payload: { message },
	};
};

export const getCourseDataForUpdate = (token, courseId) => {
	return {
		type: GET_COURSE_DATA_FOR_UPDATE,
		payload: { token, courseId },
	};
};

export const getCourseDataForUpdateSuccess = (data) => {
	return {
		type: GET_COURSE_DATA_FOR_UPDATE_SUCCESS,
		payload: { data },
	};
};

export const getCourseDataForUpdaetFail = (message) => {
	return {
		type: GET_COURSE_DATA_FOR_UPDATE_FAIL,
		payload: { message },
	};
};

export const uploadAuthorPhoto = (token, formData) => {
	return {
		type: UPLOAD_AUTHOR_PHOTO,
		payload: { token, formData },
	};
};
export const uploadAuthorPhotoSuccess = (photo) => {
	return {
		type: UPLOAD_AUTHOR_PHOTO_SUCCESS,
		payload: { photo },
	};
};

export const uploadAuthorPhotoFail = (error) => {
	return {
		type: UPLOAD_AUTHOR_PHOTO_FAIL,
		payload: { error },
	};
};

export const uploadCoursePhoto = (token, formData) => {
	return {
		type: UPLOAD_COURSE_PHOTO,
		payload: { token, formData },
	};
};

export const uploadCoursePhotoSuccess = (data) => {
	return {
		type: UPLOAD_COURSE_PHOTO_SUCCESS,
		payload: { data },
	};
};

export const uploadCoursePhotoFail = (message) => {
	return {
		type: UPLOAD_COURSE_PHOTO_FAIL,
		payload: { message },
	};
};

export const updateCourse = (
	token,
	courseId,
	courseName,
	courseCode,
	noOfPages,
	estimatedTime,
	coursePrice,
	description,
	coursePhoto,
	language,
	subjectCoverd,
	validation,
	typeOfPublication,
	history,
) => {
	return {
		type: UPDATE_COURSE,
		payload: {
			token,
			courseId,
			courseName,
			courseCode,
			noOfPages,
			estimatedTime,
			coursePrice,
			description,
			coursePhoto,
			language,
			subjectCoverd,
			validation,
			typeOfPublication,
			history,
		},
	};
};

export const updateCourseSuccess = (message) => {
	return {
		type: UPDATE_COURSE_SUCCESS,
		payload: { message },
	};
};

export const updateCourseFail = (error) => {
	return {
		type: UPDATE_COURSE_FAIL,
		payload: { error },
	};
};

export const getFormat = (token, formatId) => {
	return {
		type: GET_FORMAT,
		payload: { token, formatId },
	};
};

export const getFormatSuccess = (data) => {
	return {
		type: GET_FORMAT_SUCCESS,
		payload: { data },
	};
};

export const updateAcademicArea = (
	token,
	academicId,
	academicName,
	history,
) => {
	return {
		type: UPDATE_ACADEMIC_AREA,
		payload: { token, academicId, academicName, history },
	};
};
export const updateAcademicAreaSuccess = (message) => {
	return {
		type: UPDATE_ACADEMIC_AREA_SUCCESS,
		payload: { message },
	};
};
export const updateAcademicAreaFail = (error) => {
	return {
		type: UPDATE_ACADEMIC_AREA_FAIL,
		payload: { error },
	};
};

export const getSingleAcademicAreaAdmin = (token, academicId) => {
	return {
		type: GET_SINGLE_ACADEMIC_AREA_ADMIN,
		payload: { token, academicId },
	};
};
export const getSingleAcademicAreaSuccessAdmin = (data) => {
	return {
		type: GET_SINGLE_ACADEMIC_AREA_SUCCESS_ADMIN,
		payload: { data },
	};
};
export const getSingleAcademicAreaFailAdmin = (message) => {
	return {
		type: GET_SINGLE_ACADEMIC_AREA_FAIL_ADMIN,
		payload: { message },
	};
};

export const updateFormat = (
	token,
	formatId,
	formatName,
	formatPhoto,
	history,
) => {
	return {
		type: UPDATE_FORMAT,
		payload: { token, formatId, formatName, formatPhoto, history },
	};
};

export const updateFormatSuccess = (message) => {
	return {
		type: UPDATE_FORMAT_SUCCESS,
		payload: { message },
	};
};

export const updateFormatFail = (message) => {
	return {
		type: UPDATE_FORMAT_FAIL,
		payload: { message },
	};
};
