import { useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "./giftreward.scss";
const GiftReward = ({ handleGift, course }) => {
  let history = useHistory();

  const [giftEmail, setGiftEmail] = useState("");

  // const handleSendGift = e =>
  // {
  //   e.preventDefault()
  //   if (!giftEmail) {

  //   } else {
  //     handleSendGift(e, )

  //   }
  // }
  return (
    <div className="gift-main-area-wrap">
      <div className="gift-inner-area-wrap">
        <Container>
          <div className="gift-main-area-wrap">
            <h3>Gift a course</h3>
            <Row>
              <Col md={6} className="my-auto">
                <div className="gift-area-content-right">
                  <div className="hjgkhjghfdjnkvcmbklhjg">
                    <img
                      src={`${process.env.REACT_APP_IMAGE_URL}${course.image}`}
                      alt=""
                    />
                    {/* <h4>Business Decision Making</h4>
                    <p>Marketing (Game)</p> */}
                  </div>
                </div>
              </Col>
              <Col md={6} className="my-auto-">
                <div className="gift-area-content-left">
                  <div className="hjdhkdbmvjkfgb">
                    <h4>{course?.courseName}</h4>
                    <p>
                      {course?.academicArea?.map((v, i) => (
                        <>
                          {v.academicName}{" "}
                          {course?.academicArea.length == i + 1 ? "" : "|"}
                        </>
                      ))}
                    </p>
                  </div>
                  <Form className="form-main-area-gift">
                    {/* <Form.Group className="mb-3" controlId="">
                      <Form.Label>Recipient's Name:</Form.Label>
                      <Form.Control type="text" placeholder="Enter your name" />
                    </Form.Group>{" "} */}
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Recipient's Email</Form.Label>
                      <Form.Control
                        type="email"
                        style={{ textTransform: "lowercase" }}
                        value={giftEmail}
                        placeholder="Enter email"
                        onChange={(e) => setGiftEmail(e.target.value)}
                      />
                    </Form.Group>{" "}
                    {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>
                        When do you want to send this gift:
                      </Form.Label>
                      <Form.Control type="date" placeholder="Enter date" />
                    </Form.Group>{" "} */}
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Your Message (optional)</Form.Label>
                      <Form.Control as="textarea" placeholder="Enter email" />
                    </Form.Group>{" "}
                    <div className="submit-area-gift">
                      <button
                        className="gift-submit-button-area"
                        onClick={(e) => handleGift(e, giftEmail)}
                      >
                        Proceed to checkout
                      </button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default GiftReward;
