import React,{useState,useEffect} from "react";
import { Form, Table, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import deleteIcon from "../../img/close-circle.svg";
import editIcon from "../../img/edit-2.svg";
import { FormatData } from "./FormatData";
import { useHistory, useLocation,   } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getCourses } from "../../../../store/actions";
const AllCourseBody = () =>
{
  let dispatch = useDispatch()
  let history = useHistory()  
  const { token, loading,  courses  } = useSelector(
    state => ({
      token: state.Login.token,
      loading: state.AdminAuthorReducer.loading,
       courses: state.AdminAuthorReducer.courses
       
    })
  )

  useEffect(() =>
  {
    dispatch(getCourses())
  },[])
  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>All Course List</h3>
            <p>
              Course list {`>`} <span>Course</span>{" "}
            </p>
          </div>
        </div>
        <div className="packge-header-right">
          <Link to="/create-new-course">
            <span>+ </span>
            Create New
          </Link>
        </div>
      </div>
      <div className="data-search-and-filtering-area-wrap">
        <div className="data-search-inner-area-jhhbcvhkjzjghfjdn">
          <div className="search-box-dfghjkiujfb">
            <input type="search" name="" id="" />
          </div>
          <div className="select-filtering-area-jyfgvasjyhdfb">
            <Form.Group>
              <Form.Select>
                <option value="">Select name</option>
              </Form.Select>
            </Form.Group>
          </div>
        </div>
      </div>
      <div className="package-main-content-list">
        <Table borderless>
          <thead className="package-main-content-list-table-header">
            <tr>
              <th>#</th>
              <th>Course Title</th>
              {/* <th>Summary</th> */}
              <th>Course Format</th>
              {/* <th>Status</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loading && <Spinner />}
            {!loading && courses.map((item, i) => {
              return (
                <tr key={i}>
                  <td>{i+1}</td>
                  <td>{item.courseName}</td>
                  {/* <td>{item.Summary}</td> */}
                  <td>{item.typeOfPublication}</td>

                  {/* <td>{item.Slug}</td> */}
                  <td>
                    <div className="package-control-action-area">
                      <Link to={`/all-course-edit/${item._id}`}>  
                        <img src={editIcon} alt="" /> Edit
                      </Link>
                      <button>
                        <img src={deleteIcon} alt="" />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default AllCourseBody;
