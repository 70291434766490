export const GET_NUMBERS_OF_ALL_DATA  = "GET_NUMBERS_OF_ALL_DATA"
export const GET_NUMBERS_OF_ALL_DATA_SUCCESS = "GET_NUMBERS_OF_ALL_DATA_SUCCESS"

//Recent Courses
export const GET_RECENT_COURSES_BY_ADMIN = "GET_RECENT_COURSES_BY_ADMIN"
export const GET_RECENT_COURSES_BY_ADMIN_SUCCESS = "GET_RECENT_COURSES_BY_ADMIN_SUCCESS"

//get Popular courses
export const GET_POPULAR_COURSES_BY_ADMIN = "GET_POPULAR_COURSES_BY_ADMIN"
export const GET_POPULAR_COURSES_BY_ADMIN_SUCCESS = "GET_POPULAR_COURSES_BY_ADMIN_SUCCESS"

//get course Categories
export const GET_CATEGORIES_BY_ADMIN = "GET_CATEGORIES_BY_ADMIN"
export const GET_CATEGORIES_BY_ADMIN_SUCCESS = "GET_CATEGORIES_BY_ADMIN_SUCCESS"

//get student enrolement data
export const GET_STUDENT_ENROLLMENT_DATA = "GET_STUDENT_ENROLLMENT_DATA"
export const GET_STUDENT_ENROLLMENT_DATA_SUCCESS = "GET_STUDENT_ENROLLMENT_DATA_SUCCESS"

//get ORDER LIST
export const GET_ORDER_LIST = "GET_ORDER_LIST"
export const GET_ORDER_LIST_SUCCESS = "GET_ORDER_LIST_SUCCESS"


//coupon related
export const CREATE_COUPON = "CREATE_COUPON"
export const CREATE_COUPON_SUCCESS = "CREATE_COUPON_SUCCESS"
export const CREATE_COUPON_FAIL = "CREATE_COUPON_FAIL"
//get all coupon
export const GET_COUPONS = "GET_COUPONS"
export const GET_COUPONS_SUCCESS = "GET_COUPONS_SUCCESS"
//update coupon
export const UPDATE_COUPON = "UPDATE_COUPON"
export const UPDATE_COUPON_SUCCESS = "UPDATE_COUPON_SUCCESS"
export const UPDATE_COUPON_FAIL = "UPDATE_COUPON_FAIL"

//delete coupon
export const DELETE_COUPON = "DELETE_COUPON"
export const DELETE_COUPON_SUCCESS = "DELETE_COUPON_SUCCESS"
export const DELETE_COUPON_FAIL = "DELETE_COUPON_FAIL"

//studetn get coupon
export const GET_COUPON_BY_USER = "GET_COUPON_BY_USER"
export const GET_COUPON_BY_USER_SUCCESS = "GET_COUPON_BY_USER_SUCCESS"