import { useEffect } from "react";
import { MetaTags } from "react-meta-tags";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import MainFooter from "../Common/footer/MainFooter";
import Header from "../Common/Header";
import LoginHeader from "../Common/LoginHeader";
import CourseDetails from "./CourseDetails";
import "./CoursewareDetails.scss";
const CourseDetailsHome = () => {
  const { token } = useSelector((state) => ({
    token: state.Login.token,
  }));
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div>
      <MetaTags>
        <title>Course details | GameProject</title>
      </MetaTags>
      {token ? <LoginHeader /> : <Header />}
      <CourseDetails />
      <MainFooter />
    </div>
  );
};

export default CourseDetailsHome;
