import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
const initialState = {
  countryName: [],
};
const courseThreeSlice = createSlice({
  name: "courseThree",
  initialState,
  reducers: {
    selectCountry: (state, action) => {
      state.countryName = [...state.countryName, action.payload];
    },
  },
});
export const { selectCountry } = courseThreeSlice.actions;
const persistConfig = {
  keyPrefix: "gdp",
  key: "countryList",
  storage,
};

export default persistReducer(persistConfig, courseThreeSlice.reducer);
