import { call, put, takeEvery } from "redux-saga/effects"
import
    {
        axiosTeacherRegistration,
        axiosStudentRegistration,
        axiosResetPassword,
        axiosGetCourseByAcademy,
        axiosGetCategories,
        axiosGetDataOnSearch,
        axiosGetAuthorIDData
    } from "../../../helpers/api_helper"
import {         getDataOnSearchSuccess,
    getCourseOfAcademicAreaSuccess,
    getAllCategoriesForAllSuccess,
    getAuthorDataSuccess,
    getAuthorDataFail
} from "./actions"
import { toast } from 'react-toastify';

import
{
    GET_COURSE_OF_ACADEMIC_AREA,
    GET_ALL_CATEGORIES_FOR_ALL,
    GET_DATA_ON_SEARCH,
    GET_AUTHOR_DATA_USER
} from "./actionTypes"




//workerGetCourseAcademicArea
const asyncGetCourseAcademicArea = async (academicId) =>
{
    try {
        const response = await axiosGetCourseByAcademy(academicId)
        return response.data
    } catch (error) {
        return error.response.data
        
    }
}
function* workerGetCourseAcademicArea({ payload: { academicId } })

{
    try {
//////console.log("academic", academicId)
        const response = yield call(asyncGetCourseAcademicArea, academicId)
        //////console.log("response data", response.getData)
        yield put(yield put(getCourseOfAcademicAreaSuccess(response.getData)))
        
    } catch (error) {
        
    }
}

//get all categories for all
const asyncGetCategories = async () =>
{
    try {
        const response = await axiosGetCategories()
        return response.data
    } catch (error) {
        return error.response.data
    }
}
function* workerGetallCategoriesForAll({ })
{
    try {
        const response = yield call(asyncGetCategories)
         yield put(getAllCategoriesForAllSuccess(response.getData))

    } catch (error) {
        
    }
}


//get data on search
const asyncGetDataOnSearch = async text =>
{
    try {
        const response = await axiosGetDataOnSearch(text)
        return response.data
    } catch (error) {
        return error.response.data
    }
}
function* workerGetDataOnSearch({payload: {text}})
{
    try {
console.log("text here", text)
        const response = yield call(asyncGetDataOnSearch, text)
        console.log("response data get data", response)
        yield put(getDataOnSearchSuccess(response.getData))
      
    } catch (error) {
        
    }
}


//author data
const asyncGetAuthorData = async ( authorId) =>
{
    try {
        const response = await axiosGetAuthorIDData( authorId)
        return response.data
    } catch (error) {
        return error.response.data
    }
}
function* workerGetAuthorData({ payload: {   authorId } })
{
    try {
        const response = yield call(asyncGetAuthorData, authorId)
        // console.log("author data", response)
        if (response.status === "success") {
            console.log("data here", response.getDatas)
            yield put(getAuthorDataSuccess(response.getDatas))
        } else {
            toast.error(response.message)
            yield put(getAuthorDataFail(response.message))
            yield put(getAuthorDataFail())
        }
    } catch (error) {
        
    }
}
function* academicAreaSaga()
{
    yield takeEvery(GET_COURSE_OF_ACADEMIC_AREA, workerGetCourseAcademicArea)
    yield takeEvery(GET_ALL_CATEGORIES_FOR_ALL, workerGetallCategoriesForAll)
    yield takeEvery(GET_DATA_ON_SEARCH, workerGetDataOnSearch)
    yield takeEvery(GET_AUTHOR_DATA_USER, workerGetAuthorData)
}

export default academicAreaSaga