import React,{useEffect} from "react";
import { Table } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import { showResult } from "../../store/actions";
import resultLogo from "../../assests/images/result.svg";
import tickNegative from "../../assests/images/tick-negative.svg";

import { useDispatch, useSelector } from "react-redux";
import { courseOwnerValid } from "../../store/actions";
const ResultGameTwo = () => {
  let history = useHistory();
  let dispatch = useDispatch()

  const { token,   loading, result, position, course } = useSelector((state) => ({
    token: state.Login.token,
  
    loading : state.courseReducer.loading,
    result : state.courseReducer.result,
    position: state.courseReducer.position,
    course: state.courseReducer.course,
 
  }));
  let {courseId} = useParams()
  const handleClick = (e) => {
    e.preventDefault();
    history.push("/home");
  };

  useEffect(() =>
  {
    dispatch(showResult(token, courseId))
    dispatch(courseOwnerValid(token,courseId, history ))


  },[])
  return (
    <div>
      <div className="container-md">
        <div className="result-page-full">
          <div className="result-page-top kduhjnfjgnjvduybghvjk">
            <div>
              
             
              {  result?.mark ==100 ?  <img src={resultLogo} alt=""></img> :<img src={tickNegative} alt=""></img>}
             
              <p>Result Summery</p>
              <h4>Course Result: {result?.mark}</h4>
            </div>
          </div>
          <div className="result-page-top kduhjnfjgnjvduybghvjk">
            <div className="row">
              <div className="col-md-6">
                <div className="profile-hjkdjfbajbknyfa">
                  <p>
                    <span className="result-title">Author : </span> { course?.author?.authorName}
                  </p>
                  {/* <p>
                    <span className="result-title">Academic Area : </span>{" "}
                    {course?.author?.academicArea}
                  </p> */}
                  <p>
                    <span className="result-title">Course: </span> {course?.courseName}
                  </p>
                  <p>
                    <span className="result-title">Course Code: </span> {course?.code}
                  </p>
                </div>
              </div>{" "}
              <div className="col-md-6">
                <div className="profile-hjkdjfbajbknyfa">
                  <Table striped borderless hover>
                    <thead>
                      <tr>
                        {/* <th>Rank</th> */}
                        <th>Name</th>
                        <th>Mark</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {/* <td>{position? position: "No Data"}</td> */}
                        <td>
                          <div className="profile-hsjbahfbdgjbh">
                            <div className="profile-rank-data-image">
                            <img
                                src={`https://staging-courseware-backend.sgp1.digitaloceanspaces.com/${course.image}`}
                                alt="MAAC"
                              /> 
                            </div>
                            <div className="profile-rank-data">
                              <h5 > {result?.student?.firstName? result?.student?.firstName + " "+ result?.student?.lastName : result?.student?.email.split("@")[0]} </h5>
                              <p>{ result?.student?.email}</p>
                            </div>
                          </div>
                        </td>
                        <td>{ result?.mark}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="col-md-12 mt-5">
                <Button onClick={(e) => handleClick(e)}>Go Home</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultGameTwo;

