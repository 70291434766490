import React, {useEffect} from "react";
import "../../assests/styles.css";
import ListHeader from "./ListHeader";
import { useDispatch, useSelector } from "react-redux";
import { useHistory,  useParams} from "react-router-dom";
import { courseOwnerValid } from "../../store/actions";
const CaseMethod = () =>
{
  const { token,  } = useSelector((state) => ({
    token: state.Login.token,
  
  }));
  const {courseId} = useParams()
  let dispatch = useDispatch()
  let history = useHistory()
   useEffect(() =>
  {
    dispatch(courseOwnerValid(token,courseId, history ))
  },[])
  return (
    <div>
      <div className="container-xl">
        <div>
          <div className="case-method-list">
            <ListHeader courseId={courseId} />
            <div className="px-5 pb-5 ">
              <div className="case-summary-content">
                <h4 className="mb-3">Case Summary</h4>
                <p>
                  The focus of this case is strongly practical. We shall start
                  off with a quiz (please see Initial Exer- cise) that will get
                  us started in calculating and interpreting the most
                  significant and common ratios (please refer to attached
                  glossary) from the three categories of ratios previously
                  mentioned: financial position, operating efficiency and
                  profitability.
                </p>
                <br />
                <p>
                  In this way, through the quiz, we will directly deal with the
                  real financial statements of a company in the hotel industry:
                  NH Hoteles. We will get started by looking at this company's
                  Balance Sheet and Profit and Loss accounts for the financial
                  years of 2007, 2008 and 2009 with the aim of calculating and
                  interpreting the evolution of different ratios over time. Once
                  we have completed the quiz, we will examine the financial
                  statements of two companies in the hotel industry (NH Hoteles
                  and Sol Meliá) for the fiscal years 2009-2010-2011 (Please,
                  see section Case Analysis).
                </p>
                <br />
                <p>
                  Our objective will be to identify the most relevant
                  characteristics of both companies during the period of
                  2009-2011 from an economic-financial perspective. We will also
                  make a comparative analysis with the aim of detecting the most
                  significant differences between both companies in terms of
                  solvency, efficiency and profitability. Finally, we will
                  compute two classic ratios specific to the industry (RevPAR
                  and Average Daily Rate) and examine some ratios used as
                  covenants in debt contracts (Please, see section Industry
                  Specific Ratios).
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseMethod;
