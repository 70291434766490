import React from "react";
import ReactApexChart from "react-apexcharts";

const Apaexlinecolumn = () => {
	const series = [
		{
			name: "Month 1",
			data: [
				parseInt(localStorage.getItem("monthOneProdARevenue")),
				parseInt(localStorage.getItem("monthOneProdBRevenue")),
			],
		},

		{
			name: "Month 2",
			data: [
				parseInt(localStorage.getItem("monthTwoProdARevenue")),
				parseInt(localStorage.getItem("monthTwoProdBRevenue")),
			],
		},
	];
	const options = {
		chart: {
			toolbar: {
				show: false,
			},
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: "25%",
				endingShape: "rounded",
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			show: true,
			width: 2,
			colors: ["transparent"],
		},

		colors: ["#A589DF", "#BDD1F9"],
		xaxis: {
			categories: ["Product 1", "Product 2"],
		},
		yaxis: {
			title: {
				// text: "$ (thousands)",
			},
		},
		grid: {
			borderColor: "#f1f1f1",
		},
		fill: {
			opacity: 1,
		},
		tooltip: {
			y: {
				formatter: function (val) {
					return "tk : " + val + " ";
				},
			},
		},
	};

	return (
		<ReactApexChart
			options={options}
			series={series}
			type="bar"
			height={250}
		/>
	);
};

export default Apaexlinecolumn;
