import React from "react";
import LafargeTrainingDetails from "../LafargeCommon/LafargeComponents/LafargeTrainingDetails";
import LafargeCourseWareHeader from "../LafargeCommon/LafargeHeader/LafargeCourseWareHeader";

const LafargeDetails = () => {
  return (
    <div>
      <LafargeCourseWareHeader />
      <LafargeTrainingDetails />
    </div>
  );
};

export default LafargeDetails;
