import React from "react";
import ReactApexChart from "react-apexcharts";

const dountchart = () => {
	const series = [parseInt(localStorage.getItem("totalMarketShare"))];
	const options = {
		labels: [" Market Share "],
		colors: ["#A589DF"],
		legend: {
			show: true,
			position: "bottom",
			horizontalAlign: "center",
			verticalAlign: "middle",
			floating: false,
			fontSize: "14px",
			offsetX: 0,
			offsetY: -10,
		},
		responsive: [
			{
				breakpoint: 600,
				options: {
					chart: {
						height: 240,
					},
					legend: {
						show: false,
					},
				},
			},
		],
	};

	return (
		<ReactApexChart
			options={options}
			series={series}
			type="radialBar"
			height="300"
		/>
	);
};

export default dountchart;
