import React from "react";
// import * as serviceWorker from "./serviceWorker";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./store";
const app = (
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));
// ReactDOM.render(
// 	<Provider store={store}>
// 		<BrowserRouter>
// 			<PersistGate loading={null} persistor={persistor}>
// 				<App />
// 			</PersistGate>
// 		</BrowserRouter>
// 	</Provider>,
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// ReactDOM.render(app, document.getElementById("root"));
reportWebVitals();
