import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { Container } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import fiancialImg from "../../assests/images/more.svg";
import { courseOwnerValid } from "../../store/actions";

const DragDropTwo = () => {
  const { token } = useSelector((state) => ({
    token: state.Login.token,
  }));
  const [total, setTotal] = useState(0);
  let history = useHistory();
  let { courseId } = useParams();
  let dispatch = useDispatch();
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalcost, setTotalCost] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  useEffect(() => {
    dispatch(courseOwnerValid(token, courseId, history));
  }, []);
  const SingleFinancialInfo = [
    {
      id: uuidv4(),
      content: "Revenue From Product A",
      price:
        parseInt(localStorage.getItem("monthTwoProdARevenue")) +
        parseInt(localStorage.getItem("monthOneProdARevenue")),
    },
    {
      id: uuidv4(),
      content: "Revenue From Product B",
      price:
        parseInt(localStorage.getItem("monthTwoProdBRevenue")) +
        parseInt(localStorage.getItem("monthOneProdBRevenue")),
    },

    {
      id: uuidv4(),
      content: "Hr Cost",
      price: parseInt(localStorage.getItem("totalOpex")) * 2,
    },
    {
      id: uuidv4(),
      content: "Production Cost Product A",
      price: parseInt(localStorage.getItem("prodOne")) + 1050,
    },
    {
      id: uuidv4(),
      content: "Production Cost Product B",
      price: parseInt(localStorage.getItem("prodTwo")) + 880,
    },
    {
      id: uuidv4(),
      content: "Cost of finance",
      price:
        parseInt(localStorage.getItem("financeCostMonthOne")) +
        parseInt(localStorage.getItem("financeCostMonthTwo")),
    },
    {
      id: uuidv4(),
      content: "Launch and marketing cost",
      price: 25,
    },
  ];
  const FinancialInfo = {
    0: {
      name: "",
      items: SingleFinancialInfo,
    },
    1: {
      name: "Revenue",
      items: [],
    },
    2: {
      name: "costs",
      items: [],
    },
  };

  const [costs, setCost] = useState(FinancialInfo);

  const onDragEnd = (result, costs, setCost) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      console.log("add Here");
      const sourceColumn = costs[source.droppableId];
      const destColumn = costs[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      // console.log("hello source", source.droppableId);
      // console.log("hello source", destination.droppableId);
      setCost({
        ...costs,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
      let totalRevenue =
        destination.droppableId == 1
          ? 0
          : localStorage.getItem("jpRevenue") !== null
          ? parseInt(localStorage.getItem("jpRevenue"))
          : 0;
      let totalCost =
        destination.droppableId == 2
          ? 1
          : localStorage.getItem("jpCost") !== null
          ? parseInt(localStorage.getItem("jpCost"))
          : 0;
      // let totalRevenue =0
      // let totalCost =0
      let totalAdd = 0;
      destItems.map((dest, index) => {
        console.log("hello dest new item", dest);
        if (destination.droppableId == 1) {
          totalAdd += dest.price;
        } else if (destination.droppableId == 2) {
          totalCost += dest.price;
        }
      });
      console.log("total Add here", totalAdd);
      localStorage.setItem("jpRevenue", totalRevenue);
      localStorage.setItem("jpCost", totalCost);
    } else {
      console.log("remove from here");
      const cost = costs[source.droppableId];
      const copiedItems = [...cost.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setCost({
        ...costs,
        [source.droppableId]: {
          ...cost,
          items: copiedItems,
        },
      });
    }
  };

  const handleGoNext = () => {
    localStorage.setItem("secretCost", 0);
    localStorage.setItem("secretRevenue", 0);
    localStorage.setItem("secretProfit", 0);
    history.push(`/${courseId}/financial-graph`);
  };
  return (
    <Container>
      <div className="recruitment-info">
        <h4>Financial Statement</h4>
        <h5>
          You need to make sure you sell 2 items ( A, B)from the day you launch
          Teaco in Bangladesh, and 1 items in Nepal. ( A). For each products to
          produce, cost will be: A: 2 BDT, B: 1 BDT. Now, you need to project
          how many items do you need to sell to gain 10% of the market share in
          Bangladesh.
        </h5>
      </div>
      <div
        // style={{
        //   display: "flex",
        //   justifyContent: "center",
        //   height: "100%",
        // }}
        className="main-drag-area-grid"
      >
        <DragDropContext
          onDragEnd={(result) => onDragEnd(result, costs, setCost)}
        >
          {Object.entries(costs).map(([costID, cost], index) => {
            let revenueData = 0;
            let costData = 0;
            let costName = cost.name;

            if (cost.name) {
              if (cost.name == "Revenue") {
                console.log("hello data", cost);
                cost.items.map((v, i) => {
                  console.log("hello revenue", v.price);
                  revenueData += v.price;
                });

                console.log("hello revenueData", revenueData);
                localStorage.setItem("secretRevenue", revenueData);
                localStorage.setItem("revenueData", revenueData);
              } else if (cost.name == "costs") {
                cost.items.map((v, i) => {
                  console.log("hello revenue", v.price);
                  costData += v.price;
                });
                localStorage.setItem("secretCost", costData);
                localStorage.setItem("costData", costData);
              }

              let secretProfit =
                parseInt(localStorage.getItem("secretRevenue")) -
                parseInt(localStorage.getItem("secretCost"));
              let totalProfit =
                parseInt(localStorage.getItem("revenueData")) -
                parseInt(localStorage.getItem("costData"));
              localStorage.setItem("secretProfit", secretProfit);
              console.log("total Profit", totalProfit);
              localStorage.setItem("netProfit", totalProfit);
            }

            return (
              <div key={costID} className="single-grid-item">
                <div className="drag-item-main-area">
                  <Droppable droppableId={costID} key={costID}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="financial-single-revenue-item  product-details-area"
                        >
                          <div>
                            <div className="revenue-item-table-header">
                              <h2>{cost.name}</h2>
                            </div>
                            {cost.name !== "" ? (
                              <div className="revenue-item-bottom-item">
                                <div className="revenue-item-bottom-single-item">
                                  <h5>
                                    {cost.name === "Revenue"
                                      ? "Total Revenue"
                                      : "Total Cost"}
                                  </h5>
                                </div>
                                <div className="revenue-item-bottom-single-item">
                                  <h4>
                                    {cost.name == "Revenue"
                                      ? localStorage.getItem("secretRevenue")
                                      : ""}
                                  </h4>
                                  <h4>
                                    {cost.name == "costs"
                                      ? localStorage.getItem("secretCost")
                                      : ""}
                                  </h4>
                                </div>
                              </div>
                            ) : (
                              <div className="revenue-item-bottom-item">
                                <div className="revenue-item-bottom-single-item">
                                  <h5></h5>
                                </div>
                                <div className="revenue-item-bottom-single-item">
                                  <h4></h4>
                                </div>
                              </div>
                            )}
                          </div>

                          {cost.items.map((item, index) => {
                            return (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      // className="financial-revenue-item left-item"
                                    >
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="financial-revenue-item left-item">
                                            <img src={fiancialImg} alt="" />
                                            <h4>{item.content}</h4>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
        </DragDropContext>

        <h3></h3>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <div className="total-net-profit">
            <div>
              {" "}
              <h5>Net Profit : {localStorage.getItem("secretProfit")}</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="case-summery-btn">
        <Link to={`/${courseId}/revenue`}>
          <div>
            <button className="case-summery-previous-btn">Previous</button>
          </div>
        </Link>
        {/* <Link to="/financial-graph"> */}
        <div>
          <button
            className="case-summery-next-btn"
            onClick={(e) => handleGoNext(e)}
          >
            Next
          </button>
        </div>
        {/* </Link> */}
      </div>
    </Container>
  );
};

export default DragDropTwo;
