import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import "../../../components/StudentPages/StudentHomePage/components/Home/assets/css/feature.css";
import {
  getAllCategoriesForALl,
  getAllCourses,
  getDataOnSearch,
  searchOnCategory,
  searchQuery,
} from "../../../store/actions";
import MainFooter from "../Common/footer/MainFooter";
import Header from "../Common/Header";
import LoginHeader from "../Common/LoginHeader";
import "./category.scss";
import CategoryAllDataList from "./CategoryAllDataList";
const CourseHomeCategory = () => {
  let [categoryIds, setCategoryIds] = useState([]);

  const { academicId } = useParams();
  let dispatch = useDispatch();
  let history = useHistory();

  const { token, loading, courses, categoris, searchText, searchedCourse } =
    useSelector((state) => ({
      token: state.Login.token,
      loading: state.courseReducer.loadign,
      courses: state.courseReducer.allCourses,
      searchedCourse: state.academicAreaReducer.courses,
      categoris: state.academicAreaReducer.categoris,
      searchText: state.courseReducer.searchQuery,
    }));

  const handleSearchByText = (e) => {
    e.preventDefault();
    console.log("hello bd", e.target.value);
    // dispatch(searchQuery(e.target.value))
    if (!e.target.value) {
      searchAllCourse();
    } else {
      dispatch(searchQuery(e.target.value));
    }
  };
  useEffect(() => {
    searchAllCourse();
    dispatch(getAllCategoriesForALl());
  }, []);

  const searchAllCourse = () => {
    dispatch(getAllCourses());
    dispatch(searchQuery(""));
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      dispatch(getDataOnSearch(searchText));
    }, 400);
    return () => clearTimeout(delay);
  }, [searchText]);

  const handleStoreCategoryIds = (e, id) => {
    let category = [];
    // console.log("length of ids", categoryIds.length)
    let serial = categoryIds.length;
    // category.push({ ...categoryIds, [serial]: id })
    let newArr = [];
    let unique = [];

    let found = false;
    if (categoryIds.length > 0) {
      found = categoryIds.find((cat) => {
        return cat.index == e.target.id;
      });
    }
    if (found?.index) {
      newArr = categoryIds;
      setCategoryIds(categoryIds.filter((cat) => cat.index !== e.target.id));
      newArr = newArr.filter((cat) => cat.index !== e.target.id);
    } else {
      newArr = categoryIds;
      // newArr.push({ index: e.target.id, ids: e.target.value });
      categoryIds.push({ index: e.target.id, ids: e.target.value });
    }

    console.log("ARr ids data", newArr);
    let catIds = [];
    newArr.map((v, i) => {
      catIds.push(v.ids);
    });
    console.log("category id only");
    if (catIds.length > 0) {
      dispatch(searchQuery(""));
      dispatch(searchOnCategory(catIds));
    } else {
      searchAllCourse();
    }
    // dispatch(getDataOnSearch({ academicArea: academicId, category: category }));
  };

  return (
    <div>
      <MetaTags>
        <title>Course | GameProject</title>
      </MetaTags>
      {token ? <LoginHeader /> : <Header />}
      <CategoryAllDataList
        courses={searchText ? searchedCourse : courses}
        categoris={categoris}
        loading={loading}
        academicId={academicId}
        handleSearchByText={handleSearchByText}
        searchedCourse={searchedCourse}
        handleStoreCategoryIds={handleStoreCategoryIds}
      />
      <MainFooter />
    </div>
  );
};

export default CourseHomeCategory;
