import React,{useState, } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { options } from "./Data";
import { useDispatch, useSelector } from "react-redux";
import { courseCreate,courseRelatedPreviousMultiData , uploadCoursePhoto,  } from "../../../../store/actions";
import { toast } from 'react-toastify';
import { Spinner } from "reactstrap";
import { Link } from 'react-router-dom';

const AllNewCourseEditBody = ({
  courseTitle,
  courseCode, handleSetCourseName,
  handleCourseCode, noOfPages,
  handleSetNoOfPages, coursePrice,
  handleSetCoursePrice,
  // courseAcademicId,
  // handleSetAcademicIds, courseRelatedData,
  description,
  handleSetDescription,
  // courseFormatId,
  // handleSetCourseFormatIds,
  handleSetEstimatedTime,
  estimatedTime,
  courseLanguage, 
  handleCourseLanguage,
  // courseAuthorId,
  subjectCoverd,
  handleSetSubjectCoverd,
  duration,
  handleSetDuration,
  coursePhoto,
  loading,
  token, typeOfPublication, handleSetTypeOfPublications,
  photo,
  handleUploadAuthorImage,
  handleSubmitData
}) =>
{
  // let loading = false

  let dispatch  = useDispatch()
    // let [typeOfPublication, setTypeOfPublication] = useState([]);
  let [courseSummary, setCourseSummary] = useState("");
 
  // let [coursePhoto, setCoursePhoto] = useState("");
  // let [duration, setDuration] = useState("");
 
   
    //set course author id
    
    //course academic id set
 
    //type of publications
 
   
   
    const handleUploadImage = e =>
    {
      e.preventDefault()
      let formData = new FormData()
      formData.append("photo", e.target.files[0])
      // dispatch(uploadCoursePhoto(token, formData))

    }
  return (
    <div>
    <div className="packge-header">
      <div className="packge-header-left">
        <div className="title">
          <h3>Update Course</h3>
          <p>
            Course list {`>`} <Link to={"/course"}>course </Link> {`>`} <span>Update Course</span>{" "}
          </p>
        </div>
      </div>
    </div>

    <div className="package-main-content-list new-main-area">
      <div className="new-individual-course-inner-wrap">
        <div className="new-individual-course-title">
          <h4>Update Course</h4>
        </div>
        <Form>
          <Row>
            <Col lg={6}>
              <Row className="g-0">
                <Col lg={12}>
                  <div className="input-data-single-field">
                    <Form.Group className="mb-3">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Type course name"
                        name="categoryName"
                          value={courseTitle.title}
                          onChange={e => handleSetCourseName(e)}
                       />
                    </Form.Group>
                  </div>
                </Col>{" "}
                <Col lg={12}>
                  <div className="input-data-single-field">
                    <Form.Group className="mb-3">
                      <Form.Label>No Of Pages</Form.Label>
                      <Form.Control
                        type="Number"
                        placeholder="Enter no of pages"
                        name="noOfPages"
                          value={noOfPages.pages}
                          onChange={e => handleSetNoOfPages(e)}
                       />
                    </Form.Group>
                  </div>
                </Col>{" "}
                <Col lg={12}>
                  <div className="input-data-single-field">
                    <Form.Group className="mb-3">
                      <Form.Label>Course Price</Form.Label>
                      <Form.Control
                        type="Number"
                        placeholder="Enter Course Price"
                        name="categoryName"
                          value={coursePrice.price}
                          onChange={handleSetCoursePrice}
                       />
                    </Form.Group>
                  </div>
                </Col>
                {/* <Col lg={12}>
                  <div className="input-data-single-field">
                    <Form.Label>Course Academic Area</Form.Label>
                    <Select
                      value={courseAcademicId.obj}
                      isMulti
                      onChange={handleSetAcademicIds}
                      options={courseRelatedData?.courseAcademicArr}
                    />
                  </div>
                </Col>{" "} */}
              </Row>
            </Col>{" "}
            <Col lg={6}>
              <Row className="g-0">
                <Col lg={12}>
                  <div className="input-data-single-field">
                    <Form.Group className="mb-3">
                      <Form.Label>Course Code</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Type Course Code"
                        name="categoryName"
                        value={courseCode.code}
                        onChange={(e) => handleCourseCode(e)}
                      />
                    </Form.Group>
                  </div>
                </Col>{" "}
                {/* <Col lg={12}>
                  <div className="input-data-single-field">
                    <Form.Group className="mb-3">
                      <Form.Label>Course Format</Form.Label>
                      <Select
                        value={courseFormatId.obj}
                        isMulti
                        onChange={handleSetCourseFormatIds}
                        options={courseRelatedData?.courseFormatArr}
                      />
                    </Form.Group>
                  </div>
                </Col>{" "} */}
                <Col lg={12}>
                  <div className="input-data-single-field">
                    <Form.Group className="mb-3">
                      <Form.Label>Estimated Time</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter estimated time"
                        name="category"
                        value={estimatedTime?.estimatedTime}
                        onChange={(e) =>handleSetEstimatedTime(e)}
                      />
                    </Form.Group>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="input-data-single-field">
                    <Form.Label>Type Of Publication</Form.Label>
                    <Select
                      value={typeOfPublication?.obj}
                      onChange={handleSetTypeOfPublications}
                      options={options}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col lg={12}>
              <div className="input-data-single-field">
                <Form.Group
                  className="mb-3 mt-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Course Summary</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter text here..."
                    value={description.description}
                    onChange={(e) => handleSetDescription(e)}
                  />
                </Form.Group>
              </div>
            </Col>
            <Col lg={6}>
              <div className="input-data-single-field">
                <Form.Group className="mb-3">
                  <Form.Label>Course Language</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Type course language"
                    name="category"
                    value={courseLanguage.language}
                    onChange={(e) => handleCourseLanguage(e)}
                  />
                </Form.Group>
              </div>
            </Col>
            {/* <Col lg={6}>
              <div className="input-data-single-field">
                <Form.Group className="mb-3">
                  <Form.Label>Author </Form.Label>
                  <Select
                    value={courseAuthorId.obj}
                    options={courseRelatedData?.authorArr}
                    onChange={hanldeStoreCourseAuthorId}
                  />
                </Form.Group>
              </div>
            </Col> */}
            <Col lg={6}>
              <div className="input-data-single-field">
                <Form.Group className="mb-3">
                  <Form.Label>Subject Covered</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="List of topics covered"
                    name="author"
                    value={subjectCoverd.subjectCoverd}
                    onChange={(e) => handleSetSubjectCoverd(e)}
                  />
                </Form.Group>
              </div>
            </Col>
            {/* Course validation */}
            <Col lg={6}>
              <div className="input-data-single-field">
                <Form.Group className="mb-3">
                  <Form.Label>Course Validation</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Course validation in days"
                    name="validation For"
                    value={duration.duration}
                    onChange={(e) => handleSetDuration(e)}
                  />
                </Form.Group>
                </div>
             </Col>
            <Col lg={6}>
              <div className="input-data-single-field">
                <Form.Group className="mb-3">
                  <Form.Label>Upload Course image</Form.Label>
                   
                       <img className="Course" src={`${process.env.REACT_APP_IMAGE_URL}${coursePhoto ? coursePhoto : photo?.photo}`} height={"70px"} />

                     
                  <Form.Control
                    type="file"
                    placeholder="Type category name"
                    name="coursePhoto"
                    onChange={(e) =>handleUploadAuthorImage(e)}
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>
          {loading ? (
            <Spinner />
          ) : (
            <div className="form-submit-button-area-wrap">
              <button
                type="submit"
                className="btn create-new-item"
                onClick={(e) => handleSubmitData(e)}
              >
                Create New
              </button>
              {/* <button type="submit" className="btn save-to-new-item-draft">
                Save as Draft
              </button> */}
            </div>
          )}
        </Form>
      </div>
    </div>
  </div>
  );
  }
  export default AllNewCourseEditBody;