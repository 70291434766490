import React from "react";

const CloseIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 20.94L20.94 12"
        stroke="#070707"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M20.94 20.94L12 12"
        stroke="#070707"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <rect
        x="0.25"
        y="0.25"
        width="32.44"
        height="32.44"
        rx="16.22"
        stroke="#070707"
        strokeWidth="0.5"
      />
    </svg>
  );
};

export default CloseIcon;
