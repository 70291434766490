import { toast } from "react-toastify";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  axiosCourseOwnerValid,
  axiosCreateOrder,
  axiosGetAllCourses,
  axiosGetRankingBasedOnCourse,
  axiosGetRankingBasedOnCourseByTeacher,
  axiosGetSixCourses,
  axiosGetStudentOwnCourse,
  axiosInsertStudentIntoCourse,
  axiosRemoveStudentFromCourse,
  axiosSearchOnCategory,
  axiosShowResult,
  axiosSubmitMark,
  axiosViewStudents,
} from "../../../helpers/api_helper";
import { cleanDataPlayAgainSuccess, loginSuccess } from "../../actions";
import {
  courseOwnerValidFail,
  courseOwnerValidSuccess,
  getAllCoursesSuccess,
  getRankingBasedOnCourseByTeacherSuccess,
  getRankingBasedOnCourseSuccess,
  getSixCoursesForStudentsSuccess,
  getStudentOwnCourseSuccess,
  insertStudentsIntoCourseSuccess,
  isnertStudentsIntoCourseFail,
  removeStudentsFromCourseFail,
  removeStudetnsFromCourseSuccess,
  searchOnCategorySuccess,
  showResultSuccess,
  submitMarkFail,
  submitMarkSuccess,
  viewStudentsSuccess,
} from "./actions";
// import { cleanDataPlayAgainSuccess } from './../../recruitment/actions';
import {
  COURSE_OWNER_VALID,
  GET_ALL_COURSES_STD,
  GET_RANKING_BASED_ON_COURSE,
  GET_RANKING_BASED_ON_COURSE_BY_TEACHER,
  GET_SIX_COURSES_FOR_STUDENTS,
  GET_STUDENT_OWN_COURSE,
  INSERT_STUDENT_INTO_COURSE,
  ORDER_COURSE,
  REMOVE_STUDENTS_FORM_COURSE,
  SEARCH_ON_CATEGORY,
  SHOW_RESULT,
  SUBMIT_MARK,
  VIEW_STUDENTS,
} from "./actionTypes";

//workerGetCourseAcademicArea
const asyncGetSixCourseForStudents = async () => {
  try {
    const response = await axiosGetSixCourses();
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
function* workerGetSixCourseForStudents() {
  try {
    const response = yield call(asyncGetSixCourseForStudents);
    // ////console.log("response data", response)
    yield put(yield put(getSixCoursesForStudentsSuccess(response.getData)));
  } catch (error) {}
}

//create order
const asyncCreateOrder = async (
  course,
  price,
  totalPrice,
  email,
  mainPrice,
  discount,
  token
) => {
  try {
    ////console.log("async email", email)
    const response = await axiosCreateOrder(
      course,
      price,
      totalPrice,
      email,
      mainPrice,
      discount,
      token
    );
    return response.data;
  } catch (error) {}
};
function* workerOrderCourse({
  payload: {
    course,
    price,
    totalPrice,
    token,
    email,
    mainPrice,
    discount,
    history,
  },
}) {
  try {
    if (email) {
      // localStorage.removeItem("gift")
    } else {
      // localStorage.removeItem("cart")
      // localStorage.removeItem("gift")
    }

    // ////console.log("call from here ",email)
    // window.location.href= "https://www.facebook.com"
    const response = yield call(
      asyncCreateOrder,
      course,
      price,
      totalPrice,
      email,
      mainPrice,
      discount,
      token
    );
    // ////console.log("resposne data", response)

    // history.push(response.link)
    // window.location.replace(response.link)
    window.location.href = response.link;

    // ////console.log("response here", )
  } catch (error) {}
}

//get all coruses
const asyncGetCourses = async () => {
  try {
    const response = await axiosGetAllCourses();
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
function* workerGetAllCourses() {
  try {
    const response = yield call(asyncGetCourses);
    // ////console.log("hello response", response)
    console.log("response here on coruses", response);
    yield put(getAllCoursesSuccess(response.getData));
  } catch (error) {
    // ////console.log("hello error", error)
  }
}

//get studetn own course
const asyncGetStudentOwnCourse = async (token) => {
  try {
    const response = await axiosGetStudentOwnCourse(token);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
function* workerGetStudentOwnCourse({ payload: { token } }) {
  try {
    const response = yield call(asyncGetStudentOwnCourse, token);
    console.log("response data here", response);
    ////console.log("hello data", response.getData)
    yield put(getStudentOwnCourseSuccess(response.getData));
  } catch (error) {}
}

const asyncSubmitMark = async (token, courseId, mark, orderId, history) => {
  try {
    const response = await axiosSubmitMark(
      token,
      courseId,
      mark,
      orderId,
      history
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

function* workerSubmitMark({
  payload: { token, courseId, mark, orderId, history },
}) {
  try {
    const response = yield call(
      asyncSubmitMark,
      token,
      courseId,
      mark,
      orderId,
      history
    );
    ////console.log("resposne mark", response.message)
    if (response.status == "success") {
      history.push(`/${courseId}/${response.slug}/result`);
      yield put(submitMarkSuccess(response.message));
      toast.success(response.message);
      let data = JSON.parse(localStorage.getItem("persist:primary"));
      data = JSON.parse(data.Login);
      let userName = data.username;
      let token = data.token;
      let userrole = data.userrole;
      let email = data.email;
      console.log("data here", data, userName, token, userrole, email);
      localStorage.clear();

      yield put(loginSuccess(userName, token, email, userrole));
      yield put(cleanDataPlayAgainSuccess());

      yield put(submitMarkSuccess());
    } else {
      yield put(submitMarkFail(response.message));
      toast.error(response.message);
      history.push(`/${courseId}/${response.slug}/result`);
      yield put(submitMarkFail());
    }
  } catch (error) {}
}

const asyncShowResult = async (token, courseId) => {
  try {
    const response = await axiosShowResult(token, courseId);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
function* workerShowResult({ payload: { token, courseId } }) {
  try {
    ////console.log("token dat", token)
    const response = yield call(asyncShowResult, token, courseId);
    ////console.log('hello data', response.getData)
    yield put(showResultSuccess(response.getData));
  } catch (error) {}
}

//workerCourseOwnerValid
const asyncCourseOwnerValid = async (token, courseId) => {
  try {
    const response = await axiosCourseOwnerValid(token, courseId);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
function* workerCourseOwnerValid({ payload: { token, courseId, history } }) {
  try {
    const response = yield call(asyncCourseOwnerValid, token, courseId);
    ////console.log("232 line res", response)
    if (response.status == "success") {
      yield put(courseOwnerValidSuccess(response));
    } else {
      yield put(courseOwnerValidFail(axiosCourseOwnerValid));
      history.push(`/course-details-page/${courseId}`);
    }
  } catch (error) {}
}

//get ranking based on course
const asyncGetRankingBasedOnCourse = async (token, role, courseId) => {
  try {
    const response = await axiosGetRankingBasedOnCourse(token, role, courseId);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
function* workerGetRankingBasedOnCourse({
  payload: { token, role, courseId },
}) {
  try {
    const response = yield call(
      asyncGetRankingBasedOnCourse,
      token,
      role,
      courseId
    );
    ////console.log("response here", response)
    yield put(getRankingBasedOnCourseSuccess(response.getData));
  } catch (error) {
    ////console.log("error here", error)
  }
}

//view studetns
const asyncViewStudents = async (token, courseId) => {
  try {
    const response = await axiosViewStudents(token, courseId);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
function* workerViewStudents({ payload: { token, courseId } }) {
  // const response
  try {
    const response = yield call(asyncViewStudents, token, courseId);
    ////console.log("response data", response.getData)
    yield put(viewStudentsSuccess(response.getData));
  } catch (error) {}
}

//insert student into course
const asyncInsertStudentIntoCourse = async (token, courseId, studentEmail) => {
  try {
    const response = await axiosInsertStudentIntoCourse(
      token,
      courseId,
      studentEmail
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
function* workerInsertStudentIntoCourse({
  payload: { token, courseId, studentEmail },
}) {
  try {
    const response = yield call(
      asyncInsertStudentIntoCourse,
      token,
      courseId,
      studentEmail
    );
    ////console.log("response data", response.message)
    if (response.status == "success") {
      ////console.log("add")

      yield put(insertStudentsIntoCourseSuccess(response.message));
      toast.success(response.message);
      const res = yield call(asyncViewStudents, token, courseId);
      yield put(viewStudentsSuccess(res.getData));
      yield put(insertStudentsIntoCourseSuccess());
    } else {
      yield put(isnertStudentsIntoCourseFail(response.message));
      toast.error(response.message);
      yield put(isnertStudentsIntoCourseFail());
    }
  } catch (error) {}
}

//get ranking based on course by teacher
const asyncGetRankingBasedOnCourseByTeacher = async (token, courseId) => {
  try {
    const response = await axiosGetRankingBasedOnCourseByTeacher(
      token,
      courseId
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
function* workerGetRankingBasedOnCourseByTeacher({
  payload: { token, courseId },
}) {
  try {
    const response = yield call(
      asyncGetRankingBasedOnCourseByTeacher,
      token,
      courseId
    );
    ////console.log("response here", response)
    yield put(getRankingBasedOnCourseByTeacherSuccess(response.getData));
  } catch (error) {
    ////console.log("error here", error)
  }
}

//search on category
const asyncSearchOnCategory = async (categoryId) => {
  try {
    const response = await axiosSearchOnCategory(categoryId);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
function* workerSearchOnCategory({ payload: { categoryIds } }) {
  try {
    const response = yield call(asyncSearchOnCategory, categoryIds);
    console.log("resposne data here", response.getData);
    yield put(searchOnCategorySuccess(response.getData));
  } catch (error) {
    console.log("error result", error);
  }
}

//remove student from course
const asyncRemoveStudentFromCourse = async (token, studentId, courseId) => {
  try {
    console.log("call from here 369", token);
    const response = await axiosRemoveStudentFromCourse(
      token,
      courseId,
      studentId
    );
    console.log("response here", response);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};
function* workerRemoveStudentFromCourse({
  payload: { token, studentId, courseId },
}) {
  try {
    console.log("call from 378 line");
    const response = yield call(
      asyncRemoveStudentFromCourse,
      token,
      studentId,
      courseId
    );
    if (response.status == "success") {
      yield put(removeStudetnsFromCourseSuccess(response.message));
      toast.success(response.message);
      yield put(removeStudetnsFromCourseSuccess());
      const res = yield call(asyncViewStudents, token, courseId);
      yield put(viewStudentsSuccess(res.getData));
    } else {
      yield put(removeStudentsFromCourseFail(response.message));
      toast.error(response.message);

      yield put(removeStudentsFromCourseFail(response.message));
    }
  } catch (error) {}
}
function* courseSaga() {
  yield takeEvery(GET_SIX_COURSES_FOR_STUDENTS, workerGetSixCourseForStudents);
  yield takeEvery(ORDER_COURSE, workerOrderCourse);
  yield takeEvery(GET_ALL_COURSES_STD, workerGetAllCourses);
  yield takeEvery(GET_STUDENT_OWN_COURSE, workerGetStudentOwnCourse);
  yield takeEvery(SUBMIT_MARK, workerSubmitMark);
  yield takeEvery(SHOW_RESULT, workerShowResult);
  yield takeEvery(COURSE_OWNER_VALID, workerCourseOwnerValid);
  yield takeEvery(GET_RANKING_BASED_ON_COURSE, workerGetRankingBasedOnCourse);
  yield takeEvery(VIEW_STUDENTS, workerViewStudents);
  yield takeEvery(INSERT_STUDENT_INTO_COURSE, workerInsertStudentIntoCourse);
  yield takeEvery(
    GET_RANKING_BASED_ON_COURSE_BY_TEACHER,
    workerGetRankingBasedOnCourseByTeacher
  );
  yield takeEvery(SEARCH_ON_CATEGORY, workerSearchOnCategory);
  yield takeEvery(REMOVE_STUDENTS_FORM_COURSE, workerRemoveStudentFromCourse);
}

export default courseSaga;
