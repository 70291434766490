import LafargeQuesItems from "../LafargeCommon/LafargeComponents/LafargeQuesItems";
import LafargeCourseWareHeader from "../LafargeCommon/LafargeHeader/LafargeCourseWareHeader";

const LafargeQuesPage = () => {
  return (
    <div>
      <LafargeCourseWareHeader />
      <LafargeQuesItems />
    </div>
  );
};

export default LafargeQuesPage;
