import {
  GET_LAFARGE_COURSE,
  GET_LAFARGE_COURSE_SUCCESS,
  GET_STUDENT_ANSWER,
  GET_STUDENT_ANSWER_SUCCESS,
  GET_TOTAL_USER,
  GET_TOTAL_USER_SUCCESS,
  GET_USER,
  GET_USER_SUCCESS,
  STORE_TOTAL_MARKS_DATA,
  UPDATE_TOTAL_MARKS,
  UPDATE_TOTAL_MARKS_FAIL,
  UPDATE_TOTAL_MARKS_SUCCESS,
} from "./actionTypes";

export const getLafargeCourse = (token) => {
  return {
    type: GET_LAFARGE_COURSE,
    payload: { token },
  };
};
export const getLafargeCourseSuccess = (data) => {
  return {
    type: GET_LAFARGE_COURSE_SUCCESS,
    payload: { data },
  };
};

export const getUser = (token, skip) => {
  return {
    type: GET_USER,
    payload: { token, skip },
  };
};
export const getUserSuccess = (data) => {
  return {
    type: GET_USER_SUCCESS,
    payload: { data },
  };
};

export const getTotalUser = (token) => {
  return {
    type: GET_TOTAL_USER,
    payload: { token },
  };
};
export const getTotalUserSuccess = (data) => {
  return {
    type: GET_TOTAL_USER_SUCCESS,
    payload: { data },
  };
};

export const getStudentAnswer = (token, studentId) => {
  return {
    type: GET_STUDENT_ANSWER,
    payload: { token, studentId },
  };
};
export const getStudentAnswerSuccess = (data) => {
  return {
    type: GET_STUDENT_ANSWER_SUCCESS,
    payload: { data },
  };
};

export const updateTotalMarks = (token, examId, marks, history, skip) => {
  return {
    type: UPDATE_TOTAL_MARKS,
    payload: { token, examId, marks, history, skip },
  };
};
export const updateTotalMarksSuccess = (message) => {
  return {
    type: UPDATE_TOTAL_MARKS_SUCCESS,
    payload: { message },
  };
};
export const updateTotalMarksFail = (error) => {
  return {
    type: UPDATE_TOTAL_MARKS_FAIL,
    payload: { error },
  };
};

export const storeTotalMarksData = (name, data, id) => ({
  type: STORE_TOTAL_MARKS_DATA,
  payload: { name, data, id },
});
