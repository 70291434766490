import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import lafargeMainLOgo from "../../LafargeAssets/images/hero-1.jpg";
const LafargeHero = () => {
  const { courseId } = useParams();
  return (
    <div>
      <div
        className="lafarge-hero-main-wrap"
        style={{
          background: `url(${lafargeMainLOgo})`,
        }}
      >
        <Container>
          <Row>
            <Col md={"12"}>
              <div className="lafarge-hero-left">
                <h3>
                  Negotiation : Technique to win <br /> against odds
                </h3>
                <p>
                  When you work in sales, developing your negotiation skills and
                  strategies is, well, non-negotiable. If you want to win more
                  deals, you need to approach every sales negotiation knowing
                  how to prepare and what a win-win outcome should look like.{" "}
                  <br />
                  In the workshop, ‘Negotiation: Technique to win against odds’
                  you have (re) learnt the fundamentals of negotiation
                  specifically catered to LafargeHolcim. Tackling your
                  real-world negotiations will be much easier now. To boost up;
                  certify yourself as a top-notch negotiator
                </p>
                <Link to={`/${courseId}/lafarge-home`}>Enter</Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default LafargeHero;
