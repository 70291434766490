import React, { useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const CategoryAllDataList2 = ({ courses }) => {
  let [categoryIds, setCategoryIds] = useState([]);
  let dispatch = useDispatch();
  const hanldeDuplicateRemoveAndCall = (id) => {};
  const handleDirectCall = (id) => {};
  //searching algorithm
  //Part 1
  //if categoryIds.length <1
  //store id into categoryIds
  //part 2:
  //if categoryId>1:
  //inside 1:
  //global array
  //  map(categoryId)=>categoryIdEach
  // if(categoryIdEach == searchItem)
  // return null
  //else:
  //add categoryIdEach into global array
  //
  const handleStoreCategoryIds = (e, id) => {
    console.log("hello data", typeof e.target.id);
    let category = [];
    // console.log("length of ids", categoryIds.length)
    let serial = categoryIds.length;
    // category.push({ ...categoryIds, [serial]: id })
    let newArr = [];
    let unique = [];

    let found = false;
    if (categoryIds.length > 0) {
      found = categoryIds.find((cat) => {
        console.log("hello cat", cat.index, e.target.id);
        return cat.index == e.target.id;
      });
      console.log("found data", found);
    }
    console.log("found or not ? ", found);
    if (found?.index) {
      console.log("okay found");
      newArr = categoryIds;
      setCategoryIds(categoryIds.filter((cat) => cat.index !== e.target.id));
      newArr = newArr.filter((cat) => cat.index !== e.target.id);
    } else {
      console.log("found nai kehan theke");
      newArr = categoryIds;
      // newArr.push({ index: e.target.id, ids: e.target.value });
      categoryIds.push({ index: e.target.id, ids: e.target.value });
    }

    console.log("category ids data", categoryIds);
    console.log("ARr ids data", newArr);
    //     if (!categoryIds.length) {
    //       // console.log("not found")

    //       setCategoryIds([...categoryIds, id])
    //       newArr.push(id)
    //     } else {
    //        let arr =[]
    //       categoryIds.map((v, i) =>
    //       {

    //        if (v == id) {
    //         //  console.log("found")

    //          setCategoryIds(categoryIds.filter(item=> item !== id))
    //         //  console.log("63 line data", v,id)
    //           category = category[0][i]
    //         //  newArr = newArr.filter(data => data !== id)
    //          return category
    //        } else {
    //         //  console.log("not found 2" , i , ": ", categoryIds)

    //         // console.log("category type of", typeof category[0], category[0])

    //         setCategoryIds([...categoryIds, id])
    //          newArr.push(v)
    //          if(i ==0)
    //         newArr.push(id)
    //         //   unique = _.uniqWith(categoryIds, _.isEqual);

    //         //  unique.push(v)
    // }
    //       })

    console.log("category data 56", category);

    // }

    // dispatch(getDataOnSearch({ academicArea: academicId, category: category }));

    //already existed{
    //how to remove?
    //category.[0].remove({})
    //
  };

  const courseCart = (course) => {
    return (
      <>
        <Row>
          {course.map((v, i) => (
            <Col lg={4}>
              <div
                className="business-clases-single-item-wrap"
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}${v?.course?.image})`,
                }}
              >
                <div className="business-clases-main-part">
                  <div className="business-clases-main-part-top">
                    <h3>{v?.course?.courseName}</h3>
                    <i>{v?.course?.courseCode}</i>
                  </div>
                  <div className="business-clases-main-part-bottom">
                    {v?.course?.academicArea?.map((academic, ind) => (
                      <i>
                        {academic?.academicName}{" "}
                        {v?.course?.academicArea?.[ind + 1] ? " | " : ""}
                      </i>
                    ))}
                    <h4>{v?.course?.courseName}</h4>
                    <div className="business-clases-main-part-bottom-inner">
                      <div className="business-clases-main-part-bottom-inner-left">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}${v?.course.category[0]?.image}`}
                          alt=""
                        />
                      </div>{" "}
                      <div className="business-clases-main-part-bottom-inner-left">
                        <h6>
                          <span>Format: </span>{" "}
                          {v?.course?.category?.map((category, ind) => (
                            <i>
                              {category?.categoryName}{" "}
                              {v?.course?.category?.[ind + 1] ? " | " : ""}
                            </i>
                          ))}
                        </h6>{" "}
                        <h6>
                          <span>Language: </span> English
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="business-clases-hover-part">
                  <div className="">
                    <p>
                      {v?.course?.description &&
                        v?.course?.description?.substring(0, 100)}
                      ...
                    </p>
                    <div className="business-clases-hover-part-bottom-link">
                      <Link to={`/course-details-page/${v?.course?._id}`}>
                        <span>+</span>
                        <span>Read More</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </>
    );
  };
  const courseCartGrid = (course) => {
    return (
      <>
        <Table
          borderless
          className="list-course-view-data-table-main-area-wrap"
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Course Code</th>
              <th>Academic Area</th>
              <th>Format</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {course.map((v, i) => (
              <tr>
                <td>
                  <div className="list-course-view-data-table-jdhbbd">
                    <img
                      src={`${process.env.REACT_APP_IMAGE_URL}${v?.course?.image}`}
                      alt=""
                    />
                    <h4>{v?.course?.courseName}</h4>
                  </div>
                </td>
                <td>
                  <p>{v?.course?.code}</p>
                </td>
                <td>
                  <p>
                    {v?.course?.academicArea?.map((academic, ind) => (
                      <>
                        {academic?.academicName}{" "}
                        {v?.course?.academicArea?.[ind + 1] ? " | " : ""}
                      </>
                    ))}
                  </p>
                </td>
                <td>
                  {" "}
                  <p>
                    <span></span>{" "}
                    <>
                      {" "}
                      {v?.course?.category?.map((category, ind) => (
                        <>
                          {category?.categoryName}{" "}
                          {v?.course?.category?.[ind + 1] ? " | " : ""}
                        </>
                      ))}
                    </>
                  </p>
                </td>
                <td>
                  <div className="list-course-view-data-table-jdhbbd">
                    <Link to={`/course-details-page/${v?.course?._id}`}>
                      <span>View</span>
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  };
  return (
    <div>
      <Container>
        <div className="category-inner-area-main-wrap courseware-common-tabs">
          <Row>
            <Col lg={12}>
              <div className="search-course-main-area-wrap">
                <div className="course-filtering-area-wrap">
                  <Tabs>
                    <TabList className="tab-list-grid-course-data tab-bar-icon-box">
                      <Tab>
                        {" "}
                        <i className="bx bx-grid-alt"></i>
                        {/* <img src={fIcon1} alt="" /> */}
                      </Tab>
                      <Tab>
                        <i className="bx bx-menu"></i>
                        {/* <img src={fIcon2} alt="" /> */}
                      </Tab>
                    </TabList>

                    <TabPanel>{courseCart(courses)}</TabPanel>
                    <TabPanel>{courseCartGrid(courses)}</TabPanel>
                  </Tabs>
                </div>
              </div>

              {/* <div className="business-cases-bottom-button-area">
                <a href="/">See More</a>
              </div> */}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default CategoryAllDataList2;
