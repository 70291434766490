import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Loader from "../../components/Common/Loader";
import AudioQues from "../../components/Retail/AudioQues";
import DragAndDropQuiz from "../../components/Retail/DragAndDropQuiz";
import McqQuiz from "../../components/Retail/McqQuiz";
import { getStudentQues } from "../../store/RetailGame/Student/actions";
import "./retail.scss";
const RetailGame = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { id, courseName } = useParams();
  console.log("id", id);
  const {
    token,
    studentQues,
    mcqQuesNum,
    currentIndex,
    typeArray,
    currentType,
    studentQuesLoading,
  } = useSelector((state) => ({
    token: state.Login.token,
    studentQues: state.studentGame.studentQues,
    mcqQuesNum: state.studentGame.mcqQuesNum,
    currentIndex: state.studentGame.currentIndex,
    typeArray: state.studentGame.typeArray,
    currentType: state.studentGame.currentType,
    studentQuesLoading: state.studentGame.studentQuesLoading,
  }));
  useEffect(() => {
    if (typeArray.length === 0) {
      history.push(`/game-information/${id}/${courseName}`);
    }
  }, [history, typeArray]);
  console.log("typeArray", typeArray, currentIndex);
  console.log(
    "🚀 ~ file: RetailGame.js:32 ~ RetailGame ~ currentType:",
    currentType
  );
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      alert("hello");
      console.log("ajsbzkdbjs");
      // Cancel the default beforeunload behavior
      event.preventDefault();

      // Redirect to the desired page

      history.push("/game-information");
    };
    console.log("ajsbzkdbjs");

    // Attach the event listener when the component mounts
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [history]);
  useEffect(() => {
    console.log("id", id);
    if (id) {
      dispatch(
        getStudentQues(
          token,
          id,
          // data?.questionNumberAndSerial?.[0]?.examType
          typeArray[currentIndex]
        )
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  const getQuestion = () => {
    switch (currentType) {
      case "mcq":
        return <McqQuiz studentQues={studentQues} />;
      case "drugdrop":
        return <DragAndDropQuiz />;
      case "grid":
        return <AudioQues studentQues={studentQues} />;
      default:
        return null;
    }
  };

  return <>{studentQuesLoading ? <Loader /> : <>{getQuestion()}</>}</>;
};

export default RetailGame;
