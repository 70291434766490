import React,{useEffect} from "react";
import { Container } from "react-bootstrap";
import MainFooter from "../StudentPages/Common/footer/MainFooter";
import Header from "../StudentPages/Common/Header";
import LoginHeader from "../StudentPages/Common/LoginHeader";
import background from "./img/bgImage.png";
import { Link, useHistory, useParams ,useLocation} from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { getSingleCourseOfValidUser,getSingleCourse } from "../../store/actions";
const GameStart = () =>
{

  let history = useHistory()
  const dispatch = useDispatch()
  let location = useLocation()
  const courseName = new URLSearchParams(location.search).get("courseName");

  const { token, course,   } = useSelector((state) => ({
    token: state.Login.token,
    course: state.AdminAuthorReducer.course,
    
  }));

  const { courseId } = useParams();
  useEffect(() => {
    if (token) {
      dispatch(getSingleCourseOfValidUser(token, courseId));
    } else {
      dispatch(getSingleCourse(courseId));
    }
  }, []);
  return (
    <div>
      <LoginHeader />
      <Container>
        <div
          className="hdjnmvdhbdnmviuygbhc"
          style={{ backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}${course.image})` }}
        >
          <div className="eurkjfhdhfjsufg">
            <h3>{ course?.courseName}</h3>
            <div className="hfhdjlfdhbjn">
              <i>{ course?.courseCode}</i>
            </div>{" "}
            <div className="hfhdjlfdhbjn">
              <p>Author: {course?.author?.authorName }</p>
              <p>Estimate TIme: {course?.estimatedTime} min{ course?.estimatedTime >1 ? "s" : ""}.</p>
            </div>
            <button > <Link to={ `/${course._id}/${courseName}`}>TO ENTER </Link></button>
          </div>
        </div>
      </Container>
      <MainFooter />
    </div>
  );
};

export default GameStart;
