import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getProfile, getRankingBasedOnCourse } from "../../../store/actions";
import profileImage from "./img/profileImage.png";
import CategoryAllDataList2 from "./StudentProfileViewNew/CategoryAllDataList2";

const StudentProfileDetails = ({ courses, completedCourse, academicAreas }) => {
  const { token, rankData, role, firstname, email, lastname, isLoading } =
    useSelector((state) => ({
      token: state.Login.token,
      role: state.Login.userrole,
      loading: state.updateAccountReducer.isloading,
      firstname: state.updateAccountReducer.data?.firstName,
      email: state.Login.email,
      lastname: state.updateAccountReducer.data?.lastName,
      rankData: state.courseReducer.rankData,
      isLoading: state.courseReducer.loadign,
    }));
  let dispatch = useDispatch();
  const onHandlingData = (e) => {
    e.preventDefault();
    console.log("hello data", e.target.value);
    if (e.target.value == "1") {
      // toast.error("Please Select A valid one")
    } else {
      dispatch(getRankingBasedOnCourse(token, role, e.target.value));
    }
  };

  useEffect(() => {
    dispatch(getProfile(token));
  }, []);
  return (
    <div>
      <Container>
        <div className="student-profile-view-main-area">
          <div className="student-profile-title-area">
            <h3>Profile</h3>
          </div>
          <Row>
            <Col lg={3}>
              <div className="stident-profile-content-fgbhdnjmk">
                <div className="student-profile-image-area-top-tgbnmkiuh">
                  <label
                    className="student-profile-student-profile-image"
                    htmlFor="student-profile-image"
                  >
                    <img src={profileImage} alt="" />
                    <span>+</span>
                  </label>
                  <input type="file" id="student-profile-image" />

                  <h4>
                    {firstname
                      ? firstname + " " + lastname
                      : email?.split("@")[0]}
                  </h4>
                  <span>{email}</span>
                </div>
                <div className="student-course-profile-bottom-edfswr">
                  <Row>
                    <Col lg={12}>
                      <div className="student-course-info-wsxcde edit-profile-student-jfhbuyhweu">
                        <div className="edit-profile-student-part-">
                          <Link to="/edit-student-profile">
                            Edit Your Profile
                          </Link>
                        </div>
                      </div>
                    </Col>{" "}
                    <Col lg={12}>
                      <div className="student-course-info-wsxcde dbsn">
                        <p>Total Course Enrolled</p>
                        <h2>{courses?.length}</h2>
                      </div>
                    </Col>{" "}
                    <Col lg={12}>
                      <div className="student-course-info-wsxcde dbsn">
                        <p>Total completed</p>
                        <h2>{completedCourse}</h2>
                      </div>
                    </Col>{" "}
                    <Col lg={12}>
                      <div className="student-course-info-wsxcde dbsn">
                        <p>Rank</p>
                        <h2>{completedCourse}</h2>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={9}>
              <CategoryAllDataList2 courses={courses} />
            </Col>
          </Row>

          {/* <div className="student-buy-course-list-and-profile-info">
            <Row>
              <Col lg={6}>
                <div className="student-buy-course-list">
                  <div className="student-buy-course-list-title">
                    <h4>Course</h4>
                  </div>
                  <div className="course-list-profile-page student-courses-page">
                    <div className="dashboard-sidebar-single-item">
                      <div className="sidebar-course-list">
                        {courses?.map((v, i) => (
                          <div className="course-sidebar-single-item ujhgfbsdvzbsd">
                            <div className="course-sidebar-single-item-left-sdfghcjv">
                              <img
                                height={"60px"}
                                src={`https://staging-courseware-backend.sgp1.digitaloceanspaces.com/${v?.course?.image}`}
                                alt=""
                              />
                              <p>
                                <> {v.course?.courseName}</>
                              </p>
                            </div>
                            <div className="course-sidebar-single-item-right-sdfghcjv">
                              <span>Ongoing</span>
                              <button>
                                {" "}
                                <Link
                                  to={`/course-details-page/${v?.course?._id}`}
                                >
                                  View Game
                                </Link>
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="student-buy-course-list">
                  <div className="student-buy-course-list-title">
                    <h4>Ranking</h4>
                    {role == "teacher" ? (
                      <div className="student-result-filtering-area-hdmbfkbn">
                        <Form.Select
                          aria-label="Select Academic Area"
                          onClick={(e) => onHandlingData(e)}
                        >
                          <option value="1">Select Course</option>
                          {courses?.map((v, i) => (
                            <option value={v?.course._id}>
                              {v?.course?.courseName}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    ) : (
                      <div className="student-result-filtering-area-hdmbfkbn">
                        <Form.Select
                          aria-label="Select Academic Area"
                          onClick={(e) => onHandlingData(e)}
                        >
                          <option value="1">Select Course</option>
                          {academicAreas?.map((v, i) => (
                            <option value={v?._id}>{v?.academicName}</option>
                          ))}
                        </Form.Select>
                      </div>
                    )}
                  </div>
                  <div className="course-list-profile-page student-courses-page">
                    <div className="sidebar-course-list">
                      {isLoading && (
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                      {rankData.length < 1 ? (
                        <>No Result</>
                      ) : (
                        rankData?.map((v, i) => (
                          <div className="course-sidebar-single-item-main-wrap ujhgfbsdvzbsd kjhgfedxgfd">
                            <div className="course-sidebar-single-item ujhgfbsdvzbsd kjhgfedxgfd">
                              <div className="course-sidebar-single-item-left-sdfghcjv ">
                                <span className="course-sidebar-single-item-jhkdfgnsglfkzdg">
                                  <p>{v.position}</p>
                                </span>
                                {role == "teacher" && (
                                  <>
                                    <img src={courseIcon2} alt="" />
                                    <p>{v.studentEmail}</p>
                                  </>
                                )}
                              </div>

                              <div className="course-sidebar-single-item-middle-sdfghcjv rthbgxh">
                                <p>{v?.courseName}</p>
                              </div>
                              <div className="course-sidebar-single-item-right-sdfghcjv rthbgxh">
                                <p>{v?.mark}</p>
                              </div>
                            </div>{" "}
                          </div>
                        ))
                      )}{" "}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div> */}
        </div>
      </Container>
    </div>
  );
};

export default StudentProfileDetails;
