import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { clearSecondGame, storeSecondGameData } from "../../store/RetailGame/Course/actions";
import { getSingleGame, storeStudentGameData } from "../../store/RetailGame/Student/actions";
import RetailGameLayout from "../Retail/RetailGameLayout";
const GameSecondInformation = () => {
  let dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = useLocation();

  const { id, courseName } = useParams();
  console.log("courseName", courseName);
  const { token, studentQues, typeArray, singleGame, singleGameLoading } =
    useSelector((state) => ({
      token: state.Login.token,
      studentQues: state.studentGame.studentQues,
      typeArray: state.studentGame.typeArray,
      singleGame: state.studentGame.singleGame,
      singleGameLoading: state.studentGame.singleGameLoading,
    }));
  console.log("singleGame", singleGame);
  useEffect(() => {
    dispatch(getSingleGame(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log("id", id);

  useEffect(()=>{
    dispatch(storeSecondGameData("gameType", singleGame?.questionNumberAndSerial?.[0].examType));
    dispatch(storeStudentGameData("courseName", courseName));
    dispatch(storeStudentGameData("courseId", id));
   
  },[singleGameLoading])

  useEffect(() => {
    dispatch(clearSecondGame());
  }, []);

  return (
    <RetailGameLayout pathname={pathname}>
      <div className="p-3">
        <h3
          style={{
            textAlign: "center",
          }}
        >
          FG গেম
        </h3>
        <p
          style={{
            margin: "12px 0",
            marginBottom: "20px",
            textAlign: "center",
          }}
        >
          আপনি এই FG কুইজটি শুরু করতে পারেন। প্রতিটি প্রশ্নে একটি বুদ্ধিমত্তা
          সংক্রান্ত প্রশ্ন দেয়া হবে। প্রশ্নগুলির সাম্প্রতিক সঠিক উত্তর দিন।
          উত্তর প্রদানের পরে সরাসরি আপনার স্কোর দেখা যাবে। কুইজটি সম্পূর্ণ করতে
          পর্যাপ্ত সময় দেয়া হবে। আপনার কুইজে প্রাপ্ত সর্বোচ্চ স্কোর দেখতে পাবেন।
          যদি আপনি চান, তাহলে কুইজটি পুনরায় করতে পারেন।
        </p>
        <button
          onClick={() => history.push(`/retail-game-2/${id}/${courseName}`)}
          className="next-quiz-submit-btn"
        >
          <span>
            <span>
              <span>
                <span>খেলা শুরু</span>
              </span>
            </span>
          </span>
        </button>
        {/* <Button
          onClick={() => history.push("/student-game-ques", { data: data })}
          style={{ border: "1px solid black" }}
          className="mt-5"
        >
          Start Game
        </Button> */}
      </div>
    </RetailGameLayout>
  );
};

export default GameSecondInformation;
