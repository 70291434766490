import React from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import deleteIcon from "../../img/close-circle.svg";
import editIcon from "../../img/edit-2.svg";
import profileLogo from "./img/teacher.png";
const TeacherBody = ({ authorList, loading }) => {
  console.log("author list", authorList);
  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>Teacher List</h3>
            <p>Teacher List</p>
          </div>
        </div>
        {/* <div className="packge-header-right">
          <Link to="/teacher-edit">
            <span>+ </span>
            Create New
          </Link>
        </div> */}
      </div>
      <div className="package-main-content-list">
        <Table borderless>
          <thead className="package-main-content-list-table-header">
            <tr>
              <th>#</th>
              <th>Image</th>
              <th>User Name</th>
              <th>Email</th>
              <th>Category Name</th>
              <th>Format Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loading && <Spinner />}
            {!loading &&
              authorList?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>
                      <img src={profileLogo} alt="" />
                    </td>
                    <td>{item?.authorName}</td>

                    <td>{item?.email}</td>
                    <td>
                      {item?.category?.map((v, i) => (
                        <>{v.categoryName} | </>
                      ))}
                    </td>
                    <td>
                      {item?.academicArea?.map((v, i) => (
                        <>{v.academicName} | </>
                      ))}
                    </td>
                    <td>
                      <div className="package-control-action-area">
                        <Link to={`/teacher-edit/${item._id}`}>
                          <img src={editIcon} alt="" /> View
                        </Link>
                        <button>
                          <img src={deleteIcon} alt="" />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default TeacherBody;
