import { Field, FieldArray, Form, Formik } from "formik"
import React, { useEffect, useRef, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Button, Input, Label } from "reactstrap"
import InnerLayer from "../../../Layout/InnerLayer"
import PageHeader from "../../../Layout/PageHeader"

import QuillComponent from "../../../components/InputComponent/QuillComponent"

import FileUpload from "../../../components/InputComponent/FileUpload"
import CustomInput from "../../../components/InputComponent/CustomInput"
import CardComponent from "../../../components/Common/CardComponent"
import QuestionTopCard from "../../../components/Common/QuestionTopCard"
import { createHash } from "../../../helpers/Custom/Hash"
import Select from "react-select";
import '../Retail.scss'
import { getAllCourses, getStudents } from "../../../store/actions"
import moment from "moment";


const initialValues = {
  headerTitle: "",
  headerDescription: "",
  headerPhoto: null,
  body:"",
  bodyPhoto: null,
  overAllPhoto: null
 
}

const AssignUserCreate = ({ history, api }) => {
  const textAreaRef = useRef(null)
  const { passageId, questionId, serviceId, section, type } = useParams()

  const dispatch = useDispatch()
  const [listValue, setListValue] = useState(null)
  const [isAddMore, setIsAddMore] = useState(false)
  const [request, setRequest] = useState(false)
  const [sectionData, setSection] = useState("")
  const [courseName, setCourseName] = useState("")
  const [studentName, setStudentName] = useState("")
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const title = `Assign user`;
const title2 = questionId
  ? `Edit  Assign User`
  : `Create Assign User`;
const breadcrumbItems = [
 
  {
    title: title,
    link: `/Quantative-ques-type`,
  },
  {
    title: title2,
    link: `#`,
  },
];
const { token, courses, students } = useSelector((state) => ({
  token: state.Login.token,
  courses: state.courseReducer.allCourses,
  students: state.AdminAuthorReducer.students,
}));
console.log('students', students);

useEffect(() => {
  dispatch(getAllCourses());
}, []);


useEffect(() => {
  dispatch(getStudents(token));
}, []);
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    console.log("values", values)
    const {
    
      headerTitle,
      headerDescription,
      body,
      
      ...rest
    } = values
    const obj = {
      ...rest,
      headerTitle: createHash(headerTitle),
      headerDescription: createHash(headerDescription),
      body: createHash(body),

     
    }
    const option = {
      isMore: isAddMore,
      resetForm,
      setSubmitting,
      setIsAddMore,
      id: questionId,
      passageId,
      history,
      serviceId,
      type,
    }
    console.log(`obj`, obj, option)
    
   
  }

const handleCourse = (data) =>{
  console.log('data', data);
  if(data){
    setCourseName(data?._id)
  }
  else{
    setCourseName("")
  }

}

const handleStudent = (data) =>{
  console.log('data', data);
  if(data){
    setStudentName(data?.Id)
  }
  else{
    setStudentName("")
  }

}

const handleDate = (e)=>{
  console.log(e.target.value);
}
  return (
    <React.Fragment>
      
      <InnerLayer >
      <PageHeader title={title2} breadcrumbItems={breadcrumbItems} />
        <Row className="create_questions">
          <Col md="12">
            <QuestionTopCard
              history={history}
              title={`${
                questionId ? "Update" : "Create"
              } Assign user`}
            />
         <Row className="mx-auto">
            <Col md="6">
            <CardComponent style={{ marginTop: "5px" }}>
              <h5>Assign User</h5>
              <br />
              <Formik
                initialValues={listValue || initialValues}
                // validationSchema={TwoPartCreateSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {({
                  values,
                  errors,
                  setFieldValue,
                  isSubmitting,
                  handleSubmit,
                }) => (
                  <Form>
                    {console.log("values,", values, errors)}

                    <Row className="mb-3">
                  
                    <Col md="12 mb-2">
                        <Label htmlFor="formrow-firstname-Input">
                          Select Course
                        </Label>
                        <Select
                          name="courseid"
                          className="mb-2"
                          classNamePrefix="select2-selection"
                          placeholder="Select course"
                          cacheOptions
                          getOptionLabel={(e) => e.courseName}
                          getOptionValue={(e) => e.value}
                          isClearable
                          options={courses}
                          onChange={handleCourse}
                         
                        />
                    
                       
                      </Col>
                      <Col md="12 mb-2">
                        <Label htmlFor="formrow-firstname-Input">
                          Select Student
                        </Label>
                        <Select
                          name="courseid"
                          className="mb-2"
                          classNamePrefix="select2-selection"
                          placeholder="Select student"
                          cacheOptions
                          getOptionLabel={(e) => e.firstName}
                          getOptionValue={(e) => e.value}
                          isClearable
                          options={students}
                          onChange={handleStudent}
                         
                        />
                    
                       
                      </Col>
                      <Col md="12 mb-2">
                        <Label htmlFor="formrow-firstname-Input">
                          Select Date
                        </Label>
                       <Input type="Date" onChange={handleDate}/>
                       
                    
                       
                      </Col>
                      
                      
                  
                   
                    </Row>


                   

                 
                  
                          
             
                    <Row className="mb-3">
                      <Col md="12" className="d-flex justify-content-end">
                        <Button
                          color="warning"
                          outline
                          className="me-2 btn btn-outline-blue"
                          
                          disabled={isSubmitting || false}
                          type="submit"
                        >
                          {isSubmitting
                            ? "Submitting..."
                            : questionId
                            ? "Update"
                            : "Save"}
                        </Button>
                       
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardComponent>
            </Col>
            <Col md="6">
            
            </Col>
         </Row>
          </Col>
        </Row>
        </InnerLayer>
     
    </React.Fragment>
  )
}

export default AssignUserCreate
