import { useState } from "react";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useHistory } from "react-router-dom";
import "./callToAction.css";
const axios = require("axios");
const CallToAction = () => {
  const [open, setOpen] = useState(false);

  const onCloseModal = () => {
    window.location.reload(false);
    setOpen(false);
  };

  const history = useHistory();
  const [email, setemail] = useState("");

  const API_URL =
    "https://api-ieltsx.salesx-staging.xyz/api/v1/maacroute/newsletter";
  const axiosemailcheck = async (data, options) => {
    try {
      const response = await axios.post(API_URL, data);
      // console.log(response.data);
      if (response.data.status) {
        // localStorage.setItem("testObject", JSON.stringify(data));
        // history.push("/maacgift/join-us");
        // alert("your request is submitted");
        setOpen(true);
        // setText("maac@gmail.com");
        history.replace("/");
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  function handlesubmit(e) {
    e.preventDefault();

    let obj = {
      email: email,
    };
    console.log(obj);
    axiosemailcheck(obj);
  }
  return (
    <div className="call-to-action-main-area">
      <div>
        {/* <button onClick={onOpenModal}>Open modal</button> */}
        <Modal open={open} onClose={onCloseModal} center>
          <h3 className="my-5">Thank You</h3>
        </Modal>
      </div>
      <div className="container">
        <div className="call-to-action-area">
          <div className="call-to-action-left">
            <h2>To Know more about Courseware.</h2>
          </div>
          <div className="call-to-action-right">
            <form onSubmit={handlesubmit}>
              <div className="call-to-action-input-wrap">
                <input
                  type="email"
                  style={{ textTransform: "lowercase" }}
                  placeholder="maac@gmail.com"
                  onChange={(e) => setemail(e.target.value)}
                  required
                />
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
