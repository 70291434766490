import { useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { getDataOnSearch } from "../../../store/actions";
import emptyImage from "./img/EmptyBox.png";
const CategoryAllDataList = ({
  courses,
  categoris,
  academicId,
  handleSearchByText,
  searchedCourse,
  loading,
}) => {
  let [categoryIds, setCategoryIds] = useState([]);
  let dispatch = useDispatch();
  const hanldeDuplicateRemoveAndCall = (id) => {};
  const handleDirectCall = (id) => {};
  //searching algorithm
  //Part 1
  //if categoryIds.length <1
  //store id into categoryIds
  //part 2:
  //if categoryId>1:
  //inside 1:
  //global array
  //  map(categoryId)=>categoryIdEach
  // if(categoryIdEach == searchItem)
  // return null
  //else:
  //add categoryIdEach into global array
  //
  const handleStoreCategoryIds = (e, id) => {
    console.log("hello data", typeof e.target.id);
    let category = [];
    // console.log("length of ids", categoryIds.length)
    let serial = categoryIds.length;
    // category.push({ ...categoryIds, [serial]: id })
    let newArr = [];
    let unique = [];

    let found = false;
    if (categoryIds.length > 0) {
      found = categoryIds.find((cat) => {
        console.log("hello cat", cat.index, e.target.id);
        return cat.index == e.target.id;
      });
      console.log("found data", found);
    }
    console.log("found or not ? ", found);
    if (found?.index) {
      console.log("okay found");
      newArr = categoryIds;
      setCategoryIds(categoryIds.filter((cat) => cat.index !== e.target.id));
      newArr = newArr.filter((cat) => cat.index !== e.target.id);
    } else {
      console.log("found nai kehan theke");
      newArr = categoryIds;
      // newArr.push({ index: e.target.id, ids: e.target.value });
      categoryIds.push({ index: e.target.id, ids: e.target.value });
    }

    console.log("category ids data", categoryIds);
    console.log("ARr ids data", newArr);
    //     if (!categoryIds.length) {
    //       // console.log("not found")

    //       setCategoryIds([...categoryIds, id])
    //       newArr.push(id)
    //     } else {
    //        let arr =[]
    //       categoryIds.map((v, i) =>
    //       {

    //        if (v == id) {
    //         //  console.log("found")

    //          setCategoryIds(categoryIds.filter(item=> item !== id))
    //         //  console.log("63 line data", v,id)
    //           category = category[0][i]
    //         //  newArr = newArr.filter(data => data !== id)
    //          return category
    //        } else {
    //         //  console.log("not found 2" , i , ": ", categoryIds)

    //         // console.log("category type of", typeof category[0], category[0])

    //         setCategoryIds([...categoryIds, id])
    //          newArr.push(v)
    //          if(i ==0)
    //         newArr.push(id)
    //         //   unique = _.uniqWith(categoryIds, _.isEqual);

    //         //  unique.push(v)
    // }
    //       })

    console.log("category data 56", category);

    // }

    dispatch(getDataOnSearch({ academicArea: academicId, category: category }));

    //already existed{
    //how to remove?
    //category.[0].remove({})
    //
  };

  const courseCart = (course) => {
    return (
      <div className="StudentCategoryHome-page-inner">
        <Row>
          {course.map((v, i) => (
            <Col lg={4}>
              <div
                className="business-clases-single-item-wrap"
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_IMAGE_URL}${v.image})`,
                }}
              >
                <div className="business-clases-main-part">
                  <div className="business-clases-main-part-top">
                    <h3>{v.courseName}</h3>
                    <i>{v.courseCode}</i>
                  </div>
                  <div className="business-clases-main-part-bottom">
                    <i>
                      {" "}
                      {v?.academicArea?.map((v1, i) => (
                        <span>
                          {v1.academicName}{" "}
                          {v?.academicArea.length == i + 1 ? "" : "|"}{" "}
                        </span>
                      ))}
                    </i>
                    <h4>{v.courseName}</h4>
                    <div className="business-clases-main-part-bottom-inner">
                      <div className="business-clases-main-part-bottom-inner-left">
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}${v?.category[0]?.image}`}
                          alt=""
                        />
                      </div>{" "}
                      <div className="business-clases-main-part-bottom-inner-left">
                        <h6>
                          <span>Format: </span>{" "}
                          {v?.category?.map((v1, i) => (
                            <span>
                              {v1.categoryName}{" "}
                              {v?.category.length == i + 1 ? "" : "|"}{" "}
                            </span>
                          ))}
                        </h6>{" "}
                        <h6>
                          <span>Language: </span> {v?.language}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="business-clases-hover-part">
                  <div className="">
                    <p>
                      {v.description && v.description.substring(0, 100)}
                      ...
                    </p>
                    <div className="business-clases-hover-part-bottom-link">
                      <Link to={`/course-details-page/${v._id}`}>
                        <span>+</span>
                        <span>Read More</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    );
  };
  const courseCartGrid = (course) => {
    return (
      <>
        <Table
          borderless
          className="list-course-view-data-table-main-area-wrap"
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Course Code</th>
              <th>Academic Area</th>
              <th>Format</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {course.map((v, i) => (
              <tr>
                <td>
                  <div className="list-course-view-data-table-jdhbbd">
                    <img
                      src={`${process.env.REACT_APP_IMAGE_URL}${v.image}`}
                      alt=""
                    />
                    <h4>{v.courseName}</h4>
                  </div>
                </td>
                <td>
                  <p>{v.code}</p>
                </td>
                <td>
                  <p>
                    {v?.academicArea?.map((v1, i) => (
                      <span>
                        {v1.academicName}{" "}
                        {v?.academicArea.length == i + 1 ? "" : "|"}{" "}
                      </span>
                    ))}
                  </p>
                </td>
                <td>
                  {" "}
                  <p>
                    <span></span>{" "}
                    {v?.category?.map((v1, i) => (
                      <span>
                        {v1.categoryName}{" "}
                        {v?.category.length == i + 1 ? "" : "|"}{" "}
                      </span>
                    ))}
                  </p>
                </td>
                <td>
                  <div className="list-course-view-data-table-jdhbbd">
                    <Link to={`/course-details-page/${v._id}`}>
                      <span>View</span>
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  };
  return (
    <div>
      <Container>
        <div className="category-inner-area-main-wrap">
          <Row>
            {/* <Col lg={4}>
              <div className="hero-slider-content-right category-page">
                <h6>REFINE YOUR SEARCH</h6>
                <div className="academic-right-area-wrap category-page">
                  {categoris?.map((item, i) => {
                    return (
                      <div className="academic-right-single-item category-page">
                        <input
                          type="checkbox"
                          id={i}
                          name="ECONOMIC"
                          value={item._id}
                          onClick={(e) => handleStoreCategoryIds(e, item._id)}
                        />
                        <label for={i}> {item.categoryName}</label>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Col> */}
            <Col lg={12}>
              <div className="search-course-main-area-wrap">
                <div className="search-top-area-">
                  <div className="search-area-input-field-and-button">
                    <div className="search-area-input-field-box-wrap">
                      <input
                        type="search"
                        placeholder="Search course"
                        onChange={(e) => handleSearchByText(e)}
                      />
                      <i className="bx bx-search"></i>
                    </div>
                    <button>Search</button>
                  </div>
                  <div className="course-filtering-area-wrap-inner-wrap">
                    <span>
                      Showing {courses.length > 1 ? 1 : 0} - {courses.length} of{" "}
                      {courses.length} result(s)
                    </span>
                    {/* <span>
                      <img src={fIcon2} alt="" />
                      <img src={fIcon1} alt="" />
                    </span> */}
                  </div>
                </div>
                <div className="course-filtering-area-wrap courseware-common-tabs">
                  {loading ? (
                    "loading"
                  ) : courses.length > 0 ? (
                    <Tabs>
                      <TabList className="tab-list-grid-course-data">
                        <Tab>
                          {" "}
                          <i className="bx bx-grid-alt"></i>
                          {/* <img src={fIcon1} alt="" /> */}
                        </Tab>
                        <Tab>
                          <i className="bx bx-menu"></i>
                          {/* <img src={fIcon2} alt="" /> */}
                        </Tab>
                      </TabList>

                      <TabPanel>{courseCart(courses)}</TabPanel>
                      <TabPanel>{courseCartGrid(courses)}</TabPanel>
                    </Tabs>
                  ) : (
                    <div className="no-data-found-wrap-course">
                      <div>
                        <img src={emptyImage} alt="" />
                        <p>There’s no course at this moment.</p>
                      </div>
                    </div>
                  )}
                  {}

                  <Row>
                    {/* <Col lg={4}>
                  <div
                    className="business-clases-single-item-wrap"
                    style={{ backgroundImage: `url(${service2})` }}
                  >
                    <div className="business-clases-main-part">
                      <div className="business-clases-main-part-top">
                        <h3>People Management</h3>
                        <i>MKS020188-U-ENG-HTM</i>
                      </div>
                      <div className="business-clases-main-part-bottom">
                        <i>Academic Area</i>
                        <h4>COST ACCOUNTING & MANAGEMENT CONTROL</h4>
                        <div className="business-clases-main-part-bottom-inner">
                          <div className="business-clases-main-part-bottom-inner-left">
                            <img src={laptopIcon3} alt="" />
                          </div>{" "}
                          <div className="business-clases-main-part-bottom-inner-left">
                            <h6>
                              <span>Format: </span> Case Study
                            </h6>{" "}
                            <h6>
                              <span>Language: </span> English
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-clases-hover-part">
                      <div className="">
                        <p>
                          This interactive tutorial introduces the student to
                          the world of business management. The tutorial is
                          divided into 4 sections: trade, trading activity, the
                          complex sale and the personal sale process, where the
                          student is introduced to the basic concepts of the
                          commercial world up to how to put the seller´s
                          activity into
                        </p>
                        <div className="business-clases-hover-part-bottom-link">
                          <a href="/">
                            <span>+</span>
                            <span>Read More</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>{" "}
                <Col lg={4}>
                  <div
                    className="business-clases-single-item-wrap"
                    style={{ backgroundImage: `url(${service3})` }}
                  >
                    <div className="business-clases-main-part">
                      <div className="business-clases-main-part-top">
                        <h3>Key Concepts in Economics</h3>
                        <i>MKS020188-U-ENG-HTM</i>
                      </div>
                      <div className="business-clases-main-part-bottom">
                        <i>Academic Area</i>
                        <h4>ECONOMIC ENVIRONMENT & PUBLIC AFFAIRS</h4>
                        <div className="business-clases-main-part-bottom-inner">
                          <div className="business-clases-main-part-bottom-inner-left">
                            <img src={laptopIcon4} alt="" />
                          </div>{" "}
                          <div className="business-clases-main-part-bottom-inner-left">
                            <h6>
                              <span>Format: </span> Grains & Simulation
                            </h6>{" "}
                            <h6>
                              <span>Language: </span> English
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-clases-hover-part">
                      <div className="">
                        <p>
                          This interactive tutorial introduces the student to
                          the world of business management. The tutorial is
                          divided into 4 sections: trade, trading activity, the
                          complex sale and the personal sale process, where the
                          student is introduced to the basic concepts of the
                          commercial world up to how to put the seller´s
                          activity into
                        </p>
                        <div className="business-clases-hover-part-bottom-link">
                          <a href="/">
                            <span>+</span>
                            <span>Read More</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>{" "}
                <Col lg={4}>
                  <div
                    className="business-clases-single-item-wrap"
                    style={{ backgroundImage: `url(${service4})` }}
                  >
                    <div className="business-clases-main-part">
                      <div className="business-clases-main-part-top">
                        <h3>Metaverse reality</h3>
                        <i>MKS020188-U-ENG-HTM</i>
                      </div>
                      <div className="business-clases-main-part-bottom">
                        <i>Academic Area</i>
                        <h4>
                          DIGITAL TECHNOLOGIES & DATA <br /> SCIENCE
                        </h4>
                        <div className="business-clases-main-part-bottom-inner">
                          <div className="business-clases-main-part-bottom-inner-left">
                            <img src={laptopIcon2} alt="" />
                          </div>{" "}
                          <div className="business-clases-main-part-bottom-inner-left">
                            <h6>
                              <span>Format: </span> Coursware
                            </h6>{" "}
                            <h6>
                              <span>Language: </span> English
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-clases-hover-part">
                      <div className="">
                        <p>
                          This interactive tutorial introduces the student to
                          the world of business management. The tutorial is
                          divided into 4 sections: trade, trading activity, the
                          complex sale and the personal sale process, where the
                          student is introduced to the basic concepts of the
                          commercial world up to how to put the seller´s
                          activity into
                        </p>
                        <div className="business-clases-hover-part-bottom-link">
                          <a href="/">
                            <span>+</span>
                            <span>Read More</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>{" "}
                <Col lg={4}>
                  <div
                    className="business-clases-single-item-wrap"
                    style={{ backgroundImage: `url(${service5})` }}
                  >
                    <div className="business-clases-main-part">
                      <div className="business-clases-main-part-top">
                        <h3>Accounting Principles</h3>
                        <i>MKS020188-U-ENG-HTM</i>
                      </div>
                      <div className="business-clases-main-part-bottom">
                        <i>Academic Area</i>
                        <h4>COST ACCOUNTING & MANAGEMENT CONTROL</h4>
                        <div className="business-clases-main-part-bottom-inner">
                          <div className="business-clases-main-part-bottom-inner-left">
                            <img src={laptopIcon3} alt="" />
                          </div>{" "}
                          <div className="business-clases-main-part-bottom-inner-left">
                            <h6>
                              <span>Format: </span> Case Study
                            </h6>{" "}
                            <h6>
                              <span>Language: </span> English
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-clases-hover-part">
                      <div className="">
                        <p>
                          This interactive tutorial introduces the student to
                          the world of business management. The tutorial is
                          divided into 4 sections: trade, trading activity, the
                          complex sale and the personal sale process, where the
                          student is introduced to the basic concepts of the
                          commercial world up to how to put the seller´s
                          activity into
                        </p>
                        <div className="business-clases-hover-part-bottom-link">
                          <a href="/">
                            <span>+</span>
                            <span>Read More</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>{" "}
                <Col lg={4}>
                  <div
                    className="business-clases-single-item-wrap"
                    style={{ backgroundImage: `url(${service6})` }}
                  >
                    <div className="business-clases-main-part">
                      <div className="business-clases-main-part-top">
                        <h3>Econometrics</h3>
                        <i>MKS020188-U-ENG-HTM</i>
                      </div>
                      <div className="business-clases-main-part-bottom">
                        <i>Academic Area</i>
                        <h4>ECONOMIC ENVIRONMENT & PUBLIC AFFAIRS</h4>
                        <div className="business-clases-main-part-bottom-inner">
                          <div className="business-clases-main-part-bottom-inner-left">
                            <img src={laptopIcon4} alt="" />
                          </div>{" "}
                          <div className="business-clases-main-part-bottom-inner-left">
                            <h6>
                              <span>Format: </span> Grains & Simulation
                            </h6>{" "}
                            <h6>
                              <span>Language: </span> English
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-clases-hover-part">
                      <div className="">
                        <p>
                          This interactive tutorial introduces the student to
                          the world of business management. The tutorial is
                          divided into 4 sections: trade, trading activity, the
                          complex sale and the personal sale process, where the
                          student is introduced to the basic concepts of the
                          commercial world up to how to put the seller´s
                          activity into
                        </p>
                        <div className="business-clases-hover-part-bottom-link">
                          <a href="/">
                            <span>+</span>
                            <span>Read More</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col> */}
                  </Row>
                </div>
              </div>

              {/* <div className="business-cases-bottom-button-area">
                <a href="/">See More</a>
              </div> */}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default CategoryAllDataList;
