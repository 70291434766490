import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeSecondGameData } from '../../store/RetailGame/Course/actions';
import { useHistory } from "react-router-dom";
const SecondGameFooter = ({answer}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { token, startStep, currentMark, totalMark, userAns, totalQues } = useSelector(
    (state) => ({
      token: state.Login.token,
    
      startStep: state.retail.startStep,
      currentMark: state.retail.currentMark,
      totalMark: state.retail.totalMark,
      userAns: state.retail.userAns,
      totalQues: state.retail.totalQues,
      
  
    })
  );
  console.log('totalQues', totalQues);

  const handleNext = () => {
      dispatch(storeSecondGameData("startStep", startStep+1));
      dispatch(storeSecondGameData("totalMark", totalMark+currentMark));
      
      dispatch(storeSecondGameData("userAns", ""));
      
    
      };
      console.log('totalQues', totalQues);
    return (
        <div>
          {
            startStep === totalQues - 1 ? 
            <button
            className="next-quiz-submit-btn"
            onClick={() => history.push("/second-game-submission")}
            disabled={userAns===""}
          >
            <span>
              <span>
                <span className="button-bg">
                  <span>জমা দিন</span>
                </span>
              </span>
            </span>
          </button> :
              <button
              className="next-quiz-submit-btn"
              onClick={handleNext}
              disabled={userAns===""}
            >
              <span>
                <span>
                  <span className="button-bg">
                    <span>পরবর্তী</span>
                  </span>
                </span>
              </span>
            </button>
          }
           
        </div>
    );
};

export default SecondGameFooter;