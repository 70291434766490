import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { patchData, postData } from "../../../../helpers/backend_helper";
import LafargeFileUpload from "./LafargeFileUpload";
import Questions from "./Question/Questions";
import SuccessComponent from "./Question/SuccessComponent";

const LafargeQuesItems = () => {
	const { token } = useSelector((state) => ({
		token: state.Login.token,
	}));
	const [lafargePopup, setLafargePopup] = useState(false);
	const [reRender, setReRender] = useState(false);
	const [answer, setAnswer] = useState({
		1: { question: "", answer: "", givenAnswer: "" },
		2: { question: "", answer: "", givenAnswer: "" },
		3: { question: "", answer: "", givenAnswer: "" },
		4: { question: "", answer: "", givenAnswer: "" },
		5: { question: "", answer: "", givenAnswer: "" },
		6: { question: "", answer: "", givenAnswer: "" },
		7: { question: "", answer: "", givenAnswer: "" },
		8: { question: "", answer: "", givenAnswer: "" },
		9: { question: "", answer: "", givenAnswer: "" },
		10: { question: "", answer: "", givenAnswer: "" },
		11: { question: "", answer: "", givenAnswer: "" },
		12: { question: "", answer: "", givenAnswer: "" },
		13: { question: "", answer: "", givenAnswer: "" },
		14: { question: "", answer: "", givenAnswer: "" },
		15: { question: "", answer: "", givenAnswer: "" },
		16: { question: "", answer: "", givenAnswer: "" },
		17: { question: "", answer: "", givenAnswer: "" },
		18: { question: "", answer: "", givenAnswer: "" },
		19: { question: "", answer: "", givenAnswer: "" },
		20: { question: "", answer: "", givenAnswer: "" },
		21: { question: "", answer: "", givenAnswer: "" },
		22: { question: "", answer: "", givenAnswer: "" },
		23: { question: "", answer: "", givenAnswer: "" },
		24: { question: "", answer: "", givenAnswer: "" },
	});
	const [object, setObject] = useState({});
	console.log(`LafargeQuesItems ~ answer`, answer);
	const [loading, setLoading] = useState(true);
	const [questions2, setQuestions] = useState({});
	useEffect(() => {
		if (questions2?.status === 3) {
			setLafargePopup(true);
		}
	}, [questions2]);

	console.log(
		`LafargeQuesItems ~ loading asdfas asf`,
		loading,
		questions2._id,
	);
	const handleTakeAnswer = (e, item, number, type, serial) => {
		// console.log(`item, number, type, serial`, e, item, number, type, serial);
		const { value } = e.target;
		// console.log(`line 36 ~ handleTakeAnswer ~ value`, value, item);
		const data = { ...answer };
		data[number].givenAnswer = value;
		data[number].answer = item?.answer;
		data[number].question = item?.question;
		setAnswer(data);
		const data3 = {
			question: item?.question,
			questionId: number,
			sl: serial?.[number] + 1,
			questionType: type,
			answer: value,
		};
		if (type === "fillGap" || type === "situation") {
			setObject(data3);
		} else {
			submitData(data3);
		}
	};

	useEffect(() => {
		const debounce = setTimeout(() => {
			if (object?.answer) {
				submitData(object);
				setObject({});
			}
		}, 300);
		return () => clearTimeout(debounce);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [object?.answer]);

	useEffect(() => {
		const url = "/user/start-exam";
		postData(url, {}, token)
			.then((res) => {
				console.log(`LafargeQuesItems ~ res`, res);
				setQuestions(res?.getData);
			})
			.catch((err) => {
				console.log(`LafargeQuesItems ~ err`, err);
			})
			.finally(() => {
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reRender]);

	const submitData = async (data) => {
		const url = `/user/question-answer/${questions2?._id}`;
		try {
			await patchData(url, data, token);
		} catch (error) {
			console.log(`LafargeQuesItems ~ error`, error);
		}
	};

	return (
		<div>
			<Container>
				<div className="lafarge-training-inner-wrap">
					{questions2?.status === 1 ? (
						<div className="lafarge-training-header-title">
							<h3>Gamification</h3>
							{/* <p>Author: Nazmus Shakib</p> */}
						</div>
					) : null}
					<div className="lafarge-ques-items-wrap">
						{questions2?.status === 1 ? (
							<div className="top-ques-content">
								<p>
									Rogers Stirk Harbour + Partners was founded
									in 1978 under the name Richard Rogers
									Partnership. By February 2009, it was one of
									the top five most important studios in the
									world, with offices in four different
									countries. The studio holds numerous awards
									including two RIBA Stirling Prizes, one in
									2006 for Terminal 4 of the Adolfo Suarez
									Madrid-Barajas Airport and the other in 2009
									for Maggies, London. At the start of 2009
									the company employed just under 200 people,
									with the staff being made up of partners,
									architects, and support staff, among others.
								</p>
								<br />
								<br />
								<p>
									For examples of some of the studio's
									projects please click on the images below.
								</p>
							</div>
						) : null}
						<div className="lafarge-exercise-ques-main-wrap">
							{questions2?.status === 1 ? (
								<Questions
									answer={answer}
									handleTakeAnswer={handleTakeAnswer}
									setReRender={setReRender}
									questions={questions2}
									setLafargePopup={setLafargePopup}
								/>
							) : questions2?.status === 3 ? (
								<LafargeFileUpload
									lafargePopup={lafargePopup}
									questions={questions2}
									setLafargePopup={setLafargePopup}
								/>
							) : null}
							{questions2?.status > 3 ? (
								<SuccessComponent />
							) : null}
							{/* {shortQuestion?.map((item, index) => (
                <div className="lafarge-exercise-single-item mb-5" key={index}>
                  <div className="lafarge-exercise-single-item-inner">
                    <Row>
                      <Col md={'6 my-auto'}>
                        <div className="lafarge-exercise-single-item-inner-left">
                          <h6>{index + 6}</h6>
                          <p>{item?.question}</p>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="lafarge-exercise-single-item-inner-right f">
                          <input
                            type={'text'}
                            className="form-control"
                            value={answer?.[item?.number]?.givenAnswer}
                            onChange={(e) => handleTakeAnswer(e, item)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="lafarge-ques-submit-button">
                    {quesValue.ques2.length > 0 ? (
                      <button className="lafarge-ques-submit-button-inner">
                        Modify
                      </button>
                    ) : (
                      <button className="lafarge-ques-submit-button-inner">
                        Next
                      </button>
                    )}
                  </div>
                </div>
              ))}
              {fillGaps?.map((item, index) => (
                <div className="lafarge-exercise-single-item mb-5" key={index}>
                  <div className="lafarge-exercise-single-item-inner">
                    <Row>
                      <Col md={'6 my-auto'}>
                        <div className="lafarge-exercise-single-item-inner-left">
                          <h6>{item?.number}</h6>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: fillGap(item?.question),
                            }}
                          ></p>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="lafarge-exercise-single-item-inner-right fill-inthe-gap-field">
                          <input
                            className="form-control"
                            type="text"
                            value={answer?.[item?.number]?.givenAnswer}
                            onChange={(e) => handleTakeAnswer(e, item)}
                          ></input>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="lafarge-ques-submit-button">
                    {quesValue.ques2.length > 0 ? (
                      <button className="lafarge-ques-submit-button-inner">
                        Modify
                      </button>
                    ) : (
                      <button className="lafarge-ques-submit-button-inner">
                        Next
                      </button>
                    )}
                  </div>
                </div>
              ))}
              {situation?.map((item, index) => (
                <div className="lafarge-exercise-single-item mb-5" key={index}>
                  <div className="lafarge-exercise-single-item-inner">
                    <Row>
                      <Col md={'6 my-auto'}>
                        <div className="lafarge-exercise-single-item-inner-left">
                          <h6>{item?.number}</h6>
                          <p>
                            {item?.question}
                            <br />
                            <span style={{ color: 'red' }}>
                              (Maximum 500 characters)
                            </span>
                          </p>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="lafarge-exercise-single-item-inner-right f">
                          <textarea
                            className="form-control"
                            rows="4"
                            value={answer?.[item?.number]?.givenAnswer}
                            onChange={(e) => handleTakeAnswer(e, item)}
                          ></textarea>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div className="lafarge-ques-submit-button">
                    {quesValue.ques2.length > 0 ? (
                      <button className="lafarge-ques-submit-button-inner">
                        Modify
                      </button>
                    ) : (
                      <button className="lafarge-ques-submit-button-inner">
                        Next
                      </button>
                    )}
                  </div>
                </div>
              ))} */}
							{/* <div className="lafarge-exercise-single-item mb-5">
                <div className="lafarge-exercise-single-item-inner">
                  <Row>
                    <Col md={'6 my-auto'}>
                      <div className="lafarge-exercise-single-item-inner-left">
                        <h6>3</h6>
                        <p>
                          Assuming that redundancies are the only option for the
                          company, how would you recommend reducing the number
                          of employees? Proportionally from each department,
                          eliminating one of the departments completely or
                          another solution?
                        </p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="lafarge-exercise-single-item-inner-right">
                        <div className="radio-buttons">
                          <div className="form-group">
                            <input
                              type="radio"
                              id="q1"
                              name="gender"
                              value="jubair"
                              onChange={(e) =>
                                setQuesValue({
                                  ...quesValue,
                                  ques3: e.target.value,
                                })
                              }
                            />
                            <label htmlFor="q1">Recommend redundancies</label>
                          </div>
                          <div className="form-group">
                            <input
                              type="radio"
                              id="q2"
                              name="gender"
                              value="baker"
                              onChange={(e) =>
                                setQuesValue({
                                  ...quesValue,
                                  ques3: e.target.value,
                                })
                              }
                            />
                            <label htmlFor="q2">
                              Recommend a different process
                            </label>
                          </div>
                          <div className="form-group">
                            <input
                              type="radio"
                              id="q3"
                              name="gender"
                              value="other"
                              onChange={(e) =>
                                setQuesValue({
                                  ...quesValue,
                                  ques3: e.target.value,
                                })
                              }
                            />
                            <label htmlFor="q3">Other</label>
                          </div>
                        </div>
                        <textarea
                          className="form-control"
                          onChange={(e) =>
                            setQuesValue({
                              ...quesValue,
                              ques2: e.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="lafarge-ques-submit-button">
                  {quesValue.ques2.length > 0 ? (
                    <button className="lafarge-ques-submit-button-inner">
                      Modify
                    </button>
                  ) : (
                    <button className="lafarge-ques-submit-button-inner">
                      Next
                    </button>
                  )}
                </div>
              </div> */}
							{/* <div className="lafarge-exercise-single-item mb-5">
                <div className="lafarge-exercise-single-item-inner">
                  <Row>
                    <Col md={'6 my-auto'}>
                      <div className="lafarge-exercise-single-item-inner-left">
                        <h6>3</h6>
                        <p>
                          Please rank the following factors (where 1 is the most
                          important and 7 the least) when deciding how to select
                          those employees to be made redundant.
                        </p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="lafarge-exercise-single-item-inner-right checkbox-data-field">
                        <ul className="question-checkbox-list">
                          <li>
                            <input
                              className="styled-checkbox"
                              id="styled-checkbox-1"
                              type="checkbox"
                              value="value1"
                            />
                            <label htmlFor="styled-checkbox-1">
                              Performance
                            </label>
                          </li>
                          <li>
                            <input
                              className="styled-checkbox"
                              id="styled-checkbox-2"
                              type="checkbox"
                              value="value2"
                            />
                            <label htmlFor="styled-checkbox-2">
                              Salary level
                            </label>
                          </li>
                          <li>
                            <input
                              className="styled-checkbox"
                              id="styled-checkbox-3"
                              type="checkbox"
                              value="value3"
                            />
                            <label htmlFor="styled-checkbox-3">
                              Hierarchical position in the studio
                            </label>
                          </li>
                          <li>
                            <input
                              className="styled-checkbox"
                              id="styled-checkbox-4"
                              type="checkbox"
                              value="value4"
                            />
                            <label htmlFor="styled-checkbox-4">
                              Function in the studio (architect or support
                              staff)
                            </label>
                          </li>
                          <li>
                            <input
                              className="styled-checkbox"
                              id="styled-checkbox-4"
                              type="checkbox"
                              value="value4"
                            />
                            <label htmlFor="styled-checkbox-4">
                              Number of years in the company
                            </label>
                          </li>
                          <li>
                            <input
                              className="styled-checkbox"
                              id="styled-checkbox-4"
                              type="checkbox"
                              value="value4"
                            />
                            <label htmlFor="styled-checkbox-4">
                              Contribution to specific future development
                            </label>
                          </li>
                          <li>
                            <input
                              className="styled-checkbox"
                              id="styled-checkbox-4"
                              type="checkbox"
                              value="value4"
                            />
                            <label htmlFor="styled-checkbox-4">
                              Transferable skills
                            </label>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="lafarge-ques-submit-button">
                  {quesValue.ques2.length > 0 ? (
                    <button className="lafarge-ques-submit-button-inner">
                      Modify
                    </button>
                  ) : (
                    <button className="lafarge-ques-submit-button-inner">
                      Next
                    </button>
                  )}
                </div>
              </div>
              <div className="lafarge-exercise-single-item mb-5">
                <div className="lafarge-exercise-single-item-inner">
                  <Row>
                    <Col md={'6 my-auto'}>
                      <div className="lafarge-exercise-single-item-inner-left">
                        <h6>3</h6>
                        <p>
                          Assuming that redundancies are the only option for the
                          company, how would you recommend reducing the number
                          of employees? Proportionally from each department,
                          eliminating one of the departments completely or
                          another solution?
                        </p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="lafarge-exercise-single-item-inner-right">
                        <div className="radio-buttons">
                          <div className="lafarge-single-rank-label mb-3">
                            <h6>{''}</h6>
                            <div className="text-center">
                              <p>Keep</p>
                            </div>
                            <div className="text-center">
                              <p>Eliminate</p>
                            </div>
                          </div>
                          <div className="lafarge-single-rank-label mb-3">
                            <h6>Annual overseas office trip</h6>
                            <div className="form-group">
                              <input
                                type="radio"
                                id="q4"
                                name="gender"
                                value="jubair"
                                onChange={(e) =>
                                  setQuesValue({
                                    ...quesValue,
                                    ques1: e.target.value,
                                  })
                                }
                              />
                              <label htmlFor="q4"></label>
                            </div>
                            <div className="form-group">
                              <input
                                type="radio"
                                id="q5"
                                name="gender"
                                value="jubair"
                                onChange={(e) =>
                                  setQuesValue({
                                    ...quesValue,
                                    ques1: e.target.value,
                                  })
                                }
                              />
                              <label htmlFor="q5"></label>
                            </div>
                          </div>
                          <div className="lafarge-single-rank-label mb-3">
                            <h6>Annual overseas office trip</h6>
                            <div className="form-group">
                              <input
                                type="radio"
                                id="q6"
                                name="gender"
                                value="jubair"
                                onChange={(e) =>
                                  setQuesValue({
                                    ...quesValue,
                                    ques1: e.target.value,
                                  })
                                }
                              />
                              <label htmlFor="q6"></label>
                            </div>
                            <div className="form-group">
                              <input
                                type="radio"
                                id="q7"
                                name="gender"
                                value="jubair"
                                onChange={(e) =>
                                  setQuesValue({
                                    ...quesValue,
                                    ques1: e.target.value,
                                  })
                                }
                              />
                              <label htmlFor="q7"></label>
                            </div>
                          </div>
                          <div className="lafarge-single-rank-label mb-3">
                            <h6>Annual overseas office trip</h6>
                            <div className="form-group">
                              <input
                                type="radio"
                                id="q8"
                                name="gender"
                                value="jubair"
                                onChange={(e) =>
                                  setQuesValue({
                                    ...quesValue,
                                    ques1: e.target.value,
                                  })
                                }
                              />
                              <label htmlFor="q8"></label>
                            </div>
                            <div className="form-group">
                              <input
                                type="radio"
                                id="q9"
                                name="gender"
                                value="jubair"
                                onChange={(e) =>
                                  setQuesValue({
                                    ...quesValue,
                                    ques1: e.target.value,
                                  })
                                }
                              />
                              <label htmlFor="q9"></label>
                            </div>
                          </div>
                          <div className="lafarge-single-rank-label mb-3">
                            <h6>Annual overseas office trip</h6>
                            <div className="form-group">
                              <input
                                type="radio"
                                id="q10"
                                name="gender"
                                value="jubair"
                                onChange={(e) =>
                                  setQuesValue({
                                    ...quesValue,
                                    ques1: e.target.value,
                                  })
                                }
                              />
                              <label htmlFor="q10"></label>
                            </div>
                            <div className="form-group">
                              <input
                                type="radio"
                                id="q11"
                                name="gender"
                                value="jubair"
                                onChange={(e) =>
                                  setQuesValue({
                                    ...quesValue,
                                    ques1: e.target.value,
                                  })
                                }
                              />
                              <label htmlFor="q11"></label>
                            </div>
                          </div>
                          <div className="lafarge-single-rank-label mb-3">
                            <h6>Annual overseas office trip</h6>
                            <div className="form-group">
                              <input
                                type="radio"
                                id="q12"
                                name="gender"
                                value="jubair"
                                onChange={(e) =>
                                  setQuesValue({
                                    ...quesValue,
                                    ques1: e.target.value,
                                  })
                                }
                              />
                              <label htmlFor="q12"></label>
                            </div>
                            <div className="form-group">
                              <input
                                type="radio"
                                id="q13"
                                name="gender"
                                value="jubair"
                                onChange={(e) =>
                                  setQuesValue({
                                    ...quesValue,
                                    ques1: e.target.value,
                                  })
                                }
                              />
                              <label htmlFor="q13"></label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="lafarge-ques-submit-button">
                  {quesValue.ques2.length > 0 ? (
                    <Link
                      to="/lafarge-file-upload"
                      className="lafarge-ques-submit-button-inner"
                    >
                      Modify
                    </Link>
                  ) : (
                    <Link
                      to="/lafarge-file-upload"
                      className="lafarge-ques-submit-button-inner"
                    >
                      Next
                    </Link>
                  )}
                </div>
              </div>{" "}
              {/* ======================== */}
							{/* <div className="lafarge-exercise-single-item mb-5">
                <div className="lafarge-exercise-single-item-inner">
                  <Row>
                    <Col md={"6 my-auto"}>
                      <div className="lafarge-exercise-single-item-inner-left">
                        <h6>3</h6>
                        <p>
                          Assuming that redundancies are the only option for the
                          company, how would you recommend reducing the number
                          of employees? Proportionally from each department,
                          eliminating one of the departments completely or
                          another solution?
                        </p>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="lafarge-exercise-single-item-inner-right">
                        <div className="lafarge-video-upload-right-wrap">
                          <input type="file" name="" id="video-upload" />
                          <label htmlFor="video-upload">
                            <div style={{ textAlign: "center" }}>
                              <img src={videoUploadBg} alt="" />
                              <p>
                                Drop your files here. <br /> or
                                <span
                                  style={{
                                    color: "#F7B941",
                                    paddingLeft: "10px",
                                  }}
                                >
                                  Browse.
                                </span>
                              </p>
                            </div>
                          </label>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="lafarge-ques-submit-button">
                  {quesValue.ques2.length > 0 ? (
                    <button className="lafarge-ques-submit-button-inner">
                      Modify
                    </button>
                  ) : (
                    <Link
                      to="/succsessfull-submit"
                      className="lafarge-ques-submit-button-inner"
                    >
                      Submit
                    </Link>
                  )}
                </div>
              </div>{" "} */}
						</div>
					</div>
					<div className="lafarge-training-footer-wrap">
						<div className="lafarge-training-footer-button"></div>
					</div>
				</div>
			</Container>
		</div>
	);
};

export default LafargeQuesItems;
