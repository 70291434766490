export const countryData = [
  {
    id: 1,
    shortName: "usa",
    fullName: "United States of America",
    flag: "https://cdn.britannica.com/33/4833-004-828A9A84/Flag-United-States-of-America.jpg",
    data: 240000,
  },
  {
    id: 2,
    shortName: "russia",
    fullName: "The Russian Federation",
    flag: "https://www.worldometers.info/img/flags/rs-flag.gif",
    data: 215000,
  },
  {
    id: 3,
    shortName: "China",
    fullName: "People's Republic of China",
    flag: "https://www.worldometers.info/img/flags/ch-flag.gif",
    data: 12000,
  },
  {
    id: 4,
    shortName: "Australia",
    fullName: "The Commonwealth of Australia",
    flag: "https://www.worldometers.info/img/flags/as-flag.gif",
    data: 10000,
  },
  {
    id: 5,
    shortName: "Indonesia",
    fullName: "Indonesia",
    flag: "https://www.worldometers.info/img/flags/id-flag.gif",
    data: 11000,
  },
  {
    id: 6,
    shortName: "Brazil",
    fullName: "Federative Republic of Brazil",
    flag: "https://www.worldometers.info/img/flags/br-flag.gif",
    data: 33000,
  },
  {
    id: 7,
    shortName: "Algeria",
    fullName: "People's Democratic Republic of Algeria",
    flag: "https://www.worldometers.info/img/flags/small/tn_ag-flag.gif",
    data: 12100,
  },
  {
    id: 8,
    shortName: "Canada",
    fullName: "Canada",
    flag: "https://www.worldometers.info/img/flags/ca-flag.gif",
    data: 14000,
  },
  {
    id: 9,
    shortName: "Kazakhstan",
    fullName: "Republic of Kazakhstan",
    flag: "https://www.worldometers.info/img/flags/small/tn_kz-flag.gif",
    data: 9000,
  },
  {
    id: 10,
    shortName: "Spain",
    fullName: "Kingdom of Spain",
    flag: "https://www.worldometers.info/img/flags/small/tn_sp-flag.gif",
    data: 21000,
  },
  {
    id: 11,
    shortName: "Turkey",
    fullName: "Republic of Türkiye",
    flag: "https://www.worldometers.info/img/flags/small/tn_tu-flag.gif",
    data: 22000,
  },
  {
    id: 12,
    shortName: "India",
    fullName: "Republic of India",
    flag: "https://www.worldometers.info/img/flags/small/tn_in-flag.gif",
    data: 16000,
  },
  {
    id: 6,
    shortName: "Bangladesh",
    fullName: "People's Republic of Bangladesh",
    flag: "https://www.worldometers.info/img/flags/small/tn_bg-flag.gif",
    data: 18000,
  },
  {
    id: 6,
    shortName: "Japan",
    fullName: "People's Republic of Bangladesh",
    flag: "https://www.worldometers.info/img/flags/small/tn_ja-flag.gif",
    data: 19000,
  },
];
