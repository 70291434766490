import React,{useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import MainFooter from "../Common/footer/MainFooter";
import LoginHeader from "../Common/LoginHeader";
import { addToGift, orderCourse , getCouponByUser, calculateGift, } from "../../../store/actions";
import "./cart.scss";
import CheckoutPageBody from "./CheckoutPageBodyGift";

const ChackoutPageGift = () => {
  const { token, gift , coupon,discount,finalPrice} = useSelector((state) => ({
    token: state.Login.token,
    gift: state.cartReducer.gift,
    coupon: state.adminDashboardReducer.coupon,
    discount: state.cartReducer.discount,
    finalPrice: state.cartReducer.finalPrice,

  }));
  let dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation()
  let [giftUserEmail, setGiftUserEmail] = useState("")
  // let [discount, setDiscount] = useState(0)
  // let [finalPrice, setFinalPrice] = useState(0)
  
  useEffect(() =>
  {
    dispatch(getCouponByUser())
  },[])
  const updatedetails = (elm, prodtype) => {
    let gift = [];
    if (typeof window !== "undefined") {
      if (localStorage.getItem("gift")) {
        gift = JSON.parse(localStorage.getItem("gift"));
      }
      if (prodtype === "color") {
        gift.map((prod, i) => {
          if (prod._id === elm._id) {
            gift[i].color = elm.target.value;
          }
        });
      } else if (prodtype === "count") {
        let cont = elm;
        if (cont > elm.quantity) {
          toast.error(`Maximum Quantity of this product : ${elm.quantity}`);
          return;
        }

        gift.map((prod, i) => {
          if (prod._id === elm._id) {
            gift[i].count = cont;
          }
        });
      } else if (prodtype === "remove") {
        gift.map((prod, i) => {
          if (prod._id === elm._id) {
            gift.splice(i, 1);
          }
        });
        toast.error(`${elm.courseName} has been removed`);
      }
      // console.log("update cart : ", cart)
      localStorage.setItem("gift", JSON.stringify(gift));
      dispatch(addToGift(gift));
    }
  };

 
  
  
  const calculateCoupon =( e , couponCode)=>
  {
    e.preventDefault()
    console.log('coupon code', couponCode)
    let pr = 0;
    gift?.map((v, i) => {
      pr += v.price *v.count;
    });

    if (coupon?.couponCode == couponCode) {
      let percentage = (pr * coupon?.couponPercentage) / 100
      pr -= percentage
      console.log("discount data here", pr, percentage)
      dispatch(calculateGift( percentage,pr))
    }


  }
  
  const handleSubmit = (e) =>
  {
    e.preventDefault();
    // console.log("pass and confirm pass", password, confirmPassword);
    //got email from url
    const giftUserEmail = new URLSearchParams(location.search).get("giftEmail");
    //got token from url
  }

  const onCreateOrder = (e, course, totalPrice) => {
    e.preventDefault();

    let price = 0;
    course.map((v, i) => {
      price += v.price;
    });
    const giftUserEmail = new URLSearchParams(location.search).get("giftEmail");


    console.log('hello submit data', course, price, 0, token,giftUserEmail, history)

    if (finalPrice > 0) {
      
      dispatch(orderCourse(course, finalPrice, 0, token,giftUserEmail, price, discount, history));
    } else {
      dispatch(orderCourse(course, price, 0, token,giftUserEmail, 0, 0, history));

    }
  };
  const handleRemoveCart = (e, course) => {
    e.preventDefault();
    // console.log("cart id", cartId)

    updatedetails(course, "remove");
  };

  const handleSubmitEmail = (e, email) =>
  {
    e.preventDefault()
    console.log("email user", email)
    setGiftUserEmail(email)
  }
  return (
    <div>
      <LoginHeader />
      <CheckoutPageBody
        gift={gift}
        handleRemoveCart={handleRemoveCart}
        onCreateOrder={onCreateOrder}
        handleSubmitEmail={handleSubmitEmail}
        calculateCoupon={calculateCoupon}
        discount={discount}
        finalPrice ={finalPrice}
      />
      <MainFooter />
    </div>
  );
};

export default ChackoutPageGift;
