import React, { useState, useEffect } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import successfullIcon from "../../../LafargeAssets/images/successfull.png";
import { useSelector } from "react-redux";
import {
	deleteData,
	patchData,
	postData,
	getData,
} from "../../../../../helpers/backend_helper";
const SuccessComponent = () => {
	let [markData, setMarkData] = useState({});
	const { token } = useSelector((state) => ({
		token: state.Login.token,
	}));

	useEffect(async () => {
		const data = await getData("/student/get-mark-lafarz", token);
		console.log("data here", data);
		setMarkData(data);
	}, []);
	return (
		<React.Fragment>
			<div className="success-main-page-area-wrap">
				<div className="success-main-page-area">
					<img src={successfullIcon} alt="" />
					<h3>Succesfully Submitted</h3>
					{/* <p>Succesfully Submitted</p> */}
					<Link to="/">Go Home </Link>
				</div>
			</div>

			<div className="result-page-full">
				<div className="result-page-top kduhjnfjgnjvduybghvjk">
					<div className="row">
						<div className="col-md-5">
							<div className="profile-hjkdjfbajbknyfa py-2">
								<p>
									<span className="result-title">
										Author :{" "}
									</span>{" "}
									Nazmus Shakib
								</p>

								<p>
									<span className="result-title">
										Course Code:{" "}
									</span>{" "}
									DF2-264-1
								</p>
							</div>
						</div>{" "}
						<div className="col-md-7">
							<div className="profile-hjkdjfbajbknyf">
								<Table borderless responsive>
									<thead>
										<tr>
											<th>Name</th>
											<th>Mark</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												Negotiation : Technique to win
												against odds
												{/* <div className="profile-hsjbahfbdgjbh">
                            <div className="profile-rank-data-image">
                              <img
                                src={`${process.env.REACT_APP_IMAGE_URL}${course.image}`}
                                alt="MAAC"
                              />
                            </div>
                            <div className="profile-rank-data">
                              <h5>
                                {" "}
                                {result?.student?.firstName
                                  ? result?.student?.firstName +
                                    " " +
                                    result?.student?.lastName
                                  : result?.student?.email.split("@")[0]}{" "}
                              </h5>
                              <p>{result?.student?.email}</p>
                            </div>
                          </div> */}
											</td>
											<td>
												{" "}
												{markData?.getData?.check
													? markData?.getData?.getMark
															?.score
													: "Not Published Yet"}
											</td>
										</tr>
									</tbody>
								</Table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default SuccessComponent;
