export const GET_COURSE_OF_ACADEMIC_AREA = "GET_COURSE_OF_ACADEMIC_AREA"
export const GET_COURSE_OF_ACADEMIC_AREA_SUCCESS = "GET_COURSE_OF_ACADEMIC_AREA_SUCCESS"
export const GET_COURSE_OF_ACADEMIC_AREA_FAIL = "GET_COURSE_OF_ACADEMIC_AREA_FAIL"


export const GET_COURSE_OF_CATEGORY = "GET_COURSE_OF_CATEGORY"
export const GET_COURSE_OF_CATEGORY_SUCCESS = "GET_COURSE_OF_CATEGORY_SUCCESS"
export const GET_COURSE_OF_CATEGORY_FAIL = "GET_COURSE_OF_CATEGORY_FAIL"

export const ASSIGN_COURSE_UNDER_CATEGORY = "ASSIGN_COURSE_UNDER_CATEGORY"
export const ASSIGN_COURSE_UNDER_CATEGORY_SUCCESS = "ASSIGN_COURSE_UNDER_CATEGORY_SUCCESS"
export const ASSIGN_COURSE_UNDER_CATEGORY_FAIL = "ASSIGN_COURSE_UNDER_CATEGORY_FAIL"

export const ASSIGN_COURSE_UNDER_ACADEMIC_AREA = "ASSIGN_COURSE_UNDER_ACADEMIC_AREA"
export const ASSIGN_COURSE_UNDER_ACADEMIC_AREA_SUCCESS = "ASSIGN_COURSE_UNDER_ACADEMIC_AREA_SUCCESS"
export const ASSIGN_COURSE_UNDER_ACADEMIC_AREA_FAIL = "ASSIGN_COURSE_UNDER_ACADEMIC_AREA_FAIL"

export const ASSIGN_COURSE_UNDER_FORMAT = "ASSIGN_COURSE_UNDER_FORMAT"
export const ASSIGN_COURSE_UNDER_FORMAT_SUCCESS = "ASSIGN_COURSE_UNDER_FORMAT_SUCCESS"
// export const ASSIGN_COURSE_UNDER_FORMAT = "ASSIGN_COURSE_UNDER_FORMAT"'


export const GET_SINGLE_ACADEMIC_DATA = "GET_SINGLE_ACADEMIC_DATA"
export const GET_SINGLE_ACADEMIC_DATA_SUCCESS = "GET_SINGLE_ACADEMIC_DATA_SUCCESS"
export const GET_SINGLE_ACADEMIC_DATA_FAIL = "GET_SINGLE_ACADEMIC_DATA_FAIL"

export const GET_ALL_CATEGORIES_FOR_ALL = "GET_ALL_CATEGORIES_FOR_ALL"
export const GET_ALL_CATEGORIES_FOR_ALL_SUCCESSS = "GET_ALL_CATEGORIES_FOR_ALL_SUCCESS"

//get data on search

export const GET_DATA_ON_SEARCH = "GET_DATA_ON_SEARCH"
export const GET_DATA_ON_SEARCH_SUCCESS = "GET_DATA_ON_SEARCH_SUCCESS"
export const GET_DATA_ON_SEARCH_FAIL = "GET_DATA_ON_SEARCH_FAIL"

export const GET_AUTHOR_DATA_USER = "GET_AUTHOR_DATA_USER"
export const GET_AUTHOR_DATA_USER_SUCCESS = "GET_AUTHOR_DATA_USER_SUCCESS"
export const GET_AUTHOR_DATA_USER_FAIL = "GET_AUTHOR_DATA_USER_FAIL"
