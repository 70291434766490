import {
  GET_ALL_CATEGORIES_FOR_ALL,
  GET_ALL_CATEGORIES_FOR_ALL_SUCCESSS,
  GET_AUTHOR_DATA_USER,
  GET_AUTHOR_DATA_USER_FAIL,
  GET_AUTHOR_DATA_USER_SUCCESS,
  GET_COURSE_OF_ACADEMIC_AREA,
  GET_COURSE_OF_ACADEMIC_AREA_FAIL,
  GET_COURSE_OF_ACADEMIC_AREA_SUCCESS,
  GET_DATA_ON_SEARCH,
  GET_DATA_ON_SEARCH_SUCCESS,
} from "./actionTypes";

const initialState = {
  loadign: false,
  error: "",
  token: "",
  message: "",
  courses: [],
  academicCourses: [],
  categories: [],
  authorData: {},
};

const academicAreaReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COURSE_OF_ACADEMIC_AREA:
      state = {
        ...state,
        loadign: true,
      };
      break;
    case GET_COURSE_OF_ACADEMIC_AREA_SUCCESS:
      state = {
        ...state,
        loadign: false,
        academicCourses: action.payload.data,
      };
      break;
    case GET_COURSE_OF_ACADEMIC_AREA_FAIL:
      state = {
        ...state,
        loadign: false,
        message: action.payload.message,
      };
      break;
    case GET_DATA_ON_SEARCH:
      state = {
        ...state,
        searchLoading: true,
      };
      break;
    case GET_DATA_ON_SEARCH_SUCCESS:
      state = {
        ...state,
        searchLoading: false,
        courses: action.payload.data,
      };
      break;
    case GET_ALL_CATEGORIES_FOR_ALL:
      state = {
        ...state,
        loadign: true,
      };
      break;
    case GET_ALL_CATEGORIES_FOR_ALL_SUCCESSS:
      state = {
        ...state,
        loadign: false,
        categoris: action.payload.data,
      };
      break;
    case GET_AUTHOR_DATA_USER:
      state = {
        ...state,
        loadign: true,
      };
      break;
    case GET_AUTHOR_DATA_USER_SUCCESS:
      state = {
        ...state,
        loadign: false,
        authorData: action.payload.data,
      };
    case GET_AUTHOR_DATA_USER_FAIL:
      state = {
        ...state,
        loadign: false,
        error: action.payload.message,
      };
      break;

    default:
      state = state;
      break;
  }
  return state;
};

export default academicAreaReducer;
