import "react-datepicker/dist/react-datepicker.css";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import "react-tabs/style/react-tabs.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../src/components/Dashboard/dashboard.scss";
// Import scss
import "./App.scss";
import "./assests/login.scss";
//new tab
/*------------- END -----------------------*/
/*------------- Auth Based Route --------*/
import AdminRoute from "./authBasedRoute/AdminRoute";
import AccountVerify from "./components/Authentication/AccountVerify";
/*------------- User Authentication --------*/
import CategoryRoute from "./Routes/CategoryRoute";
import Login from "./components/Authentication/Login";
import PasswordConfirmation from "./components/Authentication/PasswordConfirmation";
import RecoveryAccount from "./components/Authentication/RecoveryAccount";
import Succsessfull from "./components/Authentication/Succsessfull";
import AcademicArea from "./components/Dashboard/DashboardItemHome/AcademicArea/AcademicArea";
import AcademicEdit from "./components/Dashboard/DashboardItemHome/AcademicEdit/AcademicEdit";
import AcademicNew from "./components/Dashboard/DashboardItemHome/AcademicNew/AcademicNew";
import AdminAuthor from "./components/Dashboard/DashboardItemHome/AdminAuthor/AdminAuthor";
import AdminAuthorEdit from "./components/Dashboard/DashboardItemHome/AdminAuthorEdit/AdminAuthorEdit";
import AdminAuthorNew from "./components/Dashboard/DashboardItemHome/AdminAuthorNew/AdminAuthorNew";
import AllCourseFrameList from "./components/Dashboard/DashboardItemHome/AllCourseFrameList/AllCourseFrameList";
import AllCourseList from "./components/Dashboard/DashboardItemHome/AllCourseList/AllCourseList";
import AllNewCourseCreate from "./components/Dashboard/DashboardItemHome/AllNewCourseCreate/AllNewCourseCreate";
import AllNewCourseEdit from "./components/Dashboard/DashboardItemHome/AllNewCourseEdit/AllNewCourseEdit";
import AllNewCourseFrame from "./components/Dashboard/DashboardItemHome/AllNewCourseFrame/AllNewCourseFrame";
import AttendenceNave from "./components/Dashboard/DashboardItemHome/Attendence/AttendenceNave";
import CertificeteList from "./components/Dashboard/DashboardItemHome/CertificateMain/CertificeteList/CertificeteList";
import CourseWareNave from "./components/Dashboard/DashboardItemHome/CourseWare/CourseWareNave";
import Dashboard from "./components/Dashboard/DashboardItemHome/DashboardMenu/Dashboard";
import EditEducatorCopy from "./components/Dashboard/DashboardItemHome/EducatorMain/EditEducatorCopy/EditEducatorCopy";
import EducatorCopy from "./components/Dashboard/DashboardItemHome/EducatorMain/EducationCopy/EducatorCopy";
import NewEducatorCopy from "./components/Dashboard/DashboardItemHome/EducatorMain/NewEducatorCopy/NewEducatorCopy";
import Format from "./components/Dashboard/DashboardItemHome/Format/Format";
import FormatEdit from "./components/Dashboard/DashboardItemHome/FormatEdit/FormatEdit";
import LafargeGame from "./components/Dashboard/DashboardItemHome/Lafarge/Game/LafargeGame";
import StudentInfo from "./components/Dashboard/DashboardItemHome/Lafarge/StudentInfo/StudentInfo";
import NewFormat from "./components/Dashboard/DashboardItemHome/NewFormat/NewFormat";
import AnnualPage from "./components/Dashboard/DashboardItemHome/Package/EditPackage/Annual/AnnualPage";
import BundlePage from "./components/Dashboard/DashboardItemHome/Package/EditPackage/Bundle/BundlePage";
import GiftAndRewardPage from "./components/Dashboard/DashboardItemHome/Package/EditPackage/GiftAndReward/GiftAndRewardPage";
import IndividualsPage from "./components/Dashboard/DashboardItemHome/Package/EditPackage/Individual/IndividualsPage";
import PackageList from "./components/Dashboard/DashboardItemHome/Package/PackageList";
import Quiz from "./components/Dashboard/DashboardItemHome/Quiz/Quiz";
import RangkingList from "./components/Dashboard/DashboardItemHome/Rangking/RangkingList";
import SettingsNave from "./components/Dashboard/DashboardItemHome/Settings/SettingsNave";
import OrderList from "./components/Dashboard/DashboardItemHome/StudentsList/OrderList";
import StudentsList from "./components/Dashboard/DashboardItemHome/StudentsList/StudentsList";
import StudentsListEdit from "./components/Dashboard/DashboardItemHome/StudentsList/StudentsListEdit";
import StudentsResult from "./components/Dashboard/DashboardItemHome/StudentsResult/StudentsResult";
import TeacherList from "./components/Dashboard/DashboardItemHome/TeacherList/TeacherList";
import TeacherListEdit from "./components/Dashboard/DashboardItemHome/TeacherList/TeacherListEdit";
import DragDropTwo from "./components/DragDropTwo/DragDropTwo";
import Budgeting from "./components/FirstGame/Budgeting";
import CaseSummery from "./components/FirstGame/CaseSummery";
import FinancialData from "./components/FirstGame/FinancialData";
import FinancialGraph from "./components/FirstGame/FinancialGraph";
import Recruitment from "./components/FirstGame/Recruitment";
import Result from "./components/FirstGame/Result";
import Revenue from "./components/FirstGame/Revenue";
import SampleDragandDrop from "./components/FirstGame/SampleDragandDrop";
import UnitsRevenue from "./components/FirstGame/UnitsRevenue";
import GameStart from "./components/GameStart/GameStart";
import RegSuccess from "./components/Registration/RegSuccess";
import Registration from "./components/Registration/Registration";
import AssignUserArea from "./components/Retail/AssignUser/AssignUserArea";
import AssignUserCreateArea from "./components/Retail/AssignUser/AssignUserCreateArea";
import CreateGameBody from "./components/Retail/Game/CreateGameBody";
import GameInfoArea from "./components/Retail/Game/GameInfoArea";
import CaseMethod from "./components/SecondGame/CaseMethod";
import ComparativeRatio from "./components/SecondGame/ComparativeRatio";
import FinancialStatement from "./components/SecondGame/FinancialStatement";
import IndustryRatio from "./components/SecondGame/IndustryRatio";
import PyramidRatio1 from "./components/SecondGame/PyramidRatio1";
import ResultGameTwo from "./components/SecondGame/ResultGameTwo";
import LoginHeader from "./components/StudentPages/Common/LoginHeader";
import StudentHome from "./components/StudentPages/StudentHomePage/components/Home/StudentHome";
import LafargeCoursewareHome from "./pages/Lafarge/LafargeHome/LafargeCoursewareHome";
import LafargeDetails from "./pages/Lafarge/LafargeHome/LafargeDetails";
import LafargeFileUploadPage from "./pages/Lafarge/LafargeHome/LafargeFileUploadPage";
import LafargeGuidlines from "./pages/Lafarge/LafargeHome/LafargeGuidlines";
import LafargeHome from "./pages/Lafarge/LafargeHome/LafargeHome";
import LafargeQuesPage from "./pages/Lafarge/LafargeHome/LafargeQuesPage";
import NegotiationMethodPage from "./pages/Lafarge/LafargeHome/NegotiationMethodPage";
import SuccesfullySubmitted from "./pages/Lafarge/LafargeHome/SuccesfullySubmitted";
import WhyDoWeNeedPage from "./pages/Lafarge/LafargeHome/WhyDoWeNeedPage";
import SettingsNav from "./pages/Settings/SettingsNav";
//checkout page gift

/*------------- END -----------------------*/

toast.configure();
function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/:courseId/lafarge" component={LafargeHome} />
          <Route
            exact
            path="/:courseId/lafarge-home"
            component={LafargeCoursewareHome}
          />
          <Route
            exact
            path="/:courseId/training-details"
            component={LafargeDetails}
          />
          <Route
            exact
            path="/:courseId/why-do-we-need"
            component={WhyDoWeNeedPage}
          />
          <Route
            exact
            path="/:courseId/negotiation-method"
            component={NegotiationMethodPage}
          />{" "}
          <Route
            exact
            path="/:courseId/lafarge-exercise"
            component={LafargeQuesPage}
          />
          <Route
            exact
            path="/lafarge-file-upload/:questionID"
            component={LafargeFileUploadPage}
          />{" "}
          <Route
            exact
            path="/lafarge-guidlines/:questionID"
            component={LafargeGuidlines}
          />{" "}
          <Route
            exact
            path="/succsessfull-submit"
            component={SuccesfullySubmitted}
          />
          {/* ==================== */}
          <Route exact path="/succsessfull" component={Succsessfull} />
          <Route exact path="/login" component={Login} />
          {/* <Route exact path="/reset-password" component={RecoveryAccount} /> */}
          <Route exact path="/recovery-account" component={RecoveryAccount} />
          <Route exact path="/registration" component={Registration} />
          <Route exact path="/registraton-success" component={RegSuccess} />
          <Route
            exact
            path="/reset-password"
            component={PasswordConfirmation}
          />
          <Route exact path="/account-verify" component={AccountVerify} />
          <Route
            exact
            path="/successfull-recover-account"
            component={Succsessfull}
          />
          <AdminRoute exact path="/admindashboard">
            <Dashboard />
          </AdminRoute>{" "}
          <AdminRoute exact path="/settings-s" component={SettingsNav} />
          {/* /case summary game   */}
          <Route exact path="/:courseId/game-start">
            <GameStart />
          </Route>{" "}
          <Route exact path="/package-list">
            <PackageList />
          </Route>{" "}
          <Route exact path="/courseware">
            <CourseWareNave />
          </Route>{" "}
          <Route exact path="/settings">
            <SettingsNave />
          </Route>{" "}
          <Route exact path="/attendence">
            <AttendenceNave />
          </Route>{" "}
          <Route exact path="/academic-edit/:academicId">
            <AcademicEdit />
          </Route>{" "}
          <Route exact path="/dashboard-author-area">
            <AdminAuthor />
          </Route>
          <Route exact path="/admin-author-edit/:authorId">
            <AdminAuthorEdit />
          </Route>{" "}
          <Route exact path="/admin-author-new">
            <AdminAuthorNew />
          </Route>
          <Route exact path="/create-new-package">
            <IndividualsPage />
          </Route>
          <Route exact path="/Bundle">
            <BundlePage />
          </Route>
          <Route exact path="/reward">
            <GiftAndRewardPage />
          </Route>
          <Route exact path="/Annual">
            <AnnualPage />
          </Route>
          {/* <Route exact path="/Individual">
            <IndividualsPage />
          </Route> */}
          <Route exact path="/teacher-list">
            <TeacherList />
          </Route>
          <Route path="/teacher-edit/:authorId">
            <TeacherListEdit />
          </Route>
          <Route exact path="/students-list">
            <StudentsList />
          </Route>
          <Route exact path="/total-orders">
            <OrderList />
          </Route>
          <Route exact path="/students-edit/:studentId">
            <StudentsListEdit />
          </Route>
          <Route exact path="/student-profile">
            <StudentsResult />
          </Route>{" "}
          <Route exact path="/format-list">
            <Format />
          </Route>{" "}
          <Route exact path="/create-new-format">
            <NewFormat />
          </Route>{" "}
          <Route exact path="/formate-edit/:formatId">
            <FormatEdit />
          </Route>{" "}
          <Route exact path="/academic-area">
            <AcademicArea />
          </Route>{" "}
          <Route exact path="/create-academic-new">
            <AcademicNew />
          </Route>{" "}
          <Route exact path="/course">
            <AllCourseList />
          </Route>{" "}
          <Route exact path="/create-new-course">
            <AllNewCourseCreate />
          </Route>{" "}
          <Route exact path="/all-course-edit/:courseId">
            <AllNewCourseEdit />
          </Route>{" "}
          <Route exact path="/admin-lafarge-games">
            <LafargeGame />
          </Route>{" "}
          <Route exact path="/admin-lafarge-student">
            <StudentInfo />
          </Route>{" "}
          <Route exact path="/course-frame">
            <AllCourseFrameList />
          </Route>{" "}
          <Route exact path="/create-course-frame-new">
            <AllNewCourseFrame />
          </Route>
          <Route exact path="/educator-copy">
            <EducatorCopy />
          </Route>
          <Route exact path="/create-new-educator-copy">
            <NewEducatorCopy />
          </Route>{" "}
          <Route exact path="/edit-educator-copy">
            <EditEducatorCopy />
          </Route>
          <Route exact path="/quiz">
            <Quiz />
          </Route>{" "}
          <Route exact path="/rank">
            <RangkingList />
          </Route>
          <Route exact path="/certificate">
            <CertificeteList />
          </Route>
          <Route exact path="/:courseId/case-summary">
            <LoginHeader />
            <CaseSummery />
          </Route>
          <Route exact path="/:courseId/recruitment">
            <LoginHeader />
            <Recruitment />
          </Route>
          <Route exact path="/:courseId/budgeting">
            <LoginHeader />
            <Budgeting />
          </Route>
          <Route exact path="/:courseId/revenue">
            <LoginHeader />
            <Revenue />
          </Route>
          <Route exact path="/:courseId/unitRevenue">
            <LoginHeader />
            <UnitsRevenue />
          </Route>
          <Route exact path="/:courseId/financial-graph">
            <LoginHeader />
            <FinancialGraph />
          </Route>
          <Route exact path="/:courseId/financial-data">
            <LoginHeader />
            <FinancialData />
          </Route>
          <Route exact path="/:courseId/sample-financial-graph">
            <LoginHeader />
            <SampleDragandDrop />
          </Route>
          <Route exact path="/:courseId/case-summary/result">
            <LoginHeader />
            <Result />
          </Route>
          <Route exact path="/:courseId/case-method">
            <LoginHeader />
            <CaseMethod />
          </Route>
          <Route exact path="/:courseId/pyramid-ratio">
            <LoginHeader />
            <PyramidRatio1 />
          </Route>
          <Route exact path="/:courseId/financial-statement">
            <LoginHeader />
            <FinancialStatement />
          </Route>
          <Route exact path="/:courseId/comparative-ratio">
            <LoginHeader />
            <ComparativeRatio />
          </Route>
          <Route exact path="/:courseId/industry-ratio">
            <LoginHeader />
            <IndustryRatio />
          </Route>
          <Route exact path="/:courseId/financial">
            <LoginHeader />
            <DragDropTwo />
          </Route>
          <Route exact path="/:courseId/case-method/result">
            <LoginHeader />
            <ResultGameTwo />
          </Route>
          <Route exact path="/game-info">
            <GameInfoArea />
          </Route>{" "}
          <Route exact path="/create-game-info">
            <CreateGameBody />
          </Route>{" "}
          {/* //for StudentCategoryHome */}
          {/* Assign User */}
          <Route exact path="/assign-user">
            <AssignUserArea />
          </Route>{" "}
          <Route exact path="/assign-to-user">
            <AssignUserCreateArea />
          </Route>
          <Route exact path={["/home", "/"]}>
            <StudentHome />
          </Route>
          <CategoryRoute exact />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
