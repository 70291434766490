import {
    ADD_TO_CART,
    ADD_TO_CART_SUCCESS,
    ADD_TO_CART_FAIL,
    ADD_TO_GIFT,
    ADD_TO_GIFT_FAIL,
    ADD_TO_GIFT_SUCCESS,
    CALCULATE_GIFT
} from "./actionTypes"

let initialState = {
    cart: [],
    gift: [],
    discount: 0,
    finalPrice : 0
    
}

if (typeof window !== "undefined") {
	if (localStorage.getItem("cart")) {
		initialState.cart = JSON.parse(localStorage.getItem("cart"));
	} else {
		initialState.cart = [];
    }
    if(localStorage.getItem("gift")){
		initialState.gift = JSON.parse(localStorage.getItem("gift"));
	} else {
		initialState.gift = [];
    }
}
const cartReducer = (state = initialState, action) =>
{
    switch (action.type) {
         
        case ADD_TO_CART:
            state = {
                ...state,
                cart : action.payload.cart
            }
            break
        case ADD_TO_GIFT:
            state = {
                ...state,
                gift : action.payload.data
            }
            break
        case CALCULATE_GIFT:
            state = {
                ...state,
                discount: action.payload.discount,
                finalPrice : action.payload.finalPrice
            }
            break
            default:
                state = state
                break
    }
    return state
}


export default cartReducer