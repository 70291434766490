import React, { useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import Forecast from "./gamePart/Forecast";
import GrowthDrivers from "./gamePart/GrowthDrivers";
import Introduction from "./gamePart/Introduction";
import ResultGameThree from "./gamePart/ResultGameThree";
import CalculateModal from "./map/CalculateModal";
import "./third-game.scss";
const ThirdGameFrame = () => {
  const { countryName } = useSelector((state) => state?.gdpCourse);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalSize, setModalSize] = useState("xl");
  console.log(countryName);
  return (
    <div>
      <Container>
        <div className="third-game-frame-wrap">
          <Tabs
            defaultActiveKey="Introduction"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="Introduction" title="Introduction">
              <div className="gdp-graph-introduction-header-content-area">
                <p>
                  WalKart is a furniture manufacturer which wants to expand its
                  buisness in south east Asia. WalKart is a furniture
                  manufacturer{" "}
                </p>
                <div className="gdp-graph-introduction-header-content-devider"></div>
                <div className="gdp-graph-introduction-header-content-right">
                  <img src="https://picsum.photos/id/22/4434/3729" alt="" />
                  <div className="gdp-graph-introduction-header-content-right-profile-info">
                    <h6>Leslie Alexander</h6>
                    <p>
                      WalKart is a furniture manufacturer which wants to expand
                      its{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="tab-introduction-gdp-data">
                <Introduction />
              </div>
            </Tab>
            <Tab
              eventKey="Forecast"
              title="Forecast"
              className="forecast-tab-wrap"
            >
              <div className="forecast-header-content-area">
                <p>
                  Lorem ipsum dolor sit amet consectetur. Elit felis duis eu
                  pellentesque viverra. Risus est ultrices lectus lorem sed
                  nulla erat ullamcorper. Felis et leo mauris neque malesuada
                  enim amet ante. Viverra integer euismod accumsan suspendisse
                  tincidunt posuere et pulvinar. Ipsum a fringilla nisl erat.
                  Amet risus a ut amet mi nibh. Laoreet egestas proin massa.
                </p>
              </div>
              <div className="tab-forecast-map">
                <CalculateModal
                  show={show}
                  handleClose={handleClose}
                  handleShow={handleShow}
                  modalSize={modalSize}
                />
                <Forecast />
                {countryName.length === 15 && (
                  <div className="gdp-value-submit-confirm-buttons">
                    <button
                      onClick={() => {
                        handleShow();
                        setModalSize("mb");
                      }}
                    >
                      Calculate next 15years
                    </button>
                    <button>Compare all data</button>
                  </div>
                )}
              </div>
            </Tab>{" "}
            <Tab
              eventKey="growth-drivers"
              title="Growth drivers"
              disabled={countryName?.length === 15 ? false : true}
            >
              <div className="growth-drivers-header-content-area">
                <p>
                  Lorem ipsum dolor sit amet consectetur. Elit felis duis eu
                  pellentesque viverra. Risus est ultrices lectus lorem sed
                  nulla erat ullamcorper. Felis et leo mauris neque malesuada
                  enim amet ante. Viverra integer euismod accumsan suspendisse
                  tincidunt posuere et pulvinar. Ipsum a fringilla nisl erat.
                  Amet risus a ut amet mi nibh. Laoreet egestas proin massa.
                </p>
              </div>
              <hr />
              <GrowthDrivers />
            </Tab>
            <Tab eventKey="Conclusion" title="Conclusion">
              <ResultGameThree />
            </Tab>
          </Tabs>
        </div>
      </Container>
    </div>
  );
};

export default ThirdGameFrame;
