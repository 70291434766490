import React, { useState } from "react";
// import { AvField, AvForm } from "availity-reactstrap-validation"
import MetaTags from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { CardBody, Col, Row } from "reactstrap";
import Loader from "../../Loader/MaacLoader";
import longArrow from "../../assests/images/ButtonLongArrow.png";
import mainLogo from "../../assests/images/main-logo.png";
import { loginUser } from "../../store/actions";

const Login = () => {
  const { loading, token, role } = useSelector((state) => ({ ...state.Login }));
  const { error } = useSelector((state) => ({ ...state.loginError }));
  let history = useHistory();
  const dispatch = useDispatch();
  const [checkerror, setCheckError] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const validateEmail = (email) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const handleValidSubmit = (e) => {
    e.preventDefault();

    if (!email || !password) {
      console.log("Field must not be empty");
      return;
    } else if (!validateEmail(email)) {
      setCheckError("Email is not valid");
      return;
    } else {
      console.log("email is valid");
      dispatch(loginUser({ email, password }, history));
    }
  };
  const [show, setShow] = useState(false);
  const showPassword = (pass, showPass) => {
    if (pass) {
      if (showPass) {
        return <i className="fa-regular fa-eye-slash"></i>;
      } else {
        return <i className="fa-regular fa-eye"></i>;
      }
    }
  };
  return (
    <React.Fragment>
      <div className="account-pages login-main-wrap">
        <MetaTags>
          <title>Login | GameProject</title>
        </MetaTags>

        <div className="login-left-logo">
          <img src={mainLogo} alt="" />
        </div>
        <div className="login-right-form">
          {/* <img src={mainLogo} alt="" /> */}
          <div className="login-right-inner-form">
            <div className="p-2">
              {loading ? (
                <div className="p-4 d-flex justify-content-center align-items-center p-2 mb-3 mt-5 mr-auto">
                  <Loader />
                </div>
              ) : (
                <CardBody className="login-right-form">
                  <div className="login-right-inner-form">
                    <h2>Log In</h2>

                    <form
                      className="form-horizontal mt-4"
                      onSubmit={(e) => handleValidSubmit(e)}
                    >
                      <div className="mb-3">
                        <input
                          name="email"
                          label="Email"
                          value={email}
                          className="form-control"
                          placeholder="Enter email"
                          onChange={(e) =>
                            setEmail(e.target.value?.toLowerCase())
                          }
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <div className="form-input-pass-show">
                          <input
                            name="password"
                            label="Password"
                            value={password}
                            className="form-control"
                            type={show ? "text" : "password"}
                            required
                            placeholder="Enter password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <span
                            onClick={() => setShow(!show)}
                            className="show-class"
                          >
                            {showPassword(password, show)}
                          </span>
                        </div>
                      </div>

                      <Row className="mb-3">
                        <Col sm={12}>
                          {error || checkerror ? (
                            <Row>
                              <Col sm={12}>
                                <div className="error-main-area-hdfskjdfbschdb">
                                  <p>
                                    <i className="bx bxs-error-alt"></i>{" "}
                                    {error || checkerror}
                                  </p>
                                </div>
                              </Col>{" "}
                            </Row>
                          ) : null}

                          {/* <div className="input-field-group- input-radio-group-new-account">
                            <p>
                              <Link to="/recovery-account">
                                {" "}
                                Forget Password?
                              </Link>
                            </p>
                          </div> */}
                        </Col>
                        <Col sm={6} className="text-end">
                          <div className="input-field-group submit-btn">
                            <button type="submit" className="calibarX-default">
                              Log In <img src={longArrow} alt="" />
                            </button>
                          </div>
                        </Col>
                        <Col sm={12}>
                          <div className="input-field-group input-radio-group-new-account">
                            <p>
                              Need to create a account?{" "}
                              <Link to="registration">Sign Up</Link>
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </CardBody>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Login;
