import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { storeStudentGameData } from "../../store/RetailGame/Student/actions";
import "./Retail.scss";

const Footer = ({ rightAns, setRightAns }) => {
  console.log("rightAns", rightAns);

  const dispatch = useDispatch();
  const history = useHistory();
  const {
    token,
    studentQues,
    currentIndex,
    currentType,
    mcqStartStep,
    mcqEndStep,
    selected,
    dragDropStartStep,
    dragDropEndStep,
    totalQuestion,
    countQuestion,
  } = useSelector((state) => ({
    token: state.Login.token,
    currentIndex: state.studentGame.currentIndex,
    mcqQuesNum: state.studentGame.mcqQuesNum,
    mcqStartStep: state.studentGame.mcqStartStep,
    mcqEndStep: state.studentGame.mcqEndStep,
    selected: state.studentGame.selected,
    mcqRightAns: state.studentGame.mcqRightAns,
    currentType: state.studentGame.currentType,
    dragDropStartStep: state.studentGame.dragDropStartStep,
    dragDropEndStep: state.studentGame.dragDropEndStep,
    totalQuestion: state.studentGame.totalQuestion,
    countQuestion: state.studentGame.countQuestion,
    
  }));
  console.log("countQuestion", totalQuestion, countQuestion);
  const handleNext = () => {
    dispatch(storeStudentGameData("countQuestion", countQuestion + 1));
    dispatch(storeStudentGameData("selected", ""));
    if (mcqStartStep <= mcqEndStep && currentType === "mcq") {

      dispatch(storeStudentGameData("mcqStartStep", mcqStartStep + 1));
    }
    if (mcqStartStep === mcqEndStep - 1 && currentType === "mcq") {
      dispatch(storeStudentGameData("currentIndex", currentIndex + 1));
    }
    if (dragDropStartStep <= dragDropEndStep && currentType === "drugdrop") {
 
      dispatch(
        storeStudentGameData("dragDropStartStep", dragDropStartStep + 1)
      );
    }
    if (
      dragDropStartStep === dragDropEndStep - 1 &&
      currentType === "drugdrop"
    ) {
      dispatch(storeStudentGameData("currentIndex", currentIndex + 1));
    }
    setRightAns(false);
  };
  console.log("selected", selected);

  return (
    <div>
      {countQuestion === totalQuestion - 1 ? (
        <button
          className="next-quiz-submit-btn"
          onClick={() => history.push("/game-sumbit")}
          disabled={!rightAns}
        >
          <span>
            <span>
              <span>
                <span>জমা দিন</span>
              </span>
            </span>
          </span>
        </button>
      ) : (
        <button
          className="next-quiz-submit-btn"
          onClick={handleNext}
          disabled={!rightAns}
        >
          <span>
            <span>
              <span>
                <span>পরবর্তী</span>
              </span>
            </span>
          </span>
        </button>
      )}
    </div>
  );
};

export default Footer;
