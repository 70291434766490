import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Col, Label, Row } from "reactstrap";
import Editor from "./QuillEditor";
import "./settings.scss";
const Settings = ({ edit, history }) => {
  const breadcrumbItems = [];
  const [tabsControl, setTabsControl] = useState({});
  return (
    <>
      <div className="website-information-write-main-area">
        <Tabs defaultIndex={1} onSelect={(index) => console.log(index)}>
          <Row>
            <div className="col-md-3">
              <div className="tabs-left-side-main-wrap-settings-page">
                <TabList>
                  <Tab>Terms and conditions</Tab>
                  <Tab>Privacy Policy</Tab>
                  <Tab>Refund Policy</Tab>
                  <Tab>FAQ</Tab>
                </TabList>
              </div>
            </div>

            <Col md="9">
              <TabPanel>
                <div>
                  <h2 className="mb-4">Terms and conditions</h2>
                  <div className="editor-website-info-wrap">
                    <Editor
                      placeholder={"Type"}
                      name="q"
                      value=""
                      height={"270px"}
                    />
                  </div>
                  <div className="website-info-data-bottom">
                    <Label>Terms and conditions</Label>
                    <button>Update</button>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <h2 className="mb-4">Privacy Policy</h2>
                  <div className="editor-website-info-wrap">
                    <Editor
                      placeholder={"Type"}
                      name="q"
                      value=""
                      height={"270px"}
                    />
                  </div>
                  <div className="website-info-data-bottom">
                    <Label>Privacy Policy</Label>
                    <button>Update</button>
                  </div>
                </div>
              </TabPanel>{" "}
              <TabPanel>
                <div>
                  <h2 className="mb-4">Refund Policy</h2>
                  <div className="editor-website-info-wrap">
                    <Editor
                      placeholder={"Type"}
                      name="q"
                      value=""
                      height={"270px"}
                    />
                  </div>
                  <div className="website-info-data-bottom">
                    <Label>Refund Policy</Label>
                    <button>Update</button>
                  </div>
                </div>
              </TabPanel>{" "}
              <TabPanel>
                <div>
                  <h2 className="mb-4">FAQ</h2>
                  <div className="editor-website-info-wrap">
                    <Editor
                      placeholder={"Type"}
                      name="q"
                      value=""
                      height={"270px"}
                    />
                  </div>
                  <div className="website-info-data-bottom">
                    <Label>FAQ</Label>
                    <button>Update</button>
                  </div>
                </div>
              </TabPanel>
            </Col>
          </Row>
        </Tabs>
      </div>
    </>
  );
};

export default withRouter(Settings);
