import React from "react";

const BgShadow = () => {
  return (
    <svg
      className="bg-shadow-image-bottom"
      width="145"
      height="144"
      viewBox="0 0 145 144"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_105_1150)">
        <path
          d="M83.0868 0.00445488C94.6821 0.00445488 106.277 -0.00859796 117.877 0.00880583C131.126 0.0305606 141.694 8.60628 144.366 21.5591C144.757 23.4561 144.896 25.4401 144.896 27.385C144.931 50.358 144.94 73.331 144.909 96.304C144.892 109.531 136.769 119.986 124.312 122.945C109.458 126.473 94.4037 115.935 92.5502 100.764C92.3544 99.1756 92.2717 97.5962 92.2717 96.0038C92.276 82.233 92.2412 68.4666 92.3152 54.6959C92.3239 52.8859 91.8235 52.4116 90.0223 52.4203C75.8164 52.4943 61.6105 52.4682 47.4047 52.4595C33.734 52.4508 22.0865 41.6909 21.299 28.3596C20.4636 14.2147 30.1358 2.2887 43.9718 0.335127C45.6339 0.100176 47.2785 0.00445488 48.9449 0.00445488C60.3314 0.00445488 71.7091 0.00445488 83.0868 0.00445488Z"
          fill="#114795"
          fillOpacity="0.2"
        />
        <path
          d="M73.0797 98.1008C73.6932 98.7404 73.4452 99.4235 73.4452 100.037C73.4626 109.526 73.4365 119.016 73.4887 128.505C73.4974 129.854 73.071 130.794 72.1268 131.734C56.9377 146.871 41.7659 162.025 26.6464 177.232C25.1366 178.75 24.5971 178.311 23.8052 176.683C21.9299 172.824 21.1729 168.725 21.0641 164.505C20.951 160.163 21.0554 155.812 21.0249 151.465C21.0162 150.469 21.2425 149.681 21.9821 148.946C38.7507 132.212 55.4975 115.457 72.2574 98.7143C72.4923 98.4794 72.7969 98.3097 73.0797 98.1008Z"
          fill="#114795"
          fillOpacity="0.2"
        />
        <path
          d="M21.0379 138.208C21.0379 135.741 21.0379 133.67 21.0379 131.599C21.0379 128.993 21.051 126.382 21.0336 123.771C21.0118 120.121 21.4208 120.473 17.8269 120.46C14.2766 120.447 10.7262 120.473 7.17145 120.447C6.484 120.443 5.73999 120.678 5.06995 120.204C5.13956 119.351 5.85312 119.003 6.33607 118.524C22.1605 102.678 38.0023 86.8536 53.8223 71.0074C54.8057 70.0241 55.6149 69.341 57.1769 70.0415C64.2864 73.2264 69.2769 78.3301 71.9136 85.7049C72.2704 86.6969 72.2747 87.4061 71.4611 88.2154C54.9884 104.64 38.5418 121.091 22.0865 137.533C21.9473 137.681 21.7384 137.764 21.0379 138.208Z"
          fill="#114795"
          fillOpacity="0.2"
        />
        <path
          d="M44.8115 68.2142C44.5809 69.2149 43.924 69.6065 43.4236 70.1068C27.1815 86.362 10.9176 102.604 -5.30269 118.881C-6.40783 119.99 -7.50427 120.547 -9.10107 120.491C-14.6746 120.286 -20.2526 120.661 -25.8261 120.221C-28.5411 120.008 -31.0995 119.333 -33.61 118.385C-36.0596 117.463 -36.0857 117.306 -34.2713 115.492C-24.3294 105.545 -14.3831 95.599 -4.43685 85.6528C0.997482 80.2184 6.43616 74.7885 11.8618 69.3454C12.5928 68.6101 13.2889 68.0184 14.455 68.0227C24.2403 68.0619 34.0299 68.0445 43.8195 68.0532C44.0849 68.0532 44.359 68.1402 44.8115 68.2142Z"
          fill="#114795"
          fillOpacity="0.2"
        />
        <path
          d="M1.2977 68.0097C0.971378 69.1714 0.318737 69.5674 -0.177273 70.0677C-13.6478 83.5513 -27.1271 97.0262 -40.6063 110.497C-42.5512 112.442 -42.6817 112.476 -44.3351 110.244C-50.2176 102.3 -51.7056 93.6107 -47.9159 84.4302C-44.074 75.1323 -36.982 69.4934 -26.9182 68.4013C-17.6376 67.3919 -8.28309 68.249 1.2977 68.0097Z"
          fill="#114795"
          fillOpacity="0.2"
        />
        <path
          d="M73.4582 142.285C73.4582 144.116 73.4582 145.687 73.4582 147.258C73.4582 153.058 73.4582 158.857 73.4582 164.657C73.4539 177.532 65.1349 188.039 52.5824 190.802C44.829 192.508 37.8196 190.58 31.4454 185.999C30.5796 185.376 30.3055 184.863 31.2105 183.962C45.0291 170.2 58.8216 156.412 72.6272 142.637C72.7186 142.546 72.8795 142.524 73.4582 142.285Z"
          fill="#114795"
          fillOpacity="0.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_105_1150">
          <rect
            width="194.927"
            height="191.42"
            fill="white"
            transform="translate(-50)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BgShadow;
