import { useState } from "react";
import { Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import landingPageLogo from "../../LafargeAssets/images/Group 97434.png";
const LafargeCourseWareHeader = () => {
  const { courseId } = useParams();
  const [toggle, setToggle] = useState(true);
  return (
    <div className="lafarge-courseware-header">
      <Container>
        <div className="lafarge-landing-header">
          <div className="lafarge-landing-header-logo">
            <img src={landingPageLogo} alt="Lafarge Logo" />
          </div>

          <div className="lafarge-course-ware-header">
            <div
              className="navbar-toggle-button-right-area"
              onClick={() => setToggle(!toggle)}
            >
              {toggle ? (
                <button className="navbar-button-bar-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </button>
              ) : (
                <button className="navbar-button-close-icon">
                  <span></span>
                  <span></span>
                </button>
              )}
            </div>

            <div
              className={
                toggle ? "desktop-show-navbar-area" : "mobile-show-navbar-area"
              }
            >
              <ul>
                <li>
                  <NavLink
                    activeClassName="active-lafarge-link"
                    to={`/${courseId}/training-details`}
                  >
                    The Concept
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active-lafarge-link"
                    to={`/${courseId}/why-do-we-need`}
                  >
                    Why Do We Need This?
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active-lafarge-link"
                    to={`/${courseId}/negotiation-method`}
                  >
                    Negotiation Process{" "}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="active-lafarge-link"
                    to={`/${courseId}/lafarge-exercise`}
                  >
                    Gamification
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default LafargeCourseWareHeader;
