import React from "react";
import { Spinner, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import deleteIcon from "../../img/close-circle.svg";
import editIcon from "../../img/edit-2.svg";
import profileLogo from "./img/teacher.png";
const StudentsBody = ({ students, loading }) => {
	return (
		<div>
			<div className="packge-header">
				<div className="packge-header-left">
					<div className="title">
						<h3>Students List</h3>
						<p>
							Students List<i className="bx bx-chevron-right"></i>
						</p>
					</div>
				</div>
			</div>
			<div className="package-main-content-list">
				<Table borderless>
					<thead className="package-main-content-list-table-header">
						<tr>
							<th>#</th>
							<th>Image</th>
							<th>User Name</th>
							<th>Email</th>
							<th>Phone Number</th>
							<th>Course</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
						{loading && <Spinner />}
						{!loading &&
							students?.map((item, i) => {
								return (
									<tr key={i}>
										<td>{i + 1}</td>
										<td>
											<img src={profileLogo} alt="" />
										</td>
										<td>
											{item.firstName} {item.lastName}
										</td>

										<td>{item.email}</td>
										<td>{item.phoneNumber}</td>
										<td>
											{item.course.length
												? item.course.map((v, i) => (
														<>{v.courseName} |</>
												  ))
												: "No Data"}
										</td>
										<td>
											<div className="package-control-action-area">
												<Link
													to={`/students-edit/${item._id}`}>
													<img
														src={editIcon}
														alt=""
													/>{" "}
													View
												</Link>
												<button>
													<img
														src={deleteIcon}
														alt=""
													/>
												</button>
											</div>
										</td>
									</tr>
								);
							})}
					</tbody>
				</Table>
			</div>
		</div>
	);
};

export default StudentsBody;
