import React, { useEffect, useState } from "react";
import { RangeStepInput } from "react-range-step-input";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  calculateRevenue,
  courseOwnerValid,
  getReveuneOnTab,
  setSellingPrice,
} from "../../store/actions";

const Revenue = () => {
  let dispatch = useDispatch();
  let { courseId } = useParams();
  const { revenue, sellingPrice, revenueFinal, token } = useSelector(
    (state) => ({
      revenue: state.recruitReducer.revenue,
      revenueFinal: state.recruitReducer.revenueFinal,
      sellingPrice: state.recruitReducer.sellingPrice,
      token: state.Login.token,
    })
  );
  const [activeTab, setActiveTab] = useState("tab1");

  const [monthSerial, setMonthSerial] = useState("");
  const [month1ProductA, setMonth1ProductA] = useState(0);
  const [month1ProductB, setMonth1ProductB] = useState(0);

  const handleChangeMonth1ProductA = (e) => {
    setMonth1ProductA(e.target.value);

    dispatch(setSellingPrice(e.target.value, monthSerial, "prodA"));
  };
  const handleChangeMonth1ProductB = (e) => {
    setMonth1ProductB(e.target.value);

    dispatch(setSellingPrice(e.target.value, monthSerial, "prodB"));
  };

  const handleTab1 = () => {
    // setActiveTab("tab1");
    document.getElementById("month1").style = "background-color: #f7b941";
    document.getElementById("month2").style = "background-color: white";
    setMonthSerial("monthOne");
    dispatch(getReveuneOnTab("monthone"));
  };
  const handleTab2 = () => {
    document.getElementById("month2").style = "background-color: #f7b941";
    document.getElementById("month1").style = "background-color: white";
    setMonthSerial("monthTwo");
    setActiveTab("tab2");
    dispatch(getReveuneOnTab("monthtwo"));
  };

  const handleUnitSold = (monthSerial, prodName, unitSold, e) => {
    e.preventDefault();

    dispatch(calculateRevenue(monthSerial, prodName, unitSold));
  };

  let history = useHistory();
  useEffect(() => {
    dispatch(courseOwnerValid(token, courseId, history));
  }, [revenue]);



  let fun= (e) =>
  {
    e.preventDefault()
    if(!localStorage.getItem("monthOneProdBRevenue") )   localStorage.setItem("monthOneProdBRevenue", 0)
   if( !localStorage.getItem("monthTwoProdBRevenue") )   localStorage.setItem("monthTwoProdBRevenue", 0)
  if(!localStorage.getItem("monthOneProdARevenue"))     localStorage.setItem("monthOneProdARevenue",0)
    if(!localStorage.getItem("monthTwoProdARevenue")) localStorage.setItem("monthTwoProdARevenue",0)
 history.push(`/${courseId}/financial`)
  }
  return (
    <div>
      <div className="container">
        <div className="recruitment-info">
          <h4>Revenue</h4>
          <h5>
            You need to make sure you sell 2 items ( A, B)from the day you
            launch Teaco in Bangladesh, and 1 items in Nepal. ( A). For each
            products to produce, cost will be: A: 2 BDT, B: 1 BDT. Now, you need
            to project how many items do you need to sell to gain 10% of the
            market share in Bangladesh.
          </h5>
        </div>
        <div className="Tabs">
          <ul className="month">
            <li id="month1" onClick={handleTab1}>
              Month 1
            </li>
            <li id="month2" onClick={handleTab2}>
              Month 2
            </li>
          </ul>
          <div className="outlet"></div>
          {monthSerial ? (
            <div>
              <div className="row">
                <div className="col-md-6">
                  <div className="revenue-item">
                    <div className="revenue-item-table-header">
                      <h2>Product A</h2>
                    </div>

                    <table className="table budget-table">
                      <tbody>
                        <tr>
                          <td>Production Cost</td>
                          <td className="single-revenue-data">
                            <p>
                              {revenue?.prodACost
                                ? revenue?.prodACost
                                : monthSerial == "monthOne"
                                ? 1050
                                : monthSerial == "monthTwo"
                                ? parseInt(
                                    localStorage.getItem("prodOne")
                                  ).toFixed(2)
                                : null}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>OPEX</td>
                          <td className="single-revenue-data">
                            <p>
                              {revenue?.opex
                                ? revenue?.opex
                                : localStorage.getItem("totalOpex")}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>Total Cost</td>
                          <td className="single-revenue-data">
                            <p>
                              {revenue?.prodATotalCost
                                ? revenue.prodATotalCost
                                : monthSerial == "monthOne"
                                ? parseInt(
                                    localStorage.getItem(
                                      "totalProdCostAMonthOne"
                                    )
                                  ).toFixed(2)
                                : monthSerial == "monthTwo"
                                ? parseInt(
                                    localStorage.getItem(
                                      "totalProdCostAMonthTwo"
                                    )
                                  ).toFixed(2)
                                : null}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>Competitor’s Price</td>
                          <td className="single-revenue-data">
                            <p>
                              {revenue?.prodAComprice
                                ? revenue?.prodAComprice
                                : 14}
                            </p>
                          </td>
                        </tr>

                        <tr>
                          <td>Price to make </td>
                          <td className="single-revenue-data">
                            <p>14</p>
                          </td>
                        </tr>
                        <tr>
                          <td>Mark Up(%)</td>
                          <td>
                            <div className="asgzhhgfd">
                              <RangeStepInput
                                min={0}
                                max={100}
                                value={month1ProductA}
                                onChange={(e) => handleChangeMonth1ProductA(e)}
                              />

                              <p>
                                {" "}
                                {monthSerial == "monthOne"
                                  ? sellingPrice?.monthOneProdAPercentage
                                    ? parseInt(
                                        sellingPrice?.monthOneProdAPercentage
                                      ).toFixed(2)
                                    : localStorage.getItem(
                                        "monthOneProdAPercentage"
                                      )
                                    ? parseInt(
                                        localStorage.getItem(
                                          "monthOneProdAPercentage"
                                        )
                                      ).toFixed(2)
                                    : 0
                                  : monthSerial == "monthTwo"
                                  ? sellingPrice?.monthTwoProdAPercentage
                                    ? parseInt(
                                        sellingPrice?.monthTwoProdAPercentage
                                      ).toFixed(2)
                                    : localStorage.getItem(
                                        "monthTwoProdAPercentage"
                                      )
                                    ? parseInt(
                                        localStorage.getItem(
                                          "monthTwoProdAPercentage"
                                        )
                                      )
                                    : 0
                                  : 0}{" "}
                                %
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Price To Sell</td>
                          <td>
                            {" "}
                            {monthSerial == "monthOne"
                              ? sellingPrice?.prodAmonthOne
                                ? parseInt(sellingPrice?.prodAmonthOne).toFixed(
                                    2
                                  )
                                : localStorage.getItem("prodAmonthOne")
                                ? parseInt(
                                    localStorage.getItem("prodAmonthOne")
                                  ).toFixed(2)
                                : 0
                              : monthSerial == "monthTwo"
                              ? sellingPrice?.prodAmonthTwo
                                ? parseInt(sellingPrice?.prodAmonthTwo).toFixed(
                                    2
                                  )
                                : localStorage.getItem(
                                    "sellingPrice?.prodAmonthTwo"
                                  )
                                ? localStorage.getItem(
                                    "sellingPrice?.prodAmonthTwo"
                                  )
                                : 0
                              : 0}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="unit-sold">
                    <div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="unit-sold-price">
                            <p>Unit Sold</p>
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="radio-items-wrap">
                            <div className="radio-input-single-item">
                              <input
                                type="radio"
                                id="sold1"
                                name="sold"
                                disabled={
                                  monthSerial == "monthOne"
                                    ? revenueFinal?.unitSoldProdAMonthOne == 0
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdAmonthOne"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdAmonthOne"
                                        ) == "0"
                                        ? true
                                        : false
                                      : false
                                    : monthSerial == "monthTwo"
                                    ? revenueFinal?.unitSoldProdAMonthTwo == 0
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdAmonthTwo"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdAmonthTwo"
                                        ) == "0"
                                        ? true
                                        : false
                                      : false
                                    : false
                                }
                                onChange={(e) =>
                                  handleUnitSold(monthSerial, "prodA", 0, e)
                                }
                              />
                              <label for="sold1">0</label>
                            </div>
                            <div className="radio-input-single-item">
                              <input
                                type="radio"
                                id="sold2"
                                name="sold"
                                disabled={
                                  monthSerial == "monthOne"
                                    ? revenueFinal?.unitSoldProdAMonthOne == 40
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdAmonthOne"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdAmonthOne"
                                        ) == "40"
                                        ? true
                                        : false
                                      : false
                                    : monthSerial == "monthTwo"
                                    ? revenueFinal?.unitSoldProdAMonthTwo == 40
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdAmonthTwo"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdAmonthTwo"
                                        ) == "40"
                                        ? true
                                        : false
                                      : false
                                    : false
                                }
                                onChange={(e) =>
                                  handleUnitSold(monthSerial, "prodA", 40, e)
                                }
                              />{" "}
                                <label for="sold2">40</label>
                            </div>
                            <div className="radio-input-single-item">
                              <input
                                type="radio"
                                id="sold3"
                                name="sold"
                                disabled={
                                  monthSerial == "monthOne"
                                    ? revenueFinal?.unitSoldProdAMonthOne == 50
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdAmonthOne"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdAmonthOne"
                                        ) == "50"
                                        ? true
                                        : false
                                      : false
                                    : monthSerial == "monthTwo"
                                    ? revenueFinal?.unitSoldProdAMonthTwo == 50
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdAmonthTwo"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdAmonthTwo"
                                        ) == "50"
                                        ? true
                                        : false
                                      : false
                                    : false
                                }
                                onChange={(e) =>
                                  handleUnitSold(monthSerial, "prodA", 50, e)
                                }
                              />
                              <label for="sold3">50</label>
                            </div>
                            <div className="radio-input-single-item">
                              <input
                                type="radio"
                                id="sold4"
                                name="sold"
                                disabled={
                                  monthSerial == "monthOne"
                                    ? revenueFinal?.unitSoldProdAMonthOne == 60
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdAmonthOne"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdAmonthOne"
                                        ) == "60"
                                        ? true
                                        : false
                                      : false
                                    : monthSerial == "monthTwo"
                                    ? revenueFinal?.unitSoldProdAMonthTwo == 60
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdAmonthTwo"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdAmonthTwo"
                                        ) == "60"
                                        ? true
                                        : false
                                      : false
                                    : false
                                }
                                onChange={(e) =>
                                  handleUnitSold(monthSerial, "prodA", 60, e)
                                }
                              />{" "}
                              <label for="sold4">60</label>
                            </div>
                            <div className="radio-input-single-item">
                              <input
                                type="radio"
                                id="sold5"
                                name="sold"
                                disabled={
                                  monthSerial == "monthOne"
                                    ? revenueFinal?.unitSoldProdAMonthOne == 70
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdAmonthOne"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdAmonthOne"
                                        ) == "70"
                                        ? true
                                        : false
                                      : false
                                    : monthSerial == "monthTwo"
                                    ? revenueFinal?.unitSoldProdAMonthTwo == 70
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdAmonthTwo"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdAmonthTwo"
                                        ) == "70"
                                        ? true
                                        : false
                                      : false
                                    : false
                                }
                                onChange={(e) =>
                                  handleUnitSold(monthSerial, "prodA", 70, e)
                                }
                              />
                              <label fro="sold5">70</label>
                            </div>
                            <div className="radio-input-single-item">
                              <input
                                type="radio"
                                id="sold6"
                                name="sold"
                                disabled={
                                  monthSerial == "monthOne"
                                    ? revenueFinal?.unitSoldProdAMonthOne == 80
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdAmonthOne"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdAmonthOne"
                                        ) == "80"
                                        ? true
                                        : false
                                      : false
                                    : monthSerial == "monthTwo"
                                    ? revenueFinal?.unitSoldProdAMonthTwo == 80
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdAmonthTwo"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdAmonthTwo"
                                        ) == "80"
                                        ? true
                                        : false
                                      : false
                                    : false
                                }
                                onChange={(e) =>
                                  handleUnitSold(monthSerial, "prodA", 80, e)
                                }
                              />
                              <label for="sold6">80</label>
                            </div>
                            <br />
                          </div>

                          <br />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="revenue-sold">
                    <div>
                      <div className="row">
                        <div className="col-md-4 my-auto">
                          <div className="unit-sold-price">
                            <p>Selected Unit </p>
                          </div>
                        </div>
                        <div className="col-md-8 my-auto">
                          <div className="unit-sold-price">
                            <div>
                              <h6>
                                {monthSerial == "monthOne"
                                  ? localStorage.getItem(
                                      "unitSoldProdAmonthOne"
                                    )
                                  : monthSerial == "monthTwo"
                                  ? localStorage.getItem(
                                      "unitSoldProdAmonthTwo"
                                    )
                                  : 0}{" "}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="revenue-sold">
                    <div>
                      <div className="row">
                        <div className="col-md-4 my-auto">
                          <div className="unit-sold-price">
                            <p>Total Revenue</p>
                          </div>
                        </div>
                        <div className="col-md-8 my-auto">
                          <div className="unit-sold-price">
                            <div>
                              <h6>
                                {" "}
                                {revenue?.unitSoldProdAmonthOne
                                  ? revenue?.unitSoldProdmonthOne
                                  : monthSerial == "monthOne"
                                  ? localStorage.getItem("monthOneProdARevenue")
                                    ? localStorage.getItem(
                                        "monthOneProdARevenue"
                                      )
                                    : 0
                                  : monthSerial == "monthTwo"
                                  ? localStorage.getItem("monthTwoProdARevenue")
                                    ? localStorage.getItem(
                                        "monthTwoProdARevenue"
                                      )
                                    : 0
                                  : 0}
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="revenue-item">
                    <div className="revenue-item-table-header">
                      <h2>Product B</h2>
                    </div>

                    <table className="table budget-table">
                      <tbody>
                        <tr>
                          <td>Production Cost</td>
                          <td className="single-revenue-data">
                            <p>
                              {" "}
                              {revenue?.prodBCost
                                ? revenue?.prodBCost
                                : monthSerial == "monthOne"
                                ? 880
                                : monthSerial == "monthTwo"
                                ? localStorage.getItem("prodTwo")
                                : 0}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>OPEX</td>
                          <td className="single-revenue-data">
                            <p>{revenue?.opex}</p>
                          </td>
                        </tr>
                        <tr>
                          <td>Total Cost</td>
                          <td className="single-revenue-data">
                            <p>
                              {revenue?.prodBTotalCost
                                ? revenue.prodBTotalCost
                                : monthSerial == "monthOne"
                                ? localStorage.getItem("totalProdCostBMonthOne")
                                : monthSerial == "monthTwo"
                                ? localStorage.getItem("totalProdCostBMonthTwo")
                                : 0}
                            </p>
                          </td>
                        </tr>
                        <tr>
                          <td>Competitor’s Price</td>
                          <td className="single-revenue-data">
                            <p>
                              {revenue?.prodBComprice
                                ? revenue?.prodBComprice
                                : 16}
                            </p>
                          </td>
                        </tr>

                        <tr>
                          <td>Price to make</td>
                          <td className="single-revenue-data">
                            <p>16</p>
                          </td>
                        </tr>
                        <tr>
                          <td>Mark Up(%)</td>
                          <td>
                            <div className="asgzhhgfd">
                              <RangeStepInput
                                min={0}
                                max={100}
                                value={month1ProductB}
                                onChange={handleChangeMonth1ProductB}
                              />
                              <p>
                                {" "}
                                {monthSerial == "monthOne"
                                  ? sellingPrice?.monthOneProdBPercentage
                                    ? parseInt(
                                        sellingPrice?.monthOneProdBPercentage
                                      ).toFixed(2)
                                    : localStorage.getItem(
                                        "monthOneProdBPercentage"
                                      )
                                    ? parseInt(
                                        localStorage.getItem(
                                          "monthOneProdBPercentage"
                                        )
                                      )
                                    : 0
                                  : monthSerial == "monthTwo"
                                  ? sellingPrice?.monthTwoProdBPercentage
                                    ? parseInt(
                                        sellingPrice?.monthTwoProdBPercentage
                                      ).toFixed(2)
                                    : localStorage.getItem(
                                        "monthTwoProdBPercentage"
                                      )
                                    ? parseInt(
                                        localStorage.getItem(
                                          "monthTwoProdBPercentage"
                                        )
                                      )
                                    : 0
                                  : 0}{" "}
                                %
                              </p>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Price To Sell</td>
                          <td>
                            {" "}
                            {monthSerial == "monthOne"
                              ? sellingPrice?.prodBmonthOne
                                ? parseInt(sellingPrice?.prodBmonthOne).toFixed(
                                    2
                                  )
                                : localStorage.getItem("prodBmonthOne")
                                ? localStorage.getItem("prodBmonthOne")
                                : 0
                              : monthSerial == "monthTwo"
                              ? sellingPrice?.prodBmonthTwo
                                ? parseInt(sellingPrice?.prodBmonthTwo).toFixed(
                                    2
                                  )
                                : localStorage.getItem("prodBmonthTwo")
                                ? localStorage.getItem("prodBmonthTwo")
                                : 0
                              : 0}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="unit-sold">
                    <div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="unit-sold-price">
                            <p>Unit Sold</p>
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="radio-items-wrap">
                            <div className="radio-input-single-item">
                              <input
                                type="radio"
                                id="sold1"
                                name="sold"
                                disabled={
                                  monthSerial == "monthOne"
                                    ? revenueFinal?.unitSoldProdBMonthOne == 0
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdBmonthOne"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdBmonthOne"
                                        ) == "0"
                                        ? true
                                        : false
                                      : false
                                    : monthSerial == "monthTwo"
                                    ? revenueFinal?.unitSoldProdBMonthTwo == 0
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdBmonthTwo"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdBmonthTwo"
                                        ) == "0"
                                        ? true
                                        : false
                                      : false
                                    : false
                                }
                                onClick={(e) =>
                                  handleUnitSold(monthSerial, "prodB", 0, e)
                                }
                              />
                              <label for="sold1">0</label>
                            </div>
                            <div className="radio-input-single-item">
                              <input
                                type="radio"
                                id="sold2"
                                name="sold"
                                disabled={
                                  monthSerial == "monthOne"
                                    ? revenueFinal?.unitSoldProdBMonthOne == 40
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdBmonthOne"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdBmonthOne"
                                        ) == "40"
                                        ? true
                                        : false
                                      : false
                                    : monthSerial == "monthTwo"
                                    ? revenueFinal?.unitSoldProdBMonthTwo == 40
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdBmonthTwo"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdBmonthTwo"
                                        ) == "40"
                                        ? true
                                        : false
                                      : false
                                    : false
                                }
                                onClick={(e) =>
                                  handleUnitSold(monthSerial, "prodB", 40, e)
                                }
                              />
                                <label for="sold2">40</label>
                            </div>
                            <div className="radio-input-single-item">
                              <input
                                type="radio"
                                id="sold3"
                                name="sold"
                                disabled={
                                  monthSerial == "monthOne"
                                    ? revenueFinal?.unitSoldProdBMonthOne == 50
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdBmonthOne"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdBmonthOne"
                                        ) == "50"
                                        ? true
                                        : false
                                      : false
                                    : monthSerial == "monthTwo"
                                    ? revenueFinal?.unitSoldProdBMonthTwo == 50
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdBmonthTwo"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdBmonthTwo"
                                        ) == "50"
                                        ? true
                                        : false
                                      : false
                                    : false
                                }
                                onClick={(e) =>
                                  handleUnitSold(monthSerial, "prodB", 50, e)
                                }
                              />
                              <label for="sold3">50</label>
                            </div>
                            <div className="radio-input-single-item">
                              <input
                                type="radio"
                                id="sold4"
                                name="sold"
                                disabled={
                                  monthSerial == "monthOne"
                                    ? revenueFinal?.unitSoldProdBMonthOne == 60
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdBmonthOne"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdBmonthOne"
                                        ) == "60"
                                        ? true
                                        : false
                                      : false
                                    : monthSerial == "monthTwo"
                                    ? revenueFinal?.unitSoldProdBMonthTwo == 60
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdBmonthTwo"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdBmonthTwo"
                                        ) == "60"
                                        ? true
                                        : false
                                      : false
                                    : false
                                }
                                onClick={(e) =>
                                  handleUnitSold(monthSerial, "prodB", 60, e)
                                }
                              />{" "}
                              <label for="sold4">60</label>
                            </div>
                            <div className="radio-input-single-item">
                              <input
                                type="radio"
                                id="sold5"
                                name="sold"
                                disabled={
                                  monthSerial == "monthOne"
                                    ? revenueFinal?.unitSoldProdBMonthOne == 70
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdBmonthOne"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdBmonthOne"
                                        ) == "70"
                                        ? true
                                        : false
                                      : false
                                    : monthSerial == "monthTwo"
                                    ? revenueFinal?.unitSoldProdBMonthTwo == 70
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdBmonthTwo"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdBmonthTwo"
                                        ) == "70"
                                        ? true
                                        : false
                                      : false
                                    : false
                                }
                                onClick={(e) =>
                                  handleUnitSold(monthSerial, "prodB", 70, e)
                                }
                              />
                              <label fro="sold5">70</label>
                            </div>
                            <div className="radio-input-single-item">
                              <input
                                type="radio"
                                id="sold6"
                                name="sold"
                                disabled={
                                  monthSerial == "monthOne"
                                    ? revenueFinal?.unitSoldProdBMonthOne == 80
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdBmonthOne"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdBmonthOne"
                                        ) == "80"
                                        ? true
                                        : false
                                      : false
                                    : monthSerial == "monthTwo"
                                    ? revenueFinal?.unitSoldProdBMonthTwo == 80
                                      ? true
                                      : localStorage.getItem(
                                          "unitSoldProdBmonthTwo"
                                        )
                                      ? localStorage.getItem(
                                          "unitSoldProdBmonthTwo"
                                        ) == "80"
                                        ? true
                                        : false
                                      : false
                                    : false
                                }
                                onClick={(e) =>
                                  handleUnitSold(monthSerial, "prodB", 80, e)
                                }
                              />
                              <label for="sold6">80</label>
                            </div>
                            <br />
                          </div>

                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="revenue-sold">
                      <div>
                        <div className="row">
                          <div className="col-md-4 my-auto">
                            <div className="unit-sold-price">
                              <p>Selected Unit </p>
                            </div>
                          </div>
                          <div className="col-md-8 my-auto">
                            <div className="unit-sold-price">
                              <div>
                                <h6>
                                  {monthSerial == "monthOne"
                                    ? localStorage.getItem(
                                        "unitSoldProdBmonthOne"
                                      )
                                    : monthSerial == "monthTwo"
                                    ? localStorage.getItem(
                                        "unitSoldProdBmonthTwo"
                                      )
                                    : null}{" "}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="revenue-sold">
                      <div>
                        <div className="row">
                          <div className="col-md-4 my-auto">
                            <div className="unit-sold-price">
                              <p>Total Revenue </p>
                            </div>
                          </div>
                          <div className="col-md-8 my-auto">
                            <div className="unit-sold-price">
                              <div>
                                <h6>
                                  {revenue?.monthOneProdBRevenue
                                    ? revenue?.monthOneProdBRevenue
                                    : monthSerial == "monthOne"
                                    ? localStorage.getItem(
                                        "monthOneProdBRevenue"
                                      )
                                      ? localStorage.getItem(
                                          "monthOneProdBRevenue"
                                        )
                                      : 0
                                    : monthSerial == "monthTwo"
                                    ? localStorage.getItem(
                                        "monthTwoProdBRevenue"
                                      )
                                      ? localStorage.getItem(
                                          "monthTwoProdBRevenue"
                                        )
                                      : 0
                                    : 0}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <UnitsRevenue
									prodName="prodB"
									monthSerial={monthSerial}
								/> */}
                  </div>
                </div>
                <div className="case-summery-btn">
                  <Link to={`/${courseId}/budgeting`}>
                    <div>
                      <button className="case-summery-previous-btn">
                        Previous
                      </button>
                    </div>
                  </Link>
                  <Link >
                    <div>
                      <button className="case-summery-next-btn" onClick={e => fun(e)}>Next</button>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Revenue;
