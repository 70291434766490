//Users
//Competitive Products
import { del, get, patch, post } from './api_helper';

// new Code
export const postData = (url, data, authToken) =>
  post(url, data, {
    headers: { authtoken: authToken },
  });

export const getData = (url, authToken) =>
  get(url, {
    headers: { authtoken: authToken },
  });

export const patchData = (url, data, authToken) =>
  patch(url, data, {
    headers: { authtoken: authToken },
  });

export const deleteData = (url, authToken) =>
  del(url, {
    headers: { authtoken: authToken },
  });

export const postNonAuth = (url, data) => post(url, data);

export const patchNonAuth = (url, data) => patch(url, data);
