import React, { useEffect, useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  courseCreate,
  courseRelatedPreviousMultiData,
  uploadCoursePhoto,
  getSingleCourse
} from "../../../../store/actions";
import { options } from "./Data";

const AllNewCourseCreateBody = () => {
  let dispatch = useDispatch();
  let history = useHistory();
  let [courseFormatId, setCourseFormatId] = useState([]);
  let [courseAcademicId, setCourseAcademicId] = useState([]);
  let [courseAuthorId, setCourseAuthorId] = useState([]);
  let [courseTitle, setCourseTitle] = useState("");
  let [noOfPages, setNoOfPages] = useState("");
  let [coursePrice, setCoursePrice] = useState("");
  let [courseCode, setCourseCode] = useState("");
  let [estimatedTime, setEstimatedTime] = useState("");
  let [typeOfPublication, setTypeOfPublication] = useState([]);
  let [courseSummary, setCourseSummary] = useState("");
  let [courseLanguage, setCourseLanguage] = useState("");
  let [subjectCoverd, setSubjectCoverd] = useState("");
  let [photo, setPhoto] = useState("");
  let [duration, setDuration] = useState("");
  const { token, loading, courseRelatedData, coursePhoto } = useSelector((state) => ({
    token: state.Login.token,
    loading: state.AdminAuthorReducer.loading,
    courseRelatedData: state.AdminAuthorReducer.courseRelatedData,
    coursePhoto : state.AdminAuthorReducer.coursePhoto
    // photo : state.AdminAuthorReducer.photo
  }));
  useEffect(() => {
    dispatch(courseRelatedPreviousMultiData(token));
  }, []);

  //course format id set
  const handleStoreCourseFormatId = (selectedOption) => {
    console.log("hello data", selectedOption);
    setCourseFormatId(selectedOption);
  };

  //set course author id
  const hanldeStoreCourseAuthorId = (selectedOption) =>
    setCourseAuthorId(selectedOption);

  //course academic id set
  const hanldeStoreCourseAcademicId = (selectedOption) =>
    setCourseAcademicId(selectedOption);
  //type of publications
  const handleStoreTypeOfPublication = (selectedOption) =>
    setTypeOfPublication(selectedOption);
  const handleSubmitData = (e) => {
    e.preventDefault();
 
    let courseFormatIdArr = [];
    let courseAcademicIdArr = [];
    courseFormatId.map((v, i) => {
      courseFormatIdArr.push(v.value);
    });
    courseAcademicId.map((v, i) => {
      courseAcademicIdArr.push(v.value);
    });

    if (
      !courseFormatId.length ||
      !courseFormatId.length ||
      !courseAuthorId.value ||
      !courseTitle ||
      !noOfPages ||
      !coursePrice ||
      !courseCode ||
      !duration ||
      !typeOfPublication.value ||
      !courseSummary ||
      !courseLanguage ||
      !subjectCoverd ||
        
      !duration
    ) {
      toast.error("PLease filled all the fill correctly");
    } else {
      // let formData = new FormData();

      // formData.append("category", JSON.stringify(courseFormatIdArr));
      // formData.append("academicArea", JSON.stringify(courseAcademicIdArr));
      // formData.append("author", courseAuthorId.value);
      // formData.append("courseName", courseTitle);
      // formData.append("noOfPages", noOfPages);
      // formData.append("price", coursePrice);
      // formData.append("code", courseCode);
      // formData.append("estimatedTime", estimatedTime);
      // formData.append("typeOfPublication", typeOfPublication.value);
      // formData.append("description", courseSummary);
      // formData.append("language", courseLanguage);
      // formData.append("subjectCoverd", subjectCoverd);
      // formData.append("photo", photo);
      // formData.append("duration", duration);

      console.log("hello data", courseFormatId, courseAcademicId)
      
      
      dispatch(courseCreate(token,
       courseTitle, courseCode, noOfPages, courseFormatIdArr,
        coursePrice, estimatedTime, courseSummary,  subjectCoverd, courseLanguage,
        courseAcademicIdArr, courseAuthorId.value, typeOfPublication.value,
        duration,   coursePhoto, history));
    }
  };

  const handleUploadImage = e =>
  {
    console.log("hello photo", e.target.files[0])
    let formData = new FormData();
    formData.append("photo", e.target.files[0])
    dispatch(uploadCoursePhoto(token, formData))
  }
  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>Create New Course</h3>
            <p>
              Course list {`>`} <Link to={"/course"}>course</Link>  {`>`} <span>Create New Course</span>{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="package-main-content-list new-main-area">
        <div className="new-individual-course-inner-wrap">
          <div className="new-individual-course-title">
            <h4>Create New Course</h4>
          </div>
          <Form>
            <Row>
              <Col lg={6}>
                <Row className="g-0">
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Type course name"
                          name="categoryName"
                          value={courseTitle}
                          onChange={(e) => setCourseTitle(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </Col>{" "}
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>No Of Pages</Form.Label>
                        <Form.Control
                          type="Number"
                          placeholder="Enter no of pages"
                          name="category"
                          value={noOfPages}
                          onChange={(e) => setNoOfPages(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </Col>{" "}
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Course Price</Form.Label>
                        <Form.Control
                          type="Number"
                          placeholder="Enter Course Price"
                          name="categoryName"
                          value={coursePrice}
                          onChange={(e) => setCoursePrice(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Label>Course Academic Area</Form.Label>
                      <Select
                        value={courseAcademicId}
                        isMulti
                        onChange={hanldeStoreCourseAcademicId}
                        options={courseRelatedData?.courseAcademicArr}
                      />
                    </div>
                  </Col>{" "}
                </Row>
              </Col>{" "}
              <Col lg={6}>
                <Row className="g-0">
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Course Code</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Type Course Code"
                          name="categoryName"
                          value={courseCode}
                          onChange={(e) => setCourseCode(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </Col>{" "}
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Course Format</Form.Label>
                        <Select
                          value={courseFormatId}
                          isMulti
                          onChange={handleStoreCourseFormatId}
                          options={courseRelatedData?.courseFormatArr}
                        />
                      </Form.Group>
                    </div>
                  </Col>{" "}
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Group className="mb-3">
                        <Form.Label>Estimated Time</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Enter estimated time"
                          name="category"
                          value={estimatedTime}
                          onChange={(e) => setEstimatedTime(e.target.value)}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="input-data-single-field">
                      <Form.Label>Type Of Publication</Form.Label>
                      <Select
                        value={typeOfPublication}
                        options={options}
                        onChange={handleStoreTypeOfPublication}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={12}>
                <div className="input-data-single-field">
                  <Form.Group
                    className="mb-3 mt-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>Course Summary</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter text here..."
                      value={courseSummary}
                      onChange={(e) => setCourseSummary(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </Col>
              <Col lg={6}>
                <div className="input-data-single-field">
                  <Form.Group className="mb-3">
                    <Form.Label>Course Language</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type course language"
                      name="category"
                      value={courseLanguage}
                      onChange={(e) => setCourseLanguage(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </Col>
              <Col lg={6}>
                <div className="input-data-single-field">
                  <Form.Group className="mb-3">
                    <Form.Label>Author </Form.Label>
                    <Select
                      value={courseAuthorId}
                      options={courseRelatedData?.authorArr}
                      onChange={hanldeStoreCourseAuthorId}
                    />
                  </Form.Group>
                </div>
              </Col>
              <Col lg={6}>
                <div className="input-data-single-field">
                  <Form.Group className="mb-3">
                    <Form.Label>Subject Covered</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="List of topics covered"
                      name="author"
                      value={subjectCoverd}
                      onChange={(e) => setSubjectCoverd(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </Col>
              {/* Course validation */}
              <Col lg={6}>
                <div className="input-data-single-field">
                  <Form.Group className="mb-3">
                    <Form.Label>Course Validation</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Course validation in days"
                      name="validation For"
                      value={duration}
                      onChange={(e) => setDuration(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </Col>
              <Col lg={6}>
                <div className="input-data-single-field">
                  <Form.Group className="mb-3">
                    <Form.Label>Upload Course image</Form.Label>
                    {coursePhoto &&
                         <img className="autor" src={`${process.env.REACT_APP_IMAGE_URL}${coursePhoto}`} height={"70px"} />

                       } 
                    <Form.Control
                      type="file"
                      placeholder="Type category name"
                      name="coursePhoto"
                      onChange={(e) =>handleUploadImage(e)}
                    />
                  </Form.Group>
                </div>
              </Col>
            </Row>
            {loading ? (
              <Spinner />
            ) : (
              <div className="form-submit-button-area-wrap">
                <button
                  type="submit"
                  className="btn create-new-item"
                  onClick={(e) => handleSubmitData(e)}
                >
                  Create New
                </button>
                <button type="submit" className="btn save-to-new-item-draft">
                  Save as Draft
                </button>
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AllNewCourseCreateBody;
