import React from "react";
import MainFooter from "../Common/footer/MainFooter";
import LoginHeader from "../Common/LoginHeader";
import CourseDetails from "./CourseDetails";
import "./CoursewareDetails.scss";

const CourseWareDetailsPageAfterLogin = () => {
  return (
    <div>
      <LoginHeader />
      <CourseDetails />
      <MainFooter />
    </div>
  );
};

export default CourseWareDetailsPageAfterLogin;
