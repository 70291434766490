export const GET_LAFARGE_COURSE = "GET_LAFARGE_COURSE";
export const GET_LAFARGE_COURSE_SUCCESS = "GET_LAFARGE_COURSE_SUCCESS";
export const GET_LAFARGE_COURSE_FAIL = "GET_LAFARGE_COURSE_FAIL";

export const GET_USER = "GET_USER";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAIL = "GET_USER_FAIL";

export const GET_TOTAL_USER = "GET_TOTAL_USER";
export const GET_TOTAL_USER_SUCCESS = "GET_TOTAL_USER_SUCCESS";
export const GET_TOTAL_USER_FAIL = "GET_TOTAL_USER_FAIL";

export const GET_STUDENT_ANSWER = "GET_STUDENT_ANSWER";
export const GET_STUDENT_ANSWER_SUCCESS = "GET_STUDENT_ANSWER_SUCCESS";
export const GET_STUDENT_ANSWER_FAIL = "GET_STUDENT_ANSWER_FAIL";

//total marks updated
export const UPDATE_TOTAL_MARKS = "UPDATE_TOTAL_MARKS";
export const UPDATE_TOTAL_MARKS_SUCCESS = "UPDATE_TOTAL_MARKS_SUCCESS";
export const UPDATE_TOTAL_MARKS_FAIL = "UPDATE_TOTAL_MARKS_FAIL";

export const STORE_TOTAL_MARKS_DATA = "STORE_TOTAL_MARKS_DATA";
