import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import loginBeforeImage from "./img/emptyImage.png";
import { useDispatch, useSelector } from "react-redux";

const BeforeLoginCartBody = () =>
{
  const { token,  } = useSelector((state) => ({
    token: state.Login.token,
    
  }))
  return (
    <div>
      <Container>
        <div className="cart-page-inner-main-wrap">
          <div className="cart-header-title">
            <h4>Cart</h4>
          </div>
          <div className="cart-main-area-wrap-cart-body-gfhdjskujcm uyghbjnkiouhjn">
            <span>
              {" "}
              <img src={loginBeforeImage} alt="" />             
                {!token ?
                <p> Please <Link to="/login">login<Link/> before Check Out. If you
                haven't <Link to="/registration">registered</Link> already
                    please register.</Link></p>  : <p>Pleae Go To <Link to ="/courses">Courses Page</Link> to add course</p>
                  }
            </span>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BeforeLoginCartBody;
