import React from "react";
import { Container } from "react-bootstrap";
import CalibraX from "./CalibraX";
import "./service.css";
const Service = () => {
  return (
    <div className="service-main-page">
      <Container>
        {/* <TeacherPart />
        <Students/> */}
        <CalibraX />
      </Container>
    </div>
  );
};

export default Service;
