import { useState } from "react";
import { Modal } from "react-bootstrap";
import coursewareLogo from "../../LafargeAssets/images/coursewarePopupBg.png";
import landingPageLogo from "../../LafargeAssets/images/Group 97434.png";
const LafargeLandingHeader = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <>
        <>
          <Modal show={show} onHide={handleClose} centered>
            <div className="lafarge-contect-form-popup ">
              <div className="lafarge-contect-form-popup-header">
                <Modal.Header closeButton>
                  <Modal.Title>Contact Us</Modal.Title>
                </Modal.Header>
              </div>
              <div className="lafarge-contect-form-popup-body px-3 pb-3">
                <p>
                  Have any queries? <br /> Contact here: 01713443520
                </p>
                <img src={coursewareLogo} alt="" />
              </div>
            </div>
          </Modal>
        </>
        <div className="lafarge-landing-header">
          <div className="lafarge-landing-header-logo">
            <img src={landingPageLogo} alt="Lafarge Logo" />
          </div>
          <div className="lafarge-landing-header-right">
            <button onClick={handleShow}>Contact Us</button>
          </div>
        </div>
      </>
    </div>
  );
};

export default LafargeLandingHeader;
