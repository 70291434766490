import React from "react";
import { Link } from "react-router-dom";
import "./third-game.scss";
const ThirdGame = () => {
  return (
    <div>
      <div className="container-xl">
        <div className="case-summery-list jhsjkgjg">
          <h4 className="mb-4">Introduction</h4>
          <p>
            WalKart is a furniture manufacturer which wants to expand its
            buisness in south east Asia. Their flagship brand, Teaco is a
            household name and they are not available in the region. Currently,
            the size of the industry in Bangladesh is 1000 BDT, and there are 5
            players who are purely manufacturers. T mart is the market leader
            having 25% share. Now you are assigned as commercial head who would
            launch Teaco in Bangladesh first, and then in Nepal. Few elements
            that you would consider:1. You have total 15 BDT to launch Teaco.
            You need to decide how much would you spend in Bangladesh and in
            Nepal. Remember, Bangladesh has 3X more consumers than Nepal. You
            need to recruit 3 managers, one HR manager, and one BDM, and 1 Sales
            manager in your team. For that you have only 5 BDT budget, and sales
            manager can only have salaries in sales, no fixed amount therefore.
            You need to make sure you sell 2 items ( A, B)from the day you
            launch Teaco in Bangladesh, and 1 items in Nepal. ( A). For each
            products to produce, cost will be: A: 2 BDT, B: 1 BDT. Now, you need
            to project how many items do you need to sell to gain 10% of the
            market share in Bangladesh. You should bear in mind that competitor
            for A priced at 3 BDT, and B priced at 6 BDT. Put your price too.
            Based on your pricing, what would be the revenues you would get in
            month 1, and if you gain 10% additional sales in B plus 30%
            additional sales for B in Month 2, how much would you revenue you
            would earn in 2 months timeline. You need to make a cash flow
            statement, and profit and loss statement from the table. To make it
            perfect please choose from the list.
          </p>
          <div className="case-summery-btn">
            <Link to="/">
              <div>
                <button className="case-summery-previous-btn">Previous</button>
              </div>
            </Link>
            <Link to={`/economic-super-power-introduction`}>
              <div>
                <button className="case-summery-next-btn">Next</button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThirdGame;
