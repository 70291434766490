import React,  {useEffect} from "react";
import { Link, useHistory , useParams } from "react-router-dom";
import { Card, CardBody, Col, Row } from "reactstrap";
import "../../assests/styles.css";
import Apaexlinecolumn from "../AllCharts/apex/apaexlinecolumn";
import CostApexLineColumn from "../AllCharts/apex/costApaexlinecolumn";
import DonutChart from "../AllCharts/apex/dountchart";
import PricingVsCompetitors from "../AllCharts/apex/PricingVsCompetitors";
import ValueShare from "../AllCharts/apex/valueShare";
import { useDispatch, useSelector } from "react-redux";
 import { courseOwnerValid } from "../../store/actions";
const FinancialData = () =>
{
  let {courseId} = useParams()
  //market share
  let unitSoldProdAmonthOne =
    localStorage.getItem("unitSoldProdAmonthOne") !== null
      ? localStorage.getItem("unitSoldProdAmonthOne")
      : 0;
  let unitSoldProdBmonthOne =
    localStorage.getItem("unitSoldProdBmonthOne") !== null
      ? localStorage.getItem("unitSoldProdBmonthOne")
      : 0;
  let unitSoldProdAmonthTwo =
    localStorage.getItem("unitSoldProdAmonthTwo") !== null
      ? localStorage.getItem("unitSoldProdAmonthTwo")
      : 0;
  let unitSoldProdBmonthTwo =
    localStorage.getItem("unitSoldProdBmonthTwo") !== null
      ? localStorage.getItem("unitSoldProdBmonthTwo")
      : 0;

  let unitSold =
    parseInt(unitSoldProdAmonthOne) +
    parseInt(unitSoldProdBmonthOne) +
    parseInt(unitSoldProdAmonthTwo) +
    parseInt(unitSoldProdBmonthTwo);

  let marketShare = (unitSold / 2000) * 100;
  localStorage.setItem("totalMarketShare", marketShare);

  //total Reveneu count
  let monthOneProdARevenue =
    localStorage.getItem("monthOneProdARevenue") !== null
      ? localStorage.getItem("monthOneProdARevenue")
      : 0;
  let monthOneProdBRevenue =
    localStorage.getItem("monthOneProdBRevenue") !== null
      ? localStorage.getItem("monthOneProdBRevenue")
      : 0;
  let monthTwoProdARevenue =
    localStorage.getItem("monthTwoProdARevenue") !== null
      ? localStorage.getItem("monthTwoProdARevenue")
      : 0;
  let monthTwoProdBRevenue =
    localStorage.getItem("monthTwoProdBRevenue") !== null
      ? localStorage.getItem("monthTwoProdBRevenue")
      : 0;

  let totalRevenue =
    parseInt(monthOneProdARevenue) +
    parseInt(monthOneProdBRevenue) +
    parseInt(monthTwoProdARevenue) +
    parseInt(monthTwoProdBRevenue);
  localStorage.setItem("totalMonthReveneu", totalRevenue);
  let totalValueShare = (parseInt(totalRevenue) / 20000) * 100;
  localStorage.setItem("totalValueShare", totalValueShare);
  const { token,  } = useSelector((state) => ({
    token: state.Login.token,
  
  }));
   let dispatch = useDispatch()
  let history = useHistory()
   useEffect(() =>
  {
    dispatch(courseOwnerValid(token,courseId, history ))
  },[])


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container">
          <div className="all-graph-item">
            <Row>
              <Col sm={4}>
                <div className="graph-item">
                  <Card>
                    <CardBody>
                      <div className="net-profit">
                        <div className="net-profit-single">
                          <h5>Net Profit</h5>
                          <h4>
                            {localStorage.getItem("netProfit")
                              ? localStorage.getItem("netProfit")
                              : 0}
                          </h4>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Col>

              <Col sm={4}>
                <div className="graph-item">
                  <Card>
                    <div className="graph-item-table-header">
                      <h2>Market Share</h2>
                    </div>
                    <CardBody>
                      {/* <CardTitle className="mb-4">Donut Chart</CardTitle> */}
                      <DonutChart />
                    </CardBody>
                  </Card>
                </div>
              </Col>
              <Col sm={4}>
                <div className="graph-item">
                  <Card>
                    <div className="graph-item-table-header">
                      <h2>Value Share</h2>
                    </div>
                    <CardBody>
                      {/* <CardTitle className="mb-4">Donut Chart</CardTitle> */}
                      <ValueShare />
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm={4}>
                <div className="graph-item">
                  <Card>
                    <div className="graph-item-table-header">
                      <h2>Revenue</h2>
                    </div>
                    <CardBody>
                      {/* <CardTitle className="mb-4"> Column Chart </CardTitle> */}
                      <Apaexlinecolumn />
                    </CardBody>
                  </Card>
                </div>
              </Col>
              <Col sm={4}>
                <div className="graph-item">
                  <Card>
                    <div className="graph-item-table-header">
                      <h2>Cost</h2>
                    </div>
                    <CardBody>
                      {/* <CardTitle className="mb-4"> Column Chart </CardTitle> */}
                      <CostApexLineColumn />
                    </CardBody>
                  </Card>
                </div>
              </Col>
              <Col sm={4}>
                <div className="graph-item">
                  <Card>
                    <div className="graph-item-table-header">
                      <h2>Pricing vs Competitor’s</h2>
                    </div>
                    <CardBody>
                      {/* <CardTitle className="mb-4">Bar Chart</CardTitle> */}
                      <PricingVsCompetitors />
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
          <div className="case-summery-btn">
            <Link to={`/${courseId}/financial`}>
              <div>
                <button className="case-summery-previous-btn">Previous</button>
              </div>
            </Link>
            <Link to={`/${courseId}/financial-data`}>
              <div>
                <button className="case-summery-next-btn">Next</button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FinancialData;
