import React from "react";
import MainFooter from "../Common/footer/MainFooter";
import LoginHeader from "../Common/LoginHeader";
import "./studentProfileView.scss";
import TeacherProfileEditPage from "./TeacherProfileEditPage";
const TeacherProfileEdit = () => {
  return (
    <div>
      <LoginHeader />
      <TeacherProfileEditPage />
      <MainFooter />
    </div>
  );
};

export default TeacherProfileEdit;
