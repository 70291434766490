import { call, put, takeEvery } from "redux-saga/effects";
import {
	GET_AUTHORS_BY_ADMIN,
	GET_SINGLE_AUTHOR,
	GET_CATEGORY,
	CREATE_CATEGORY,
	GET_STUDENTS,
	GET_SINGLE_STUDENT,
	GET_ACADEMIC_AREAS,
	UPDATE_AUTHOR,
	GET_MULTISELECTOR_DATA,
	CREATE_AUTHOR,
	COURSE_RELATED_PREVOUS_MULTI_DATA,
	COURSE_CREATE,
	GET_COURSES,
	GET_SINGLE_COURSE,
	CREATE_ACADEMIC_AREA,
	GET_CATEGORY_IDS,
	GET_SINGLE_COURSE_OF_VALID_USER,
	GET_COURSE_DATA_FOR_UPDATE,
	UPLOAD_AUTHOR_PHOTO,
	UPLOAD_COURSE_PHOTO,
	GET_FORMAT,
	GET_SINGLE_ACADEMIC_AREA_ADMIN,
	UPDATE_ACADEMIC_AREA,
	UPDATE_FORMAT,
	UPDATE_COURSE,
	GET_ORDERS,
} from "./actionTypes";

import {
	getAuthorsByAdminSuccess,
	getSingleAuthorSuccess,
	getCategorySuccess,
	createCategoryFail,
	createCateogrySuccess,
	getStudentsSuccess,
	getSingleStudentSuccess,
	getAcademicAreaSuccess,
	updateAuthorSuccess,
	getMultiSelectorDataSuccess,
	createAuthorSuccess,
	createAuthorFail,
	courseRelatedPreviousMultiDataSuccess,
	courseCreateSuccess,
	courseCreateFail,
	getCoursesSuccess,
	getSingleCourseSuccess,
	getSingleCourseFail,
	createAcademicAreaSuccess,
	createAcademicAreaFail,
	getCategoryIdsSuccess,
	getSingleCourseOfValidUserSuccess,
	getSingleCourseOfValidUserFail,
	getCourseDataForUpdateSuccess,
	getCourseDataForUpdaetFail,
	uploadAuthorPhotoSuccess,
	uploadAuthorPhotoFail,
	uploadCoursePhotoSuccess,
	uploadCoursePhotoFail,
	getFormatSuccess,
	getSingleAcademicAreaSuccessAdmin,
	getSingleAcademicAreaFailAdmin,
	updateAcademicAreaSuccess,
	updateAcademicAreaFail,
	updateFormatSuccess,
	updateFormatFail,
	updateCourseSuccess,
	updateCourseFail,
	getOrderRelatedDataSuccess,
} from "./actions";
import { toast } from "react-toastify";

import {
	axiosGetAllAuthor,
	axiosGetSingleAuthor,
	axiosGetCategories,
	axiosCreateCategory,
	axiosGetStudents,
	axiosGetSingleStudent,
	axiosGetAcademicAreas,
	axiosUpdateAuthor,
	axiosGetMultiSelectorData,
	axiosCreateAuthor,
	axiosCourseRelatedMultiData,
	axiosCreateCourse,
	axiosGetCourses,
	axiosGetSingleCourse,
	axiosCreateAcademicArea,
	axiosGetCategoryIds,
	axiosGetSingleCourseOfValidUser,
	axiosGetCourseDataForUpdate,
	axiosUploadAuthorPhoto,
	axiosUploadCoursePhoto,
	axiosGetFormat,
	axiosAcademicArea,
	axiosUpdateAcademicArea,
	axiosUpdateFormat,
	axiosUpdateCourse,
	axiosGetOrdersRelatedData,
} from "../../../helpers/api_helper";
import { loginSuccess } from "../../login/actions";
toast.configure();

//get authors
const asyncGetAuthors = async (token) => {
	try {
		const response = await axiosGetAllAuthor(token);
		return response.data;
	} catch (error) {}
};
function* workerGetAuthors({ payload: { token } }) {
	try {
		// console.log("hello jp data")
		const response = yield call(asyncGetAuthors, token);
		// console.log("hello response", response)

		yield put(getAuthorsByAdminSuccess(response.getData));
	} catch (error) {}
}

//Get single author
const asyncGetSingleAuthor = async (token, authorId) => {
	try {
		const response = await axiosGetSingleAuthor(authorId, token);
		return response.data;
	} catch (error) {}
};
function* workerGetSinlgeAuthor({ payload: { token, authorId } }) {
	try {
		const response = yield call(asyncGetSingleAuthor, token, authorId);
		// console.log("hello res data", response)
		yield put(getSingleAuthorSuccess(response.getDatas));
	} catch (error) {}
}

//category
const asyncGetCategory = async (token) => {
	try {
		let response = await axiosGetCategories();
		// console.log("hello response", response.data)
		return response.data;
	} catch (error) {
		// console.log("error data", error)
		return error.response.data;
	}
};
function* workerGetCategory({ payload: { token } }) {
	try {
		let response = yield call(asyncGetCategory, token);
		// console.log("hello response", response)
		yield put(getCategorySuccess(response.getData));
	} catch (error) {
		console.log("error", error);
	}
}

//create cateogry
const asyncCreateCategory = async (token, formData) => {
	try {
		const response = await axiosCreateCategory(token, formData);
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};
function* workerCreateCategory({ payload: { token, formData, history } }) {
	try {
		const response = yield call(asyncCreateCategory, token, formData);
		// console.log("hello response", response)
		if (response.status == "success") {
			toast.success("Format has been created");
			yield put(createCateogrySuccess(response.message));
			history.push("/format-list");
			yield put(createCateogrySuccess());
		} else {
			toast.error(response.message);
			yield put(createCategoryFail(response.message));
			yield put(createCategoryFail());
		}
	} catch (error) {}
}

//get students
const getStudents = async (token) => {
	try {
		const response = await axiosGetStudents(token);
		return response.data.data;
	} catch (error) {
		return error.response.data;
	}
};
function* workerGetStudents({ payload: { token } }) {
	try {
		const response = yield call(getStudents, token);
		// console.log("hello response", response)
		yield put(getStudentsSuccess(response.getUserList));
	} catch (error) {}
}

//get singile student
const getSingleStudent = async (token, id) => {
	try {
		const response = await axiosGetSingleStudent(token, id);
		return response.data;
	} catch (error) {}
};
function* workerGetSingleStudent({ payload: { token, id } }) {
	try {
		const response = yield call(getSingleStudent, token, id);

		// console.log("hello data", response.getDatas)
		yield put(getSingleStudentSuccess(response.getDatas));
	} catch (error) {}
}

//get academic areas
const asyncGetAcademicAreas = async () => {
	try {
		const response = await axiosGetAcademicAreas();
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};
function* workerGetAcademicAreas() {
	try {
		const response = yield call(asyncGetAcademicAreas);
		// console.log("response academic area", response.getData)
		yield put(getAcademicAreaSuccess(response.getData));
	} catch (error) {}
}

//update author
const asyncUpdateAuthor = async (
	token,
	authorName,
	phoneNumber,
	email,
	academicIds,
	description,
	photo,
	formatIds,
	authorId,
) => {
	try {
		const response = await axiosUpdateAuthor(
			token,
			authorName,
			phoneNumber,
			email,
			academicIds,
			description,
			photo,
			formatIds,
			authorId,
		);
		return response.data;
	} catch (error) {}
};
function* workerUpdateAuthor({
	payload: {
		token,
		authorName,
		phoneNumber,
		email,
		academicIds,
		description,
		photo,
		formatIds,
		authorId,
		history,
	},
}) {
	try {
		const response = yield call(
			asyncUpdateAuthor,
			token,
			authorName,
			phoneNumber,
			email,
			academicIds,
			description,
			photo,
			formatIds,
			authorId,
		);

		if (response.status == "success") {
			//  toast.success("Author Updated")
			history.push("/dashboard-author-area");
		} else {
			//  toast.error("Author not updated")
		}
	} catch (error) {}
}

//get multiselector data
const asyncGetMultiselectorData = async (token) => {
	try {
		const response = await axiosGetMultiSelectorData(token);
		return response.data;
	} catch (error) {}
};
function* workerGetMultiSelectorData({ payload: { token } }) {
	try {
		const response = yield call(asyncGetMultiselectorData, token);
		// console.log("resposne data here", response)
		yield put(getMultiSelectorDataSuccess(response.data));
	} catch (error) {}
}

const asyncCreateAuthor = async (
	authtoken,
	formatId,
	academicId,
	phoneNumber,
	authorName,
	email,
	photo,
	description,
) => {
	try {
		const response = await axiosCreateAuthor(
			authtoken,
			authorName,
			email,
			phoneNumber,
			academicId,
			formatId,
			photo,
			description,
		);
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};
function* workerCreateAuthor({
	payload: {
		token,
		formatId,
		academicId,
		phoneNumber,
		authorName,
		email,
		photo,
		description,
		history,
	},
}) {
	try {
		const response = yield call(
			asyncCreateAuthor,
			token,
			formatId,
			academicId,
			phoneNumber,
			authorName,
			email,
			photo,
			description,
		);
		if (response.status == "success") {
			toast.success(response.message);

			yield put(createAuthorSuccess(response.message));
			history.push("/dashboard-author-area");
			yield put(createAuthorSuccess());
		} else {
			toast.error(response.message);
			yield put(createAuthorSuccess(response.message));
			yield put(createAuthorSuccess());
		}
	} catch (error) {}
}

//course related multi data
const asyncCourseRelatedPreviousMultiData = async (token) => {
	try {
		const response = await axiosCourseRelatedMultiData(token);
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};
function* workerCourseRelatedPreveousMultiData({ payload: { token } }) {
	try {
		const response = yield call(asyncCourseRelatedPreviousMultiData, token);
		// console.log("hello data res", response)
		yield put(courseRelatedPreviousMultiDataSuccess(response.data));
	} catch (error) {}
}
//create course
const asyncCreateCourse = async (
	token,
	courseName,
	code,
	noOfPages,
	category,
	price,
	estimatedTime,
	description,
	subjectCoverd,
	language,
	academicArea,
	author,
	typeOfPublication,
	duration,
	coursePhoto,
) => {
	try {
		const response = await axiosCreateCourse(
			token,
			courseName,
			code,
			noOfPages,
			category,
			price,
			estimatedTime,
			description,
			subjectCoverd,
			language,
			academicArea,
			author,
			typeOfPublication,
			duration,
			coursePhoto,
		);
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};
function* workerCourseCreate({
	payload: {
		token,
		courseName,
		code,
		noOfPages,
		category,
		price,
		estimatedTime,
		description,
		subjectCoverd,
		language,
		academicArea,
		author,
		typeOfPublication,
		duration,
		coursePhoto,
		history,
	},
}) {
	try {
		console.log("token data here", token);
		const response = yield call(
			asyncCreateCourse,
			token,
			courseName,
			code,
			noOfPages,
			category,
			price,
			estimatedTime,
			description,
			subjectCoverd,
			language,
			academicArea,
			author,
			typeOfPublication,
			duration,
			coursePhoto,
		);
		// console.log("hello res data", response)
		if (response.status == "success") {
			toast.success(response.message);
			yield put(courseCreateSuccess(response.message));
			yield put(courseCreateSuccess());
			history.push("/course");
		} else {
			toast.error(response.message);
			yield put(courseCreateFail(response.message));
			yield put(courseCreateFail());
		}
	} catch (error) {}
}

//get courses
const asyncGetCourses = async () => {
	try {
		const response = await axiosGetCourses();
		return response.data;
	} catch (error) {
		return error.respone.data;
	}
};
function* workerGetCourses() {
	const response = yield call(asyncGetCourses);
	yield put(getCoursesSuccess(response.getData));
}

//get single course
const asyncGetSingleCourse = async (courseId) => {
	try {
		const response = await axiosGetSingleCourse(courseId);
		return response.data;
	} catch (error) {
		return error.respone.data;
	}
};
function* workerGetSingleCourse({ payload: { courseId } }) {
	try {
		const response = yield call(asyncGetSingleCourse, courseId);
		// console.log("hello response data single course", response)
		yield put(getSingleCourseSuccess(response.getSingleData));
	} catch (error) {
		// console.log("hello error course")
	}
}

//academic area
const asyncCreateAcademicArea = async (token, academicName, category) => {
	try {
		const response = await axiosCreateAcademicArea(
			token,
			academicName,
			category,
		);
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};
function* workerCreateAcademicArea({
	payload: { token, academicName, category, history },
}) {
	try {
		const response = yield call(
			asyncCreateAcademicArea,
			token,
			academicName,
			category,
		);
		// console.log("hello resposne data", response.message)
		if (response.status == "success") {
			toast.success(response.message);
			yield put(createAcademicAreaSuccess(response.message));
			yield put(createAcademicAreaSuccess());
			history.push("/academic-area");
		} else {
			toast.error(response.message);
			yield put(createAcademicAreaFail(response.message));
			yield put(createAcademicAreaFail());
		}
	} catch (error) {}
}
//get category ids
const asyncGetCategoryIds = async (token) => {
	try {
		const response = await axiosGetCategoryIds(token);
		return response.data;
	} catch (error) {}
};
function* workerGetCategoryIds({ payload: { token } }) {
	try {
		const response = yield call(asyncGetCategoryIds, token);
		// console.log("hello resposne", response)

		yield put(getCategoryIdsSuccess(response.categoryids));
	} catch (error) {}
}
//get single course of valid user
const asyncGetSingleCourseOfValidUser = async (token, courseId) => {
	try {
		const response = await axiosGetSingleCourseOfValidUser(token, courseId);
		return response.data;
	} catch (error) {
		console.log("error here 575", error.response);
		return error.response.data;
	}
};

function* workerGetSingleCourseOfValidUser({ payload: { token, courseId } }) {
	try {
		const response = yield call(
			asyncGetSingleCourseOfValidUser,
			token,
			courseId,
		);
		// console.log("course data", response.getData)
		if (response.status == "success") {
			yield put(getSingleCourseOfValidUserSuccess(response.getData));
		} else {
			if (response.statusCode == 405) {
				yield put(loginSuccess());
				localStorage.removeItem("persist:primary");
			}
			yield put(getSingleCourseOfValidUserFail(response.message));
			toast.error(response.message);
			yield put(getSingleCourseOfValidUserFail());
		}
	} catch (error) {
		console.log("error 597");
	}
}

//async Get CourseDataFor UPdate
const asyncGetCourseDataForUpdate = async (token, courseId) => {
	try {
		const response = await axiosGetCourseDataForUpdate(token, courseId);
		return response.data;
	} catch (error) {
		return error.respone.data;
	}
};

function* workerGetCourseDataForUpdate({ payload: { token, courseId } }) {
	try {
		const response = yield call(
			asyncGetCourseDataForUpdate,
			token,
			courseId,
		);
		console.log("call course data form update");
		yield put(getCourseDataForUpdateSuccess(response.getData));
	} catch (error) {
		console.log("error data here", error);
	}
}

//upload author photo
const asyncUploadAuthorPhoto = async (token, formData) => {
	try {
		const response = await axiosUploadAuthorPhoto(token, formData);
		return response.data;
	} catch (error) {}
};
function* workerUploadAuthorPhoto({ payload: { token, formData } }) {
	try {
		const response = yield call(asyncUploadAuthorPhoto, token, formData);
		if (response.status == "success") {
			console.log("respose photo here", response);
			yield put(uploadAuthorPhotoSuccess(response.photo));
		} else {
			yield put(uploadAuthorPhotoFail(response.message));
			toast.error(response.message);
			yield put(uploadAuthorPhotoFail());
		}
	} catch (error) {}
}

const asyncUploadCoursePhoto = async (token, formData) => {
	try {
		const response = await axiosUploadCoursePhoto(token, formData);
		return response.data;
	} catch (error) {
		return error.respone.data;
	}
};
function* workerUploadCoursePhoto({ payload: { token, formData } }) {
	try {
		console.log("hello form data", formData);

		const response = yield call(asyncUploadCoursePhoto, token, formData);
		if (response.status == "success") {
			yield put(uploadCoursePhotoSuccess(response.photo));
		} else {
			yield put(uploadCoursePhotoFail(response.message));
			toast.error(response.message);
			yield put(uploadCoursePhotoFail());
		}
	} catch (error) {
		console.log("error data ", error);
	}
}

// Get Format
const asyncGetFormat = async (token, formatId) => {
	try {
		const response = await axiosGetFormat(token, formatId);
		return response.data;
	} catch (error) {
		return error.respone.data;
	}
};
function* workerGetFormat({ payload: { token, formatId } }) {
	try {
		const response = yield call(asyncGetFormat, token, formatId);
		console.log("format data here 651", response.getSingleData);

		yield put(getFormatSuccess(response.getSingleData));
	} catch (error) {
		console.log("error data", error);
	}
}

//get single academic area
const asyncGetSingleAcademicArea = async (token, academicId) => {
	try {
		const response = await axiosAcademicArea(token, academicId);
		return response.data;
	} catch (error) {
		return error.response.data;
	}
};
function* workerGetSingleAcademicArea({ payload: { token, academicId } }) {
	try {
		const response = yield call(
			asyncGetSingleAcademicArea,
			token,
			academicId,
		);
		if (response.status == "success") {
			yield put(getSingleAcademicAreaSuccessAdmin(response.getData));
		} else {
			yield put(getSingleAcademicAreaFailAdmin(response.message));
		}
	} catch (error) {}
}

//update academci Area
const asyncUpdateAcademicArea = async (token, academicId, academicName) => {
	try {
		const response = await axiosUpdateAcademicArea(
			token,
			academicId,
			academicName,
		);
		return response.data;
	} catch (error) {
		return error.respone.data;
	}
};
function* workerUpdateAcadmeicArea({
	payload: { token, academicId, academicName, history },
}) {
	try {
		const response = yield call(
			asyncUpdateAcademicArea,
			token,
			academicId,
			academicName,
			history,
		);
		if (response.status == "success") {
			toast.success(response.message);
			yield put(updateAcademicAreaSuccess(response.message));
			history.push("/academic-area");
			yield put(updateAcademicAreaSuccess());
		} else {
			yield put(updateAcademicAreaFail(response.message));
			toast.error(response.message);
			yield put(updateAcademicAreaFail());
		}
	} catch (error) {}
}

const asyncUpdateFormat = async (token, formatId, formatName, formatPhoto) => {
	try {
		const response = await axiosUpdateFormat(
			token,
			formatId,
			formatName,
			formatPhoto,
		);
		return response.data;
	} catch (error) {
		return error.respone.data;
	}
};
function* workerUpdateFormat({
	payload: { token, formatId, formatName, formatPhoto, history },
}) {
	try {
		const response = yield call(
			asyncUpdateFormat,
			token,
			formatId,
			formatName,
			formatPhoto,
		);
		if (response.status == "success") {
			yield put(updateFormatSuccess(response.message));
			history.push("/format-list");
			toast.success(response.message);
			yield put(updateFormatSuccess());
		} else {
			yield put(updateFormatFail(response.message));
			toast.success(response.message);
			yield put(updateFormatFail());
		}
	} catch (error) {}
}

//update course
const asyncUpdateCourse = async (
	token,
	courseId,
	courseName,
	courseCode,
	noOfPages,
	estimatedTime,
	coursePrice,
	description,
	coursePhoto,
	language,
	subjectCoverd,
	validation,
	typeOfPublication,
) => {
	try {
		console.log("call from here 765 line", courseId);
		const response = await axiosUpdateCourse(
			token,
			courseId,
			courseName,
			courseCode,
			noOfPages,
			estimatedTime,
			coursePrice,
			description,
			coursePhoto,
			language,
			subjectCoverd,
			validation,
			typeOfPublication,
		);
		return response.data;
	} catch (error) {
		return error.respone.data;
	}
};

function* workerUpdateCourse({
	payload: {
		token,
		courseId,
		courseName,
		courseCode,
		noOfPages,
		estimatedTime,
		coursePrice,
		description,
		coursePhoto,
		language,
		subjectCoverd,
		validation,
		typeOfPublication,
		history,
	},
}) {
	try {
		const response = yield call(
			asyncUpdateCourse,
			token,
			courseId,
			courseName,
			courseCode,
			noOfPages,
			estimatedTime,
			coursePrice,
			description,
			coursePhoto,
			language,
			subjectCoverd,
			validation,
			typeOfPublication,
		);
		if (response.status == "success") {
			yield put(updateCourseSuccess(response.message));
			toast.success(response.message);
			history.push("/course");
			yield put(updateCourseSuccess());
		} else {
			yield put(updateCourseFail(response.message));
			toast.error(response.message);
			yield put(updateCourseFail());
		}
	} catch (error) {}
}

//get orders
const asyncGetOrders = async () => await axiosGetOrdersRelatedData();
function* workerGetOrders() {
	try {
		const response = yield call(asyncGetOrders);
		console.log("response get Data", response.data.getData);
		yield put(getOrderRelatedDataSuccess(response.data.getData));
	} catch (error) {
		console.log("error here", error);
	}
}

//get orders related
function* adminAuthorPage() {
	yield takeEvery(GET_AUTHORS_BY_ADMIN, workerGetAuthors);
	yield takeEvery(GET_SINGLE_AUTHOR, workerGetSinlgeAuthor);
	yield takeEvery(GET_CATEGORY, workerGetCategory);
	yield takeEvery(CREATE_CATEGORY, workerCreateCategory);
	yield takeEvery(GET_STUDENTS, workerGetStudents);
	yield takeEvery(GET_SINGLE_STUDENT, workerGetSingleStudent);
	yield takeEvery(GET_ACADEMIC_AREAS, workerGetAcademicAreas);
	yield takeEvery(UPDATE_AUTHOR, workerUpdateAuthor);
	yield takeEvery(GET_MULTISELECTOR_DATA, workerGetMultiSelectorData);
	yield takeEvery(CREATE_AUTHOR, workerCreateAuthor);
	yield takeEvery(
		COURSE_RELATED_PREVOUS_MULTI_DATA,
		workerCourseRelatedPreveousMultiData,
	);
	yield takeEvery(COURSE_CREATE, workerCourseCreate);
	yield takeEvery(GET_COURSES, workerGetCourses);
	yield takeEvery(GET_SINGLE_COURSE, workerGetSingleCourse);
	yield takeEvery(CREATE_ACADEMIC_AREA, workerCreateAcademicArea);

	yield takeEvery(GET_CATEGORY_IDS, workerGetCategoryIds);
	yield takeEvery(
		GET_SINGLE_COURSE_OF_VALID_USER,
		workerGetSingleCourseOfValidUser,
	);
	yield takeEvery(GET_COURSE_DATA_FOR_UPDATE, workerGetCourseDataForUpdate);
	yield takeEvery(UPLOAD_AUTHOR_PHOTO, workerUploadAuthorPhoto);
	yield takeEvery(UPLOAD_COURSE_PHOTO, workerUploadCoursePhoto);
	yield takeEvery(GET_FORMAT, workerGetFormat);
	yield takeEvery(
		GET_SINGLE_ACADEMIC_AREA_ADMIN,
		workerGetSingleAcademicArea,
	);
	yield takeEvery(UPDATE_ACADEMIC_AREA, workerUpdateAcadmeicArea);
	yield takeEvery(UPDATE_FORMAT, workerUpdateFormat);

	yield takeEvery(UPDATE_COURSE, workerUpdateCourse);
	yield takeEvery(GET_ORDERS, workerGetOrders);
}

export default adminAuthorPage;
