import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CategoryData } from "./CategoryData";
import laptopIcon2 from "./img/diagram.svg";
import fIcon1 from "./img/filter-btn.svg";
import fIcon2 from "./img/menu.svg";
const CategoryAllDataList = ({ courses }) => {
  return (
    <div>
      <Container>
        <div className="category-inner-area-main-wrap">
          <Row>
            <Col lg={3}>
              <div className="hero-slider-content-right category-page">
                <h6>REFINE YOUR SEARCH</h6>
                <div className="academic-right-area-wrap category-page">
                  {CategoryData.map((item, i) => {
                    return (
                      <div className="academic-right-single-item category-page">
                        <input
                          type="checkbox"
                          id="ECONOMIC"
                          name="ECONOMIC"
                          value="ECONOMIC"
                        />
                        <label for="ECONOMIC"> {item.name}</label>
                        {/* <a href="/student-category"> </a>
                    <a href="/">STRATEGY</a> */}
                      </div>
                    );
                  })}
                </div>
              </div>
            </Col>
            <Col lg={9}>
              <div className="search-course-main-area-wrap">
                <div className="search-top-area-">
                  <div className="search-area-input-field-and-button">
                    <div className="search-area-input-field-box-wrap">
                      <input type="search" placeholder="Search course" />
                      <i className="bx bx-search"></i>
                    </div>
                    <button>Search</button>
                  </div>
                </div>
                <div className="course-filtering-area-wrap">
                  <div className="course-filtering-area-wrap-inner-wrap">
                    {/* <span>Showing 1 - 9 of 108 result(s)</span> */}
                    <span>
                      <img src={fIcon2} alt="" />
                      <img src={fIcon1} alt="" />
                    </span>
                  </div>
                </div>
              </div>
              <Row>
                {courses?.map((v, i) => (
                  <Col lg={4}>
                    <div
                      className="business-clases-single-item-wrap"
                      style={{
                        backgroundImage: `url(https://staging-courseware-backend.sgp1.digitaloceanspaces.com/${v.image})`,
                      }}
                    >
                      <div className="business-clases-main-part">
                        <div className="business-clases-main-part-top">
                          <h3>{v.courseName}</h3>
                          <i>{v.courseCode}</i>
                        </div>
                        <div className="business-clases-main-part-bottom">
                          <i>Academic Area</i>
                          <h4>{v.courseName}</h4>
                          <div className="business-clases-main-part-bottom-inner">
                            <div className="business-clases-main-part-bottom-inner-left">
                              <img src={laptopIcon2} alt="" />
                            </div>{" "}
                            <div className="business-clases-main-part-bottom-inner-left">
                              <h6>
                                <span>Format: </span> Multimedia & Interactive
                              </h6>{" "}
                              <h6>
                                <span>Language: </span> English
                              </h6>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="business-clases-hover-part">
                        <div className="">
                          <p>
                            {v.description && v.description.substring(0, 100)}
                            ...
                          </p>
                          <div className="business-clases-hover-part-bottom-link">
                            <Link to={`/course-details-page/${v._id}`}>
                              <span>+</span>
                              <span>Read More</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}

                {/* <Col lg={4}>
                  <div
                    className="business-clases-single-item-wrap"
                    style={{ backgroundImage: `url(${service2})` }}
                  >
                    <div className="business-clases-main-part">
                      <div className="business-clases-main-part-top">
                        <h3>People Management</h3>
                        <i>MKS020188-U-ENG-HTM</i>
                      </div>
                      <div className="business-clases-main-part-bottom">
                        <i>Academic Area</i>
                        <h4>COST ACCOUNTING & MANAGEMENT CONTROL</h4>
                        <div className="business-clases-main-part-bottom-inner">
                          <div className="business-clases-main-part-bottom-inner-left">
                            <img src={laptopIcon3} alt="" />
                          </div>{" "}
                          <div className="business-clases-main-part-bottom-inner-left">
                            <h6>
                              <span>Format: </span> Case Study
                            </h6>{" "}
                            <h6>
                              <span>Language: </span> English
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-clases-hover-part">
                      <div className="">
                        <p>
                          This interactive tutorial introduces the student to
                          the world of business management. The tutorial is
                          divided into 4 sections: trade, trading activity, the
                          complex sale and the personal sale process, where the
                          student is introduced to the basic concepts of the
                          commercial world up to how to put the seller´s
                          activity into
                        </p>
                        <div className="business-clases-hover-part-bottom-link">
                          <a href="/">
                            <span>+</span>
                            <span>Read More</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>{" "}
                <Col lg={4}>
                  <div
                    className="business-clases-single-item-wrap"
                    style={{ backgroundImage: `url(${service3})` }}
                  >
                    <div className="business-clases-main-part">
                      <div className="business-clases-main-part-top">
                        <h3>Key Concepts in Economics</h3>
                        <i>MKS020188-U-ENG-HTM</i>
                      </div>
                      <div className="business-clases-main-part-bottom">
                        <i>Academic Area</i>
                        <h4>ECONOMIC ENVIRONMENT & PUBLIC AFFAIRS</h4>
                        <div className="business-clases-main-part-bottom-inner">
                          <div className="business-clases-main-part-bottom-inner-left">
                            <img src={laptopIcon4} alt="" />
                          </div>{" "}
                          <div className="business-clases-main-part-bottom-inner-left">
                            <h6>
                              <span>Format: </span> Grains & Simulation
                            </h6>{" "}
                            <h6>
                              <span>Language: </span> English
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-clases-hover-part">
                      <div className="">
                        <p>
                          This interactive tutorial introduces the student to
                          the world of business management. The tutorial is
                          divided into 4 sections: trade, trading activity, the
                          complex sale and the personal sale process, where the
                          student is introduced to the basic concepts of the
                          commercial world up to how to put the seller´s
                          activity into
                        </p>
                        <div className="business-clases-hover-part-bottom-link">
                          <a href="/">
                            <span>+</span>
                            <span>Read More</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>{" "}
                <Col lg={4}>
                  <div
                    className="business-clases-single-item-wrap"
                    style={{ backgroundImage: `url(${service4})` }}
                  >
                    <div className="business-clases-main-part">
                      <div className="business-clases-main-part-top">
                        <h3>Metaverse reality</h3>
                        <i>MKS020188-U-ENG-HTM</i>
                      </div>
                      <div className="business-clases-main-part-bottom">
                        <i>Academic Area</i>
                        <h4>
                          DIGITAL TECHNOLOGIES & DATA <br /> SCIENCE
                        </h4>
                        <div className="business-clases-main-part-bottom-inner">
                          <div className="business-clases-main-part-bottom-inner-left">
                            <img src={laptopIcon2} alt="" />
                          </div>{" "}
                          <div className="business-clases-main-part-bottom-inner-left">
                            <h6>
                              <span>Format: </span> Coursware
                            </h6>{" "}
                            <h6>
                              <span>Language: </span> English
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-clases-hover-part">
                      <div className="">
                        <p>
                          This interactive tutorial introduces the student to
                          the world of business management. The tutorial is
                          divided into 4 sections: trade, trading activity, the
                          complex sale and the personal sale process, where the
                          student is introduced to the basic concepts of the
                          commercial world up to how to put the seller´s
                          activity into
                        </p>
                        <div className="business-clases-hover-part-bottom-link">
                          <a href="/">
                            <span>+</span>
                            <span>Read More</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>{" "}
                <Col lg={4}>
                  <div
                    className="business-clases-single-item-wrap"
                    style={{ backgroundImage: `url(${service5})` }}
                  >
                    <div className="business-clases-main-part">
                      <div className="business-clases-main-part-top">
                        <h3>Accounting Principles</h3>
                        <i>MKS020188-U-ENG-HTM</i>
                      </div>
                      <div className="business-clases-main-part-bottom">
                        <i>Academic Area</i>
                        <h4>COST ACCOUNTING & MANAGEMENT CONTROL</h4>
                        <div className="business-clases-main-part-bottom-inner">
                          <div className="business-clases-main-part-bottom-inner-left">
                            <img src={laptopIcon3} alt="" />
                          </div>{" "}
                          <div className="business-clases-main-part-bottom-inner-left">
                            <h6>
                              <span>Format: </span> Case Study
                            </h6>{" "}
                            <h6>
                              <span>Language: </span> English
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-clases-hover-part">
                      <div className="">
                        <p>
                          This interactive tutorial introduces the student to
                          the world of business management. The tutorial is
                          divided into 4 sections: trade, trading activity, the
                          complex sale and the personal sale process, where the
                          student is introduced to the basic concepts of the
                          commercial world up to how to put the seller´s
                          activity into
                        </p>
                        <div className="business-clases-hover-part-bottom-link">
                          <a href="/">
                            <span>+</span>
                            <span>Read More</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>{" "}
                <Col lg={4}>
                  <div
                    className="business-clases-single-item-wrap"
                    style={{ backgroundImage: `url(${service6})` }}
                  >
                    <div className="business-clases-main-part">
                      <div className="business-clases-main-part-top">
                        <h3>Econometrics</h3>
                        <i>MKS020188-U-ENG-HTM</i>
                      </div>
                      <div className="business-clases-main-part-bottom">
                        <i>Academic Area</i>
                        <h4>ECONOMIC ENVIRONMENT & PUBLIC AFFAIRS</h4>
                        <div className="business-clases-main-part-bottom-inner">
                          <div className="business-clases-main-part-bottom-inner-left">
                            <img src={laptopIcon4} alt="" />
                          </div>{" "}
                          <div className="business-clases-main-part-bottom-inner-left">
                            <h6>
                              <span>Format: </span> Grains & Simulation
                            </h6>{" "}
                            <h6>
                              <span>Language: </span> English
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="business-clases-hover-part">
                      <div className="">
                        <p>
                          This interactive tutorial introduces the student to
                          the world of business management. The tutorial is
                          divided into 4 sections: trade, trading activity, the
                          complex sale and the personal sale process, where the
                          student is introduced to the basic concepts of the
                          commercial world up to how to put the seller´s
                          activity into
                        </p>
                        <div className="business-clases-hover-part-bottom-link">
                          <a href="/">
                            <span>+</span>
                            <span>Read More</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col> */}
              </Row>
              <div className="business-cases-bottom-button-area">
                <a href="/">See More</a>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default CategoryAllDataList;
