import {
  ADD_COURSE_USER,
  ADD_COURSE_USER_FAIL,
  ADD_COURSE_USER_SUCCESS,
  CLEAR_SECOND_GAME_DATA,
  GET_COURSE_ALL_STUDENT,
  GET_COURSE_ALL_STUDENT_FAIL,
  GET_COURSE_ALL_STUDENT_SUCCESS,
  GET_COURSE_SINGLE_STUDENT,
  GET_COURSE_SINGLE_STUDENT_FAIL,
  GET_COURSE_SINGLE_STUDENT_SUCCESS,
  GET_RETAIL_COURSE,
  GET_RETAIL_COURSE_FAIL,
  GET_RETAIL_COURSE_SUCCESS,
  GET_SECOND_GAME,
  GET_SECOND_GAME_FAIL,
  GET_SECOND_GAME_SUCCESS,
  POST_INTRO_DATA,
  POST_INTRO_DATA_FAIL,
  POST_INTRO_DATA_SUCCESS,
  REMOVE_COURSE_STUDENT,
  REMOVE_COURSE_STUDENT_FAIL,
  REMOVE_COURSE_STUDENT_SUCCESS,
  STORE_GENERAL_DATA,
  STORE_SECOND_GAME_DATA,
} from "./actionTypes";

const INIT_STATE = {
  loading: true,
  error: "",
  data: [],
  adding: false,
  removeCourseStudent: [],
  allStudent: [],
  singleStudent: [],
  removeCourseStudentLoading: true,
  allStudentLoading: true,
  singleStudentLoading: true,
  retailGameData: {},
  retailGameLoading: true,
  options: [],
  haveToDone: [],
  notToDone: [],
  secondGameLoading: true,
  secondGameData:[],
  startStep: 0,
  currentMark: 0,
  totalMark: 0,
  userAns:"",
  growth:0,
  fixed:0,
  totalQues:0,
  totalNumber:0,
  gameType:""

};

const RetailGeneralReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_INTRO_DATA:
      return {
        ...state,
        adding: true,
      };

    case POST_INTRO_DATA_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        adding: false,
      };

    case POST_INTRO_DATA_FAIL:
      return {
        ...state,
        error: action.payload.message,
        adding: false,
      };
    case ADD_COURSE_USER:
      return {
        ...state,
        adding: true,
      };

    case ADD_COURSE_USER_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        adding: false,
      };

    case ADD_COURSE_USER_FAIL:
      return {
        ...state,
        error: action.payload.message,
        adding: false,
      };

    case REMOVE_COURSE_STUDENT:
      return {
        ...state,
        removeCourseStudentLoading: true,
      };
    case REMOVE_COURSE_STUDENT_SUCCESS:
      return {
        ...state,
        removeCourseStudentLoading: false,
        removeCourseStudent: action.payload,
      };
    case REMOVE_COURSE_STUDENT_FAIL:
      return {
        ...state,
        error: action.payload,
        removeCourseStudentLoading: false,
      };

    case GET_COURSE_ALL_STUDENT:
      return {
        ...state,
        allStudentLoading: true,
      };
    case GET_COURSE_ALL_STUDENT_SUCCESS:
      return {
        ...state,
        allStudentLoading: false,
        allStudent: action.payload,
      };
    case GET_COURSE_ALL_STUDENT_FAIL:
      return {
        ...state,
        error: action.payload,
        allStudentLoading: false,
      };
    case GET_COURSE_SINGLE_STUDENT:
      return {
        ...state,
        singleStudentLoading: true,
      };
    case GET_COURSE_SINGLE_STUDENT_SUCCESS:
      return {
        ...state,
        singleStudentLoading: false,
        singleStudent: action.payload,
      };
    case GET_COURSE_SINGLE_STUDENT_FAIL:
      return {
        ...state,
        error: action.payload,
        singleStudentLoading: false,
      };

    case GET_RETAIL_COURSE:
      return {
        ...state,
        retailGameLoading: true,
      };
    case GET_RETAIL_COURSE_SUCCESS:
      return {
        ...state,
        retailGameLoading: false,
        retailGameData: action.payload,
        options: action.payload?.options,
      };
    case GET_RETAIL_COURSE_FAIL:
      return {
        ...state,
        error: action.payload,
        secondGameLoading: false,
      };
      case GET_SECOND_GAME:
        return {
          ...state,
          secondGameLoading: true,
        };
      case GET_SECOND_GAME_SUCCESS:
        return {
          ...state,
          secondGameLoading: false,
          secondGameData: action.payload,
         
        };
      case GET_SECOND_GAME_FAIL:
        return {
          ...state,
          error: action.payload,
          retailGameLoading: false,
        };
  
    case STORE_GENERAL_DATA:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };
      case STORE_SECOND_GAME_DATA:
        return {
          ...state,
          [action.payload.name]: action.payload.data,
        };
  
        case CLEAR_SECOND_GAME_DATA:
          return {
            ...state,
        
            currentMark: 0,
            totalMark: 0,
            startStep: 0,
            totalNumber: 0,
            totalQues: 0,
            dragDropStartStep:0,
            userAns:"",
          
    
          };
    default:
      return state;
  }
};

export default RetailGeneralReducer;
