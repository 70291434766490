import React from "react";
import LafargeTraining from "../LafargeCommon/LafargeComponents/LafargeTraining";
import LafargeCourseWareHeader from "../LafargeCommon/LafargeHeader/LafargeCourseWareHeader";

const LafargeCoursewareHome = () => {
  return (
    <div>
      <LafargeCourseWareHeader />
      <LafargeTraining />
    </div>
  );
};

export default LafargeCoursewareHome;
