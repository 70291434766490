import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import React, { useEffect, useRef, useState } from "react";
 import "../../assests/styles.css";
import { comparativeAnalysis } from "../../store/actions";
import ListHeader from "./ListHeader";
// import RatioGraph
import RatioGraph from "./ratioGraph";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation , useParams} from "react-router-dom";
import { courseOwnerValid } from "../../store/actions";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

let obj = {
  gross: {
    0: 91.11,
    1: 91.2,
    2: 92.48,
    4: 87.55,
    5: 87.82,
    6: 87.99,
  },
  roe: {
    0: 7.43,
    1: 0.39,
    2: -8.28,
    4: 16.03,
    5: 5.87,
    6: 4.12,
  },
  roa: {
    0: 5.14,
    1: 3.78,
    2: -2.3,
    4: 8.38,
    5: 5.42,
    6: 3.4,
  },
  storage: {
    0: 326,
    1: 348,
    2: 509,
    4: 62,
    5: 76,
    6: 152,
  },
  receivable: {
    0: 30,
    1: 36,
    2: 37,
    4: 23,
    5: 26,
    6: 35,
  },
  payable: {
    0: 649,
    1: 626,
    2: 885,
    4: 426,
    5: 417,
    6: 419,
  },
  fixed: {
    0: 0.62,
    1: 0.61,
    2: 0.51,
    4: 0.68,
    5: 0.64,
    6: 0.58,
  },
  total: {
    0: 0.45,
    1: 0.44,
    2: 0.36,
    4: 0.47,
    5: 0.44,
    6: 0.38,
  },
  expenses: {
    0: 18.57,
    1: 4.89,
    2: 67.14,
    4: 90.93,
    5: 97.12,
    6: 171.18,
  },
  cost: {
    0: 0.03,
    1: 0.04,
    2: 0.03,
    4: 0.04,
    5: 0.04,
    6: 0.03,
  },
  interests: {
    0: 2.61,
    1: 1.6,
    2: -1.41,
    4: 3.55,
    5: 1.9,
    6: 1.96,
  },
  debt: {
    0: 1.48,
    1: 1.55,
    2: 1.54,
    4: 1.79,
    5: 2.15,
    6: 1.97,
  },
  short: {
    0: 0.44,
    1: 0.38,
    2: 0.51,
    4: 0.58,
    5: 0.64,
    6: 0.63,
  },
  current: {
    0: 0.72,
    1: 0.72,
    2: 0.79,
    4: 0.75,
    5: 0.79,
    6: 1.07,
  },
  quick: {
    0: 0.52,
    1: 0.46,
    2: 0.62,
    4: 0.71,
    5: 0.73,
    6: 0.96,
  },
};
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Line Chart",
    },
  },
};

const labels = ["2007", "2008", "2009", "2010"];

export const data = {
  labels,
  datasets: [
    {
      label: "NH Hoteles",
      data: [45, 47, 48],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Sol Melia",
      data: [25, 37, 48],
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

const ComparativeRatio = () => {
  const inputE1 = useRef(null);
  const inputE2 = useRef(null);
  let dispatch = useDispatch();
  let [check, setCheck] = useState("");
  let [num, setNum] = useState();
  let [rData, setRData] = useState(0);
  let { comparativeData, ratioType, loading } = useSelector((state) => ({
    comparativeData: state.comparativeAnalysisReducer.comparativeData,
    ratioType: state.comparativeAnalysisReducer.ratioType,
    loading: state.comparativeAnalysisReducer.loading,
  }));
  const { token,  } = useSelector((state) => ({
    token: state.Login.token,
  
  }));
  const {courseId} = useParams()
   let history = useHistory()
   useEffect(() =>
  {
    dispatch(courseOwnerValid(token,courseId, history ))
  },[])

  useEffect(() => {
    console.log("hello 199 data here");
    setRData(ratioType);
  }, [ratioType]);
  const handleRatioGraph = (e) => {
    e.preventDefault();

 
    console.log("hello data here", e.target.value);
    dispatch(comparativeAnalysis(e.target.value, obj[e.target.value]));
    // console.log("hy run data", inputE1.current.value);
  };
  // console.log("ratioType here", ratioType);
  return (
    <div>
      {/* {JSON.stringify(comparativeData ? comparativeData : [])} */}
      <div className="container-xxl">
        <div>
          <div className="case-method-list">
            <ListHeader />
            <h3 className="my-3">COMPARATIVE OF RATIOS</h3>
            <div className="row">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-7">
                    <div className="table-responsive">
                      {check}
                      <table className="table table-bordered mb-0">
                        <thead className="table-header-main-table-data-equal-dbsdba">
                          <tr className="table-ration-input-header-top-shjbnskbg">
                            <th></th>
                            <th
                              colSpan={3}
                              className="text-center ghbmv-jsvuhjnc-jb"
                            >
                              NH Hoteles
                            </th>
                          </tr>
                          <tr>
                            <th>Ratios</th>
                            <th>2007</th>
                            <th>2008</th>
                            <th>2009</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="ratio-single-info">
                            <td colSpan={4}>PROFITABILITY (%)</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>
                              <div className="comparative-ratio">
                                {!loading && ratioType == "gross" ? (
                                  <input
                                    type="radio"
                                    disabled
                                    id="gross-profit"
                                    name="fav_language"
                                    value="gross"
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="radio"
                                    id="gross-profit"
                                    name="fav_language"
                                    value="gross"
                                    ref={inputE1}
                                    onClick={(e) => handleRatioGraph(e)}
                                  />
                                )}
                                <label for="gross-profit">
                                  Gross profit over sales
                                </label>
                              </div>
                            </td>

                            <td>{obj.gross[0]}</td>
                            <td>{obj.gross[1]}</td>
                            <td>{obj.gross[2]}</td>
                          </tr>

                          <tr className="table-ration-input">
                            <td>
                              <div className="comparative-ratio">
                                {!loading && ratioType == "roe" ? (
                                  <input
                                    type="radio"
                                    id="roe"
                                    name="fav_language"
                                    value="roe"
                                    disabled
                                    ref={inputE2}
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="radio"
                                    id="roe"
                                    name="fav_language"
                                    value="roe"
                                    ref={inputE2}
                                    onClick={(e) => handleRatioGraph(e)}
                                  />
                                )}

                                <label for="roe">
                                  ROE
                                  <sup>(1)</sup>
                                </label>
                              </div>
                            </td>
                            <td>{obj.roe[0]}</td>
                            <td>{obj.roe[1]}</td>
                            <td>{obj.roe[2]}</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>
                              <div className="comparative-ratio">
                                {!loading && ratioType == "roa" ? (
                                  <input
                                    type="radio"
                                    id="roe2"
                                    name="fav_language"
                                    value="roa"
                                    disabled
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="radio"
                                    id="roe2"
                                    name="fav_language"
                                    value="roa"
                                    onClick={(e) => handleRatioGraph(e)}
                                  />
                                )}

                                <label for="roe2">
                                  ROA
                                  <sup>(2)</sup>
                                </label>
                              </div>
                            </td>
                            <td>{obj.roa[0]}</td>
                            <td>{obj.roa[1]}</td>
                            <td>{obj.roa[2]}</td>
                          </tr>
                          <tr className="ratio-single-info">
                            <td colSpan={4}>
                              OPERATING EFFICIENCY (days/times)
                            </td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>
                              <div className="comparative-ratio">
                                {!loading && ratioType == "storage" ? (
                                  <input
                                    type="radio"
                                    id="storage"
                                    name="fav_language"
                                    value="storage"
                                    disabled
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="radio"
                                    id="storage"
                                    name="fav_language"
                                    value="storage"
                                    onClick={(e) => handleRatioGraph(e)}
                                  />
                                )}

                                <label for="storage">
                                  Average storage period
                                </label>
                              </div>
                            </td>
                            <td>{obj.storage[0]}</td>
                            <td>{obj.storage[1]}</td>
                            <td>{obj.storage[2]}</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>
                              <div className="comparative-ratio">
                                {!loading && ratioType == "receivable" ? (
                                  <input
                                    type="radio"
                                    id="accounts"
                                    name="fav_language"
                                    value="receivable"
                                    checked
                                    disabled
                                  />
                                ) : (
                                  <input
                                    type="radio"
                                    id="accounts"
                                    name="fav_language"
                                    value="receivable"
                                    onClick={(e) => handleRatioGraph(e)}
                                  />
                                )}

                                <label for="accounts">
                                  Average accounts receivable period
                                </label>
                              </div>
                            </td>
                            <td>{obj.receivable[0]}</td>
                            <td>{obj.receivable[1]}</td>
                            <td>{obj.receivable[2]}</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>
                              <div className="comparative-ratio">
                                {!loading && ratioType == "payable" ? (
                                  <input
                                    type="radio"
                                    id="payable"
                                    name="fav_language"
                                    value="payable"
                                    checked
                                    disabled
                                  />
                                ) : (
                                  <input
                                    type="radio"
                                    id="payable"
                                    name="fav_language"
                                    value="payable"
                                    onClick={(e) => handleRatioGraph(e)}
                                  />
                                )}

                                <label for="payable">
                                  Average accounts payable period
                                </label>
                              </div>
                            </td>
                            <td>{obj.payable[0]}</td>
                            <td> {obj.payable[1]}</td>
                            <td> {obj.payable[2]}</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>
                              <div className="comparative-ratio">
                                {!loading && ratioType == "fixed" ? (
                                  <input
                                    type="radio"
                                    id="asset"
                                    name="fav_language"
                                    value="fixed"
                                    checked
                                    disabled
                                  />
                                ) : (
                                  <input
                                    type="radio"
                                    id="asset"
                                    name="fav_language"
                                    value="fixed"
                                    onClick={(e) => handleRatioGraph(e)}
                                  />
                                )}

                                <label for="asset">Fixed asset turnover</label>
                              </div>
                            </td>
                            <td> {obj.fixed[0]}</td>
                            <td>{obj.fixed[1]}</td>
                            <td>{obj.fixed[2]}</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>
                              <div className="comparative-ratio">
                                {!loading && ratioType == "total" ? (
                                  <input
                                    type="radio"
                                    id="turnover"
                                    name="fav_language"
                                    value="total"
                                    disabled
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="radio"
                                    id="turnover"
                                    name="fav_language"
                                    value="total"
                                    onClick={(e) => handleRatioGraph(e)}
                                  />
                                )}
                                <label for="turnover">
                                  Total asset turnover
                                </label>
                              </div>
                            </td>
                            <td>{obj.total[0]}</td>
                            <td> {obj.total[1]}</td>
                            <td>{obj.total[2]}</td>
                          </tr>
                          <tr className="ratio-single-info">
                            <td colSpan={4}>FINANCIAL POSITION (days/times)</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>
                              <div className="comparative-ratio">
                                {!loading && ratioType == "expenses" ? (
                                  <input
                                    type="radio"
                                    id="expenses"
                                    name="fav_language"
                                    value="expenses"
                                    disabled
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="radio"
                                    id="expenses"
                                    name="fav_language"
                                    value="expenses"
                                    onClick={(e) => handleRatioGraph(e)}
                                  />
                                )}
                                <label for="expenses">
                                  Operating expenses coverage
                                </label>
                              </div>
                            </td>
                            <td>{obj.expenses[0]}</td>
                            <td>{obj.expenses[1]}</td>
                            <td>{obj.expenses[2]}</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>
                              <div className="comparative-ratio">
                                {!loading && ratioType == "cost" ? (
                                  <input
                                    type="radio"
                                    id="cost"
                                    name="fav_language"
                                    value="cost"
                                    disabled
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="radio"
                                    id="cost"
                                    name="fav_language"
                                    value="cost"
                                    onClick={(e) => handleRatioGraph(e)}
                                  />
                                )}
                                <label for="cost">Average cost of debt</label>
                              </div>
                            </td>
                            <td>{obj.cost[0]}</td>
                            <td>{obj.cost[1]}</td>
                            <td>{obj.cost[2]}</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>
                              <div className="comparative-ratio">
                                {!loading && ratioType == "interests" ? (
                                  <input
                                    type="radio"
                                    id="coverage"
                                    name="fav_language"
                                    value="interests"
                                    disabled
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="radio"
                                    id="coverage"
                                    name="fav_language"
                                    value="interests"
                                    onClick={(e) => handleRatioGraph(e)}
                                  />
                                )}

                                <label for="coverage">Interests coverage</label>
                              </div>
                            </td>
                            <td>{obj.interests[0]}</td>
                            <td>{obj.interests[1]}</td>
                            <td>{obj.interests[2]}</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>
                              <div className="comparative-ratio">
                                {!loading && ratioType == "debt" ? (
                                  <input
                                    type="radio"
                                    id="Debt"
                                    name="fav_language"
                                    value="debt"
                                    disabled
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="radio"
                                    id="Debt"
                                    name="fav_language"
                                    value="debt"
                                    onClick={(e) => handleRatioGraph(e)}
                                  />
                                )}
                                <label for="Debt">Debt to equity</label>
                              </div>
                            </td>
                            <td>{obj.debt[0]}</td>
                            <td>{obj.debt[0]}</td>
                            <td>{obj.debt[0]}</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>
                              <div className="comparative-ratio">
                                {!loading && ratioType == "short" ? (
                                  <input
                                    type="radio"
                                    id="Short-term"
                                    name="fav_language"
                                    value="short"
                                    disabled
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="radio"
                                    id="Short-term"
                                    name="fav_language"
                                    value="short"
                                    onClick={(e) => handleRatioGraph(e)}
                                  />
                                )}
                                <label for="Short-term">Short-term debt</label>
                              </div>
                            </td>
                            <td>{obj.short[0]}</td>
                            <td>{obj.short[1]}</td>
                            <td>{obj.short[2]}</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>
                              <div className="comparative-ratio">
                                {!loading && ratioType == "current" ? (
                                  <input
                                    type="radio"
                                    id="ratio"
                                    name="fav_language"
                                    value="current"
                                    disabled
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="radio"
                                    id="ratio"
                                    name="fav_language"
                                    value="current"
                                    onClick={(e) => handleRatioGraph(e)}
                                  />
                                )}
                                <label for="ratio">Current ratio</label>
                              </div>
                            </td>
                            <td>{obj.current[0]}</td>
                            <td>{obj.current[1]}</td>
                            <td>{obj.current[2]}</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>
                              <div className="comparative-ratio">
                                {!loading && ratioType == "quick" ? (
                                  <input
                                    type="radio"
                                    id="Quick-ratio"
                                    name="fav_language"
                                    value="quick"
                                    disabled
                                    checked
                                  />
                                ) : (
                                  <input
                                    type="radio"
                                    id="Quick-ratio"
                                    name="fav_language"
                                    value="quick"
                                    onClick={(e) => handleRatioGraph(e)}
                                  />
                                )}
                                <label for="Quick-ratio">Quick ratio</label>
                              </div>
                            </td>
                            <td>{obj.quick[0]}</td>
                            <td>{obj.quick[1]}</td>
                            <td>{obj.quick[2]}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="table-responsive">
                      <table className="table table-bordered mb-0">
                        <thead>
                          <tr className="table-ration-input-header-top-shjbnskbg wrsf">
                            <th
                              colSpan={3}
                              className="text-center ghbmv-jsvuhjnc-jb hrdfgsgfg"
                            >
                              Sol Melia
                            </th>
                          </tr>
                          <tr className="table-ration-input">
                            <th scope="col">2007</th>
                            <th scope="col">2008</th>
                            <th scope="col">2009</th>
                          </tr>
                        </thead>
                        <tbody className="table-ration-input-body-wrap-hbbcfbdbfvbj">
                          <tr className="ratio-single-info">
                            <td colSpan={4}></td>
                          </tr>

                          <tr className="table-ration-input">
                            <td>{obj.gross[4]}</td>
                            <td>{obj.gross[5]}</td>
                            <td>{obj.gross[6]}</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>{obj.roe[4]}</td>
                            <td>{obj.roe[4]}</td>
                            <td>{obj.roe[4]}</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>{obj.roa[4]}</td>
                            <td>{obj.roa[5]}</td>
                            <td>{obj.roa[6]}</td>
                          </tr>
                          <tr className="ratio-single-info">
                            <td colSpan={4}></td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>{obj.storage[4]}</td>
                            <td>{obj.storage[5]}</td>
                            <td>{obj.storage[6]}</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>{obj.receivable[4]}</td>
                            <td>{obj.receivable[5]}</td>
                            <td>{obj.receivable[6]}</td>
                          </tr>

                          <tr className="table-ration-input">
                            <td>{obj.payable[4]}</td>
                            <td>{obj.payable[5]}</td>
                            <td>{obj.payable[6]}</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>{obj.fixed[4]}</td>
                            <td>{obj.fixed[5]}</td>
                            <td>{obj.fixed[6]}</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>{obj.total[4]}</td>
                            <td>{obj.total[5]}</td>
                            <td>{obj.total[6]}</td>
                          </tr>
                          <tr className="ratio-single-info">
                            <td colSpan={4}></td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>{obj.expenses[4]}</td>
                            <td>{obj.expenses[5]}</td>
                            <td>{obj.expenses[6]}</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>{obj.cost[4]}</td>
                            <td>{obj.cost[5]}</td>
                            <td>{obj.cost[6]}</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>{obj.interests[4]}</td>
                            <td>{obj.interests[5]}</td>
                            <td>{obj.interests[6]}</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>{obj.debt[4]}</td>
                            <td>{obj.debt[5]}</td>
                            <td>{obj.debt[6]}</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>{obj.short[4]}</td>
                            <td>{obj.short[4]}</td>
                            <td>{obj.short[4]}</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>{obj.current[4]}</td>
                            <td>{obj.current[5]}</td>
                            <td>{obj.current[6]}</td>
                          </tr>
                          <tr className="table-ration-input">
                            <td>{obj.quick[4]}</td>
                            <td>{obj.quick[5]}</td>
                            <td>{obj.quick[6]}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <RatioGraph
                  data={comparativeData ? comparativeData : undefined}
                />
                {/* <Line options={options} data={data} />; */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparativeRatio;
