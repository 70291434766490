import React from "react";

const GrowthIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="74"
      height="75"
      viewBox="0 0 74 75"
      fill="none"
    >
      <g clipPath="url(#clip0_441_1298)">
        <mask
          id="mask0_441_1298"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="74"
          height="75"
        >
          <path
            d="M-3.05176e-05 0.724129H74V74.7241H-3.05176e-05V0.724129Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_441_1298)">
          <path
            d="M19.2322 39.3378V73.2788"
            stroke="white"
            strokeWidth="4.313"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M19.2322 53.5835L26.2444 46.5713"
          stroke="white"
          strokeWidth="4.313"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.2325 47.3501L13.945 42.0626"
          stroke="white"
          strokeWidth="4.313"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <mask
          id="mask1_441_1298"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="74"
          height="75"
        >
          <path
            d="M-3.05176e-05 0.724129H74V74.7241H-3.05176e-05V0.724129Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_441_1298)">
          <path
            d="M37 72.4038V16.0443"
            stroke="white"
            strokeWidth="4.313"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <path
          d="M37.0316 25.6353L41.8659 20.801"
          stroke="white"
          strokeWidth="4.313"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.7227 32.1592L30.9505 26.387"
          stroke="white"
          strokeWidth="4.313"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <mask
          id="mask2_441_1298"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="74"
          height="75"
        >
          <path
            d="M-3.05176e-05 0.724129H74V74.7241H-3.05176e-05V0.724129Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2_441_1298)">
          <path
            d="M65.4508 39.8101C65.6823 38.9204 65.8058 37.9872 65.8058 37.0252C65.8058 30.9342 60.8681 25.9966 54.7773 25.9966C48.6865 25.9966 43.7489 30.9342 43.7489 37.0252C43.7489 38.7824 44.1605 40.4433 44.8917 41.9178C40.3602 43.0394 37 47.1311 37 52.0093C37 57.7517 41.655 62.4067 47.3974 62.4067C49.8365 62.4067 52.0776 61.5647 53.8509 60.1584C55.7944 61.5722 58.1862 62.4067 60.7735 62.4067C67.28 62.4067 72.5547 57.1321 72.5547 50.6256C72.5547 45.781 69.6302 41.6201 65.4508 39.8101Z"
            stroke="white"
            strokeWidth="4.313"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M53.8514 43.6975V73.2788"
            stroke="white"
            strokeWidth="4.313"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M53.8514 50.313L59.9629 44.2015"
            stroke="white"
            strokeWidth="4.313"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M53.8515 55.8335L48.0978 50.0799"
            stroke="white"
            strokeWidth="4.313"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.44528 73.2788H72.5547"
            stroke="white"
            strokeWidth="4.313"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M72.5554 9.34745C72.5554 13.3119 69.3414 16.5259 65.3769 16.5259C61.4124 16.5259 58.1986 13.3119 58.1986 9.34745C58.1986 5.38295 61.4124 2.16901 65.3769 2.16901C69.3414 2.16901 72.5554 5.38295 72.5554 9.34745Z"
            stroke="white"
            strokeWidth="4.313"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.63033 49.8165C5.19879 56.4801 11.6637 61.208 19.2326 61.208C28.2887 61.208 35.7641 54.44 36.8764 45.6861"
            stroke="white"
            strokeWidth="4.313"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24.5401 26.4412C22.8638 25.9179 21.0811 25.6357 19.2322 25.6357C11.663 25.6357 5.19808 30.3639 2.62976 37.0278"
            stroke="white"
            strokeWidth="4.313"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M49.3448 27.4253C49.5005 26.294 49.5809 25.1386 49.5809 23.9644C49.5809 14.6511 44.5207 6.52006 37 2.16952C29.4792 6.52006 24.419 14.6511 24.419 23.9644C24.419 33.2777 29.4792 41.4086 37 45.7593C39.7364 44.1764 42.1472 42.0928 44.1069 39.634"
            stroke="white"
            strokeWidth="4.313"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_441_1298">
          <rect
            width="74"
            height="74"
            fill="white"
            transform="translate(-3.05176e-05 0.724121)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GrowthIcon;
