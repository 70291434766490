import React from "react";
import { Form } from "react-bootstrap";
import inputBg from "./img/file.svg";
const EditEducatorCopyBody = () => {
  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>Edit Educator Copy</h3>
            <p>
              Course <i className="bx bx-chevron-right"></i> educator copy
              <i className="bx bx-chevron-right"></i>
              <span>Edit Educator Copy</span>{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="package-main-content-list kjhgfwertyuiutfc">
        <div className="add-new-format-area-wrap">
          <div className="add-new-format-inner-item-wrap">
            <h4>Edit Educator Copy</h4>
            <Form className="format-upload-form-main-area-zkjdh">
              <Form.Group className="mb-3" controlId="AuthorName">
                <Form.Label>Educator Name</Form.Label>
                <Form.Control type="Text" placeholder="Type category name" />
              </Form.Group>{" "}
              <Form.Group className="mb-3" controlId="AcademicArea">
                <Form.Label>Academic Area</Form.Label>
                <Form.Control type="Text" placeholder="Type category name" />
              </Form.Group>{" "}
              <Form.Group className="mb-3" controlId="Category">
                <Form.Label>Category</Form.Label>
                <Form.Control type="Text" placeholder="Type category name" />
              </Form.Group>{" "}
              <Form.Group>
                <Form.Label>Upload user image</Form.Label>
                <div className="button-area-input-data-wrap">
                  <label
                    for="file-upload"
                    // style={{ backgroundImage: `url(${inputBg})` }}
                  >
                    <img src={inputBg} alt="" />
                    Upload Your Data
                  </label>
                  <input type="file" id="file-upload" />
                </div>
              </Form.Group>
              <div className="">
                <div className="create-new-format-btn-area-wrap">
                  <button>Update</button>
                  <button>Cancel</button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEducatorCopyBody;
