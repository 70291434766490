import { Field, FieldArray, Form, Formik } from "formik"
import React, { useEffect, useRef, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import { Button, Label } from "reactstrap"
import InnerLayer from "../../../Layout/InnerLayer"
import PageHeader from "../../../Layout/PageHeader"

import QuillComponent from "../../../components/InputComponent/QuillComponent"

import FileUpload from "../../../components/InputComponent/FileUpload"
import CustomInput from "../../../components/InputComponent/CustomInput"
import CardComponent from "../../../components/Common/CardComponent"
import QuestionTopCard from "../../../components/Common/QuestionTopCard"
import { createHash } from "../../../helpers/Custom/Hash"
import '../Retail.scss'


const initialValues = {
  headerTitle: "",
  headerDescription: "",
  headerPhoto: null,
  body:"",
  bodyPhoto: null,
  overAllPhoto: null
 
}

const CreateGameInfo = ({ history, api }) => {
  const textAreaRef = useRef(null)
  const { passageId, questionId, serviceId, section, type } = useParams()

  const dispatch = useDispatch()
  const [listValue, setListValue] = useState(null)
  const [isAddMore, setIsAddMore] = useState(false)
  const [request, setRequest] = useState(false)
  const [sectionData, setSection] = useState("")
  const title = `Game Info`;
const title2 = questionId
  ? `Edit  Question`
  : `Create Game Info`;
const breadcrumbItems = [
 
  {
    title: title,
    link: `/Quantative-ques-type`,
  },
  {
    title: title2,
    link: `#`,
  },
];






  const onSubmit = (values, { setSubmitting, resetForm }) => {
    console.log("values", values)
    const {
    
      headerTitle,
      headerDescription,
      body,
      
      ...rest
    } = values
    const obj = {
      ...rest,
      headerTitle: createHash(headerTitle),
      headerDescription: createHash(headerDescription),
      body: createHash(body),

     
    }
    const option = {
      isMore: isAddMore,
      resetForm,
      setSubmitting,
      setIsAddMore,
      id: questionId,
      passageId,
      history,
      serviceId,
      type,
    }
    console.log(`obj`, obj, option)
   
  }


  return (
    <React.Fragment>
      
      <InnerLayer >
      <PageHeader title={title2} breadcrumbItems={breadcrumbItems} />
        <Row className="create_questions">
          <Col md="12">
            <QuestionTopCard
              history={history}
              title={`${
                questionId ? "Update" : "Create"
              } Game Info`}
            />
         <Row className="mx-auto">
            <Col md="6">
            <CardComponent style={{ marginTop: "5px" }}>
              <h5>Type question</h5>
              <br />
              <Formik
                initialValues={listValue || initialValues}
                // validationSchema={TwoPartCreateSchema}
                onSubmit={onSubmit}
                enableReinitialize={true}
              >
                {({
                  values,
                  errors,
                  setFieldValue,
                  isSubmitting,
                  handleSubmit,
                }) => (
                  <Form>
                    {console.log("values,", values, errors)}

                    <Row className="mb-3">
                  
                      <Col md="12 mb-3 " >
                        <Field
                          label="Header title"
                          name="headerTitle"
                         
                          id={"headerTitle"}
                          component={QuillComponent}
                         
                        />
                      </Col>
                      <Col md="12 mb-3 " >
                        <Field
                          label="Header description"
                          name="headerDescription"
                         
                          id={"headerDescription"}
                          component={QuillComponent}
                         
                        />
                      </Col>
                      <Col md="12 mb-3 " >
                        <Field
                          label="General Description"
                          name="body"
                         
                          id={"body"}
                          component={QuillComponent}
                         
                        />
                      </Col>
                      <Col md="12 mb-3 " >
                        <Field
                          label="Header photo"
                          name="headerPhoto"
                         
                          id={"headerPhoto"}
                          component={FileUpload}
                         
                        />
                      </Col>
                      <Col md="12 mb-3 " >
                        <Field
                          label="Body photo"
                          name="bodyPhoto"
                         
                          id={"bodyPhoto"}
                          component={FileUpload}
                         
                        />
                      </Col>
                      <Col md="12 mb-3 " >
                        <Field
                          label="Overall photo"
                          name="overAllPhoto"
                         
                          id={"overAllPhoto"}
                          component={FileUpload}
                         
                        />
                      </Col>
                   
                   
                    </Row>


                   

                 
                  
                          
             
                    <Row className="mb-3">
                      <Col md="12" className="d-flex justify-content-end">
                        <Button
                          color="warning"
                          outline
                          className="me-2 btn btn-outline-blue"
                          
                          disabled={isSubmitting || false}
                          type="submit"
                        >
                          {isSubmitting
                            ? "Submitting..."
                            : questionId
                            ? "Update"
                            : "Save"}
                        </Button>
                        {!questionId ? (
                          <button
                            color="primary"
                            style={{color:"white"}}
                            disabled={isSubmitting || false}
                            type="button"
                            onClick={() => {
                              setIsAddMore(true)
                              handleSubmit()
                              if(handleSubmit){
                                let isSubmit = true
                                if(isAddMore===isSubmit){
                                  
                                }
                              }
                            }}
                            className="btn button-blue"
                          >
                            {isSubmitting
                              ? "Submitting..."
                              : "Create more question"}
                          </button>
                        ) : null}
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </CardComponent>
            </Col>
            <Col md="6">
            
            </Col>
         </Row>
          </Col>
        </Row>
        </InnerLayer>
     
    </React.Fragment>
  )
}

export default CreateGameInfo
