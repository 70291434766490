import React from "react";
import { Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import deleteIcon from "../../../img/close-circle.svg";
import editIcon from "../../../img/edit-2.svg";
import { FormatData } from "./FormatData";
const EducatorCopyBody = () => {
  return (
    <div>
      <div className="packge-header">
        <div className="packge-header-left">
          <div className="title">
            <h3>Educator Copy List</h3>
            <p>
              Course list > <span>Educator Copy List</span>{" "}
            </p>
          </div>
        </div>
        <div className="packge-header-right">
          <Link to="/create-new-educator-copy">
            <span>+ </span>
            Create New
          </Link>
        </div>
      </div>
      <div className="data-search-and-filtering-area-wrap">
        <div className="data-search-inner-area-jhhbcvhkjzjghfjdn">
          <div className="search-box-dfghjkiujfb">
            <input type="search" name="" id="" />
          </div>
          <div className="select-filtering-area-jyfgvasjyhdfb">
            <Form.Group>
              <Form.Select>
                <option value="">Select name</option>
              </Form.Select>
            </Form.Group>
          </div>
        </div>
      </div>
      <div className="package-main-content-list">
        <Table borderless>
          <thead className="package-main-content-list-table-header">
            <tr>
              <th>#</th>
              <th>Author Name</th>
              <th>Academic Area Name</th>
              <th>Category Name</th>
              <th>Slug</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {FormatData.map((item, i) => {
              return (
                <tr key={i}>
                  <td>{item.id}</td>

                  <td>{item.AcademicAreaName}</td>
                  <td>{item.formatName}</td>
                  <td>{item.CategoryName}</td>

                  <td>{item.Slug}</td>
                  <td>
                    <div className="package-control-action-area">
                      <Link to="/edit-educator-copy">
                        <img src={editIcon} alt="" /> Edit
                      </Link>
                      <button>
                        <img src={deleteIcon} alt="" />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default EducatorCopyBody;
