import { Table } from "react-bootstrap";

const AsCoustomer = () => {
  return (
    <div className="as-organization-content-area">
      <h2>As Customer</h2>
      <p>The Target Dilemma</p>
      <p>
        Confidential role information for Mr. Fahmid; Owner M/S Shymoli Traders
        (Customer)
      </p>
      <p>
        You are the owner of M/S Shymoli Traders, one of the largest
        distributors in the Adabor area with over 60+ retailers under your
        coverage. You are doing business with Lafarge bangladesh for the past 5
        years and since 2019 you are their exclusive dealer in the Adabor area.{" "}
      </p>
      <p>
        Starting from February’ 2022, cement price is increasing due to various
        reason but as like many others you are also suffering. The biggest hit
        from the market is that your retail coverage is decreasing rapidly due
        to retailers backing out from selling a high priced product with the
        current credit terms.{" "}
      </p>
      <p>
        Amidst of that, one newcomer Mitali cement has taken the industry by
        storm by offering huge TLP program in retail and retailers are pulling
        this cement like anything. Although it is yet to be tested how it
        performs in the end consumer, retailers are pushing their distributors
        to lift the product since Mitali is only operating in the Distributor
        channel.{" "}
      </p>
      <p>
        You contacted one of Mitali’s ASM to offer you a deal and within 3 days
        they have offered you the deal. You also contacted ASM of Lafarge
        Bangladesh and after a brief comparison you have found out the following
      </p>
      <Table bordered responsive>
        <thead>
          <tr>
            <th>Cement Company</th>
            <th>Price</th>
            <th>Target </th>
            <th>Regular Incentive</th>
            <th>Additional incentive</th>
            <th>Credit Terms</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Lafarge Bangladesh </td>
            <td>550 taka</td>

            <td>50000 (ASM will confirm later)</td>
            <td>5 taka/bag</td>
            <td>You don't know if there is any</td>
            <td>30 days</td>
          </tr>
          <tr>
            <td>Mitali Cement</td>
            <td>520 taka</td>
            <td>40000 (ASM will confirm later)</td>
            <td>7 taka/bag</td>
            <td>1 taka/ bag additional for the next 10000 bag</td>
            <td>40 days</td>
          </tr>
        </tbody>
      </Table>
      <p>
        Apart from that, You want the credit terms to increase as much as
        possible since the price is now higher and retail feedback is that
        customers are now prone for a flexible credit term.{" "}
      </p>
      <p>
        It seems Mitali Cement’s offer is too good to be true, right? Well there
        is a catch, and you know this. You are not entirely sure how it will
        perform at the consumer level whereas Lafarge already has a good
        consumer base and their product is top quality with a proven history. At
        the end you do not want customer grievances.
      </p>
      <p>
        Mitali’s offer stands for 7 days and its final. So you choose to
        negotiate with the representative of Lafarge Cement. Your objective is
        to get the best deal out of Lafarge Bangladesh in amidst of price hike.{" "}
      </p>
      <p>
        While doing that find out your BATNA, Reservation value and ZOPA (if
        there is one)
      </p>
      <p>
        <b>
          Record your pitch on how you will prepare for this negotiation, what
          will be the opening argument and the possible solution for your
          win-win negotiation
        </b>
      </p>
    </div>
  );
};

export default AsCoustomer;
