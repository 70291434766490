export const FormatData = [
  {
    id: 1,
    QuizName: "5.0 Brand Campaign/launcn.....",
    Duration: "2 Min",
    PassingMark: "60%",
    TotalQuestion: "03",
  },
  {
    id: 2,
    QuizName: "5.0 Brand Campaign/launcn.....",
    Duration: "2 Min",
    PassingMark: "60%",
    TotalQuestion: "03",
  },
  {
    id: 3,
    QuizName: "5.0 Brand Campaign/launcn.....",
    Duration: "2 Min",
    PassingMark: "60%",
    TotalQuestion: "03",
  },
  {
    id: 4,
    QuizName: "5.0 Brand Campaign/launcn.....",
    Duration: "2 Min",
    PassingMark: "60%",
    TotalQuestion: "03",
  },
  {
    id: 5,
    QuizName: "5.0 Brand Campaign/launcn.....",
    Duration: "2 Min",
    PassingMark: "60%",
    TotalQuestion: "03",
  },
  {
    id: 6,
    QuizName: "5.0 Brand Campaign/launcn.....",
    Duration: "2 Min",
    PassingMark: "60%",
    TotalQuestion: "03",
  },
  {
    id: 7,
    QuizName: "5.0 Brand Campaign/launcn.....",
    Duration: "2 Min",
    PassingMark: "60%",
    TotalQuestion: "03",
  },
  {
    id: 8,
    QuizName: "5.0 Brand Campaign/launcn.....",
    Duration: "2 Min",
    PassingMark: "60%",
    TotalQuestion: "03",
  },
  {
    id: 9,
    QuizName: "5.0 Brand Campaign/launcn.....",
    Duration: "2 Min",
    PassingMark: "60%",
    TotalQuestion: "03",
  },
];
