const Contept4 = () => {
  return (
    <svg
      width="86"
      height="86"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2038_35686)">
        <path
          d="M31.9137 69.187H10.0781C9.15043 69.187 8.39844 69.939 8.39844 70.8667C8.39844 71.7944 9.15043 72.5464 10.0781 72.5464H31.9137C32.8413 72.5464 33.5933 71.7944 33.5933 70.8667C33.5933 69.939 32.8413 69.187 31.9137 69.187Z"
          fill="black"
        />
        <path
          d="M10.0781 65.5796H20.9961C21.9238 65.5796 22.6758 64.8276 22.6758 63.8999C22.6758 62.9722 21.9238 62.2202 20.9961 62.2202H10.0781C9.15043 62.2202 8.39844 62.9722 8.39844 63.8999C8.39844 64.8276 9.1506 65.5796 10.0781 65.5796Z"
          fill="black"
        />
        <path
          d="M31.9137 55.2539H10.0781C9.15043 55.2539 8.39844 56.0059 8.39844 56.9336C8.39844 57.8613 9.15043 58.6132 10.0781 58.6132H31.9137C32.8413 58.6132 33.5933 57.8613 33.5933 56.9336C33.5933 56.0059 32.8413 55.2539 31.9137 55.2539Z"
          fill="black"
        />
        <path
          d="M10.0781 51.6465H20.9961C21.9238 51.6465 22.6758 50.8945 22.6758 49.9668C22.6758 49.0391 21.9238 48.2871 20.9961 48.2871H10.0781C9.15043 48.2871 8.39844 49.0391 8.39844 49.9668C8.39844 50.8945 9.1506 51.6465 10.0781 51.6465Z"
          fill="black"
        />
        <path
          d="M31.9137 41.3203H10.0781C9.15043 41.3203 8.39844 42.0723 8.39844 43C8.39844 43.9277 9.15043 44.6797 10.0781 44.6797H31.9137C32.8413 44.6797 33.5933 43.9277 33.5933 43C33.5933 42.0723 32.8413 41.3203 31.9137 41.3203Z"
          fill="black"
        />
        <path
          d="M10.0781 37.7134H20.9961C21.9238 37.7134 22.6758 36.9614 22.6758 36.0337C22.6758 35.106 21.9238 34.354 20.9961 34.354H10.0781C9.15043 34.354 8.39844 35.106 8.39844 36.0337C8.39844 36.9614 9.1506 37.7134 10.0781 37.7134Z"
          fill="black"
        />
        <path
          d="M31.914 27.3872H17.6377C16.71 27.3872 15.958 28.1392 15.958 29.0669C15.958 29.9946 16.71 30.7465 17.6377 30.7465H31.914C32.8417 30.7465 33.5937 29.9946 33.5937 29.0669C33.5937 28.1392 32.8417 27.3872 31.914 27.3872Z"
          fill="black"
        />
        <path
          d="M22.6758 22.1001C22.6758 21.1724 21.9238 20.4204 20.9961 20.4204H10.0781C9.15043 20.4204 8.39844 21.1724 8.39844 22.1001C8.39844 23.0278 9.15043 23.7798 10.0781 23.7798H20.9961C21.9238 23.7798 22.6758 23.0278 22.6758 22.1001Z"
          fill="black"
        />
        <path
          d="M31.9137 13.4541H10.0781C9.15043 13.4541 8.39844 14.2061 8.39844 15.1338C8.39844 16.0615 9.15043 16.8134 10.0781 16.8134H31.9137C32.8413 16.8134 33.5933 16.0615 33.5933 15.1338C33.5933 14.2061 32.8413 13.4541 31.9137 13.4541Z"
          fill="black"
        />
        <path
          d="M43.9149 23.2879C44.2429 23.6159 44.6728 23.7799 45.1026 23.7799C45.5324 23.7799 45.9624 23.6159 46.2903 23.2879L53.257 16.3212C53.9129 15.6653 53.9129 14.6017 53.257 13.9456C52.6011 13.2895 51.5375 13.2897 50.8814 13.9456L45.1024 19.7246L42.8068 17.429C42.1509 16.773 41.0873 16.773 40.4314 17.429C39.7755 18.0849 39.7755 19.1484 40.4314 19.8044L43.9149 23.2879Z"
          fill="black"
        />
        <path
          d="M84.9848 31.3953L82.7678 29.1783C82.1134 28.5239 81.2418 28.1636 80.3131 28.1636C79.3846 28.1636 78.5129 28.524 77.8586 29.1783L73.3215 33.7154C72.6732 33.3104 71.8091 33.3882 71.2454 33.9517C70.6817 34.5154 70.604 35.3794 71.0089 36.0278L60.4682 46.5687V7.72702C60.4682 5.87468 58.9612 4.36768 57.1088 4.36768H3.35934C1.507 4.36768 0 5.87468 0 7.72702V78.2734C0 80.1257 1.507 81.6327 3.35934 81.6327H57.1088C58.9612 81.6327 60.4682 80.1257 60.4682 78.2734V60.8213L78.1353 43.1541C78.4064 43.3236 78.7146 43.4096 79.0237 43.4096C79.4535 43.4096 79.8835 43.2455 80.2113 42.9176C80.775 42.3539 80.8528 41.4899 80.4479 40.8416L84.985 36.3044C86.3385 34.951 86.3383 32.7487 84.9848 31.3953ZM50.2707 66.2679L46.3116 67.8517L47.8951 63.8925L73.3529 38.4349L75.7283 40.8103L50.2707 66.2679ZM57.1088 78.2732H3.35934V7.72702H57.1088V49.9281L45.276 61.7609C45.1151 61.9218 44.9888 62.1136 44.9043 62.3247L41.737 70.243C41.4874 70.8669 41.6337 71.5795 42.1087 72.0546C42.4298 72.3757 42.8593 72.5465 43.2967 72.5465C43.5065 72.5465 43.718 72.5072 43.9202 72.4263L51.8385 69.2589C52.0496 69.1744 52.2415 69.0479 52.4024 68.8872L57.1088 64.1808V78.2732ZM82.6094 33.929L78.1037 38.4348L75.7283 36.0594L80.234 31.5538C80.2747 31.513 80.3514 31.513 80.3923 31.5538L82.6092 33.7708C82.6502 33.8115 82.6502 33.8884 82.6094 33.929Z"
          fill="black"
        />
        <path
          d="M43.9151 37.2211C44.2301 37.5361 44.6574 37.7131 45.1028 37.7131C45.5483 37.7131 45.9756 37.5361 46.2905 37.2211L53.2572 30.2543C53.9131 29.5984 53.9131 28.5348 53.2572 27.8787C52.6013 27.2226 51.5377 27.2228 50.8816 27.8787L45.1027 33.6579L42.807 31.3622C42.1511 30.7063 41.0875 30.7063 40.4315 31.3622C39.7754 32.0181 39.7755 33.0817 40.4315 33.7378L43.9151 37.2211Z"
          fill="black"
        />
        <path
          d="M45.1026 51.6465C45.5324 51.6465 45.9624 51.4826 46.2903 51.1546L53.257 44.1879C53.9129 43.532 53.9129 42.4684 53.257 41.8123C52.6011 41.1562 51.5375 41.1564 50.8814 41.8123L45.1024 47.5913L42.8068 45.2957C42.1509 44.6397 41.0873 44.6397 40.4314 45.2957C39.7755 45.9516 39.7755 47.0151 40.4314 47.6711L43.9147 51.1544C44.2429 51.4826 44.6728 51.6465 45.1026 51.6465Z"
          fill="black"
        />
        <path
          d="M10.0784 27.3872C9.15074 27.3872 8.39941 28.1392 8.39941 29.0669C8.39941 29.9946 9.15191 30.7466 10.0796 30.7466C11.0073 30.7466 11.7593 29.9946 11.7593 29.0669C11.7593 28.1392 11.0073 27.3872 10.0796 27.3872H10.0784Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_2038_35686">
          <rect width="86" height="86" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Contept4;
