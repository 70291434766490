 
import
{
    UPDATE_PROFILE,
    UPDATE_PROFILE_FAIL,
    UPDATE_PROFILE_SUCCESS,
    GET_PROFILE,
    GET_PROFILE_SUCCESS,
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_FAIL,
    UPDATE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAIL
} from './actionTypes';
 

const initialState = {
    loading: false,
    isloading: false,
    isloadingPass :false,
    data: {},
    message: "",
    error: "",
    
}

const updateAccountReducer = (state = initialState, action) =>
{
    switch (action.type) {
        case GET_PROFILE:
            state = {
                ...state,
                isloading: true
            }
            break
        case GET_PROFILE_SUCCESS:
            state = {
                ...state,
                isloading: false,
                loading : false,
                data : action.payload.data
            }
            break
        case UPDATE_PASSWORD:
            state = {
                ...state,
                loading: true, 

            }
            break
        case UPDATE_PASSWORD_SUCCESS:
            state = {
                ...state,
                loading: false,
                message: action.payload.message
            }
            break
        case UPDATE_PASSWORD_FAIL:
            state = {
                ...state,
                loading: false,
                error : action.payload.message
            }
            break
        case UPDATE_PROFILE:
            state = {
                ...state,
                loading: true
            }
            break
        case UPDATE_PROFILE_SUCCESS:
            state = {
                ...state,
                loading: false,
                message: action.payload.message
            }
            break
        case UPDATE_PROFILE_FAIL:
            state = {
                ...state,
                loading: false,
                error : action.payload.message
            }
            break
        case CHANGE_PASSWORD:
            state = {
                ...state,
                isloadingPass: true
            }
            break
        case CHANGE_PASSWORD_SUCCESS:
            state = {
                ...state,
                isloadingPass: false,
                message : action.payload.message
            }
            break
        case CHANGE_PASSWORD_FAIL:
            state = {
                ...state,
                isloadingPass: false,
                error : action.payload.message
            }
            break
            default:
                state = state
                break
    }
    return state
}

export default updateAccountReducer