import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const StudentEnroll = ({ enrolmentData }) => {
  const data = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Sales Analytics",
        backgroundColor: "#F7B941",
        width: "15px",
        data: enrolmentData,
        borderRadius: 25,
      },
      //   {
      //     label: "Sales Analytics",
      //     backgroundColor: "rgba(52, 195, 143, 0.8)",

      //     data: [50, 20, 13, 25, 32, 21, 81, 45, 56, 80, 50, 20],
      //   },
    ],
  };

  const option = {
    showScale: true,
    pointDot: true,
    showLines: false,
    scales: {
      dataset: [
        {
          barPercentage: 0.4,
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            min: 0,
            max: 100,
          },
        },
      ],
    },
  };

  return <Bar data={data} options={option} />;
};

export default StudentEnroll;
