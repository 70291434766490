
//registration
export const REGISTRATION_USER = "REGISTRATION_USER"
export const REGISTRATION_USER_SUCCESS = "REGISTRATION_USER_SUCCESS"
export const REGISTRATION_USER_FAIL = "REGISTRATION_USER_FAIL"

//RESET PASSWORD
export const RESET_PASSWORD = "RESET_PASSWORD"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL"
//ACCOUnt -VERIFY
export const ACCOUNT_VERIFY_OF_USER = "ACCOUNT_VERIFY_OF_USER"
export const ACCOUNT_VERIFY_OF_USER_SUCCESS = "ACCOUNT_VERIFY_OF_USER_SUCCESS"
export const ACCOUNT_VERIFY_OF_USER_FAIL = "ACCOUNT_VERIFY_OF_USER_FAIL"