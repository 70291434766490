import { Button, Col, Container, Form, Row } from "react-bootstrap";
import editIcon from "./img/edit.svg";
import profileImage from "./img/profileImage.png";
const TeacherProfileEditPage = () => {
  return (
    <div>
      <Container>
        <div className="student-profile-view-main-area">
          <div className="student-profile-title-area">
            <h3>Profile Edit</h3>
          </div>
          <Row>
            <Col lg={6}>
              <div className="stident-profile-content-fgbhdnjmk gfhdjskuedj">
                <h3>Profile Information</h3>
                <div className="student-profile-image-area-top-tgbnmkiuh">
                  <div className="profile-image-upload-btn-gfhdjksuej">
                    <label for="profileImage">
                      <img src={editIcon} alt="" />
                    </label>
                    <input type="file" name="profileImage" id="profileImage" />
                    <img src={profileImage} alt="" />
                    <h4>Your image</h4>
                  </div>
                </div>
                <div className="student-course-profile-bottom-edfswrkjhg">
                  <h4>Details</h4>
                  <Form>
                    <Row>
                      <Col lg={6}>
                        <div className="student-profile-update-tgbdhsjka">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicFirstName"
                          >
                            <Form.Label>First name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type your first name"
                            />
                          </Form.Group>
                        </div>
                      </Col>{" "}
                      <Col lg={6}>
                        <div className="student-profile-update-tgbdhsjka">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicLastName"
                          >
                            <Form.Label>Last name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type your last name"
                            />
                          </Form.Group>
                        </div>
                      </Col>{" "}
                      <Col lg={12}>
                        <div className="student-profile-update-tgbdhsjka">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                              type="email"
                              style={{ textTransform: "lowercase" }}
                              placeholder="Type your email"
                            />
                          </Form.Group>
                        </div>
                      </Col>{" "}
                      <Col lg={12}>
                        <div className="student-profile-update-tgbdhsjka">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicLastName"
                          >
                            <Form.Label>Phone No:</Form.Label>
                            <Form.Control
                              type="email"
                              style={{ textTransform: "lowercase" }}
                              placeholder="Type your phone number"
                            />
                          </Form.Group>
                        </div>
                      </Col>{" "}
                      <Col lg={6}>
                        <div className="student-profile-update-tgbdhsjka">
                          <Button variant="primary" type="submit">
                            Update
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="stident-profile-content-fgbhdnjmk gfhdjskuedj right-gfdhsjkaudhncbcnm">
                <div className="student-course-profile-bottom-edfswrkjhg hjkrhdncbvcnmxjc">
                  <h4>Details</h4>
                  <Form>
                    <Row>
                      <Col lg={12}>
                        <div className="student-profile-update-tgbdhsjka">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicFirstName"
                          >
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={3} />
                            {/* <Form.Control
                              type="text"
                              placeholder="Type your Institute/Company"
                            /> */}
                          </Form.Group>
                        </div>
                      </Col>{" "}
                      {/* <Col lg={6}>
                        <div className="student-profile-update-tgbdhsjka">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicLastName"
                          >
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Type your last name"
                            />
                          </Form.Group>
                        </div>
                      </Col>{" "}
                      <Col lg={6}>
                        <div className="student-profile-update-tgbdhsjka">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Confirm Password"
                            />
                          </Form.Group>
                        </div>
                      </Col>{" "} */}
                      <Col lg={6}>
                        <div className="student-profile-update-tgbdhsjka mt-2">
                          <Button variant="primary" type="submit">
                            Update
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>{" "}
              <div className="stident-profile-content-fgbhdnjmk gfhdjskuedj right-gfdhsjkaudhncbcnm">
                <div className="student-course-profile-bottom-edfswrkjhg hjkrhdncbvcnmxjc">
                  <h4>Change password</h4>
                  <Form>
                    <Row>
                      <Col lg={12}>
                        <div className="student-profile-update-tgbdhsjka">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicFirstName"
                          >
                            <Form.Label>Institute/Company</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Type your Institute/Company"
                            />
                          </Form.Group>
                        </div>
                      </Col>{" "}
                      <Col lg={6}>
                        <div className="student-profile-update-tgbdhsjka">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicLastName"
                          >
                            <Form.Label>New Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Type your last name"
                            />
                          </Form.Group>
                        </div>
                      </Col>{" "}
                      <Col lg={6}>
                        <div className="student-profile-update-tgbdhsjka">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                              type="password"
                              placeholder="Confirm Password"
                            />
                          </Form.Group>
                        </div>
                      </Col>{" "}
                      <Col lg={6}>
                        <div className="student-profile-update-tgbdhsjka mt-2">
                          <Button variant="primary" type="submit">
                            Update
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default TeacherProfileEditPage;
