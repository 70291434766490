import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import weNeedImage from "../../LafargeAssets/images/we-need-image.png";
import weNeedImage2 from "../../LafargeAssets/images/we-need-image2.png";
import weNeedImage3 from "../../LafargeAssets/images/we-need-image3.png";
import weNeedImage4 from "../../LafargeAssets/images/we-need-image4.png";
const WhyDoWeNeedDetails = () => {
  const { courseId } = useParams();
  return (
    <Container>
      <div className="lafarge-training-inner-wrap">
        <div className="lafarge-training-header-title">
          <h3>Why do we need to focus on negotiation?</h3>
          {/* <p>Author: Nazmus Shakib</p> */}
        </div>
        <div className="why-do-we-need-content-area">
          <p>
            {" "}
            In case of LafargeHolcim, the day to day negotiation interactions
            happens on pricing, credit terms, mapping the market for business
            development. More customer acquisition, more area coverage or
            tapping into more govt projects or private projects are the key to
            flourish the business and that’s where negotiation makes a huge
            impact. We have explored those opportunities in the workshop and
            with this simulation platform we will create more master negotiators
            who can navigate through internal and external stakeholders
            achieving the expected business outcome.
          </p>
          <div>
            <Row className="mt-3">
              <Col md={6} lg={3}>
                <div className="why-do-we-need-bottom-image">
                  <h5>Handling pricing objection with non-price items</h5>
                  <img src={weNeedImage} alt="" className="img-fluid" />
                </div>
              </Col>{" "}
              <Col md={6} lg={3}>
                <div className="why-do-we-need-bottom-image">
                  <h5>Creating a WIN-WIN situation to tackle credit terms</h5>
                  <img src={weNeedImage2} alt="" className="img-fluid" />
                </div>
              </Col>{" "}
              <Col md={6} lg={3}>
                <div className="why-do-we-need-bottom-image">
                  <h5>Establishing the power of market mapping </h5>
                  <img src={weNeedImage3} alt="" className="img-fluid" />
                </div>
              </Col>{" "}
              <Col md={6} lg={3}>
                <div className="why-do-we-need-bottom-image">
                  <h5>Identifying your best alternative to a negotiation. </h5>
                  <img src={weNeedImage4} alt="" className="img-fluid" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="lafarge-training-footer-wrap">
          <div className="lafarge-training-footer-button lafarge-details">
            <Link to={`/${courseId}/training-details`}>Previous </Link>
            <Link to={`/${courseId}/negotiation-method`}>Next</Link>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default WhyDoWeNeedDetails;
