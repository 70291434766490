import React from "react";
import { useLocation } from "react-router-dom";
import RetailGameLayout from "./RetailGameLayout";
import SuccessfulIcon from "./img/SuccessfulIcon";
import { Button } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
const SubmisstionPage = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const {
    
    courseName,
    courseId
  } = useSelector((state) => ({
   
    courseName: state.studentGame.courseName,
    courseId: state.studentGame.courseId,
    
  }));
   console.log(courseName, courseId);
  return (
    <div>
      <RetailGameLayout pathname={pathname}>
        <div className="successful-page-content-area">
          <SuccessfulIcon />
          <h4>অভিনন্দন</h4>
          <p>আপনি সফলভাবে কুইজ সম্পন্ন করেছেন</p>
          <button
          className="next-quiz-submit-btn"
          onClick={()=>history.push(`/game-information/${courseId}/${courseName}`)}
          
        >
          <span>
            <span>
              <span>
                <span>ব্যাক টু গেম</span>
              </span>
            </span>
          </span>
        </button>
          
        </div>
      </RetailGameLayout>
    </div>
  );
};

export default SubmisstionPage;
