import React from "react"

import { Route, useHistory } from "react-router-dom"
import { useSelector } from "react-redux"
 
import LoadingToRedirect from "./LoadingToRedirect"

const AdminRoute = ({ children, ...rest }) => {
  const { token, email, userrole } = useSelector(state => ({ ...state.Login }))
   

 
  return token && userrole === "admin" ? (
  
      <Route {...rest} render={() => children} />
     
  ) : (
    <LoadingToRedirect />
  )
}

export default AdminRoute
