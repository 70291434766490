import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import profileImage from "../../../assests/images/teacherH.png";
import AuthorCourseData from "./AuthorCourseData";
const AuthorViewProfile = ({ authorData }) => {
  let history = useHistory();
  return (
    <div>
      <Container>
        <div className="student-profile-view-main-area">
          <div className="student-profile-title-area">
            <h3>Profile</h3>
          </div>
          <Row className="g-3">
            <Col lg={3}>
              <div className="stident-profile-content-fgbhdnjmk mfhsdkjhb">
                {/* <div className="edit-profile-student-part">
              <Link to="/edit-teacher-profile">EDIT</Link>
            </div> */}
                <div className="student-profile-image-area-top-tgbnmkiuh teacher-ghjuyhgbuygb jvndkvnb">
                  <img src={profileImage} alt="" />
                  <h4>{authorData?.authorName}</h4>
                  <span>{authorData?.email}</span>
                  <p>
                    This technical note explains what a financial restructuring
                    of a company involves, differentiating it from other types
                    of transactions which share similarities, such as
                    operational restructurings or debt refinancings. It also
                    explains the main financial tools generally used in
                    financial restructuring deals, their advantages and main
                    implementation principles.
                  </p>
                </div>
                <div className="student-course-profile-bottom-edfswr jikbdkghfijk">
                  <Row>
                    <Col lg={12}>
                      <div className="student-course-info-wsxcde">
                        <p>Total Academic Area</p>
                        <h2>
                          2
                          {/* {authorData?.getSingleData?.academicArea?.length} */}
                        </h2>
                      </div>
                    </Col>{" "}
                    <Col lg={12}>
                      <div className="student-course-info-wsxcde">
                        <p>Total Course Submitted</p>
                        <h2>
                          5
                          {/* {authorData?.getSingleData?.academicArea?.length} */}
                        </h2>
                      </div>
                    </Col>{" "}
                    <Col lg={12}>
                      <div className="student-course-info-wsxcde">
                        <p>Total Enrolled</p>
                        <h2>
                          10k
                          {/* {authorData?.getSingleData?.academicArea?.length} */}
                        </h2>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={9}>
              <AuthorCourseData authorData={authorData} />
            </Col>
          </Row>
          <div className="student-buy-course-list-and-profile-info">
            <Row>
              {/* <Col lg={12}>
                <div className="student-buy-course-list">
                  <div className="student-buy-course-list-title">
                    <h4>Course</h4>
                  </div>
                  <div className="course-list-profile-page student-courses-page">
                    <div className="dashboard-sidebar-single-item">
                      <div className="sidebar-course-list">
                        {authorData?.getSingleData?.course.map((v, i) => (
                          <div className="course-sidebar-single-item ujhgfbsdvzbsd">
                            <div className="course-sidebar-single-item-left-sdfghcjv">
                              <img
                                height="45px"
                                width={"70px"}
                                src={`https://staging-courseware-backend.sgp1.digitaloceanspaces.com/${v.image}`}
                                alt=""
                              />
                              <p>{v.courseName}</p>
                            </div>
                            <div className="course-sidebar-single-item-right-sdfghcjv">
                              <span>Ongoing</span>
                              <div>
                                <p>Academic Area</p>

                                <p>Finance</p>
                              </div>{" "}
                              <div>
                                <p>Format</p>
                                <p>Coursware</p>
                              </div>{" "}
                              <div>
                                <p>Total Student</p>
                              <p>8,909</p>
                              </div>{" "}
                              <div>
                                <p>Total Student</p>
                              <p>8,909</p>
                              </div>
                              <button
                                onClick={(e) =>
                                  history.push(`/course-details-page/${v._id}`)
                                }
                              >
                                Overview
                              </button>
                            </div>
                          </div>
                        ))}{" "}
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </Col> */}
              {/* <Col lg={6}>
                <div className="student-buy-course-list">
                  <div className="student-buy-course-list-title">
                    <h4>Ranking</h4>
                    <div className="student-result-filtering-area-hdmbfkbn">
                      <Form.Select aria-label="Select Academic Area">
                        <option>Open this select menu</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </Form.Select>
                    </div>
                  </div>
                  <div className="course-list-profile-page student-courses-page">
                    <div className="sidebar-course-list">
                      <div className="course-sidebar-single-item-main-wrap ujhgfbsdvzbsd kjhgfedxgfd">
                        <div className="course-sidebar-single-item ujhgfbsdvzbsd kjhgfedxgfd">
                          <div className="course-sidebar-single-item-left-sdfghcjv easfv">
                            <span className="course-sidebar-single-item-jhkdfgnsglfkzdg">
                              <p>100.</p>
                            </span>
                            <img src={courseIcon2} alt="" />
                            <p>Samsad Rashid</p>
                          </div>
                          <div className="course-sidebar-single-item-middle-sdfghcjv rthbgxh">
                            <p>Games</p>
                          </div>
                          <div className="course-sidebar-single-item-right-sdfghcjv rthbgxh">
                            <p>66 Points</p>
                          </div>
                        </div>{" "}
                      </div>{" "}
                      <div className="course-sidebar-single-item-main-wrap ujhgfbsdvzbsd kjhgfedxgfd">
                        <div className="course-sidebar-single-item ujhgfbsdvzbsd kjhgfedxgfd">
                          <div className="course-sidebar-single-item-left-sdfghcjv easfv">
                            <span className="course-sidebar-single-item-jhkdfgnsglfkzdg">
                              <p>120.</p>
                            </span>
                            <img src={courseIcon2} alt="" />
                            <p>Samsad Rashid</p>
                          </div>
                          <div className="course-sidebar-single-item-middle-sdfghcjv rthbgxh">
                            <p>Games</p>
                          </div>
                          <div className="course-sidebar-single-item-right-sdfghcjv rthbgxh">
                            <p>76 Points</p>
                          </div>
                        </div>{" "}
                      </div>{" "}
                      <div className="course-sidebar-single-item-main-wrap ujhgfbsdvzbsd kjhgfedxgfd">
                        <div className="course-sidebar-single-item ujhgfbsdvzbsd kjhgfedxgfd">
                          <div className="course-sidebar-single-item-left-sdfghcjv easfv">
                            <span className="course-sidebar-single-item-jhkdfgnsglfkzdg">
                              <p>120.</p>
                            </span>
                            <img src={courseIcon2} alt="" />
                            <p>Samsad Rashid</p>
                          </div>
                          <div className="course-sidebar-single-item-middle-sdfghcjv rthbgxh">
                            <p>Games</p>
                          </div>
                          <div className="course-sidebar-single-item-right-sdfghcjv rthbgxh">
                            <p>76 Points</p>
                          </div>
                        </div>{" "}
                      </div>{" "}
                      <div className="course-sidebar-single-item-main-wrap ujhgfbsdvzbsd kjhgfedxgfd">
                        <div className="course-sidebar-single-item ujhgfbsdvzbsd kjhgfedxgfd">
                          <div className="course-sidebar-single-item-left-sdfghcjv easfv">
                            <span className="course-sidebar-single-item-jhkdfgnsglfkzdg">
                              <p>120.</p>
                            </span>
                            <img src={courseIcon2} alt="" />
                            <p>Samsad Rashid</p>
                          </div>
                          <div className="course-sidebar-single-item-middle-sdfghcjv rthbgxh">
                            <p>Games</p>
                          </div>
                          <div className="course-sidebar-single-item-right-sdfghcjv rthbgxh">
                            <p>76 Points</p>
                          </div>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </Col> */}
            </Row>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AuthorViewProfile;
