import React from "react";

const DragAndDropIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.71167 3.19494C6.71167 2.49207 6.10755 1.91699 5.36917 1.91699C4.6308 1.91699 4.02667 2.49207 4.02667 3.19494C4.02667 3.89782 4.6308 4.4729 5.36917 4.4729C6.10754 4.4729 6.71167 3.89782 6.71167 3.19494Z"
        fill="#222222"
      />
      <path
        d="M6.71167 12.1405C6.71167 11.4376 6.10754 10.8625 5.36917 10.8625C4.63079 10.8625 4.02667 11.4376 4.02667 12.1405C4.02667 12.8434 4.63079 13.4185 5.36917 13.4185C6.10754 13.4185 6.71167 12.8434 6.71167 12.1405Z"
        fill="#222222"
      />
      <path
        d="M6.71167 7.66785C6.71167 6.96497 6.10755 6.38989 5.36917 6.38989C4.6308 6.38989 4.02667 6.96497 4.02667 7.66784C4.02667 8.37072 4.6308 8.9458 5.36917 8.9458C6.10754 8.9458 6.71167 8.37072 6.71167 7.66785Z"
        fill="#222222"
      />
      <path
        d="M12.0828 3.19494C12.0828 2.49207 11.4786 1.91699 10.7403 1.91699C10.0019 1.91699 9.39776 2.49207 9.39776 3.19494C9.39776 3.89782 10.0019 4.4729 10.7403 4.4729C11.4786 4.4729 12.0828 3.89782 12.0828 3.19494Z"
        fill="#222222"
      />
      <path
        d="M12.0828 12.1405C12.0828 11.4376 11.4786 10.8625 10.7403 10.8625C10.0019 10.8625 9.39776 11.4376 9.39776 12.1405C9.39776 12.8434 10.0019 13.4185 10.7403 13.4185C11.4786 13.4185 12.0828 12.8434 12.0828 12.1405Z"
        fill="#222222"
      />
      <path
        d="M12.0828 7.66784C12.0828 6.96497 11.4786 6.38989 10.7403 6.38989C10.0019 6.38989 9.39776 6.96497 9.39776 7.66784C9.39776 8.37072 10.0019 8.9458 10.7403 8.9458C11.4786 8.9458 12.0828 8.37072 12.0828 7.66784Z"
        fill="#222222"
      />
    </svg>
  );
};

export default DragAndDropIcon;
