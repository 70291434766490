import { call, put, takeEvery } from "redux-saga/effects";
import { toaster } from "../../../helpers/Custom/Toaster";
import { getData } from "../../../helpers/backend_helper";
import { GET_RETAIL_COURSE, GET_SECOND_GAME } from "./actionTypes";
import { getRetailCourseFail, getRetailCourseSuccess, getRetailSecondGameFail, getRetailSecondGameSuccess, storeSecondGameData } from "./actions";

function* onGetRetailCourse({
  payload: { token, courseId = "6480517659b3e80e18caa53e" },
}) {
  try {
    const url = `/get-exam-questions/${courseId}/drugdrop`;
    const response = yield call(getData, url, token);
    console.log(`response:`, response);
    yield put(getRetailCourseSuccess(response?.getData));
  } catch (error) {
    const message = error.response?.data?.message || "get retail failed";
    yield put(getRetailCourseFail(message));
    toaster("error", message);
  }
}
function* onGetSecondGame({
  payload: { token, courseId = "64b2180d420ea51f00b28a3f" },
}) {
  try {
    const url = `/scroll/get-single-scroll/${courseId}`;
    const response = yield call(getData, url, token);
    console.log(`response:`, response?.getData?.questionArr);
    yield put(getRetailSecondGameSuccess(response?.getData));
    yield put(storeSecondGameData("totalQues", response?.getData?.questionArr?.length))
  } catch (error) {
    const message = error.response?.data?.message || "get retail failed";
    yield put(getRetailSecondGameFail(message));
    toaster("error", message);
  }
}
function* RetailGeneralSaga() {
  yield takeEvery(GET_RETAIL_COURSE, onGetRetailCourse);
  yield takeEvery(GET_SECOND_GAME, onGetSecondGame);
}

export default RetailGeneralSaga;
