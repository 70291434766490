// import React, { useState } from 'react';
// import { ListGroup } from 'react-bootstrap';
// import fiancialImg from "../../assests/images/more.svg";
// const data = [
//       {
//         title: "one",
//         price: 22,
//         id: "1",
//       },
//       {
//         title: "two",
//         price: 3,
//         id: "2",
//       },
//       {
//         title: "three",
//         price: 2288,
//         id: "3",
//       },
//     ];
// const SampleDragandDrop = () => {
//     const [list, setList] = useState(data);
//     const handletDragStart = (e, item) => {
//     }

//     return (
//         <div>
//              <div>
//           <div className="row">
//             <div className="col-lg-6">
//               <div className="single-revenue">
//                 <div>
//                   <div className="financial-single-revenue-item  product-details-area">
//                     <div className="revenue-item-table-header">
//                       <h2>Revenue</h2>
//                     </div>
//                     <div className="revenue-item-middle-item">
//                       <div className="row">
//                         <div className="col-md-6">
//                           <div className="financial-revenue-item left-item">
//                             <img src={fiancialImg} />
//                             <h4>Salaries Expenses</h4>
//                             <h6>22</h6>
//                           </div>
//                         </div>
//                         <div className="col-md-6">
//                           <div className="financial-revenue-item right-item">
//                             <img src={fiancialImg} />
//                             <h4>Salaries Expenses</h4>
//                             <h6>222 </h6>
//                           </div>
//                         </div>
//                       </div>{" "}
//                     </div>
//                     <div className="revenue-item-bottom-item">
//                       <div className="revenue-item-bottom-single-item">
//                         <h5>Total Net Revenue</h5>
//                       </div>
//                       <div className="revenue-item-bottom-single-item">
//                         <h4>100</h4>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="single-revenue">
//                 <div>
//                   <div className="financial-single-revenue-item  product-details-area">
//                     <div className="revenue-item-table-header">
//                       <h2>Cost</h2>
//                     </div>

//                     <div className="revenue-item-bottom-item">
//                       <div className="revenue-item-bottom-single-item">
//                         <h5>Total Net cost</h5>
//                       </div>
//                       <div className="revenue-item-bottom-single-item">
//                         <h4>100</h4>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div>
//                 <div className="total-net-profit">
//                   <div>
//                     {" "}
//                     <p>Total Net Profit BDT</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="col-lg-6">
//               <div className="revenue-item-middle-item">
//                 <div className="row">
//                     {list.map((item,item1)=>(
//                   <div className="col-md-6">
//                     <div className="financial-revenue-item left-item" draggable key={item} onDragStart={handletDragStart}>
//                       <img src={fiancialImg} />
//                       <h4>Salaries Expenses</h4>
//                       <h6>22</h6>
//                     </div>
//                   </div>
//                   ))}

//                 </div>{" "}
//               </div>
//             </div>
//           </div>
//         </div>

//         </div>
//     );
// };

// export default SampleDragandDrop;

// // import React, { useState } from "react";
// // import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// // const data = [
// //   {
// //     title: "one",
// //     price: 22,
// //     id: "1",
// //   },
// //   {
// //     title: "two",
// //     price: 3,
// //     id: "2",
// //   },
// //   {
// //     title: "three",
// //     price: 2288,
// //     id: "3",
// //   },
// // ];
// // const SampleDragandDrop = () => {
// //   const [list, setList] = useState(data);
// //   const onEnd = (result) => {
// //     console.log(result);
// //   };
// //   return (
// //     <DragDropContext onDragEnd={onEnd}>
// //       <Droppable droppableId="12121">
// //         {(providedDroppable, snapshot) => {
// //           <div {...providedDroppable.droppableProps} ref={providedDroppable.innerRef}>

// //             {list.map((item, index) => (
// //               <Draggable draggableId={item.id} key={item.id} index={index}>
// //                 {(providedDraggable, snapshot) => (
// //                   <div
// //                   {...providedDraggable.draggableProps} {...providedDraggable.dragHandleProps} ref={providedDraggable.innerRef}
// //                   >
// //                     <div>
// //                       <p>djbkjdzb</p>
// //                     </div>
// //                     <div>

// //                          <p>zdbzbdc</p>

// //                     </div>
// //                   </div>
// //                 )}
// //               </Draggable>
// //             ))}
// //             {/* {provided.placeholder} */}
// //           </div>;
// //         }}
// //       </Droppable>
// //     </DragDropContext>
// //   );
// // };

// // export default SampleDragandDrop;
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Container, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import fiancialImg from "../../assests/images/more.svg";

const itemsFromBackend = [
  { id: uuidv4(), content: "First task" },
  { id: uuidv4(), content: "Second task" },
  { id: uuidv4(), content: "Third task" },
  { id: uuidv4(), content: "Fourth task" },
  { id: uuidv4(), content: "Fifth task" },
];

const columnsFromBackend = {
  [uuidv4()]: {
    name: "Requested",
    items: itemsFromBackend,
  },
  [uuidv4()]: {
    name: "To do",
    items: [],
  },
};

const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems,
      },
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  }
};

function App() {
  const [columns, setColumns] = useState(columnsFromBackend);
  return (
    <Container>
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
      >
        {Object.entries(columns).map(([columnId, column], index) => {
          return (
            <Row>
              <h2>{column.name}</h2>
              <div className="jfghiuzbj" style={{ margin: 8 }}>
                <Droppable droppableId={columnId} key={columnId}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        className="row"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {column.items.map((item, index) => {
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="col-lg-6"
                                  >
                                    <div className="revenue-item-middle-item">
                                      {/* <div className="row">
                                        <div className="col-md-6">
                                       
                                        </div>
                                      </div> */}
                                      <div className="financial-revenue-item left-item ">
                                        <img src={fiancialImg} />
                                        <h4>Salaries Expenses</h4>
                                        <h6>22</h6>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </Row>
          );
        })}
      </DragDropContext>
    </Container>
  );
}

export default App;
