import { call, put, takeEvery } from "redux-saga/effects";
import { COMPARATIVE_ANALYSIS, SUBMIT_REV } from "./actionTypes";

import { comparativeAnalysisSuccess, submitRevSuccess } from "./actions";
import { toast } from "react-toastify";

function* workerComparativeAnalysis({ payload: { ratioType, obj } }) {
	// console.log("hello data here", ratioType, obj);
	yield put(comparativeAnalysisSuccess(obj, ratioType));
}

function* workerSubmitRev({ payload: { revNumber, history } }) {
	// console.log("data he;;p", revNumber);
	if (revNumber == 67.5) {
		yield put(submitRevSuccess(1));
		history.push("/second-game-result");
	} else {
		yield put(submitRevSuccess(2));
	}
}

function* comparativeAnalysisSaga() {
	yield takeEvery(COMPARATIVE_ANALYSIS, workerComparativeAnalysis);
	yield takeEvery(SUBMIT_REV, workerSubmitRev);
}

export default comparativeAnalysisSaga;
