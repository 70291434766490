import {
  CLEAR_STUDENT_GAME_DATA,
  GET_SINGLE_GAME,
  GET_SINGLE_GAME_FAIL,
  GET_SINGLE_GAME_SUCCESS,
  GET_STUDENT_COURSE,
  GET_STUDENT_COURSE_FAIL,
  GET_STUDENT_COURSE_SUCCESS,
  GET_STUDENT_QUES,
  GET_STUDENT_QUES_FAIL,
  GET_STUDENT_QUES_SUCCESS,
  STORE_STUDENT_GAME_DATA,
} from "./actionTypes";

const INIT_STATE = {
  loading: true,
  error: "",
  data: [],
  adding: false,
  studentCourse: [],
  studentQues: {},
  mcqStartStep: 0,
  mcqEndStep: 0,
  dragDropStartStep: 0,
  dragDropEndStep: 0,
  gridStartStep: 0,
  gridEndStep: 0,
  type: "",
  currentIndex: 0,
  steps: [],
  currentStep: "",
  studentCourseLoading: true,
  studentQuesLoading: true,
  options: [],
  haveToDone: [],
  notToDone: [],
  typeArray: [],
  selected: "",
  mcqRightAns: false,
  currentType: "",
  totalQuestion: 0,
  countQuestion: 0,
  singleGameLoading:true,
  singleGame:[],
  courseName: "",
  courseId:""
};

const studentGame = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STUDENT_COURSE:
      return {
        ...state,
        studentCourseLoading: true,
      };
    case GET_STUDENT_COURSE_SUCCESS:
      return {
        ...state,
        studentCourseLoading: false,
        studentCourse: action.payload.values,
      };
    case GET_STUDENT_COURSE_FAIL:
      return {
        ...state,
        error: action.payload,
        studentCourseLoading: false,
      };
    case GET_STUDENT_QUES:
      return {
        ...state,
        studentQuesLoading: true,
      };

    case GET_STUDENT_QUES_SUCCESS:
      return {
        ...state,
        studentQuesLoading: false,
        studentQues: action.payload,
        options: action.payload.options,
      };

    case GET_STUDENT_QUES_FAIL:
      return {
        ...state,
        error: action.payload,
        studentQuesLoading: false,
      };
      case GET_SINGLE_GAME:
        return {
          ...state,
          singleGameLoading: true,
        };
  
      case GET_SINGLE_GAME_SUCCESS:
        return {
          ...state,
          singleGameLoading: false,
          singleGame: action.payload,
         
        };
  
      case GET_SINGLE_GAME_FAIL:
        return {
          ...state,
          error: action.payload,
          singleGameLoading: false,
        };
    case STORE_STUDENT_GAME_DATA:
      return {
        ...state,
        [action.payload.name]: action.payload.data,
      };
    case CLEAR_STUDENT_GAME_DATA:
      return {
        ...state,
        options: [],
        haveToDone: [],
        notToDone: [],
        mcqStartStep: 0,
        gridStartStep: 0,
        currentIndex: 0,
        dragDropStartStep:0,
        countQuestion: 0,
        currentType: "",
        selected: "",

      };

    default:
      return state;
  }
};

export default studentGame;
