import { call, put, takeEvery } from "redux-saga/effects";

import {
	RECRUITE_MANAGER,
	CALCULATE_BUDGETING,
	ADD_COST_OF_FINANCE,
	GET_REVENUE_ON_TAB,
	SET_SELLING_PRICE,
	CALCULATE_REVENUE,
	FINANCIAL_STATEMENT,
	CLEAN_DATA_PLAY_AGAIN,
} from "./actionTypes";

import {
	addRecruitManagerSuccess,
	addRecruitManagerFail,
	calculateBudgetingSuccess,
	addCostOfFinanceSuccess,
	getRevenueOnTabSuccess,
	setSellingPriceSuccess,
	calculateRevenueSuccess,
	financialStatementSuccess,
	cleanDataPlayAgainSuccess,
	
} from "./actions";
import { loginSuccess } from "../actions";
import { toast } from "react-toastify";

const maxCapacity = {
	prodOne: 150,
	prodTwo: 100,
};

function* workerRecruiteManager({ payload: { budget, position } }) {
 	//first delete position wise data
	let totalOpex = 0;
	if (localStorage.getItem(position) !== null) {
		if (localStorage.getItem("totalOpex")) {
			totalOpex =
				parseInt(localStorage.getItem("totalOpex")) -
				parseInt(localStorage.getItem(position))
					? parseInt(localStorage.getItem(position))
					: 0;
		}
	} else {
		// console.log("from her 3");
		if (localStorage.getItem("totalOpex")) {
			totalOpex = parseInt(localStorage.getItem("totalOpex"));
		}
	}
	// console.log("total Opex here", totalOpex);
	let totalCount = 0;
	totalOpex = totalOpex + parseInt(budget);
	localStorage.setItem(position, budget);
	localStorage.setItem("totalOpex", totalOpex);
	let one = parseInt(localStorage.getItem("firstEmp"));
	let two = parseInt(localStorage.getItem("secondEmp"));
	let three = parseInt(localStorage.getItem("thirdEmp"));
	let serial = 0;
	if (one) {
		totalCount += one;
		serial += 1;
	}
	if (two) {
		totalCount += two;
		serial += 1;
	}
	if (three) {
		totalCount += three;
		serial += 1;
	}
 	localStorage.setItem("totalOpex", totalCount);
	let obj = {};
	obj.first = one;
	obj.second = two;
	obj.third = three;
	obj.opex = totalCount;

	if (budget * 2 > 166 || totalCount * (serial * 2) > 498) {
		toast.error("You have Exceeded the budget limit");
	}

	yield put(addRecruitManagerSuccess(obj));
}

function* workerCalculateBudgeting({
	payload: { capacityUtilization, prodSerial },
}) {
 	let productionOne = 0;
	let productionTwo = 0;
	let capacity = maxCapacity[prodSerial];
 	if (prodSerial == "prodOne") {
		productionOne = ((capacity * capacityUtilization) / 100) * 10;
		localStorage.setItem("prodOne", productionOne);
	} else if (prodSerial == "prodTwo") {
		productionTwo = ((capacity * capacityUtilization) / 100) * 11;
		localStorage.setItem("prodTwo", productionTwo);
	}

	//first find total

	let localStorageProdOne = 0;
	let localStorageProdTwo = 0;
	let totalProductionCost = 0;
	if (localStorage.getItem(prodSerial) !== null) {
		if (localStorage.getItem("totalProductionCost") !== null) {
			totalProductionCost =
				parseInt(localStorage.getItem("totalProductionCost")) -
				parseInt(localStorage.getItem(prodSerial));
		}
	} else if (localStorage.getItem("totalProductionCost") !== null) {
		totalProductionCost = parseInt(
			localStorage.getItem("totalProductionCost"),
		);
	}
	localStorageProdOne = parseInt(localStorage.getItem("prodOne"));
	localStorageProdTwo = parseInt(localStorage.getItem("prodTwo"));
	let totalCost = 0;
	if (localStorageProdOne) {
		totalCost += localStorageProdOne;
	}
	if (localStorageProdTwo) {
		totalCost += localStorageProdTwo;
	}
	localStorage.setItem("totalProductionCost", parseInt(totalCost).toFixed(2));
	// console.log("production capacity", productionOne, productionTwo);
 	let obj = {};
	obj.prodCostOne = localStorageProdOne ? localStorageProdOne : 0;
	obj.prodCostTwo = localStorageProdTwo ? localStorageProdTwo : 0;
	obj.totalProductionCost = totalCost;
	let totalOpexCount = localStorage.getItem("totalOpex")
		? parseInt(localStorage.getItem("totalOpex"))
		: 0;
	let costOne = totalOpexCount + 1050 + 880 + 50 + 15;
	obj.costOfFinanceOne = costOne * 0.04;
	localStorage.setItem("beforeCostOfFinanceOne", costOne);
	let costTwo = totalOpexCount + obj.prodCostOne + obj.prodCostTwo + 50 + 10;
 	
	obj.costOfFinanceTwo = costTwo * 0.04;
	localStorage.setItem("beforeCostOfFinanceTwo", costTwo);
	obj.totalProdCostMonthOne = costOne + obj.costOfFinanceOne;
	// localStorage.setItem("totalProdCostMonthOne", obj.totalProdCostMonthOne);
	obj.totalProdCostMonthTwo = costTwo + obj.costOfFinanceTwo;
	// localStorage.setItem("totalProdCostMonthTwo", obj.totalProdCostMonthTwo);
	yield put(calculateBudgetingSuccess(obj));
}

function* workerAddCostOfFinance({ payload: { cost, costType } }) {
	localStorage.setItem(costType, cost);
	let totalProdCostMonthOne = 0;
	let totalProdCostMonthTwo = 0;
	let totalProdCostAMonthOne = 0;
	let totalProdCostAMonthTwo = 0;
	let totalProdCostBMonthOne = 0;
	let totalProdCostBMonthTwo = 0;
	let costOffinanceone = localStorage.getItem("costOfFinanceReal1");
	let costOffinanceTwo = localStorage.getItem("costOfFinanceReal2");
	let totalOpexCount = localStorage.getItem("totalOpex")
		? parseInt(localStorage.getItem("totalOpex"))
		: 0;

	let costOne = totalOpexCount + 1050 + 880 + 50 + 15;
		// obj.costOfFinanceOne = costOne * 0.04;
		
	localStorage.setItem("beforeCostOfFinanceOne", costOne);
 
	if (costType == "costOfFinanceReal1") {
	
		if (localStorage.getItem("costOfFinanceReal1") !== null) {
			 
				totalProdCostMonthOne =costOne

				let financeCostMonthOne = (totalProdCostMonthOne * cost) / 100;
				localStorage.setItem(
					"financeCostMonthOne",
					financeCostMonthOne,
				);
				localStorage.setItem(
					"totalProdCostMonthOne",
					parseInt(
						totalProdCostMonthOne + financeCostMonthOne,
					).toFixed(2),
				);
				totalProdCostAMonthOne =
					totalProdCostMonthOne + financeCostMonthOne - 880;
				localStorage.setItem(
					"totalProdCostAMonthOne",
					parseInt(totalProdCostAMonthOne).toFixed(2),
				);

				totalProdCostBMonthOne =
					totalProdCostMonthOne + financeCostMonthOne - 1050;
				localStorage.setItem(
					"totalProdCostBMonthOne",
					parseInt(totalProdCostBMonthOne).toFixed(2),
				);
		
		}
	} else if (costType == "costOfFinanceReal2") {
		if (localStorage.getItem("costOfFinanceReal2") !== null) {
			if (localStorage.getItem("beforeCostOfFinanceTwo") !== null) {
				totalProdCostMonthTwo = parseInt(
					localStorage.getItem("beforeCostOfFinanceTwo"),
				);
				let financeCostMonthTwo = (totalProdCostMonthTwo * cost) / 100;
				localStorage.setItem(
					"financeCostMonthTwo",
					financeCostMonthTwo,
				);
				localStorage.setItem(
					"totalProdCostMonthTwo",
					parseInt(
						totalProdCostMonthTwo + financeCostMonthTwo,
					).toFixed(2),
				);
				// totalProdCostAMonthOne =
				// totalProdCostMonthOne + financeCostMonthOne - 880;
				totalProdCostAMonthTwo =
					totalProdCostMonthTwo +
					financeCostMonthTwo -
					parseInt(localStorage.getItem("prodOne"));
				localStorage.setItem(
					"totalProdCostAMonthTwo",
					parseInt(totalProdCostAMonthTwo).toFixed(2),
				);

				totalProdCostBMonthTwo =
					totalProdCostMonthTwo +
					financeCostMonthTwo -
					parseInt(localStorage.getItem("prodTwo"));

				localStorage.setItem(
					"totalProdCostBMonthTwo",
					parseInt(totalProdCostBMonthTwo).toFixed(2),
				);
			}
		}
	}

	let obj = {};
	let cost1 = localStorage.getItem("totalProdCostMonthOne")
		? localStorage.getItem("totalProdCostMonthOne")
		: 0;
	let cost2 = localStorage.getItem("totalProdCostMonthTwo")
		? localStorage.getItem("totalProdCostMonthTwo")
		: 0;

	if (costOffinanceone !== null) {
		obj.costOffinanceone = costOffinanceone;
	}
 	if (costOffinanceTwo !== null) {
		obj.costOffinanceTwo = costOffinanceTwo;
		obj.totalProdCostMonthOne = cost1;
		obj.totalProdCostMonthTwo = cost2;
	}
	yield put(addCostOfFinanceSuccess(obj));
}

function* workerGetRevenueOnTab({ payload: { month } }) {
 
	if (month == "monthone") {
		let obj = {};
		obj.prodACost = 1050;
		obj.prodBCost = 880;
		obj.opex = localStorage.getItem("totalOpex")
			? localStorage.getItem("totalOpex")
			: 0;
		obj.prodATotalCost = localStorage.getItem("totalProdCostAMonthOne");
		obj.prodBTotalCost = localStorage.getItem("totalProdCostBMonthOne");
		obj.prodAComprice = 14;
		obj.prodBComprice = 16;
		yield put(getRevenueOnTabSuccess(obj));
	} else if (month == "monthtwo") {
		let obj = {};
		obj.prodACost = localStorage.getItem("prodOne");
		obj.prodBCost = localStorage.getItem("prodTwo");
		obj.opex = localStorage.getItem("totalOpex")
			? localStorage.getItem("totalOpex")
			: 0;
		obj.prodATotalCost = localStorage.getItem("totalProdCostAMonthTwo");
		obj.prodBTotalCost = localStorage.getItem("totalProdCostBMonthTwo");
		obj.prodAComprice = 14;
		obj.prodBComprice = 16;
		yield put(getRevenueOnTabSuccess(obj));
	}
}

function* workerSetSellingPrice({ payload: { markeUp, month, prodName } }) {
	// console.log("Hello markup data", markeUp, month, prodName);
	let obj = {};
	if (prodName == "prodA") {
		if (month == "monthOne") {
			localStorage.setItem("monthOneProdAPercentage", markeUp);
			let sellingPrice = 14 + (14 * markeUp) / 100;
			 
			localStorage.setItem("prodAmonthOne", sellingPrice);
			obj.prodAmonthOne = sellingPrice;

			let prodBmonthOne = localStorage.getItem("prodBmonthOne");
			let prodAmonthTwo = localStorage.getItem("prodAmonthTwo");
			let prodBmonthTwo = localStorage.getItem("prodBmonthTwo");

			if (prodBmonthOne !== null) {
				obj.prodBmonthOne = prodBmonthOne;
			}

			if (prodAmonthTwo !== null) {
				obj.prodAmonthTwo = prodAmonthTwo;
			}
			if (prodBmonthTwo !== null) {
				obj.prodBmonthTwo = prodBmonthTwo;
			}

			let monthOneProdAPercentage = localStorage.getItem(
				"monthOneProdAPercentage",
			);
			let monthOneProdBPercentage = localStorage.getItem(
				"monthOneProdBPercentage",
			);
			let monthTwoProdAPercentage = localStorage.getItem(
				"monthTwoProdAPercentage",
			);
			let monthTwoProdBPercentage = localStorage.getItem(
				"monthTwoProdBPercentage",
			);
			if (monthOneProdAPercentage !== null) {
				obj.monthOneProdAPercentage = monthOneProdAPercentage;
			}
			if (monthOneProdBPercentage !== null) {
				obj.monthOneProdBPercentage = monthOneProdBPercentage;
			}

			if (monthTwoProdAPercentage !== null) {
				obj.monthTwoProdAPercentage = monthTwoProdAPercentage;
			}
			if (monthTwoProdBPercentage !== null) {
				obj.monthTwoProdBPercentage = monthTwoProdBPercentage;
			}

		 
			yield put(setSellingPriceSuccess(obj));
		} else if (month == "monthTwo") {
			let sellingPrice = 14 + (14 * markeUp) / 100;
			 
			localStorage.setItem("monthTwoProdAPercentage", markeUp);

			localStorage.setItem("prodAmonthTwo", sellingPrice);
			obj.prodAmonthTwo = sellingPrice;

			let prodBmonthOne = localStorage.getItem("prodBmonthOne");
			let prodAmonthOne = localStorage.getItem("prodAmonthOne");
			let prodBmonthTwo = localStorage.getItem("prodBmonthTwo");

			if (prodBmonthOne !== null) {
				obj.prodBmonthOne = prodBmonthOne;
			}

			if (prodAmonthOne !== null) {
				obj.prodAmonthOne = prodAmonthOne;
			}
			if (prodBmonthTwo !== null) {
				obj.prodBmonthTwo = prodBmonthTwo;
			}

			let monthOneProdAPercentage = localStorage.getItem(
				"monthOneProdAPercentage",
			);
			let monthOneProdBPercentage = localStorage.getItem(
				"monthOneProdBPercentage",
			);
			let monthTwoProdAPercentage = localStorage.getItem(
				"monthTwoProdAPercentage",
			);
			let monthTwoProdBPercentage = localStorage.getItem(
				"monthTwoProdBPercentage",
			);
			if (monthOneProdAPercentage !== null) {
				obj.monthOneProdAPercentage = monthOneProdAPercentage;
			}
			if (monthOneProdBPercentage !== null) {
				obj.monthOneProdBPercentage = monthOneProdBPercentage;
			}

			if (monthTwoProdAPercentage !== null) {
				obj.monthTwoProdAPercentage = monthTwoProdAPercentage;
			}
			if (monthTwoProdBPercentage !== null) {
				obj.monthTwoProdBPercentage = monthTwoProdBPercentage;
			}
			yield put(setSellingPriceSuccess(obj));
		}
	} else if (prodName == "prodB") {
		if (month == "monthOne") {
			let sellingPrice = 14 + (14 * markeUp) / 100;
			localStorage.setItem("monthOneProdBPercentage", markeUp);

			// console.log("selling ", sellingPrice);
			localStorage.setItem("prodBmonthOne", sellingPrice);

			obj.prodBmonthOne = sellingPrice;

			let prodAmonthOne = localStorage.getItem("prodAmonthOne");
			let prodAmonthTwo = localStorage.getItem("prodAmonthTwo");
			let prodBmonthTwo = localStorage.getItem("prodBmonthTwo");

			if (prodAmonthTwo !== null) {
				obj.prodAmonthTwo = prodAmonthTwo;
			}

			if (prodAmonthOne !== null) {
				obj.prodAmonthOne = prodAmonthOne;
			}
			if (prodBmonthTwo !== null) {
				obj.prodBmonthTwo = prodBmonthTwo;
			}
			let monthOneProdAPercentage = localStorage.getItem(
				"monthOneProdAPercentage",
			);
			let monthOneProdBPercentage = localStorage.getItem(
				"monthOneProdBPercentage",
			);
			let monthTwoProdAPercentage = localStorage.getItem(
				"monthTwoProdAPercentage",
			);
			let monthTwoProdBPercentage = localStorage.getItem(
				"monthTwoProdBPercentage",
			);
			if (monthOneProdAPercentage !== null) {
				obj.monthOneProdAPercentage = monthOneProdAPercentage;
			}
			if (monthOneProdBPercentage !== null) {
				obj.monthOneProdBPercentage = monthOneProdBPercentage;
			}

			if (monthTwoProdAPercentage !== null) {
				obj.monthTwoProdAPercentage = monthTwoProdAPercentage;
			}
			if (monthTwoProdBPercentage !== null) {
				obj.monthTwoProdBPercentage = monthTwoProdBPercentage;
			}
			yield put(setSellingPriceSuccess(obj));
		} else if (month == "monthTwo") {
			let sellingPrice = 14 + (14 * markeUp) / 100;
			localStorage.setItem("monthTwoProdBPercentage", markeUp);

			// console.log("selling ", sellingPrice);
			localStorage.setItem("prodBmonthTwo", sellingPrice);
			obj.prodBmonthTwo = sellingPrice;

			let prodAmonthOne = localStorage.getItem("prodAmonthOne");
			let prodAmonthTwo = localStorage.getItem("prodAmonthTwo");
			let prodBmonthOne = localStorage.getItem("prodBmonthOne");

			if (prodAmonthTwo !== null) {
				obj.prodAmonthTwo = prodAmonthTwo;
			}

			if (prodAmonthOne !== null) {
				obj.prodAmonthOne = prodAmonthOne;
			}
			if (prodBmonthOne !== null) {
				obj.prodBmonthOne = prodBmonthOne;
			}
			let monthOneProdAPercentage = localStorage.getItem(
				"monthOneProdAPercentage",
			);
			let monthOneProdBPercentage = localStorage.getItem(
				"monthOneProdBPercentage",
			);
			let monthTwoProdAPercentage = localStorage.getItem(
				"monthTwoProdAPercentage",
			);
			let monthTwoProdBPercentage = localStorage.getItem(
				"monthTwoProdBPercentage",
			);
			if (monthOneProdAPercentage !== null) {
				obj.monthOneProdAPercentage = monthOneProdAPercentage;
			}
			if (monthOneProdBPercentage !== null) {
				obj.monthOneProdBPercentage = monthOneProdBPercentage;
			}

			if (monthTwoProdAPercentage !== null) {
				obj.monthTwoProdAPercentage = monthTwoProdAPercentage;
			}
			if (monthTwoProdBPercentage !== null) {
				obj.monthTwoProdBPercentage = monthTwoProdBPercentage;
			}
			yield put(setSellingPriceSuccess(obj));
		}
	}
}

function* workerCalculateRevenue({
	payload: { monthSerial, prodName, unitSold },
}) {
	// console.log("hello month serial of reveneu", monthSerial);
	let obj = {};
	if (prodName == "prodA") {
		if (monthSerial == "monthOne") {
			let sellingPrice = parseInt(localStorage.getItem("prodAmonthOne"));
			let revenue = sellingPrice * unitSold;
			localStorage.setItem("monthOneProdARevenue", revenue);
			localStorage.setItem("unitSoldProdAmonthOne", unitSold);
			obj.unitSoldProdAMonthOne = unitSold;
			let monthTwoProdARevenue = localStorage.getItem(
				"monthTwoProdARevenue",
			);
			let monthOneProdBRevenue = localStorage.getItem(
				"monthOneProdBRevenue",
			);
			let monthTwoProdBRevenue = localStorage.getItem(
				"monthTwoProdBReveneu",
			);

			if (monthTwoProdARevenue) {
				obj.monthTwoProdARevenue = monthTwoProdARevenue;
			}
			if (monthOneProdBRevenue) {
				obj.monthOneProdBRevenue = monthOneProdBRevenue;
			}

			if (monthTwoProdBRevenue) {
				obj.monthTwoProdBRevenue = monthTwoProdBRevenue;
			}
			obj.monthOneProdARevenue = revenue;

			yield put(calculateRevenueSuccess(obj));
		} else if (monthSerial == "monthTwo") {
			let sellingPrice = parseInt(localStorage.getItem("prodAmonthTwo"));
			let revenue = sellingPrice * unitSold;
			localStorage.setItem("monthTwoProdARevenue", revenue);
			localStorage.setItem("unitSoldProdAmonthTwo", unitSold);
			obj.unitSoldProdAMonthTwo = unitSold;
			let monthOneProdARevenue = localStorage.getItem(
				"monthOneProdARevenue",
			);
			let monthOneProdBRevenue = localStorage.getItem(
				"monthOneProdBRevenue",
			);
			let monthTwoProdBRevenue = localStorage.getItem(
				"monthTwoProdBReveneu",
			);

			if (monthOneProdARevenue) {
				obj.monthOneProdARevenue = monthOneProdARevenue;
			}
			if (monthOneProdBRevenue) {
				obj.monthOneProdBRevenue = monthOneProdBRevenue;
			}

			if (monthTwoProdBRevenue) {
				obj.monthTwoProdBRevenue = monthTwoProdBRevenue;
			}
			obj.monthTwoProdARevenue = revenue;

			yield put(calculateRevenueSuccess(obj));
		}
	} else if (prodName == "prodB") {
		if (monthSerial == "monthOne") {
			let sellingPrice = parseInt(localStorage.getItem("prodBmonthOne"));
			let revenue = sellingPrice * unitSold;
			localStorage.setItem("monthOneProdBRevenue", revenue);
			localStorage.setItem("unitSoldProdBmonthOne", unitSold);
			obj.unitSoldProdBmonthOne = unitSold;
			let monthTwoProdARevenue = localStorage.getItem(
				"monthTwoProdARevenue",
			);
			let monthOneProdARevenue = localStorage.getItem(
				"monthOneProdARevenue",
			);
			let monthTwoProdBRevenue = localStorage.getItem(
				"monthTwoProdBReveneu",
			);

			if (monthTwoProdARevenue) {
				obj.monthTwoProdARevenue = monthTwoProdARevenue;
			}
			if (monthOneProdARevenue) {
				obj.monthOneProdARevenue = monthOneProdARevenue;
			}

			if (monthTwoProdBRevenue) {
				obj.monthTwoProdBRevenue = monthTwoProdBRevenue;
			}
			obj.monthOneProdBRevenue = revenue;

			yield put(calculateRevenueSuccess(obj));
		} else if (monthSerial == "monthTwo") {
			let sellingPrice = parseInt(localStorage.getItem("prodBmonthTwo"));
			let revenue = sellingPrice * unitSold;
			localStorage.setItem("monthTwoProdBRevenue", revenue);
			localStorage.setItem("unitSoldProdBmonthTwo", unitSold);
			obj.unitSoldProdBmonthTwo = unitSold;
			let monthOneProdARevenue = localStorage.getItem(
				"monthOneProdARevenue",
			);
			let monthOneProdBRevenue = localStorage.getItem(
				"monthOneProdBRevenue",
			);
			let monthTwoProdARevenue = localStorage.getItem(
				"monthTwoProdAReveneu",
			);

			if (monthOneProdARevenue) {
				obj.monthOneProdARevenue = monthOneProdARevenue;
			}
			if (monthOneProdBRevenue) {
				obj.monthOneProdBRevenue = monthOneProdBRevenue;
			}

			if (monthTwoProdARevenue) {
				obj.monthTwoProdARevenue = monthTwoProdARevenue;
			}
			obj.monthTwoProdBRevenue = revenue;

			yield put(calculateRevenueSuccess(obj));
		}
	}
}

function* workerFinancialStatement({
	payload: { elementType, revenueOrCost, position },
}) {
	 
	let totalProfit =
		localStorage.getItem("totalProfit") !== null
			? parseInt(localStorage.getItem("totalProfit"))
			: 0;
	let totalCost =
		localStorage.getItem("totalCost") !== null
			? parseInt(localStorage.getItem("totalCost"))
			: 0;
	let checked = localStorage.getItem(position);
	if (checked == null || checked == "false") {
		localStorage.setItem(position, true);
 		if (revenueOrCost == "revenue") {
			let totalRevenueA = 0;
			let totalRevenueB = 0;
			let totalHrCost = 0;
			let productionCostA = 0;
			let productionCostB = 0;
			let totalOverHeads = 0;
			let totallaunchAndMarketing = 0;
			let totalcostOfFinance = 0;
			if (elementType == "revenueProductionA") {
				totalRevenueA =
					parseInt(localStorage.getItem("monthTwoProdARevenue")) +
					parseInt(localStorage.getItem("monthOneProdARevenue"));
				totalProfit += totalRevenueA;
			}
			if (elementType == "revenueProductionB") {
				totalRevenueB =
					parseInt(localStorage.getItem("monthTwoProdBRevenue")) +
					parseInt(localStorage.getItem("monthOneProdBRevenue"));
				totalProfit += totalRevenueB;
			}

			if (elementType == "hrCost") {
				totalHrCost = parseInt(localStorage.getItem("totalOpex")) * 2;
				totalProfit += totalHrCost;
			}

			if (elementType == "productionCostProdA") {
				productionCostA =
					parseInt(localStorage.getItem("prodOne")) + 1050;
				totalProfit += productionCostA;
			}

			if (elementType == "productionCostProdB") {
				productionCostB =
					parseInt(localStorage.getItem("prodTwo")) + 880;
				totalProfit += productionCostB;
			}

			if (elementType == "overHeads") {
				totalOverHeads = 100;
				totalProfit += totalOverHeads;
			}

			if (elementType == "launchAndMarketing") {
				totallaunchAndMarketing = 25;
				totalProfit += totallaunchAndMarketing;
			}

			if (elementType == "costOfFinance") {
				totalcostOfFinance =
					parseInt(localStorage.getItem("financeCostMonthOne")) +
					parseInt(localStorage.getItem("financeCostMonthTwo"));
				totalProfit += totalcostOfFinance;
			}
		 
			let obj = {};
			localStorage.setItem("totalProfit", totalProfit);
			let netProfit = totalProfit - totalCost;
			localStorage.setItem("netProfit", netProfit);
			yield put(financialStatementSuccess(obj));
		} else if (revenueOrCost == "cost") {
			let totalRevenueA = 0;
			let totalRevenueB = 0;
			let totalHrCost = 0;
			let productionCostA = 0;
			let productionCostB = 0;
			let totalOverHeads = 0;
			let totallaunchAndMarketing = 0;
			let totalcostOfFinance = 0;

			if (elementType == "revenueProductionA") {
				totalRevenueA =
					parseInt(localStorage.getItem("monthTwoProdARevenue")) +
					parseInt(localStorage.getItem("monthOneProdARevenue"));
				totalCost += totalRevenueA;
			}
			if (elementType == "revenueProductionB") {
				totalRevenueB =
					parseInt(localStorage.getItem("monthTwoProdBRevenue")) +
					parseInt(localStorage.getItem("monthOneProdBRevenue"));
				totalCost += totalRevenueB;
			}

			if (elementType == "hrCost") {
				totalHrCost = parseInt(localStorage.getItem("totalOpex")) * 2;
				totalCost += totalHrCost;
			}

			if (elementType == "productionCostProdA") {
				productionCostA =
					parseInt(localStorage.getItem("prodOne")) + 1050;
				totalCost += productionCostA;
			}

			if (elementType == "productionCostProdB") {
				productionCostB =
					parseInt(localStorage.getItem("prodTwo")) + 880;
				totalCost += productionCostB;
			}

			if (elementType == "overHeads") {
				totalOverHeads = 100;
				totalCost += totalOverHeads;
			}

			if (elementType == "launchAndMarketing") {
				totallaunchAndMarketing = 25;
				totalCost += totallaunchAndMarketing;
			}

			if (elementType == "costOfFinance") {
				totalcostOfFinance =
					parseInt(localStorage.getItem("financeCostMonthOne")) +
					parseInt(localStorage.getItem("financeCostMonthTwo"));
				totalCost += totalcostOfFinance;
			}
			let obj = {};
			localStorage.setItem("totalCost", totalCost);
			let netProfit = totalProfit - totalCost;
			localStorage.setItem("netProfit", netProfit);
			yield put(financialStatementSuccess(obj));
		}
	} else if (checked == "true") {
		// console.log("checked call from true");
		localStorage.setItem(position, false);
		if (revenueOrCost == "revenue") {
			let totalRevenueA = 0;
			let totalRevenueB = 0;
			let totalHrCost = 0;
			let productionCostA = 0;
			let productionCostB = 0;
			let totalOverHeads = 0;
			let totallaunchAndMarketing = 0;
			let totalcostOfFinance = 0;
			if (elementType == "revenueProductionA") {
				totalRevenueA =
					parseInt(localStorage.getItem("monthTwoProdARevenue")) +
					parseInt(localStorage.getItem("monthOneProdARevenue"));
				totalProfit -= totalRevenueA;
			}
			if (elementType == "revenueProductionB") {
				totalRevenueB =
					parseInt(localStorage.getItem("monthTwoProdBRevenue")) +
					parseInt(localStorage.getItem("monthOneProdBRevenue"));
				totalProfit -= totalRevenueB;
			}

			if (elementType == "hrCost") {
				totalHrCost = parseInt(localStorage.getItem("totalOpex")) * 2;
				totalProfit -= totalHrCost;
			}

			if (elementType == "productionCostProdA") {
				productionCostA =
					parseInt(localStorage.getItem("prodOne")) + 1050;
				totalProfit -= productionCostA;
			}

			if (elementType == "productionCostProdB") {
				productionCostB =
					parseInt(localStorage.getItem("prodTwo")) + 880;
				totalProfit -= productionCostB;
			}

			if (elementType == "overHeads") {
				totalOverHeads = 100;
				totalProfit -= totalOverHeads;
			}

			if (elementType == "launchAndMarketing") {
				totallaunchAndMarketing = 25;
				totalProfit -= totallaunchAndMarketing;
			}

			if (elementType == "costOfFinance") {
				totalcostOfFinance =
					parseInt(localStorage.getItem("financeCostMonthOne")) +
					parseInt(localStorage.getItem("financeCostMonthTwo"));
				totalProfit -= totalcostOfFinance;
			}

			let obj = {};

			localStorage.setItem("totalProfit", totalProfit);
			let netProfit = totalProfit - totalCost;
			localStorage.setItem("netProfit", netProfit);
			yield put(financialStatementSuccess(obj));
		} else if (revenueOrCost == "cost") {
			let totalRevenueA = 0;
			let totalRevenueB = 0;
			let totalHrCost = 0;
			let productionCostA = 0;
			let productionCostB = 0;
			let totalOverHeads = 0;
			let totallaunchAndMarketing = 0;
			let totalcostOfFinance = 0;
			// console.log("call from here posiiton 713");
			if (elementType == "revenueProductionA") {
				totalRevenueA =
					parseInt(localStorage.getItem("monthTwoProdARevenue")) +
					parseInt(localStorage.getItem("monthOneProdARevenue"));
				totalCost -= totalRevenueA;
			}
			if (elementType == "revenueProductionB") {
				totalRevenueB =
					parseInt(localStorage.getItem("monthTwoProdBRevenue")) +
					parseInt(localStorage.getItem("monthOneProdBRevenue"));
				totalCost -= totalRevenueB;
			}

			if (elementType == "hrCost") {
				totalHrCost = parseInt(localStorage.getItem("totalOpex")) * 2;
				totalCost -= totalHrCost;
			}

			if (elementType == "productionCostProdA") {
				productionCostA =
					parseInt(localStorage.getItem("prodOne")) + 1050;
				totalCost -= productionCostA;
			}

			if (elementType == "productionCostProdB") {
				productionCostB =
					parseInt(localStorage.getItem("prodTwo")) + 880;
				totalCost -= productionCostB;
			}

			if (elementType == "overHeads") {
				totalOverHeads = 100;
				totalCost -= totalOverHeads;
			}

			if (elementType == "launchAndMarketing") {
				totallaunchAndMarketing = 25;
				totalCost -= totallaunchAndMarketing;
			}

			if (elementType == "costOfFinance") {
				totalcostOfFinance =
					parseInt(localStorage.getItem("financeCostMonthOne")) +
					parseInt(localStorage.getItem("financeCostMonthTwo"));
				totalCost -= totalcostOfFinance;
			}
			let obj = {};
			localStorage.setItem("totalCost", totalCost);
			let netProfit = totalProfit - totalCost;
			localStorage.setItem("netProfit", netProfit);
			yield put(financialStatementSuccess(obj));
		}
	}
}

function* workerCleanDataPlayAgain({ payload: { empty,courseId, history } })
{
	// let "persist:primary"  = localStorage.getItem("persist:primary")
	let data = JSON.parse(localStorage.getItem("persist:primary"))
	data = JSON.parse(data.Login)
	let userName = data.username;
	let token = data.token
	let userrole = data.userrole
	let email = data.email
	console.log("data here", data, userName, token, userrole, email )
	localStorage.clear();
	yield put(loginSuccess(userName, token, email, userrole))
	cleanDataPlayAgainSuccess()
 	yield put(cleanDataPlayAgainSuccess(history.push(`/${courseId}/recruitment`)));
}
function* recruitmentSaga() {
	yield takeEvery(RECRUITE_MANAGER, workerRecruiteManager);
	yield takeEvery(CALCULATE_BUDGETING, workerCalculateBudgeting);
	yield takeEvery(ADD_COST_OF_FINANCE, workerAddCostOfFinance);
	yield takeEvery(GET_REVENUE_ON_TAB, workerGetRevenueOnTab);
	yield takeEvery(SET_SELLING_PRICE, workerSetSellingPrice);
	yield takeEvery(CALCULATE_REVENUE, workerCalculateRevenue);
	yield takeEvery(FINANCIAL_STATEMENT, workerFinancialStatement);
	yield takeEvery(CLEAN_DATA_PLAY_AGAIN, workerCleanDataPlayAgain);
}

export default recruitmentSaga;