import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { storeSecondGameData } from "../../store/RetailGame/Course/actions";
import { storeStudentGameData } from "../../store/RetailGame/Student/actions";
import RetailGameLayout2 from "./RetailGameLayout2";
import SecondGameFooter from "./SecondGameFooter";

const McqQuiz2 = ({ studentQues }) => {
  const [answer, setAnswer] = useState("");
  const dispatch = useDispatch();
  const [result, setResult] = useState(null);
  const { courseName } = useParams();
  const { token, startStep, userAns, singleGame } = useSelector((state) => ({
    token: state.Login.token,
    singleGame: state.studentGame.singleGame,
    startStep: state.retail.startStep,
    currentMark: state.retail.currentMark,
    userAns: state.retail.userAns,
  }));
  console.log("startStep", startStep);
  console.log("studentQues", studentQues);

  useEffect(() => {
   
    dispatch(storeSecondGameData("currentMark", 0));
    dispatch(storeStudentGameData("courseName", courseName));
    dispatch(
      storeSecondGameData(
        "totalQues",
        singleGame?.questionNumberAndSerial?.[0]?.totalQuestion
      )
    );
    dispatch(
      storeSecondGameData(
        "totalNumber",
        singleGame?.questionNumberAndSerial?.[0]?.totalNumber
      )
    );
  }, []);

  const handleRightAns = (userAns) => {
    dispatch(storeSecondGameData("userAns", userAns));
    let rightAns = studentQues?.questionArr[startStep]?.rightAnswer;
    console.log("userAns", userAns);
    console.log("rightAns", answer);
    if (rightAns === userAns) {
      setResult("Correct!");
      dispatch(storeSecondGameData("currentMark", 1));
    } else {
      setResult("Wrong!");
      dispatch(storeSecondGameData("currentMark", 0));
    }
  };
  console.log("answer", answer);
  console.log("result", result);
  return (
    <RetailGameLayout2>
      <div className="quiz-content-full-area">
        <div className="single-quiz-item-retail-2">
          <h6>{studentQues?.questionArr?.[startStep]?.question}</h6>
          <div className="select-your-answer-btn">
            <div className="select-your-answer-left-part">
              <div
                className={
                  userAns === studentQues?.questionArr?.[startStep]?.options[0]
                    ? "left-bg-area active"
                    : "left-bg-area"
                }
                onClick={() => {
                  let userAns = studentQues?.questionArr?.[startStep]?.options[0];
                  setAnswer(userAns);

                  handleRightAns(userAns);
                }}
              ></div>
              <button>{studentQues?.questionArr?.[startStep]?.options[0]}</button>
            </div>
            <div className="select-your-answer-right-part">
              <div
                className={
                  userAns === studentQues?.questionArr?.[startStep]?.options[1]
                    ? "right-bg-area active"
                    : "right-bg-area"
                }
                onClick={() => {
                  let userAns = studentQues?.questionArr?.[startStep]?.options[1];
                  setAnswer(userAns);

                  handleRightAns(userAns);
                }}
              ></div>
              <button>{studentQues?.questionArr?.[startStep]?.options[1]}</button>
            </div>
          </div>
          <SecondGameFooter answer={answer} userAns={userAns} />
        </div>
      </div>
    </RetailGameLayout2>
  );
};

export default McqQuiz2;
