import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Container, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  addPrediction,
  clearPredictionData,
} from "../../../../store/housingMarket/housingMarketSlice";
import StepNavbar from "./StepNavbar";

const HousingMarketStepOne = () => {
  const { newAddedValues } = useSelector((state) => state?.housingMarket);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [updateValue, setUpdateValue] = useState();
  const [dValue, setDValue] = useState("");
  const [dValue2, setDValue2] = useState("");
  const [dValue3, setDValue3] = useState("");
  const history = useHistory();
  useEffect(() => {
    setUpdateValue(newAddedValues);
  }, [newAddedValues]);
  useEffect(() => {
    setDValue(updateValue?.[8]);
    setDValue2(updateValue?.[9]);
    setDValue3(updateValue?.[10]);
  }, [updateValue]);
  const series = [
    {
      name: "series1",
      data: newAddedValues,

      color: "rgba(87, 86, 216, 1)",
    },
  ];
  const options = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    labels: ["86", "88", "90", "92", "94", "96", "98", "100", "+1", "+2", "+3"],
    stroke: {
      curve: "smooth",
    },

    yaxis: {
      labels: {
        formatter: (val) => {
          return val + "%";
        },
      },
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };
  useEffect(() => {
    dispatch(addPrediction());
  }, [dispatch]);
  const handleKeyPress = (e, index) => {
    const keyCode = e.keyCode || e.which;
    if (keyCode === 13) {
      console.log(e.target.value, index);
      const value = e.target.value;
      dispatch(addPrediction({ value, index }));
    }
  };
  const removeInputData = () => {
    localStorage.removeItem("HM-housingMarket");
    dispatch(clearPredictionData());
  };
  //=======================
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  console.log(pathname);
  return (
    <>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>Prediction saved</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          WalKart is a furniture manufacturer which wants to expand its b
          <button
            className="btn btn-primary"
            onClick={() => history.push("/housing-market-prediction/step-2")}
          >
            Step 2
          </button>
        </Modal.Body>
      </Modal>
      <Container>
        <div className="third-game-frame-wrap housing-market-frame-wrap">
          <StepNavbar pathname={pathname} />
          <div className="housing-market-introduction-header-content-area">
            <div className="prediction-data-graph-and-content">
              <h4>Ready to make your prediction</h4>
              <p>
                WalKart is a furniture manufacturer which wants to expand its
                buisness in south east Asia. Their flagship brand, Teaco is a
                household name and they are not available in the region.
                Currently, the size of the industry in Bangladesh is 1000 BDT,
                and there are 5 players who are purely manufacturers. T mart{" "}
              </p>
              <div className="game-step-1-checkbox">
                <input type="checkbox" name="" id="" />
                <label htmlFor="">
                  WalKart is a furniture manufacturer which wants to expand its
                  buisness in south east Asia. Their flagship brand, Teaco is a
                  household name and they are not available in the
                </label>
              </div>
            </div>
            <div className="prediction-graph-bottom">
              <div className="divide-title-prediction">
                <p>Evaluation of house price </p>
                {/* <span className="your-prediction-title">Your prediction</span> */}
              </div>

              <div className="evaluation-graph">
                <div className="left-prev-data-show">
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="line"
                    height={460}
                  />
                  {/* <TestGraph newAddedValues={newAddedValues} /> */}
                  <div className="prediction-input-data-bottom-wrap">
                    <div className="single-input-prediction-value">
                      <p>Your prediction</p>
                      <input
                        type="number"
                        onChange={(e) => setDValue(e.target.value)}
                        onKeyPress={(e) => handleKeyPress(e, 8)}
                        value={dValue || ""}
                      />
                      <input
                        type="number"
                        onChange={(e) => setDValue2(e.target.value)}
                        onKeyPress={(e) => handleKeyPress(e, 9)}
                        value={dValue2 || ""}
                      />
                      <input
                        type="number"
                        onChange={(e) => setDValue3(e.target.value)}
                        onKeyPress={(e) => handleKeyPress(e, 10)}
                        value={dValue3 || ""}
                      />
                    </div>
                  </div>
                  {newAddedValues.length >= 11 && (
                    <div className="prediction-input-data-bottom-wrap">
                      <div className="single-input-prediction-value">
                        <button onClick={removeInputData}>Clear</button>
                        <button
                          onClick={() => setShow(true)}
                          className="confirm-prediction-btn"
                        >
                          Confirm
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default HousingMarketStepOne;
