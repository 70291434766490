import React, { useState } from "react";
import { Col, Form, Modal as BModal, Row } from "react-bootstrap";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { gdpData } from "./../gamePart/gdpData";
const CalculateModal = ({ show, handleClose, handleShow, modalSize }) => {
  const [showData, setShowData] = useState(false);
  const [openSecond, setOpenSecond] = React.useState(false);
  return (
    <BModal
      show={show}
      onHide={() => {
        handleClose();
        // setShowData(false);
      }}
      size={showData ? "xl" : modalSize}
      centered
    >
      <BModal.Header
        closeButton
        className="gdpcalculate-modal-header"
      ></BModal.Header>
      <BModal.Body className="gdp-submit-form-popup">
        {showData ? (
          <div className="calculate-next-15years-data">
            <Modal
              open={openSecond}
              onClose={() => setOpenSecond(false)}
              center
              classNames={{
                overlay: "customOverlay",
                modal: "customModal-gdp-data-submit",
              }}
            >
              <div className="gdp-confirm-popup-submit">
                <p>
                  Lorem ipsum dolor sit amet consectetur. Mauris pulvinar
                  commodo in pellentesque dis pellentesque amet vitae porttitor.
                  Sit id in{" "}
                </p>{" "}
                <Form>
                  <div className="mb-3">
                    <Form.Check
                      label="Lorem ipsum dolor sit amet consectetur. Mauris 
                      pulvinar commodo "
                      name="group1"
                      type="radio"
                      id={`inline--1`}
                    />
                    <Form.Check
                      label="Lorem ipsum dolor sit amet consectetur. Mauris 
                      pulvinar commodo "
                      name="group1"
                      type="radio"
                      id={`inline--2`}
                    />
                  </div>
                </Form>
                <div className="gdp-confirm-popup-submit-btn">
                  <button onClick={() => setOpenSecond(false)}>Ok</button>
                </div>
              </div>
            </Modal>
            <Row>
              <Col lg={8}>
                <div className="country-gdp-statistics-with-data">
                  <div className="country-name-left">
                    <h3>Country name</h3>
                    {gdpData[0]?.countryData?.map((data) => (
                      <div className="single-country-name">
                        <img src={data?.flag} alt="" />
                        <span>{data.shortName}</span>
                      </div>
                    ))}
                  </div>
                  <div className="country-gdp-value-with-year-right">
                    {gdpData.map((data, idx) => (
                      <>
                        <div className="single-country-name-wrap">
                          <div className="single-gdp-year-show">
                            {data.year}
                          </div>
                          {gdpData[idx]?.countryData?.map((data) => (
                            <div className="single-country-name">
                              {data.data}
                              <span></span>
                            </div>
                          ))}
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </Col>
              <Col lg={4}>
                <div className="country-gdp-statistics-content-right">
                  <div className="gdp-popup-content-right-top">
                    <h3>Your forecast</h3>
                    <p>
                      Lorem ipsum dolor sit amet constetur. Elit felis duis eu
                      pellentesque viverra. Risus est ultrices lectus lom sed
                      nulla erat ullamcorper.{" "}
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet constetur. Elit felis duis eu
                      pellentesque viverra. Risus est ultrices lectus lom sed
                      nulla erat ullamcorper.{" "}
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet constetur. Elit felis duis eu
                      pellentesque viverra. Risus est
                    </p>
                    <p>
                      Lorem ipsum dolor sit amet constetur. Elit felis duis eu
                      pellentesque viverra. Risus est ultrices lectus lom sed
                      nulla erat ullamcorper.{" "}
                    </p>
                  </div>
                  <button
                    onClick={() => setOpenSecond(true)}
                    // onClick={() => {
                    //   // setShowData(!showData);
                    //   onOpenModal();
                    // }}
                  >
                    Send
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="calculate-next-15years-data">
            <p>
              Lorem ipsum dolor sit amet consectetur. Mauris pulvinar commodo in
              pellentesque dis pellentesque amet vitae porttitor. Sit id in{" "}
            </p>
            <button onClick={() => setShowData(!showData)}>
              Calculate next 15years
            </button>
          </div>
        )}
      </BModal.Body>
    </BModal>
  );
};

export default CalculateModal;
