import React, { useState } from "react";
import { useSelector } from "react-redux";
import AudioSet from "./AudioSet";
import Footer from "./Footer";
import RetailGameLayout from "./RetailGameLayout";

const AudioQues = ({ studentQues }) => {
  console.log("studentQues", studentQues);
  const [ansCheck, setAnsCheck] = useState(false);
  const [ans, setAns] = useState("");

  const { token, mcqStartStep, selected, currentType } = useSelector(
    (state) => ({
      token: state.Login.token,
      currentIndex: state.studentGame.currentIndex,
      currentType: state.studentGame.currentType,
    })
  );

  const handleAns = (e) => {
    setTimeout(() => {
      setAns(e.target.value);
      if (e.target.value * 1 === studentQues?.rightAnswer) {
        setAnsCheck(true);
      } else {
        setAnsCheck(false);
      }
    }, 1000);
 
  };
  
  console.log("ansCheck", ansCheck, ans);

  return (
    <RetailGameLayout>
      {" "}
      <div className="quiz-content-full-area">
        <div className="single-quiz-item">
          <p className="fw-bold">IOQ calculation check</p>
          <h6>
            নিচের আডিও ক্লিপ মনোযোগ দিয়ে শুনুন এবং নিচের খালি বক্স এ সঠিক সংখ্যা
            লিখুন{" "}
          </h6>

          <AudioSet track={studentQues?.audio} />
          <div className="retail-game-ioq-answer">
            <p>IOQ =</p>
            <input type="number" onChange={(e) => handleAns(e)}></input>
          </div>
          {ans === "" ? (
            ""
          ) : ansCheck ? (
            <p
              className="text-success"
              style={{
                fontSize: "18px",
                marginBottom: "20px",
              }}
            >
              আপনার উত্তর টি সঠিক
            </p>
          ) : (
            <p
              className="text-danger"
              style={{
                fontSize: "18px",
                marginBottom: "20px",
              }}
            >
              <i className="bx bx-x-circle"></i> দুঃখিত, আপনার উত্তর টি সঠিক নয়
            </p>
          )}
          <Footer rightAns={ansCheck} currentType={currentType} />
        </div>
      </div>
    </RetailGameLayout>
  );
};

export default AudioQues;
