import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import bdm from "../../assests/images/bdm.svg";
import hrManager from "../../assests/images/hrManager.svg";
import salesManager from "../../assests/images/salesManager.svg";
import "../../assests/styles.css";
import { addRecruitManager, courseOwnerValid } from "../../store/actions";
import { toast } from 'react-toastify';

const Recruitment = () => {
  let dispatch = useDispatch();
  let history = useHistory();
let {courseId} = useParams()
  const [bdmCounter, setBdmCounter] = useState(1);
  const [hrCounter, setHrCounter] = useState(1);
  const [salesCounter, setSalesCounter] = useState(1);
   const { recruitManager, token } = useSelector((state) => ({
     recruitManager: state.recruitReducer.recruitManager,
     token: state.Login.token,

  }));
  const incrementCounterBDM = () => setBdmCounter(bdmCounter + 1);
  let decrementCounterBDM = () => setBdmCounter(bdmCounter - 1);
  const incrementCounterHR = () => setHrCounter(hrCounter + 1);
  let decrementCounterHR = () => setHrCounter(hrCounter - 1);
  const incrementCounterSales = () => setSalesCounter(salesCounter + 1);
  let decrementCounterSales = () => setSalesCounter(salesCounter - 1);
  const handleSubmitBudget = (e, position) => {
    const keyCode = e.keyCode || e.which;

    if (keyCode == 13) {
      console.log("hello data", e.target.value, position);
      
      dispatch(addRecruitManager(e.target.value, position));
    }

  };

  let func = e =>
  {
    
    let data = parseInt(localStorage.getItem("totalOpex")) > -1
    if (data) {
      history.push(`/${courseId}/budgeting`)
    } else {
      toast.error("Please insert the data first")
    }
  }
  useEffect(() =>
  {
    dispatch(courseOwnerValid(token,courseId, history ))
  },[])
  return (
    <div className="revenue-item-bottom-item-main-wrap">
      <div className="container">
        <div className="recruitment-info">
          <h4 className="mb-2">Recruitment</h4>
          <h5>
            You need to recruit 3 managers, one HR manager, and one BDM, and 1
            Sales manager in your team. For that you have only 5 BDT budget, and
            sales manager can only have salaries in sales, no fixed amount
            therefore. And CEO’s salary is 3 BDT
          </h5>
        </div>
        <div className="row mt-5">
          <div className="col-md-3">
            <div className="single-recruitment">
              <div className="single-recruitment-title">
                <h3>BDM</h3>
              </div>
              <div className="single-recruitment-image">
                <img src={bdm}></img>
              </div>
              <div className="single-recruitment--bottom-btn">
                {/* <button onClick={decrementCounterBDM}>-</button> */}
                <input
                  type="number"
                  className="budget-data"
                  placeholder="0"
                  onKeyPress={(e) => handleSubmitBudget(e, "firstEmp")}
                />
                <p>
                  {" "}
                  {recruitManager?.first
                    ? recruitManager?.first
                    : localStorage.getItem("firstEmp")}
                  {/* <button onClick={incrementCounterBDM}>+</button> */}
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="single-recruitment">
              <div className="single-recruitment-title">
                <h3>HR Manager</h3>
              </div>
              <div className="single-recruitment-image">
                <img src={hrManager}></img>
              </div>
              <div className="single-recruitment--bottom-btn">
                <div className="single-budget-data">
                  {/* <button onClick={decrementCounterHR}>-</button> */}
                  <input
                    type="number"
                    className="budget-data"
                    placeholder="0"
                    onKeyPress={(e) => handleSubmitBudget(e, "secondEmp")}
                  />
                  <p>
                    {" "}
                    {recruitManager?.second
                      ? recruitManager?.second
                      : localStorage.getItem("secondEmp")}
                    {/* <button onClick={incrementCounterHR}>+</button> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="sss">
              <div className="single-recruitment">
                <div className="single-recruitment-title">
                  <h3>Sales Manager</h3>
                </div>
                <div className="single-recruitment-image">
                  <img src={salesManager}></img>
                </div>
                <div className="single-recruitment--bottom-btn">
                  {/* <button onClick={decrementCounterSales}>-</button> */}
                  <input
                    type="number"
                    className="budget-data"
                    placeholder="0"
                    onKeyPress={(e) => handleSubmitBudget(e, "thirdEmp")}
                  />
                  <p>
                    {" "}
                    {recruitManager?.third
                      ? recruitManager?.third
                      : localStorage.getItem("thirdEmp")}
                    {/* <button onClick={incrementCounterSales}>+</button> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 my-auto">
            <div className="single-recruitment-item-main-wrap">
              <div className="single-recruitment">
                <div className="single-recruitment-title-recruitment-item-main-wrap">
                  <div>
                    <div className="recruitment-total-info">
                      <p>Your value is greater than your budget.</p>
                    </div>
                    <div className="single-recruitment-total">
                      <p>Total: 1000 BDT</p>
                      <p>
                        Total OPEX{" "}
                        {recruitManager?.opex
                          ? recruitManager?.opex
                          : localStorage.getItem("totalOpex")}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="case-summery-btn">
          <Link to={`/${courseId}/case-summary`}>
            <div>
              <button className="case-summery-previous-btn">Previous</button>
            </div>
          </Link>
          <Link >
            <div>
              <button className="case-summery-next-btn" onClick={e => func(e)}>Next</button>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Recruitment
