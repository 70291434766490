import {
  CLEAR_STUDENT_GAME_DATA,
  GET_SINGLE_GAME,
  GET_SINGLE_GAME_FAIL,
  GET_SINGLE_GAME_SUCCESS,
  GET_STUDENT_COURSE,
  GET_STUDENT_COURSE_FAIL,
  GET_STUDENT_COURSE_SUCCESS,
  GET_STUDENT_QUES,
  GET_STUDENT_QUES_FAIL,
  GET_STUDENT_QUES_SUCCESS,
  STORE_STUDENT_GAME_DATA,
} from "./actionTypes";

export const getStudentData = (token) => {
  return {
    type: GET_STUDENT_COURSE,
    payload: { token },
  };
};

export const getStudentDataSuccess = (values) => {
  return {
    type: GET_STUDENT_COURSE_SUCCESS,
    payload: { values },
  };
};

export const getStudentDataFail = (message) => {
  return {
    type: GET_STUDENT_COURSE_FAIL,
    payload: { message },
  };
};

export const getStudentQues = (token, courseId, type) => {
  return {
    type: GET_STUDENT_QUES,
    payload: { token, courseId, type },
  };
};

export const getStudentQuesSuccess = (values) => {
  return {
    type: GET_STUDENT_QUES_SUCCESS,
    payload: values,
  };
};

export const getStudentQuesFail = (message) => {
  return {
    type: GET_STUDENT_QUES_FAIL,
    payload: { message },
  };
};

export const getSingleGame = (courseId) => {
  return {
    type: GET_SINGLE_GAME,
    payload: { courseId },
  };
};

export const getSingleGameSuccess = (values) => {
  return {
    type: GET_SINGLE_GAME_SUCCESS,
    payload: values,
  };
};

export const getSingleGameFail = (message) => {
  return {
    type: GET_SINGLE_GAME_FAIL,
    payload: { message },
  };
};


export const storeStudentGameData = (name, data) => ({
  type: STORE_STUDENT_GAME_DATA,
  payload: { name, data },
});

export const clearGame = () => ({
  type: CLEAR_STUDENT_GAME_DATA,
  payload: {},
});
