import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import successfullIcon from "../LafargeAssets/images/successfull.png";
import LafargeCourseWareHeader from "../LafargeCommon/LafargeHeader/LafargeCourseWareHeader";
const SuccesfullySubmitted = () => {
  return (
    <div>
      <LafargeCourseWareHeader />
      <Container>
        <div className="success-main-page-area-wrap">
          <div className="success-main-page-area">
            <img src={successfullIcon} alt="" />
            <h3>Succesfully Submitted</h3>
            <p style={{ fontSize: "18px" }}>
              ‘’We will send your result within one week directly to your mail”
            </p>
            <Link to="/">Go Home </Link>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SuccesfullySubmitted;
