export const videoLinkData = [
  {
    className: "active",
    title: "Paradigm Shift",
    id: "first",
    videoLink: "https://www.youtube.com/embed/zw4QwDZF2Jw",
  },
  {
    className: "active",
    title: "Non-Price Items",
    id: "second",
    videoLink: "https://www.youtube.com/embed/-JoImyGI3R0",
  },
  {
    className: "active",
    title: "Reservation Value",
    id: "third",
    videoLink: "https://www.youtube.com/embed/XnSVxuL7_o0",
  },
  {
    className: "active",
    title: "BATNA",
    id: "fourth",
    videoLink: "https://www.youtube.com/embed/dwmjE13X-Os",
  },
  {
    className: "active",
    title: "ZOPA",
    id: "fifth",
    videoLink: "https://www.youtube.com/embed/ot3jjj8N2hY",
  },
  {
    className: "active",
    title: "Door in the Face Technique",
    id: "sixth",
    videoLink: "https://www.youtube.com/embed/aQDIXHjfT60",
  },
  {
    className: "active",
    title: "Foot in the door technique",
    id: "seventh",
    videoLink: "https://www.youtube.com/embed/JLgr5RTBqcI",
  },
];
