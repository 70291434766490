import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storeStudentGameData } from "../../store/RetailGame/Student/actions";
import Footer from "./Footer";
import RetailGameLayout from "./RetailGameLayout";

const McqQuiz = ({ studentQues }) => {
  console.log("studentQues", studentQues);
  const [rightAns, setRightAns] = useState(false);
  let dispatch = useDispatch();
  const { token, mcqStartStep, selected, currentType } = useSelector(
    (state) => ({
      token: state.Login.token,
      currentIndex: state.studentGame.currentIndex,
      mcqStartStep: state.studentGame.mcqStartStep,
      selected: state.studentGame.selected,
      currentType: state.studentGame.currentType,
    })
  );

  const handleAns = (item, i) => {
    dispatch(storeStudentGameData("selected", i + 1));
    if (item === studentQues?.mcq?.[0]?.mcq?.[mcqStartStep]?.rightAnswer[0]) {
      setRightAns(true);
    } else {
      setRightAns(false);
    }
  };
  return (
    <RetailGameLayout>
      <div className="quiz-content-full-area">
        <div className="single-quiz-item">
          {/* <p>প্রশ্ন 5 এর মধ্যে 1</p> */}
          <h6>{studentQues?.mcq?.[0]?.mcq?.[mcqStartStep]?.question}</h6>
          <div className="">
            <ul>
              {studentQues?.mcq?.[0]?.mcq?.[mcqStartStep]?.options?.map(
                (item, i) => (
                  <li
                    onClick={() => handleAns(item, i)}
                    key={i}
                    className={
                      selected === i + 1 && rightAns
                        ? "selected-active"
                        : selected === i + 1 && !rightAns
                        ? "selected-wrong"
                        : ""
                    }
                  >
                    {item}
                  </li>
                )
              )}
            </ul>
          </div>
          <Footer
            rightAns={rightAns}
            currentType={currentType}
            setRightAns={setRightAns}
          />
        </div>
      </div>
    </RetailGameLayout>
  );
};

export default McqQuiz;
