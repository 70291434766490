import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Col, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { selectCountry } from "../../../store/CourseThree/courseSlice";
import { mapCountryData } from "./mapCountryData";

const MapModalView = ({ show, handleClose, country }) => {
  const dispatch = useDispatch();
  const countryViewInfo = mapCountryData.find(
    (data) => data.shortName === country
  );
  const seriesData = countryViewInfo?.countryGDP?.map(
    (data, idx) => data.score
  );
  const [submitInfo, setSubmitInfo] = useState({
    countryTitle: "",
    gdpNumber: 0,
    message: "",
  });
  useEffect(() => {
    if (country) {
      setSubmitInfo((prev) => ({
        ...prev,
        countryTitle: country,
      }));
    }
  }, [country]);
  const handleFormSubmit = (e) => {
    e.preventDefault();
    dispatch(selectCountry(submitInfo));
    handleClose(true);
  };
  const series = [
    {
      name: "Sales",
      data: seriesData,
    },
  ];
  const options = {
    chart: {
      height: 200,
      type: "line",
      background: "#fff",
      toolbar: {
        show: false,
      },
    },

    forecastDataPoints: {
      count: 3,
    },
    stroke: {
      curve: "smooth",
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      enabled: false,
    },
    title: {
      text: "",
      align: "left",
      style: {
        fontSize: "16px",
        color: "#666",
      },
    },
    dataLabels: {
      enabled: true,
    },

    yaxis: {
      enabled: false,
    },
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      //   backdrop="static"
      //   keyboard={false}
      size="xl"
      centered
    >
      <Modal.Body className="gdp-submit-form-popup">
        <Row>
          <Col lg="3">
            <div className="country-info-left-popup">
              <img src={countryViewInfo?.flag} alt="" />
              <ReactApexChart
                options={options}
                series={series}
                type="line"
                height={200}
              />
              <p>GDP current price of 100 US $ billion</p>
            </div>
          </Col>
          <Col lg="9">
            <div className="country-info-right-popup">
              <h2>{countryViewInfo?.fullName}</h2>
              <form onSubmit={handleFormSubmit}>
                <div className="form-group">
                  <label htmlFor="gdp-input">
                    Forecast GDP Growth for the us for the next 15 years
                  </label>
                  <input
                    className="form-control"
                    type="number"
                    id="gdp-input"
                    onChange={(e) =>
                      setSubmitInfo({
                        ...submitInfo,
                        gdpNumber: e.target.value,
                      })
                    }
                  />
                </div>
                <hr />
                <div className="form-group">
                  <label htmlFor="gdp-input">
                    Please explain your decision briefly in the above ( no more
                    than 200)
                  </label>
                  <textarea
                    className="form-control"
                    onChange={(e) =>
                      setSubmitInfo({
                        ...submitInfo,
                        message: e.target.value,
                      })
                    }
                  ></textarea>
                </div>
                <button>Save</button>
              </form>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary">Understood</Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default MapModalView;
