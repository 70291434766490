import React from "react";
import ReactApexChart from "react-apexcharts";

const CostApexLineColumn = () => {
	const series = [
		{
			name: "Product A",
			data: [
				parseInt(localStorage.getItem("totalProdCostAMonthOne")),
				parseInt(localStorage.getItem("totalProdCostAMonthTwo")),
			],
		},
		{
			name: "Product B",
			data: [
				parseInt(localStorage.getItem("totalProdCostBMonthOne")),
				parseInt(localStorage.getItem("totalProdCostBMonthTwo")),
			],
		},

		// {
		// 	name: "Month 2",
		// 	data: [0, 0],
		// },
	];
	const options = {
		chart: {
			toolbar: {
				show: false,
			},
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: "25%",
				endingShape: "rounded",
			},
		},
		dataLabels: {
			enabled: false,
		},
		stroke: {
			show: true,
			width: 2,
			colors: ["transparent"],
		},

		colors: ["#A589DF", "#BDD1F9"],
		xaxis: {
			categories: ["First Month", "Second Month"],
		},
		yaxis: {
			title: {
				// text: "$ (thousands)",
			},
		},
		grid: {
			borderColor: "#f1f1f1",
		},
		fill: {
			opacity: 1,
		},
		tooltip: {
			y: {
				formatter: function (val) {
					return "tk : " + val + " ";
				},
			},
		},
	};

	return (
		<ReactApexChart
			options={options}
			series={series}
			type="bar"
			height={250}
		/>
	);
};

export default CostApexLineColumn;
